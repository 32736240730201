import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ReportPropertyDetailConstant } from '../../report-property-detail.constant';
import { ReportPropertyDetailService } from '../../report-property-detail.service';
import moment from 'moment';

@Component({
  selector: 'app-property-transaction',
  templateUrl: './property-transaction.component.html',
  styleUrls: ['./property-transaction.component.scss']
})
export class PropertyTransactionComponent implements OnChanges {
  
  @Input() propertyInfo: any;
  @Output() headerCount = new EventEmitter();

  
  propertyTransactionLoading = false;
  propertyTransactionData = [];
  propertyTransactionCount = 0;

  transactionPropertyColumns  = ReportPropertyDetailConstant.transactionPropertyColumns;

  constructor(private reportPropertyDetailService: ReportPropertyDetailService){}

  ngOnChanges(): void {
    this.getPropertyTransaction();
  }

  getPropertyTransaction() {
    this.propertyTransactionLoading = true;
    this.reportPropertyDetailService.getPropertyTransctionReport(this.reportPropertyDetailService.propertyTransactionPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.propertyTransactionData = res.data.map((value: any) => {
              value['transaction_date']= value.transaction_date ? moment.utc(value.transaction_date).local().format('DD MMM YYYY') : null;
              return value;
            }) 
            if(this.reportPropertyDetailService.propertyTransactionPayload.pageNumber == 0){
            this.propertyTransactionCount = res.count;
            this.headerCount.emit(this.propertyTransactionCount)
           }
            
          } else {
            this.propertyTransactionData = [];
            this.propertyTransactionCount = 0;
            this.headerCount.emit(null)
          }
          this.propertyTransactionLoading = false;
        },
        error:()=>{
          this.propertyTransactionLoading = false;
          this.propertyTransactionData = [];
          this.propertyTransactionCount = 0;
          this.headerCount.emit(null)
        }
      }
    )
  }

  

  onPageEvent(e: any) {
    this.reportPropertyDetailService.propertyTransactionPayload.pageNumber = (e.pageSize * e.pageIndex);
    this.getPropertyTransaction();
  }

  sortData(event: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    this.reportPropertyDetailService.propertyTransactionPayload.pageNumber = 0;
    this.reportPropertyDetailService.propertyTransactionPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
    this.getPropertyTransaction();
  }

  downloadExcel(event: any, type: string) {
    this.reportPropertyDetailService.downloadRentalReport(type);
  }

  
}
