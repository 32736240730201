import { SharedService } from "../services/shared.service";

export class GlobalContants {

     static Routes = class {
        public static get LogIn() : string {
            return '';
        }
        public static get Log_In() : string {
            return 'login';
        }
        public static get Signup() : string {
            return 'sign-up';
        }
        public static get SignUp() : string {
            return 'signup';
        }
        public static get ForgotPassword() : string {
            return 'login/forgot-password';
        }
        public static get Dashboard() : string {
            return 'dashboard';
        }
        public static get Reports() : string {
            return 'reports';
        }
        public static get Profile() : string {
            return 'profile';
        }
        public static get PersonalInfo() : string {
            return 'personal-info';
        }
        public static get ResetPassword() : string {
            return 'reset-password';
        }
        public static get Notification() : string {
          return 'notifications';
      }
      public static get MySavedReport() : string {
          return 'saved-report';
      }
      public static get RequestDemo() : string {
          return 'request-demo';
      }
      public static get MySavedSearch() : string {
        return 'saved-search';
      }
      public static get BookMark() : string {
        return 'bookmarks';
      }
      public static get SampleReport() : string {
        return 'AI-report';
      }
      public static get emailVerification() : string {
        return 'verify';
      }
      public static get notVerified() : string {
        return 'not-verified'
      }
    }

    static StoreKey = class {
        public static get Slug() : string {
            return 'slug';
        }
        public static get PlaceId() : string {
            return 'placeId';
        }
        public static get PlaceName() : string {
            return 'placeName';
        }
        public static get CurrencyCode() : string {
            return 'currencyCode';
        }
        public static get latitude() : string {
            return 'latitude';
        }
        public static get longitude() : string {
            return 'longitude';
        }
        public static get CurrentLang() : string {
            return 'currentLang';
        }
        public static get CurrentLangID() : string {
            return 'currentLangID';
        }
        public static get userInfo() : string {
            return 'user_info';
        }
        public static get userToken() : string {
            return 'user_token';
        }
        public static get currentRoute() : string {
            return 'currentRoute';
        }
        public static get latestReportRoute() : string {
            return 'latestReportRoute';
        }
        public static get placeCode() : string {
            return 'place_code';
        }
        public static get neighList() : string {
            return 'neigh_list';
        }
        public static get langList() : string {
            return 'lang_list';
        }
        public static get cityList() : string {
            return 'city_list';
        }
        public static get CityFlag() : string {
            return 'city_flag';
        }
        public static get legend() : string {
            return 'legend';
        }
    }

    static keySymbolContants = class {

        public static get percentage() : string {
            return '%';
        }
        public static get unit() : string {
            return 'm\u00B2';
        }
        public static get squareMeter() : string {
            return 'sqm';
        }
        public static get squareMeterUnit() : string {
            return 'sqm_unit';
        }
        public static get squarefeetUnit() : string {
            return 'feet_unit';
        }
        public static get squarefeet() : string {
            return 'feet';
        }
    }

    static defaultTitleConstant = class {
        public static get defaultTitle() : string {
            return 'Estater Markets'
        }
    }

}
