import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportMallProfilingService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  getMallProfilingTotalReport(year = null){
    
    const url: string = `${environment.miUrl}${apiEndPoints.mallProfilingTotalReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getMallProfilingFloorWiseReport(year = null){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year =  year ? year : this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.mallProfilingFloorWiseReport}${changeParams}`;
    return this.apiServive.get(url);
  }
}
