import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { InventoryMenuEnum } from "./report-investment-inventory.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class InventoryReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: InventoryMenuEnum.overview
        },
        {
            label: 'reports.propertiesByNeighborhood',
            name: InventoryMenuEnum.propertiesByNeighborhood,
            count: null
        },
        {
            label: 'reports.projectsProperties',
            name: InventoryMenuEnum.projectsAndProperties,
            count: null
        },
        {
            label: 'reports.project',
            name: InventoryMenuEnum.project,
            count: null
        }
    ]

    static propertiesByNeighborhoodColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'total_land',
            columnName: 'reports.totalProperties',
            field: 'total_land',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
       
        {
            header: 'land_area',
            columnName: 'reports.totalLandArea',
            field: 'land_area',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'residential',
            columnName: 'reports.totalResidentialUnits',
            field: 'residential',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
        },
        {
            header: 'commercial',
            columnName: 'reports.totalCommercialUnits',
            field: 'commercial',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true
        }
    ]

    
    static propertiesByNeighborhoodExpandColumns: any[] = [
        { field : 'block', convertNumber: false },
        { field : 'total_land', convertNumber: true },
        { field : 'land_area', convertNumber: true },
        { field : 'residential', convertNumber: true },
        { field : 'commercial', convertNumber: true }
    ]

    static projectsAndPropertiesColumns: ReportTableColumn[] = [
        {
            header: 'p_property_name',
            columnName: 'reports.PropertyName',
            field: 'p_property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true
        },
        {
            header: 'build_year',
            columnName: 'reports.yearOfBuild',
            field: 'build_year',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'land_area',
            columnName: 'reports.totalLandArea',
            field: 'land_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'residential',
            columnName: 'reports.totalResidentialUnits',
            field: 'residential',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'commercial',
            columnName: 'reports.totalCommercialUnits',
            field: 'commercial',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'total_buildup_area',
            columnName: 'reports.builtup_area',
            field: 'total_buildup_area',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        }
       
    ]

    static projectColumns: ReportTableColumn[] = [
        {
            header: 'project_name',
            columnName: 'reports.project_name',
            field: 'project_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true
        },
        {
            header: 'build_year',
            columnName: 'reports.yearOfBuild',
            field: 'build_year',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'land_area',
            columnName: 'reports.totalLandArea',
            field: 'land_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'residential',
            columnName: 'reports.totalResidentialUnits',
            field: 'residential',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'commercial',
            columnName: 'reports.totalCommercialUnits',
            field: 'commercial',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_buildup_area',
            columnName: 'reports.builtup_area',
            field: 'total_buildup_area',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        }
       
    ]

    static unitPropertyColumns: ReportTableColumn[] = [
        {
            header: 'unit_type',
            columnName: 'reports.unitType',
            field: 'unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            
        },
        {
            header: 'count',
            columnName: 'reports.count',
            field: 'count',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
       
        {
            header: 'min_size',
            columnName: 'reports.minSize',
            field: 'min_size',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'max_size',
            columnName: 'reports.maxSize',
            field: 'max_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'avg_size',
            columnName: 'reports.AverageSize',
            field: 'avg_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        }
    ]

    static unitTransactionPropertyColumns: ReportTableColumn[] = [
        {
            header: 'u_unit_uid',
            columnName: 'reports.unitId',
            field: 'u_unit_uid',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'u_unit_area',
            columnName: 'reports.unitSize',
            field: 'u_unit_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },
       
        {
            header: 'u_unit_type',
            columnName: 'reports.unitType',
            field: 'u_unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'transaction_date',
            columnName: 'reports.transactionDate',
            field: 'transaction_date',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'value_type',
            columnName: 'reports.valueType',
            field: 'value_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]

    static projectUnitTransactionPropertyColumns: ReportTableColumn[] = [
        {
            header: 'u_unit_uid',
            columnName: 'reports.unitId',
            field: 'u_unit_uid',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'u_unit_area',
            columnName: 'reports.unitSize',
            field: 'u_unit_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
        },
       
        {
            header: 'u_unit_type',
            columnName: 'reports.unitType',
            field: 'u_unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'transaction_date',
            columnName: 'reports.transactionDate',
            field: 'transaction_date',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'value_type',
            columnName: 'reports.valueType',
            field: 'value_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]

    
    static projectPropertiesColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'total_units',
            columnName: 'reports.total_units',
            field: 'total_units',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'residential_units',
            columnName: 'reports.totalResidentialUnits',
            field: 'residential_units',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'commercial_units',
            columnName: 'reports.totalCommercialUnits',
            field: 'commercial_units',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]

    
}
