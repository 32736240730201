<div class="filter-section" [formGroup]="unitForm">
    <label class="datepicker-label">{{name | langtranslate}} <span *ngIf="required" class="required">*</span></label>
    <div class="filter-date-input form-control" style="direction: ltr;" (click)="picker.open()" [class.dateinput-disable]="!isSubscription">
        <mat-date-range-input [rangePicker]="picker" [max]="maxDate" [min]="minDate" [disabled]="!isSubscription">
            <input matInput matStartDate [placeholder]="'startDate' | translate" class="form-control" [(ngModel)]="startDate"
                name="startDate" [ngModelOptions]="{standalone: true}" readonly>
            <input matEndDate [placeholder]="'endDate' | translate" class="form-control" [(ngModel)]="endDate" name="endDate"
                [ngModelOptions]="{standalone: true}" (dateChange)="saveDate(startDate, endDate)" readonly>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (closed)="onClose()"></mat-date-range-picker>
        <i *ngIf="!isSubscription" class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
    </div>
</div>