import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WarehouseInventoryMenuEnum } from './report-warehouse-inventory.enum';
import { WarehouseInventoryConstant } from './report-warehouse-inventory.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { WarehouseInventoryService } from './report-warehouse-inventory.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { ReportService } from 'src/app/reports/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/common/services/store.service';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-warehouse-inventory',
  templateUrl: './report-warehouse-inventory.component.html',
  styleUrls: ['./report-warehouse-inventory.component.scss']
})
export class ReportWarehouseInventoryComponent implements OnInit, OnChanges {

  RYDCHECK = environment.RYDCHECK;
  @Input() params: any;
  UnitTypeEnum = UnitTypeEnum;
  overViewMenu = WarehouseInventoryConstant.overViewMenu;
  currentScroll: any = '';

  propertiesColumns = WarehouseInventoryConstant.propertiesColumns;
  neighborhoodColumns = WarehouseInventoryConstant.neighborhoodColumns;
  neighbourhoodExpandColumns = WarehouseInventoryConstant.neighbourhoodExpandColumns;

  chartView = ReportsChartEnum;

  warehouseInventoryMenuEnum = WarehouseInventoryMenuEnum;
  placeName = '';
  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  isFirstTime = true;
  overViewLoading: boolean = false;
  overViewSummary: any = null;
  overViewSubCategory: any = null;

  propertiesData: any = [];
  propertiesCount: number = 0;
  propertiesLoading: boolean = false;
  retailRentalRentrateReport: any = null;

  neighbourhooddata: any = [];
  neighbourhoodCount: number = 0;
  neighbourhoodLoading: boolean = false;

  houserentalChartLoading: boolean = false;
  houserentalChart: any = [];

  resetPaginator: boolean = false;
  currentPageIndex: number = 0;

  overviewbuiltUpArea: any[] = [];
  overviewTotalLandCovered: any[] = [];
  overviewTotalWarehouse: any[] = [];
  overViewCategory: any = null;
  grossBuiltupChartLoading = false;
  langName: any;
  isChart = true;

  grossChartOption: string = 'grossChart';

  downloadLoading: any = {
    a: false,
    b: false
  };
  sqm_unit: string = '';
  graphLoader: boolean = false;

  grossBuiltupChart: any[] = [];
  selectedGrossBuiltupChart: any[] = [];
  selectedGrossBuiltupChartName = '';

  grossLandCoveredLoading = false
  grossLandCoveredOption: string = 'grossLandCoveredChart';
  grossLandCoveredChart: any[] = [];
  selectedGrossLandCoveredChart: any[] = [];
  selectedGrossLandCoveredChartName  = '';
  tableName:unknown;

  constructor(
    private sharedService: SharedService,
    private warehouseInventoryService: WarehouseInventoryService,
    private alertService: AlertService,
    public reportService: ReportService,
    public translate: TranslateService,
    private storeService: StoreService
  ) {
    this.sqm_unit = 'reports.' + GlobalContants.keySymbolContants.squareMeterUnit;
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
        this.isChart = false;
      }
    })
  }

  lastClickedTable(event:Event, table:any){
    this.tableName = table
  }
  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.warehouseInventoryService.propertiesPayload.pageNumber = 0;
      this.warehouseInventoryService.neighbourhoodPayload.pageNumber = 0;

      this.propertyInfo = null;
      this.getReports();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(): void {
    this.storeService.add(this.grossChartOption, this.selectedGrossBuiltupChart);
    this.storeService.add(this.grossLandCoveredOption, this.selectedGrossLandCoveredChart);
  }

 

  getReports() {
    this.getOverviewSummary();
    this.getGrossBuiltupChart();
    this.getNeighbourhood();
    this.getProperties();
    this.getOverviewSubCategory();
    this.getGrossLandCoveredChart();
  }

  getOverviewSummary() {
    this.overViewLoading = true;
    this.warehouseInventoryService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.warehouseInventoryService.formatOverViewSummary(res.data);
          } else {
            this.overViewSummary = null;
          }
          this.overViewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.overViewLoading = false;
        }
      }
    )
  }

  getGrossBuiltupChart() {
    this.grossBuiltupChartLoading = true;
    this.warehouseInventoryService.getGrossBuiltupChart().subscribe(
      {
        next: (res: any) => {
          const previouseSelected: any = this.storeService.get(this.grossChartOption);
          this.grossBuiltupChart = res.data ? Object.keys(res.data).map((val: any, index: number) => {
            return {
              label: val,
              checked: index <= 2 ? true : false,
              data: res.data[val].map((val: any) => {
                let obj = {
                  category: val.year.toString(),
                  value: val.area
                }
                return obj
              })
            }
          }) : [];
          
          // this.selectedGrossBuiltupChart = res.data ? previouseSelected ? previouseSelected : this.grossBuiltupChart.filter(item => item.checked): [];
          this.selectedGrossBuiltupChart = res.data ? this.grossBuiltupChart.filter(item => item.checked): [];
          this.selectedGrossBuiltupChartName = res.data? this.selectedGrossBuiltupChart.map(item => item.label).join(","): '';
          this.grossBuiltupChartLoading = false;
        },
        error: (error: any) => {
          this.grossBuiltupChartLoading = false;
          this.grossBuiltupChart = [];
          this.selectedGrossBuiltupChart = [];
          this.selectedGrossBuiltupChartName = '';
        }
      }
    )
  }

  getGrossLandCoveredChart() {
    this.grossLandCoveredLoading = true;
    this.warehouseInventoryService.getGrossLandCoveredChart().subscribe(
      {
        next: (res: any) => {
          const previouseSelected: any = this.storeService.get(this.grossLandCoveredOption);
          this.grossLandCoveredChart = res.data ? Object.keys(res.data).map((val: any, index: number) => {
            return {
              label: val,
              checked: index <= 2 ? true : false,
              data: res.data[val].map((val: any) => {
                let obj = {
                  category: val.year.toString(),
                  value: val.area
                }
                return obj
              })
            }
          }) : [];

          
          // this.selectedGrossLandCoveredChart = res.data ? previouseSelected ? previouseSelected : this.grossLandCoveredChart.filter(item => item.checked): [];
          this.selectedGrossLandCoveredChart = res.data ? this.grossLandCoveredChart.filter(item => item.checked): [];
          this.selectedGrossLandCoveredChartName = res.data? this.selectedGrossLandCoveredChart.map(item => item.label).join(","): '';
          this.grossLandCoveredLoading = false;
        },
        error: (error: any) => {
          this.grossLandCoveredLoading = false;
          this.grossLandCoveredChart = [];
          this.selectedGrossLandCoveredChart = [];
          this.selectedGrossLandCoveredChartName = '';
        }
      }
    )
  }

  
  barSortData(data: any) {
    let overviewArray: any = Object.entries(data);
    return overviewArray.sort((a: any, b: any) => b[1].category - a[1].category);
  }

  getOverviewSubCategory() {
    this.graphLoader = true;
    this.warehouseInventoryService.getOverviewSubCategory().subscribe(
      {
        next: (res: any) => {
          this.graphLoader = false;
          if (res.status === 200) {
            this.overViewCategory = { ...res.data[0] };

            let builtUpArea: any = { ...this.reportService.getPercentageOverview(this.overViewCategory.builtup_area) };
            this.overviewbuiltUpArea = this.barSortData(builtUpArea);

            let total_land_covered: any = this.reportService.getPercentageOverview(this.overViewCategory.total_land_covered);
            this.overviewTotalLandCovered = this.barSortData(total_land_covered);

            let total_warehouses: any = this.reportService.getPercentageOverview(this.overViewCategory.total_warehouses);
            this.overviewTotalWarehouse = this.barSortData(total_warehouses);

          } else {
            this.overViewCategory = null;
            this.overviewTotalLandCovered = [];
            this.overviewbuiltUpArea = [];
            this.overviewTotalWarehouse = [];
          }
        },
        error: (error: any) => {
          this.graphLoader = false;
          this.overViewCategory = null;
        }
      }
    )
  }

  getNeighbourhood() {
    this.neighbourhoodLoading = true;
    this.warehouseInventoryService.getNeighbourhood(this.warehouseInventoryService.neighbourhoodPayload).subscribe(
      {
        next: (res: any) => {
        
          this.neighbourhoodLoading = false;
          this.neighbourhooddata = res.status === 200 ? res.data : [];
          if(this.warehouseInventoryService.neighbourhoodPayload.pageNumber == 0){
            this.neighbourhoodCount = res.status === 200 ? res.count : 0;
            this.overViewCount(WarehouseInventoryMenuEnum.neighborhood, res.status === 200 ?  this.reportService.formatInternationNum(this.neighbourhoodCount) : 0)
          }
        },
        error: (error: any) => {
          this.neighbourhoodLoading = false;
          this.neighbourhooddata = [];
          this.neighbourhoodCount = 0;
          this.overViewCount(WarehouseInventoryMenuEnum.neighborhood, 0)
          
        }

      }
    )
  }

  overViewCount(type: number, count: number){
    this.overViewMenu.map((e:any)=>{
      if(e.name == type){
        e['count'] = count;
      }
    })
  }

  getProperties() {
    this.propertiesLoading = true;
    this.warehouseInventoryService.getProperties(this.warehouseInventoryService.propertiesPayload).subscribe(
      {
        next: (res: any) => {

          this.propertiesLoading = false;
          this.propertiesData = res.status === 200 ? res.data : [];
          if(this.warehouseInventoryService.propertiesPayload.pageNumber == 0){
            this.propertiesCount = res.status === 200 ? res.count : 0;
            this.overViewCount(WarehouseInventoryMenuEnum.properties, res.status === 200 ?  this.reportService.formatInternationNum(this.propertiesCount) : 0)
          }

          
        },
        error: (error: any) => {
          this.propertiesData = [];
          this.propertiesCount = 0;
          this.propertiesLoading = false;
        
          this.overViewCount(WarehouseInventoryMenuEnum.properties, 0)
        }

      }
    )
  }


  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }

  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.reportService.downloadReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=warehouse_inventory`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.warehouseInventoryMenuEnum.properties) {
        this.warehouseInventoryService.propertiesPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getProperties();

      } else {

        this.warehouseInventoryService.neighbourhoodPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getNeighbourhood();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.warehouseInventoryMenuEnum.properties) {
      this.warehouseInventoryService.propertiesPayload.pageNumber = 0;
      this.warehouseInventoryService.propertiesPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getProperties();

    } else {
      this.warehouseInventoryService.neighbourhoodPayload.pageNumber = 0;
      this.warehouseInventoryService.neighbourhoodPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getNeighbourhood();
    }
  }

  selectChart(e: any, item: any) {

    

    this.grossBuiltupChartLoading = true;
    this.selectedGrossBuiltupChart = this.grossBuiltupChart.filter(item => item.checked);
    if(this.selectedGrossBuiltupChart.length === 0){
      this.alertService.warn(this.translate.instant('atLeastOneMust'));
      item.checked = true;
      e.source.checked = true;
    }
    if(this.selectedGrossBuiltupChart.length > 3){
      item.checked = false;
      e.source.checked = false;
      this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
    }
    this.selectedGrossBuiltupChart = this.grossBuiltupChart.filter(item => item.checked);
    this.selectedGrossBuiltupChartName = this.selectedGrossBuiltupChart.map(item => item.label).join(",");
    this.grossBuiltupChartLoading = false;
  }

  selectLandCoveredChart(e: any, item: any) {
    this.grossLandCoveredLoading = true;
    this.selectedGrossLandCoveredChart = this.grossLandCoveredChart.filter(item => item.checked);
    if(this.selectedGrossLandCoveredChart.length === 0){
      item.checked = true;
      e.source.checked = true;
      this.alertService.warn(this.translate.instant('atLeastOneMust'));
    }
    if(this.selectedGrossLandCoveredChart.length > 3){
      item.checked = false;
      e.source.checked = false;
      this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
    }
    this.selectedGrossLandCoveredChart = this.grossLandCoveredChart.filter(item => item.checked);
    this.selectedGrossLandCoveredChartName = this.selectedGrossLandCoveredChart.map(item => item.label).join(",");
    this.grossLandCoveredLoading = false;
  }


  selectedYear(data: any) {
    if (data.reportType === 'neighborhood') {
      this.warehouseInventoryService.neighbourhoodPayload.pageNumber = 0;
      this.warehouseInventoryService.neighbourhoodPayload.year = data.year;
      this.neighbourhoodCount = 0;
      this.getNeighbourhood();
    } else {
      this.warehouseInventoryService.propertiesPayload.pageNumber = 0;
      this.warehouseInventoryService.propertiesPayload.year = data.year;
      this.propertiesCount = 0;
      this.getProperties();
    }
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  ngOnDestroy() {
    this.storeService.clear(this.grossChartOption);
    this.storeService.clear(this.grossLandCoveredOption);
  }

}
