import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";

import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { GlobalContants } from "../constants/global.contants";
import { StoreService } from "./store.service";
import { LoaderType } from "../models/enums/common.enum";

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    baseEndPoint = environment.miUrl;
    isBrowser: boolean;
    userToken: string = '';
    placeId: string = '';
    CurrentLang: any = ''
    CurrentLangCode: any = 'en';
    city_Code: string = '';
    allowResources: any;

    constructor(
        private http: HttpClient,
        private storeKey: StoreService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
    getAddressByLatlon(lat:any, lon:any) {
        return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyAmnH9DzaMJXdNw2TzgZ18RydRBDIhWHUo`)
    }
    public storeInfo(){
        if(this.isBrowser){
            this.userToken = this.storeKey.get(GlobalContants.StoreKey.userToken) || environment.user_token;
            this.placeId = this.storeKey.get(GlobalContants.StoreKey.PlaceId) || '';
            this.CurrentLang = this.storeKey.get(GlobalContants.StoreKey.CurrentLangID) || 1;
            this.CurrentLangCode = this.storeKey.get(GlobalContants.StoreKey.CurrentLang) || 'en';
            this.city_Code = this.storeKey.get(GlobalContants.StoreKey.placeCode) || '';
        }
    }

    public getLanguage<T>(url: string, umsUrl: boolean = false, loader: string = LoaderType.skip): Observable<T> {
        let headers: any = {};
        headers['Content-Type']= 'application/json';
        headers['X-Loader-Interceptor'] = loader;
        headers['app-id'] = environment.umsApp_id;
        headers['user-token'] = environment.user_token;
        headers['City-Id'] = this.placeId ? this.placeId :'';
        headers['User-Lang']= this.CurrentLangCode;
        headers['City-Code']=this.city_Code

        let options = { headers: new HttpHeaders(headers)};
        return this.http.get<T>(url, options);
    }
    public get<T>(url: string, umsUrl: boolean = false, loader: string = LoaderType.skip): Observable<T> {
        let headers: any = {};
        headers['Content-Type']= 'application/json';
        headers['X-Loader-Interceptor'] = loader;

        headers = umsUrl ? {...headers, ...this.umsHeaders()} : {...headers, ...this.miHeaders()};

        let options = { headers: new HttpHeaders(headers)};
        url = `${url}`;
        return this.http.get<T>(url, options);
    }

    public post<T>(url: string, body: any = '',  umsUrl: boolean = false, loader: string = LoaderType.skip): Observable<T> {
        let headers: any = {};
        headers['X-Loader-Interceptor'] = loader;

        headers = umsUrl ? {...headers, ...this.umsHeaders()} : {...headers, ...this.miHeaders()};

        let options = { headers: new HttpHeaders(headers)};
        url = `${url}`;
        return this.http.post<T>(url, body, options);
    }
    public requestDemoPost<T>(url: string, body: any = '',  placeId:string): Observable<T> {
        this.storeInfo();
        let headers: any = {};
        headers['X-Skip-Loader-Interceptor'] = '1';
        headers['api-key'] = environment.api_key;
        headers['user-token'] = this.userToken ? this.userToken : environment.user_token;
        headers['User-Lang']= this.CurrentLang;

        let options = { headers: new HttpHeaders(headers)};
        url = `${url}`;
        return this.http.post<T>(url, body, options);
    }

    public contactUsPost<T>(url: string, body: any = '',  placeId:string): Observable<T>{
        this.storeInfo();
        let headers: any = {};
        headers['X-Skip-Loader-Interceptor'] = '1';
        headers['api-key'] = environment.api_key;
        headers['user-token'] = environment.user_token;
        headers['User-City'] = placeId;
        headers['User-Lang']= this.CurrentLang;

        let options = { headers: new HttpHeaders(headers)};
        url = `${url}`;
        return this.http.post<T>(url, body, options);
    }

    public patch<T>(url: string, body: any = '', umsUrl: boolean = false, loader: string = LoaderType.skip): Observable<T> {
        let headers: any = {};
        headers['X-Loader-Interceptor'] = loader;


        headers = umsUrl ? {...headers, ...this.umsHeaders()} : {...headers, ...this.miHeaders()};

        let options = { headers: new HttpHeaders(headers)};
        url = `${url}`;
        return this.http.patch<T>(url, body, options);
    }

    public delete<T>(url: string, umsUrl: boolean = false, loader: string = LoaderType.skip): Observable<T> {
        let headers: any = {};
        headers['X-Loader-Interceptor'] = loader;


        headers = umsUrl ? {...headers, ...this.umsHeaders()} : {...headers, ...this.miHeaders()};

        let options = { headers: new HttpHeaders(headers)};
        url = `${url}`;
        return this.http.delete<T>(url, options);
    }

    public deleteWithBody<T>(url: string, request: any = '', umsUrl: boolean = false, loader: string = LoaderType.skip, headers?: any): any {
        const obj: any = {};
        headers = {};
        (obj as any).body = request;
        headers['X-Loader-Interceptor'] = loader;

        if(headers) { (obj as any).headers = headers; }
        headers = umsUrl ? {...headers, ...this.umsHeaders()} : {...headers, ...this.miHeaders()};
        url = `${url}`;
        return this.http.delete<T>(url, obj);
    }

    public umsHeaders(): any{
        this.storeInfo();
        let headers: any = {};
        headers['app-id'] = environment.umsApp_id;
        headers['user-token'] = this.userToken ? this.userToken : environment.user_token;
        headers['City-Id'] = this.placeId ? this.placeId :'';
        headers['User-Lang']= this.CurrentLangCode;
        headers['City-Code']=this.city_Code
        return headers;
    }

    public miHeaders(): any{
        this.storeInfo();
        let headers: any = {};
        headers['api-key'] = environment.api_key;
        headers['user-token'] = this.userToken;
        headers['User-City'] = this.placeId;
        headers['User-Lang']= this.CurrentLang;
        headers['City-Code']=this.city_Code
        return headers;
    }

    // getting allow resources
    getAllowResources(user_id:number) {
        let url = `${environment.umsUrl}user/resource?place_id=${this.storeKey.get(GlobalContants.StoreKey.PlaceId)}&user_id=${user_id}&status=1`
        this.get(url, true).subscribe({
            next: (res:any)=>{
                this.allowResources = res.data;
            },
            error:(err)=>{
                this.allowResources = [];
            }
        })
    }


    //
    checkResourceAccess(name:string) {
        let resource = {
        GET: false,
        PATCH: false,
        POST: false,
        DELETE: false
        }

        let resources = this.allowResources;
        if(resources && resources.length){
            resources.forEach((element:any) => {
                if (element.resource_name == name) {
                if (element.methods.indexOf('GET') != -1) {
                    resource.GET = true;
                }
                if (element.methods.indexOf('POST') != -1) {
                    resource.POST = true;
                }
                if (element.methods.indexOf('PATCH') != -1) {
                    resource.PATCH = true;
                }
                if (element.methods.indexOf('DELETE') != -1) {
                    resource.DELETE = true;
                }
                }
            });
        }

        return resource;
    }

}
