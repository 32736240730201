import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() saveFilter !: any;
  @Input() isCheckbox: boolean = true;
  @Input() isDelete: boolean = true;
  @Input() isViewAll: boolean = true;
  @Input() isGenReport: boolean = true;
  /* @Input() master_filter_list: any = []; */

  @Output() genReport = new EventEmitter();
  @Output() deleteFilter = new EventEmitter();
  @Output() viewFilter = new EventEmitter();

  lang: any;

  constructor(
    private reportService: ReportService,
    public sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lan => {
      if (lan) { this.lang = this.sharedService.getCurrentLangID() }
    })
  }
  ngOnInit() {
  }
  get entries() {
    if(this.saveFilter.data.filter){
      return Object?.entries(this.saveFilter.data.filter);
    }
    return [];
  }

  generateReport(value: any) {
    this.genReport.emit(value)
  }

  /* getFilterLabel(key: string) {
    return this.reportService.getParameterLabel(key, this.master_filter_list)
  } */

  // getValues(val:any){
  //   return val.replaceAll(',', ', ')
  // }

  deleteSavedFilter(filter: any) {
    this.deleteFilter.emit(filter);
  }

  viewAllFilter(filter: any) {
    this.viewFilter.emit(filter)
  }

  selectFilter(filter_id: string) {
    const index = this.sharedService.savedFilter.indexOf(filter_id);
    if (index === -1) {
      this.sharedService.savedFilter.push(filter_id);
    }
    else {
      this.sharedService.savedFilter.splice(index, 1);
    }
  }
  getLabel(val: any) {
    return val[this.lang] ? val[this.lang] : val['1']
  }
}
