<div class="mapLayer-panel">
    <div class="mapLayer-panel_header">
        <div class="filter-title">
          <b>{{'reports.filters'| translate}}</b>
        </div>
        <mat-icon (click)="layerToggle()" class="close-icon">close</mat-icon>
     </div>
     <div class="mapLayer-panel_body" [ngStyle]="{'height': !applybtnlock || atLeastOneSubsLock ? 'calc(100% - 200px)' : 'calc(100% - 150px)'}">
        <ng-container *ngIf="filterList.length">
            <form [formGroup]="reportFilterForm" class="filter-form">
                <ng-container *ngFor="let filter of filterList">
                    <div *ngIf="filter.type == 'single_select' || filter.type == 'multi_select'">
                        <div class="position-relative" id="{{filter.parameter_name}}">
                            <ng-container>
                                <app-multi-select
                                [Disabled]="(isPolygon && filter.parameter_name == 'neigh_name') ? true: false"
                                [required]="filter.required"
                                [name]="filter.title"
                                [filterData]="filter"
                                [query_params]="queryParams"
                                formGroupName="{{filter.parameter_name}}" [selectAllSubscription]="filter?.isSubscription" [atLeastOneSubscription]="filter?.atLeastOneSubscription"></app-multi-select>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container>
                       
                    </ng-container>
                    <div *ngIf="filter.type == 'range'">
                        <app-min-max-slider [isSubscription]="filter?.isSubscription" [required]="filter.required" [name]="filter.title" [sliderValue]="SilderValue[filter.parameter_name]"
                        [floorMin]="0" [floorMax]="getMaxValue(filter.default)"
                        formGroupName="{{filter.parameter_name}}"></app-min-max-slider>
                    </div>
                    <div *ngIf="filter.type == 'form'">
                       
                        <app-amenties-filter-form [name]="filter.title" formGroupName="{{filter.parameter_name}}" [amentiesSelected]="amentiesSelected" [filterData]="filter"></app-amenties-filter-form>
                    </div>
                    <div *ngIf="filter.type == 'text'">
                        <app-form-text [required]="filter.required" [name]="filter.title" [data]="filter.textval" formGroupName="{{filter.parameter_name}}"></app-form-text>
                    </div>
                    <div *ngIf="filter.type == 'date_range'">
                        <app-date-range [isSubscription]="filter?.isSubscription" [required]="filter.required" [name]="filter.title"
                        formGroupName="{{filter.parameter_name}}"></app-date-range>
                    </div>
                </ng-container>
            </form>
        </ng-container>
    </div>
   
    <div class="action-filter" [class.sublock-filtergrp]="!applybtnlock || atLeastOneSubsLock">
            <div class="sublock-filters" *ngIf="!applybtnlock || atLeastOneSubsLock">
                <ng-container *ngIf="LockReport">
                    <i class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
                    <p><strong>{{'premiumPackage' | translate}}</strong></p>
                    <p><a (click)="openContactSales()">{{'contactSales' | translate}}</a> {{'toSubscribe' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="!LockReport && atLeastOneSubsLock">
                    <i class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
                    <p><strong>{{'Lockedfunctionalities' | translate}}</strong></p>
                    <p>{{'InorderToSubscribe' | translate}} <a (click)="openContactSales()">{{'contactSales' | translate}}</a></p>
                </ng-container>
            </div>
            <button [disabled]="!applybtnlock" class="apply-filter" (click)="applyFilter(reportFilterForm.value)" type="button">{{'reports.apply'|translate}}</button>
            <button [disabled]="!applybtnlock" class="reset-filter" (click)="resetFilter()">{{'reports.reset_filter' | translate}}</button>
    </div>
</div>
