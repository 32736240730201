export const IconPath = {
    male: `../../../assets/images/Male_normal.svg`,
    female: `../../../assets/images/Female_normal.svg`,
    fileIcon: `../../../assets/images/FileIcon.svg`,
    deleteIcon:`../../../assets/images/DeleteIcon.svg`,
    downloadIcon: `../../../assets/images/DownloadIcon.svg`,
    filterIcon: `../../../assets/images/FilterIcon.svg`,
    layoutIcon: `../../../assets/images/LayoutIcon.svg`,
    removeFolderIcon: `../../../assets/images/RemoveFolderIcon.svg`,
    shareIcon: `../../../assets/images/ShareIcon.svg`,
    sortIcon: `../../../assets/images/SortIcon.svg`,
    saveIcon: `../../../assets/images/saveIcon.svg`,
    searchIcon: `../../../assets/images/search.svg`,
    reportFilterIcon: `../../../assets/images/filter_icon.svg`,
    layerIcon:`../../../assets/images/layer_icon.svg`,
    vector: `../../../assets/images/Vector.svg`,
    group: `../../../assets/images/group.svg`,
    security: `../../../assets/images/security.svg`,
    creditCard: `../../../assets/images/credit-card.svg`,
    rename : `../../../assets/images/rename-icon.svg`,
    verticalEllipsis: `../../../assets/images/verticle-ellipsis.svg`,
    duplicateIcon: `../../../assets/images/duplicate-icon.svg`,
    arrowDown: `../../../assets/images/arrowDown.svg`,
    bookmark: `../../../assets/images/bookmark.svg`,
    bookmarkWhite: `../../../assets/images/bookmark-white.svg`,
    restartIcon: `../../../assets/images/restartIcon.svg`,
    markets: `../../../assets/images/dashboard-icon.svg`,
    minmenu: `../../../assets/images/mic-menu.svg`,
    description : `../../../assets/images/description.svg`,
    micMenu : `../../../assets/images/micMenu.svg`,
    location : `../../../assets/images/pan_to_map_2.svg`,
    floorLayoutIcon : `../../../assets/images/floorLayoutIcon.svg`,
    upArrow : `../../../assets/images/upArrow.svg`

}
