import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';

@Component({
  selector: 'app-reports-container',
  templateUrl: './reports-container.component.html',
  styleUrls: ['./reports-container.component.scss']
})
export class ReportsContainerComponent {

  @Input() tableColumn: any;
  @Input() isDownloadExcel: boolean = false;
  @Input() dataValue: any;
  @Input() reportType: number = ReportsChartEnum.tableView;
  @Input() loading: boolean = false;
  @Input() title: string = '';
  @Input() chartName: string = '';
  @Input() reportName: string = '';
  @Input() expandable: boolean = false;

  
  @Input() height = '300px';
  @Input() legend: any = [];
  @Input() isBorder: boolean=true;

  @Input() yearDropdown: boolean = false;
  @Input() yearReportType: string = '';
  @Output() yearSelected = new EventEmitter<any>();
  

  tableView = ReportsChartEnum.tableView;
  pieChart = ReportsChartEnum.pieChart;


  selectedYear(year: any): void {
    
    this.yearSelected.emit(year);
  }

}

