import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoader$ = new BehaviorSubject('1');
  isReportLoader$ = new BehaviorSubject(true);
  constructor() { 
    this.hideLoader();
    this.hideReportLoader();
  }

  showLoader(type: string){
    this.isLoader$.next(type);
  }

  hideLoader(){
    this.isLoader$.next('1');
  }

  showReportLoader(){
    this.isReportLoader$.next(true);
  }

  hideReportLoader(){
    this.isReportLoader$.next(false);
  }
}
