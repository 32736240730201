
<app-property-details-overview [Property_Uid]="propertyInfo?.property_uid"></app-property-details-overview>
<!--Property Information-->
<div class="property-infod">
    <div class="property-information">
        <h2 class="detail-heading">{{'reports.property_information' | translate}}<i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionShows' | translate"></i></h2>

        <div class="info"  *ngIf="propertyInfomation.length > 0; else noDataMessage">
            <ng-container  *ngFor="let info of propertyInfomation">
                <div class="p-info" *ngIf="info.value">
                    <h6>{{ info.name | translate}}</h6>
                    <h3>{{ info.value | number : '1.0-1'}}<ng-container *ngIf="info.name == 'FAR'">%</ng-container>
                        <sup *ngIf="info.name == 'Leasable Area'">{{unitType | translate}}</sup>
                    </h3>
                </div>
            </ng-container>

        </div>
        <ng-template #noDataMessage>
            <div class="data_unavail">
                <span>
                    {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                </span>
            </div>
        </ng-template>


    </div>

    <!-- <div class="numberofmax">
        <h2 class="detail-heading">Number of Maximum Permitted Floors <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" mamatTooltip="This section displays the maximum number of floors allowed for construction on this property, along with a breakdown of different floor types permitted, according to local municipal guidelines."></i></h2>
    </div> -->
</div>
