<div class="report-container occupancy-rental" [class.display-none]="showPropertyDetails">
    <!-- Menu -->
    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <!-- Overview -->
    <ng-container *ngIf="overviewLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [height]="'150px'"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ occupancyRentalMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === occupancyRentalMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overviewLoading">
            <app-overview-card
                [card_title]="sharedService.priceCalculation$() === sharedService.priceYearly ? 'reports.AnnuallyAverageRent' : 'reports.averageRent'"
                [total_current]="averageData?.avg_rent" [untillDate]="averageData?.untillDate"
                [total_prev]="averageData?.prev_avg_rent" [total_percentage]="averageData?.totalaverage_rate"
                [uniType]="UnitTypeEnum.currencywithsqm"
                [graphData]="{data: averageGradeData, type: 'averageRentRate', loader: overviewGradeLoading}"></app-overview-card>

            <app-overview-card [card_title]="'reports.occupancyRate'" [total_current]="occupancyData?.occupacy_rate"
                [untillDate]="occupancyData?.untillDate" [total_prev]="occupancyData?.prev_occupacy_rate"
                [total_percentage]="occupancyData?.totaloccupancy_rate" [uniType]="UnitTypeEnum.percentage"
                [graphData]="{data: occupancyGradeData, type: 'occupancyRate', loader: overviewGradeLoading}"></app-overview-card>
        </div>
        <!-- Rent Rate Graph -->
        <div class="report-model report-rentrate" *ngIf="overviewChartData">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ? ('reports.annual_rent_rate_in' | translate) : ('reports.monthly_rent_rate_in' | translate)}} <span class="text-capitalize">{{(placeName |
                            lowercase) | translate}}</span> <!-- {{'reports.the_past_few_years' | translate}} -->
                        ({{CurrencyCode
                        | translate}}/{{'reports.sqm_unit' | translate}})
                    </h3>
                </div>
                <button class="btn-select-grade" [matMenuTriggerFor]="menu"
                    *ngIf="(retailRentalChartReport | json) != '{}'">
                    <div class="setext">
                        <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l"
                                style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"
                    class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let category of getReportKeys()">
                            <mat-checkbox (change)="selectChart($event, category)"
                                [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>
            <ng-container *ngIf="overviewGraphLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!overviewGraphLoading">
                <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                    <!-- {{retailRentalChartReport.length}} -->
                    <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                        <app-report-charts class="grchart" [data]="chart.value" [chartType]="chartView.smallBarChart"
                            [isBorder]="false" [name]="chart.key" [index]="i"
                            [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '300px'}">
                        </app-report-charts>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon role="img"
                                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                                aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>

    
    <!-- Residential Units - Type wise -->
    <div class="report-tabsection"
        [ngClass]="{ 'scroll': currentScroll === occupancyRentalMenuEnum.residentialUnitsTypeWise}"
        id="{{ occupancyRentalMenuEnum.residentialUnitsTypeWise }}">
        <app-report-year-dropdown [reportType]="'residentialType'"
        (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.residential_units_type_wise' | translate}}</h3>
                    <span class="totalTransaction">({{ residentialTypeCount ?
                        reportService.formatInternationNum(residentialTypeCount) : residentialTypeCount}})</span>
                </div>
            </div>
            <app-report-datatable class="occrental-table" [showLoader]="!residentialTypeLoading" [ngClass]="{'activeTable': tableName == occupancyRentalMenuEnum.residentialUnitsTypeWise}"
            (lastClickedTable)="lastClickedTable($event, occupancyRentalMenuEnum.residentialUnitsTypeWise)"
                [tableColumns]="residentialTypeColumns" [dataLength]="residentialTypeCount"
                [tableData]="residentialTypeData"
                (pageEvent)="onPageEvent($event, occupancyRentalMenuEnum.residentialUnitsTypeWise)"
                (sort)="sortData($event, occupancyRentalMenuEnum.residentialUnitsTypeWise)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'ir_occupancy_residential_unit_type_wise', 'b')"
                [downloadLoading]="downloadLoading['b']" [expandColumn]="true"
                [tableExpandColumns]="residentialTypeExpandColumns">
            </app-report-datatable>
        </div>
    </div>
    
    <!-- Commercial Units - Size wise -->
    <div class="report-tabsection"
        [ngClass]="{ 'scroll': currentScroll === occupancyRentalMenuEnum.commercialUnitsSizeWise}"
        id="{{ occupancyRentalMenuEnum.commercialUnitsSizeWise }}">
        <app-report-year-dropdown [reportType]="'commercialSize'"
        (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.commercial_units_size_wise' | translate}}</h3>
                    <span class="totalTransaction">({{ commercialSizeCount ?
                        reportService.formatInternationNum(commercialSizeCount) : commercialSizeCount}})</span>
                </div>
            </div>
            <app-report-datatable class="occrental-table" [showLoader]="!commercialSizeLoading" [ngClass]="{'activeTable': tableName == occupancyRentalMenuEnum.commercialUnitsSizeWise}"
            (lastClickedTable)="lastClickedTable($event, occupancyRentalMenuEnum.commercialUnitsSizeWise)"
                [tableColumns]="commercialSizeColumns" [dataLength]="commercialSizeCount"
                [tableData]="commercialSizeData"
                (pageEvent)="onPageEvent($event, occupancyRentalMenuEnum.commercialUnitsSizeWise)"
                (sort)="sortData($event, occupancyRentalMenuEnum.commercialUnitsSizeWise)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'ir_occupancy_commercial_unit_size_wise', 'd')"
                [downloadLoading]="downloadLoading['d']" [expandColumn]="true"
                [tableExpandColumns]="commercialSizeExpandColumns">
            </app-report-datatable>
        </div>
    </div>

</div>