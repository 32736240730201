<!--Amenities-->

<div class="amentities detail-info">
    <div class="info">
        <h2 class="detail-heading">{{'reports.amenities' | translate}}</h2>
        <span class="totalTransaction" *ngIf="count">({{count}})</span>
    </div>
    <div class="amenities" *ngIf="items.length > 0; else noDataMessage">
        <div class="cols" *ngFor="let item of items">
           <ng-container *ngIf="item">
            <img [src]="'assets/images/amenities/'+item+'.png'" alt=""
            onerror="this.src='assets/images/amenities/ar_stickers.png'">
            <span>{{ item }}</span>
           </ng-container>
        </div>
    </div>
    <ng-template #noDataMessage>
        <div class="data_unavail">
            <span>
                {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
            </span>
        </div>
    </ng-template>
</div>
<!--Amenities-->