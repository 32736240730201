import { Component, Inject, effect, PLATFORM_ID, Input, Output, EventEmitter } from '@angular/core';
import { LanguagesEnum } from 'src/app/common/models/enums/common.enum';
import { SharedService } from '../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-outer-header',
  templateUrl: './outer-header.component.html',
  styleUrls: ['./outer-header.component.scss']
})

export class OuterHeaderComponent {
  @Input() landingheader: any = "";
  @Output() languageChange = new EventEmitter()
  isMenuOpen= false;

  isBrowser: any;
  currentLangID: number = LanguagesEnum.english;
  langList!:number;
  isMobile: boolean = false;
  opened: boolean | undefined;
  languageList:any;

  constructor(private sharedService: SharedService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.setLang();
      effect(()=> {
        this.languageList = this.sharedService.languageList()
        this.langList = this.sharedService.languageList()?.length
    });
    }
  }
  ngOnInit(): void {
    this.checkIfMobile();
  }
  returnVal(lanId:number){
    if(lanId == LanguagesEnum.arabic && this.languageList.indexOf('en') > -1){
      return true;
    }
    if(lanId == LanguagesEnum.english && this.languageList.indexOf('ar') > -1){
      return true;
    }
    return false;
  }
  checkIfMobile(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  setLang(): void{
    this.currentLangID = this.sharedService.getCurrentLangID() ? this.sharedService.getCurrentLangID() : LanguagesEnum.english;
    this.sharedService.language$.subscribe((lang : any) => {
      this.translate.use(lang);
    })
  }
  selectLang(lang:any){
    this.currentLangID = lang;
    this.sharedService.setLanguage(lang)
    this.languageChange.emit(this.currentLangID+'');
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
