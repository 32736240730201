import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ReportPropertyDetailConstant } from './report-property-detail.constant';
import { SharedService } from 'src/app/common/services/shared.service';
import { PropertyDetailMenuEnum } from './report-property-detail.enum';
import { ReportPropertyDetailService } from './report-property-detail.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportService } from 'src/app/reports/services/report.service';
import { Subscription } from 'rxjs';
import { ReportsViewEnum } from 'src/app/reports/models/enums/reports.enum';

@Component({
  selector: 'app-report-property-detail',
  templateUrl: './report-property-detail.component.html',
  styleUrls: ['./report-property-detail.component.scss']
})
export class ReportPropertyDetailComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {

  @Input() propertyInfo: any;
  propertyDetail: any = null;
  currentReportView: number = ReportsViewEnum.mapView;

  overViewMenu = ReportPropertyDetailConstant.overViewMenu;
  currentScroll: any = PropertyDetailMenuEnum.overview;;

  propertyDetailMenuEnum = PropertyDetailMenuEnum;
  reportName: string = '';
  currentLang: string = '';
  languageSub$: Subscription = new Subscription();
  currentReportView$: Subscription = new Subscription();
  @ViewChild('navBar') navBar: ElementRef | any;


  constructor(
    private sharedService: SharedService,
    private reportPropertyDetailService: ReportPropertyDetailService,
    public reportService: ReportService,

  ) { }

  ngOnInit(): void {
    this.setLang();
    this.getReportView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reportPropertyDetailService.propertyInfo = this.propertyInfo;
    this.reportPropertyDetailService.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.reportPropertyDetailService.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.reportPropertyDetailService.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.scrollPoint(String(this.propertyDetailMenuEnum.overview));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollPoint(String(this.propertyDetailMenuEnum.overview));
    }, 0);
  }


  setCount(count: any, heading: number) {
    this.overViewMenu.map((e: any) => {
      if (e.name == heading) {
        e['count'] = this.reportService.formatInternationNum(count) || 0;
      }
    })
  }

  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
    })
  }

  getReportView() {
    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      this.currentReportView = view;
    });
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  backToTransaction() {
    this.reportService.isDetailPageOpen = false;
    this.reportService.transactionPropertyDetails.next(false);
  }

  ngOnDestroy(): void {
    this.reportPropertyDetailService.propertyInfo = null;
    this.languageSub$?.unsubscribe()
    this.currentReportView$?.unsubscribe()
  }


}
