<div class="landing-page">
  <app-outer-header class="container-fluid static" [landingheader]="true"></app-outer-header>

  <!-- Unleashed Real Estate Potential -->
  <section class="container-fluid">
    <section class="Unleashed-Real-Estate">
      <div class="content">
        <h1>{{'landing.banner_heading' | translate}}</h1>
        <p>
          {{'landing.banner_para' | translate}}
        </p>
        <div class=" btnsec">
          <a [routerLink]="['/sign-up']" class="free-trail">{{'landing.free_trial' | translate}}</a>
          <a [routerLink]="['/request-demo']" class="live-demo">{{'landing.request_a_live_demo' | translate}}</a>
        </div>
        <div class="statistics-container">
          <div class="statistic">
            <div class="number">{{'landing.500M' | translate}}</div>
            <div class="description">{{'landing.unique_data' | translate}}</div>
          </div>
          <div class="statistic">
            <div class="number">{{'landing.6M' | translate}}</div>
            <div class="description">{{'landing.properties_covered' | translate}}</div>
          </div>
          <div class="statistic">
            <div class="number">{{'landing.75' | translate}}</div>
            <div class="description">{{'landing.data_points' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="image">
        <div class="play-button" (click)="openVideoModal('yoXHGa8HP0c')">
          <img src="assets/images/landing_page/youtubeplay.svg" alt="Play" />
        </div>
        <img *ngIf="!isMobile && currentLangID == 1" src="assets/images/landing_page/Unleashed.png"
          alt="Person holding a tablet with graphs" />
        <img *ngIf="!isMobile && currentLangID == 2" src="assets/images/landing_page/unleashed-arabic.png"
          alt="Person holding a tablet with graphs" />

          <img *ngIf="isMobile && currentLangID == 1" src="assets/images/landing_page/Unleashed-mobile.svg"
          alt="Mobile view of the tablet with graphs" />
        <img *ngIf="isMobile && currentLangID == 2" src="assets/images/landing_page/Unleashed-arabic-mobile.png"
          alt="Mobile view of the tablet with graphs" />
      </div>


    </section>
  </section>

  <section class="container-fluid" *ngIf="isMobile">
    <div class="sectors-covered">
      <h2>{{ 'landing.sectors_covered' | translate }}</h2>
      <div class="sectors-grid">
        <div class="sector-item">
          <span class="checkmark"></span> {{ 'landing.residential_sector' | translate }}
        </div>
        <div class="sector-item">
          <span class="checkmark"></span> {{ 'landing.industrial_sector' | translate }}
        </div>
        <div class="sector-item">
          <span class="checkmark"></span> {{ 'landing.investment_sector' | translate }}
        </div>
        <div class="sector-item">
          <span class="checkmark"></span> {{ 'landing.office_spaces' | translate }}
        </div>
        <div class="sector-item">
          <span class="checkmark"></span> {{ 'landing.retail_and_Restaurants' | translate }}
        </div>
        <div class="sector-item">
          <span class="checkmark"></span> {{ 'landing.hospitality_sector' | translate }}
        </div>
      </div>
    </div>
  </section>
  
  

  <!-- Trusted by section-->
  <section class="home_page container-fluid">
    <article class="trusted_by">
    <div class="trends_heading">
      <div class="heading">
        <!-- <h2 class="lang_trans text-center mb-3" [innerHtml]="'Home.they_trust_us'|translate"></h2> -->
        <h2 class="lang_trans text-center" [innerHtml]="'Home.trusted_by'|translate"></h2>
        <!-- <h2 class="lang_trans text-center mb-3">{{'Home.trusted_by' | translate}}</h2> -->
      </div>
    </div>
    <div class="scroll_container1">
      <ul>
        <li><img src="../../../../../../assets/images/trusted_by/neom.png" alt="Neom"></li>
        <li><img src="../../../../../../assets/images/trusted_by/ambanee.png" alt="Ambanee"></li>
        <li><img src="../../../../../../assets/images/trusted_by/alargan.svg" alt="Alargan"></li>
        <li><img src="../../../../../../assets/images/trusted_by/agility.svg" alt="Agility"></li>
        <li><img src="../../../../../../assets/images/trusted_by/wafra.png" alt="Wafra"></li>
      </ul>
    </div>
  </article>

  </section>

  <!-- Generate Thousands of Real Estate Reports in Minutes -->
  <section class="container-fluid">
    <article class="Generate-thousands">
    <h2 [innerHTML]="'landing.generate_thousands' | translate"></h2>
    <div class="container">
      <div class="column">
        <div class="report-card" *ngFor="let report of reports; let l = last" [ngClass]="{'get-started': report.isGetStarted}">
          <div class="report-icon" [routerLink]="[report.isGetStarted ? '/sign-up' : '']">
            <img [src]="report.imageSrc" [alt]="report.altText" [class.arrow-rotate]="l" />
          </div>
          <div class="report-details">
            <h3>{{ report.title | translate }}</h3>
            <p [innerHTML]="report.description | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </article>
  </section>

  <!-- Unlock a World of Possibilities Beyond Basic Reports -->
  <section class="container-fluid unlock-possiblity">
    <article class="unlock-a-world">
    <div class="container">
      <div class="text-container">
        <h2 [innerHTML]="'landing.unlock_a_world' | translate"></h2>
      </div>
      <div class="content-columns">
        <div class="report-details" *ngFor="let detail of unlockWorldDetails">
          <h3>{{ detail.title | translate}}</h3>
          <p>{{ detail.description | translate }}</p>
        </div>
      </div>
    </div>
  </article>
  </section>


  <!-- Only image is here -->
  <div class="img-container">
    <img *ngIf="!isMobile" src="assets/images/landing_page/Unlock-a-world.png" alt="Unlock a World" id="image">
    <img *ngIf="isMobile" src="assets/images/landing_page/Unlock-a-world-mobile.png" alt="Unlock a World" id="image">
  </div>

  <!-- Estater Markets Data Sources -->
  <section class=" container-fluid">
    <article class="Estater-Markets-Data-Sources">
    <div class="head-ing">
      <h2>{{'landing.data_sources' | translate}}</h2>
      <h3 [innerHTML]="'landing.we_gather_data' | translate"></h3>
      <!-- Use for desktop only -->
      <div class="grid" *ngIf="windowWidth > tabletScreenWidth; else tabletVisible;">
        <div class="card" [class.card-last]="l" *ngFor="let source of dataSources; let l = last">
          <div class="icon">
            <img [src]="source.icon" [alt]="source.alt">
          </div>
          <div class="card-content">
            <h4 [innerHTML]="source.title | translate"></h4>
            <p *ngIf="source.subtitle">{{ source.subtitle | translate }}</p>
            <p class="card-dis">{{ source.description | translate }}</p>
            <button *ngIf="source.title.includes('trial')" [routerLink]="['/sign-up']">{{'landing.get_started' |
              translate}}</button>
          </div>
        </div>
      </div>
      <!-- Use only mobile/tablet only -->
      <ng-template #tabletVisible>
        <owl-carousel-o [options]="slideConfig1" class="testimonial-carousel">
          <ng-template carouselSlide *ngFor="let source of dataSources; let l = last">
            <div class="card" [class.card-last]="l">
              <div class="icon">
                <img [src]="source.icon" [alt]="source.alt">
              </div>
              <div class="card-content">
                <h4>{{ source.title | translate }}</h4>
                <p *ngIf="source.subtitle">{{ source.subtitle | translate }}</p>
                <p class="card-dis">{{ source.description | translate }}</p>
                <button *ngIf="source.title.includes('trial')" [routerLink]="['/sign-up']">{{'landing.get_started' |
              translate}}</button>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </ng-template>
    </div>
  </article>
  </section>



  <!-- GIS: The Heart of Our Insights -->

  <section class="container-fluid">
    <article [ngClass]="{'the-heart-of-our-insights' : windowWidth > tabletScreenWidth}">

    <div class="gis-header">
      <h2 class="title-1">{{'landing.gis_the_heart' | translate}}</h2>
      <h3 class="title-2" [innerHTML]="'landing.our_5_Step' | translate"></h3>
    </div>
    <!-- Only use Tablet/Mobile -->
    <owl-carousel-o [options]="slideConfig1" class="testimonial-carousel" *ngIf="windowWidth <= tabletScreenWidth">
      <ng-template carouselSlide *ngFor="let step of gisSteps">
        <div class="step">
          <div class="step-number">{{ step.number }}</div>
          <div class="step-content">
            <h4>{{ step.title | translate }}</h4>
            <ul>
              <li *ngFor="let detail of step.details">{{ detail | translate }}</li>
            </ul>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <div class="gis-body">
      <div class="gis-body-img">
        <img *ngIf="!isMobile" src="assets/images/landing_page/GIS-The-Heart.png" alt="GIS Process Image">
        <img *ngIf="isMobile" src="assets/images/landing_page/GIS-The-Heart-mobile.png" alt="GIS Process Image">
      </div>

      <div class="gis-content">
        <div class="gis-body-content">
          <!-- Use for desktop only -->
          <ng-container *ngIf="windowWidth > tabletScreenWidth">
            <div class="step" *ngFor="let step of gisSteps">
              <div class="step-number">{{ step.number }}</div>
              <div class="step-content">
                <h4>{{ step.title | translate }}</h4>
                <ul>
                  <li *ngFor="let detail of step.details">{{ detail | translate }}</li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </article>
  </section>

  <!-- How We Process Property Data -->
  <section class="container-fluid ">
    <article class="data-process">
    <div class="property-heading">
      <h3 class="process-title" [innerHTML]="'landing.process_property_data' | translate"></h3>
    </div>
    <div class="data-process-section">
      <div class="data-process-content">
        <div class="process-item" *ngFor="let item of processItems; let i = index">
          <div class="item-header" (click)="toggleAccordion(i)">
            <h4>{{item.title | translate}}</h4>
            <span [ngClass]="{'open': item.isOpen}">
              <!-- {{item.isOpen ? '-' : '+'}} -->
              <svg *ngIf="item.isOpen; else plus" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="25.082" height="25.082" rx="12.541" transform="matrix(-1 0 0 1 25.082 0)" fill="#FF6E00"/>
                <g clip-path="url(#clip0_1231_6581)">
                <path d="M16.9304 12.541H8.15173" stroke="white" stroke-width="1.2541" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1231_6581">
                <rect width="15.0492" height="15.0492" fill="white" transform="matrix(-1 0 0 1 20.0657 5.01639)"/>
                </clipPath>
                </defs>
                </svg>
                <ng-template #plus>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-0.313525" y="0.313525" width="24.4549" height="24.4549" rx="12.2275" transform="matrix(-1 0 0 1 24.455 0)" stroke="#D7D8DB" stroke-width="0.627049"/>
                    <path d="M12.5411 8.15164V16.9303M16.9304 12.541H8.15173" stroke="#1D1F22" stroke-width="1.2541" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </ng-template>
                
                  
                
            </span>
          </div>
          <ul class="item-content" *ngIf="item.isOpen">
            <li *ngFor="let detail of item.details">{{detail | translate}}</li>
          </ul>
        </div>
      </div>

      <div class="data-process-image">
        <img *ngIf="!isMobile" src="assets/images/landing_page/DataCleanin.svg" alt="Arrow">
        <img *ngIf="isMobile" src="assets/images/landing_page/data-process-mobile.svg" alt="Arrow">
      </div>
    </div>
  </article>
  </section>


  <!-- Conduct 40 Real Estate Studies For the Cost of 1 in 60 seconds! -->
  <section class="container-fluid unlock-possiblity">
    <article class="promo-section">
    <div class="promo-container">
      <div class="promo-content">
        <h2>{{'landing.conduct_real_estate' | translate}} </h2>

        <button (click)="openVideoModal('C9tRP2Kbui4')" type="button" class="btn-get-started">
          {{'landing.lets_show' | translate}}
          <img src="assets/images/landing_page/arrow-up-right-sharp.svg" alt="Arrow" class="arrow-icon arrow-rotate" />
        </button>

        <a [routerLink]="['/request-demo']" class="promo-link">{{'landing.book_a_demo' | translate}} <span
            class="arrow">></span></a>
        <a [routerLink]="['/sign-up']" class="promo-link">{{'landing.signup_for_days' | translate}} <span
            class="arrow">></span></a>
      </div>
      <div class="promo-image">
        <img *ngIf="!isMobile" src="assets/images/landing_page/saved-search-applied.svg" alt="Real Estate Study Image">
        <img *ngIf="isMobile" src="assets/images/landing_page/saved-search-mobile.svg" alt="Real Estate Study Image">
      </div>
    </div>

    <!-- Video Modal -->
    <div *ngIf="isVideoModalOpen" class="video-modal">
      <div class="modal-content">
        <span class="close-button" (click)="closeVideoModal()">&times;</span>
        <iframe *ngIf="youtubeUrl" [src]="youtubeUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </article>
  </section>


  <!-- Real Estate Sectors We Cover-->
  <section class="sector-section container-fluid">
    <article class="sector-section">
    <div class="sector-container">
      <div class="sector-header">
        <p class="sector-subtitle">{{'landing.covering_all_facets' | translate}}</p>
        <h2 class="sector-title">{{'landing.real_estate_sectors' | translate}}</h2>
      </div>

      <!-- Use for desktop only -->
      <div class="sector-grid" *ngIf="windowWidth > tabletScreenWidth; else mobileVisible">
        <div class="sector-card" *ngFor="let sector of sectors">
          <img [src]="sector.imageSrc" [alt]="sector.altText" class="sector-image">
          <h3 class="sector-name">{{ sector.name | translate }}</h3>
          <p class="sector-description">{{ sector.description | translate }}</p>
        </div>
      </div>

      <!-- Use only mobile/tablet -->
      <ng-template #mobileVisible>
        <owl-carousel-o [options]="slideConfig1" class="sector-carousel">
          <ng-template carouselSlide *ngFor="let sector of sectors">
            <div class="sector-card">
              <img [src]="sector.imageSrc" [alt]="sector.altText" class="sector-image">
              <h3 class="sector-name">{{ sector.name | translate }}</h3>
              <p class="sector-description">{{ sector.description | translate }}</p>
            </div>
          </ng-template>
        </owl-carousel-o>
      </ng-template>
    </div>
  </article>
  </section>



  <!-- Trusted by 100s of real estate specialists -->
  <!-- <section class="testimonial-section container-fluid">
    <h2 class="testimonial-title" [innerHTML]="'landing.trusted_by' | translate"></h2>
    <owl-carousel-o [options]="slideConfig" class="testimonial-carousel">
      <ng-template carouselSlide *ngFor="let testimonial of testimonials">
        <div class="testimonial-card">
          <div class="testimonial-content">
            <div class="testimonial-user">
              <img src="assets/images/landing_page/JohnDoe_icon.png" alt="{{testimonial.name}}"
                class="testimonial-avatar">
              <div>
                <h3 class="testimonial-name">{{testimonial.name}}</h3>
                <p class="testimonial-company">{{testimonial.company}}</p>
              </div>
            </div>
            <p class="testimonial-text">{{testimonial.text}}</p>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </section> -->



  <!-- Footer -->
  <section class="container-fluid">
    <article class="footer-logo">
    <div class="header-container">
      <div class="logo">
        <img *ngIf="currentLangID == 1" src="assets/images/landing_page/footer-img.svg" alt="estater Logo">
        <img *ngIf="currentLangID == 2" src="assets/images/landing_page/arabic-logo.svg" alt="estater Logo" />
      </div>
    </div>
  </article>
  </section>

  <footer class="container-fluid">
    <div class="footer-position ">
      <ul class="footer-list footer_social">
        <li><a href="https://www.facebook.com/people/Estatercom/100070864224953/" target="_blank"><img
              src="assets/images/landing_page/facebook-logo.svg" alt="Facebook"></a></li>

        <li><a href="https://www.linkedin.com/company/estater/about/" target="_blank"><img
              src="assets/images/landing_page/linkedin.svg" alt="linkedin"></a></li>

        <li><a href="https://www.instagram.com/the.real.estater?igsh=dGFwOWl2cjc0emxk" target="_blank"><img
              src="assets/images/landing_page/social-media.svg" alt="Instagram"></a></li>

        <li><a href="https://www.youtube.com/@TheRealEstater" target="_blank"><img
              src="assets/images/landing_page/play.svg" alt="Youtube"></a></li>

        <li><a href="https://twitter.com/thereal_estater" target="_blank"> <img
              src="assets/images/landing_page/twitter.svg" alt="Twitter"></a></li>


      </ul>
      <div class="contact-info">
        <img src="assets/images/landing_page/mail.svg" alt="Email Icon">
        <span>{{'landing.contact_us' | translate}} <a href="mailto:sales@estater.com">{{'sales@estater.com'}}</a></span>
      </div>
    </div>

  </footer>
</div>