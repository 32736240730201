<app-child-breadcum *ngIf="!residentialProp && !officeProp && !retailProp" [property_uid]="property.property_uid" (back)="backToReport()"></app-child-breadcum>

<div class="items" *ngIf="loader">
    <div class="item-card">
        <div class="page-loader d-flex align-items-center justify-content-center">
            <img class="vsloader" style="width: 80px;" src="assets/images/loading.gif" alt="">
        </div>
     </div>
</div>
<div class="items" *ngIf="PowerBIData">
    <div class="item-card">
        <app-pbi-visual-container [params]="params" [embedData]="embedData" [main_data]="PowerBIData"
            [catchmentCount]="catchmentCount" [propertyUid]="property?.property_uid"></app-pbi-visual-container>
    </div>
</div>
<ng-container *ngIf="!PowerBIData && !loader">
    <app-report-property-detail  *ngIf="residentialProp" [propertyInfo]="property"></app-report-property-detail>
    <app-office-property-detail *ngIf="officeProp" [propertyInfo]="property"></app-office-property-detail>
    <app-retail-property-detail *ngIf="retailProp" [propertyInfo]="property"></app-retail-property-detail>
    <app-property-details-overview *ngIf="!residentialProp && !officeProp && !retailProp" [Property_Uid]="property?.property_uid"></app-property-details-overview>
</ng-container>
