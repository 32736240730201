import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { environment } from 'src/environments/environment';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { RetailRentalReportConstant } from './report-retail-rental.constants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { RetailRentalMenuEnum } from './report-retail-rental-v2.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportRetailRentalService } from './report-retail-rental.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/common/services/store.service';
import { DataType } from 'src/app/common/models/enums/common.enum';
import moment from 'moment';

@Component({
  selector: 'app-report-retail-rental-v2',
  templateUrl: './report-retail-rental-v2.component.html',
  styleUrls: ['./report-retail-rental-v2.component.scss'],
})
export class ReportRetailRentalComponentV2 {
  @Input() isPremium: boolean = false;
  @Input() params: any;
  RYDCHECK = environment.RYDCHECK;
  UnitTypeEnum = UnitTypeEnum;
  currentScroll: any = '';
  overViewMenu = this.isPremium? RetailRentalReportConstant.overViewMenuL3 : RetailRentalReportConstant.overViewMenuL1;
  retailUnitColumns = RetailRentalReportConstant.retailUnitColumns;
  retailUnitsizeColumns = RetailRentalReportConstant.retailUnitsizeColumns;
  retailUnitsizeExpandColumns = RetailRentalReportConstant.retailUnitsizeExpandColumns;
  unitSizeDropdown = RetailRentalReportConstant.unitSizeDropdown;
  retailRentMallWiseColumns = RetailRentalReportConstant.retailRentMallWiseColumns;

  chartView = ReportsChartEnum;

  retailRentalMenuEnum = RetailRentalMenuEnum;
  placeName = '';
  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  isFirstTime = true;
  overViewLoading: boolean = false;
  overViewSummaryAvgRentRate: any = null;
  overViewSummaryOccupancyRate: any = null;
  overViewSubCategory: any = null;

  retailUnitdata: any = [];
  retailUnitCount: number = 0;
  retailUnitLoading: boolean = false;
  retailRentalRentrateReport: any = null;

  retailUnitsizedata: any = [];
  retailUnitsizeCount: number = 0;
  retailUnitsizeLoading: boolean = false;

  houserentalChartLoading: boolean = false;
  houserentalChart: any = [];

  resetPaginator: boolean = false;
  currentPageIndex: number = 0;

  retailRentalChartLoading = false;
  overviewOccupancy: any[] = [];
  overviewAverageRate: any[] = [];
  overViewCategory: any = null;
  selectedChartValue: any[] = [];
  retailRentalChartReport: any[] = [];
  moreText: any;
  langName: any;
  isChart = true;
  retailRentOption: string = 'retailRentOption';
  downloadLoading: any = {
    a: false,
    b: false,
    c: false
  };
  sqm_unit: string = '';
  graphLoader: boolean = false;
  globalContants: any = GlobalContants;
  retailMallWiseLoading: boolean = false;
  retailMallWisedata: any = [];
  retailMallWiseCount: number = 0;
  rentRatedropDownValue: string = '';
  showPropertyDetails: boolean = false;
  transactionPropertyDetails$ = new Subscription();
  tableName: unknown = '';

  constructor(
    public sharedService: SharedService,
    private reportRetailRentalService: ReportRetailRentalService,
    private alertService: AlertService,
    public reportService: ReportService,
    public translate: TranslateService,
    private storeService: StoreService
  ) {
    this.sqm_unit =
      'reports.' + GlobalContants.keySymbolContants.squareMeterUnit;
    this.sharedService.language$.subscribe((lang) => {
      this.langName = lang;
      if (this.langName && !this.isFirstTime) {
        this.getReports();
        this.isChart = false;
        this.storeService.clear(this.retailRentOption);
      }
    });
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    });
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  ngOnInit() {

    this.overViewMenu = this.isPremium? RetailRentalReportConstant.overViewMenuL3 : RetailRentalReportConstant.overViewMenuL1;
     //Overview menu text update yearly & montly case
     if (
      this.sharedService.priceCalculation$() === this.sharedService.priceYearly
    ) {
      this.overViewMenu[1]['label'] = 'reports.annualRentUnitSizeWise';
      this.overViewMenu[2]['label'] = 'reports.annualRentMallWise';
    }
    //Update Column Monthly/Annual Rent Mall Wise Table
    if (
      this.sharedService.priceCalculation$() === this.sharedService.priceYearly
    ) {
      const columnsToUpdate = [
        {
          header: 'avg_lease_rate',
          columnName: 'reports.averageAnnualLeaseRate',
        },
        {
          header: 'min_lease_rate',
          columnName: 'reports.min_annual_lease_rate',
        },
        {
          header: 'max_lease_rate',
          columnName: 'reports.max_annual_lease_rate',
        },
      ];
      columnsToUpdate.forEach((column) => {
        const targetColumn = this.retailRentMallWiseColumns.find(
          (x) => x.header === column.header
        );
        if (targetColumn) {
          targetColumn.columnName = column.columnName;
        }
      });
      this.retailUnitColumns =
        RetailRentalReportConstant.retailUnitColumnsYearly;
    }
    this.getReports();
    this.transactionPropertyDetails();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(
      GlobalContants.StoreKey.userInfo
    );
    this.placeCode = this.sharedService.getStoreValue(
      GlobalContants.StoreKey.placeCode
    );
    this.CurrencyCode = this.sharedService.getStoreValue(
      GlobalContants.StoreKey.CurrencyCode
    );
    this.placeName = this.sharedService.getStoreValue(
      GlobalContants.StoreKey.PlaceName
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['params'] &&
      changes['params'].currentValue &&
      !changes['params'].firstChange
    ) {
      this.reportRetailRentalService.retailUnitPayload.pageNumber = 0;
      this.reportRetailRentalService.retailUnitSizePayload.pageNumber = 0;

      this.propertyInfo = null;
      this.showPropertyDetails = false;
      this.getReports();
    }
  }
  /**
   * This function is used to get tha data of Rent-Mall Wise table.
   */
  getRetailRentMallWise() {
    this.retailMallWiseLoading = true;
    this.reportRetailRentalService
      .getRetailMallWiseV2(this.reportRetailRentalService.retailMallWisePayload)
      .subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            if (res.data) {
              let cols = ['unit_size'];
              res.data.map((el: any) => {
                Object.keys(el).map((e: any) => {
                  if (cols.includes(e)) {
                    el[e] = this.reportService.rangeToNumberFor(el[e]);
                  }
                });
                if(el.details && el.details?.length > 0){
                  el.details.map((detail: any) => {
                    detail['property_name'] = `${detail['property_name']} <span class="td-sqmtext">${this.sharedService.reportDataValueType(DataType.squareMeter)}</span>`
                  })
                }
              });
            }
            this.retailMallWisedata = res.data;
            if (
              this.reportRetailRentalService.retailMallWisePayload.pageNumber ==
              0
            ) {
              this.retailMallWiseCount = res.count;
              this.overViewMenu.map((e: any) => {
                if (e.name == RetailRentalMenuEnum.retailRentMallWise) {
                  e['count'] = this.reportService.formatInternationNum(
                    this.retailMallWiseCount
                  );
                }
              });
            }
          } else {
            this.retailMallWisedata = [];
            this.retailMallWiseCount = 0;
            if (
              this.reportRetailRentalService.retailMallWisePayload.pageNumber ==
              0
            ) {
              this.overViewMenu.map((e: any) => {
                if (e.name == RetailRentalMenuEnum.retailRentMallWise) {
                  e['count'] = 0;
                }
              });
            }
          }
          this.retailMallWiseLoading = false;
        },
        error: (error: any) => {
          this.retailMallWisedata = [];
          this.retailMallWiseCount = 0;
          this.retailMallWiseLoading = false;
          if (
            this.reportRetailRentalService.retailMallWisePayload.pageNumber == 0
          ) {
            this.overViewMenu.map((e: any) => {
              if (e.name == RetailRentalMenuEnum.retailRentMallWise) {
                e['count'] = 0;
              }
            });
          }
        },
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(): void {
    this.storeService.add(this.retailRentOption, this.selectedChartValue);
  }

  selectedYear(data: any) {
    if (data.reportType === 'unit') {
      this.reportRetailRentalService.retailUnitPayload.pageNumber = 0;
      this.reportRetailRentalService.retailUnitPayload.year = data.year;
      this.retailUnitCount = 0;
      this.getRetailUnit();
    } else if (data.reportType === 'mallUnit') {
      this.reportRetailRentalService.retailMallWisePayload.pageNumber = 0;
      this.reportRetailRentalService.retailMallWisePayload.year = data.year;
      this.retailMallWiseCount = 0;
      this.getRetailRentMallWise();
    } else {
      this.reportRetailRentalService.retailUnitSizePayload.pageNumber = 0;
      this.reportRetailRentalService.retailUnitSizePayload.year = data.year;
      this.retailUnitsizeCount = 0;
      this.getRetailUnitSize();
    }
  }

  getReports() {
    this.getOverviewSummaryAverageRentRate();
    this.getOverviewSummaryOccupancyRate();
    this.getRetailUnitSize();
    this.getRetailUnit();
    this.getRetailRentMallWise();
    // this.getOverviewSubCategory();
    this.getRetailRentalRentrateReport();
  }
  /**
   * Property Details
   */
  transactionPropertyDetails() {
    this.transactionPropertyDetails$ =
      this.reportService.transactionPropertyDetails.subscribe((data) => {
        if (data) {
          this.showPropertyDetails = true;
        } else {
          this.showPropertyDetails = false;
        }
      });
  }
  /**
   * This function is used to get the overview summary of Average rent rate.
   */
  getOverviewSummaryAverageRentRate() {
    this.overViewLoading = true;
    this.reportRetailRentalService.getOverviewSummary('average').subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.overViewSummaryAvgRentRate = res.data.average_rate;
          this.overViewSummaryAvgRentRate['untillDate'] =  moment.utc(this.overViewSummaryAvgRentRate.as_of_date).local().format('DD MMM YYYY');
           let overviewAverageRateData: any = {
          ...this.reportService.getPercentageOverview(
            this.overViewSummaryAvgRentRate.average_rate
          ),
        };
        this.overviewAverageRate = this.barSortData(overviewAverageRateData);
        } else {
          this.overViewSummaryAvgRentRate = null;
        }
        this.overViewLoading = false;
      },
      error: (error: any) => {
        this.overViewSummaryAvgRentRate = null;
        this.overViewLoading = false;
      },
    });
  }
  getOverviewSummaryOccupancyRate() {
    this.overViewLoading = true;
    this.reportRetailRentalService.getOverviewSummary('occupancy').subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.overViewSummaryOccupancyRate = res.data?.occupancy;
          this.overViewSummaryOccupancyRate['untillDate'] =  moment.utc(this.overViewSummaryOccupancyRate.as_of_date).local().format('DD MMM YYYY');

            let overviewOccupancyData: any =
            this.reportService.getPercentageOverview(
              this.overViewSummaryOccupancyRate.occupancy
            );
          this.overviewOccupancy = this.barSortData(overviewOccupancyData);
        } else {
          this.overViewSummaryOccupancyRate = null;
        }
        this.overViewLoading = false;
      },
      error: (error: any) => {
        this.overViewSummaryOccupancyRate = null;
        this.overViewLoading = false;
      },
    });
  }
  getRetailRentalRentrateReport() {
    this.retailRentalChartLoading = true;
    this.reportRetailRentalService.getRetailRentalRentrateReportV2().subscribe({
      next: (res: any) => {
        this.retailRentalChartLoading = false;
        if (res.status === 200) {
          this.retailRentalRentrateReport = this.sharedService.sortObjectByKeyV2(res.data);
          this.selectedChartValue = [...Object.keys(this.retailRentalRentrateReport).slice(0, 3)];
          Object.keys(this.retailRentalRentrateReport).forEach((key, index) => {
            let data = this.retailRentalRentrateReport[key];
            this.retailRentalRentrateReport[key] = data.map((obj: any) => ({
              category: String(obj.year),
              value: obj.count,
              name: key,
            }));
          });

          let retailChart = Object.keys(this.retailRentalRentrateReport)
          .filter((key) => this.selectedChartValue.includes(key))


          // Update the retail rental chart report
          this.retailRentalChartReport = retailChart.reduce((obj: any, key: any) => {
              obj[key] = this.retailRentalRentrateReport[key];
              return obj;
            }, {});


        } else {
          this.retailRentalChartReport = [];
          this.retailRentalRentrateReport = null;
          this.selectedChartValue = [];
        }
      },
      error: (error: any) => {
        this.retailRentalChartReport = [];
        this.selectedChartValue = [];
        this.retailRentalRentrateReport = null;
        this.retailRentalChartLoading = false;
      },
    });
  }

  originalOrder = () => 0;

  getReportKeys(): string[] {
    if (this.retailRentalRentrateReport) {
      return Object.keys(this.retailRentalRentrateReport);
    }
    return [];
  }
  barSortData(data: any) {
    let overviewArray: any = Object.entries(data);
    return overviewArray.sort(
      (a: any, b: any) => b[1].category - a[1].category
    );
  }

  /**
   * This function is used to get the data of retail unit size wise table.
   */
  getRetailUnitSize() {
    this.retailUnitsizeLoading = true;
    this.reportRetailRentalService
      .getRetailUnitSizeV2(this.reportRetailRentalService.retailUnitSizePayload)
      .subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            if (res.data) {
              let cols = [
                'lower_ground_range',
                'ground_range',
                'first_floor_range',
                'second_floor_range',
                'third_floor_range',
                'fourth_floor_range',
                'fifth_floor_range',
                'sixth_floor_range',
                'higher_floor_range'
              ];

              res.data.map((el: any) => {
                Object.keys(el).map((e: any) => {
                  if (cols.includes(e)) {
                    el[e] = this.reportService.rangeToNumberFor(el[e]);
                  }
                  if (el.details && el.details.length > 0) {
                    el.details.map((detail: any) => {
                      Object.keys(detail).map((key: any) => {
                        if (cols.includes(key)) {
                          detail[key] = this.reportService.rangeToNumberFor(
                            detail[key]
                          );
                        }
                      });
                    });
                  }
                });
              });
            }

            this.retailUnitsizedata = res.data;
            if (
              this.reportRetailRentalService.retailUnitSizePayload.pageNumber ==
              0
            ) {
              this.retailUnitsizeCount = res.count;
              this.overViewMenu.map((e: any) => {
                if (e.name == RetailRentalMenuEnum.retailUnitSize) {
                  e['count'] = this.reportService.formatInternationNum(
                    this.retailUnitsizeCount
                  );
                }
              });
            }
          } else {
            this.retailUnitsizedata = [];
            this.retailUnitsizeCount = 0;
            if (
              this.reportRetailRentalService.retailUnitSizePayload.pageNumber ==
              0
            ) {
              this.overViewMenu.map((e: any) => {
                if (e.name == RetailRentalMenuEnum.retailUnitSize) {
                  e['count'] = 0;
                }
              });
            }
          }
          this.retailUnitsizeLoading = false;
        },
        error: (error: any) => {
          this.retailUnitdata = [];
          this.retailUnitsizeCount = 0;
          this.retailUnitsizeLoading = false;
          if (
            this.reportRetailRentalService.retailUnitSizePayload.pageNumber == 0
          ) {
            this.overViewMenu.map((e: any) => {
              if (e.name == RetailRentalMenuEnum.retailUnitSize) {
                e['count'] = 0;
              }
            });
          }
        },
      });
  }
  /**
   * This function is used to get the data of units-in selected properties table
   */
  getRetailUnit() {
    this.retailUnitLoading = true;
    this.reportRetailRentalService
      .getRetailUnitV2(this.reportRetailRentalService.retailUnitPayload)
      .subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.retailUnitdata = res.data;
            if (
              this.reportRetailRentalService.retailUnitPayload.pageNumber == 0
            ) {
              this.retailUnitCount = res.count;
              this.overViewMenu.map((e: any) => {
                if (e.name == RetailRentalMenuEnum.retailUnit) {
                  e['count'] = this.reportService.formatInternationNum(
                    this.retailUnitCount
                  );
                }
              });
            }
          } else {
            this.retailUnitdata = [];
            this.retailUnitCount = 0;
            if (
              this.reportRetailRentalService.retailUnitPayload.pageNumber == 0
            ) {
              this.overViewMenu.map((e: any) => {
                if (e.name == RetailRentalMenuEnum.retailUnit) {
                  e['count'] = 0;
                }
              });
            }
          }
          this.retailUnitLoading = false;
        },
        error: (error: any) => {
          this.retailUnitdata = [];
          this.retailUnitCount = 0;
          this.retailUnitLoading = false;
          if (
            this.reportRetailRentalService.retailUnitPayload.pageNumber == 0
          ) {
            this.overViewMenu.map((e: any) => {
              if (e.name == RetailRentalMenuEnum.retailUnit) {
                e['count'] = 0;
              }
            });
          }
        },
      });
  }

  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }

  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.reportService.downloadReport(type, this.userInfo?.user_id).subscribe({
      next: (res: any) => {
        this.downloadLoading[key] = false;
        if (res.status === 200) {
          const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${
            res.data
          }&city_code=${this.placeCode.toLowerCase()}&module_name=retail_rental`;
          this.sharedService.downloadExcelUrl(downloadUrl);
          this.alertService.success(
            this.translate.instant('reports.downloadRequestSubmitted')
          );
        } else {
          this.alertService.warn(res.message);
        }
      },
      error: (error: any) => {
        this.downloadLoading[key] = false;
      },
    });
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.retailRentalMenuEnum.retailUnit) {
        this.reportRetailRentalService.retailUnitPayload.pageNumber =
          e.pageSize * e.pageIndex;
        this.getRetailUnit();
      } else if (report === this.retailRentalMenuEnum.retailRentMallWise) {
        this.reportRetailRentalService.retailMallWisePayload.pageNumber =
          e.pageSize * e.pageIndex;
        this.getRetailRentMallWise();
      } else {
        this.reportRetailRentalService.retailUnitSizePayload.pageNumber =
          e.pageSize * e.pageIndex;
        this.getRetailUnitSize();
      }
    }
  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction,
    };
    if (report === this.retailRentalMenuEnum.retailUnit) {
      this.reportRetailRentalService.retailUnitPayload.pageNumber = 0;
      this.reportRetailRentalService.retailUnitPayload.sort_order =
        JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRetailUnit();
    } else if (report === this.retailRentalMenuEnum.retailRentMallWise) {
      this.reportRetailRentalService.retailMallWisePayload.pageNumber = 0;
      this.reportRetailRentalService.retailMallWisePayload.sort_order =
        JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRetailRentMallWise();
    } else {
      this.reportRetailRentalService.retailUnitSizePayload.pageNumber = 0;
      this.reportRetailRentalService.retailUnitSizePayload.sort_order =
        JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRetailUnitSize();
    }
  }

  dropDownChange(value: any) {
    this.rentRatedropDownValue = value;
    if (value === 'Range') {
      this.retailUnitsizeColumns =
        RetailRentalReportConstant.retailUnitsizeColumns;
      this.retailUnitsizeExpandColumns =
        RetailRentalReportConstant.retailUnitsizeExpandColumns;
    } else {
      this.retailUnitsizeColumns =
        RetailRentalReportConstant.retailUnitsizeAverageColumns;
      this.retailUnitsizeExpandColumns =
        RetailRentalReportConstant.retailUnitsizeExpandAverageColumns;
    }
  }

  lastClickedTable(event: Event, table: any) {
    this.tableName = table;
  }
  selectChart(e: any, value: any) {
    let index = this.selectedChartValue.indexOf(value);
    if (!e.checked) {
      if (this.selectedChartValue.length === 1) {
        this.alertService.warn(this.translate.instant('atLeastOneMust'));
        e.source.checked = true;
      } else {
        this.selectedChartValue.splice(index, 1);
      }
    } else {
      if (this.selectedChartValue.length > 2) {
        this.alertService.warn(
          this.translate.instant('youCannotSelectMoreThan')
        );
        e.source.checked = false;
        return;
      } else {
        this.selectedChartValue.push(value);
      }
    }

    this.retailRentalChartReport = Object.keys(this.retailRentalRentrateReport)
      .filter((key) => this.selectedChartValue.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = this.retailRentalRentrateReport[key];
        return obj;
      }, {});
    this.retailRentalChartReport = this.sharedService.sortObjectByKeyV2(
      this.retailRentalChartReport
    );

    this.retailRentalChartLoading = false;
    setTimeout(() => {
      let firstEleCount: any = document
        .querySelector('.setext')
        ?.textContent?.split(',')[0].length;
      if (this.selectedChartValue.length == 2 && firstEleCount > 6) {
        this.moreText = `(1) ${this.translate.instant('reports.more')}`;
      } else if (this.selectedChartValue.length == 3 && firstEleCount > 6) {
        this.moreText = `(2) ${this.translate.instant('reports.more')}`;
      } else if (this.selectedChartValue.length == 2 && firstEleCount < 6) {
        this.moreText = '';
      } else {
        this.moreText = `(1) ${this.translate.instant('reports.more')}`;
      }
    }, 1);
  }

  // getOpacity(index:number, value: any, type: string): string{
  //   // Adjust the factor and starting value as per your requirement
  //   return this.reportRetailRentalService.changeOpacity(index, value, type);
  // }
  // getNumber(nbr: any){
  //   return +nbr;
  // }
  ngOnDestroy() {
    this.storeService.clear(this.retailRentOption);
    this.transactionPropertyDetails$.unsubscribe();
  }
}
