import {
  Component,
  AfterViewInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';


@Component({
  selector: 'app-pie-chart-report',
  templateUrl: './pie-chart-report.component.html',
  styleUrls: ['./pie-chart-report.component.scss']
})
export class PieChartReportComponent implements OnDestroy, OnChanges {
  private root1!: am5.Root;
  private root2!: am5.Root;
  private root3!: am5.Root;
  private legendRoot!: am5.Root;
  @Input() data: any;
  result: any = [];

  ngOnChanges(changes: SimpleChanges): void {
    if(this.root1){
      this.root1.dispose();
    }
    if(this.root3){
      this.root3.dispose();
    }
    if(this.root2){
      this.root2.dispose();
    }
    if(this.legendRoot){
      this.legendRoot.dispose();
    }
    this.createPieCharts();
  }

  getTransactionKeys(): string[] {
    if (this.data) {

      let data: any = Object.keys(this.data).sort((a: any, b: any) => b - a);
      if (data.includes('Last 12 months') || data.includes('آخر 12 شهرا')) {
        let last12months = data.pop();
        data.unshift(last12months)
      }
      return data;
    }
    return [];
  }

  createPieCharts(): void {
    // Initialize root elements for each pie chart
    const dataResult = this.getTransactionKeys();
    this.result = dataResult;
    this.root1 = am5.Root.new('chartdiv1');
    this.root1.setThemes([am5themes_Animated.new(this.root1)]);
    if(dataResult.length > 1){
      this.root2 = am5.Root.new('chartdiv2');
      this.root2.setThemes([am5themes_Animated.new(this.root2)]);

    }
    if(dataResult.length === 3){
      this.root3 = am5.Root.new('chartdiv3');
      this.root3.setThemes([am5themes_Animated.new(this.root3)]);
    }
    this.legendRoot = am5.Root.new('legenddiv');

    // Apply theme
   
    this.legendRoot.setThemes([am5themes_Animated.new(this.legendRoot)]);

    // Create shared legend
    let legend = this.legendRoot.container.children.push(am5.Legend.new(this.legendRoot, {
       y: am5.percent(45),
      layout: this.legendRoot.horizontalLayout,
      centerX: am5.percent(50),
      x: am5.percent(50),
      clickTarget: "none"


    }));

    

    legend.markers.template.setAll({
      width: 12,
      height: 12,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10
    });
    legend.labels.template.setAll({
      fontSize: 12,
      oversizedBehavior: "wrap"

    });

    
    legend.itemContainers.template.setAll({
      paddingLeft: -5,
      paddingRight: -5
    });
    legend.valueLabels.template.setAll({
      fontSize: 12,
    });

    // Function to create a pie chart with custom colors
    const createPieChart = (root: am5.Root, data: any[], colors: string[], isMainChart: boolean) => {
      let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        width: am5.percent(100), // Set an initial width (e.g., 50% of container)
        height: am5.percent(100)
      }));
      

      let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        legendLabelText: "[bold]{category}[/]",
        legendValueText: "",
        alignLabels: false
      }));

      series.ticks.template.set('forceHidden', true);
      

      series.labels.template.setAll({
        text: "{valuePercentTotal.formatNumber('#.#')}%", // Show percentage
        fontSize: 12,
        fill: am5.color(0xffffff),
        radius: -60
      });

      // series.labels.template.set("text", "{valuePercentTotal.formatNumber('#.#')}%");

      series.set("colors", am5.ColorSet.new(root, {
        colors: [
          am5.color("#2372F0"),  
          am5.color("#568AEE"),  
          am5.color("#87ADF4"),  
          am5.color("#AFC7F6"),
          am5.color("#CDDEFF")   
        ],
        reuse: true  // Reuse colors if more slices exist
      }));



      series.data.setAll(data);

      // Apply custom colors
      
 

      // Add tooltips
      series.slices.template.set('tooltipText', '{value}');

      // Attach the legend only to the first chart
     // if (isMainChart) {
        legend.data.setAll(series.dataItems);
      //}
    };

    // Data for each chart

    // Custom color schemes
    const colors1 = ['#ff6384', '#36a2eb', '#ffce56'];
    const colors2 = ['#4bc0c0', '#ff9f40', '#9966ff'];
    const colors3 = ['#e74c3c', '#2ecc71', '#000000'];

    // Create three pie charts, first one controls the legend
    let dataCount = 
    createPieChart(this.root1, this.data[dataResult[0]], colors1, true);
    if(dataResult.length > 1){
      createPieChart(this.root2, this.data[dataResult[1]], colors2, false);
    }

    if(dataResult.length === 3){
      createPieChart(this.root3, this.data[dataResult[2]], colors3, false);
    }
  }

  ngOnDestroy(): void {
    if(this.root1)  this.root1.dispose();
    if(this.root2) this.root2.dispose();
    if(this.root3) this.root3.dispose();
    if(this.legendRoot) this.legendRoot.dispose();
  }
}
