import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ReportPropertyDetailService } from '../../residential-property/report-property-detail.service';
import { ReportPropertyDetailConstant } from '../../residential-property/report-property-detail.constant';

@Component({
  selector: 'app-residential-units',
  templateUrl: './residential-units.component.html',
  styleUrls: ['./residential-units.component.scss']
})
export class ResidentialUnitsComponent implements OnChanges {

  @Input() propertyInfo: any;
  @Output() headerCount = new EventEmitter();
  residentialUnitsLoading = false;
  residentialUnitsCount = 0;
  residentialUnitsData = [];
  residentialColumns  = ReportPropertyDetailConstant.residentialColumns;

  constructor(private reportPropertyDetailService: ReportPropertyDetailService){}

  ngOnChanges(): void {
    this.getUnit();
  }


  getUnit() {
    this.residentialUnitsLoading = true;
    this.reportPropertyDetailService.getUnitReport(this.reportPropertyDetailService.unitPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.residentialUnitsData = res.data;
            if(this.reportPropertyDetailService.unitPayload.pageNumber == 0){
            this.residentialUnitsCount = res.count;
            this.headerCount.emit(this.residentialUnitsCount)
           }
            
          } else {
            this.residentialUnitsData = [];
            this.residentialUnitsCount = 0;
            this.headerCount.emit(null)
          }
          this.residentialUnitsLoading = false;
        },
        error:()=>{
          this.residentialUnitsLoading = false;
          this.residentialUnitsData = [];
          this.residentialUnitsCount = 0;
          this.headerCount.emit(null)
         
        }
      }
    )
  }

  

  onPageEvent(e: any) {
    this.reportPropertyDetailService.unitPayload.pageNumber = (e.pageSize * e.pageIndex);
    this.getUnit();
  }

  sortData(event: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    this.reportPropertyDetailService.unitPayload.pageNumber = 0;
    this.reportPropertyDetailService.unitPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
    this.getUnit();
  }

  downloadExcel(event: any, type: string) {
    this.reportPropertyDetailService.downloadRentalReport(type);
  }

  

}
