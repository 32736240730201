<div class="filter-section" [formGroup]="unitForm">
    <label class="range-slider-label">{{name | langtranslate}} <span *ngIf="required" class="required">*</span>
        <i *ngIf="!isSubscription" class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
    </label>
    <div class="show-max-value"><small>0</small><small>{{floorMax}}</small></div>
    <mat-slider class="mat-slider" [min]="floorMin" [max]="floorMax">
        <input #minRange [(ngModel)]="minValue" (input)="min.value = minRange.value" [ngModelOptions]="{standalone: true}" (change)="sliderValueChange($event)" matSliderStartThumb style="direction: ltr;" [disabled]="!isSubscription">
        <input #maxRange [(ngModel)]="maxValue" (input)="max.value = maxRange.value" [ngModelOptions]="{standalone: true}" (change)="highValueChange($event)" matSliderEndThumb style="direction: ltr;" [disabled]="!isSubscription">
      </mat-slider>
    <div class="filter-unit-input" style="direction: ltr;">
        <input placeholder="{{'reports.min' | translate}}" #min type="number" min="0" (keypress)="numberOnly($event)" formControlName="min"  class="unit-size" onlyNumeric>
        <input placeholder="{{'reports.max' | translate}}" #max type="number" min="0" (keypress)="numberOnly($event)" formControlName="max"  class="unit-size" onlyNumeric>
    </div>
    <p *ngIf="errorMax" class="invaild">{{'reports.please_enter_zooro_to' | translate }} {{floorMax}} {{'reports.number'| translate}}</p>
    <p *ngIf="errorCheck" class="invaild">{{'reports.min_not_more_than_max'| translate}}</p>
</div>

