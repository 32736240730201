import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportRetailInventoryService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  propertiesPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  departmentsPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  tentantsPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  unitPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

 
  getOverviewSummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailInventoryOverview}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getPropertyCount() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailInventoryPropertyCount}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getGlaShare() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailInventoryGlaShare}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getTrends() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailInventoryTrends}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getNeighbhoodGlaShare(type: string) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailInventoryNeighborhoodInsights}${changeParams}&type=${type}`;
    return this.apiServive.get(url, false);
  }

  getProperties(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.propertiesPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.retailInventoryProperties}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.propertiesPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  
  

  getDepartment(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.departmentsPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.retailInventoryDepartments}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.departmentsPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }

  getTenants(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.tentantsPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.retailInventoryTenants}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.tentantsPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }

  getUnits(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.unitPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.retailInventoryUnits}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.unitPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  

  downloadTransactionReport(type: string, user_id: number) {
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  downloadExcelUrl() {
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }

  



  formatOverViewSummary(data: any) {

    data['totalgla'] = this.reportService.calcPercentage(data.prev_gla, data.gla);
    data['totalshopsVal'] = this.reportService.calcPercentage(data.prev_total_shops, data.total_shops);
    data['totalretailpropVal'] = this.reportService.calcPercentage(data.prev_total_retail_prop, data.total_retail_prop);
    data['untillDate'] = data.until_date ? moment.utc(data.until_date).local().format('DD MMM YYYY') : null;
    return data;
  }

  


  
  downloadInvestmentReport(type:string, user_id:any, property_uid:string='', proj_uid:string=''){
    let url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    if(property_uid) url += `&property_uid=${property_uid}`;
    if(proj_uid) url += `&project_uid=${proj_uid}`;
    return this.apiServive.get(url);
  }
}
