import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: string[], name:string , searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    if(!name) return items;
    return items.filter((item:any) => item[name].toLowerCase().includes(searchText.toLowerCase()));
  }

}
