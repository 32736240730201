<ng-container *ngIf="data.type === 'reset'">
    <ng-container *ngTemplateOutlet="catchmentModel; context:{
        data: {
            name: ('map.resetCatchment' | translate),
            msg: ('map.resetCatchmentMsg' | translate),
            saveBtn: ('map.reset' | translate)
        }
    }"></ng-container>
</ng-container>

<ng-container *ngIf="data.type == 'revert'">
    <ng-container *ngTemplateOutlet="catchmentModel; context:{
    data: {
        name: ('map.revertOriginal' | translate),
        msg: ('map.revertCatchmentMsg' | translate),
        saveBtn: ('map.delete' | translate)
    }
}"></ng-container>
</ng-container>

<ng-container *ngIf="data.type == 'load'">
    <ng-container *ngTemplateOutlet="catchmentModel; context:{
    data: {
        name: ('map.loadCatchment' | translate),
        msg: ('map.loadCatchmentMsg' | translate),
        saveBtn: ('map.continue' | translate)
    }
}"></ng-container>
</ng-container>


<ng-template #catchmentModel let-data="data">
    <div class="container-model">
        <div class="reset-catchment-container">
            <div class="title">
                <h2>{{ data.name }}</h2>
                <i (click)="closePopup(false)"><mat-icon>close</mat-icon></i>
            </div>
            <h4>{{ data.msg }}</h4>

            <div class="save-catchment">
                <button class="cancel-btn" (click)="closePopup(false)">{{'map.cancel' | translate}}</button>
                <button class="update-btn" (click)="closePopup(true)">{{ data.saveBtn }}</button>
            </div>
        </div>
    </div>

</ng-template>
