import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { SharedService } from 'src/app/common/services/shared.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { IReportEmbedConfiguration, models,} from 'powerbi-client';

import { ReportMapService } from 'src/app/reports/services/report-map.service';


@Component({
  selector: 'app-power-bi-dynamic',
  templateUrl: './power-bi-dynamic.component.html',
  styleUrls: ['./power-bi-dynamic.component.scss']
})
export class PowerBiDynamicComponent {

  @Input() params:any;
  currentScroll=0;
  
  visualLoader: boolean =  false;
  overViewMenu:any=[];
  PowerBIData: any;
  embedData: any;
  constructor(
    private sharedService: SharedService,
    public translate: TranslateService,
    public reportService: ReportService,
    private mapService: ReportMapService,
  ) {
    
    this.overViewMenu = []
    
    // this.PowerBIData.pb_container.map((e:any)=>{
    //   this.overViewMenu.push({
    //     label: 'reports.overview',
    //     name: e.sort_order,
    //     count: null
    //   })
    // })
    
  }
  
  ngOnInit(): void {
    if(this.reportService.getCurrentReport() && this.reportService.getCurrentReport().powerbi_data){
      this.PowerBIData = this.reportService.getCurrentReport().powerbi_data;
      this.PowerBIData.pb_container.sort((a:any, b:any) =>  (Number(a.sort_order) - Number(b.sort_order)));  
      this.loadVisualReport(); 
    }
  }



  loadVisualReport(){
    this.visualLoader = true;
    this.reportService.powerBiFecthReport(this.PowerBIData.pb_group_id, this.PowerBIData.pb_report_id)
      .subscribe({
        next: (res: any) =>{
          this.embedData = res.data;
          this.visualLoader = false;
        },
        error: (err)=>{
          this.visualLoader = false;
        }
      })
  }
  
  
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }
  
  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }
  
}
