export enum PropertyDetailMenuEnum {
    overview = 0,
    builtInformation,
    //costOfconstruction,
    amentities,
    unit,
    unitTransaction,
    projectTransaction,
    rentalIncome
}
