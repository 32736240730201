import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportMallInventoryService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  getMallInventoryTotalReport(year = null){
    
    const url: string = `${environment.miUrl}${apiEndPoints.mallInventoryTotalReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getMallInventoryYearlyUnitsReport(){
    let url: string = `${environment.miUrl}${apiEndPoints.mallInventoryYearlyUnitsReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getMallInventoryYearlyOccupancyReport(){
    let url: string = `${environment.miUrl}${apiEndPoints.mallInventoryYearlyOccupancyReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getMallInventoryYearlyIncrementalReport(){
    let url: string = `${environment.miUrl}${apiEndPoints.mallInventoryYearlyIncrementalReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getMallInventoryNeighTrendlReport(type: string){
    let url: string = `${environment.miUrl}${apiEndPoints.mallInventoryNeighTrendlReport}${this.reportService.filterQueryParams}&type=${type}`;
    return this.apiServive.get(url);
  }

 }
