import { Pipe, PipeTransform } from "@angular/core";
import { SharedService } from "../services/shared.service";

@Pipe({
    name: 'langtranslate',
    pure: false
})

export class LangTranslatePipe implements PipeTransform {

    constructor(private sharedService: SharedService){}

    transform(value: any, ...args: any[]) {
        if(!value) return '';
        const currentLangID = this.sharedService.getCurrentLangID();
        return value[currentLangID] || value[1];
    }
}

@Pipe({
    name: 'foldertranslate',
    pure: false
})

export class FolderTranslatePipe implements PipeTransform {

    constructor(private sharedService: SharedService){}

    transform(value: any, args: any=null) {
        if(!value) return '';
        const currentLangID = this.sharedService.getCurrentLangID();
        return value[currentLangID] ? value[currentLangID] : (value[1] ? value[1] : args['name']);
    }
}
