import { ReportTableColumn } from "../../../../models/reports.model";
import { DataType } from "src/app/common/models/enums/common.enum";
import { retailsPropertyDetailMenuEnum } from "./retail-property-detail.enum";

export class RetailPropertyDetailConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: retailsPropertyDetailMenuEnum.overview
        },
        {
            label: 'reports.unitRental',
            name: retailsPropertyDetailMenuEnum.retailUnitSize,
            count: null
        },
        {
            label: 'reports.propertyTransactions',
            name: retailsPropertyDetailMenuEnum.propertyTransaction,
            count: null
        },
       
    ]

    static retailUnitColumns: ReportTableColumn[] = [
        {
            header: 'tenant_name_unit_number',
            columnName: 'reports.tentant_name_with_unit_number',
            field: 'tenant_name_unit_number',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true,
            isTenent:true
        },
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'gross_area',
            columnName: 'reports.gross_area',
            field: 'gross_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'lease_rate',
            columnName: 'reports.lease_rate',
            field: 'lease_rate',
            border: true,
            symbol: DataType.currencyWithSquareMeter,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'rental_price',
            columnName: 'reports.monthly_rentals',
            field: 'rental_price',
            border: false,
            isSortable: true,
            symbol: DataType.currencyCode,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'is_anchor',
            columnName: 'reports.anchor',
            field: 'is_anchor',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'tenant_category',
            columnName: 'reports.tenant_category',
            field: 'tenant_category',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_uid',
            columnName: 'reports.unit_uid',
            field: 'unit_uid',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
    ]

    static retailUnitColumnsYearly: ReportTableColumn[] = [
        {
            header: 'tenant_name_unit_number',
            columnName: 'reports.tentant_name_with_unit_number',
            field: 'tenant_name_unit_number',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true,
            isTenent:true
        },
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'gross_area',
            columnName: 'reports.gross_area',
            field: 'gross_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'lease_rate',
            columnName: 'reports.annual_lease_rate',
            field: 'lease_rate',
            border: true,
            symbol: DataType.currencyWithSquareMeter,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'rental_price',
            columnName: 'reports.yearly_rentals',
            field: 'rental_price',
            border: false,
            isSortable: true,
            symbol: DataType.currencyCode,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'is_anchor',
            columnName: 'reports.anchor',
            field: 'is_anchor',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'tenant_category',
            columnName: 'reports.tenant_category',
            field: 'tenant_category',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_uid',
            columnName: 'reports.unit_uid',
            field: 'unit_uid',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]
}
