import { Component, effect, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { RetailInventoryReportConstant } from './report-retail-inventory.constants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReportRetailInventoryService } from './report-retail-inventory.service';
import { RetailInventoryMenuEnum } from './report-retail-inventory.enum';
import { ReportChartColumn } from '../../models/reports.model';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { UnitTypeEnum } from '../../models/unit-type-enum';

@Component({
  selector: 'app-report-retail-inventory',
  templateUrl: './report-retail-inventory.component.html',
  styleUrls: ['./report-retail-inventory.component.scss']
})
export class ReportRetailInventoryComponent implements OnChanges, OnInit, OnDestroy {

  @Input() params: any;

  chartView = ReportsChartEnum;

  currentScroll: any = '';

  overViewLoading: boolean = false;
  overViewSummary: any = null;
  overViewMenu = RetailInventoryReportConstant.overViewMenu;
  retailInventoryMenuEnum = RetailInventoryMenuEnum;

  downloadLoading: any = {
    a: false,
    b: false,
    c: false
  };
  propertyCountLoading = false;
  glashareLoading= false;
  trendsLoading = false;
  neighbhoodGlaShareLoading = false;

  showPropertyDetails: boolean = false;
  
  propertyCount: ReportChartColumn[] = [];
  glashare: ReportChartColumn[] = [];
  trendsData: ReportChartColumn[] = [];
  neighGlashare: any = [];
  neighType = 'gla_share';

  propertiesData: any = [];
  propertiesCount: number = 0;
  propertiesLoading: boolean = false;

  departmentsdata: any = [];
  departmentsCount: number = 0;
  departmentsLoading: boolean = false;

  tentantsdata: any = [];
  tentantsCount: number = 0;
  tentantsLoading: boolean = false;
  UnitTypeEnum = UnitTypeEnum;

  unitsdata: any = [];
  trendslist: any = [];
  unitsCount: number = 0;
  unitsLoading: boolean = false;
  trendsText = 'reports.occupancy_trends';
  neighbourhodinsightText = 'reports.gla_share';
  propertyInfo = null;
  propertiesColumns = RetailInventoryReportConstant.propertiesColumns;
  departmentsColumns = RetailInventoryReportConstant.departmentsColumns;
  tentantsColumns = RetailInventoryReportConstant.tentantsColumns;
  unitColumns = RetailInventoryReportConstant.unitColumns;
  unitSizeDropdown = RetailInventoryReportConstant.unitSizeDropdown;
  trendsDropdown = RetailInventoryReportConstant.trendsDropdown;
  neighInsightDropdownList = RetailInventoryReportConstant.neighInsightDropdown;
  departmentExpandColumns = RetailInventoryReportConstant.departmentExpandColumns;

  transactionPropertyDetails$ = new Subscription();
  isFirstTime = true;
  placeName='';
  userInfo: any = 0;
  placeCode: string = '';
  sqm_unit: string = '';
  CurrencyCode: string = '';
  neightMap = new Map();
  tableName:any;
  activeTab:any;
  activeTabName: string = 'property';

  constructor(
    private sharedService: SharedService,
    private reportRetailInventoryService: ReportRetailInventoryService,
    public translate: TranslateService,
    public reportService: ReportService,
    private alertService: AlertService,

  ){
    effect(()=> {this.activeTab = this.sharedService.activeTab()});
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
    //Update Column Monthly/Annual Properties Table
    if (this.sharedService.priceCalculation$() === this.sharedService.priceYearly) {
      const columnsToUpdate = [
        { header: 'blended_lease_rate', columnName: 'reports.annualBlendedLeaseRate' }
      ];
      //Residential Units - Grade wise Table 
      columnsToUpdate.forEach(column => {
        const targetColumn = this.propertiesColumns.find(x => x.header === column.header);
        if (targetColumn) {
          targetColumn.columnName = column.columnName;
        }
      });
    }
    this.updateColumnsDepartments('Range');
    this.updateColumnsTenants('Range');
  }

  updateColumnsDepartments(type: string = 'Range'){
    //Update Column Monthly/Annual Properties Table
    if (this.sharedService.priceCalculation$() === this.sharedService.priceYearly) {
      const columnsToUpdateDepartments = [
        { header:type === 'Range' ? 'lease_rate_range' : 'lease_rate_avg', columnName: 'reports.annual_leaserate_range' }
      ];
      columnsToUpdateDepartments.forEach(column => {
        //Departments 
        const targetColumnDepartments = this.departmentsColumns.find(x => x.header === column.header);
        if (targetColumnDepartments) {
          targetColumnDepartments.columnName = column.columnName;
        }
      })
    }
  }
  updateColumnsTenants(type: string = 'Range'){
    //Update Column Monthly/Annual Properties Table
    if (this.sharedService.priceCalculation$() === this.sharedService.priceYearly) {
      const columnsToUpdateDepartments = [
        { header:type === 'Range' ? 'lease_rate_range' : 'lease_rate_average', columnName: 'reports.annual_leaserate_range' }
      ];
      columnsToUpdateDepartments.forEach(column => {
        //Tenants
        const targetColumnTenants = this.tentantsColumns.find(x => x.header === column.header);
        if (targetColumnTenants) {
          targetColumnTenants.columnName = column.columnName;
        }
      })
    }
  }

  ngOnInit(): void {
    this.getReports();
    this.transactionPropertyDetails();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName =  this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.reportRetailInventoryService.propertiesPayload.pageNumber = 0;
      this.reportRetailInventoryService.departmentsPayload.pageNumber = 0;
      this.reportRetailInventoryService.tentantsPayload.pageNumber = 0;
      this.reportRetailInventoryService.unitPayload.pageNumber = 0;

      this.reportRetailInventoryService.propertiesPayload.year =null
      this.reportRetailInventoryService.departmentsPayload.year =null
      this.reportRetailInventoryService.tentantsPayload.year =null
      this.reportRetailInventoryService.unitPayload.year =null

      this.showPropertyDetails = false;
      this.getReports();
      this.neightMap.clear();
    }
  }
  lastClickedTable(event:Event, table:any){
    this.tableName = table
  }


  transactionPropertyDetails(){
    this.transactionPropertyDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {
      if(data){
        this.showPropertyDetails = true;
        this.propertyInfo = data;
      } else {
        this.showPropertyDetails = false;
        this.propertyInfo = null;
      }

    });
  }

  getReports(){
    this.getOverviewSummary();
    this.getPropertyCount();
    this.getGlaShare();
    this.getTrends();
    this.getNeighbhoodGlaShare();
    this.getProperties();
    this.getDepartments();
    this.getTentants();
    this.getUnits();
  }

  getOverviewSummary() {
    this.overViewLoading = true;
    this.reportRetailInventoryService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.overViewSummary = this.reportRetailInventoryService.formatOverViewSummary(res.data);
          } else {
            this.overViewSummary = null;
          }
          this.overViewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.overViewLoading = false;
        }
      }
    )
  }

   /**
   * Graph Data Api
   */
   getPropertyCount() {
    this.propertyCountLoading = true;
    this.reportRetailInventoryService.getPropertyCount().subscribe(
      {
        next: (res: any) => {
          this.propertyCountLoading = false;
          this.propertyCount =  res.data ? res.data.map((data: any) => {
            return {
              category: data.category,
              value: data.count
            }
          }) : [];
        },
        error: (error: any) => {
          this.propertyCount = [];
          this.propertyCountLoading = false;
        }
      }
    )
  }

  /**
   * Graph Data Api
   */
  getGlaShare() {
    this.glashareLoading = true;
    this.reportRetailInventoryService.getGlaShare().subscribe(
      {
        next: (res: any) => {
          this.glashareLoading = false;
          this.glashare =  res.data ? res.data.map((data: any) => {
            return {
              category: data.category,
              value: data.gla
            }
          }) : [];
        },
        error: (error: any) => {
          this.glashare = [];
          this.glashareLoading = false;
        }
      }
    )
  }

  getTrends() {
    this.trendsLoading = true;
    this.reportRetailInventoryService.getTrends().subscribe(
      {
        next: (res: any) => {
          this.trendsLoading = false;
          this.trendslist = res.data ? res.data : [];
          this.trendsData =  res.data ? res.data.map((data: any) => {
            return {
              category: data.year.toString(),
              value: data.occupancy
            }
          }) : [];
        },
        error: (error: any) => {
          this.trendsData = [];
          this.trendsLoading = false;
        }
      }
    )
  }

  getNeighbhoodGlaShare(type = 'gla') {
    this.neighbhoodGlaShareLoading = true;
    this.reportRetailInventoryService.getNeighbhoodGlaShare(type).subscribe(
      {
        next: (res: any) => {
          this.neighbhoodGlaShareLoading = false;
          if(res.status === 200 && res.data.length > 0){
            let data = res.data;
            let neigh: any = {};
            data.forEach((val: any) => {
              neigh[val.neigh] = val.value
            })
            let overviewAverageRateData: any = { ...this.reportService.getPercentageOverview(neigh) };
            this.neighGlashare = this.barSortData(overviewAverageRateData);
          } else {
            this.neighGlashare = [];
          }
        },
        error: (error: any) => {
          this.neighGlashare = [];
          this.neighbhoodGlaShareLoading = false;
        }
      }
    )
  }

  barSortData(data: any){
    let overviewArray: any = Object.entries(data);
    return overviewArray.sort((a: any, b: any) => b[1].category - a[1].category);
  }

  getProperties(){
    this.propertiesLoading = true;
    this.reportRetailInventoryService.getProperties(this.reportRetailInventoryService.propertiesPayload).subscribe(
      {
        next: (res: any) => {
          this.propertiesLoading = false;
          this.propertiesData = res.status === 200 ? res.data : [];
          if(this.reportRetailInventoryService.propertiesPayload.pageNumber == 0){
            this.propertiesCount = res.status === 200 ? res.count : 0;
          this.overViewCount(RetailInventoryMenuEnum.properties, res.status === 200 ?  this.reportService.formatInternationNum(this.propertiesCount) : 0)
          }
          
        },
        error:()=>{
          this.propertiesLoading = false;
          this.propertiesData = [];
          this.propertiesCount = 0;
          this.overViewCount(RetailInventoryMenuEnum.properties, 0)
        }
      }
    )

  }

  overViewCount(type: number, count: number){
    this.overViewMenu.map((e:any)=>{
      if(e.name == type){
        e['count'] = count;
      }
    })
  }

  getDepartments(){
    this.departmentsLoading = true;
    this.reportRetailInventoryService.getDepartment(this.reportRetailInventoryService.departmentsPayload).subscribe(
      {
        next: (res: any) => {
          this.departmentsLoading = false;
          res.data.map((el:any)=>{
            Object.keys(el).map((e:any)=>{
              if(['lease_rate_range','unit_size_range'].includes(e)){
                el[e] = this.reportService.rangeToNumberFor(el[e]);
              }
              if(el.details && el.details.length>0){
                el.details.map((detail:any)=>{
                  Object.keys(detail).map((key:any)=>{
                    if(['lease_rate_range','unit_size_range'].includes(key)){
                      detail[key] = this.reportService.rangeToNumberFor(detail[key]);
                    }
                  })
                })
              }
            })
          })
          this.departmentsdata = res.status === 200 ? res.data : [];         
          if(this.reportRetailInventoryService.departmentsPayload.pageNumber == 0){
            this.departmentsCount = res.status === 200 ? res.count : 0;
            this.overViewCount(RetailInventoryMenuEnum.departments, res.status === 200 ?  this.reportService.formatInternationNum(this.departmentsCount) : 0)
          }
         
        },
        error:()=>{
          this.departmentsLoading = false;
          this.departmentsdata = [];
          this.departmentsCount = 0;
          this.overViewCount(RetailInventoryMenuEnum.departments, 0)
        }
      }
    )
  }

  getTentants(){
    this.tentantsLoading = true;
    this.reportRetailInventoryService.getTenants(this.reportRetailInventoryService.tentantsPayload).subscribe(
      {
        next: (res: any) => {
          this.tentantsLoading = false;
          res.data.map((el:any)=>{
            Object.keys(el).map((e:any)=>{
              if(['lease_rate_range','unit_size_range'].includes(e)){
                el[e] = this.reportService.rangeToNumberFor(el[e]);
              }
            })
          })
          this.tentantsdata = res.status === 200 ? res.data : [];
          if(this.reportRetailInventoryService.tentantsPayload.pageNumber == 0){
            this.tentantsCount = res.status === 200 ? res.count : 0;
            this.overViewCount(RetailInventoryMenuEnum.tenants, res.status === 200 ?  this.reportService.formatInternationNum(this.tentantsCount) : 0)
          }
         
        },
        error:()=>{
          this.tentantsLoading = false;
          this.tentantsdata = [];
          this.tentantsCount = 0;
          this.overViewCount(RetailInventoryMenuEnum.tenants, 0)
        }
      }
    )

  }

  getUnits(){
    this.unitsLoading = true;
    this.reportRetailInventoryService.getUnits(this.reportRetailInventoryService.unitPayload).subscribe(
      {
        next: (res: any) => {
          this.unitsLoading = false;
          this.unitsdata = res.status === 200 ? res.data : [];
          if(this.reportRetailInventoryService.unitPayload.pageNumber == 0){
            this.unitsCount = res.status === 200 ? res.count : 0;
            this.overViewCount(RetailInventoryMenuEnum.unit, res.status === 200 ?  this.reportService.formatInternationNum(this.unitsCount) : 0)
          }
          
        },
        error:()=>{
          this.unitsLoading = false;
          this.unitsdata = [];
          this.unitsCount = 0;
          this.overViewCount(RetailInventoryMenuEnum.unit, 0)
        }
      }
    )
  }

  getOpacity(index:number, value: any): string{
    // Adjust the factor and starting value as per your requirement
    return this.changeOpacity(index, value);
  }


  getNumber(nbr: any){
    return +nbr;
  }
  changeOpacity(index: number, value: any){

    if(this.neightMap.has(value)){
      return this.neightMap.get(value);
    } else {
      this.neightMap.set(value, this.getOpacityVal(index));
      return this.getOpacityVal(index);
    }

  }

  getOpacityVal(index:number): string{
    // Adjust the factor and starting value as per your requirement
    return (1 - (index * 0.1)).toFixed(2);
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.retailInventoryMenuEnum.properties) {
        this.reportRetailInventoryService.propertiesPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getProperties();
      } else if (report === this.retailInventoryMenuEnum.departments)  {
        this.reportRetailInventoryService.departmentsPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getDepartments();
      } else if (report === this.retailInventoryMenuEnum.tenants)  {
        this.reportRetailInventoryService.tentantsPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getTentants();  
      } else {
        this.reportRetailInventoryService.unitPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getUnits();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.retailInventoryMenuEnum.properties) {
      this.reportRetailInventoryService.propertiesPayload.pageNumber = 0;
      this.reportRetailInventoryService.propertiesPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getProperties();
    }  else if (report === this.retailInventoryMenuEnum.departments)  {
        this.reportRetailInventoryService.departmentsPayload.pageNumber = 0;
        this.reportRetailInventoryService.departmentsPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
        this.getDepartments();
    }  else if (report === this.retailInventoryMenuEnum.tenants)  {
        this.reportRetailInventoryService.tentantsPayload.pageNumber = 0;
        this.reportRetailInventoryService.tentantsPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
        this.getTentants();    
    } else {
        this.reportRetailInventoryService.unitPayload.pageNumber = 0;
        this.reportRetailInventoryService.unitPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
        this.getUnits();
    }
  }

  selectedYear(data: any){
    if (data.reportType === 'properties') {
      this.reportRetailInventoryService.propertiesPayload.pageNumber = 0;
      this.reportRetailInventoryService.propertiesPayload.year = data.year;
      this.getProperties();
    }else if(data.reportType === 'departments'){
      this.reportRetailInventoryService.departmentsPayload.pageNumber = 0;
      this.reportRetailInventoryService.departmentsPayload.year = data.year;
      this.getDepartments();
    }else if(data.reportType === 'tenants'){
      this.reportRetailInventoryService.tentantsPayload.pageNumber = 0;
      this.reportRetailInventoryService.tentantsPayload.year = data.year;
      this.getTentants();  
    }else{
      this.reportRetailInventoryService.unitPayload.pageNumber = 0;
      this.reportRetailInventoryService.unitPayload.year = data.year;
      this.getUnits();
    }
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    this.activeTab = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }

  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.reportRetailInventoryService.downloadInvestmentReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=retail_inventory`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }

  trendsdropDownChange(event: any){
    this.trendsText = `reports.${event}`;

    this.trendsData =  this.trendslist.map((data: any) => {
      return {
        category: data.year.toString(),
        value: event === 'occupancy_trends' ? data.occupancy : data.vacancy
      }
    });
  }

  neighInsightDropdown(event: any){
    this.neighType = event;
    let type = event === 'gla_share' ? 'gla': 'count';
    this.neighbourhodinsightText = event === 'gla_share' ? 'reports.gla_share' : 'reports.countOf_malls';
    this.getNeighbhoodGlaShare(type);
  }

  departmentDropdownChange(value: any){
    if (value === 'Range') {
      this.departmentsColumns = RetailInventoryReportConstant.departmentsColumns;
      this.departmentExpandColumns = RetailInventoryReportConstant.departmentExpandColumns;
      this.updateColumnsDepartments(value);
    } else {
      this.departmentsColumns = RetailInventoryReportConstant.departmentsAverageColumns;
      this.departmentExpandColumns = RetailInventoryReportConstant.departmentAverageExpandColumns;
      this.updateColumnsDepartments(value);
    }
  }

  tenantDropdownChange(event: any){
    if(event === 'Range'){
      this.tentantsColumns = RetailInventoryReportConstant.tentantsColumns;
      this.updateColumnsTenants(event);
    } else {
      this.tentantsColumns = RetailInventoryReportConstant.tentantsAverageColumns;
      this.updateColumnsTenants(event);
    }
  }

  ngOnDestroy(): void {
    this.transactionPropertyDetails$?.unsubscribe();
  }

}
