import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { DecimalPipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { I18nModule } from '../../../i18n/i18n.module';
import { SharedModule } from '../../../common/shared.module';

import { ReportTableViewComponent } from './components/report-table-view/report-table-view.component';
import { ReportChartViewComponent } from './components/report-chart-view/report-chart-view.component';
import { ReportYearDropdownComponent } from './components/report-year-dropdown/report-year-dropdown.component';
import { ReportSubMenuComponent } from './components/report-sub-menu/report-sub-menu.component';
import { ReportDatatableComponent } from './components/report-datatable/report-datatable.component';

import { PaginationDirective } from '../../../common/directive/paginator.directive';
import { ReportChartsComponent } from './components/report-charts/report-charts.component';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-int';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LineSeriesChartComponent } from './components/report-charts/components/line-series-chart/line-series-chart.component';
import { ReportDatatableExpandedComponent } from './components/report-datatable-expanded/report-datatable-expanded.component';

@NgModule({
  declarations: [
    ReportTableViewComponent,
    ReportChartViewComponent,
    ReportYearDropdownComponent,
    ReportSubMenuComponent,
    ReportDatatableComponent,
    ReportDatatableExpandedComponent,
    ReportChartsComponent,
    LineSeriesChartComponent,
    
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedModule,
    MatIconModule,
    HttpClientModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    PaginationDirective,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ReportTableViewComponent,
    ReportChartViewComponent,
    ReportYearDropdownComponent,
    ReportDatatableComponent,
    ReportDatatableExpandedComponent,
    ReportSubMenuComponent,
    ReportChartsComponent,
    LineSeriesChartComponent,
  ],
  providers: [
    DecimalPipe,
    {
      provide: MatPaginatorIntl, 
      useClass: CustomMatPaginatorIntl
    }
  ]
})

export class ReportsPreviewModule { }
