import { Injectable } from '@angular/core';
import moment from 'moment';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';
import { ApiService } from 'src/app/common/services/api.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportOccupancyRentalService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }
  residentialGradePayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  residentialTypePayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  commercialGradePayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  commercialSizePayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  transactionPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  unitPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  unitTransactionPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  /**
   *
   * @returns
   * OverView Data
  */
  getOverview(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  /**
   *
   * @returns
   * OverView Data
  */
  getOverviewGrade(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewSubCategoryDetailsReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  /**
   *
   * @returns
   * OverView Data
   */
  getOverviewGraph(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    delete queryParams['property_grade']
    const changeParams = this.reportService.setfilterQueryParams(queryParams);

    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalRentRateOverviewGraphdataReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  /**
   * 
   * @param payload 
   * @returns 
   * Residential Units - Grade wise
   */
  getResidentialGrade(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.residentialGradePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyResidentialUnitsGradewiseReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.residentialGradePayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  /**
   * 
   * @param payload 
   * @returns 
   * Residential Units - Grade wise
   */
  getResidentialType(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.residentialTypePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyResidentialUnitstypewiseReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.residentialTypePayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  /**
   * 
   * @param payload 
   * @returns 
   * Residential Units - Grade wise
   */
  getCommercialGrade(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.commercialGradePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyCommercialUnitsGradewiseReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.commercialGradePayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  /**
   * 
   * @param payload 
   * @returns 
   * Commercial units - size wise
   */
  getCommercialSize(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.commercialSizePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyCommercialUnitsSizewiseReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.commercialSizePayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  /**
   * 
   * @param payload 
   * @returns 
   * Commercial units - size wise
   */
  getTransactionSize(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.transactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyUnitRentalTransactionsReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.transactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  downloadInvestmentReport(type:string, user_id:any, property_uid:string='', proj_uid:string=''){
    let url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    if(property_uid) url += `&property_uid=${property_uid}`;
    if(proj_uid) url += `&project_uid=${proj_uid}`;
    return this.apiServive.get(url);
  }
  getPropertyAreaSummary(queryParams:any, uid:string) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    if(queryParams.year == null){
      queryParams['year'] = new Date().getFullYear();
    }
    
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.inventoryAreaPropertyReport}${changeParams}&property_uid=${uid}`;
    return this.apiServive.get(url, false);
  }
  getOverviewPropertySummary(uid:string) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.inventoryOverviewPropertyReport}${changeParams}&property_uid=${uid}`;
    return this.apiServive.get(url, false);
  }
  formatOverViewPropertySummary(data: any) {

    data['totalOccupancy'] = this.reportService.calcPercentage(data.prev_occupancy_rate, data.occupancy_rate);
    data['totalResidential'] = this.reportService.calcPercentage(data.prev_residential_units, data.residential_units);
    data['totalCommercial'] = this.reportService.calcPercentage(data.prev_commercial_units, data.commercial_units);
    data['untillDate'] = data.max_date ? moment.utc(data.max_date).local().format('DD MMM YYYY') : null;
    return data;
  }
  getUnitReport(payload: any, uid:string) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.unitPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyPropertyUnitReport}${changeParams}&property_uid=${uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.unitPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  getUnitTransctionReport(payload: any, uid:string) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.unitTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.occupancyPropertyUnitTransactionsReport}${changeParams}&property_uid=${uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.unitTransactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
}
