import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ReportPropertyDetailComponent } from './report-property-detail/report-property-detail.component';
import { ReportPropertyInfoComponent } from './report-property-detail/components/report-property-info/report-property-info.component';
import { BuildUpInfoComponent } from './report-property-detail/components/build-up-info/build-up-info.component';
import { ReportsPreviewModule } from '../../../reports-preview/reports-preview.module';
import { I18nModule } from 'src/app/i18n/i18n.module';
import { CostOfConstructionComponent } from './report-property-detail/components/cost-of-construction/cost-of-construction.component';
import { PropertyAmenitiesComponent } from './report-property-detail/components/property-amenities/property-amenities.component';
import { ResidentialUnitsComponent } from './report-property-detail/components/residential-units/residential-units.component';
import { UnitTransactionComponent } from './report-property-detail/components/unit-transaction/unit-transaction.component';
import { PropertyTransactionComponent } from './report-property-detail/components/property-transaction/property-transaction.component';
import { ResidentialRentalincomeAssessementComponent } from './report-property-detail/components/residential-rentalincome-assessement/residential-rentalincome-assessement.component';
import { LandValueDetailsComponent } from './report-property-detail/components/land-value-details/land-value-details.component';
import { SharedModule } from 'src/app/common/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ReportPropertyDetailComponent,
    ReportPropertyInfoComponent,
    BuildUpInfoComponent,
    CostOfConstructionComponent,
    PropertyAmenitiesComponent,
    ResidentialUnitsComponent,
    UnitTransactionComponent,
    PropertyTransactionComponent,
    ResidentialRentalincomeAssessementComponent,
    LandValueDetailsComponent
  ],
  imports: [
    CommonModule,
    ReportsPreviewModule,
    I18nModule,
    SharedModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports:[
    ReportPropertyDetailComponent,
    ReportPropertyInfoComponent,
    BuildUpInfoComponent,
    CostOfConstructionComponent
  ]
})
export class ReportCommonInfoModule { }
