import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportMapService } from '../../services/report-map.service';
import { ReportService } from '../../services/report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogBoxComponent } from 'src/app/common/components/confirm-dialog-box/confirm-dialog-box.component';
import { Subscription } from 'rxjs';
import { StoreService } from 'src/app/common/services/store.service';
import { UserService } from 'src/app/common/services/user.service';
import { actionIdConstants } from 'src/app/common/constants/common.contants';
import { ContactSalesComponent } from 'src/app/common/components/contact-sales/contact-sales.component';
import { array } from '@amcharts/amcharts5';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css']
})
export class ReportFilterComponent {

  filterList: any = []
  reportFilterForm!: FormGroup;
  queryParams: any;
  SilderValue: any = {};
  selectedReport: any;
  user_info!: any;
  @Output() reset_filter = new EventEmitter();
  unSubsZoom: Subscription = new Subscription();
  isPolygon: boolean = false;
  savedFilters: any;
  selectedFilterId: any;
  LockReport: boolean = true;
  applybtnlock: boolean = false;
  atLeastOneSubsLock: boolean = false;
  constructor(private mapService: ReportMapService,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private alertService: AlertService,
    private translateService: TranslateService,
    private dailog: MatDialog,
    private storeService: StoreService,
    private userservice: UserService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
      this.isPolygon = (this.queryParams?.polygon) ? true : false;
    })
    this.user_info = this.sharedService.getUserInfo()
    this.selectedReport = this.reportService.getCurrentReport();
    this.filterList = this.reportService.reportFilter;
    this.LockReport = this.reportService.getLockReport();
    if (this.filterList) {
      this.initForm();
    }
    this.isPolygon = (this.queryParams?.polygon) ? true : false;
    const selectedData = this.reportService.setFiltervalue(this.queryParams);
    this.getPatchValue(selectedData);
   
  }

  ngOnDestroy() {
    this.unSubsZoom.unsubscribe();
  }

  initForm() {
    //Add Lock
    this.reportService.filterSubscription();
    // let reportSubscription = this.reportService.userSubscription.find((x: any) => (x.group_id == this.queryParams?.gid) && (x.report_id == this.queryParams?.rid));
    
    if(Array.isArray(this.filterList)){
      this.applybtnlock = this.filterList.some((x: any) => (x.atLeastOneSubscription || x.isSubscription));
      this.atLeastOneSubsLock = this.filterList.some((x: any) => (x.atLeastOneSubsLock == true));
    }
    
    // reportSubscription?.filter?.map((userFilter: any) => {
    //   if(userFilter.dynamic_value == true){
    //     this.applybtnlock = true;
    //   }else{
    //     this.applybtnlock = userFilter.value.length > 0;
    //   }
    // });
     
    let form: any = {};
    this.filterList.map((reportFilter: any) => {
      if (reportFilter && reportFilter.type != 'hidden') {
        if (reportFilter.required) {
          form[reportFilter.parameter_name] = new FormControl('', Validators.required);
        } else {
          form[reportFilter.parameter_name] = new FormControl('');
        }
        if (reportFilter.type == 'range') {
          form[reportFilter.parameter_name] =
            this.fb.group({
              min: [0],
              max: [0],
            });
          if (!reportFilter.isSubscription) {
            form[reportFilter.parameter_name]?.disable();
          }
        }
      }
    })
    this.reportFilterForm = this.fb.group(form);
  }

  getPatchValue(selectedData: any) {
    delete selectedData['gid'];
    delete selectedData['rid'];
    delete selectedData['oid'];
    this.SilderValue = {}
    if (selectedData) {
      let ftype: any;
      for (let key in selectedData) {
        ftype = this.selectedReport.filter.find((e: any) => e.parameter_name == key);
        if (ftype && ftype.type == 'multi_select') {
          if (selectedData[key]?.includes(',')) {
            this.reportFilterForm.patchValue({ [key]: selectedData[key].split(',') });
          } else {
            this.reportFilterForm.patchValue({ [key]: selectedData[key] ? [selectedData[key]] : '' });
          }
        }
        if (ftype && ftype.type == 'single_select') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : '' });
        }
        if (ftype && ftype.type == 'range') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : { min: "0", max: "0" } });
          this.SilderValue[key] = selectedData[key];
        }
        if (ftype && ftype.type == 'text') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : '' });
        }
        if (ftype && ftype.type == 'date_range') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : '' });
        }
      }

    }
  }

  layerToggle() {
    this.mapService.mapFiltertoggle.next(false);
  }

  applyFilter(value: any) {
    const controls = this.reportFilterForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        let ftype: any = this.filterList.find((e: any) => e.parameter_name == name);
        if (ftype.type == "range") {
          this.alertService.warn(`${ftype.label[this.sharedService.getCurrentLangID()] + " " + this.translateService.instant('reports.invalid')}`);
        } else {
          this.alertService.warn(`${ftype.label[this.sharedService.getCurrentLangID()] + " " + this.translateService.instant('reports.is_required')}`);
        }
      }
    }
    for (const name in controls) {
      if (name == 'year') {
        let val = this.reportFilterForm.value.year;
        if (val?.length > environment.max_year_filter) {
          this.alertService.warn(`${this.translateService.instant('reports.year_cannot_10')} ${environment.max_year_filter}`)
          return;
        }
      }
    }
    if (this.reportFilterForm.invalid) return;
    const filterUrl = this.reportService.getFilterInURL(
      this.reportFilterForm.value
    );

    let mergerparam = { ...this.queryParams, ...filterUrl }
    for (let key in mergerparam) {
      if (mergerparam[key] == '' || mergerparam[key] == null || mergerparam[key] == undefined || mergerparam[key] == '0-0') {
        delete mergerparam[key];
      }
    }

    this.unSubsZoom = this.mapService.zoom.subscribe(res => {
      mergerparam['zoom'] = res;
    })
    if (this.mapService.getCentroid()) mergerparam['centroid'] = this.mapService.getCentroid();
    delete mergerparam['Zoom'];
    delete mergerparam['Centroid'];
    this.reportService.filterFlag = true;  // hold the user activity on router change
    this.router.navigate([`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`], {
      queryParams: mergerparam,
    });
    let filters = { ...this.reportFilterForm.value }
    if (this.queryParams.property_category) {
      filters['property_category'] = this.queryParams.property_category
    }
    if (this.queryParams.unit_category) {
      filters['unit_category'] = this.queryParams.unit_category
    }
    // Track user activity for filter application
    const activity = {
      userdata: { filter: filters },
      actionId: actionIdConstants.Apply_Filter,
      entityId: this.selectedReport.id,
      entity: 'Appliedfilter',
    }
    this.userservice.logUserActivity(activity);
    // this.savefilterValue(mergerparam);
    this.mapService.reportFiltertoggle.next(true); //// for when filter is being applied
    this.layerToggle();
    // this.sharedService.clearSession('filter');
  }


  getMaxValue(rangeValue: string) {
    if (rangeValue) {
      const val = rangeValue.split('|')
      if (val[1]) {
        return Number(val[1]);
      } else {
        const a = val[0].split('-');
        if (a[1]) {
          return Number(a[1]);
        }
      }
    }
    return 50000;
  }


  resetFilter() {
    if (this.filterList?.length == 0) return;
    let _data = {
      parent_data: null,
      message: this.translateService.instant("reports.Do_you_want_reset_all"),
    };
    this.dailog.open(ConfirmDialogBoxComponent, {
      width: '400px',
      height: 'auto',
      data: _data
    }).afterClosed().subscribe(res => {
      if (res) {
        this.reset_filter.emit()
        this.storeService.clearSessionData('filter')
        this.layerToggle();
      }
    })
  }
  /**
     * This is a fuction to open Contact Sales Form
     */
  openContactSales() {
    this.dailog.open(ContactSalesComponent, {
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type: 'contact_us' }
    })
  }
}
