import { ReportTableColumn } from "../../models/reports.model";

export class UnitReportConstant {

    static reports = {
        investment_unitreport: 'investment_unitreport',
        unit_grade: 'unit_grade',
        unit_type: 'unit_type',
        furnished: 'furnished',
        numberOfunits:'numberOfunits'
    }

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]

    static totalUnitColumn: ReportTableColumn[] = [
        {
            header: 'reports.noofProperties',
            field: 'total_property',
        },
        {
            header: 'reports.noofUnits',
            field: 'total_unit',
        },
        {
            header: 'reports.avgMonthlyRent',
            field: 'average_monthly_rent',
            cityCode: true
        }
    ]

    static tableColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.unitType',
            field: 'category',
            border: false
        }
    ]
}
