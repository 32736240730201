import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-save-polygon',
  templateUrl: './save-polygon.component.html',
  styleUrls: ['./save-polygon.component.scss']
})
export class SavePolygonComponent {

  message: string = '';
  class = '';
  type: string = '';
  inputText = new FormControl('', [Validators.required, Validators.maxLength(20)]);;
  errorMessage = '';

  constructor(
    public dialogRef: MatDialogRef<SavePolygonComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.message = this.info.message;
    this.class = this.info.class
    this.type = this.info.type ? this.info.type : '';
    merge(this.inputText.statusChanges, this.inputText.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessage());
  }

  confirmDialogBox(check: boolean) {
    let value;
    if (check) {
      if (this.inputText.valid) {
        value = [check, this.inputText.value]
        this.dialogRef.close(value);
      } else this.inputText.markAsTouched();
    }
    else {
      value = check;
      this.dialogRef.close(value);
    }
  }

  updateErrorMessage() {
    if (this.inputText.hasError('required')) {
      this.errorMessage = 'You must enter a value';
    } else if (this.inputText.errors?.['maxlength'] ) {
      this.errorMessage = 'Maximum Characters cannot exceed 20';
    } else {
      this.errorMessage = '';
    }
  }
}
