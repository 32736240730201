import { DataType } from "src/app/common/models/enums/common.enum";
import { ReportTableColumn } from "../../reports-lists/models/reports.model";
import { projectMenuEnum } from "./report-project-menu-enum";

export class ProjectConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: projectMenuEnum.overview
        },
        {
            label: 'reports.landParcelTable',
            name: projectMenuEnum.landParcelTable
        },
        {
            label: 'reports.propertyDetailsHouses',
            name: projectMenuEnum.propertyDetailsHouses
        },
        {
            label: 'reports.propertyDetailsOthers',
            name: projectMenuEnum.propertyDetailsOthers
        }
    ]
    //Land Parcel Table Columns
    static landParcelColumns: ReportTableColumn[] = [
        {
            header: 'parcel_number',
            columnName: 'reports.parcelNumber',
            field: 'parcel_number',
            isSortable: true,
            border: false,
            isContainsHTML: false,
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'zoning',
            columnName: 'reports.zoning_label',
            field: 'zoning',
            isSortable: true,
            border: false,
            isContainsHTML: false,
        }
    ]
    //Property Details - Houses Columns
    static propertyHousesColumns: ReportTableColumn[] = [
        {
            header: 'type',
            columnName: 'reports.type',
            field: 'type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
        },
        {
            header: 'block',
            columnName: 'reports.block',
            field: 'block',
            border: false,
            isSortable: true,
        },
        {
            header: 'builtup_area',
            columnName: 'reports.builtUpArea',
            field: 'builtup_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },
        {
            header: 'no_of_floors',
            columnName: 'reports.no_of_floor',
            field: 'no_of_floors',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'no_of_bedrooms',
            columnName: 'reports.no_of_bedroom',
            field: 'no_of_bedrooms',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'no_of_bathrooms',
            columnName: 'reports.no_of_bathroom',
            field: 'no_of_bathrooms',
            isSortable: true,
            border: false,
            isContainsHTML: false
        }
    ]
}