<div class="report-model detail-info">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.residentialUnits' | translate}}</h3>
            <span class="totalTransaction">({{ residentialUnitsCount }})</span>
        </div>

      
    </div>

    <app-report-datatable 
        [showLoader]="!residentialUnitsLoading" 
        [tableColumns]="residentialColumns" 
        [dataLength]="residentialUnitsCount"
        [tableData]="residentialUnitsData" 
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event)" 
        (sort)="sortData($event)"
        (excelDownload)="downloadExcel($event, 'ir_inventory_unit')">
    </app-report-datatable>

</div>    