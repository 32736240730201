import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { SharedService } from '../../../../../common/services/shared.service';
import { GlobalContants } from '../../../../../common/constants/global.contants';
import { ExcelDownloadService } from '../../../../../common/services/excel-download.service';
import { ReportService } from '../../../../../reports/services/report.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { Subscription } from 'rxjs';
import { ContactSalesComponent } from 'src/app/common/components/contact-sales/contact-sales.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-table-view',
  templateUrl: './report-table-view.component.html',
  styleUrls: ['./report-table-view.component.scss']
})
export class ReportTableViewComponent implements OnInit {

  @Input() tableColumn: any = [];
  @Input() dataValue: any = [];
  @Input() expandable: boolean = false;
  @Input() singleColumn: boolean = false;
  @Input() reportName: string = 'download';
  @Input() isDownloadExcel: boolean = true;

  currentReportData: any = null;
  allowDownload: boolean = false;

  cityCurrencyCode: string | any = null;
  current_lang: any = 'en';

  languageSub$: Subscription = new Subscription();
  LockReport: boolean=true;

  total:number=0;

  constructor(
    private sharedService: SharedService,
    private excelDownloadService: ExcelDownloadService,
    public translateService: TranslateService,
    private _decimalPipe: DecimalPipe,
    public reportService: ReportService,
    private alertService: AlertService,
    private matdailog : MatDialog
  ) {
    this.languageSub$ = this.sharedService.language$.subscribe((lang) => {
      this.current_lang = lang;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.LockReport = this.reportService.getLockReport();
    this.total = this.dataValue.length;
    if(this.LockReport){
      this.dataValue = this.dataValue.slice(0,3);      
      if(this.dataValue.length>0){
        let keys= Object.keys(this.dataValue[0])
        for(let i=0; i < 3; i++){
          let temp:any = {};
          keys.map(e=>{
            temp[e]=this.dataValue[0][e],
            temp['NODATA'] = true;
          })
          this.dataValue.push(temp);
        }  
      }     
    }
  }

  
  /**
   * This is a fuction to open Contact Sales Form
   */
  openContactSales(){
    this.matdailog.open(ContactSalesComponent,{
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type : 'contact_us'}
    })
  }

  ngOnInit(): void {
    this.cityCurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.currentReportData = this.reportService.getCurrentReport();
    this.setDownloadAccess();
  }

  setDownloadAccess() {
    let subscription = this.reportService.userSubscription.find((sub: any) => sub.report_id === this.currentReportData?.id);
    if (subscription) {
      this.allowDownload = subscription.allow_download && this.currentReportData?.is_download;
    }
  }

  expandPlus(data: any) {
    data.expand = true;
  }

  expandMinus(data: any) {
    data.expand = false;
  }

  downloadExcel() {
    if(this.reportService.getLockReport()){
      return;
    }
    if (!this.allowDownload) {
      this.alertService.warn(this.translateService.instant('reports.disableDownloadAccessMsg'));
      return;
    }

    let excelData: any = [];
    let Sno = this.translateService.instant('Sno');
    let header: any = [Sno];
    this.tableColumn.forEach((element: any) => {
      header.push(this.translateService.instant(`${element.header}`))
    });
    excelData.push(header);

    let reportData = [...this.dataValue];

    if (reportData.length > 0) {
      reportData.forEach((value: any, i) => {
        let items: any = this.expandable ? [''] : [];

        if (value.subReport?.length > 0) {
          let subReport = [...value.subReport];
          subReport.forEach((value: any, index) => {
            let subReportItems: any = [];
            subReportItems[0] = (index === 0) ? i + 1 : '';

            this.tableColumn.forEach((col: any) => {
              subReportItems.push(this.setExcelValue(col, value));
            });
            excelData.push(subReportItems);
          });
        }

        if (!this.expandable) {
          items.push(i + 1)
        }

        this.tableColumn.forEach((col: any, colIndx: number) => {
          if (colIndx === 0) {
            this.expandable ? items.push('') : items.push(this.setExcelValue(col, value));
          } else {
            items.push(this.setExcelValue(col, value));
          }
        });
        excelData.push(items)
      })
    }
    let reportName = this.translateService.instant(`${this.reportName}`)
    this.excelDownloadService.exportexcel(excelData, this.tableColumn, reportName);
  }

  setExcelValue(col: any, value: any) {
    let val = value[col.field] ? value[col.field] : '--';
    let symbol: any = '';
    if (col?.convertNumber) {
      val = value[col.field] ? this._decimalPipe.transform(val, '1.0-1') : '--';
    }
    if (val !== '--') {
      if (col?.cityCode) {
        symbol = this.translateService.instant(this.cityCurrencyCode);
      } else if (col?.symbol) {
        symbol = col.symbol;
      }
    }
    return `${val}${symbol}`;
  }

}
