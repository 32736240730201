import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportPopulationService } from './report-population.service';
import { ReportsListsService } from '../../services/reports-lists.service';
import { PopulationReportConstant } from './report-population.constants';
import { ReportTableColumn } from '../../models/reports.model';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-population',
  templateUrl: './report-population.component.html',
  styleUrls: ['./report-population.component.scss']
})
export class ReportPopulationComponent implements OnInit, OnChanges {

  @Input() params: any;

  chartView = ReportsChartEnum;

  populationReports = PopulationReportConstant.populationReports;

  totalpopulationReport: any = [];
  totalpopulationReportColumn: ReportTableColumn[] = PopulationReportConstant.totalpopulationColumn;
  populationAgeWiseColumns: ReportTableColumn[] = PopulationReportConstant.populationAgeWiseColumns;
  populationAgeWiseData: any = [];
  populationYearWiseReport: any = [];
  populationTrendlineReport: any = [];

  isFirstTime = true;
  populationAgeWiseDataLoading = false;
  totalLoading= false;
  populationYearWiseReportLoading = false;

  constructor(
    private reportPopulationService: ReportPopulationService,
    private reportsListsService: ReportsListsService,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  getReports() {
    this.getPopulationTotalReport();
    this.getPopulationYearWiseReport();
    this.getPopulationTrendlineReport();
    this.getPopulationAgeWiseReport();
  }

  getPopulationTotalReport(year = null) {
    this.totalLoading= true;
    this.reportPopulationService.getPopulationTotalReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.totalpopulationReportColumn = [...this.reportsListsService.yearColumnAppend(PopulationReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
            this.totalpopulationReport = this.reportsListsService.summaryReportConversion(res.data, PopulationReportConstant.totalpopulationColumn)
          } else {
            this.totalpopulationReport = [];
          }
          this.totalLoading= false;
        },
        error: (error: any) => {
          this.totalpopulationReport = [];
          this.totalLoading= false;
        }
      }
    )
  }

  getPopulationYearWiseReport(year = null) {
    this.populationYearWiseReportLoading = true;
    this.reportPopulationService.getPopulationYearWiseReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            res?.data.map((ele: any) => {
              ele['sortnum'] = ele.age_group.includes('-')
                ? Number(ele.age_group.replace('-', ''))
                : ele.age_group;
            });
            res?.data.sort((a: any, b: any) => a['sortnum'] - b['sortnum']);
            let data = res?.data.map((val: any) => {
              return {
                category: val.age_group,
                value: Number(-val.male),
                value1: Number(val.female),
              };
            });
            this.populationYearWiseReport = data;
          } else {
            this.populationYearWiseReport = [];
          }
          this.populationYearWiseReportLoading = false;
        },
        error: (error: any) => {
          this.populationYearWiseReport = [];
          this.populationYearWiseReportLoading = false;
        }
      }
    )
  }

  getPopulationTrendlineReport() {
    this.reportPopulationService.getPopulationTrendlineReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.populationTrendlineReport = res.data.map((val: any) => {
              return {
                category: val.year.toString(),
                value1: val.male,
                value2: val.female,
                total: Number(val.total_population),
              };
            });
          } else {
            this.populationTrendlineReport = [];
          }
        },
        error: (error: any) => {
          this.populationTrendlineReport = [];
        }
      }
    )
  }

  getPopulationAgeWiseReport() {
    this.populationAgeWiseDataLoading = true;
    this.reportPopulationService.getPopulationAgeWiseReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['neigh_name', 'age_group'];
            if (res?.data?.year.length > 0) {
              this.populationAgeWiseColumns = [...this.reportsListsService.yearColumnAppend(PopulationReportConstant.populationAgeWiseColumns, res?.data.year)];
            }
            this.populationAgeWiseData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);
          } else {
            this.populationAgeWiseData = [];
          }
          this.populationAgeWiseDataLoading = false;
        },
        error: (error: any) => {
          this.populationAgeWiseData = [];
          this.populationAgeWiseDataLoading = false;
        }
      }
    )
  }

  selectedYear(data: any) {
    if (data.reportType === 'totalReport') {
      this.getPopulationTotalReport(data.year);
    } else {
      this.getPopulationYearWiseReport(data.year);
    }
  }
}
