import { Component, effect, OnInit } from '@angular/core';
import { ReportMapService } from '../../services/report-map.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from '../../services/report.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-report-layout',
  templateUrl: './report-layout.component.html',
  styleUrls: ['./report-layout.component.css']
})
export class ReportLayoutComponent implements OnInit {
  panelOpenState = false;
  basemap_select: string = this.mapService.mapLayer_current();
  public legendLayerGroup: any = [];
  public layerData: any = [];
  current_lang: any = 'en';
  current_lang_id: number = 1;
  mapLayers: any[] = [];
  studyName: any;

  constructor(public reportService: ReportService, private mapService: ReportMapService, private sharedService: SharedService,
    private alertService: AlertService,
    private translateService:TranslateService
  ) {
    this.sharedService.language$.subscribe((lang) => {
      this.current_lang_id = lang == 'ar'?2:1;
      this.current_lang = lang;
    });
    effect(()=> this.basemap_select= this.mapService.mapLayer_current())
  }

  ngOnInit(): void {
    this.mapService.legendLayerGroupList.pipe(
      distinctUntilChanged()
    ).subscribe((item:any)=>{
      if(item){
        this.legendLayerGroup = item;
      }
    })
    this.reportService.selectedReport$.subscribe((data) => {
      this.studyName = this.reportService?.selectedStudies?.study_name;
      if (data.flag) {
        let layer_data = this.reportService.getCurrentReport();
        if (layer_data) {
          this.layerData = layer_data.layer;
          if(!this.legendLayerGroup?.length){
            this.legendLayerGroup = layer_data.legend?.map((p:any) => {
              return {
                ...p,
                data: p.data.filter((item:any) => item.layer_status === true)
              };
            });
          }
          this.mapLayers = layer_data.map_layer;
          // .map((item:any)=>{
          //   if(item.layer_name == 'gisdb:allparcels'){
          //     return {
          //       ...item,
          //       display:true
          //     }
          //   } else {
          //     return item
          //   }
          // });
          // this.mapLayers = layer_data.map_layer;
          // this.layerData = this.mapService.getLayerData(); /// for testing only
          // this.mapLayers = this.mapService.getLayerData().map_layers; /// for testing only
          // this.mapService.legendLayer.next(this.layerData?.legend.filter((r: any) => r.display == true));
        }
      }
    })
  }

  layerToggle() {
    this.mapService.mapLayertoggle.next(false);
  }

  selectedLegend(data: any) {
    if(this.reportService.getLockReport()){
      return;
    }
    // 
    if (!data.display) {
      data.display = !data.display;
      this.legendLayerGroup = this.legendLayerGroup.map((r: any) => {
        if(r.display == true && data.legend_type  == r.legend_type){
          return r;
        } else {
          return {
            ...r,
            display: false
          }
        }
      });
      this.mapService.legendLayerGroupList.next(this.legendLayerGroup);
      this.mapService.legendLayerGroup.next(this.legendLayerGroup.filter((r: any) => r.display == true));
      this.mapService.legendToggle.next(true);
    }
    return;
    // legend Group 
    if(this.legendLayerGroup.filter((r: any) => r.display == true).length == 2 && data.display == false){
      if(this.studyName != 'apartment' && this.studyName != 'house_and_villa'){
        this.alertService.warn(this.translateService.instant('reports.property_max2'))
      }
    }
    if (this.legendLayerGroup.filter((r: any) => r.display == true).length == 1) {
      if (!data.display) {
        data.display = !data.display;
        this.mapService.legendLayerGroup.next(this.legendLayerGroup.filter((r: any) => r.display == true));
        if (this.legendLayerGroup.filter((r: any) => r.display == true).length > 1) this.mapService.legendToggle.next(true);
      }
      return;
    }
    if (this.legendLayerGroup.filter((r: any) => r.display == true).length == 2) {
      if (data.display) { /// to avoid flickering of layers when already 2 selected and user is trying to select third property legend
        data.display = !data.display;
        this.mapService.legendLayerGroup.next(this.legendLayerGroup.filter((r: any) => r.display == true));
        if (this.legendLayerGroup.filter((r: any) => r.display == true).length > 1) this.mapService.legendToggle.next(true);
      }
      return;
    }
    
    
    data.display = !data.display;
    this.mapService.legendLayerGroup.next(this.legendLayerGroup.filter((r: any) => r.display == true));
    if (this.legendLayerGroup.filter((r: any) => r.display == true).length > 1) this.mapService.legendToggle.next(true);

    // End 
    return;
    // 
    if(this.layerData?.legend.filter((r: any) => r.display == true).length == 2 && data.display ==false){
      if(this.studyName != 'apartment' && this.studyName != 'house_and_villa'){
        this.alertService.warn(this.translateService.instant('reports.property_max2'))
      }
    }
    if (this.layerData.legend.filter((r: any) => r.display == true).length == 1) {
      if (!data.display) {
        data.display = !data.display;
        this.mapService.legendLayer.next(this.layerData.legend.filter((r: any) => r.display == true));
        if (this.layerData.legend.filter((r: any) => r.display == true).length > 1) this.mapService.legendToggle.next(true);
      }
      return;
    }
    if (this.layerData.legend.filter((r: any) => r.display == true).length == 2) {
      if (data.display) { /// to avoid flickering of layers when already 2 selected and user is trying to select third property legend
        data.display = !data.display;
        this.mapService.legendLayer.next(this.layerData.legend.filter((r: any) => r.display == true));
        if (this.layerData.legend.filter((r: any) => r.display == true).length > 1) this.mapService.legendToggle.next(true);
      }
      return;
    }
    
    
    data.display = !data.display;
    this.mapService.legendLayer.next(this.layerData.legend.filter((r: any) => r.display == true));
    if (this.layerData.legend.filter((r: any) => r.display == true).length > 1) this.mapService.legendToggle.next(true);
  }

  selectedBasemap(value: string) {
    if(value == this.mapService.mapLayer_current()) return;
    this.mapService.mapLayer_current.set(value)
    this.mapService.basemapLayer.next(value);
  }

  selectedLayers(value: any) {
    if(this.reportService.getLockReport()){
      return;
    }
    value.display = !value.display;
    this.mapService.overlay_mapLayer_current.set(value)
    this.mapService.overlay_mapLayer.next(this.mapLayers.filter((r: any) => r.display == true));
  }

  onImgError(event: any) {
    event.target.src = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";
  }

}
