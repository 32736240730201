import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';

import { ReportService } from 'src/app/reports/services/report.service';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportOccupancyLeaseService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  getTotalInventoryReport(year = null): Observable<any>{
   
    let url: string = `${environment.miUrl}${apiEndPoints.totalInventoryReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getInvestmentPropertyReport(){
    const url: string = `${environment.miUrl}${apiEndPoints.investmentPropertyReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getYearwiseOccupancyLeaseReport(){
    const url: string = `${environment.miUrl}${apiEndPoints.yearwiseOccupancyLeaseReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getOccupancyLeaseReport(type: string): Observable<any>{
    let url: string = `${environment.miUrl}${apiEndPoints.unitTypeOccupancyLeaseReport}${this.reportService.filterQueryParams}&type=${type}`;
    return this.apiServive.get(url);
  }

}
