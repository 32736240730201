import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { TransactionsMenuEnum } from "./report-transactions.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class TransactionsReportConstant {

    static transactionMenu = [
        {
            label: 'reports.overview',
            name: TransactionsMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.propertyTransactions',
            name: TransactionsMenuEnum.property_transaction,
            count: null
        },
        {
            label: 'reports.unitTransactions',
            name: TransactionsMenuEnum.unit_transaction,
            count: null
        }
    ]

    static propertyTransactionColumns: ReportTableColumn[] = [
      {
        header: 'property_name',
        columnName: 'reports.PropertyName',
        field: 'property_name',
        isSortable: true,
        border: false,
        isContainsHTML: false,
        showOnMap: true,
    },
    {
      header: 'transaction_date',
      columnName: 'reports.transactionDate',
      field: 'transaction_date',
      isSortable: true,
      border: false,
      isContainsHTML: false,
    },
    {
      header: 'transaction_type',
      columnName: 'reports.transactionType',
      field: 'transaction_type',
      border: false,
      isSortable: true,
      isContainsHTML: false,
    },
    {
      header: 'value',
      columnName: 'reports.value',
      field: 'value',
      border: true,
      isSortable: true,
      valSymbol: '',
      isContainsHTML: false,
      convertNumber: true,
      symbol: DataType.currencyCode,
    },
    {
        header: 'land_area',
        columnName: 'reports.land_area',
        field: 'land_area',
        border: false,
        isSortable: true,
        symbol: DataType.squareMeter,
        isContainsHTML: false,
        convertNumber: true
    },
    {
        header: 'transaction_rate',
        columnName: 'reports.property_transaction_rate',
        field: 'transaction_rate',
        border: false,
        isSortable: true,
        isContainsHTML: false,
        convertNumber: true,
        symbol: DataType.currencyWithSquareMeter,
    },
    {
        header: 'block',
        columnName: 'reports.block',
        field: 'block',
        border: false,
        isSortable: true,
        isContainsHTML: false
    },
    {
        header: 'neighborhood',
        columnName: 'reports.NEIGHBORHOODS',
        field: 'neighborhood',
        isSortable: true,
        border: false,
        isContainsHTML: false
    },
    {
        header: 'zone',
        columnName: 'reports.zone',
        field: 'zone',
        isSortable: true,
        border: false,
        isContainsHTML: false
    },
    {
        header: 'record_type',
        columnName: 'reports.record_type',
        field: 'record_type',
        border: false,
        isSortable: true,
        isContainsHTML: true,
    },
  //   {
  //     header: 'builtup_area',
  //     columnName: 'reports.builtUpArea',
  //     field: 'builtup_area',
  //     border: false,
  //     isSortable: true,
  //     isContainsHTML: false,
  //     symbol: DataType.squareMeter,
  //     convertNumber: true
  // }
]
  static propertyTransactionExpandColumns: any[] = [
    { field : 'property_name', convertNumber: false, blankColumn: true},
    { field : 'transaction_date', convertNumber: false },
    { field : 'transaction_type', convertNumber: false },
    { field : 'value', convertNumber: true, contentBg : true },
    { field : 'land_area', convertNumber: true },
    { field : 'transaction_rate', convertNumber: true },
    { field : 'block', convertNumber: false },
    { field : 'neighborhood', convertNumber: false },
    { field : 'zone', convertNumber: false },
    { field : 'record_type', convertNumber: false, isContainsHTML: true },
    // { field : 'builtUpArea', convertNumber: true }
  ]
    static housevillaProjectColumns: ReportTableColumn[] = [
        {
            header: 'type',
            columnName: 'reports.type',
            field: 'type',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'block',
            columnName: 'reports.block',
            field: 'block',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },
        {
            header: 'builtup_area',
            columnName: 'reports.builtup_area',
            field: 'builtup_area',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },
        {
            header: 'floor_number',
            columnName: 'reports.no_of_floor',
            field: 'floor_number',
            border: false,
            isSortable: true,
            isContainsHTML: true
        },
        // {
        //     header: 'balconies',
        //     columnName: 'reports.balconies',
        //     field: 'balconies',
        //     border: false,
        //     isSortable: true,
        //     isContainsHTML: false
        // },
        {
            header: 'no_of_bedroom',
            columnName: 'reports.no_of_bedroom',
            field: 'no_of_bedroom',
            border: false,
            isSortable: true,
            valSymbol: '',
            isContainsHTML: false,
        },
        {
            header: 'no_of_bathroom',
            columnName: 'reports.no_of_bathroom',
            field: 'no_of_bathroom',
            border: false,
            isSortable: true,
            valSymbol: '',
            isContainsHTML: false,
        }
    ]
    static unitTransactionColumns: ReportTableColumn[] = [
      {
          header: 'unit_uid',
          columnName: 'reports.unit_uid',
          field: 'unit_uid',
          isSortable: true,
          border: false,
          isContainsHTML: false,

      },
      {
        header: 'property_name',
        columnName: 'reports.PropertyName',
        field: 'property_name',
        border: false,
        isSortable: true,
        showOnMap: true
    },
      {
        header: 'transaction_date',
        columnName: 'reports.transactionDate',
        field: 'transaction_date',
        isSortable: true,
        border: false,
        isContainsHTML: false,
    },
    {
      header: 'transaction_type',
      columnName: 'reports.transactionType',
      field: 'transaction_type',
      border: false,
      isSortable: true,
      isContainsHTML: false,
  },
      {
          header: 'value',
          columnName: 'reports.value',
          field: 'value',
          border: true,
          isSortable: true,
          valSymbol: '',
          symbol: DataType.currencyCode,
          isContainsHTML: false,
          convertNumber: true
      },
      {
        header: 'unit_area',
        columnName: 'reports.unit_area',
        field: 'unit_area',
        border: false,
        isSortable: true,
        symbol: DataType.squareMeter,
        isContainsHTML: false,
        convertNumber: true
      },
      {
        header: 'transaction_rate',
        columnName: 'reports.transaction_rate',
        field: 'transaction_rate',
        border: false,
        isSortable: true,
        isContainsHTML: false,
        convertNumber: true,
        symbol: DataType.currencyWithSquareMeter,
      },
      {
        header: 'block_name',
        columnName: 'reports.block',
        field: 'block_name',
        border: false,
        isSortable: true,
        isContainsHTML: false
      },
      {
        header: 'neigh_name',
        columnName: 'reports.neighborhoods',
        field: 'neigh_name',
        isSortable: true,
        border: false,
        isContainsHTML: false
      },
    {
      header: 'zone',
      columnName: 'reports.zone',
      field: 'zone',
      isSortable: true,
      border: false,
      isContainsHTML: false
    },
    {
      header: 'record_type',
      columnName: 'reports.record_type',
      field: 'record_type',
      border: false,
      isSortable: true,
      isContainsHTML: true
    },
  ]
  static unitTransactionExpandColumns: any[] = [
    { field: 'unit_uid', convertNumber : false, blankColumn: true},
    {field: 'property_name',convertNumber : false, blankColumn: true},
    {field: 'transaction_date', convertNumber : false},
    {field: 'transaction_type',convertNumber : false},
    {field: 'value', convertNumber: true, contentBg : true},
    {field: 'unit_area', convertNumber: true},
    {field: 'transaction_rate', convertNumber: true},
    {field: 'block_name',convertNumber : false},
    {field: 'neigh_name', convertNumber : false},
    {field: 'zone', convertNumber: false},
    {field: 'record_type', convertNumber : false, isContainsHTML: true,}]

    static propertyTransactionHistoryColumns: ReportTableColumn[] = [
        {
            header: 'date_of_transaction',
            columnName: 'reports.date_of_transaction',
            field: 'date_of_transaction',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'type',
            columnName: 'reports.type',
            field: 'reportTypeBind',
            isSortable: true,
            border: false,
            isContainsHTML: true
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            valSymbol: '',
            isContainsHTML: false,
            convertNumber: true
        }
    ]

    static unitTransactionHistoryColumns: ReportTableColumn[] = [
        {
            header: 'date_of_transaction',
            columnName: 'reports.date_of_transaction',
            field: 'date_of_transaction',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'type',
            columnName: 'reports.type',
            field: 'type',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'floor_number',
            columnName: 'reports.floor',
            field: 'floor_number',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'unit_sub_type',
            columnName: 'reports.unit',
            field: 'unit_sub_type',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'unit_area',
            columnName: 'reports.unit_area',
            field: 'unit_area',
            symbol: DataType.squareMeter,
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            valSymbol: '',
            isContainsHTML: false,
            convertNumber: true
        }
    ]
}
