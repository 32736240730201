<div class="progress-bar" *ngIf="isReportLoaded">
  <span class="unlock-loader"><app-progress-bar></app-progress-bar></span>
</div>
<div class="saved-report" *ngIf="savedReport.length == 0">
  <ul class="recentReportLists">
    <li>{{'reports.no_record_found' | translate}}</li>
  </ul>
</div>
<div class="saved-report" *ngIf="savedReport.length">
    <ng-container *ngFor="let report of savedReport first as isFirst let i = index">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [togglePosition]="'before'" [expanded]="isFirst || openedReport == i">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{report.day | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngIf="report.data.length">
                <ul class="recentReportLists">
                  <div *ngFor="let child of report.data" class="d-flex list-container" [matTooltip]="child.report_name | langtranslate" [matTooltipPosition]="'after'">
                    <li class="recentReportList" [ngClass]="{ active: recentReport == child.id}" (click)="viewReport(child)">
                    <span> {{getJSONName(child.report_name)}}</span>
                  </li>
                  <button (click)="deleteReport(child, i)" class="delete">
                    <mat-icon svgIcon="deleteIcon"></mat-icon>
                  </button>
                </div>
                </ul>
              </ng-container>
            </mat-expansion-panel>
            </mat-accordion>
    </ng-container>

</div>
