import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportUnderConstructionService } from './report-under-construction.service';
import { ReportsListsService } from '../../services/reports-lists.service';
import { UnderConstructionReportConstant } from './report-under-construction.constants';
import { ReportTableColumn } from '../../models/reports.model';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-under-construction',
  templateUrl: './report-under-construction.component.html',
  styleUrls: ['./report-under-construction.component.scss']
})
export class ReportUnderConstructionComponent implements OnInit, OnChanges {

  @Input() params: any;

  totalUnderConstructionReport: any = [];
  totalUnderConstructionColumn: ReportTableColumn[] = UnderConstructionReportConstant.totalUnderConstructionColumn;
  underConstructionNeightableColumns: ReportTableColumn[] = UnderConstructionReportConstant.underConstructionNeightableColumns;
  neightProperty: any = [];
  isFirstTime = true;
  totalLoading = false;
  neightPropertyLoading = false;

  constructor(
    private reportUnderConstructionService: ReportUnderConstructionService,
    private reportsListsService: ReportsListsService,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  getReports() {
    this.getTotalUnderConstructionReport();
    this.getNeighPropertyReport();
  }

  getTotalUnderConstructionReport(year = null) {
    this.totalLoading = true;
    this.reportUnderConstructionService.getTotalUnderConstructionReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.totalUnderConstructionColumn = [...this.reportsListsService.yearColumnAppend(UnderConstructionReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
            this.totalUnderConstructionReport = this.reportsListsService.summaryReportConversion(res.data, UnderConstructionReportConstant.totalUnderConstructionColumn)
          } else {
            this.totalUnderConstructionReport = [];
          }
          this.totalLoading = false;
        },
        error: (error: any) => {
          this.totalUnderConstructionReport = [];
          this.totalLoading = false;
        }
      }
    )
  }

  getNeighPropertyReport(year = null) {
    this.neightPropertyLoading = true;
    this.reportUnderConstructionService.getNeighPropertyReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.neightProperty = res.data;
          } else {
            this.neightProperty = [];
          }
          this.neightPropertyLoading = false;
        },
        error: (error: any) => {
          this.neightProperty = [];
          this.neightPropertyLoading = false;
        }
      }
    )
  }

  selectedYear(data: any) {
    if (data.reportType === 'totalReport') {
      this.getTotalUnderConstructionReport(data.year);
    } else {
      this.getNeighPropertyReport(data.year);
    }
  }

}
