import { Component, Input, SimpleChanges } from '@angular/core';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { HouseLandVacancyReportConstant } from './report-house-land-vacancy.constants';
import { HouseLandVacancyMenuEnum } from './report-house-land-vacancy.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportHouseLandVacancyService } from './report-house-land-vacany.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Component({
  selector: 'app-report-house-land-vacancy',
  templateUrl: './report-house-land-vacancy.component.html',
  styleUrls: ['./report-house-land-vacancy.component.scss']
})
export class ReportHouseLandVacancyComponent {

  @Input() params: any;

  chartView = ReportsChartEnum;

  overViewMenu = HouseLandVacancyReportConstant.overViewMenu;
  currentScroll: any = '';

  landParcelColumns = HouseLandVacancyReportConstant.landParcelColumns;

  houseLandVacancyMenuEnum = HouseLandVacancyMenuEnum;

  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  isFirstTime = true;
  overViewLoading: boolean = false;
  overViewSummary: any = null;

  landParceldata: any = [];
  landParcelCount: number = 0;
  landParcelLoading: boolean = false;

  houseChartLoading: boolean = false;
  houseChart: any = [];

  constructor(
    private sharedService: SharedService,
    private reportHouseLandVacancyService: ReportHouseLandVacancyService,
    private reportService: ReportService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.reportHouseLandVacancyService.landParcelPayload.pageNumber = 0;
      this.propertyInfo = null;
      this.getReports();
    }
  }

  getReports() {
    this.getOverviewSummary();
    this.getlandParcel();
  }
  
  getOverviewSummary() {
    this.overViewLoading = true;
    this.reportHouseLandVacancyService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.reportHouseLandVacancyService.formatOverViewSummary(res.data[0]);
            // this.houserentalChart = [];
            //   res.graphData.map((data: any) => {
            //     let obj: any = {};
            //     if (data?.count > 0) {
            //       obj = {
            //         category: data.year.toString(),
            //         value: data.count
            //       }
            //       this.houserentalChart.push(obj);
            //     }

            //   })

           
          } else {
            this.overViewSummary = null;
          }
          this.overViewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.overViewLoading = false;
        }
      }
    )
  }

  getlandParcel(year = null) {
    this.landParcelLoading = true;
    this.reportHouseLandVacancyService.getLandParcelHouseVillas(this.reportHouseLandVacancyService.landParcelPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.landParceldata = res.data;
           this.landParcelCount = res.count;
            
          } else {
            this.landParceldata = [];
            this.landParcelCount = 0;
          }
          this.landParcelLoading = false;
        }
        
      }
    )
  }

  selectedYear(data: any){
   this.getlandParcel(data.year);
  }

  downloadExcel(event: any, type: string) {
    //this.downloadHouseVillaReport(type, 'house_and_villa');
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.houseLandVacancyMenuEnum.landParcel) {
        this.reportHouseLandVacancyService.landParcelPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getlandParcel();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.houseLandVacancyMenuEnum.landParcel) {
      this.reportHouseLandVacancyService.landParcelPayload.pageNumber = 0;
      this.reportHouseLandVacancyService.landParcelPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getlandParcel();
    } 
  }

}
