import { Component, Inject } from '@angular/core';
import { UserService } from 'src/app/common/services/user.service';
import { ReportService } from '../../services/report.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { actionIdConstants } from 'src/app/common/constants/common.contants';
import { ConfirmDialogBoxComponent } from 'src/app/common/components/confirm-dialog-box/confirm-dialog-box.component';
import { LangTranslatePipe } from 'src/app/common/pipes/translation.pipe';

@Component({
  selector: 'app-save-report-dailog',
  templateUrl: './save-report-dailog.component.html',
  styleUrls: ['./save-report-dailog.component.scss']
})
export class SaveReportDailogComponent {
  userInfo: any;
  folder_list: any=[];
  bucket_id: number=0;
  createFolder:boolean=false;
  report_name!:string;
  folder_name:string='';
  queryParams: any;
  is_saved: boolean=false;
  selected_saved_report: any;
  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<SaveReportDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public Data: any,
    private alertService: AlertService,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private reportService: ReportService
    ){

  }

  ngOnInit(){
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.getFolderList();
    this.report_name = this.Data.currentReportData.label[this.sharedService.getCurrentLangID()];
    this.report_id_check(this.queryParams);
  }

  report_id_check(queryP:any){
    if (this.Data.saved_report) {
      let saved = this.Data.saved_report.find((ele:any) => ele.id == queryP['srid']);
      if (saved) {
        this.selected_saved_report = saved;
        this.report_name = saved.report_name[this.sharedService.getCurrentLangID()]
        this.is_saved = true;
        // this.bucket_id = this.selected_saved_report.bucket_id;
      } else {
        this.is_saved = false;
      }
    }
  }
  getFolderList(){
    this.userService.getUserBuckets(this.userInfo.user_id).subscribe({
      next: (res:any)=>{
      if(res && res.data){
        this.folder_list = res.data;
        let defaultId = this.folder_list.find((ele: any) => (ele.name == 'default'))
        if(defaultId && !this.bucket_id){
          this.bucket_id = defaultId.id;
        }
      }else{
        this.folder_list =[];
      }
    },error: (err)=>{
      this.folder_list =[];
    }})
  }

  addFolder(){
    this.createFolder = !this.createFolder;
    if(this.createFolder){
      this.bucket_id = 0;
    }else{
      this.folder_name='';
    }
  }
  select_floder(id:number){
    this.folder_name='';
    this.createFolder =false;
    if(this.bucket_id == id){
      this.bucket_id = 0;
    }else{
      this.bucket_id = id;
    }
  }

  cancel(){
    this.dialogRef.close();
  }


  openConfimDailog(){
    let data:any={}
    for (let key in this.queryParams) {
      if (key != 'srid') {
        data[key] = this.queryParams[key];
      }
    }
    if(this.is_saved && !this.sharedService.compareObject(this.selected_saved_report.data, data)){
      let _data = {
        parent_data: null,
        message: "Do you want to update existing report?",
      };
      const dialogRef = this.dialog.open(ConfirmDialogBoxComponent, {
        panelClass:'show-dialog',
        data: _data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result == true){
          this.updateReport();
        }else if(result == false){
          this.saveReportData();
        }
      });
  }else{
    this.saveReportData();
  }
  }

  saveReportData(){
    if(this.report_name =='' || this.report_name == null) {
      this.alertService.warn(this.translate.instant('reports.report_required'));
      return;
    }
    this.report_name = this.report_name ? this.report_name.trim():'';
    if(this.report_name =='' || this.report_name == null) {
      this.alertService.warn(this.translate.instant('reports.avoid_space_in_report_name'));
      return;
    }
    let data:any={};
    for(let key in this.queryParams){
      if(key != 'srid'){
        if(key == 'neigh_name') {
          data[key]=this.sharedService.escapeMetaCharacters(this.queryParams[key]);
        }else if(key == 'polygon'){
          data[key]= this.queryParams[key] ? JSON.parse(this.queryParams[key]) : null;
        }else {
          data[key]= this.queryParams[key]
        }
      }
    }
    let body:any = {
      "user_id":this.userInfo.user_id,
      "report_id": this.Data.currentReportData.id,
      "data": data,
      "report_name": {[this.sharedService.getCurrentLangID()]: this.report_name},
      "sort_order": 0,
      "important": false,
      "status": 1,
      "added_by":this.userInfo.user_id,
      filter_data: this.reportService.user_preference ? this.reportService.user_preference['report_data'] : {}
    }

    if(this.bucket_id){
      body["bucket_id"] = this.bucket_id;
    }
    else if(this.selected_saved_report) {
      body["bucket_id"] = this.selected_saved_report.bucket_id;
    }
    this.userService.saveUserReport(body).subscribe({
      next: (res:any)=>{
        if(res.status == 201){
          const activity = {
            userdata: body,
            actionId: actionIdConstants.Save_Report,
            entityId: res.data,
            entity: 'report',
          };
          this.userService.logUserActivity(activity);
          if(this.is_saved){
            let mergerparam = {...this.queryParams}
            mergerparam['srid'] =  res.data;
            this.router.navigate([`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`],
            {queryParams: mergerparam})
          }
          this.userService.savedReport$.next(true);
          this.alertService.success(res.message);
          this.dialogRef.close(true);
        }else{
          this.alertService.warn(res.message);
        }
      },
      error:(err:any)=>{
        this.alertService.warn(err.error.message);
      }
    })

  }

updateReport(){
   let data:any={};
   for (let key in this.queryParams) {
     if (key != 'srid') {
      if(key == 'neigh_name') {
        data[key]=this.sharedService.escapeMetaCharacters(this.queryParams[key]);
      }else if(key == 'polygon'){
        data[key]= this.queryParams[key] ? JSON.parse(this.queryParams[key]) : null;
      }else {
        data[key]= this.queryParams[key]
      }
     }
   }
   let body = {
     "id": this.selected_saved_report.id,
     "bucket_id": this.selected_saved_report.bucket_id,
     "user_id": this.userInfo.user_id,
     "report_id": this.Data.currentReportData.id,
     "data": data,
     "important": true,
     "status": 1,
     "updated_by": this.userInfo.user_id,
     "report_name": {[this.sharedService.getCurrentLangID()]: this.report_name}
   }

   if(this.bucket_id) body["bucket_id"] = this.bucket_id
   this.userService.updateSavedReport(body).subscribe({next: (res:any)=>{
     if(res && res.status == 201){
       this.alertService.success(res.message);
       this.userService.savedReport$.next(true);
       this.dialogRef.close(true);
     }else{
      this.alertService.error(res.message);
     }
   }, error: (err:any)=>{
    this.alertService.error(err.error.message);
   }})
 }

  saveFolderdata(){
    this.folder_name = this.folder_name? this.folder_name.trim(): '';
    if(this.folder_name == '' || this.folder_name == null){
      this.alertService.warn(this.translate.instant('reports.enter_folder_name'))
      return;
    }
    if(this.folder_name.length > 48){
      this.alertService.warn(this.translate.instant("reports.folder_name_character_check"))
      return;
    }

    let folderName = this.folder_list.find((ele:any)=> (
      ele.name.toLowerCase() == this.folder_name.toLowerCase() ||
      ele.label[this.sharedService.getCurrentLangID()]?.toLowerCase() == this.folder_name.toLowerCase()
      ));
    if(folderName){
      this.alertService.warn(this.translate.instant('reports.folder_name_deplicate'));
      return;
    }

    let body={
      "user_id": this.userInfo.user_id,
      "name": this.sharedService.replaceSpchar(this.folder_name),
      "label": {[this.sharedService.getCurrentLangID()]: this.folder_name},
      "sort_order": 1,
      "status": 1,
      "added_by": this.userInfo.user_id
    }
    this.userService.saveUserBuckets(body).subscribe({next: (res:any)=>{
      if(res && res.status == 201){
        this.bucket_id = res.data;
        this.folder_name='';
        this.getFolderList();
      }else{
        this.alertService.warn(res.message);
      }
    },error: (err: any)=>{
      this.alertService.warn(err.error.message);
    }
  })
  }


  getBucketName(){
    if(this.bucket_id){
      let floderName = this.folder_list.find((e:any)=> e.id == this.bucket_id);
      return  floderName ? floderName.label[this.sharedService.getCurrentLangID()]:'';
    }
    return ''
  }

  getName(args:any){
    let value = args.label;
    value[this.sharedService.getCurrentLangID()] != "" || value[this.sharedService.getCurrentLangID()] != null ? value[this.sharedService.getCurrentLangID()] : (value[1] ? value[1] : args['name']);
  }
}
