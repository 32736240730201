import { DecimalPipe } from '@angular/common';
import { Component, effect, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReportProjectDetailsService } from '../report-project-details.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-project-detail-overview',
  templateUrl: './project-detail-overview.component.html',
  styleUrls: ['./project-detail-overview.component.scss']
})
export class ProjectDetailOverviewComponent {

  @Input() projectUid: any;
  unitType = GlobalContants.keySymbolContants.squareMeterUnit;
  CurrencyCode: any;
  ProjectDetails: any = {};
  param: any;
  multi_story_residential: boolean = false;
  loading: boolean = false;
  chartView = ReportsChartEnum;
  isFirstTime = true;
  private destroy$ = new Subject<void>();
  constructor(
    private sharedService: SharedService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private projectService: ReportProjectDetailsService
  ) {
    effect(() => this.multi_story_residential = this.reportService.multi_story_residential$$())
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    //Language Change
    this.sharedService.language$.pipe(takeUntil(this.destroy$)).subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getPropertyDetails(this.projectUid);
      }
    })
  }
  ngOnInit(): void {
    this.getPropertyDetails(this.projectUid);
    this.isFirstTime = false;
  }
  /**
   * 
   * @param uid 
   * Project Detail Overview Section API
   */
  getPropertyDetails(uid: string) {
    this.loading = true;
    this.route.queryParams.subscribe((param: any) => {
      this.param = { ...param }
    })
    this.projectService.projectDetailOverview(this.param, uid).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res: any) => {
        this.loading = false;
        if (res?.data) {
          this.ProjectDetails = this.ProjectDetails;
          this.ProjectDetails = { ...res.data };
          // this.ProjectDetails['year_of_build'] = this.ProjectDetails.year_of_build ? new Date(this.ProjectDetails.year_of_build).getFullYear() : null;
          this.ProjectDetails['total_area'] = this.ProjectDetails['total_area']
            ? this.reportService.formatInternationNum(this.ProjectDetails['total_area'])
            : null;
          this.ProjectDetails['total_properties'] = this.ProjectDetails['total_properties']
            ? this.reportService.formatInternationNum(this.ProjectDetails['total_properties'])
            : null;
          this.ProjectDetails['total_gross_area'] = this.ProjectDetails['total_gross_area']
            ? this.reportService.formatInternationNum(this.ProjectDetails['total_gross_area'])
            : null;
        } else {
          this.ProjectDetails = null;
        }
      }, error: (err) => {
        this.ProjectDetails = null;
        this.loading = false;
      }
    })
  }
  ngOnDestroy() {
    this.destroy$.next(); // Emit signal to unsubscribe
    this.destroy$.complete(); // Clean up the Subject
  }
}
