import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactSalesComponent } from 'src/app/common/components/contact-sales/contact-sales.component';
import { ReportService } from 'src/app/reports/services/report.service';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import * as pbi from 'powerbi-client';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-power-bi-full-with-map',
  templateUrl: './report-power-bi-full-with-map.component.html',
  styleUrls: ['./report-power-bi-full-with-map.component.scss']
})
export class ReportPowerBiFullWithMapComponent {
  @ViewChild('reportContainer', { static: false }) reportContainer!: ElementRef;
  //Power Bi
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        pageNavigation: { visible: false },
        filters: { visible: false, expanded: false },
        bookmarks: { visible: false },
        visualizations: { visible: true, expanded: false },
        fields: { visible: true, expanded: false },
        selection: { visible: false },
        syncSlicers: { visible: false },
      }
    },
  };
  @Input() params: any;
  isLoader: boolean = true;
  powerBiReport: any;
  visuals: any[] = [];
  visualsGrFilter: any[] = [];
  visualLoader: boolean = false;
  PowerBIData: any = {};
  langName: string = '';
  powerbiService:any;
  isFirstTime:boolean = true;
  constructor(
    public reportService: ReportService,
    private dailog: MatDialog,
    private sharedService : SharedService,
  ) {
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        // Reset the embedded object
        this.powerbiService.reset(this.reportContainer.nativeElement);
        this.isLoader = true;
      }
      this.callLoadReport();
    })
  }
  ngOnInit(): void {
    this.isFirstTime = false
    this.callLoadReport();
  }
  callLoadReport() {
    if(!this.reportService.getLockReport() && this.reportService?.getCurrentReport()?.powerbi_data){
      this.PowerBIData = this.reportService.getCurrentReport().powerbi_data;
      this.loadReport();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getVisualFilter();
    }
  }
  /**
   * Loads a PowerBI report and embeds it into the UI.
   */
  loadReport() {
    this.reportService.powerBiFecthReport(this.PowerBIData.pb_group_id, this.PowerBIData.pb_report_id).subscribe((res: any) => {
      // Update the reportConfig to embed the PowerBI report
      this.reportConfig = {
        ...this.reportConfig,
        id: this.PowerBIData.pb_report_id,
        pageName: this.langName === 'ar' ? this.PowerBIData.pb_page_id_ar : this.PowerBIData.pb_page_id,
        embedUrl: res.data.embedUrl,
        accessToken: res.data.token,
      }
      this.powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.powerBiReport = this.powerbiService.embed(this.reportContainer.nativeElement, this.reportConfig);
      this.powerBiReport.on('loaded', async () => {
        // Set the page after the report is loaded
        this.isLoader = false;
        this.reportContainer.nativeElement.style.display = 'block';
        this.getVisualFilter();
      })
    })
  }
  async getVisualFilter() {
    //Basics filter
    let basicFilter: any = await this.reportService.createFilterJson(this.params);

    // Filter out any objects where values are empty or undefined
    basicFilter = basicFilter.filter((filter: any) => filter.values && filter.values.length > 0);
    
    this.powerBiReport.setFilters(basicFilter)
  }

  // Opens the Contact Sales dialog with specified configurations
  openContactSales() {
    this.dailog.open(ContactSalesComponent, {
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type: 'contact_us' }
    })
  }
}
