<div class="navBar">
  <div class="navBar-btn">
      <button (click)="backToTransaction()" [ngClass]="{ 'backToReportAr' : currentLang === 'ar'}"
          class="backToReport"><mat-icon>keyboard_arrow_left</mat-icon>
          <span>{{'reports.backToReport'|translate}}</span> </button>
  </div>

  <div class="property-detailMenu" [ngClass]="{ 'property-detailMenuAr' : currentReportView === 2}">
      <app-report-sub-menu [menuList]="overViewMenu" [isPropertyDetailPage]="true"
          (menuChangeValue)="menuChangeValue($event)" [activeTabName] ="'property'"></app-report-sub-menu>
  </div>
</div>

<div class="report-property-detail">
  <!-- Overview Section -->
  <div class="report-tabsection-d" id="{{ officePropertyDetailMenuEnum.overview}}"
  [ngClass]="{ 'scroll': currentScroll === officePropertyDetailMenuEnum.overview}">
      <app-property-details-overview [Property_Uid]="propertyInfo?.property_uid"></app-property-details-overview>
  </div>
 <!-- Overview Section -->

  <!-- Units Rental-->
  <div class="report-model report-tabsection-d" id="{{ officePropertyDetailMenuEnum.unitRental}}"
      [ngClass]="{ 'scroll': currentScroll === officePropertyDetailMenuEnum.unitRental}">
      <app-office-unit-rental  (headerCount)="setCount($event, officePropertyDetailMenuEnum.unitRental)" [propertyInfo]="propertyInfo" [report_category]="'office'" ></app-office-unit-rental>
  </div>
  <!--Units Rental-->


  <!-- Property Transaction -->
  <div class="report-model report-tabsection-d" id="{{ officePropertyDetailMenuEnum.propertyTransaction}}"
  [ngClass]="{ 'scroll': currentScroll === officePropertyDetailMenuEnum.propertyTransaction}">
    <app-property-transaction (headerCount)="setCount($event, officePropertyDetailMenuEnum.propertyTransaction)" [propertyInfo]="propertyInfo" [report_category]="'office'"></app-property-transaction>
  </div>
   <!-- Property Transaction -->
</div>
