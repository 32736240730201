<app-reports-container
    [tableColumn]="totalUnderConstructionColumn"
    [dataValue]="totalUnderConstructionReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [tableColumn]="underConstructionNeightableColumns"
    [dataValue]="neightProperty"
    [isDownloadExcel]="true"
    [loading]="neightPropertyLoading"
    [reportName]="'reports.neighborrhoodandblockwiseUnderConstruction'"
    [title]="'reports.neighborrhoodandblockwiseUnderConstruction'"
    [expandable]="false"
    [yearDropdown]="true"
    [yearReportType]="'neighborrhoodandblockwiseUnderConstruction'"
    (yearSelected)="selectedYear($event)"
></app-reports-container>
