<div *ngIf="alerts.length" class="alert-container">
    <div *ngFor="let alert of alerts" class="left-border-info alert-section">
        <div class="message-section">
            <div class="icon_top">
                <img src="../../../../assets/images/alert_icons/{{getAlertTitle(alert)}}.svg" alt="">
            </div>
            <div>
                <h3>{{getAlertTitle(alert) | translate}}</h3>
                <p class="alert-text" [innerHTML]="alert.message"></p>
            </div>
        </div>
        <div>
            <button class="close" [ngClass]="{'close_arb':currentLang == 'ar' }" 
                (click)="removeAlert(alert)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4L4 12M4 4L12 12" stroke="#161717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </button>
        </div>
    </div>
</div>