<div class="cost-ofConstr detail-info">
    <h2 class="detail-heading">{{'reports.costOfConstruction' | translate}}</h2>
    <!-- <button class="btn-select-dropdown" [matMenuTriggerFor]="menu">
        Grade+
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="table-dropdown-menu">
        <div >
          <h5 >
            Grade+
          </h5>
          <mat-radio-group aria-label="Select an option">
            <ng-container
             
            >
              <mat-radio-button
                
                >wewe</mat-radio-button
              >
            </ng-container>
          </mat-radio-group>
        </div>
      </mat-menu> -->
    <div class="info">
        <div class="info-detail">
            <h6>{{'reports.retailUnits' | translate}}</h6>
            <h3>600</h3>
            <span>kws</span>
        </div>
        <div class="info-detail">
            <h6>{{'reports.retailUnits' | translate}}</h6>
            <h3>600</h3>
            <span>kws</span>
        </div>
        <div class="info-detail">
            <h6>{{'reports.retailUnits' | translate}}</h6>
            <h3>600</h3>
            <span>kws</span>
        </div>
        <div class="info-detail">
            <h6>{{'reports.retailUnits' | translate}}</h6>
            <h3>600</h3>
            <span>kws</span>
        </div>
        <div class="info-detail">
            <h6>{{'reports.retailUnits' | translate}}</h6>
            <h3>600</h3>
            <span>kws</span>
        </div>
        <div class="info-detail">
            <h6>{{'reports.retailUnits' | translate}}</h6>
            <h3>600</h3>
            <span>kws</span>
        </div>
    </div>

</div>
