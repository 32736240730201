import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-power-bi-property-details',
  templateUrl: './power-bi-property-details.component.html',
  styleUrls: ['./power-bi-property-details.component.scss']
})
export class PowerBiPropertyDetailsComponent {

  @Input() property:any;

  public params: any;
  public currentScroll = 0;
  public PowerBIData: any;
  public embedData: any;
  public catchmentCount: number = 0;
  loader: boolean=false;
  currentLang: string = '';
  languageSub$: Subscription = new Subscription();

  residentialProp:boolean = false;
  officeProp:boolean = false;
  retailProp:boolean = false;
    constructor(
      private sharedService: SharedService,
      public translate: TranslateService,
      public reportService: ReportService,
      private route: ActivatedRoute
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
      this.route.queryParams.subscribe(param => {
        this.params = param;
       
      })
     this.getPropertyConfig();
    }

    setLang(): void {
      this.languageSub$ = this.sharedService.language$.subscribe(lang => {
        this.currentLang = lang;
      })
    }

    async getPropertyConfig(){

      this.residentialProp = false;
      this.officeProp = false;
      this.retailProp = false;
      this.loader=true;
      let propertydata:any = await this.reportService.getPropertyConfigAPI();
      let paramcat = this.params['property_category']?.toLowerCase() || null;
      let propcat = this.property?.property_category?.toLowerCase() || null;
      const residential = "multi_story_residential";
      const office = "multi_story_office";
      const retail = "retail_f_b_and_entertainment";
      if(paramcat && propcat){
        if(propertydata && propertydata?.length > 0){
          let proData = propertydata.find((e:any)=> (propcat.includes(e?.property_category?.toLowerCase()) && paramcat.includes(e?.property_category?.toLowerCase())))
          if(proData){
            this.loader=false;
            this.PowerBIData = {...proData};
            this.loadVisualReport();
          } else {
            this.PowerBIData=null;
            if(propcat.includes(paramcat) && propcat.includes(residential) && paramcat.includes(residential)){
              this.residentialProp = true;
            }else if(propcat.includes(paramcat) && propcat.includes(office) && paramcat.includes(office)) {
              this.officeProp = true;
            }else if(propcat.includes(paramcat) && propcat.includes(retail) && paramcat.includes(retail)) {
              this.retailProp = true;
            }
         }
        }else{
          this.PowerBIData=null;
          if(propcat.includes(paramcat) && propcat.includes(residential) && paramcat.includes(residential)){
            this.residentialProp = true;
          }else if(propcat.includes(paramcat) && propcat.includes(office) && paramcat.includes(office)) {
            this.officeProp = true;
          }else if(propcat.includes(paramcat) && propcat.includes(retail) && paramcat.includes(retail)) {
            this.retailProp = true;
          }
        }
        this.loader=false;
      }else{
        this.PowerBIData = null;
        this.loader=false;
      }
    }
    ngOnInit(): void {
      this.setLang();
      //Get Catchment Count
      // this.route.queryParams.subscribe(param => {
      //   this.params = param;
        // if (param.hasOwnProperty('polygon')) {
        //   this.getCatchmentCount();
        // }
      // })
    }
  //Get Catchment for draw catchment
  // getCatchmentCount() {
  //   this.reportService.getCatchmentproperties().subscribe((res: any) => {
  //     if (res.status === 200) {
  //       this.catchmentCount = res.data;
  //     }
  //   });
  // }

    loadVisualReport(){
      this.loader = true;
      this.reportService.powerBiFecthReport(this.PowerBIData.pb_group_id, this.PowerBIData.pb_report_id)
        .subscribe({
          next: (res: any) =>{
            this.loader = false;
            this.embedData = res.data;
          },
          error: (err)=>{
            this.loader = false;
          }
        })
    }


    menuChangeValue(menu: any) {
      this.currentScroll = menu.name;
      setTimeout(() => {
        this.scrollPoint(menu.name);
      })
    }

    scrollPoint(menu: string) {
      this.sharedService.scrollMenu(menu);
    }

    backToReport(){
      this.reportService.transactionPropertyDetails.next(false);
    }
    ngOnDestroy(): void {
      this.languageSub$?.unsubscribe()
    }
}
