import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent {

  @Input() name!: any;
  @Input() required: boolean = false;
  @Input() isSubscription: boolean = false;
  unitForm!: FormGroup;
  startDate!: any;
  endDate!: any;
  StartEndDate = new FormControl();
  minEnableDate = '01/01/1970';
  maxDate: any = new Date();
  minDate: any = new Date(this.minEnableDate);

  constructor(
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    this.unitForm = this.controlContainer.control as FormGroup;
    if (this.StartEndDate.value) {
      let [startDateString, endDateString] = this.StartEndDate.value.split('to');
      let sDate = moment(new Date(startDateString)).format('DD-MM-YYYY');
      let eDate = moment(new Date(endDateString)).format('DD-MM-YYYY');
      const convertToDate = (dateString: any) => {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
      };
      this.startDate = convertToDate(sDate);

      this.endDate = convertToDate(eDate);
    }
  }

  ngOnChanges() {
    this.StartEndDate = this.controlContainer.control as FormControl;
  }

  saveDate(startDate: any, endDate: any) {
    // DD/MM/YYYY
    let sDate = moment(new Date(startDate)).format('YYYY-MM-DD');
    let eDate = moment(new Date(endDate)).format('YYYY-MM-DD');
    let finalDate = `${sDate}to${eDate}`;
    this.StartEndDate.patchValue(finalDate);
    this.minDate = new Date(this.minEnableDate);
    this.maxDate = new Date();
  }
  onClose(){
    if(!this.endDate){
      this.startDate = null;
      this.StartEndDate.patchValue('');
    }
  }

}
