<!-- Power Bi Map -->
<div class="page-loader" *ngIf="isLoader && !reportService.getLockReport()">
    <img class="vsloader" style="width: 80px;" src="assets/images/loading.gif" alt="">
</div>
<!-- Not Subscription -->
<div class="pbi-subscription d-flex align-items-center justify-content-center text-center" *ngIf="reportService.getLockReport()">
    <section>
        <i class="filterlock">
            <img src="assets/images/lock-gray.svg" alt=""></i>
        <p><strong>{{'premiumPackage' | translate}}</strong></p>
        <p><a (click)="openContactSales()">{{'contactSales' | translate}}</a> {{'toSubscribe' | translate}}</p>
    </section>
</div>
<div *ngIf="!reportService.getLockReport()" #reportContainer class="fullreport-container" style="display: none;">
</div>