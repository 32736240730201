import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { UserService } from 'src/app/common/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription, take } from 'rxjs';
@Component({
  selector: 'app-resend-mail',
  templateUrl: './resend-mail.component.html',
  styleUrls: ['./resend-mail.component.scss']
})
export class ResendMailComponent {
  newUserEmail:string = "";
  countdown: number = -1;
  countdownSubscription: Subscription | undefined;
  constructor(
    private userService: UserService,
    private router:Router,
    private sharedService: SharedService,
    private translate: TranslateService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.newUserEmail = params['email'];
    });
  }
  requestDemo(){
    this.router.navigateByUrl(GlobalContants.Routes.RequestDemo);
  }
  resendEmail() {
    let payload = {
      email: this.newUserEmail,
      notification_type: "verify_user"
    }
    this.userService.resendEmail(payload).subscribe({
      next : (res:any) => {
        if(res && res?.status == 201) {
          this.alertService.success(res?.message)
          this.startCountdown(50)
        }else {
          this.alertService.warn(res?.message)
        }
      },
      error : (error:any) => {
        this.alertService.error(error?.message)
      }
    })
  }
  startCountdown(seconds: number) {
    this.countdown = seconds;
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdownSubscription = interval(1000)
      .pipe(take(seconds + 1))
      .subscribe(() => {
        this.countdown--;
      });
  }
}
