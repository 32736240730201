import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportPreview, ReportTableColumn } from "../../models/reports.model";
import { ReportsChartEnum } from "src/app/reports/models/enums/reports.enum";

export class InventoryReportConstant {

    static numberOfUnitsPropertyGradeWise: ReportPreview = {
        reportLabel:'reports.NUMBERPRO',
        reportName: 'numberOfUnitsPropertyGradeWise',
        yearFilter: true,
        multiChart: true,
        reportView: [ReportsChartEnum.tableView],
        currentView: ReportsChartEnum.tableView,
        originalData: [],
        currentData: [],
        table: {
            expandable: true,
            column: []
        },
        chart:{
            chartFilter: []
        }
        
    }

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]

    static totalInventorColumn: ReportTableColumn[] = [
        {
            header: 'reports.noofProperties',
            field: 'total_property',
        },
        {
            header: 'reports.noofUnits',
            field: 'total_unit',
        },
        {
            header: 'reports.occupancy',
            field: 'occupancy_ratio',
            symbol: GlobalContants.keySymbolContants.percentage
        },
        {
            header: 'reports.occupiedUnits',
            field: 'occupied_unit',
        },
        {
            header: 'reports.vacantUnits',
            field: 'vacant_unit',
        },
        {
            header: 'reports.avgMonthlyRent',
            field: 'average_monthly_rent',
            cityCode: true
        }
    ]

    static unitTypetableColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.unitType',
            field: 'category',
            border: false
        }
    ]

    static propertyTypetableColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.propertyGrade',
            field: 'category',
            border: false
        }
    ]

    static unitGradetableColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.propertyGrade',
            field: 'category',
            border: false
        }
    ]
}
