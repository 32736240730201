<div class="report-container retail-rental warehouse-rentalv2">

    <ng-container>

        <app-report-sub-menu [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
        <div class="report-tabsection" id="{{ warehouseRentalMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === warehouseRentalMenuEnum.overview}">
            <div class="report-overview report-overview-2col">
                <ng-container *ngIf="overViewLoading">
                    <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1" [height]="'150px'"></app-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!overViewLoading">
                    <app-overview-card
                        [card_title]="sharedService.priceCalculation$() === sharedService.priceYearly ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'"
                        [propertiesCount]="propertiesCount"
                        [total_current]="overViewAverageSummary.avg_rent"
                        [untillDate]="overViewAverageSummary.asOfDate" [uniType]="UnitTypeEnum.currencywithsqm"
                        [graphData]="{data: overviewAverageRate, type: 'averageRentRate'}"></app-overview-card>
                </ng-container>
                <ng-container *ngIf="overViewNeighborhoodsLoading">
                    <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1" [height]="'150px'"></app-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!overViewNeighborhoodsLoading">
                    <app-overview-card [card_title]="'reports.topNeighborhoods'"
                        [BasedOnRentalsText]="true" [rentalsName]="basedOnCategory"
                        [total_current]="overViewNeighborhoodSummary.avg_rent"
                        [untillDate]="overViewNeighborhoodSummary.asOfDate" [uniType]="UnitTypeEnum.currencywithsqm"
                        [graphData]="{data: overviewNeighborhoodRate, type: 'occupancyRate'}"></app-overview-card>
                </ng-container>
            </div>
            <div class="report-model report-rentrate">
                <div class="report-model-header">
                    <div class="info">
                        <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ?
                            ('reports.annual_rental_price_in' | translate) : ('reports.rental_price_in' | translate)}}
                            <span class="text-capitalize">{{(placeName | lowercase) | translate}}</span>
                            <!-- {{'reports.past_years' | translate}} --> ({{CurrencyCode |
                            translate}}/{{'reports.sqm_unit' | translate}})
                        </h3>
                    </div>
                    <button class="btn-select-grade" [matMenuTriggerFor]="menu"
                        *ngIf="!retailRentalChartLoading && (retailRentalChartReport | json) != '{}' && (retailRentalChartReport | json) != '[]'">
                        <div class="setext">
                            <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l"
                                    style="padding-right: 2px;">,</span></ng-container>
                        </div>
                        <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"
                        class="grades-menu directionLtr">
                        <div (click)="$event.stopPropagation()">
                            <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                            <ng-container *ngFor="let category of getReportKeys()">
                                <mat-checkbox (change)="selectChart($event, category)"
                                    [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                            </ng-container>
                        </div>
                    </mat-menu>



                </div>
                <ng-container *ngIf="retailRentalChartLoading">
                    <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!retailRentalChartLoading">
                    <div class="multiBarChart d-flex"
                    *ngIf="(retailRentalChartReport | json) != '{}' && (retailRentalChartReport | json) != '[]'; else noDataMessage">
                        <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                            <app-report-charts class="grchart" [data]="chart.value"
                                [chartType]="chartView.smallBarChart" [isBorder]="false" [name]="chart.key" [index]="i"
                                [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '300px'}">
                            </app-report-charts>
                        </ng-container>


                    </div>
                    <ng-template #noDataMessage>
                        <div class="data_unavail">
                            <span>
                                {{"reports.data_not_available" | translate}} <mat-icon role="img"
                                    class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                                    aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                            </span>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>

        <!-- Rental Unit Size Table -->
        <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === warehouseRentalMenuEnum.retailUnitSize}"
            id="{{ warehouseRentalMenuEnum.retailUnitSize }}">

            <app-report-year-dropdown [reportType]="'rentalUnit'"
                (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ?
                            ('reports.annualRentUnitSizeWise' | translate) : ('reports.rentUnitSizeWise' | translate)}}
                        </h3>
                        <span class="totalTransaction"> ({{retailUnitsizeCount ?
                            reportService.formatInternationNum(retailUnitsizeCount) : retailUnitsizeCount}}
                            {{"reports.totalUnits" | translate}})</span>
                    </div>

                </div>
                <app-report-datatable-expanded class="rwr-rentunitsize-table" [showLoader]="!warehouseUnitsizeLoading"
                    [tableColumns]="warehouseUnitsizeColumns" [dataLength]="retailUnitsizeCount"
                    [tableData]="warehouseUnitsizedata"
                    (pageEvent)="onPageEvent($event, warehouseRentalMenuEnum.retailUnitSize)"
                    (sort)="sortData($event, warehouseRentalMenuEnum.retailUnitSize)" [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'warehouse_rental_unitsize_v2', 'a')"
                    [downloadLoading]="downloadLoading['a']" [showEstaterText]="true" [expandColumn]="true"
                    [tableExpandColumns]="retailUnitsizeExpandColumns" [dropDownOption]="unitSizeDropdown"
                    (dropDownValue)="dropDownChange($event, warehouseRentalMenuEnum.retailUnitSize)"
                    [showTooltip]="true" [isWarehouseUnit]="true">
                </app-report-datatable-expanded>



            </div>
        </div>

        <!-- Properties table -->
        <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === warehouseRentalMenuEnum.properties}"
            id="{{ warehouseRentalMenuEnum.properties }}">

            <app-report-year-dropdown [reportType]="'unit'"
                (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{'reports.projectsProperties' | translate}}</h3>
                        <span class="totalTransaction">({{retailUnitCount ?
                            reportService.formatInternationNum(retailUnitCount) : retailUnitCount}}
                            {{"reports.totalProperties" | translate}})</span>
                    </div>

                </div>
                <app-report-datatable-expanded [showLoader]="!retailUnitLoading"
                    [tableColumns]="warehousePropertiesColumns" [dataLength]="retailUnitCount"
                    [tableData]="retailUnitdata" (pageEvent)="onPageEvent($event, warehouseRentalMenuEnum.properties)"
                    (sort)="sortData($event, warehouseRentalMenuEnum.properties)" [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'warehouse_rental_property_v2', 'b')"
                    [downloadLoading]="downloadLoading['b']" [showEstaterText]="true"
                    [dropDownOption]="propertiesDropdown"
                    (dropDownValue)="dropDownChange($event, warehouseRentalMenuEnum.properties)">
                </app-report-datatable-expanded>


            </div>
        </div>


    </ng-container>

</div>