import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { ReportOfficeInventoryConstants } from './report-office-inventory.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { OfficeInventoryMenuEnum } from './report-office-inventory.enum';
import { ReportOfficeInventoryService } from './report-office-inventory.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { ReportService } from 'src/app/reports/services/report.service';
import { StoreService } from 'src/app/common/services/store.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-office-inventory',
  templateUrl: './report-office-inventory.component.html',
  styleUrls: ['./report-office-inventory.component.scss']
})
export class ReportOfficeInventoryComponent {
  @Input() params: any;
  overViewMenu: any = ReportOfficeInventoryConstants.overViewMenu;
  officeInventoryMenuEnum = OfficeInventoryMenuEnum;
  chartView = ReportsChartEnum;
  UnitTypeEnum = UnitTypeEnum;

  currentScroll: any = '';
  placeName = '';
  CurrencyCode: string = '';
  officeRentOption: string = 'officeRentOption';
  moreText: any;
  langName: any;
  overviewLoading = false;
  overviewGraphLoading = false;
  isFirstTime = true;
  isChart = true;

  neighborhoodCount: number = 0;
  neighborhoodLoading: boolean = false;
  neighborhoodData: any = [];

  propertiesCount: number = 0;
  propertiesLoading: boolean = false;
  propertiesTableData: any = [];

  propertiesData: any = {};
  openMarketData: any = {};
  captiveOfficeData: any = {};
  overviewChartData: any = {};
  selectedChartValue: any[] = [];
  retailRentalChartReport: any[] = [];

  overviewData$ = new Subscription();
  overviewDataGraph$ = new Subscription();
  neighborhoodData$ = new Subscription();
  propertiesData$ = new Subscription();

  neighborhoodColumns = ReportOfficeInventoryConstants.neighborhoodColumns;
  neighborhoodExpandColumns = ReportOfficeInventoryConstants.neighborhoodExpandColumns;
  propertiesColumns = ReportOfficeInventoryConstants.propertiesColumns;

  userInfo: any = 0;
  placeCode: string = '';
  tableName:unknown;
  downloadLoading: any = {
    a: false,
    b: false
  };

  constructor(
    private sharedService: SharedService,
    private officeService: ReportOfficeInventoryService,
    public reportService: ReportService,
    private storeService: StoreService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    public translate: TranslateService
  ){
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
        this.isChart = false;
      }
    })
    //Update Column Monthly/Annual
    if (this.sharedService.priceCalculation$() === this.sharedService.priceYearly) {
      const columnsToUpdate = [
        { header: 'avgOfficeLeaseRate', columnName: 'reports.avgAnnualOfficeLeaseRate' },
        { header: 'avgRetailLeaseRate', columnName: 'reports.avgAnnualRetailLeaseRate' },
        { header: 'averageOtherCommercialLeaseRate', columnName: 'reports.averageAnnualOtherCommercialLeaseRate' }
      ];
      // Neighborhood Table 
      columnsToUpdate.forEach(column => {
        const targetColumn = this.neighborhoodColumns.find(x => x.header === column.header);
        if (targetColumn) {
          targetColumn.columnName = column.columnName;
        }
      });
      // Neighborhood Table 
      const columnsToUpdateProperties = [
        { header: 'average_office_lease_rate', columnName: 'reports.avgAnnualOfficeLeaseRate' },
        { header: 'average_retail_lease_rate', columnName: 'reports.avgAnnualRetailLeaseRate' },
        { header: 'average_other_commercial_space_lease_rate', columnName: 'reports.averageAnnualOtherCommercialSpaceLeaseRate' }
      ];
      columnsToUpdateProperties.forEach(column => {
        const targetColumn = this.propertiesColumns.find(x => x.header === column.header);
        if (targetColumn) {
          targetColumn.columnName = column.columnName;
        }
      });
    }
  }
  ngOnInit(){
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.placeName = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.getReports();
    this.isFirstTime = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }
  lastClickedTable(event:Event, table:any){
    this.tableName = table
  }
  /**
   * All Rports api call
  */
  getReports() {
    this.getOverview();
    this.getOverviewGraph();
    this.getNeighborhoodData();
    this.getPropertiesData();
  }
  /**
   * Overview all type Data
   */
  getOverview() {
    this.overviewLoading = true;
    this.overviewData$ = this.officeService.getOverview().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            //propertiesData
            this.propertiesData = res.data.total_properties;
            this.propertiesData['untillDate'] = this.propertiesData.max_date ? moment.utc(this.propertiesData.max_date).local().format('DD MMM YYYY') : null;
            this.propertiesData['totalaverage_rate'] = this.reportService.calcPercentage(this.propertiesData.prev_total_properties, this.propertiesData.total_properties);
            //openMarketData
            this.openMarketData = res.data.open_market_share;
            this.openMarketData['untillDate'] = this.openMarketData.max_date ? moment.utc(this.openMarketData.max_date).local().format('DD MMM YYYY') : null;
            this.openMarketData['totalaverage_rate'] = this.reportService.calcPercentage(this.openMarketData.prev_open_market_share, this.openMarketData.open_market_share);
            //captiveOfficeData
            this.captiveOfficeData = res.data.captive_office_share;
            this.captiveOfficeData['untillDate'] = this.captiveOfficeData.max_date ? moment.utc(this.captiveOfficeData.max_date).local().format('DD MMM YYYY') : null;
            this.captiveOfficeData['totalaverage_rate'] = this.reportService.calcPercentage(this.captiveOfficeData.prev_captive_office_share, this.captiveOfficeData.captive_office_share);
          }
          this.overviewLoading = false;
        },
        error: (error: any) => {
          this.overviewLoading = false;
        }
      }
    )
  }
  /**
   * Residential Units - Grade wise
   */
  getNeighborhoodData() {
    this.neighborhoodLoading = true;
    this.neighborhoodData$ = this.officeService.getNeighborhood(this.officeService.neighborhoodPayload).subscribe(
      {
        next: (res: any) => {
          this.neighborhoodData = res.data;
          if (this.officeService.neighborhoodPayload.pageNumber == 0) {
            this.neighborhoodCount = res.count;
            this.overViewMenu.map((e: any) => {
              if (e.name == this.officeInventoryMenuEnum.neighborhood) {
                e['count'] = this.reportService.formatInternationNum(this.neighborhoodCount);
              }
            })
          }
          this.neighborhoodLoading = false;
        },
        error: (error: any) => {
          this.neighborhoodData = [];
          this.neighborhoodCount = 0;
          this.neighborhoodLoading = false;
        }

      }
    )
  }
  /**
   * Unit Rental Transaction
   */
  getPropertiesData() {
    this.propertiesLoading = true;
    this.propertiesData$ = this.officeService.getProperties(this.officeService.propertiesPayload).subscribe(
      {
        next: (res: any) => {
          this.propertiesTableData = res.data;
          if (this.officeService.propertiesPayload.pageNumber == 0) {
            this.propertiesCount = res.count;
            this.overViewMenu.map((e: any) => {
              if (e.name == this.officeInventoryMenuEnum.properties) {
                e['count'] = this.reportService.formatInternationNum(this.propertiesCount);
              }
            })
          }
          this.propertiesLoading = false;
        },
        error: (error: any) => {
          this.propertiesTableData = [];
          this.propertiesCount = 0;
          this.propertiesLoading = false;
        }

      }
    )
  }
  /**
   * Overview year chart
   */
  getOverviewGraph() {
    this.overviewGraphLoading = true;
    this.overviewDataGraph$ = this.officeService.getOverviewGraph().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.overviewChartData = res.data;
          let officeRentOption: any = this.storeService.get(this.officeRentOption);
          if (officeRentOption && officeRentOption?.length > 0) {
            this.selectedChartValue = officeRentOption;
          } else {
            // Select the first three keys by default
            this.selectedChartValue = Object.keys(this.overviewChartData).slice(0, 3);
          }
          // Transform the data
          Object.keys(this.overviewChartData).forEach((key) => {
            let data = this.overviewChartData[key];
            this.overviewChartData[key] = data.map((obj: any) => ({
              category: obj.year.toString(),
              value: obj.area,
              name: key
            }));
          });
          // Update the retail rental chart report
          this.retailRentalChartReport = Object.keys(this.overviewChartData)
            .filter(key => this.selectedChartValue.includes(key))
            .reduce((obj: any, key: any) => {
              obj[key] = this.overviewChartData[key];
              return obj;
            }, {});
              this.showMoreText();
              this.cdr.detectChanges();
        } else {
          this.overviewChartData = null;
        }
        this.overviewGraphLoading = false;
      },
      error: (error: any) => {
        this.overviewGraphLoading = false;
        this.overviewChartData = null;
      }
    });
  }
  showMoreText() {
    let textContent = document.querySelector('.setext')?.textContent || '';
    this.moreText = this.reportService.showMoreText(this.selectedChartValue, textContent);
  }
  getReportKeys(): string[] {
    if (this.overviewChartData) {
      let data = Object.keys(this.overviewChartData);
      return this.sharedService.sortGrades(data)
    }
    return [];
  }
  /**
   *
   * @param e
   * @param value
   * @returns
   * On clicking the dropdown, the chart will be added or removed
   */
  selectChart(e: any, value: any) {
    let data = this.reportService.selectChart(
      this.selectedChartValue,
      this.overviewChartData,
      e,
      value
    );
    if(data) this.retailRentalChartReport = data;
    this.overviewGraphLoading = false;
    this.showMoreText();
  }
  selectedYear(data: any){
    if (data.reportType === 'neighborhood') {
      this.officeService.neighborhoodPayload.pageNumber = 0;
      this.officeService.neighborhoodPayload.year = data.year;
      this.getNeighborhoodData();
    }else{
      this.officeService.propertiesPayload.pageNumber = 0;
      this.officeService.propertiesPayload.year = data.year;
      this.getPropertiesData();
    }
  }
  /**
   *
   * @param menu
   * if click menu scroll particular div
   */
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }
  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }
  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.officeInventoryMenuEnum.neighborhood) {
        this.officeService.neighborhoodPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getNeighborhoodData();
      }else{
        this.officeService.propertiesPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getPropertiesData();
      }
    }
  }
  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.officeInventoryMenuEnum.neighborhood) {
      this.officeService.neighborhoodPayload.pageNumber = 0;
      this.officeService.neighborhoodPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getNeighborhoodData();
    }else{
      this.officeService.propertiesPayload.pageNumber = 0;
      this.officeService.propertiesPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getPropertiesData();
    }
  }
  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }
  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.officeService.downloadOfficeReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=office_inventory`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }
  ngOnDestroy() {
    this.overviewData$.unsubscribe();
    this.overviewDataGraph$.unsubscribe();
    this.neighborhoodData$.unsubscribe();
    this.propertiesData$.unsubscribe();
  }
}

