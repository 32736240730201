

<app-reports-container
    [tableColumn]="totalInventoryReportColumn"
    [dataValue]="totalInventoryReport"
    [isDownloadExcel]="false"
    [loading]="totalInventoryLoading"
></app-reports-container>


<app-reports-container
    [dataValue]="investmentPropertyReport"
    [reportType]="chartView.barChart"
    [chartName]="'investmentPropertyReport'"
    [loading]="investmentPropertyLoading"
    [title]="'reports.investment_propertyreport'"
></app-reports-container>

<app-reports-container
    [dataValue]="investmentUnitReport"
    [reportType]="chartView.barChart"
    [chartName]="'investmentUnitReport'"
    [loading]="investmentUnitLoading"
    [title]="'reports.investment_unitreport'"
></app-reports-container>


<app-reports-container
    [tableColumn]="propertyTypetableColumns"
    [dataValue]="propertyTypeData"
    [isDownloadExcel]="true"
    [loading]="propertyTypetableLoading"
    [reportName]="'reports.NUMBERPRO'"
    [title]="'reports.NUMBERPRO'"
    [expandable]="true"
></app-reports-container>


<app-reports-container
    [tableColumn]="unitTypeReportTableColumn"
    [dataValue]="unitTypeData"
    [isDownloadExcel]="true"
    [loading]="unitTypeDataLoading"
    [reportName]="'reports.NUMOFUNITS'"
    [title]="'reports.NUMOFUNITS'"
    [expandable]="true"
></app-reports-container>

<app-reports-container
    [tableColumn]="unitGradeReportTableColumn"
    [dataValue]="unitGradeData"
    [isDownloadExcel]="true"
    [loading]="unitGradeReportLoading"
    [reportName]="'reports.NUMOFUNITSGRADE'"
    [title]="'reports.NUMOFUNITSGRADE'"
    [expandable]="true"
></app-reports-container>
