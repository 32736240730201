import { Component, effect, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/common/services/shared.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReportMapService } from 'src/app/reports/services/report-map.service';

@Component({
  selector: 'app-map-layers',
  templateUrl: './map-layers.component.html',
  styleUrls: ['./map-layers.component.scss']
})
export class MapLayersComponent implements OnInit{
  panelOpenState = false;
  basemap_select: string = this.mapService.mapLayer_current();
  public legendLayerGroup: any = [];
  public defultLegendLayerGroup: any = [];
  public layerData: any = [];
  current_lang: any = 'en';
  current_lang_id: number = 1;
  mapLayers: any[] = [];
  defultMapLayers: any[] = [];
  studyName: any;
  @Input('mapLayertoggle') mapLayertoggle:boolean=false;
  @Input('mapZoom') mapZoom:any;
  @ViewChild('customLayerController', {static: true}) customLayerController!:ElementRef;

  constructor(public reportService: ReportService, private mapService: ReportMapService, private sharedService: SharedService,
    private alertService: AlertService,
    private translateService:TranslateService
  ) {
    this.sharedService.language$.subscribe((lang) => {
      this.current_lang_id = lang == 'ar'?2:1;
      this.current_lang = lang;
    });
    effect(()=> this.basemap_select= this.mapService.mapLayer_current())
  }
  ngOnInit(): void {
    this.mapService.legendLayerGroupList.pipe(
      distinctUntilChanged()
    ).subscribe((item:any)=>{
      if(item){
        this.legendLayerGroup = item;
      }
    })
    this.reportService.selectedReport$.subscribe((data) => {
      this.studyName = this.reportService?.selectedStudies?.study_name;
      if (data.flag) {
        this.resetLayer();
        let layer_data = this.reportService.getCurrentReport();
        if (layer_data) {
          this.layerData = layer_data.layer;
          if(!this.legendLayerGroup?.length){
            const legendLayerGroup = layer_data.legend?.map((p:any) => {
              return {
                ...p,
                data: p.data.filter((item:any) => item.layer_status === true)
              };
            });
            // Create a deep copy of legendLayerGroup
            this.defultLegendLayerGroup = JSON.parse(JSON.stringify(legendLayerGroup));
            this.legendLayerGroup = legendLayerGroup;
          }
          // Create a deep copy of legendLayerGroup
          this.defultMapLayers = JSON.parse(JSON.stringify(layer_data.map_layer));
          this.mapLayers = layer_data.map_layer;
          // Check if elements with overflowing content need a tooltip and update the `showToolTip` property accordingly.
          setTimeout(()=>{
            this.getLayerTooltip();
          })
        }
      }
    })
  }
  getLayerTooltip(){
    let elements: NodeListOf<Element> = document.querySelectorAll('.maplayer-box');
    let layerStatusArr = this.mapLayers.filter((x:any) => x.layer_status);
    layerStatusArr.forEach((list, index) => {
      let element = elements[index] as HTMLElement;
      if (element) {
        // const lastChild = element.firstChild as HTMLElement;
        const scrollWidth = element.scrollWidth;
        const clientWidth = element.clientWidth;
        list['showToolTip'] = scrollWidth > clientWidth;
      }else{
        list['showToolTip'] = false;
      }
    });
  }
/**
 * Resets the map layers and legend to their default state.
 */
  resetLayer() {
    this.selectedBasemap('default');
    this.legendLayerGroup = JSON.parse(JSON.stringify(this.defultLegendLayerGroup));
    this.mapLayers = JSON.parse(JSON.stringify(this.defultMapLayers));
    this.mapService.legendToggle.next(false);
    this.getLayerTooltip();
    this.mapService.overlay_mapLayer.next(this.defultLegendLayerGroup.filter((r: any) => r.display == true));
  }
/**
 * 
 * @returns 
 * Checks if the reset layer functionality should be disabled.
 */
  resetLayerDisable(): boolean {
    return (
      this.basemap_select === 'default' &&
      JSON.stringify(this.legendLayerGroup) === JSON.stringify(this.defultLegendLayerGroup) &&
      JSON.stringify(this.mapLayers) === JSON.stringify(this.defultMapLayers)
    );
  }

  selectedLegend(data: any) {
    if(this.reportService.getLockReport()){
      return;
    }
    //
    if (!data.display) {
      data.display = !data.display;
      this.legendLayerGroup = this.legendLayerGroup.map((r: any) => {
        if(r.display == true && data.legend_type  == r.legend_type){
          return r;
        } else {
          return {
            ...r,
            display: false
          }
        }
      });
      this.mapService.legendLayerGroupList.next(this.legendLayerGroup);
      this.mapService.legendLayerGroup.next(this.legendLayerGroup.filter((r: any) => r.display == true));
      this.mapService.legendToggle.next(true);
    }
  }

  selectedBasemap(value: string) {
    if(value == this.mapService.mapLayer_current()) return;
    this.mapService.mapLayer_current.set(value)
    this.mapService.basemapLayer.next(value);
  }

  selectedLayers(value: any) {
    if(this.reportService.getLockReport()){
      return;
    }
    value.display = !value.display;
    this.mapService.overlay_mapLayer_current.set(value)
    this.mapService.overlay_mapLayer.next(this.mapLayers.filter((r: any) => r.display == true));
  }

  onImgError(event: any) {
    event.target.src = "../../../../assets/images/map_layer_icons/Default.svg";
  }

}
