import { Component } from '@angular/core';

@Component({
  selector: 'app-report-house-land-vacancy-details',
  templateUrl: './report-house-land-vacancy-details.component.html',
  styleUrls: ['./report-house-land-vacancy-details.component.scss']
})
export class ReportHouseLandVacancyDetailsComponent {

}
