<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>

    <ng-container *ngIf="allDataLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"  [height]="'150px'"></app-skeleton-loader>
    </ng-container>
    <div class="property-details" *ngIf="landInformation">

        <div class="property-info">
            <h4>{{ landInformation?.neigh_name }}</h4>
            <!-- <p>{{ propertyDetail?.address }}</p> -->

            <div class="property-zone" *ngIf="areaInformation?.zoning">
                <h4>{{'reports.property_type_zoning' | translate}}</h4>
                <div class="zoning-info">
                    <span>{{ "reports.zoning" | translate }} : <strong>{{ areaInformation?.zoning }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>{{'reports.neighborhoodInformation' | translate}}</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>{{'reports.total_area' | translate}}</h6>
                        <span [matTooltip]="areaInformation?.total_land_area">{{ areaInformation?.total_land_area ?
                            areaInformation?.total_land_area : ('na' | translate)
                            }}</span>
                        <h6 *ngIf="areaInformation?.total_land_area">{{sqm_unit | translate}}</h6>
                    </div>
                    <div class="property-area frontage">
                        <h6>{{'Roads' | translate}}</h6>
                        <span [matTooltip]="areaInformation?.road">{{ areaInformation?.road ?
                            areaInformation?.road+'%' : ('na' | translate) }}</span>
                    </div>
                    <div class="property-area setback">
                        <h6>{{'reports.parks' | translate}}</h6>
                        <span [matTooltip]="areaInformation?.park">{{ areaInformation?.park ?
                            areaInformation?.park+'%' : ('na' | translate) }}</span>
                        <h6 *ngIf="areaInformation?.park"></h6>
                    </div>
                    <div class="property-area corner">
                        <h6>{{'reports.plots' | translate}}</h6>
                        <span [matTooltip]="areaInformation?.plot">{{ areaInformation?.plot ? areaInformation?.plot+'%' :
                            ('na' | translate) }}</span>

                    </div>
                </div>
            </div>
        </div>

        <div class="property-info-img">
            <div class="property-imgD">
                <img src="assets/images/housevilla.png">
            </div>

        </div>

    </div>
    <ng-container *ngIf="allDataLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="3" [height]="'150px'"></app-skeleton-loader>
    </ng-container>
    <div class="report-overview reportov-housevilla" *ngIf="landInformation">
        <app-overview-card
                [card_title]="'reports.totalLandVillas'" 
                [total_current]="landInformation?.total_land"
                [untillDate]="landInformation?.max_date"
                [total_prev]="landInformation?.prev_total_land"
                [total_percentage]="landInformation?.tot_land_diff"></app-overview-card>

        <app-overview-card
                [card_title]="'reports.averageLandArea'" 
                [total_current]="landInformation?.avg_land"
                [untillDate]="landInformation?.max_date"
                [total_prev]="landInformation?.prev_avg_land"
                [total_percentage]="landInformation?.avg_land_diff"
                [uniType]="UnitTypeEnum.sqm"></app-overview-card>
            
        <app-overview-card
                [card_title]="'reports.landDevelopmentRate'" 
                [total_current]="landInformation?.land_dev_rate"
                [untillDate]="landInformation?.max_date"
                [total_prev]="landInformation?.prev_land_dev_rate"
                [total_percentage]="landInformation?.land_dev_rate_diff"
                [uniType]="UnitTypeEnum.percentage"></app-overview-card>

        <!-- <div class="overview">
            <div class="overview-data">
                <h2>{{"reports.totalLandVillas" | translate}}</h2>
                <h1>{{ landInformation?.total_land | number:'1.0-1' }}</h1>

                <span>{{"reports.forYear" | translate}} {{landInformation?.max_date}}</span>

            </div>
            <div class="overview-v">
                <span>{{"reports.was" | translate}} {{ landInformation?.prev_total_land | number:'1.0-1'
                    }}</span>
                <div class="overview-prev">
                  <h5 class="normal-price" *ngIf="landInformation?.tot_land_diff == 0"> {{
                    landInformation?.tot_land_diff }} %</h5>
                    <h5 class="high-price" *ngIf="landInformation?.tot_land_diff > 0">+ {{
                        landInformation?.tot_land_diff }} %</h5>
                    <h5 class="low-price" *ngIf="landInformation?.tot_land_diff < 0">{{
                        landInformation?.tot_land_diff }} %</h5>
                    <span>{{"reports.fromLastYear" | translate}}</span>
                    <mat-icon *ngIf="landInformation?.tot_land_diff > 0" class="high-price">
                        call_made</mat-icon>
                    <mat-icon *ngIf="landInformation?.tot_land_diff < 0" class="low-price">
                        call_received</mat-icon>
                </div>
            </div>
        </div>
        <div class="overview">
            <div class="overview-data">
                <h2>{{"reports.averageLandArea" | translate}} </h2>
                <h1>
                    <ng-container *ngIf="landInformation?.avg_land; else otheravgLand">
                        {{landInformation?.avg_land | number:'1.0-1'}} <sup class="sup-font">sqm</sup>
                    </ng-container>
                    <ng-template #otheravgLand>-</ng-template>
                </h1>

                <span>{{"reports.forYear" | translate}} {{landInformation?.max_date}}</span>

            </div>
            <div class="overview-v">
                <span>{{"reports.was" | translate}} {{ landInformation?.prev_avg_land ? (landInformation?.prev_avg_land | number:'1.0-1')+ ( sqm_unit | translate):''
                    }}</span>
                <div class="overview-prev">
                  <h5 class="normal-price" *ngIf="landInformation?.avg_land_diff == 0"> {{
                    landInformation?.avg_land_diff }} %</h5>

                    <h5 class="high-price" *ngIf="landInformation?.avg_land_diff > 0">+ {{
                        landInformation?.avg_land_diff }} %</h5>
                    <h5 class="low-price" *ngIf="landInformation?.avg_land_diff < 0">{{
                        landInformation?.avg_land_diff }} %</h5>
                    <span>{{"reports.fromLastYear" | translate}}</span>
                    <mat-icon *ngIf="landInformation?.avg_land_diff > 0" class="high-price">
                        call_made</mat-icon>
                    <mat-icon *ngIf="landInformation?.avg_land_diff < 0" class="low-price">
                        call_received</mat-icon>
                </div>
            </div>
        </div>
        <div class="overview">
            <div class="overview-data">
                <h2>{{"reports.landDevelopmentRate" | translate}}</h2>
                <h1>
                    <ng-container *ngIf="landInformation?.land_dev_rate; else otherLandDevRate">
                        {{landInformation?.land_dev_rate | number:'1.0-1'}} <sup class="sup-font">%</sup>
                    </ng-container>
                    <ng-template #otherLandDevRate>-</ng-template>
                    </h1>

                <span>{{"reports.forYear" | translate}} {{landInformation?.max_date}}</span>

            </div>
            <div class="overview-v">
                <span>{{"reports.was" | translate}} {{ landInformation?.prev_land_dev_rate ? (landInformation?.prev_land_dev_rate | number:'1.0-1')+'%':''
                    }}</span>
                <div class="overview-prev">
                  <h5 class="normal-price" *ngIf="landInformation?.land_dev_rate_diff == 0"> {{
                    landInformation?.land_dev_rate_diff }} %</h5>
                    <h5 class="high-price" *ngIf="landInformation?.land_dev_rate_diff > 0">+ {{
                        landInformation?.land_dev_rate_diff }} %</h5>
                    <h5 class="low-price" *ngIf="landInformation?.land_dev_rate_diff < 0">{{
                        landInformation?.land_dev_rate_diff }} %</h5>
                    <span>{{"reports.fromLastYear" | translate}}</span>
                    <mat-icon *ngIf="landInformation?.land_dev_rate_diff > 0" class="high-price">
                        call_made</mat-icon>
                    <mat-icon *ngIf="landInformation?.land_dev_rate_diff < 0" class="low-price">
                        call_received</mat-icon>
                </div>
            </div>
        </div> -->
    </div>
    <div class="report-chart-overview reportov-housevilla">
        <!-- <div class="overview overview-chart">
            <div class="overview-data">
                <h2>{{"Total Villas Unit Type Wise" | translate}}</h2>
            </div>
            <ng-container *ngIf="landInformationPieData.length">
                <app-report-chart-view
                [height]="'200px'"
                [data]="landInformationPieData"
                [chartType]="chartView.barChart"
                ></app-report-chart-view>
            </ng-container>
        </div> -->
        <div class="overview overview-chart">
            <div class="overview-data">
                <h2>{{"reports.developed_vs_vacant_villas" | translate}}</h2> 
                 <!--- Down load icon -->
                    <!-- <div class="download_excel" *ngIf="landInformationPieData.length">
                        <span *ngIf="!downloadLoading" (click)="downloadVacantDevelopedNeighReport('neighborhood_details', landInformation?.neigh_name)" matTooltip="{{ 'reports.download_excel' | translate }}"
                        matTooltipPosition="above"><img src="../../../../../../assets/images/excel-icon.svg" /></span>
                        <span *ngIf="downloadLoading"><mat-spinner class="download-spinner"></mat-spinner></span>
                    </div> -->
                <!--- Down load icon -->
            </div>
           
            <ng-container *ngIf="allDataLoading">
                <app-skeleton-loader [reportType]="chartView.pieChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="landInformationPieData.length; else noDataMessage">
                <app-report-chart-view
                [height]="'200px'"
                [data]="landInformationPieData"
                [chartType]="chartView.pieChart"
                [isBorder]="false"
                ></app-report-chart-view>
            </ng-container>
            <ng-template #noDataMessage>
                <div class="data_unavail">
                    <span>
                        {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                    </span>
                </div>
            </ng-template>
            <!-- <ng-container *ngIf="landInformationPieData.length == 0">
                <p>{{"reports.no_record_found" | translate}}</p>
            </ng-container> -->
        </div>
    </div>
</div>
