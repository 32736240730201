import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import * as pbi from 'powerbi-client';
import { ContactSalesComponent } from 'src/app/common/components/contact-sales/contact-sales.component';
import { ReportService } from 'src/app/reports/services/report.service';
@Component({
  selector: 'app-report-pbi-retail-malls-plaza',
  templateUrl: './report-pbi-retail-malls-plaza.component.html',
  styleUrls: ['./report-pbi-retail-malls-plaza.component.scss']
})
export class ReportPbiRetailMallsPlazaComponent {
  @Input() params: any;
  isLoader: boolean = true;
  //Power Bi
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        pageNavigation: { visible: false },
        filters: { visible: false, expanded: false },
        bookmarks: { visible: false },
        visualizations: { visible: true, expanded: false },
        fields: { visible: true, expanded: false },
        selection: { visible: false },
        syncSlicers: { visible: false },
      }
    },
  };
  @ViewChild('reportContainer', { static: false }) reportContainer!: ElementRef;
  private pageName: string = "Map"; // Replace with the target page name
  //RID
  rID = '39f5905a-ce12-47a2-ab4b-f3ca832dfbc6';
  gID = '8faa79ae-8a94-423a-a657-35066f0c8005';
  //Filter
  powerBifilters = [
    {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Year",
        column: "Year"
      },
      operator: "In",
      values: []
    }
  ];
  powerBiReport: any;
  visuals: any[] = [];
  visualsGrFilter: any[] = [];
  visualLoader: boolean = false;
  //End
  constructor(
    public reportService: ReportService,
    private dailog: MatDialog
  ) { }
  ngOnInit(): void {
    // this.reportContainer.nativeElement.style.display = 'none';
    this.loadVisualReport();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getVisualFilter();
    }
  }
  /**
   * Loads a PowerBI report and embeds it into the UI.
   */
  loadVisualReport() {
    this.reportService.powerBiFecthReport(this.gID, this.rID).subscribe((res: any) => {
      // Update the reportConfig to embed the PowerBI report
      this.reportConfig = {
        ...this.reportConfig,
        id: this.rID,
        embedUrl: res.data.embedUrl,
        accessToken: res.data.token,
      }
      const powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.powerBiReport = powerbiService.embed(this.reportContainer.nativeElement, this.reportConfig);
      this.powerBiReport.on('loaded', async () => {
        // Set the page after the report is loaded
        this.isLoader = false;
        this.reportContainer.nativeElement.style.display = 'block';
        this.setReportPage(this.powerBiReport, this.pageName);
        this.getVisualFilter();
      })
    })
  }
  /**
   * 
   * @param report 
   * @param pageName 
   * Response get active page 
   */
  private setReportPage(report: any, pageName: string): void {
    report.getPages().then((pages: any) => {
      const targetPage = pages.find((page: any) => page.displayName === pageName);
      if (targetPage) {
        targetPage.setActive(); // Sets the specified page as active
      }
    });
  }
  convertCommaFilter(name: any) {
    if (name) return name.split(',');
  }
  /**
   * 
   * @param isMultiYear 
   * apply filters
   */
  getVisualFilter(isMultiYear = false) {
    //Get QueryParam Data
    let paramsFilters = this.params;
    let filterYear: any;
    filterYear = (paramsFilters['year']?.split(',') || [new Date().getFullYear()]).map((y: any) => Number(y));

    const neigh_name = this.convertCommaFilter(paramsFilters['neigh_name']);
    const block_name = this.convertCommaFilter(paramsFilters['block_name']);
    const unit_type = this.convertCommaFilter(paramsFilters['unit_type']);
    let basicFilter: any;
    //property_grade

    this.visuals.forEach((visual: any, index: any) => {
      if (index === 0) {
        isMultiYear = true;
      }
    })
    if (isMultiYear) {
      filterYear = Array.from({ length: 5 }, (_, i) => filterYear[0] - i);
    }
    //Basics
    basicFilter = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Property History",
          column: "year"
        },
        operator: "In",
        values: filterYear
      },
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Property History",
          column: "neigh_name"
        },
        operator: "In",
        values: neigh_name ? neigh_name : null
      },
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Property History",
          column: "block_name"
        },
        operator: "In",
        values: block_name ? block_name : null
      }
    ]
    // Filter out any objects where values are empty or undefined
    basicFilter = basicFilter.filter((filter: any) => filter.values && filter.values.length > 0);
    this.powerBiReport.setFilters(basicFilter)
  }
  openContactSales() {
    this.dailog.open(ContactSalesComponent, {
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type: 'contact_us' }
    })
  }
}
