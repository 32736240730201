export const apiEndPoints = {
    city: 'country-with-city',
    login: 'login',
    notification: 'notification',
    verify: 'verify',
    reset_password: 'reset-password',
    register: 'register',
    objStudyReport: `obj-study-report`,
    userInfo: `user`,
    reports: `reports`,
    totalInventoryReport: 'total-inventory-report',
    filterDataList: 'filter-data-list',
    casecaddingfilter: 'cascading-filter-data',
    combineFilterDataList: 'combine-filter-data-list',
    userReports: `user-reports`,
    userBuckets: `user-buckets`,
    investmentUnitReport: 'investment-unit-report',
    unitTypeReport: 'unit-type-report',
    propertyReport: 'property-report',
    unitGradeReport: 'unit-grade-report',
    recentSavedReport: 'recent-saved-report',
    profileImage: `profile-image`,
    unitTypeOccupancyLeaseReport: 'unit-type-occupancy-lease-report',
    populationAgeWiseReport: 'population-age-wise-report',
    populationTotalReport: 'population-total-report',
    userActivity: `user-activity`,
    userPreference: 'user-preference',
    language: 'language',
    userNotifications: 'user-notification-preference',
    userSetting: 'user-setting',
    underConstructionTotalReport: 'under-construction-total-report',
    underConstructionNeighPropertyReport: 'under-construction-neigh-property-report',
    mallProfilingTotalReport: 'mall-profiling-total-report',
    mallProfilingFloorWiseReport: 'mall-profiling-floor-wise-report',
    mallInventoryTotalReport: 'mall-inventory-total-report',
    mallInventoryNeighTrendlReport: 'mall-inventory-neigh-trend-report',
    retailfnbAverageLeaseCategoryReport: 'retail-fnb-average-lease-category-report',
    retailfnbTrendAreaTentantReport: 'retail-fnb-trend-area-occupied-tenant-report',
    retailfnbTotalReport: 'retail-fnb-total-report',
    mallTenantLeaseRatePropertyTypeReport: 'mall-tenant-lease-rate-property-type-report',
    mallTenantTrentAreaReport: 'mall-tenant-trend-area-report',
    mallTenantTotalReport: 'mall-tenant-total-report',
    userSubscription: `user-subscription`,
    subscriptionRequest: `subscription-request`,
    investmentPropertyReport: 'investment-property-report',
    unitOccupancyLeaseReport: 'unit-occupancy-lease-report',
    unitOccupancyTypeReport: 'unit-occupancy-type-report',
    populationYearWiseReport: 'population-year-wise-report',
    populationTrendlineReport: 'population-trendline-report',
    yearwiseOccupancyLeaseReport: 'year-wise-occupancy-lease-report',
    retailFnbCuisineWiseSplitAreaReport: 'retail-fnb-cuisine-wise-split-area-report',
    retailFnbCategoryWiseReport: 'retail-fnb-category-wise-report',
    mallTenantShareCategoryReport: 'mall-tenant-share-category-report',
    mallInventoryYearlyUnitsReport: 'mall-inventory-yearly-units-report',
    mallInventoryYearlyOccupancyReport: 'mall-inventory-yearly-occupancy-report',
    mallInventoryYearlyIncrementalReport: 'mall-inventory-yearly-incremental-report',
    propertyProfilingTotalReport: 'property-profiling-total-report',
    typeGradePropertyProfilingReport: 'type-grade-property-profiling-report',
    typePropertyProfilingReport: 'type-property-profiling-report',
    gradeFurnishedPropertyProfilingReport: 'grade-furnished-property-profiling-report',
    amenitiesPropertyProfilingReport: 'amenities-property-profiling-report',
    changePassword: 'change-password',
    contactUs: 'contact-us',
    requestAccount: 'request-account',
    transactionSummary: 'transaction-summary',
    propertyTransaction: 'property-transaction',
    propertyTransactionHistory: 'property-transaction-datewise-report',
    unitTransactionHistory: 'unit-transaction-datewise-report',
    unitTransaction: 'unit-transaction',
    downloadTransactionReport: 'download-transaction-report',
    downloadExcel: 'download-report',
    bulkUpdateReport: 'bulk-update-report',
    bulkDeleteReport: 'bulk-delete-report',
    propertyTransactionDetail: 'property-transaction-detail',
    houseVillaLandInformation: 'house-villa-land-information',
    houseVillaAreaInformation: 'house-villa-area-information',
    houseVillasSummary: 'house-villa-overview-details-report',
    houseVillaGraph: 'house-villa-graph-details-report',
    projectHouseVillas: 'house-villa-project-villa-list',
    independentHouseVillas: 'house-villa-independent-villa-list',
    governorHouseVillas: 'house-villa-govt-list',
    houseVillaPropertyDetail: 'house-villa-property-detail',
    // houseVillaProjectDetail: 'house-villa-project-details',
    downloadHouseVillaReport: 'download-house-villa-report',
    neighDetails: 'neigh-details',
    reportDownload: 'report-download',
    saveFilter: 'save-filter',
    getSavedFilter: 'saved-filter',
    dashboardMapTransaction : 'top-transactions',
    reportMapCluster: 'map-property-cluster-details',
    logout: 'logout',
    houseLandVacancyOverview: 'house-villa-land-vacancy-overview-report',
    houseLandVacancyList: 'house-villa-land-vacancy-list-report',
    houseRentalOverview: 'house-villa-rental-overview-report',
    houseRentalList: 'house-villa-rental-list-report',
    searchMaster: 'search-master',
    userReportCount: 'user-report-count',
    officeRentalpropertyListReport: 'office-rental-property-list-report',
    officeRentalRentUnitSizewiseReport: 'office-rental-rent-unit-sizewise-report',
    officeRentalOverviewReport: "office-rental-overview-report",
    officeRentalOverviewSubCategoryDetailsReport: "office-rental-overview-sub-category-details-report",
    officeRentalRentRateOverviewGraphdataReport: "office-rental-rent-rate-overview-graphdata-report",
    retailRentalUnit: 'retail-rental-unit-report',
    retailRentalUnitSize: 'retail-rental-rent-unitsize-report',
    retailRentalOverview: 'retail-rental-overview-report',
    retailRentalRentrate: 'retail-rental-rentrate-report',
    retailRentalOverviewSubCategory: 'retail-rental-overview-sub-category-details-report',
    retailRentalRentrateReport: 'retail-rental-rentrate-report',
    warehouseOverviewReport: 'warehouse-overview-report',
    warehouseRentrateReport: 'warehouse-rentrate-report',
    // warehouseUnitReport: 'warehouse-unit-report',
    warehousePropertiesReport: 'warehouse-properties-report',
    warehouseRentunitsizeReport: 'warehouse-rent-unitsize-report',
    retailWarehouseOverviewSubCategory: 'warehouse-overview-sub-category-details-report',
    houseVillaProjectUnitDetails: 'house-villa-project-unit-details',
    houseVillaProjectAreaOverview: 'house-villa-project-area-overview',
    houseVillaProjectLandInfo: 'house-villa-project-land-info',
    inventoryOverviewReport: 'ir-inventory-overview-report',
    inventoryPropertyGraphReport: 'ir-inventory-property-graph-report',
    inventoryNeighWisePropertiesReport: 'ir-inventory-neighwise-properties-report',
    inventoryProjectsAndPropertiesReport: 'ir-inventory-projects-and-properties-report',
    inventoryUnitReport: 'ir-inventory-unit-report',
    inventoryUnitTransctionReport: 'ir-inventory-unit-transactions-report',
    inventoryOverviewPropertyReport: 'ir-inventory-property-overview-report',
    inventoryAreaPropertyReport: 'ir-inventory-property-area-overview-report',
    inventoryProjectReport: 'ir-inventory-project-report',
    inventoryOverviewProjectReport: 'ir-inventory-project-overview-report',
    inventoryUnitProjectReport: 'ir-inventory-project-unit-report',
    inventoryUnitTransactionProjectReport: 'ir-inventory-project-unit-transactions-report',
    inventoryProjectpropertiesReport: 'ir-inventory-project-properties-report',
    inventoryAreaProjectReport: 'house-villa-project-area-overview',
    occupancyResidentialUnitsGradewiseReport: 'ir-occupancy-residential-units-gradewise-report',
    occupancyResidentialUnitstypewiseReport: 'ir-occupancy-residential-units-typewise-report',
    occupancyCommercialUnitsGradewiseReport: 'ir-occupancy-commercial-units-gradewise-report',
    occupancyCommercialUnitsSizewiseReport: 'ir-occupancy-commercial-units-sizewise-report',
    occupancyUnitRentalTransactionsReport: 'ir-occupancy-unit-rental-transactions-report',
    // occupancyPropertyOverviewReport: 'ir-occupancy-property-overview-report',
    occupancyPropertyUnitReport: 'ir-occupancy-property-unit-report',
    occupancyPropertyUnitTransactionsReport: "ir-occupancy-property-unit-transactions-report",
    PropertyAmenities: 'property-details-amenities',
    reportPropertyDetailsOverview: 'property-details-overview',
    reportPropertyDetailsBuiltupinfo: 'property-details-builtup-info',
    reportPropertyDetailsPropertytransactions: 'property-details-property-transactions',
    reportPropertyDetailsCostofconstruction: 'property-details-cost-of-construction',
    reportPropertyDetailsresidentialrentalincomesubtypewise: 'property-details-residential-rental-income-subtypewise',
    reportPropertyDetailsresidentialrentalincometypewise: 'property-details-residential-rental-income-typewise',
    reportPropertyDetailsPropertyInfo: 'property-details-property-info',
    reportPropertyDetailsMaxPermittedFloor: 'property-details-max-permitted-floors',
    reportPropertyDetailsLandValue: 'property-details-land-value-transactions-count',
    userSettings:'user-settings',
    retailInventoryOverview: 'retail-inventory-overview-report',
    retailInventoryPropertyCount: 'retail-inventory-property-count-typewise',
    retailInventoryGlaShare: 'retail-inventory-gla-share-typewise',
    retailInventoryProperties: 'retail-inventory-properties',
    retailInventoryTenants: 'retail-inventory-tenants',
    retailInventoryUnits: 'retail-inventory-units',
    retailInventoryTrends: 'retail-inventory-trends',
    retailInventoryNeighborhoodInsights: 'retail-inventory-neighborhood-insights',
    retailInventoryDepartments: 'retail-inventory-departments',
    warehouseInventoryOverview: 'warehouse-inventory-overview-report',
    warehouseInventoryOverviewSubCat: 'warehouse-inventory-overview-subcat-report',
    warehouseInventoryGrossBuiltupReport: 'warehouse-inventory-gross-builtup-report',
    warehouseInventoryNeighborhood: 'warehouse-inventory-neighborhood_report',
    warehouseInventoryProperties: 'warehouse-inventory-property-report',
    officeInventoryOverviewReport: 'office-inventory-overview-report',
    warehouseInventoryGrossLandCoverageReport: 'warehouse-inventory-gross-land-coverage-report',
    officeInventoryGrossReport: 'office-inventory-gross-report',
    officeInventoryNeighborhoodReport: 'office-inventory-neighborhood-report',
    officeInventoryPropertiesReport: 'office-inventory-properties-report',
    retailInventoryTenantFloorDetail: 'retail-inventory-tenant-floor-detail',
    retailInventoryTenantDetails: 'retail-inventory-tenant-map-detail',
    powerBiEmbedToken: 'embed-token',
}