<app-reports-container
    [tableColumn]="totalColumnReportColumn"
    [dataValue]="totalColumnReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [dataValue]="mallTenantShareCategoryReport"
    [reportType]="chartView.pieChart"
    [chartName]="'share_trend'"
    [loading]="mallTenantShareCategoryReportLoading"
    [title]="'reports.share_trend'"
    [yearDropdown]="true"
    [yearReportType]="reports.share_trend"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<app-reports-container
    [tableColumn]="mallTenantTrentAreaableColumns"
    [dataValue]="mallTenantTrentAreaableData"
    [isDownloadExcel]="true"
    [loading]="mallTenantTrentAreaableDataLoading"
    [reportName]="'reports.tenantAreaOccupied'"
    [title]="'reports.tenantAreaOccupied'"
    [expandable]="false"
    [yearDropdown]="true"
    [yearReportType]="reports.tenantAreaOccupied"
    (yearSelected)="selectedYear($event)"
></app-reports-container>


<app-reports-container
    [tableColumn]="averageLeasetableColumns"
    [dataValue]="averageLeasetableData"
    [isDownloadExcel]="true"
    [loading]="averageLeasetableDataLoading"
    [reportName]="'reports.tentant_average_lease'"
    [title]="'reports.tentant_average_lease'"
    [expandable]="false"
    [yearDropdown]="true"
    [yearReportType]="reports.tentant_average_lease"
    (yearSelected)="selectedYear($event)"
></app-reports-container>
