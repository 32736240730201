import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class MallInventoryReportConstant {

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]
    
    static totalColumn: ReportTableColumn[] = [
        {
            header: 'reports.total_retail_unit',
            field: 'total_unit',
        },
        {
            header: 'reports.gross_leasable_area',
            field: 'gross_leasable_area',
            symbol: GlobalContants.keySymbolContants.unit
        },
        {
            header: 'reports.occupancy',
            field: 'occupancy',
            symbol: GlobalContants.keySymbolContants.percentage
        },
        {
            header: 'reports.vacant_area',
            field: 'vacant_area',
            symbol: GlobalContants.keySymbolContants.unit
        }
    ]

    static neighTrendlReporttableColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        }
    ]


}
