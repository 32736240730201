<div class="report-container office-space-rental">
    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <!-- Overview -->
    <ng-container *ngIf="overviewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ officeSpaceRentalMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === officeSpaceRentalMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overviewLoading">
            <app-overview-card
                [card_title]="placeCode  == RYDCHECK ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'"
                [total_current]="averageData?.avg_rent"
                [untillDate]="averageData?.untillDate"
                [total_prev]="averageData?.prev_avg_rent"
                [total_percentage]="averageData?.totalaverage_rate"
                [uniType]="UnitTypeEnum.currencywithsqm"
                [graphData]="{data: averageGradeData, type: 'averageRentRate', loader: overviewGradeLoading}"></app-overview-card>

                <app-overview-card
                [card_title]="'reports.occupancyRate'"
                [total_current]="occupancyData?.occupacy_rate"
                [untillDate]="occupancyData?.untillDate"
                [total_prev]="occupancyData?.prev_occupacy_rate"
                [total_percentage]="occupancyData?.totaloccupancy_rate"
                [uniType]="UnitTypeEnum.percentage"
                [graphData]="{data: occupancyGradeData, type: 'occupancyRate', loader: overviewGradeLoading}"></app-overview-card>



            <!-- <div class="overview">
                <div class="overview-data">
                    <h2 *ngIf="placeCode  != RYDCHECK">{{"reports.averageRentRate" | translate}}</h2>
                    <h2 *ngIf="placeCode  == RYDCHECK">{{"reports.AnnuallyAverageRent" | translate}}</h2>
                    <h1>
                        <ng-container *ngIf="averageData?.avg_rent">
                            {{averageData?.avg_rent | number:'1.0-1'}} <sup>{{(CurrencyCode | translate) + '/'+(sqm_unit | translate)}}</sup>
                        </ng-container>
                        <ng-container *ngIf="!averageData?.avg_rent">
                            -
                        </ng-container>
                    </h1>
                    <div class="untill">
                        <span class="directionLtr">{{"reports.untill" | translate}}
                        <ng-container *ngIf="averageData?.avg_rent">{{averageData?.max_date | date:'d MMM y'}}</ng-container>
                        <ng-container *ngIf="!averageData?.avg_rent">-</ng-container>
                        </span>
                    </div>

                </div>
                <div class="overview-v d-flex justify-content-between">
                    <div class="overview-prev-year">
                        <span>{{"reports.was" | translate}}</span>
                        <span> {{averageData?.prev_avg_rent ? (averageData?.prev_avg_rent | number:'1.0-0') + ' ' + (CurrencyCode | translate)+'/'+(sqm_unit | translate) :
                            '-'}}</span>
                    </div>
                    <div class="overview-prev d-flex">
                      <h5 class="normal-price directionLtr" *ngIf="averageData?.totalaverage_rate && averageData?.totalaverage_rate == 0"> {{
                        averageData?.totalaverage_rate | number:'1.0-0'}} %</h5>
                        <h5 class="high-price directionLtr" *ngIf="averageData?.totalaverage_rate && averageData?.totalaverage_rate > 0">+ {{
                            averageData?.totalaverage_rate | number:'1.0-0'}} %</h5>
                        <h5 class="low-price directionLtr" *ngIf="averageData?.totalaverage_rate < 0">{{
                            averageData?.totalaverage_rate | number:'1.0-0'}} %</h5>
                        <span>{{"reports.fromLastYear" | translate}}</span>
                        <mat-icon *ngIf="averageData?.totalaverage_rate > 0" class="high-price">
                            call_made</mat-icon>
                        <mat-icon *ngIf="averageData?.totalaverage_rate < 0" class="low-price">
                            call_received</mat-icon>
                    </div>
                </div>
                <div class="horizontal-progress">
                    <ng-container *ngFor="let grade of averageGradeData; let i = index">
                        <div class="progress d-flex align-items-center" *ngIf="grade[1] && grade[1] != 0">
                            <h5>{{grade[0]}}</h5>
                            <section>
                                <div class="bar"
                                    [ngStyle]="{'width': reportService.calculateGradePercentage(grade[1], averageTotalGradeValue) > 1 ? reportService.calculateGradePercentage(grade[1], averageTotalGradeValue)+'%' : '2%', 'opacity': getOpacity(i, getNumber(grade[1]), 'averageRentRate' )}">
                                </div>
                                <small>{{getNumber(grade[1]) | number:'1.0-1'}} {{(CurrencyCode | translate)+'/'+(sqm_unit | translate)}}</small>
                            </section>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="overview">
                <div class="overview-data">
                    <h2>{{"reports.occupancyRate" | translate}}</h2>
                    <h1>
                        <ng-container *ngIf="occupancyData?.occupacy_rate; else otherOccupacyRate">
                            {{occupancyData?.occupacy_rate | number:'1.0-1'}} <sup>%</sup>
                        </ng-container>
                        <ng-template #otherOccupacyRate>-</ng-template>
                    </h1>
                    <div class="untill">
                        <span class="directionLtr">{{"reports.untill" | translate}}
                        <ng-container *ngIf="occupancyData?.occupacy_rate">{{occupancyData?.max_date | date:'d MMM y'}}</ng-container>
                        <ng-container *ngIf="!occupancyData?.occupacy_rate">-</ng-container>
                        </span>
                    </div>
                </div>
                <div class="overview-v d-flex justify-content-between">
                    <div class="overview-prev-year">
                        <span>{{"reports.was" | translate}}</span>
                        <span class="directionLtr nbrpercentage"> {{occupancyData?.prev_occupacy_rate ?
                           (occupancyData?.prev_occupacy_rate | number:'1.0-0') +'%' : '-'}}</span>
                    </div>
                    <div class="overview-prev d-flex">
                      <h5 class="normal-price directionLtr" *ngIf="occupancyData?.totaloccupancy_rate && occupancyData?.totaloccupancy_rate == 0"> {{
                        occupancyData?.totaloccupancy_rate | number:'1.0-0' }} %</h5>
                        <h5 class="high-price directionLtr" *ngIf="occupancyData?.totaloccupancy_rate && occupancyData?.totaloccupancy_rate > 0">+ {{
                            occupancyData?.totaloccupancy_rate | number:'1.0-0' }} %</h5>
                        <h5 class="low-price directionLtr" *ngIf="occupancyData?.totaloccupancy_rate < 0">{{
                            occupancyData?.totaloccupancy_rate | number:'1.0-0'}} %</h5>
                        <span>{{"reports.fromLastYear" | translate}}</span>
                        <mat-icon *ngIf="occupancyData?.totaloccupancy_rate > 0" class="high-price">
                            call_made</mat-icon>
                        <mat-icon *ngIf="occupancyData?.totaloccupancy_rate < 0" class="low-price">
                            call_received</mat-icon>
                    </div>
                </div>
               <div class="horizontal-progress">
                    <ng-container *ngFor="let grade of occupancyGradeData; let i = index">
                        <div class="progress d-flex align-items-center" *ngIf="grade[1] && grade[1] != 0">
                            <h5>{{grade[0]}}</h5>
                            <section class="occupancy">
                                <div class="bar"
                                    [ngStyle]="{'width': reportService.calculateGradePercentage(grade[1], occupancyTotalGradeValue) > 1 ? reportService.calculateGradePercentage(grade[1], occupancyTotalGradeValue)+'%' : '2%', 'opacity': getOpacity(i, grade[1], 'occupancyRate')}">
                                </div>
                                <small>{{grade[1] | number:'1.0-1' }}% </small>
                            </section>
                        </div>
                    </ng-container>
                </div>
            </div> -->
        </div>
        <!-- Rent Rate Graph -->
        <div class="report-model report-rentrate" *ngIf="overviewChartData">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.rental_price_in' | translate}} <span class="text-capitalize">{{(placeName | lowercase) | translate}}</span> <!-- {{'reports.past_years' | translate}} --> ({{CurrencyCode | translate}}/{{'reports.sqm_unit' | translate}})
                    </h3>
                </div>
                <!-- <button class="btn-select-grade" [matMenuTriggerFor]="menu">
                    <div class="setext"><ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l" style="padding-right: 2px;">,</span></ng-container></div> <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon></button>
                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'" class="grades-menu">
                    <div (click)="$event.stopPropagation()">
                        <h5>Choose Maximum 3 options</h5>
                        <ng-container *ngFor="let cateogory of overviewChartData | keyvalue; let i = index">
                            <mat-checkbox (change)="selectChart($event, cateogory.key)" [checked]="selectedChartValue.includes(cateogory?.key)">{{cateogory?.key}}</mat-checkbox>

                        </ng-container>
                    </div>
                </mat-menu> -->
                <button class="btn-select-grade" [matMenuTriggerFor]="menu" *ngIf="(retailRentalChartReport | json) != '{}'">
                    <div class="setext">
                      <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l" style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'" class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                      <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                      <ng-container *ngFor="let category of getReportKeys()">
                        <mat-checkbox (change)="selectChart($event, category)" [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                      </ng-container>
                    </div>
                  </mat-menu>
            </div>
            <ng-container *ngIf="overviewGraphLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!overviewGraphLoading">
                <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                    <ng-container *ngFor="let chart of retailRentalChartReportArray; let i = index">
                      <ng-container *ngFor="let key of getKeys(chart)">
                        <app-report-charts
                        class="grchart"
                        [data]="chart[key]"
                        [chartType]="chartView.smallBarChart"
                        [isBorder]="false"
                        [name]="key" [index]="i" [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '300px'}"
                        >
                    </app-report-charts>
                  </ng-container>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>

    <!-- Rent Unit Size Wise -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === officeSpaceRentalMenuEnum.rentUnitSizeWise}"
        id="{{ officeSpaceRentalMenuEnum.rentUnitSizeWise }}">
        <!-- Multiple Year Dropdown -->
        <app-report-year-dropdown [reportType]="'rentUnitSize'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.rentUnitSizeWise" | translate}}</h3>
                    <!-- <span class="totalTransaction">({{rentUnitSizeCount ? reportService.formatInternationNum(rentUnitSizeCount) : rentUnitSizeCount}} {{"reports.totalUnits" | translate}})</span> -->
                </div>
            </div>
            <!-- <ng-container *ngIf="rentUnitSizeLoading">
                <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
            </ng-container> -->
            <app-report-datatable class="rosr-rentunitsize-table"
                [showLoader]="!rentUnitSizeLoading"
                [tableColumns]="rentUnitSizeColumns"
                [dataLength]="rentUnitSizeCount"
                [tableData]="rentUnitSizeData"
                (pageEvent)="onPageEvent($event, officeSpaceRentalMenuEnum.rentUnitSizeWise)"
                (sort)="sortData($event, officeSpaceRentalMenuEnum.rentUnitSizeWise)"
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'office_rental_rent_unitsize', 'a')"
                [showEstaterText]="true"
                [expandColumn]="true"
                [tableExpandColumns]="unitsizeExpandColumns"
                [dropDownOption]="unitSizeDropdown"
                (dropDownValue)="dropDownChange($event, officeSpaceRentalMenuEnum.rentUnitSizeWise)"
                [downloadLoading]="downloadLoading['a']"
                [showTooltip]="true"
            >
            </app-report-datatable>
        </div>
    </div>

    <!-- Properties -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === officeSpaceRentalMenuEnum.properties}"
        id="{{ officeSpaceRentalMenuEnum.properties }}">
        <!-- Multiple Year Dropdown -->
        <app-report-year-dropdown [reportType]="'units'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.projectsProperties" | translate}}</h3>
                    <span class="totalTransaction">({{unitsCount ? reportService.formatInternationNum(unitsCount) : unitsCount }} {{"reports.total_transaction" | translate}})</span>
                </div>
            </div>
            <!-- <ng-container *ngIf="unitsLoading">
                <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
            </ng-container> -->
            <app-report-datatable [showLoader]="!unitsLoading" [tableColumns]="propertiesColumns" [dataLength]="unitsCount"
                [tableData]="unitsData" (pageEvent)="onPageEvent($event, officeSpaceRentalMenuEnum.properties)"
                (sort)="sortData($event, officeSpaceRentalMenuEnum.properties)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'office_rental_property', 'b')" [downloadLoading]="downloadLoading['b']" [showEstaterText]="true" [dropDownOption]="propertiesDropdown" (dropDownValue)="dropDownChange($event, officeSpaceRentalMenuEnum.properties)">
            </app-report-datatable>
        </div>
    </div>

</div>
