<!-- <div class="container">
  <div class="heading text-center">
    <p>{{"reports.save_search_criteria" | translate}}</p>
  </div>
  <div class="content">
    <form [formGroup]="savedFilter" (ngSubmit)="onSaveClick(savedFilter)">
      <div class="form-group" [class.error]="savedFilter.invalid && (savedFilter.dirty || savedFilter.touched)">
        <label for="inputField">{{"reports.filter_title" | translate}}</label>
        <input id="inputField" class="inputField" formControlName="inputField"
          placeholder="{{'reports.Untitled' | translate}}" maxlength="21">
        <div class="character-count">
          <span>{{savedFilter.value.inputField?.length}}</span><span>/ 20</span>
        </div>
        <div class="error-msg">
          <div class="error-msg-inner" *ngIf="savedFilter.invalid && (savedFilter.dirty || savedFilter.touched)">
            <span *ngIf="inputField.errors?.['required'] && inputField?.errors != null">
              {{'User.title_required' | translate}}</span>
            <span *ngIf="inputField.errors?.['maxlength'] && inputField?.errors != null">
              {{'User.search_criteria_maxlength' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="form-group-btns">
        <button (click)="onCancelClick()" type="button" class="btn btn-cancel">{{'User.Cancel' | translate}}</button>
        <button type="submit" [disabled]="savedFilter.invalid" class="btn btn-save">{{'reports.save'|
          translate}}</button>
      </div>
    </form>
  </div>
</div> -->
<div class="loader-prog" *ngIf="loader">
  <img width="100px" src="assets/images/loading.gif" alt="loader">
</div>
<div class="load-catchment-container edit-catchment-container">
  <div class="title">
    <h2>{{'map.saveNewCatchmet' | translate}}</h2>
    <i (click)="onCancelClick()"><mat-icon>close</mat-icon></i>
  </div>
  <h4>{{'map.saveNewCatchmetMsg' | translate}} </h4>
  <form [formGroup]="savedFilter" (ngSubmit)="onSaveClick(savedFilter)">
    <div class="edit-catchment">
      <h5>{{'map.name' | translate}}</h5>
      <input id="inputField" formControlName="inputField" class="edit-inputField" #input maxlength="20">
      <div class="hint"><mat-hint>{{input.value.length}}/20</mat-hint></div>
    </div>
    <div class="save-catchment">
      <button class="cancel-btn" type="button" (click)="onCancelClick()">{{'User.Cancel' | translate}}</button>
      <button type="submit" class="update-btn" [disabled]="savedFilter.invalid">{{'reports.save'| translate}}</button>

    </div>
  </form>
</div>