<ng-container *ngIf="loading">
    <div class="prop-info detail-info">
        <div class="prop-detail">
            <h2><app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                    [height]="'25px'"></app-skeleton-loader></h2>
            <div class="prop-list">
                <ul>
                    <li><app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                            [height]="'15px'"></app-skeleton-loader></li>
                    <li><app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                            [height]="'15px'"></app-skeleton-loader></li>
                    <li><app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                            [height]="'15px'"></app-skeleton-loader> </li>
                </ul>
            </div>
            <div style="width: 45%;">
                <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                    [height]="'35px'"></app-skeleton-loader>
            </div>
            <div class="land-info">
                <div style="width: 30%;">
                    <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                        [height]="'25px'"></app-skeleton-loader>
                </div>
                <div class="info-land">
                    <div class="skel-info">
                        <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                            [height]="'65px'"></app-skeleton-loader>
                    </div>
                    <div class="skel-info">
                        <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                            [height]="'65px'"></app-skeleton-loader>
                    </div>
                    <div class="skel-info">
                        <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                            [height]="'65px'"></app-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
        <div class="prop-img">
            <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"
                [height]="'250px'"></app-skeleton-loader>
        </div>
    </div>
</ng-container>
<div class="prop-info detail-info proj-info" *ngIf="ProjectDetails && !loading">
    <div class="prop-detail">
        <h2>{{ProjectDetails.project_name}}</h2>
        <div class="prop-list">
            <ul>
                <li><span>{{'reports.address' | translate}}: </span> {{ ProjectDetails.address ? ProjectDetails.address
                    : '-' }}</li>
                <li *ngIf="ProjectDetails.year_of_build"><span>{{'reports.yearOfBuild' | translate}}: </span> {{
                    ProjectDetails.year_of_build ? ProjectDetails.year_of_build : '-' }}</li>
                <li *ngIf="ProjectDetails.developer_name"><span>{{'reports.developer' | translate}}: </span> {{
                    ProjectDetails.developer_name ? ProjectDetails.developer_name : '-' }} </li>
            </ul>
        </div>
        <div class="property-cat" *ngIf="ProjectDetails.municipal_land_zone">
            <h2>{{'reports.property_type_zoning' | translate}}</h2>
            <ul>
                <li><span>{{'reports.zoning' | translate}}:</span> {{ ProjectDetails.municipal_land_zone ?
                    ProjectDetails.municipal_land_zone : '-' }}</li>
            </ul>
        </div>
        <div class="property-info-bx" *ngIf="ProjectDetails.property_types">
            <h2 class="d-flex">{{'reports.property_information' | translate}} <span class="d-flex align-items-center"
                    matTooltipPosition="above" matTooltipClass="custom-tooltip"
                    [matTooltip]="'reports.thisSectionProvidesBasicDetails' | translate"><img
                        src="assets/images/prop-info.svg" alt=""></span></h2>
            <div class="prop-list">
                <ul>
                    <li><span>{{'reports.propertyTypes' | translate}}:</span> {{ ProjectDetails.property_types ?
                        ProjectDetails.property_types : '-' }}</li>
                </ul>
            </div>
        </div>

        <div class="land-info">
            <div class="info-land">
                <div class="p-info">
                    <h6>{{'reports.total_area' | translate}}</h6>
                    <h3>{{ ProjectDetails.total_area ? ProjectDetails.total_area : '-' }} <sup
                            *ngIf="ProjectDetails.total_area">{{unitType | translate}}</sup></h3>
                </div>
                <div class="p-info">
                    <h6>{{'reports.projectsProperties' | translate}}</h6>
                    <h3>{{ ProjectDetails.total_properties ? ProjectDetails.total_properties : '-' }}</h3>
                </div>
                <div class="p-info">
                    <h6>{{'reports.grossBuiltUpArea' | translate}}</h6>
                    <h3>{{ ProjectDetails.total_gross_area ? ProjectDetails.total_gross_area : '-' }} <sup
                            *ngIf="ProjectDetails.total_gross_area">{{unitType | translate}}</sup></h3>
                </div>
            </div>
        </div>
    </div>
    <div class="prop-img">
        <img src="assets/images/project-detail.png" alt="Image">
    </div>
</div>