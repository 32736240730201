import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Injectable({
  providedIn: 'root'
})
export class ReportPropertyDetailService {

  propertyInfo: any = null;
  placeCode: any = null;
  CurrencyCode: any = null;
  userInfo: any = null;

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService,
    private sharedService: SharedService,
    private alertService: AlertService,
    public translate: TranslateService,
  ) {
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)
  }


  unitPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  unitTransactionPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  propertyTransactionPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  rentalIncomeCountPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  rentalIncomeAreaPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  getOverviewPropertySummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsOverview}${changeParams}&property_uid=${this.propertyInfo?.property_uid}`;
    return this.apiServive.get(url, false);
  }

  getPropertyInfo(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsPropertyInfo}${changeParams}&property_uid=${this.propertyInfo?.property_uid}`;
    return this.apiServive.get(url, false);
  }

  getBuiltInformation(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsBuiltupinfo}${changeParams}&property_uid=${this.propertyInfo?.property_uid}`;
    return this.apiServive.get(url, false);
  }

  getCostOfConstruction(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsCostofconstruction}${changeParams}&property_uid=${this.propertyInfo?.property_uid}`;
    return this.apiServive.get(url, false);
  }

  getMaxPermittedFloor(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsMaxPermittedFloor}${changeParams}&property_uid=${this.propertyInfo?.property_uid}`;
    return this.apiServive.get(url, false);
  }

  getPropertyDetailsLandValue(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsLandValue}${changeParams}&property_uid=${this.propertyInfo?.property_uid}`;
    return this.apiServive.get(url, false);
  }

  getUnitReport(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.unitPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.inventoryUnitReport}${changeParams}&property_uid=${this.propertyInfo?.property_uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.unitPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }


  getUnitTransctionReport(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.unitTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.inventoryUnitTransctionReport}${changeParams}&property_uid=${this.propertyInfo?.property_uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.unitTransactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }

  getPropertyTransctionReport(payload: any, report_category:string='', property_uid:string) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.propertyTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsPropertytransactions}${changeParams}&property_uid=${property_uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    if(report_category){
      url += `&report_category=${report_category}`
    }
    this.propertyTransactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }

  getRentalIncomeCountReport(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.propertyTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsresidentialrentalincomesubtypewise}${changeParams}&property_uid=${this.propertyInfo?.property_uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.propertyTransactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }

  getRentalIncomeAreaReport(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.propertyTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.reportPropertyDetailsresidentialrentalincometypewise}${changeParams}&property_uid=${this.propertyInfo?.property_uid}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.propertyTransactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }

  /**
   * Get property amenities list
   * @param property_uid
   * @returns
   */
  getPropertyAmneities(property_uid:string){
    let url = `${environment.miUrl}${apiEndPoints.PropertyAmenities}?property_uid=${property_uid}`
    return this.apiServive.get(url)
  }

  downloadRentalReport(type: string, report_category:string='') {
    let module_name = 'investment_residential'
    if(report_category === 'office') {
      module_name = "office_rental";
    }else if(report_category === 'retail') {
      module_name = "retail_rental";
    }
    this.downloadInvestmentReport(type, this.userInfo?.user_id, this.propertyInfo?.property_uid, '', report_category).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode?.toLowerCase()}&module_name=${module_name}`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        }
      }
    )
  }

  downloadInvestmentReport(type:string, user_id:any, property_uid:string='', proj_uid:string='', report_category=''){
    let url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    if(property_uid) url += `&property_uid=${property_uid}`;
    if(proj_uid) url += `&project_uid=${proj_uid}`;
    if(report_category) url += `&report_category=${report_category}`;
    return this.apiServive.get(url);
  }
}
