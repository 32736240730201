import { 
  AfterViewInit, 
  Component, 
  ElementRef, 
  Input, 
  NgZone, 
  OnChanges, 
  OnInit, 
  SimpleChanges, 
  ViewChild 
} from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ReportsChartEnum } from '../../../../../reports/models/enums/reports.enum';
import { SharedService } from '../../../../../common/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-chart-view',
  templateUrl: './report-chart-view.component.html',
  styleUrls: ['./report-chart-view.component.scss']
})
export class ReportChartViewComponent implements AfterViewInit, OnInit, OnChanges {

  @Input() height = '300px';
  @Input() data: any = [];
  @Input() chartType!: number;
  @Input() legend: any = [];
  @Input() isBorder: boolean=true;
  @ViewChild('chart', { static: true }) private chartDom!: ElementRef;
  
  private chart!: am4charts.Chart;

  lang!: number;
   
  constructor(
    private zone: NgZone,
    private sharedService: SharedService,
    private translation: TranslateService
  ){}


  ngOnInit(): void {
    am4core.useTheme(am4themes_animated);
    this.lang = this.sharedService.getCurrentLangID();
    this.getChangedLang();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.zone.runOutsideAngular(() => {
        this.createChart();
    });
  }

  getChangedLang(){
    this.sharedService.language$.subscribe(res=>{
      if(res){
        this.lang = this.sharedService.getCurrentLangID();
        this.zone.runOutsideAngular(() => {
            this.createChart();
        });
      }
    });
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
        this.createChart();
    });
  }

  createChart(){
    switch (this.chartType) {
      case ReportsChartEnum.barChart:
        this.barChart();
        break;
      case ReportsChartEnum.pieChart:
        this.pieChart();
        break;
      case ReportsChartEnum.barStackedChart:
        this.barStackedChart();
        break;
      case ReportsChartEnum.multiBarLineGroupChart:
        this.multiBarLineGroupChart();
        break; 
      case ReportsChartEnum.barLineGroupChart:
        this.barLineGroupChart();
        break;
      case ReportsChartEnum.normalBarLineChart:
        this.normalBarLineChart();
        break;     
      }
  }


  barChart(){
    
    let chart = am4core.create(this.chartDom.nativeElement, am4charts.XYChart);

    chart.data = this.data;
    
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.labels.template.fontSize = 11;
    categoryAxis.renderer.minGridDistance = 30;

    
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.min = 0;
    
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "category";
    series.dataFields.valueY = "value";
    series.columns.template.tooltipText = "{valueY.value}";
    series.columns.template.tooltipY = 0;
    series.columns.template.strokeOpacity = 0;
    series.columns.template.fill = am4core.color('#3774E8');
    series.columns.template.stroke = am4core.color('#3774E8');
    
    if(this.lang === 2){
      chart.rtl = false;  
    }
    this.chart = chart

  }

  pieChart(){
    
    let chart: any  = am4core.create(this.chartDom.nativeElement, am4charts.PieChart);

    chart.data = this.data;
    
    let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'category';
      pieSeries.ticks.template.disabled = true;
      pieSeries.alignLabels = false;
      pieSeries.labels.template.disabled = true;
      pieSeries.slices.template.tooltipText = "[#ffffff]{category}: {value.percent.formatNumber('#.#')}%";
      
      // Add a legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.maxHeight = 250;
      chart.legend.scrollable = true;
      chart.legend.fontSize = 11;   
      
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      chart.legend.itemContainers.template.reverseOrder = this.lang === 1 ? false : true;

      if(this.lang === 2){
        chart.rtl = false;  
      }
      this.chart = chart
  }

  barStackedChart() {
   
      // Create chart instance
      let chart = am4core.create(this.chartDom.nativeElement,am4charts.XYChart);
      chart.data = this.data;

      
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'category';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.grid.template.strokeWidth = 0;
      categoryAxis.renderer.labels.template.fontSize = 11;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.strokeWidth = 0;
      valueAxis.extraMin = 0.1;
      valueAxis.extraMax = 0.2;
      valueAxis.renderer.minGridDistance = 60;
      valueAxis.renderer.baseGrid.strokeOpacity = 0.8;

      // Create series
      let value = chart.series.push(new am4charts.ColumnSeries());
      value.dataFields.valueX = 'value';
      value.dataFields.categoryY = 'category';
      value.clustered = false;
      value.name = this.translation.instant('reports.male');
      value.fill = am4core.color('#3774E8');
      value.stroke = am4core.color('#3774E8');
      value.columns.template.width = am4core.percent(40);
      value.columns.template.tooltipText = '{name}: [bold]{valueX}[/]';
      value.columns.template.adapter.add(
        'tooltipText',
        function (x, target: any): any {
          var values = target.dataItem.values;
          if (values.valueX.value === 0) {
            return '--';
          } else if (values.valueX.value < 0) {
            return `{name} : [bold]${Math.abs(values.valueX.value)}[/]`;
          }
        }
      );  
      
      let valueLabel = value.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = '{valueX}';
      valueLabel.label.fontSize = 11;
      valueLabel.label.adapter.add('text', function (x, target: any): any {
        var values = target.dataItem.values;
        if (values.valueX.value === 0) {
          return '--';
        } else if (values.valueX.value < 0) {
          return Math.abs(values.valueX.value);
        }
      });

      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;
      valueLabel.label.horizontalCenter = 'right';
      valueLabel.label.dx = -10;

      let value1 = chart.series.push(new am4charts.ColumnSeries());
      value1.dataFields.valueX = 'value1';
      value1.dataFields.categoryY = 'category';
      value1.clustered = false;
      value1.name = this.translation.instant('reports.female');
      value1.fill = am4core.color('#EE7435');
      value1.stroke = am4core.color('#EE7435');
      value1.columns.template.width = am4core.percent(40);
      
      value1.columns.template.tooltipText =
        '{name} : [bold]{valueX}[/]';
        
      let value1Label1 = value1.bullets.push(new am4charts.LabelBullet());
      value1Label1.label.text = '{valueX}';
      value1Label1.label.fontSize = 11;
      value1Label1.label.adapter.add('text', function (x, target: any): any {
        var values = target.dataItem.values;
        if (values.valueX.value === 0) {
          return '--';
        } else {
          return Math.abs(values.valueX.value);
        }
      });
      value1Label1.label.hideOversized = false;
      value1Label1.label.truncate = false;
      value1Label1.label.horizontalCenter = 'left';
      value1Label1.label.dx = 10;

      chart.legend = new am4charts.Legend();
      chart.legend.valueLabels.template.disabled = true;
      chart.legend.fontSize = 11;
      chart.legend.markers.template.width = 100;
      chart.legend.markers.template.height = 8;
      chart.legend.itemContainers.template.paddingRight = 10;

      if(this.lang === 2){
        chart.rtl = false;  
      }
      this.chart = chart
  
  }

  multiBarLineGroupChart(){
    let chart = am4core.create(
      this.chartDom.nativeElement,
      am4charts.XYChart
    );

    chart.colors.step = 2;
    chart.data = this.data;
    chart.legend = new am4charts.Legend();
    chart.legend.valueLabels.template.disabled = true;
    chart.legend.fontSize = 11;
    chart.legend.markers.template.width = 100;
    chart.legend.markers.template.height = 8;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'category';
    xAxis.renderer.cellStartLocation = 0.2;
    xAxis.renderer.cellEndLocation = 0.8;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.grid.template.strokeWidth = 0;
    xAxis.renderer.labels.template.fontSize = 11;


    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.strokeWidth = 0;
    yAxis.extraMin = 0.1;
    yAxis.extraMax = 0.2;
    function createSeries(value: string, name: string, color: string) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = 'category';
      series.name = name;
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
      series.sequencedInterpolation = true;
      series.fill = am4core.color(color);
      series.stroke = am4core.color(color);

      return series;
    }

    createSeries('value1', this.translation.instant('reports.male'), '#3774E8');
    createSeries('value2', this.translation.instant('reports.female'), '#EE7435');


   
    let distanceAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    distanceAxis1.renderer.opposite = true;
    distanceAxis1.renderer.grid.template.strokeWidth = 0;
    distanceAxis1.renderer.labels.template.disabled = true;
    distanceAxis1.extraMin = 0.1;
    distanceAxis1.extraMax = 0.2;
    let durationSeries = chart.series.push(new am4charts.LineSeries());
    durationSeries.dataFields.valueY = 'total';
    durationSeries.dataFields.categoryX = 'category';
    durationSeries.yAxis = distanceAxis1;
    durationSeries.name = this.translation.instant('reports.total');
    durationSeries.strokeWidth = 2;
    durationSeries.propertyFields.strokeDasharray = 'dashLength';
    durationSeries.tooltipText = '{valueY}';
    
    let circle = durationSeries.bullets.push(new am4charts.CircleBullet());
    let tooltip = `{name}: [bold]{valueY}[/]\n`
    tooltip = tooltip + this.translation.instant('reports.male')`: [bold]{value1}[/]\n`
    tooltip = tooltip + this.translation.instant('reports.female')`: [bold]{value2}[/]\n`
    circle.tooltipText = `${tooltip}`;
    if(this.lang === 2){
      chart.rtl = false;  
    }
    this.chart = chart
  }

  barLineGroupChart(){
    let chart = am4core.create(
      this.chartDom.nativeElement,
      am4charts.XYChart
    );
    chart.data = this.data;

    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.renderer.labels.template.fontSize = 11;

    //categoryAxis.renderer.labels.template.dy = 5;
    categoryAxis.renderer.grid.template.strokeWidth = 0;

    let distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
    distanceAxis.title.text = this.legend[0];
    distanceAxis.renderer.grid.template.strokeWidth = 0;
    distanceAxis.renderer.labels.template.fontSize = 11;

    distanceAxis.extraMin = 0.1;
    distanceAxis.extraMax = 0.2;

    let distanceSeries = chart.series.push(new am4charts.ColumnSeries());
    distanceSeries.dataFields.valueY = 'barValue';
    distanceSeries.dataFields.categoryX = 'category';
    distanceSeries.yAxis = distanceAxis;
    distanceSeries.tooltipHTML = "{valueY}{symbol}";
    distanceSeries.name = this.legend[0];
    distanceSeries.columns.template.propertyFields.strokeDasharray =
      'dashLength';
    distanceSeries.columns.template.propertyFields.fillOpacity = 'alpha';
    distanceSeries.showOnInit = true;

    distanceSeries.columns.template.fill = am4core.color("#3774E8"); 

    let distanceState =
      distanceSeries.columns.template.states.create('hover');
    distanceState.properties.fillOpacity = 0.9;

    if(this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode) != environment.RYDCHECK){
      let distanceAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      distanceAxis1.title.text = this.legend[1];
      distanceAxis1.renderer.opposite = true;
      distanceAxis1.renderer.grid.template.strokeWidth = 0;
      distanceAxis1.renderer.labels.template.fontSize = 11;

      distanceAxis1.extraMin = 0.1;
      distanceAxis1.extraMax = 0.2;

   
      
     let durationSeries = chart.series.push(new am4charts.LineSeries());
      durationSeries.dataFields.valueY = 'lineValue';
      durationSeries.dataFields.categoryX = 'category';
      durationSeries.yAxis = distanceAxis1;
      durationSeries.name = this.legend[1];
      durationSeries.strokeWidth = 2;
      durationSeries.propertyFields.strokeDasharray = 'dashLength';
      durationSeries.tooltipText = '{valueY}% ';
      durationSeries.showOnInit = true;
      durationSeries.stroke = am4core.color('#EE7435');
      durationSeries.fill = am4core.color('#EE7435'); 

      let durationBullet = durationSeries.bullets.push(new am4charts.Bullet());
      let durationRectangle = durationBullet.createChild(am4core.Rectangle);
      durationBullet.horizontalCenter = 'middle';
      durationBullet.verticalCenter = 'middle';
      durationBullet.fill = am4core.color('#EE7435')
      durationBullet.width = 7;
      durationBullet.height = 7;
      durationRectangle.width = 7;
      durationRectangle.height = 7;
      durationRectangle.fill = am4core.color('#EE7435')
    }

    
    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = 11;
    let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;

    chart.cursor = new am4charts.XYCursor();
    chart.legend.itemContainers.template.reverseOrder = this.lang === 1 ? false : true;

    if(this.lang === 2){
      chart.rtl = false;  
    }
    this.chart = chart;
  }

  normalBarLineChart(){
    let chart = am4core.create(
      this.chartDom.nativeElement,
      am4charts.XYChart
    );

    chart.colors.step = 2;
    chart.data = this.data;
    chart.legend = new am4charts.Legend();
    chart.legend.valueLabels.template.disabled = true;
    chart.legend.fontSize = 12;
    chart.legend.markers.template.width = 100;
    chart.legend.markers.template.height = 8;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'category';
    xAxis.renderer.cellStartLocation = 0.2;
    xAxis.renderer.cellEndLocation = 0.8;
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.grid.template.strokeWidth = 0;
    xAxis.renderer.labels.template.fontSize = 11;


    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.strokeWidth = 0;
    yAxis.extraMin = 0.1;
    yAxis.extraMax = 0.2;
    function createSeries(value: string, name: string, color: string) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = 'category';
      series.name = name;
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
      ///series.columns.template.width = am4core.percent(100);
      series.sequencedInterpolation = true;
      series.fill = am4core.color(color);
      series.stroke = am4core.color(color);

     return series;
    }

    createSeries('value1', 'Occupied Area (sqm)', '#3774E8');

    let distanceAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    distanceAxis1.renderer.opposite = true;
    distanceAxis1.renderer.grid.template.strokeWidth = 0;
    distanceAxis1.renderer.labels.template.disabled = true;
   
    let durationSeries = chart.series.push(new am4charts.LineSeries());
    durationSeries.dataFields.valueY = 'total';
    durationSeries.dataFields.categoryX = 'category';
    durationSeries.yAxis = distanceAxis1;
    durationSeries.name = 'Occupied Ratio (%)';
    durationSeries.strokeWidth = 2;
    durationSeries.propertyFields.strokeDasharray = 'dashLength';
    durationSeries.tooltipText = '{valueY}';
    durationSeries.stroke = am4core.color('#EE7435');
    durationSeries.fill = am4core.color('#EE7435'); 

    let circle = durationSeries.bullets.push(new am4charts.CircleBullet());
    let tooltip = `{name}: [bold]{valueY}[/]\n`
    tooltip = tooltip + `Occupied Area (sqm)  : [bold]{value1}[/]\n`
    
    circle.tooltipText = `${tooltip}`
    if(this.lang === 2){
      chart.rtl = false;  
    }
    this.chart = chart
  }


  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
