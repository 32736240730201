import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';
import { ApiService } from 'src/app/common/services/api.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfficeReportPropertyDetailService {
  constructor(
    private apiServive: ApiService,
    private reportService: ReportService,
    private sharedService: SharedService,
    private alertService: AlertService,
    public translate: TranslateService
  ) {}
  placeCode: any = null;
  userInfo: any = null;
  CurrencyCode: any = null;
  retailUnitPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  getRetailUnit(payload: any, report_category:string='', property_uid:string) {
      let queryParams = { ...this.reportService.queryParams };
      if (queryParams?.year) {
        queryParams.year = this.retailUnitPayload.year || this.reportService.yearMaxValue(queryParams.year);
      }
      const changeParams = this.reportService.setfilterQueryParams(queryParams);
      let offset = payload.pageNumber || 0
      let url: string = `${environment.miUrl}${apiEndPoints.propertyDetailsUnitRentalReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}&property_uid=${property_uid}`;
      if (payload.sort_order) {
        url += `&sort_order=${payload.sort_order}`;
      }
      if(offset == 0){
        url += "&is_count=true"
      }
      if(report_category){
        url += `&report_category=${report_category}`
      }
      return this.apiServive.get(url, false);
    }
}
