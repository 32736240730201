import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { OccupancyRentalMenuEnum } from "./report-occupancy-rental.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class ReportOccupancyRentalConstants {
    static overViewMenu = [
        {
            label: 'reports.overview',
            name: OccupancyRentalMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.residential_units_grade_wise',
            name: OccupancyRentalMenuEnum.residentialUnitsGradeWise,
            count: null
        },
        {
            label: 'reports.residential_units_type_wise',
            name: OccupancyRentalMenuEnum.residentialUnitsTypeWise,
            count: null
        },
        {
            label: 'reports.commercial_units_grade_wise',
            name: OccupancyRentalMenuEnum.commercialUnitsGradeWise,
            count: null
        },
        {
            label: 'reports.commercial_units_size_wise',
            name: OccupancyRentalMenuEnum.commercialUnitsSizeWise,
            count: null
        },
        {
            label: 'reports.unit_rental_transactions',
            name: OccupancyRentalMenuEnum.unitRentalTransaction,
            count: null
        },
    ]
    static residentialGradeColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
       
        {
            header: 'average_size',
            columnName: 'reports.AverageSize',
            field: 'average_size',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'rentals_as_lumpsum',
            columnName: 'reports.rentals_as_lumpsum',
            field: 'rentals_as_lumpsum',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        },
        {
            header: 'rental_price',
            columnName: 'reports.monthly_rental_rate',
            field: 'rental_price',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter,
        }
    ]
    static residentialGradeExpandColumns: any[] = [
        { field : 'grade', convertNumber: false },
        { field : 'occupancy', convertNumber: true },
        { field : 'average_size', convertNumber: true },
        { field : 'rentals_as_lumpsum', convertNumber: true },
        { field : 'rental_price', convertNumber: true }
    ]
    static residentialTypeColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
       
        {
            header: 'average_size',
            columnName: 'reports.AverageSize',
            field: 'average_size',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'rentals_as_lumpsum',
            columnName: 'reports.rentals_as_lumpsum',
            field: 'rentals_as_lumpsum',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        },
        {
            header: 'rental_price',
            // columnName: 'reports.monthly_rentals',
            columnName: 'reports.monthly_rental_rate',
            field: 'rental_price',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter,
        }
    ]
    static residentialTypeExpandColumns: any[] = [
        { field : 'unit_type', convertNumber: false },
        { field : 'occupancy', convertNumber: true },
        { field : 'average_size', convertNumber: true },
        { field : 'rentals_as_lumpsum', convertNumber: true },
        { field : 'rental_price', convertNumber: true }
    ]
    static commercialGradeColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
        {
            header: 'commercialRetail',
            columnName: 'retail',
            field: 'retails',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'commercial',
            columnName: 'reports.commercial',
            field: 'commercial',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'clinics',
            columnName: 'reports.clinics',
            field: 'clinics',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'professional',
            columnName: 'reports.professional',
            field: 'professional',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        }
    ]
    static commercialGradeExpandColumns: any[] = [
        { field : 'grade', convertNumber: false },
        { field : 'occupancy', convertNumber: true },
        { field : 'retails', convertNumber: true },
        { field : 'commercial', convertNumber: true },
        { field : 'clinics', convertNumber: true },
        { field : 'professional', convertNumber: true }
    ]
    static commercialSizeColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
        {
            header: 'commercialRetail',
            columnName: 'retail',
            field: 'retail',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'commercial',
            columnName: 'reports.commercial',
            field: 'commercial',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'clinics',
            columnName: 'reports.clinics',
            field: 'clinics',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'professional',
            columnName: 'reports.professional',
            field: 'professional',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        }
    ]
    static commercialSizeExpandColumns: any[] = [
        { field : 'unit_size', convertNumber: false },
        { field : 'occupancy', convertNumber: true },
        { field : 'retail', convertNumber: true },
        { field : 'commercial', convertNumber: true },
        { field : 'clinics', convertNumber: true },
        { field : 'professional', convertNumber: true }
    ]
    static transactionColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'project_name',
            columnName: 'reports.project_name',
            field: 'project_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'unit_type',
            columnName: 'reports.unitType',
            field: 'unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter
        },
        {
            header: 'rental_lumpsum',
            columnName: 'reports.rental_lumpsum',
            field: 'rental_lumpsum',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.monthly
        },
        {
            header: 'rental',
            columnName: 'reports.rental',
            field: 'rental',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMonthly
        }
    ]
    static unitPropertyColumns: ReportTableColumn[] = [
        {
            header: 'unit_type',
            columnName: 'reports.unitType',
            field: 'unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            
        },
        {
            header: 'count',
            columnName: 'reports.count',
            field: 'count',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
       
        {
            header: 'min_size',
            columnName: 'reports.minSize',
            field: 'min_size',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'max_size',
            columnName: 'reports.maxSize',
            field: 'max_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'avg_size',
            columnName: 'reports.AverageSize',
            field: 'avg_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'rental',
            columnName: 'reports.rental',
            field: 'rental',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMonthly,
        },
        {
            header: 'rent_rate',
            columnName: 'reports.rental_rate',
            field: 'rent_rate',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMonthly
        }
    ]

    static unitTransactionPropertyColumns: ReportTableColumn[] = [
        {
            header: 'transaction_date',
            columnName: 'reports.transactionDate',
            field: 'transaction_date',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        // {
        //     header: 'property_name',
        //     columnName: 'reports.PROPERTYNAME',
        //     field: 'property_name',
        //     isSortable: true,
        //     border: false,
        //     isContainsHTML: false,
        //     convertNumber: false
        // },
       
        {
            header: 'unit_uid',
            columnName: 'reports.unit_uid',
            field: 'unit_uid',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter
        },
        {
            header: 'unit_type',
            columnName: 'reports.unitType',
            field: 'unit_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'value_type',
            columnName: 'reports.valueType',
            field: 'value_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]
}