<main>
    <section class="reports-container" [ngClass]="{'toggle-main': !toggleSide$, 'arabic-toggle': currentLang === 'ar'}">
        <app-report-query></app-report-query>
        <div class="reports-main">
            <app-report-data #ReportDataComponent></app-report-data> 
        </div>
    </section>
    <app-report-layout *ngIf="layerToggle"></app-report-layout>
    <app-report-filter (reset_filter)="resetFilterData()" *ngIf="filterToggle"></app-report-filter>
</main>
