<div class="container">
  <div class="header-close">
    <mat-icon (click)="closeDialog()" class="close-icon">close</mat-icon>
  </div>
  <div class="inner-container">
    <div class="banner-container">
      <div class="banner-img">
        <img src="../../../../assets/images/mobile-view-popUp-men.svg" alt="" />
      </div>
      <div class="banner-img">
        <img
          src="../../../../assets/images/mobile-view-popUp-mobile.svg"
          alt=""
        />
      </div>
    </div>
    <div class="message-container">
      <p> {{'hello_there' | translate}}
        <span>{{'title_markets' | translate}}</span> {{'consider_switching_devices' | translate}}
      </p>
    </div>
  </div>
</div>
