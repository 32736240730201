import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class UnderConstructionReportConstant {

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]
    
    
    static totalUnderConstructionColumn: ReportTableColumn[] = [
        {
            header: 'reports.noofProperties',
            field: 'no_of_properties',
        },
        {
            header: 'reports.land_area',
            field: 'leasable_area',
        },
        {
            header: 'reports.builtup_area',
            field: 'built_up_area',
            symbol: GlobalContants.keySymbolContants.squareMeter
        },
        {
            header: 'reports.no_of_car_parking',
            field: 'no_of_car_parking',
        }
    ]

    static underConstructionNeightableColumns: ReportTableColumn[] = [
        {
            header: 'reports.project_name',
            field: 'project_name',
            border: false
        },
        {
            header: 'reports.neighborhood',
            field: 'neighborhood',
            border: false
        },
        {
            header: 'reports.land_area',
            field: 'land_area',
            border: false
        },
        {
            header: 'reports.builtup_area',
            field: 'built_up_area',
            border: false
        },
        {
            header: 'reports.residential_area',
            field: 'residential_area',
            border: false
        },
        {
            header: 'reports.retail_area',
            field: 'retail_area',
            border: false
        },
        {
            header: 'reports.office_space_area',
            field: 'office_space_area',
            border: false
        },
        {
            header: 'reports.industrial_area',
            field: 'industrial_area',
            border: false
        },
        {
            header: 'reports.commercial_area',
            field: 'commercial_area',
            border: false
        },
        {
            header: 'reports.parking_area',
            field: 'parking_area',
            border: false
        },
        {
            header: 'reports.anchor_tenants',
            field: 'anchor_tenants',
            border: false
        },
        {
            header: 'reports.construction_start',
            field: 'construction_start',
            border: false
        },
        {
            header: 'reports.construction_end',
            field: 'construction_end',
            border: false
        }
    ]
}

