<div class="navBar">
    <div class="navBar-btn">
        <button (click)="backToTransaction()" [ngClass]="{ 'backToReportAr' : currentLang === 'ar'}"
            class="backToReport"><mat-icon>keyboard_arrow_left</mat-icon>
            <span>{{'reports.backToReport'|translate}}</span> </button>
    </div>

    <div class="property-detailMenu" [ngClass]="{ 'property-detailMenuAr' : currentReportView === 2}">
        <app-report-sub-menu [menuList]="overViewMenu" [isPropertyDetailPage]="true"
            (menuChangeValue)="menuChangeValue($event)" [activeTabName] ="'property'"></app-report-sub-menu>
    </div>
</div>

<div class="report-property-detail">
    <div class="report-tabsection" id="{{ retailsPropertyDetailMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === retailsPropertyDetailMenuEnum.overview}">
        <app-property-details-overview [Property_Uid]="propertyInfo?.property_uid"></app-property-details-overview>

    </div>
</div>

<!-- Rental Unit -->
<div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailsPropertyDetailMenuEnum.retailUnitSize}"
    id="{{ retailsPropertyDetailMenuEnum.retailUnitSize }}">
    <app-unit-rental (headerCount)="setCount($event, retailsPropertyDetailMenuEnum.retailUnitSize)"
        [propertyInfo]="propertyInfo"></app-unit-rental>
</div>

<!-- property Transaction Table -->
<div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailsPropertyDetailMenuEnum.propertyTransaction}"
    id="{{ retailsPropertyDetailMenuEnum.propertyTransaction }}">
    <app-property-transaction (headerCount)="setCount($event, retailsPropertyDetailMenuEnum.propertyTransaction)"
        [propertyInfo]="propertyInfo" [report_category]="'retail'"></app-property-transaction>
</div>
