import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfficeReportPropertyDetailConstant } from '../../office-property-detail/office-report-property-details.constant';
import { OfficeReportPropertyDetailService } from '../../office-property-detail/office-report-property-detail.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { environment } from 'src/environments/environment';
import { ReportService } from 'src/app/reports/services/report.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-office-unit-rental',
  templateUrl: './office-unit-rental.component.html',
  styleUrls: ['./office-unit-rental.component.scss'],
})
export class OfficeUnitRentalComponent {
  @Input() propertyInfo: any;
  @Input() report_category: any;
  @Output() headerCount = new EventEmitter();
  unitRentalLoading = false;
  unitRentalData = [];
  unitRentalCount = 0;
  unitRentalPropertyColumns =
    OfficeReportPropertyDetailConstant.unitRentalColumn;
  downloadLoading: boolean=false;
  userInfo:any;
  constructor(
    public reportService: ReportService,
    private alertService: AlertService,
    private officeReportPropertyDetailService: OfficeReportPropertyDetailService,
    private sharedService : SharedService,
    private translate: TranslateService
  ) {
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
  }
  ngOnChanges(): void {

    this.officeReportPropertyDetailService.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    // if(this.officeReportPropertyDetailService.placeCode == environment.RYDCHECK)
    //   this.unitRentalPropertyColumns =  OfficeReportPropertyDetailConstant.unitRentalColumnSaudi;
    this.getUnitRental();

  }
  getUnitRental() {
    this.unitRentalLoading = true;
    this.officeReportPropertyDetailService
      .getRetailUnit(
        this.officeReportPropertyDetailService.retailUnitPayload,
        this.report_category,
        this.propertyInfo?.property_uid
      )
      .subscribe({
        next: (res: any) => {
          if (res.status === 200) {
            this.unitRentalData = res.data;
             if (
              this.officeReportPropertyDetailService.retailUnitPayload
                .pageNumber == 0
            ) {
              this.unitRentalCount = res.count;
              this.headerCount.emit(this.unitRentalCount);
            }
          } else {
            this.unitRentalData = [];
            this.unitRentalCount = 0;
            this.headerCount.emit(this.unitRentalCount);
          }
          this.unitRentalLoading = false;
        },
        error: (error: any) => {
          this.unitRentalData = [];
          this.unitRentalCount = 0;
          this.unitRentalLoading = false;
          this.headerCount.emit(this.unitRentalCount);
        },
      });
  }


  downloadExcel(event: any, type: string) {
    this.downloadRentalReport(type);
  }
  downloadRentalReport(type: string) {
    this.downloadLoading   = true;
    this.reportService.downloadReport(type, this.userInfo?.user_id, 'office',  this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.officeReportPropertyDetailService.placeCode.toLowerCase()}&module_name=office_rental`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading  = false;
        }
      }
    )
  }

  onPageEvent(e: any) {
    if (e.previousPageIndex !== e.pageIndex) {

        this.officeReportPropertyDetailService.retailUnitPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getUnitRental();
    }

  }

  sortData(event: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
      this.officeReportPropertyDetailService.retailUnitPayload.pageNumber = 0;
      this.officeReportPropertyDetailService.retailUnitPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getUnitRental();
  }
}
