import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportService } from 'src/app/reports/services/report.service';
import { AlertService } from '../alert/alert.service';
import { SharedService } from '../../services/shared.service';
import { StoreService } from '../../services/store.service';
import { SaveFilterComponent } from '../save-filter/save-filter.component';
@Component({
  selector: 'app-save-as-filter',
  templateUrl: './save-as-filter.component.html',
  styleUrls: ['./save-as-filter.component.scss']
})
export class SaveAsFilterComponent {
  savedFilter !: FormGroup;
  user_info !: any;
  filterDropdownData: any;
  langNbr: number;
  filters !: any;
  filterLabel: any[] = [];
  appliedFilterName:string = ""
  constructor(
    public dialogRef: MatDialogRef<SaveAsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private fb: FormBuilder,
    private reportService: ReportService,
    private alertService: AlertService,
    private sharedService: SharedService,
    public storeService: StoreService,
    private dailog: MatDialog
  ) {
    this.filters = this.info.filterData,
    this.filterDropdownData = this.info.filterDropdownData;
    this.langNbr = this.sharedService.getCurrentLangID();
  }
  ngOnInit() {
    let filter:any = this.storeService.getSessionData('filter');
    this.appliedFilterName = filter?.name;
    this.user_info = this.sharedService.getUserInfo();
  }
  getLabel(key: string, value: string) {
    let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
    if (filter && filter.value_list?.length) {
      let f_value_obj = filter.value_list.find((e: any) => e.val == value);
      if (f_value_obj) {
        return f_value_obj.label;
      }
    }
    return {[this.langNbr]: value} //this.sharedService.removeUnderscores(value);
  }
  /**
   * This function is used to update the saved filter.
   */
  onSaveClick() {
    let savedFilter = this.storeService.getSessionData("filter")
    let datas: { [key: string]: string } = { ...this.filters }
    let polygon: any;
    if (datas['polygon']) {
      polygon = datas['polygon'];
    }
    for (let key in datas) {
      if (['rid', 'srid', 'gid', 'oid', 'polygon'].includes(key)) {
        delete datas[key];
      } else {
        let temp = { ...datas }
        temp['label'] = this.getLabel(key, datas[key])
      }
    }
    /**
     * Send label object in api
     */
    for (const [key, value] of Object.entries(datas)) {
      if (key !== 'zoom' && key !== 'centroid') {
        let data = value?.includes(',') ? value.split(',') : [value]

        const parameterMap: any = {};
        data.forEach((item: any) => {
          if (parameterMap[key]) {
            parameterMap[key].data.push({ value: item, label: this.getLabel(key, item) });
          } else {
            let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
            parameterMap[key] = { parameter_name: filter.label, data: [{ value: item, label: this.getLabel(key, item) }] };
            this.filterLabel.push(parameterMap[key]);
          }
        });

      }
    }
    let payload = {
      "user_id": this.user_info.user_id,
      "filter_type": "filter",
      "filter_data": {
        "filter": datas || null,
        "catchment": polygon ? JSON.parse(polygon) : null,
        "filter_label": this.filterLabel
      }
    }
    this.reportService.updateSavedFilter(payload, savedFilter).subscribe({
      next: (res: any) => {
        if (res && res?.status == 200) {
          this.alertService.success(res.message)
          this.reportService.getSavedFilter()
          this.dialogRef.close('update');
        } else {
          this.alertService.warn(res.message)
        }
      },
      error: (err: any) => {
        this.alertService.error(err.message)
      }
    });

  }

  onCancelClick(val:string) {
    this.dialogRef.close(val)
  }
}
