import { Component, Input, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/common/services/shared.service';
import { ProjectConstant } from './report-project-details.constants';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ReportsViewEnum } from 'src/app/reports/models/enums/reports.enum';
import { ReportService } from 'src/app/reports/services/report.service';
import { projectMenuEnum } from './report-project-menu-enum';
import { ReportProjectDetailsService } from './report-project-details.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-project-details',
  templateUrl: './report-project-details.component.html',
  styleUrls: ['./report-project-details.component.scss']
})
export class ReportProjectDetailsComponent {

  @Input() projectInfo: any = {};
  overViewMenu = ProjectConstant.overViewMenu;
  menuEnum = projectMenuEnum;
  currentScroll: any;
  activeTab: any = '';

  currentLang: string = '';
  languageSub$: Subscription = new Subscription();

  currentReportView: number = ReportsViewEnum.mapView;
  currentReportView$: Subscription = new Subscription();

  isFirstTime = true;
  landParcelLoading: boolean = false;
  private destroy$ = new Subject<void>();

  landParceldata: any[] = [];
  landParcelCount: number = 0;
  landParcelColumns = ProjectConstant.landParcelColumns;

  propertyHousesLoading: boolean = false;
  propertyHousesdata: any[] = [];
  propertyHousesCount: number = 0;
  propertyHousesColumns = ProjectConstant.propertyHousesColumns;

  downloadLoading: any = {
    a: false,
    b: false,
    c: false
  };

  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';

  constructor(
    private sharedService: SharedService,
    public reportService: ReportService,
    private projectService: ReportProjectDetailsService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {
    this.sharedService.language$.subscribe(lang => {
      // this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit(): void {
    this.setLang();
    this.getReportView();
    this.getReports();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scrollPoint(String(this.menuEnum.overview));
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollPoint(String(this.menuEnum.overview));
    }, 0);
  }

  getReports() {
    this.getLandParcel();
    this.getPropertyHouses();
  }

  /**
   * Land Parcel Table API
   */
  getLandParcel() {
    this.landParcelLoading = true;
    this.projectService.getLandParcel(this.projectService.projDetailLandParcel)
      .pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: (res: any) => {
            this.landParcelLoading = false;
            if (res.status === 200) {
              this.landParceldata = res.data;
              if (this.projectService.projDetailLandParcel.pageNumber == 0) {
                this.landParcelCount = res.count;
                this.overViewMenu.map((e: any) => {
                  if (e.name == this.menuEnum.landParcelTable) {
                    e['count'] = this.reportService.formatInternationNum(this.landParcelCount);
                  }
                })
              }
            } else {
              this.landParceldata = [];
              this.landParcelCount = 0;
              if (this.projectService.projDetailLandParcel.pageNumber == 0) {
                this.overViewMenu.map((e: any) => {
                  if (e.name == this.menuEnum.landParcelTable) {
                    e['count'] = 0;
                  }
                })
              }
            }
          },
          error: (error: any) => {
            this.landParceldata = [];
            this.landParcelCount = 0;
            this.landParcelLoading = false;
            if (this.projectService.projDetailLandParcel.pageNumber == 0) {
              this.overViewMenu.map((e: any) => {
                if (e.name == this.menuEnum.landParcelTable) {
                  e['count'] = 0;
                }
              })
            }
          }

        }
      )
  }

  /**
   * Property Details - Houses API
   */
  getPropertyHouses() {
    this.propertyHousesLoading = true;
    this.projectService.getPropertyHouses(this.projectService.projDetailHouses)
      .pipe(takeUntil(this.destroy$)).subscribe(
        {
          next: (res: any) => {
            this.propertyHousesLoading = false;
            if (res.status === 200) {
              this.propertyHousesdata = res.data;
              if (this.projectService.projDetailHouses.pageNumber == 0) {
                this.propertyHousesCount = res.count;
                this.overViewMenu.map((e: any) => {
                  if (e.name == this.menuEnum.propertyDetailsHouses) {
                    e['count'] = this.reportService.formatInternationNum(this.propertyHousesCount);
                  }
                })
              }
            } else {
              this.propertyHousesdata = [];
              this.propertyHousesCount = 0;
              if (this.projectService.projDetailHouses.pageNumber == 0) {
                this.overViewMenu.map((e: any) => {
                  if (e.name == this.menuEnum.propertyDetailsHouses) {
                    e['count'] = 0;
                  }
                })
              }
            }
          },
          error: (error: any) => {
            this.propertyHousesdata = [];
            this.propertyHousesCount = 0;
            this.propertyHousesLoading = false;
            if (this.projectService.projDetailHouses.pageNumber == 0) {
              this.overViewMenu.map((e: any) => {
                if (e.name == this.menuEnum.propertyDetailsHouses) {
                  e['count'] = 0;
                }
              })
            }
          }

        }
      )
  }

  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
      this.menuChangeValue(ProjectConstant.overViewMenu[0])
    })
  }

  getReportView() {
    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      this.currentReportView = view;
    });
  }

  backToTransaction() {
    this.reportService.isDetailPageOpen = false;
    this.reportService.transactionPropertyDetails.next(false);
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    this.activeTab = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  onPageEvent(e: any, report: string) {
    if (report === 'landParcel') {
      if (e.previousPageIndex !== e.pageIndex) {
        this.projectService.projDetailLandParcel.pageNumber = (e.pageSize * e.pageIndex);
      }
      this.getLandParcel();
    } else if (report === 'propertyHouses') {
      if (e.previousPageIndex !== e.pageIndex) {
        this.projectService.projDetailHouses.pageNumber = (e.pageSize * e.pageIndex);
      }
      this.getPropertyHouses();
    }
  }
  sortData(event: any, report: string) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === 'landParcel') {
      this.projectService.projDetailLandParcel.pageNumber = 0;
      this.projectService.projDetailLandParcel.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
      this.getLandParcel();
    } else if (report === 'propertyHouses') {
      this.projectService.projDetailHouses.pageNumber = 0;
      this.projectService.projDetailHouses.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
      this.getPropertyHouses();
    }
  }
  downloadExcel(event: any, type: string, key: any) {
    this.downloadReport(type, 'transaction', key);
  }
  downloadReport(type: string, module_name: string, key: any) {
    this.downloadLoading[key] = true;
    this.projectService.downloadProjectDetail(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.projectService.downloadExcelUrl()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=${module_name}`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));
          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }

  ngOnDestroy() {
    this.destroy$.next(); // Emit signal to unsubscribe
    this.destroy$.complete(); // Clean up the Subject
  }
}
