import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-box',
  templateUrl: './confirm-dialog-box.component.html',
  styleUrls: ['./confirm-dialog-box.component.scss']
})
export class ConfirmDialogBoxComponent {

  
  message: string = '';
  class='';
  type:string='';
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.message = this.info.message;
    this.class = this.info.class
    this.type= this.info.type?this.info.type:'';
   }

  confirmDialogBox(check:boolean) {
    this.dialogRef.close(check);
  }

}
