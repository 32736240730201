import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { HouseRentalMenuEnum } from "./report-house-rental.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class HouseVillasReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: HouseRentalMenuEnum.overview
        },
        {
            label: 'reports.average_rent_unit_wise',
            name: HouseRentalMenuEnum.averageRentUnitWise,
            count: null
        }
    ]

    static averageRentUnitWiseColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'property_type',
            columnName: 'reports.villaType',
            field: 'property_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'project_name',
            columnName: 'reports.projectAndCompound',
            field: 'project_name',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_type',
            columnName: 'reports.unitType',
            field: 'unit_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'rent',
            columnName: 'reports.AverageRental',
            field: 'rent',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        },
        {
            header: 'build_up_area',
            columnName: 'reports.builtup_area',
            field: 'build_up_area',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
       
    ]

    

    
}
