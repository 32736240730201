export const environment = {
    production: false,
    baseEndPoint: 'https://miapi.dev10.in/v1/',
    miBaseUrl: 'https://miapi.dev10.in/v1/',
    miUrl: 'https://mireportsapi.dev10.in/v1/',
    // miUrl: 'http://localhost:2405/v1/',
    miApp: 'https://mi.dev10.in/',
    umsUrl: "https://umsapinode.dev10.in/",
    e2Url: "https://e2.dev10.in/",
    user_token: 'TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=',
    umsApp_id: 12,
    geoestater: 'https://ged.gisworld.in/',
    // user_token: 'TlRBPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgwNTg=',
    // geoestater: 'https://geoestater.gisworld.in/',
    api_key: '00cc456ac4963d000af6c114dcf89825',
    recaptchaKey: '6Lc4aOIjAAAAAJG0LE_Oyd8Nn3pUEC_5ZIpwzeng',
    max_year_filter: 10,
    hotjarSiteId:5036430, //Hotjar June 2024 (Owner Md Ishtiyaque Ansari)
    googleAnalyticsId:'G-D1M2RZSLYZ', //GA June 2024 (Owner Md Ishtiyaque Ansari)
    is_captcha: false,
    is_production: false,
    jawgLayer:'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=O4lo58fpF3zdhkzPQMtrEmKtTZmV4FOlArfe2vlryRpMCoOEfbqJv4ui9ypAxexs',
    BHRCHECK:'BHR',
    RYDCHECK:'RYD',
    encrypt_key:'QhdfV7@1764sdXA',
    getLayer_from_clickHouse:true,
    getLayer_from_clickHousePBF:false
}
