import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class PropertyProfilingReportConstant {

    static reports = {
        share_Of_Parking: 'share_Of_Parking',
        neighborhoodAndGradewiseParkingratio: 'neighborhoodAndGradewiseParkingratio',
        neighborhoodsAndGradeWiseUnitFurnishingStatus: 'neighborhoodsAndGradeWiseUnitFurnishingStatus',
        shareofamenities: 'shareofamenities'
    }

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]

    static totalColumn: ReportTableColumn[] = [
        {
            header: 'reports.totalProperties',
            field: 'total_property',
        },
        {
            header: 'reports.totalUnits',
            field: 'total_unit',
        },
        {
            header: 'reports.avgNumberofUnits',
            field: 'average_number_unit',

        },
        {
            header: 'reports.totalNumberofParking',
            field: 'total_parking',
        },
        {
            header: 'reports.averageUnitArea',
            field: 'average_land_area',
            symbol: GlobalContants.keySymbolContants.squareMeterUnit
        },

        {
            header: 'reports.parkingRatioPerUnit',
            field: 'parking_ratio_unit',
        }
    ]

    static typeGradePropertyProfilingReportTableColumn: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.propertyGrade',
            field: 'property_grade',
            border: false
        },
        {
            header: 'reports.parkingRatio',
            field: 'parking_ratio',
            border: true
        }
    ]

    static amenitiesPropertyProfiling: ReportTableColumn[] = [
        {
            header: 'reports.amenities',
            field: 'amenities',
            border: false
        },
        {
            header: 'reports.propertyGrade',
            field: 'property_grade',
            border: false
        },
        {
            header: 'reports.selectedProperties',
            field: 'total_properties',
            border: true
        },
        {
            header: 'reports.countOfPropertiesWithAmenities',
            field: 'amenities_property',
            border: true
        }, {
            header: 'reports.ofSelectedProperties',
            field: 'value',
            border: true
        }
    ]

    static gradeFurnishedPropertyProfilingReport: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.propertyGrade',
            field: 'property_grade',
            border: false
        },
        {
            header: 'reports.furnished',
            field: 'furnished',
            border: true
        },
        {
            header: 'reports.semiFurnished',
            field: 'semifurnished',
            border: true
        },
        {
            header: 'reports.unfurnished',
            field: 'unfurnished',
            border: true
        },
        {
            header: 'reports.grandTotal',
            field: 'grandTotal',
            border: true
        }
    ]
}
