
<div id="globalSearchBar" class="globalSearchBar">
    <mat-icon class="search" [class.ar-search-icon]="currentLang == 'ar'">search</mat-icon>
    <input type="text"
        [formControl]="searchControl"
        [placeholder]="placeholder | translate"
        class="input-search"
        (keydown)="disableInput($event)"
        (focus)="onFocus()"
        [matTooltip]="tooltip_data | translate"
        [matTooltipPosition]="'below'"
        [ngStyle]="{'cursor': tooltip_data ? 'default' :'auto'}"
    >
    <ng-container *ngIf="closeButton">
      <mat-icon class="close" (click) = closeEvent()>close</mat-icon>
    </ng-container>

    <ng-container *ngIf="catchmentCloseBtn">
      <i class="closeCatchment-icon" (click) = closeEvent()></i>
    </ng-container>

</div>
