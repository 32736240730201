import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { HouseVillasReportConstant } from './report-house-rental.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportHouseRentalService } from './report-house-rental.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { HouseRentalMenuEnum } from './report-house-rental.enum';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-house-rental',
  templateUrl: './report-house-rental.component.html',
  styleUrls: ['./report-house-rental.component.scss']
})
export class ReportHouseRentalComponent implements OnInit, OnChanges {
  RYDCHECK=environment.RYDCHECK;
  @Input() params: any;

  chartView = ReportsChartEnum;
  
  overViewMenu = HouseVillasReportConstant.overViewMenu;
  currentScroll: any = '';
  UnitTypeEnum=UnitTypeEnum;
  averageRentUnitWiseColumns = HouseVillasReportConstant.averageRentUnitWiseColumns;

  houseRentalMenuEnum = HouseRentalMenuEnum;
  placeName='';
  userInfo: any = 0;
  placeCode: string = '';
  sqm_unit: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  isFirstTime = true;
  overViewLoading: boolean = false;
  overViewSummary: any = null;

  averageRentUnitWisedata: any = [];
  averageRentUnitWiseCount: number = 0;
  averageRentUnitWiseLoading: boolean = false;

  houserentalChartLoading: boolean = false;
  houserentalChart: any = [];

  resetPaginator: boolean = false;
  currentPageIndex: number = 0;
  downloadLoading = false;

  constructor(
    public sharedService: SharedService,
    private reportHouseRentalService: ReportHouseRentalService,
    private alertService: AlertService,
    public translate: TranslateService,
    public reportService: ReportService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
    //Overview menu text update yearly & montly case
    if(sharedService.priceCalculation$() === this.sharedService.priceYearly){
      this.overViewMenu[1]['label'] = 'reports.annual_average_rent_unit_wise';
    }
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  ngOnInit() {
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
    this.getReports();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName =  this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.reportHouseRentalService.averageRentUnitWisePayload.pageNumber = 0;
      this.propertyInfo = null;
      this.getReports();
    }
  }

  selectedYear(data: any){
    this.reportHouseRentalService.averageRentUnitWisePayload.pageNumber = 0;
    this.reportHouseRentalService.averageRentUnitWisePayload.year = data.year;
    this.resetPaginator = true;
    this.getAverageRentUnitWise();
  }

  getReports() {
    this.getOverviewSummary();
    this.getAverageRentUnitWise();
  }
  
  getOverviewSummary() {
    this.overViewLoading = true;
    this.reportHouseRentalService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.reportHouseRentalService.formatOverViewSummary(res.data[0]);
            this.houserentalChart = [];
              res.graphData.map((data: any) => {
                let obj: any = {};
                if (data?.count > 0) {
                  obj = {
                    category: data.year.toString(),
                    value: data.count
                  }
                  this.houserentalChart.push(obj);
                }

               

              })

           
          } else {
            this.overViewSummary = null;
          }
          this.overViewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.overViewLoading = false;
        }
      }
    )
  }

  getAverageRentUnitWise(year = null) {
    this.averageRentUnitWiseLoading = true;
    this.reportHouseRentalService.getAverageRentUnitWise(this.reportHouseRentalService.averageRentUnitWisePayload, year).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.averageRentUnitWisedata = res.data;
            if(this.reportHouseRentalService.averageRentUnitWisePayload.pageNumber == 0){
            this.averageRentUnitWiseCount = res.count;
            this.overViewMenu.map((e:any)=>{
              if(e.name == HouseRentalMenuEnum.averageRentUnitWise){
                e['count'] = this.reportService.formatInternationNum(this.averageRentUnitWiseCount);
              }
            })
           }
            
          } else {
            this.averageRentUnitWisedata = [];
            this.averageRentUnitWiseCount = 0;
            this.overViewMenu.map((e:any)=>{
              if(e.name == HouseRentalMenuEnum.averageRentUnitWise){
                e['count'] = 0;
              }
            })
          }
          this.averageRentUnitWiseLoading = false;
        },
        error:()=>{
          this.averageRentUnitWiseLoading = false;
          this.overViewMenu.map((e:any)=>{
            if(e.name == HouseRentalMenuEnum.averageRentUnitWise){
              e['count'] = 0;
            }
          })
        }
        
      }
    )
  }

  downloadExcel(event: any, type: string) {
    this.downloadRentalReport(type);
  }

  downloadRentalReport(type: string) {
    this.downloadLoading = true;
    this.reportHouseRentalService.downloadHouseRentalReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=rental`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading = false;
        }
      }
    )
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.houseRentalMenuEnum.averageRentUnitWise) {
        this.reportHouseRentalService.averageRentUnitWisePayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getAverageRentUnitWise();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.houseRentalMenuEnum.averageRentUnitWise) {
      this.reportHouseRentalService.averageRentUnitWisePayload.pageNumber = 0;
      this.reportHouseRentalService.averageRentUnitWisePayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getAverageRentUnitWise();
    } 
  }
}
