<div class="report-container house-rental">

    <ng-container>

        <app-report-sub-menu [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
        <ng-container *ngIf="overViewLoading">
            <app-skeleton-loader [reportType]="chartView.overView" [height]="'150px'"></app-skeleton-loader>
        </ng-container>
        <div class="report-tabsection" id="{{ houseRentalMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === houseRentalMenuEnum.overview}">
            <div class="report-overview" *ngIf="!overViewLoading">
                <app-overview-card
                [card_title]="sharedService.priceCalculation$() === sharedService.priceYearly ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'" 
                [total_current]="overViewSummary?.avg_rent"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.prev_avg_rent"
                [total_percentage]="overViewSummary?.totalaverage_rent"
                [uniType]="UnitTypeEnum.currencywithsqm"></app-overview-card>
                
                <app-overview-card
                [card_title]="'reports.highest_valued_rent_rate'" 
                [total_current]="overViewSummary?.highest_rent"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.prev_highest_rent"
                [total_percentage]="overViewSummary?.totalhighest_rent"
                [neighborhood]="overViewSummary?.neighborhood"
                [uniType]="UnitTypeEnum.currencywithsqm"></app-overview-card>

                <!-- <div class="overview">
                    <div class="overview-data">
                        <h2 *ngIf="placeCode  != RYDCHECK">{{"reports.averageRentRate" | translate}}</h2>
                        <h2 *ngIf="placeCode  == RYDCHECK">{{"reports.AnnuallyAverageRent" | translate}}</h2>
                        <h1>
                            <ng-container *ngIf="overViewSummary?.avg_rent">
                                {{overViewSummary?.avg_rent | number:'1.0-1'}} <sup>{{CurrencyCode + '/'+(sqm_unit | translate)}}</sup>
                            </ng-container>
                            <ng-container *ngIf="!overViewSummary?.avg_rent">
                                -
                            </ng-container>
                        </h1>
                        <div class="untill">

                            <span class="directionLtr">{{'reports.untill' | translate}} {{ overViewSummary?.untillDate }} </span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}} {{ overViewSummary?.prev_avg_rent ? (overViewSummary?.prev_avg_rent | number:'1.0-0') +' '+CurrencyCode + '/'+(sqm_unit | translate): '-'}}
                            </span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.totalaverage_rent && overViewSummary?.totalaverage_rent == 0">{{
                            overViewSummary?.totalaverage_rent | number:'1.0-0'}} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.totalaverage_rent && overViewSummary?.totalaverage_rent > 0">+ {{
                                overViewSummary?.totalaverage_rent | number:'1.0-0'}} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.totalaverage_rent < 0">{{
                                overViewSummary?.totalaverage_rent | number:'1.0-0'}} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.totalaverage_rent > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.totalaverage_rent < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="overview">
                    <div class="overview-data">
                        <h2>{{'reports.highest_valued_rent_rate' | translate}}</h2>
                        <h1>
                            <ng-container *ngIf="overViewSummary?.highest_rent">
                                {{overViewSummary?.highest_rent | number:'1.0-1'}} <sup>{{CurrencyCode + '/'+(sqm_unit | translate)}}</sup>
                            </ng-container>
                            <ng-container *ngIf="!overViewSummary?.highest_rent">
                                -
                            </ng-container>
                        </h1>

                        <div class="untill">
                            <span class="neight" *ngIf="overViewSummary?.neighborhood">In {{ overViewSummary?.neighborhood }}</span>
                            <span class="directionLtr">{{'reports.untill' | translate}} {{ overViewSummary?.untillDate }} </span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}} {{ overViewSummary?.prev_highest_rent ? (overViewSummary?.prev_highest_rent | number:'1.0-0')+' '+CurrencyCode + '/'+(sqm_unit | translate):'-'
                                }}
                            </span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.totalhighest_rent && overViewSummary?.totalhighest_rent == 0">{{
                            overViewSummary?.totalhighest_rent | number:'1.0-0'}} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.totalhighest_rent && overViewSummary?.totalhighest_rent > 0">+ {{
                                overViewSummary?.totalhighest_rent | number:'1.0-0'}} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.totalhighest_rent < 0">{{
                                overViewSummary?.totalhighest_rent | number:'1.0-0'}} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.totalhighest_rent > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.totalhighest_rent < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>

        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ? ('reports.annual_rental_price_in' | translate) : ('reports.rental_price_in' | translate)}} {{(placeName | lowercase) | translate}} ({{CurrencyCode | translate}}/{{'reports.sqm_unit' | translate}})</h3>
                </div>

            </div>
            <ng-container  *ngIf="houserentalChart.length > 0; else chartNoRecords">
                <ng-container *ngIf="houserentalChartLoading">
                    <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!houserentalChartLoading">
                    <app-report-charts [data]="houserentalChart" [chartType]="chartView.varianceIndicatorsChart"
                        [name]="'houserentalChart'"></app-report-charts>

                </ng-container>
            </ng-container>
            <ng-template #chartNoRecords>
                <div class="data_unavail">
                    {{"reports.no_record_found" | translate}}
                </div>
            </ng-template>
        </div>


        <!-- Average Rent Unit Wise -->
        <div class="report-tabsection"
            [ngClass]="{ 'scroll': currentScroll === houseRentalMenuEnum.averageRentUnitWise}"
            id="{{ houseRentalMenuEnum.averageRentUnitWise }}">

            <app-report-year-dropdown [reportType]="'rental'"
                (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ? ('reports.annual_average_rent_unit_wise' | translate) : ('reports.average_rent_unit_wise' | translate)}}</h3>
                        <span class="totalTransaction">({{ averageRentUnitWiseCount ? reportService.formatInternationNum(averageRentUnitWiseCount) : averageRentUnitWiseCount}} {{"reports.totalUnits" | translate}})</span>
                    </div>

                </div>
                <!-- <ng-container *ngIf="averageRentUnitWiseLoading">
                    <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
                </ng-container> -->
                <app-report-datatable
                    [showLoader]="!averageRentUnitWiseLoading"
                    [tableColumns]="averageRentUnitWiseColumns"
                    [dataLength]="averageRentUnitWiseCount"
                    [tableData]="averageRentUnitWisedata"
                    (pageEvent)="onPageEvent($event, houseRentalMenuEnum.averageRentUnitWise)"
                    (sort)="sortData($event, houseRentalMenuEnum.averageRentUnitWise)"
                    [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'rental')" [downloadLoading]="downloadLoading"
                    [showEstaterText]="true"
                    [resetPaginator]="resetPaginator"
                    [currentPageIndex]="currentPageIndex"
                >
                </app-report-datatable>


            </div>
        </div>


    </ng-container>

</div>
