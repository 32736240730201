import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { StoreService } from 'src/app/common/services/store.service';
import { LoadCatchmentComponent } from '../load-catchment/load-catchment.component';
import { ConfirmDialogBoxComponent } from 'src/app/common/components/confirm-dialog-box/confirm-dialog-box.component';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { SaveFilterComponent } from 'src/app/common/components/save-filter/save-filter.component';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { CatchmentModelComponent } from './catchment-model/catchment-model.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-draw-catchment',
  templateUrl: './draw-catchment.component.html',
  styleUrls: ['./draw-catchment.component.scss']
})
export class DrawCatchmentComponent implements OnInit, OnChanges {

  @Input() drawnPolygons: any;
  @Input() filterList: any;
  @Input() polygon_chip: any;
  @Input() polygon_create: boolean = false;

  @Output() polygonRemove = new EventEmitter();
  @Output() catchmentExpandCol = new EventEmitter();
  @Output() resetCatchmentConfirm = new EventEmitter();

  catchmentExpandList: boolean = false;
  resetCatchment: boolean = false;
  catchmentExistingSelected: boolean = false;
  queryParams: any = null;
  filterDropdownData: any = [];
  langNbr: number;
  queryParamsFirst: any;
  filterLabel: any[] = [];
  selectedCatchmentData: any = null;
  revertCatchment: boolean = false;
  loadCatchmentPopup: boolean = false;
  current_lang: any = null
  loader: boolean=false;
  // @ViewChild('existingCatchmentMenuTrigger') existingCatchmentMenuTrigger!: MatMenuTrigger;
  constructor(
    private dailog: MatDialog,
    public storeService: StoreService,
    private translate: TranslateService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private mapService: ReportMapService,
    public reportService: ReportService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.langNbr = this.sharedService.getCurrentLangID();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['filterList']) {
      let dropdowns = changes?.['filterList'].currentValue;
      if (dropdowns.length > 0) {
        this.filterDropdownData = dropdowns;
      }
    }
    if(!this.polygon_create){
      this.catchmentExpandList = false;
    }
  }

  ngOnInit(): void {
    this.sharedService.language$.subscribe((lang) => {
      this.current_lang = lang;
      
    });
    this.getActivateRoute();
  }

  getActivateRoute() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;
      let currentParams = { ...params };
      this.catchmentExistingSelected = false;
      let savefilter: any = this.storeService.getSessionData('filter');
      this.queryParamsFirst = {
        "oid": this.queryParams['oid'],
        "gid": this.queryParams['gid'],
        "rid": this.queryParams['rid']
      }
      if(savefilter){
        this.queryParamsFirst = {...this.queryParamsFirst, ...savefilter.data.filter }
      }

      if (this.storeService.getSessionData("queryParamsFirst")) {
        this.queryParamsFirst  = this.storeService.getSessionData("queryParamsFirst");
      }
      if (savefilter) {
        if (!this.sharedService.compareObject(this.queryParamsFirst, currentParams)) {
          this.catchmentExistingSelected = true;
        } else {
          this.catchmentExistingSelected = false;
        }
        if (this.queryParamsFirst && ((this.queryParamsFirst['rid'] !== params['rid']) || (this.queryParamsFirst['srid'] !== params['srid']))) {
          this.catchmentExistingSelected = false
        }
      } else {
        this.catchmentExistingSelected = false;
      }
    
    });

  }

  loadSaved() {
    if(this.getSaveFilter() && this.catchmentExistingSelected){
      this.dailog.open(CatchmentModelComponent, {
        disableClose: true,
        panelClass: 'load-catchment',
        data :{
          type:'load'
        }
      }).afterClosed().subscribe((res: any) => {
        if(res){
            this.reloadExistingCatchement();
        }
      })
    } else {
      this.loadExistingCatchment();
    }
    
  }

  loadExistingCatchment(){
    this.dailog.open(LoadCatchmentComponent, {
      disableClose: true,
      panelClass: 'load-catchment'
    })
  }

  resetCatchmentOpen(){
    this.dailog.open(CatchmentModelComponent, {
      disableClose: true,
      panelClass: 'load-catchment',
      data :{
        type:'reset'
      }
    }).afterClosed().subscribe((res: any) => {
      if(res){
          this.resetCatchmentLayer();
      }
    })

    
  }

  revertCatchmentOpen(){
    this.dailog.open(CatchmentModelComponent, {
      disableClose: true,
      panelClass: 'load-catchment',
      data :{
        type:'revert'
      }
    }).afterClosed().subscribe((res: any) => {
      if(res){
        this.revertLayer()
      }
    })

    
  }

  catchmentExpand() {
    this.catchmentExpandList = !this.catchmentExpandList;
    this.catchmentExpandCol.emit(this.catchmentExpandList)
  }

  polygonWKTRemove(polygon: any) {
    const _data = {
      parent_data: null,
      message: this.translate.instant('map.do_you_want_to_remove_the_catchment'),
      inputbox: true
    };
    this.dailog.open(ConfirmDialogBoxComponent, {
      width: '400px',
      height: 'auto',
      data: _data,
      disableClose: true
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        this.polygonRemove.emit(polygon)
      }
    });
  }

  savedFilterName(event: any, existingCatchemnt: boolean = false) {
    if (this.reportService.getLockReport()) {
      return;
    }
    let qParams = { ...this.queryParams };
    this.mapService.zoom.subscribe(res => {
      qParams['zoom'] = res;
    })
    if (this.mapService.getCentroid()) qParams['centroid'] = this.mapService.getCentroid();
    let _data = {
      filterData: qParams,
      filterDropdownData: this.filterDropdownData
    };
    if (existingCatchemnt) {
      this.saveExicitingCatchment(_data);
    } else {
      this.dailog.open(SaveFilterComponent, {
        data: _data
      }).afterClosed().subscribe(res => { })
    }
  }

  getSaveFilter() {
    let savefilter: any = this.storeService.getSessionData('filter');
    if (savefilter) {
      // this.selectedSaveFilter = savefilter?.data?.filter;
      return savefilter?.name || '';
    }
    return '';
  }

  saveExicitingCatchment(data: any) {
    let savedFilter = this.storeService.getSessionData("filter")
    let datas: { [key: string]: string } = { ...data.filterData }
    let polygon: any;
    if (datas['polygon']) {
      polygon = datas['polygon'];
    }
    for (let key in datas) {
      if (['rid', 'srid', 'gid', 'oid', 'polygon'].includes(key)) {
        delete datas[key];
      } else {
        let temp = { ...datas }
        temp['label'] = this.getLabel(key, datas[key])
      }
    }
    /**
     * Send label object in api
     */
    for (const [key, value] of Object.entries(datas)) {
      if (key !== 'zoom' && key !== 'centroid') {
        let data = value?.includes(',') ? value.split(',') : [value]

        const parameterMap: any = {};
        data.forEach((item: any) => {
          if (parameterMap[key]) {
            parameterMap[key].data.push({ value: item, label: this.getLabel(key, item) });
          } else {
            let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
            parameterMap[key] = { parameter_name: filter.label, data: [{ value: item, label: this.getLabel(key, item) }] };
            this.filterLabel.push(parameterMap[key]);
          }
        });

      }
    }
    const user: any = this.sharedService.getUserInfo();
    // let payload = {
    //   "user_id": user?.user_id,
    //   "filter_type": "filter",
    //   "filter_data": {
    //     "filter": datas || null,
    //     "catchment": polygon ? JSON.parse(polygon) : null,
    //     "filter_label": this.filterLabel
    //   }
    // }
    let payload = {
      "user_id": user?.user_id,
      "filter_type": "filter",
      "filter_data": {
        "catchment": polygon ? JSON.parse(polygon) : null,
      }
    }
    this.queryParamsFirst = this.queryParams;
    this.loader=true;

   this.storeService.addSessionData("queryParamsFirst", this.queryParams)
    
    this.reportService.updateSavedFilter(payload, savedFilter).subscribe({
      next: (res: any) => {
        this.loader=false;
        if (res && res?.status == 200) {
          this.catchmentExistingSelected = false;
          this.alertService.success(res.message)
         // this.reportService.getSavedFilter()
        } else {
          this.alertService.warn(res.message)
        }
      },
      error: (err: any) => {
        this.loader=false;
        this.alertService.error(err.message)
      }
    });

  }

  getLabel(key: string, value: string) {
    let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
    if (filter && filter.value_list?.length) {
      let f_value_obj = filter.value_list.find((e: any) => e.val == value);
      if (f_value_obj) {
        return f_value_obj.label;
      }
    }
    return {[this.langNbr]: value} //this.sharedService.removeUnderscores(value);
  }

  resetCatchmentLayer() {
    this.resetCatchment = false;
    this.resetCatchmentConfirm.emit(true)
  }

  

  revertLayer(event: any = '') {
    this.revertCatchment = false;
    this.router.navigate([`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`], {
      queryParams: this.queryParamsFirst
    });
    
  }

  reloadExistingCatchement(){
    this.revertLayer()
    this.loadExistingCatchment();
  }


}
