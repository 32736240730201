

<app-reports-container
    [tableColumn]="totalOccupancyLeaseReportColumn"
    [dataValue]="totalOccupancyLeaseReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [dataValue]="investmentPropertyReport"
    [reportType]="chartView.barChart"
    [chartName]="'investmentPropertyReport'"
    [loading]="investmentPropertyLoading"
    [title]="'reports.investment_propertyreport'"
></app-reports-container>



<div class="report-list">
    <h4>{{"reports.year_Wise_Occupancy_Lease_Rate" | translate}}</h4>
    <ng-container *ngIf="yearwiseOccupancyLeaseReport.length > 0">

        <app-report-chart-view [legend]="['reports.lease_rate' | translate,'reports.occupancy' | translate]"
            [data]="yearwiseOccupancyLeaseReport" [chartType]="chartView.barLineGroupChart"></app-report-chart-view>
    </ng-container>
    <div class="noRecord" *ngIf="yearwiseOccupancyLeaseReport.length === 0">
        <span>{{"reports.no_record_found" | translate}}</span>
    </div>
</div>

<app-reports-container
    [tableColumn]="occupancyLeaseTableColumn"
    [dataValue]="occupancyLeaseData"
    [isDownloadExcel]="true"
    [loading]="occupancyLeaseLoading"
    [reportName]="'reports.averageLeaseRate_unitTypeWise'"
    [title]="'reports.averageLeaseRate_unitTypeWise'"
    [expandable]="true"
></app-reports-container>

<ng-container *ngIf="placeCode != RYDCHECK">
    <app-reports-container
    [tableColumn]="occupancyTableColumn"
    [dataValue]="occupancyData"
    [isDownloadExcel]="true"
    [loading]="occupancyLoading"
    [reportName]="'reports.occupancy_unitTypeWise'"
    [title]="'reports.occupancy_unitTypeWise'"
    [expandable]="true"
></app-reports-container>
</ng-container>
<div *ngIf="placeCode == RYDCHECK">
    <div class="report-list">
        <h4>{{ 'reports.occupancy_unitTypeWise' | translate}}</h4>
        <p class="text-center"> {{'reports.coming_soon' | translate}}</p>
    </div>
</div>

