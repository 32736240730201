import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactSalesComponent } from 'src/app/common/components/contact-sales/contact-sales.component';
import { ReportService } from 'src/app/reports/services/report.service';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import * as pbi from 'powerbi-client';

@Component({
  selector: 'app-report-power-bi-full-with-map',
  templateUrl: './report-power-bi-full-with-map.component.html',
  styleUrls: ['./report-power-bi-full-with-map.component.scss']
})
export class ReportPowerBiFullWithMapComponent {
  @ViewChild('reportContainer', { static: false }) reportContainer!: ElementRef;
  //Power Bi
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        pageNavigation: { visible: false },
        filters: { visible: false, expanded: false },
        bookmarks: { visible: false },
        visualizations: { visible: true, expanded: false },
        fields: { visible: true, expanded: false },
        selection: { visible: false },
        syncSlicers: { visible: false },
      }
    },
  };
  @Input() params: any;
  isLoader: boolean = true;
  powerBiReport: any;
  visuals: any[] = [];
  visualsGrFilter: any[] = [];
  visualLoader: boolean = false;
  PowerBIData: any = {};
  constructor(
    public reportService: ReportService,
    private dailog: MatDialog,
  ) { }
  ngOnInit(): void {
    if(!this.reportService.getLockReport() && this.reportService?.getCurrentReport()?.powerbi_data){
      this.PowerBIData = this.reportService.getCurrentReport().powerbi_data; 
      this.loadReport(); 
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getVisualFilter();
    }
  }
  /**
   * Loads a PowerBI report and embeds it into the UI.
   */
  loadReport() {
    this.reportService.powerBiFecthReport(this.PowerBIData.pb_group_id, this.PowerBIData.pb_report_id).subscribe((res: any) => {
      // Update the reportConfig to embed the PowerBI report
      this.reportConfig = {
        ...this.reportConfig,
        id: this.PowerBIData.pb_report_id,
        pageName: this.PowerBIData.pb_page_id,
        embedUrl: res.data.embedUrl,
        accessToken: res.data.token,
      }
      const powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.powerBiReport = powerbiService.embed(this.reportContainer.nativeElement, this.reportConfig);
      this.powerBiReport.on('loaded', async () => {
        // Set the page after the report is loaded
        this.isLoader = false;
        this.reportContainer.nativeElement.style.display = 'block';
        this.getVisualFilter();
      })
    })
  }
  async getVisualFilter() {
    //Basics filter
    let basicFilter: any = await this.createFilterJson();
    
    // Filter out any objects where values are empty or undefined
    basicFilter = basicFilter.filter((filter: any) => filter.values && filter.values.length > 0);
    this.powerBiReport.setFilters(basicFilter)
  }
  createFilterJson(){
    return new Promise((resolve, reject) => {     
      let paramsFilters = {...this.params};
      delete paramsFilters.rid;
      delete paramsFilters.oid;
      delete paramsFilters.gid;
      delete paramsFilters.zoom;
      delete paramsFilters.centroid;
      delete paramsFilters.polygon;
      delete paramsFilters.srid;
  
      let basicFilter:any = [];
       if(this.reportService.reportFilter?.length > 0){
        for(let key in paramsFilters){
          let data = paramsFilters[key];
          if(key == 'year'){
            data = (paramsFilters['year']?.split(',') || [new Date().getFullYear()]).map((y: any) => Number(y));
          }else{
            data = paramsFilters[key].includes(',') ? paramsFilters[key]?.split(',') : [paramsFilters[key]]
          }
    
          let filter = this.reportService.reportFilter.find((f:any)=> f.parameter_name == key);
          if(filter){
            basicFilter.push({
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: filter.table,
                    column: filter.col_name
                  },
                  operator: "In",
                  values: data
                })
          }
         }
        resolve(basicFilter)
       }else{
        resolve(basicFilter)
       }
      
    })
    
  }
  // Opens the Contact Sales dialog with specified configurations
  openContactSales() {
    this.dailog.open(ContactSalesComponent, {
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type: 'contact_us' }
    })
  }
}
