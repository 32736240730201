import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ReportPropertyDetailConstant } from '../../residential-property/report-property-detail.constant';
import { ReportPropertyDetailService } from '../../residential-property/report-property-detail.service';
import moment from 'moment';
import { OfficeReportPropertyDetailConstant } from '../../office-property-detail/office-report-property-details.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-property-transaction',
  templateUrl: './property-transaction.component.html',
  styleUrls: ['./property-transaction.component.scss']
})
export class PropertyTransactionComponent implements OnChanges {

  @Input() propertyInfo: any;
  @Output() headerCount = new EventEmitter();
  @Input() report_category = 'residential';

  propertyTransactionLoading = false;
  propertyTransactionData = [];
  propertyTransactionCount = 0;

  transactionPropertyColumns  = ReportPropertyDetailConstant.transactionPropertyColumns;

  constructor(
    private reportPropertyDetailService: ReportPropertyDetailService,
    private translate : TranslateService
  ){}

  ngOnChanges(): void {
    if(this.report_category == 'retail'){
      this.transactionPropertyColumns = ReportPropertyDetailConstant.retailTransactionPropertyColumns
    }
    if(this.report_category == 'office'){
      this.transactionPropertyColumns = OfficeReportPropertyDetailConstant.propertyTransactionColumn
    }
    this.getPropertyTransaction();
  }

  getPropertyTransaction() {
    this.propertyTransactionLoading = true;
    this.reportPropertyDetailService.getPropertyTransctionReport(this.reportPropertyDetailService.propertyTransactionPayload, this.report_category, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.propertyTransactionData = res.data.map((value: any) => {
              value['transaction_date']= value.transaction_date ? moment.utc(value.transaction_date).local().format('DD MMM YYYY') : null;
              if(this.report_category === 'office' || this.report_category === 'retail') {
                value['record_type'] = this.reportType(value);
              }
              return value;
            })
            if(this.reportPropertyDetailService.propertyTransactionPayload.pageNumber == 0){
            this.propertyTransactionCount = res.count;
            this.headerCount.emit(this.propertyTransactionCount)
           }

          } else {
            this.propertyTransactionData = [];
            this.propertyTransactionCount = 0;
            this.headerCount.emit(0)
          }
          this.propertyTransactionLoading = false;
        },
        error:()=>{
          this.propertyTransactionLoading = false;
          this.propertyTransactionData = [];
          this.propertyTransactionCount = 0;
          this.headerCount.emit(0)
        }
      }
    )
  }

  reportType(value: any): string{
    if(value?.prop_type_estater == null || value?.prop_type_estater == 'null'){
      return '-'
    }
    if(value?.prop_type_govt && value?.prop_type_estater && value?.prop_type_govt !== value?.prop_type_estater){
      return `<span><del>${this.translate.instant(value?.prop_type_govt)}</del></span><span class='reportType'>*${this.translate.instant(value?.prop_type_estater)}</span>`;
    } else if(value?.prop_type_govt ) {
      return `<span>${this.translate.instant(value?.prop_type_govt)}</span>`;

    }else if(value?.prop_type_estater ) {
      return `<span>${this.translate.instant(value?.prop_type_estater)}</span>`;

    } else {
      return '-'
    }
  }



  onPageEvent(e: any) {
    this.reportPropertyDetailService.propertyTransactionPayload.pageNumber = (e.pageSize * e.pageIndex);
    this.getPropertyTransaction();
  }

  sortData(event: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    this.reportPropertyDetailService.propertyTransactionPayload.pageNumber = 0;
    this.reportPropertyDetailService.propertyTransactionPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
    this.getPropertyTransaction();
  }

  downloadExcel(event: any, type: string) {
    this.reportPropertyDetailService.downloadRentalReport(type, this.report_category);
  }


}
