<div class="container">
  <div class="header">
    <p>{{'User.request_for_a_demo' | translate}}</p>
  </div>
  <div class="body">
    <p>{{'User.authorizing_us_to_contact_you' | translate}}</p>
  </div>
  <div class="footer">
    <button class="btn gry-button" (click)="confirmDialogBox(false)">{{'User.Cancel' | translate}}</button>
    <button class="btn org-button" (click)="confirmDialogBox(true)">{{'confirm.confirm' | translate}}</button>
  </div>
</div>
