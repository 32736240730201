import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.scss']
})
export class UniversalSearchComponent {

  private unsubscribe$: Subject<void> = new Subject<void>();
  @Input() Searched_properties:any;
  @Input() cityCode:string='';

  @Output() SearchedParcelOnMap = new EventEmitter();
  @Output() closeChip = new EventEmitter();

  @ViewChild('searchpropertyDetail') searchpropertyDetail !: SearchBarComponent

  public current_lang: any = 'en';
  showRecentSearch: boolean=true;
  searchedData: any=[];
  recentSearchedData: any[]=[];

  constructor(private sharedService: SharedService,
    private reportService: ReportService
  ){
    this.sharedService.language$.pipe(
          debounceTime(200),
          takeUntil(this.unsubscribe$)
        ).subscribe((lang) => {
          this.current_lang = lang;
        }) 
  }

  ngOnInit(){
    this.getRecentSearchedParcelDetail();
  }

  locateSearchedParcelOnMap(properties:any){
    this.addRecentSearchedParcelDetail(properties);
    this.SearchedParcelOnMap.emit(properties);
  }

  clearChipdata(search:boolean=false){
    this.searchpropertyDetail?.searchControl.patchValue("");
    this.closeChip.emit(search);
  }

  closeMapSearch(event: boolean) {
    if (event) {
      this.searchpropertyDetail?.searchControl.patchValue("");
    }
  }

  addRecentSearchedParcelDetail(propertyData: any, searchedText: string = "") {
    let payload = {
      property_uid: propertyData.property_uid,
      neigh_name: propertyData.neigh_name,
      block_name: propertyData.block_name,
      property_name: propertyData.property_name ? propertyData.property_name : "",
      search_text: this.searchpropertyDetail?.searchControl.value
    }
    this.reportService.addRecentSearchedParcelDetail(payload).subscribe({
      next: (res: any) => {
        if (res && res.status == 201)
          this.getRecentSearchedParcelDetail();
      },
      error: (err) => {

      }
    })
  }
  deleteRecentSearchedParcelDetail(action: string, id: number = 0) {
    this.reportService.deleteRecentSearchedParcelDetail(action, id).subscribe({
      next: (res: any) => {
        if (res && res.status == 201)
          this.getRecentSearchedParcelDetail();
      },
      error: (err: any) => { }
    })

  }

  /**
   * This fuction use for the getting recent search
   */
  getRecentSearchedParcelDetail() {
    this.reportService.getRecentSearchedParcelDetail().subscribe({
      next: (res: any) => {
        if (res && res.status == 200)
          this.recentSearchedData = res?.data
        else {
          this.recentSearchedData = [];
        }
      },
      error: (err) => {
        this.recentSearchedData = [];
      }
    })
  }
  /**
   * This function is used to get the property detail based on universal search on map
   * @param searchedText : searched text used to get the filterd data based on user search.
   */
  getMapSearchedDetail(searchedText: string = '') {
    let queryParams = { ...this.reportService.queryParams };
    let year = new Date().getFullYear();
    if (queryParams?.year) {
      year = this.reportService.yearMaxValue(queryParams.year);
    }
    searchedText = searchedText.trim();
    if (searchedText.length >= 3)
      this.showRecentSearch = false;
    else {
      this.showRecentSearch = true
      return
    }
    this.reportService.getMapSearchedDetail(searchedText, year).subscribe({
      next: (res: any) => {
        if (res?.status == 200) {
          this.searchedData = res.data
        } else {
          this.searchedData = []
        }
      },
      error: (err: any) => {
        //this.alertService.error(err)
      }
    })

  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete(); 
  }
    
}
