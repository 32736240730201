import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-view-pop-up',
  templateUrl: './mobile-view-pop-up.component.html',
  styleUrls: ['./mobile-view-pop-up.component.scss']
})
export class MobileViewPopUpComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public info: any,
    public dialogRef : MatDialogRef<MobileViewPopUpComponent>,
  ) {

  }

  closeDialog() {
    this.dialogRef.close();
  }
}
