import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class OccupancyLeaseReportConstant {

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]

    static totalOccupanyLeaseColumn: ReportTableColumn[] = [
        {
            header: 'reports.totalProperties',
            field: 'total_property',
        },
        {
            header: 'reports.totalUnits',
            field: 'total_unit',
        },
        {
            header: 'reports.occupancy',
            field: 'occupancy_ratio',
            symbol: GlobalContants.keySymbolContants.percentage
        },
        {
            header: 'reports.avgMonthlyRent',
            field: 'average_monthly_rent',
            cityCode: true
        }
    ]

    static OccupanyLeaseColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.unitType',
            field: 'category',
            border: false
        }
    ]
}
