import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ReportTenantsMapService } from './reports-tenants-map-view.service';
import { Subscription } from 'rxjs';
import { ReportService } from '../../services/report.service';
import { ReportsViewEnum } from '../../models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { environment } from 'src/environments/environment';

declare const L: any;

@Component({
  selector: 'app-reports-tenants-map-view',
  templateUrl: './reports-tenants-map-view.component.html',
  styleUrls: ['./reports-tenants-map-view.component.scss']
})
export class ReportsTenantsMapViewComponent {

  @Input() classname!: string;
  @Input() set propertyInfo(value: any) {
    if (value) {
      this.selected_property = value;
    }

  }
  public mapCenter: any = [29.3560456, 47.9999959];
  mapLayer: any;
  map: any;
  public mapZoom: any = 16;
  floorDetail: any;
  currentFloorSelected: any;
  currentReportView!: number;
  currentReportView$: Subscription = new Subscription();
  public selected_property: any;
  viewType = ReportsViewEnum;
  selectedIndex = 0;
  currentLangID = 1;
  floorData: any;
  public sataliteLayer: any;
  public defaultLayer: any;
  public scaleControl: any;
  public layerControl: any;
  multyGeoJsonLayer:any;
  geoJsonLayer:any;
  unitLayer: any = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private reportTenantsMapService: ReportTenantsMapService,
    public reportService: ReportService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sharedService.language$.subscribe(res => {
        let language = this.sharedService.getCurrentLangID();
        this.currentLangID = language;
      })
      this.initMap();
    }

    this.getUnits()

    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      if (this.currentReportView != view) {
        if (this.map && view !== 0) this.map.closePopup();
        this.currentReportView = view;
      }
    });
  }

  getUnits() {
    this.reportTenantsMapService.getUnits(this.selected_property).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.floorDetail = res.data;
            let index = this.floorDetail.findIndex((item: any) => item?.unit_count) !== -1
              ? this.floorDetail.findIndex((item: any) => item?.unit_count)
              : this.floorDetail.findIndex((item: any) => item?.unit_layout?.layout_data);
            this.setGeoLayer(index)
            // ========
            this.sataliteLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
              maxZoom: 21,
              zIndex: 1,
              subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            }).addTo(this.map);
            if (environment.is_production) {
              this.defaultLayer = L.tileLayer('https://tile.jawg.io/c458ee94-1619-468e-bfb3-e315b379a4ce/{z}/{x}/{y}{r}.png?access-token={accessToken}', {
                attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                maxZoom: 21,
                minZoom: this.mapZoom,
                subdomains: 'abcd',
                accessToken: 'O4lo58fpF3zdhkzPQMtrEmKtTZmV4FOlArfe2vlryRpMCoOEfbqJv4ui9ypAxexs'
              });
            } else {
              this.defaultLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 21,
                minZoom: this.mapZoom
              });
            }
            let x = {
              zoomControl: false,
              maxZoom: 21,
              minZoom: this.mapZoom
            }
            var layers = {
              'No layer': L.tileLayer('', {maxZoom: 23,minZoom: this.mapZoom}),
              "Satellite": this.sataliteLayer,
              "OSM <small>(Jawg)</small>": this.defaultLayer,
            };
            this.scaleControl = L.control.scale(); //Variable declared for adding of Scale Control on Map
            if (!this.layerControl)
              this.layerControl = L.control.layers(layers).addTo(this.map);
            // 
            // ========
          } else {
            this.floorDetail = null;
            this.currentFloorSelected = null;
          }
        },
        error: (error: any) => {
          this.floorDetail = null;
          this.currentFloorSelected = null;
        }
      }
    )
  }

  initMap() {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    this.map = L.map('tenantsMap', {
      zoomControl: false,
      maxZoom: 21,
      minZoom: this.mapZoom
    }).setView(this.mapCenter, this.mapZoom);
    this.map.attributionControl.setPrefix('');
  }
  setGeoLayer(index = 0) {
    this.currentFloorSelected = this.floorDetail[index];
    this.selectedIndex = index;
    var that = this;
    this.unitLayer = this.currentFloorSelected.unit_layout.layout_data?.features.filter((layer: any) => layer.properties.shop_name && layer.properties.shop_name?.toLowerCase() == that.selected_property.shop_label?.toLowerCase())
    if (this.currentFloorSelected.unit_layout.layout_data) {
      if (this.floorData) {
        this.floorData.clearLayers()
      }
      this.floorData = L.geoJSON(this.currentFloorSelected.unit_layout.layout_data, {

        style: function (feature: any) {
          let properties = feature.properties;
          if (properties.shop_name && properties.shop_name?.toLowerCase() == that.selected_property.shop_label?.toLowerCase()) {

            return that.reportTenantsMapService.tenantColor();
          } else if (properties.is_vacant) {
            return that.reportTenantsMapService.vacantColor();
          } else {
            return that.reportTenantsMapService.filledTenantColor();
          }
        }
      }).bindTooltip(function (layer: any) {
          return layer.feature.properties.shop_name || 'Vacant';
        }, {
          direction: `${this.currentLangID == 1 ? 'left' : 'right'}`,
          className: "tenantTT-tip",
        }

        ).addTo(this.map);
        if(this.geoJsonLayer) this.map.removeLayer(this.geoJsonLayer);
        if(this.multyGeoJsonLayer) this.map.removeLayer(this.multyGeoJsonLayer);
        console.log(this.unitLayer)
      if (this.unitLayer?.length > 1) {
        const parcels = {
          type: 'FeatureCollection',
          features: this.unitLayer
        }
        this.multyGeoJsonLayer = L.geoJSON(parcels, {

          style: function (feature: any) {
            return that.reportTenantsMapService.tenantColor();
          }
        }).bindTooltip(function (layer: any) {
          return layer.feature.properties.shop_name || 'Vacant';
        }, {
          direction: `${this.currentLangID == 1 ? 'left' : 'right'}`,
          className: "tenantTT-tip",
        }).addTo(this.map);
        this.map.fitBounds(this.multyGeoJsonLayer.getBounds());
      } else {
        if(this.unitLayer?.length > 0){
          this.geoJsonLayer = L.geoJSON(this.unitLayer[0], {

            style: function (feature: any) {
              return that.reportTenantsMapService.tenantColor();
            }
          }).bindTooltip(function (layer: any) {
            return layer.feature.properties.shop_name || 'Vacant';
          }, {
            direction: `${this.currentLangID == 1 ? 'left' : 'right'}`,
            className: "tenantTT-tip",
          }).addTo(this.map);
          this.map.fitBounds(this.geoJsonLayer.getBounds());
        } else {
          this.map.setZoom(19);
        }
        
      }
    } else {
      if(this.geoJsonLayer) this.map.removeLayer(this.geoJsonLayer);
      if(this.multyGeoJsonLayer) this.map.removeLayer(this.multyGeoJsonLayer);
      this.floorData?.clearLayers()
      this.map.setZoom(19);
    }

  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    if (this.currentReportView$) this.currentReportView$.unsubscribe();
  }


}
