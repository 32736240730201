<div class="report-container warehouse-inventory">


    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <ng-container *ngIf="overViewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ warehouseInventoryMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === warehouseInventoryMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overViewLoading">
            <app-overview-card
                [card_title]="'reports.totalBuiltUparea'"
                [total_current]="overViewSummary?.total_builtup_area.total_builtup_area" 
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.total_builtup_area.prev_total_builtup_area"
                [total_percentage]="overViewSummary?.totalBuiltupArea" 
                [uniType]="UnitTypeEnum.sqm"
                [graphData]="{data: overviewbuiltUpArea, type: 'total_builtup_area', loader: graphLoader}">
            </app-overview-card>

            <app-overview-card 
                [card_title]="'reports.totalWarehouses'"
                [total_current]="overViewSummary?.total_warehouses.total_warehouses"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.total_warehouses.prev_total_warehouses"
                [total_percentage]="overViewSummary?.totalOccupacy_rate"
                [graphData]="{data: overviewTotalWarehouse, type: 'totalWarehouses', loader: graphLoader}">
            </app-overview-card>
            
            <app-overview-card 
                [card_title]="'reports.totalland_covered'"
                [total_current]="overViewSummary?.total_land_covered.total_land_covered"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.total_land_covered.prev_total_land_covered"
                [total_percentage]="overViewSummary?.totalLandCovered" 
                [uniType]="UnitTypeEnum.sqm"
                [graphData]="{data: overviewTotalLandCovered, type: 'total_land_covered', loader: graphLoader}">
        </app-overview-card>    



        </div>
        <div class="report-model report-rentrate">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.grossBuiltupWarehouseSpace' | translate}}  ({{'reports.sqm_unit' | translate}})
                    </h3>
                </div>

                <button  *ngIf="selectedGrossBuiltupChart.length" class="btn-select-drop" [matMenuTriggerFor]="menu">
                    <div class="setext" [ngClass]="{'setextfull': selectedGrossBuiltupChart.length === 1 }">
                        {{selectedGrossBuiltupChartName}}
                    </div>
                    <span class="morecount" *ngIf="selectedGrossBuiltupChart.length > 1">({{selectedGrossBuiltupChart.length - 1}}) {{'reports.more' | translate}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"  class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let item of grossBuiltupChart">
                            <mat-checkbox (change)="selectChart($event, item)" [(ngModel)]="item.checked">{{item.label}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu> 

                
            </div>
            <ng-container *ngIf="grossBuiltupChartLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!grossBuiltupChartLoading">
                <div class="multiBarChart d-flex" *ngIf="selectedGrossBuiltupChart.length; else noDataMessage">
                    <ng-container *ngFor="let chart of selectedGrossBuiltupChart; let i = index">
                        <app-report-charts 
                            [data]="chart.data" 
                            [chartType]="chartView.smallvarianceIndicatorsChart"
                            [name]="'grossBuiltupChart' + i"
                            [isBorder]="false"
                            [text]="chart.label"
                            [ngStyle]="{'min-width': selectedGrossBuiltupChart.length == 1 ? '100%' : '450px'}"
                            >
                        </app-report-charts>
                    </ng-container>
                </div>
            </ng-container>
            
            <ng-template #noDataMessage>
                <div class="data_unavail">
                    <span>
                        {{"reports.data_not_available" | translate}} <mat-icon role="img"
                            class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                            aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                    </span>
                </div>
            </ng-template>
            
        </div>

        

        <div class="report-model report-rentrate">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.grossLandCoverageArea' | translate}}  ({{'reports.sqm_unit' | translate}})
                    </h3>
                </div>


                <button  *ngIf="selectedGrossLandCoveredChart.length" class="btn-select-drop" [matMenuTriggerFor]="menuList">
                    <div class="setext" [ngClass]="{'setextfull': selectedGrossLandCoveredChart.length === 1 }">
                        {{selectedGrossLandCoveredChartName}}
                    </div>
                    <span class="morecount" *ngIf="selectedGrossLandCoveredChart.length > 1">({{selectedGrossLandCoveredChart.length - 1}}) {{'reports.more' | translate}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menuList="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"  class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let items of grossLandCoveredChart">
                            <mat-checkbox (change)="selectLandCoveredChart($event, items)" [(ngModel)]="items.checked">{{items.label}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu> 

                
            </div>
            <ng-container *ngIf="grossLandCoveredLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!grossLandCoveredLoading">
                <div class="multiBarChart d-flex" *ngIf="selectedGrossLandCoveredChart.length; else noDataMessage">
                    <ng-container *ngFor="let chart of selectedGrossLandCoveredChart; let i = index">
                        <app-report-charts 
                            [data]="chart.data" 
                            [chartType]="chartView.smallvarianceIndicatorsChart"
                            [name]="'grosslandcoveredChart' + i"
                            [isBorder]="false"
                            [text]="chart.label"
                            [ngStyle]="{'min-width': selectedGrossLandCoveredChart.length == 1 ? '100%' : '450px'}"
                            >
                        </app-report-charts>
                    </ng-container>
                </div>
            </ng-container>
            
           
            
        </div>
    </div>

    <!-- neighborhood -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === warehouseInventoryMenuEnum.neighborhood}"
        id="{{ warehouseInventoryMenuEnum.neighborhood }}">

        <app-report-year-dropdown [reportType]="'neighborhood'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.neighborhood" | translate}}</h3>
                    <span class="totalTransaction"> ({{neighbourhoodCount ? reportService.formatInternationNum(neighbourhoodCount) : neighbourhoodCount}})</span>
                </div>

            </div>
          
            <app-report-datatable class="rwi-rentunitsize-table" 
                [showLoader]="!neighbourhoodLoading"
                [tableColumns]="neighborhoodColumns" 
                [dataLength]="neighbourhoodCount"
                [tableData]="neighbourhooddata" 
                (pageEvent)="onPageEvent($event, warehouseInventoryMenuEnum.neighborhood)"
                (sort)="sortData($event, warehouseInventoryMenuEnum.neighborhood)" 
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'warehouse_inventory_neighborhood', 'a')"
                [downloadLoading]="downloadLoading['a']" 
                [expandColumn]="true"
                [tableExpandColumns]="neighbourhoodExpandColumns" 
                [showTooltip]="true"
                >
            </app-report-datatable>



        </div>
    </div>

    <!-- Properties -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === warehouseInventoryMenuEnum.properties}"
        id="{{ warehouseInventoryMenuEnum.properties }}">

        <app-report-year-dropdown [reportType]="'properties'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.projectsProperties' | translate}}</h3>
                    <span class="totalTransaction">({{propertiesCount ?
                        reportService.formatInternationNum(propertiesCount) : propertiesCount}})</span>
                </div>

            </div>
           
            <app-report-datatable 
                [showLoader]="!propertiesLoading" 
                [tableColumns]="propertiesColumns"
                [dataLength]="propertiesCount" 
                [tableData]="propertiesData"
                (pageEvent)="onPageEvent($event, warehouseInventoryMenuEnum.properties)"
                (sort)="sortData($event, warehouseInventoryMenuEnum.properties)" 
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'warehouse_inventory_properties', 'b')"
                [downloadLoading]="downloadLoading['b']" 
                >
            </app-report-datatable>


        </div>
    </div>



</div>