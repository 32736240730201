<div class="navBar">
    <div class="navBar-btn">
        <button (click)="backToTransaction()" [ngClass]="{ 'backToReportAr' : currentLang === 'ar'}"
            class="backToReport"><mat-icon>keyboard_arrow_left</mat-icon>
            <span>{{'reports.backToReport'|translate}}</span> </button>
    </div>

    <div class="property-detailMenu" [ngClass]="{ 'property-detailMenuAr' : currentReportView === 2}">
        <app-report-sub-menu [menuList]="overViewMenu" [isPropertyDetailPage]="true"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    </div>
</div>

<div class="report-property-detail">
    <div class="report-tabsection-d" id="{{ propertyDetailMenuEnum.overview}}"
    [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.overview}">
        <app-report-property-info [propertyInfo]="propertyInfo"></app-report-property-info>
    </div>



    <!--Built up Information-->
    <div class="report-tabsection-d" id="{{ propertyDetailMenuEnum.builtInformation}}"
        [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.builtInformation}">
        <app-build-up-info [propertyInfo]="propertyInfo"></app-build-up-info>

    </div>
    <!--Built up Information-->


    <!--Construction-->
    <!-- <div id="{{ propertyDetailMenuEnum.costOfconstruction}}">
        <app-cost-of-construction [propertyInfo]="propertyInfo"></app-cost-of-construction>

    </div> -->

    <!--Construction-->

    <!--Amenities-->
    <div class="report-tabsection-d" id="{{ propertyDetailMenuEnum.amentities}}"
        [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.amentities}">
        <app-property-amenities (headerCount)="setCount($event, propertyDetailMenuEnum.amentities)"
            [propertyInfo]="propertyInfo"></app-property-amenities>

    </div>

    <!--Amenities-->

    <!--Residential Units-->
    <div class="report-tabsection-d" id="{{ propertyDetailMenuEnum.unit}}"
        [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.unit}">

        <app-residential-units (headerCount)="setCount($event, propertyDetailMenuEnum.unit)"
            [propertyInfo]="propertyInfo"></app-residential-units>

        <app-land-value-details [propertyInfo]="propertyInfo"></app-land-value-details>

    </div>
    <!--Residential Units-->

    <!-- Units Trasaction-->
    <div class="report-model report-tabsection-d" id="{{ propertyDetailMenuEnum.unitTransaction}}"
        [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.unitTransaction}">

        <app-unit-transaction (headerCount)="setCount($event, propertyDetailMenuEnum.unitTransaction)"
            [propertyInfo]="propertyInfo"></app-unit-transaction>
    </div>
    <!--Units Trasaction-->

    <div class="report-model report-tabsection-d" id="{{ propertyDetailMenuEnum.propertyTransaction}}"
        [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.propertyTransaction}">
        <app-property-transaction (headerCount)="setCount($event, propertyDetailMenuEnum.propertyTransaction)"
            [propertyInfo]="propertyInfo"></app-property-transaction>


    </div>

    <div class="report-model report-tabsection-d" id="{{ propertyDetailMenuEnum.rentalIncome}}"
        [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.rentalIncome}">
        <app-residential-rentalincome-assessement
            [propertyInfo]="propertyInfo"></app-residential-rentalincome-assessement>


    </div>

</div>