<app-reports-container
    [tableColumn]="totalPropertyProfilinColumn"
    [dataValue]="totalPropertyProfilingReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [dataValue]="typePropertyProfilingReport"
    [reportType]="chartView.pieChart"
    [chartName]="'share_Of_Parking'"
    [loading]="typePropertyProfilingReportLoading"
    [title]="'reports.share_Of_Parking'"
    [yearDropdown]="true"
    [yearReportType]="reports.share_Of_Parking"
    (yearSelected)="selectedYear($event)"
></app-reports-container>


<app-reports-container
    [tableColumn]="typeGradePropertyProfilingReportTableColumn"
    [dataValue]="typeGradePropertyProfilingReport"
    [isDownloadExcel]="true"
    [loading]="typeGradePropertyProfilingReportLoading"
    [reportName]="'reports.neighborhoodAndGradewiseParkingratio'"
    [title]="'reports.neighborhoodAndGradewiseParkingratio'"
    [expandable]="true"
    [yearDropdown]="true"
    [yearReportType]="reports.neighborhoodAndGradewiseParkingratio"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<app-reports-container
    [tableColumn]="gradeFurnishedPropertyProfilingTableColumn"
    [dataValue]="gradeFurnishedPropertyProfilingReport"
    [isDownloadExcel]="true"
    [loading]="gradeFurnishedPropertyProfilingReportLoading"
    [reportName]="'reports.neighborhoodsAndGradeWiseUnitFurnishingStatus'"
    [title]="'reports.neighborhoodsAndGradeWiseUnitFurnishingStatus'"
    [expandable]="true"
    [yearDropdown]="true"
    [yearReportType]="reports.neighborhoodsAndGradeWiseUnitFurnishingStatus"
    (yearSelected)="selectedYear($event)"
></app-reports-container>


<app-reports-container
    [tableColumn]="amenitiesPropertyProfilingTableColumn"
    [dataValue]="amenitiesPropertyProfilingReport"
    [isDownloadExcel]="true"
    [loading]="amenitiesPropertyProfilingReportLoading"
    [reportName]="'reports.shareofamenities'"
    [title]="'reports.shareofamenities'"
    [expandable]="true"
    [yearDropdown]="true"
    [yearReportType]="reports.shareofamenities"
    (yearSelected)="selectedYear($event)"
></app-reports-container>
