import { ReportTableColumn } from "../../models/reports.model";

export class PopulationReportConstant {
    static populationReports = {
        population_year_wise: 'population_year_wise',
        population_trendline: 'population_trendline',
        totalPopulation: 'totalPopulation'
    }

    
    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]
    
    static totalpopulationColumn: ReportTableColumn[] = [
        {
            header: 'reports.totalPopulation',
            field: 'total_population',
        },
        {
            header: 'reports.male',
            field: 'male',
        },
        {
            header: 'reports.female',
            field: 'female',
        }
    ]
 
    static populationAgeWiseColumns: ReportTableColumn[] = [
        {
            header: 'reports.neighborhoods',
            field: 'neigh_name',
            border: false
        },
        {
            header: 'reports.age',
            field: 'age_group',
            border: false
        }
    ]
}
