<div [ngClass]="{'custom_zindex' : tenantPropertyInfo}" class="map_section {{classname}}">
<div id="map" #mapIdentifier [ngClass]="{'arb-map': current_lang == 'ar'}" [ngStyle]="{'margin-top' : reportId ? '60px' : '0px', 'height' : reportId ? 'calc(100vh - 143px)' : 'calc(100vh - 83px)'}">
    <div class="map-spinner" *ngIf="map_spinner">
        <mat-spinner class="background" mode="determinate" value="100">
        </mat-spinner>
        <mat-spinner class="mat-spin"></mat-spinner>
    </div>
    <div class="draw-toolbar"
        [ngStyle]="{'margin': draw_btn_toggle ? '12px 10px':''} "
        *ngIf="draw_btn_toggle">

        <ng-container *ngIf="polygon_create">
            <app-draw-catchment 
            [drawnPolygons]="drawnPolygons" 
            [filterList]="filterList" 
            [polygon_chip]="polygon_chip"
            [polygon_create]="polygon_create" 
            (polygonRemove)="polygonWKTRemove($event)"
            (catchmentExpandCol)="catchmentExpand($event)"
            (resetCatchmentConfirm)="resetCatchmentConfirm()"
        ></app-draw-catchment>

        </ng-container>
       

        <button [disabled]="reportService.getLockReport()"
            *ngIf="!polygon_create && reportName?.toLocaleLowerCase() !== 'population'" (click)="drawPolygon();"
            id="draw_polybtn" [ngClass]="{ 'rmv-btn': polygon_create, 'draw-btn':!polygon_create}">
            <img *ngIf="reportService.getLockReport()" class="lock" src="assets/images/Lock-disable.png" alt="">
            <span *ngIf="!polygon_create">{{"map.set_catchment" | translate}}</span>
            <!-- <span *ngIf="polygon_create && (polygon_WKT == null || polygon_WKT == undefined)">{{"map.cancel_catchment" |
                    translate}}</span>
                <span *ngIf="polygon_create && (polygon_WKT != null && polygon_WKT.length > 0)">{{"map.remove_catchment" |
                    translate}}</span> -->
            <span *ngIf="polygon_create && (polygon_WKT != null && polygon_WKT.length > 0)">{{"map.remove_catchment" |
                translate}}</span>
        </button>

        <div class="tenant-names d-flex align-items-center" *ngIf="TenantName">
            {{'reports.show_on_map' | translate}}:<button><span>{{TenantName}}</span> <span class="icon"
                    (click)="removeCustomMaker(true)"><mat-icon class="remove-filter">close</mat-icon></span></button>
        </div>
    </div>
    <div class="legendContainer map_legend" *ngIf="legendToggle && legend_data_by_group?.length"
        [ngStyle]="{'direction': current_lang == 'ar' ? 'ltr':''} ">
        <span class="legendContainer_header"><span>{{'Map Legend' | translate}}
                ({{layerselected}})</span> <span class="legend_close" (click)="collapseLegend()"><img
                    class="legend_close" src="../../../../assets/images/cross.svg" /></span></span>
        <!-- <div *ngFor="let main_legend of legend_data;let idx = index;">
            <span class="mapLegend" style="font-weight: 500;">{{returnVal(main_legend.label,'text',main_legend)}}</span>
            <div *ngFor="let legend of main_legend.data;let idx = index; let isLast=last;" class="mapLegend">
                <div *ngIf="main_legend.type != 'number'" style="display: flex;"><img
                        src="{{returnVal(main_legend.data[idx],'src',main_legend)}}" alt="legend" width="20"
                        height="20">{{returnVal(main_legend.data[idx],'text',main_legend)}}
                </div>
                <div *ngIf="main_legend.type == 'number' && !isLast" style="display: flex;"><img
                        src="{{returnVal(main_legend.data[idx]+'-'+main_legend.data[idx+1],'src',main_legend)}}"
                        alt="legend" width="20" height="20">{{returnVal(main_legend.data[idx],'text',main_legend)}}
                    {{'sqm_unit' | translate}}. -
                    {{returnVal(main_legend.data[idx+1],'text',main_legend)}} {{'sqm_unit' |translate}}.</div>
                <div *ngIf="main_legend.type == 'number' && isLast" style="display: flex;"><img
                        src="{{returnVal('>'+main_legend.data[idx],'src',main_legend)}}" alt="legend" width="20"
                        height="20">{{returnVal(main_legend.data[idx],'text',main_legend)}}
                    {{'sqm_unit' | translate}}. +</div>
            </div>
        </div> -->
        <div *ngFor="let main_legend of legend_data_by_group;let idx = index;">
            <span class="mapLegend" style="font-weight: 500;">{{ main_legend.label[current_lang_id]?main_legend.label[current_lang_id]:main_legend?.label[1] }}</span>
            <div *ngFor="let legend of main_legend.data;let idx = index; let isLast=last;" class="mapLegend rows">
                <div style="display: flex;">
                        <span class="legendcolor" [class.circle]="main_legend.legend_type == 'grade'" [ngStyle]="{'background-color': legend.color}"></span>
                        {{ legend?.label?.[current_lang_id]?legend.label[current_lang_id]:legend?.label?.[1] }}
                </div>
            </div>
        </div>
    </div>
</div>
<app-reports-tenants-map-view *ngIf="tenantPropertyInfo" [propertyInfo]="tenantPropertyInfo"></app-reports-tenants-map-view>
</div>
<!-- <ng-container  *ngIf="currentReportView === viewType.gridView || currentReportView === viewType.mapGridView"> -->
<div class="mapLayer-panel" *ngIf="propertyinfoToggle">
    <app-map-property-details [propertyInfo]="selected_property"></app-map-property-details>
</div>
<ng-container *ngIf="showAreaToggle">
    <div class="mapLayer-panel" [ngClass]="{'mapLayer-fullpanel': showAreaToggle && currentReportView === viewType.gridView }"><app-report-neigh-area-details [propertyInfo]="neightInfo"></app-report-neigh-area-details>
    </div>
</ng-container>
<ng-container *ngIf="showProjectToggle">
    <div class="mapLayer-panel"
        [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }"
        *ngIf="reportName !== 'inventory'">

        <app-report-housevilla-project-details
            [propertyInfo]="projecttInfovar"></app-report-housevilla-project-details>
    </div>
    <div class="mapLayer-panel"
        [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }"
        *ngIf="reportName == 'inventory'">

        <app-report-inventory-project-details
            [propertyInfo]="projecttInfovar"></app-report-inventory-project-details>
    </div>
</ng-container>


<!-- <ng-container *ngIf="propertyinfoToggle">
    <div class="mapLayer-panel">
        <app-power-bi-property-details></app-power-bi-property-details>
    </div>
</ng-container> -->