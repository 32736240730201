<div>
    <div class="nav-bar">
        <span>{{"reports.saveReportTo" | translate}} {{getBucketName()}} {{'reports.saveReportToFolder' | translate}}</span>
        <span class="close" (click)="cancel()">{{"reports.close" | translate}}</span>
    </div>
    <div class="report-name-form">
        <input type="text" class="form-control" [(ngModel)]="report_name" maxlength ="65" minlength="2" placeholder="{{'reports.report_name' | translate}}">
        <span class="report-name-count">{{report_name ? report_name.length : 0}} / 65</span>
    </div>
    <div class="folder-list">
        <div class="search-section">
            <input type="text" [(ngModel)]="folder_name" maxlength ="48" minlength="2" placeholder="{{'reports.folder_name' | translate}}" class="folder-name">
            <button class="add-folder" (click)="saveFolderdata()" matTooltip="{{'reports.add_folder' | translate}}">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5H7V0H5V5H0V7H5V12H7V7H12V5Z" fill="white"/>
                </svg>
            </button>
        </div>
        <ul *ngIf="folder_list.length>0">
            <li [class.active]="bucket_id == folder.id" (click)="select_floder(folder.id)"
            *ngFor="let folder of folder_list"><i class="fa fa-folder-open-o" aria-hidden="true"></i> {{folder.label | foldertranslate:folder}}</li>
        </ul>
        <p *ngIf="folder_list.length == 0">{{"reports.folder_not_found" | translate}}</p>
    </div>

    <div class="action">
        <Button type="button" class="btn-default" (click)="cancel()">{{'User.Cancel' | translate}}</Button>
        <Button type="button" class="btn-primary" (click)="openConfimDailog()">{{'reports.saveReport' | translate}}</Button>
    </div>
</div>
