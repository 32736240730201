
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import { SharedService } from 'src/app/common/services/shared.service';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
   isFirstTime = true;
   language$: Subscription = new Subscription()
  constructor(private sharedService: SharedService) {
    super();  
    
    this.language$ = this.sharedService.language$.subscribe(lang => {
        if (lang) {
            this.getAndInitTranslations();
        }
      })
  }

  ngOnInit(){
    this.getAndInitTranslations();
    this.isFirstTime = false;
  }
  getAndInitTranslations() {
    let current_lang = this.sharedService.getCurrentLangID()
      this.nextPageLabel = current_lang == 1 ? "Next Page": 'الصفحة التالية';
      this.previousPageLabel = current_lang == 1 ? "Previous Page" : 'الصفحة السابقة';
      this.lastPageLabel  = current_lang == 1 ? "Last Page": 'الصفحة الأخيرة';
      this.firstPageLabel = current_lang == 1 ? "First Page" : 'الصفحة الأولى';
      this.changes.next();
  }

  ngOnDestroy(){
    this.language$.unsubscribe()
  }
}