<button class="container" (click)="mainadd()">Add Container</button>
<div id="content" *ngFor="let container of containers; let i = index">

  <ng-container *ngIf="container.type">
    <app-main-chart [type]="container.type"></app-main-chart>
  </ng-container>
  <ng-container *ngIf="container.type === 'overview' && container.subcontainers.length">
    <div class="sub">
      <div id="contentInside" *ngFor="let subcontainer of container.subcontainers; let j = index">
        <h1>{{ subcontainer.name }}</h1>
        <button mat-button [matMenuTriggerFor]="menuOverview">Edit</button>
    <mat-menu #menuOverview="matMenu">
       <div class="poc-col"  (click)="$event.stopPropagation();">
        <mat-select placeholder="Select Overview" (selectionChange)="selectionOverview($event, i, j)">
           
          <mat-option [value]="'count'">Count</mat-option>
          <mat-option [value]="'average'">Average</mat-option>
         
        
      </mat-select>

    
    

       </div>
         
    </mat-menu>
      </div> 
    </div>
    
  </ng-container>
    
    <!-- <div id="contentInside" *ngFor="let subcontainer of container.subcontainers"></div> -->
    <!-- <button (click)="subadd(i)">Add</button> -->
    <button mat-button [matMenuTriggerFor]="menu">Edit</button>
    <mat-menu #menu="matMenu">
       <div class="poc-col"  (click)="$event.stopPropagation();">
        <mat-select placeholder="Select Container"  [(ngModel)]="container.type" >
           
          <mat-option [value]="'pie'">Pie Chart</mat-option>
          <mat-option [value]="'line'">Bar Chart</mat-option>
          <mat-option [value]="'table'">Table</mat-option>
          <mat-option [value]="'overview'">Overview</mat-option>
        
      </mat-select>

      <ng-container *ngIf="container.type === 'overview'">
        <input type="number" (change)="dataChanged(i)" [(ngModel)]="container.overviewCount">
      </ng-container>
     
    

       </div>
         
    </mat-menu>
</div>