import { ChangeDetectorRef, Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { ReportOccupancyRentalBasicConstants } from './report-occupancy-rental-basic.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportOccupancyRentalBasicService } from './report-occupancy-rental-basic.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { StoreService } from 'src/app/common/services/store.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { environment } from 'src/environments/environment';
import { OccupancyRentalBasicMenuEnum } from './report-occupancy-rental-basic.enum';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-report-occupancy-rental-basic',
  templateUrl: './report-occupancy-rental-basic.component.html',
  styleUrls: ['./report-occupancy-rental-basic.component.scss']
})
export class ReportOccupancyRentalBasicComponent {
  RYDCHECK = environment.RYDCHECK;
  @Input() params: any;
  @Input() multi_story_residential: boolean = false;
  overViewMenu: any = ReportOccupancyRentalBasicConstants.overViewMenu;
  occupancyRentalMenuEnum = OccupancyRentalBasicMenuEnum;
  chartView = ReportsChartEnum;
  UnitTypeEnum = UnitTypeEnum;

  placeCode: string = '';
  currentScroll: any = '';
  overviewLoading = false;
  overviewGradeLoading = false;
  overviewGraphLoading = false;
  averageData: any = {};
  occupancyData: any = {};
  averageGradeData: any[] = [];
  occupancyGradeData: any[] = [];
  averageTotalGradeValue: any = 0;
  occupancyTotalGradeValue: any = 0;
  overviewChartData: any = {};
  placeName = '';
  CurrencyCode: string = '';
  selectedChartValue: any[] = [];
  officeRentOption: string = 'officeRentOption';
  moreText: any;
  langName: any;
  sqm_unit: string = '';
  isFirstTime = true;
  isChart = true;
  retailRentalChartReport: any[] = [];
  userInfo: any = 0;
  downloadLoading: any = {
    a: false,
    b: false,
    c: false,
    d: false,
    f: false
  };

  residentialGradeCount: number = 0;
  residentialGradeLoading: boolean = false;

  residentialTypeCount: number = 0;
  residentialTypeLoading: boolean = false;
  residentialTypeData: any = [];

  commercialGradeCount: number = 0;
  commercialGradeLoading: boolean = false;

  commercialSizeCount: number = 0;
  commercialSizeLoading: boolean = false;
  commercialSizeData: any = [];

  transactionCount: number = 0;
  transactionLoading: boolean = false;
  residentialTypeColumns = ReportOccupancyRentalBasicConstants.residentialTypeColumns;
  residentialTypeExpandColumns = ReportOccupancyRentalBasicConstants.residentialTypeExpandColumns;

  commercialSizeColumns = ReportOccupancyRentalBasicConstants.commercialSizeColumns;
  commercialSizeExpandColumns = ReportOccupancyRentalBasicConstants.commercialSizeExpandColumns;

  showPropertyDetails: boolean = false;
  propertyInfo = null;

  overviewData$ = new Subscription();
  overviewDataGrade$ = new Subscription();
  overviewDataGraph$ = new Subscription();
  residentialTypeData$ = new Subscription();
  commercialSizeData$ = new Subscription();
  transactionPropertyDetails$ = new Subscription();
  tableName:unknown;

  constructor(
    public sharedService: SharedService,
    private occupancyService: ReportOccupancyRentalBasicService,
    public reportService: ReportService,
    private storeService: StoreService,
    private alertService: AlertService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.sqm_unit = 'reports.' + GlobalContants.keySymbolContants.squareMeterUnit;
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
        this.isChart = false;
        this.storeService.clear(this.officeRentOption);
      }
    })
    //Update Column Monthly/Annual Properties Table
    if (this.sharedService.priceCalculation$() === this.sharedService.priceYearly) {
      const columnsToUpdate = [
        { header: 'rental_price', columnName: 'reports.annual_rental_rate' }
      ];
      //Residential Units - Grade wise Table 
      columnsToUpdate.forEach(column => {
        //Residential Units - Type wise
        const targetColumnResidentialType = this.residentialTypeColumns.find(x => x.header === column.header);
        if (targetColumnResidentialType) {
          targetColumnResidentialType.columnName = column.columnName;
        }
      });
    }
  }
  lastClickedTable(event:Event, table:any){
    this.tableName = table
  }
  ngOnInit() {
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.placeName = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.getReports();
    this.transactionPropertyDetails();
    this.isFirstTime = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.occupancyService.residentialTypePayload.pageNumber = 0;
      this.occupancyService.commercialSizePayload.pageNumber = 0;
      this.propertyInfo = null;
      this.showPropertyDetails = false;
      this.getReports();
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(): void {
    this.storeService.add(this.officeRentOption, this.selectedChartValue);
  }
  /**
   * All Rports api call
  */
  getReports() {
    this.getOverview();
    this.getOverviewGrade();
    this.getOverviewGraph();
    this.getResidentialTypeData();
    this.getcommercialSizeData();
  }
  /**
   * Property Details
   */
  transactionPropertyDetails() {
    this.transactionPropertyDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {

      if (data) {
        this.showPropertyDetails = true;
        this.propertyInfo = data;
      } else {
        this.showPropertyDetails = false;
        this.propertyInfo = null;
      }
    });
  }
  /**
   * Overview all type Data
   */
  getOverview() {
    this.overviewLoading = true;
    this.overviewData$ = this.occupancyService.getOverview().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.averageData = res.data.average_rate;
            this.averageData['untillDate'] = this.averageData.max_date ? moment.utc(this.averageData.max_date).local().format('DD MMM YYYY') : null;
            this.averageData['totalaverage_rate'] = this.reportService.calcPercentage(this.averageData.prev_avg_rent, this.averageData.avg_rent);

            this.occupancyData = res.data.occupacy_rate;
            this.occupancyData['untillDate'] = this.occupancyData.max_date ? moment.utc(this.occupancyData.max_date).local().format('DD MMM YYYY') : null;
            this.occupancyData['totaloccupancy_rate'] = this.reportService.calcPercentage(this.occupancyData.prev_occupacy_rate, this.occupancyData.occupacy_rate);

          }
          this.overviewLoading = false;
        },
        error: (error: any) => {
          this.overviewLoading = false;
        }
      }
    )
  }
  /**
   * Overview Grade Data
   */
  getOverviewGrade() {
    this.overviewGradeLoading = true;
    this.overviewDataGrade$ = this.occupancyService.getOverviewGrade().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            let overviewAverageRateData: any = { ...this.reportService.getPercentageOverview(res.data.average_rate) };
            this.averageGradeData = this.barSortData(overviewAverageRateData);
            let overviewOccupancyData: any = { ...this.reportService.getPercentageOverview(res.data.occupancy) };
            this.occupancyGradeData = this.barSortData(overviewOccupancyData);

            //Grade Total
            let averageObj: any = Object.fromEntries(this.averageGradeData);
            this.averageTotalGradeValue = this.reportService.calculateTotalGradeValue(averageObj);
            //Grade Total
            let occupancyObj: any = Object.fromEntries(this.occupancyGradeData);
            this.occupancyTotalGradeValue = this.reportService.calculateTotalGradeValue(occupancyObj);
          } else {
            this.averageGradeData = [];
            this.occupancyGradeData = [];
          }
          this.overviewGradeLoading = false;
        },
        error: (error: any) => {
          this.overviewGradeLoading = false;
        }
      }
    )
  }
  /**
   * Overview year chart
   */
  getOverviewGraph() {
    this.overviewGraphLoading = true;
    this.overviewDataGraph$ = this.occupancyService.getOverviewGraph().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.overviewChartData = res.data;
          let officeRentOption: any = this.storeService.get(this.officeRentOption);
          if (officeRentOption && officeRentOption?.length > 0) {
            this.selectedChartValue = officeRentOption;
          } else {
            // Select the first three keys by default
            this.selectedChartValue = Object.keys(this.overviewChartData).slice(0, 3);
          }
          // Transform the data
          Object.keys(this.overviewChartData).forEach((key) => {
            let data = this.overviewChartData[key];
            this.overviewChartData[key] = data.map((obj: any) => ({
              category: obj.year.toString(),
              value: obj.count,
              name: key
            }));
          });
          // Update the retail rental chart report
          this.retailRentalChartReport = Object.keys(this.overviewChartData)
            .filter(key => this.selectedChartValue.includes(key))
            .reduce((obj: any, key: any) => {
              obj[key] = this.overviewChartData[key];
              return obj;
            }, {});
          this.showMoreText();
          this.cdr.detectChanges();
        } else {
          this.overviewChartData = null;
        }
        this.overviewGraphLoading = false;
      },
      error: (error: any) => {
        this.overviewGraphLoading = false;
        this.overviewChartData = null;
      }
    });
  }
  /**
   * Residential Units - Type wise
   */
  getResidentialTypeData() {
    this.residentialTypeLoading = true;
    this.residentialTypeData$ = this.occupancyService.getResidentialType(this.occupancyService.residentialTypePayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.residentialTypeData = res.data;
            if (this.occupancyService.residentialTypePayload.pageNumber == 0) {
              this.residentialTypeCount = res.count;
              this.overViewMenu.map((e: any) => {
                if (e.name == OccupancyRentalBasicMenuEnum.residentialUnitsTypeWise) {
                  e['count'] = this.reportService.formatInternationNum(this.residentialTypeCount);
                }
              })
            }
          } else {
            this.residentialTypeCount = 0;
            this.residentialTypeData = [];
            this.overViewMenu.map((e: any) => {
              if (e.name == OccupancyRentalBasicMenuEnum.residentialUnitsTypeWise) {
                e['count'] = 0;
              }
            })
          }
          this.residentialTypeLoading = false;
        },
        error: (error: any) => {
          this.residentialTypeData = [];
          this.residentialTypeCount = 0;
          this.residentialTypeLoading = false;
        }

      }
    )
  }
  /**
   * Commercial Units - Size wise
   */
  getcommercialSizeData() {
    this.commercialSizeLoading = true;
    this.commercialSizeData$ = this.occupancyService.getCommercialSize(this.occupancyService.commercialSizePayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.commercialSizeData = res.data;
            if (this.occupancyService.commercialSizePayload.pageNumber == 0) {
              this.commercialSizeCount = res.count;
              this.overViewMenu.map((e: any) => {
                if (e.name == OccupancyRentalBasicMenuEnum.commercialUnitsSizeWise) {
                  e['count'] = this.reportService.formatInternationNum(this.commercialSizeCount);
                }
              })
            }
          } else {
            this.commercialSizeCount = 0;
            this.commercialSizeData = [];
            this.overViewMenu.map((e: any) => {
              if (e.name == OccupancyRentalBasicMenuEnum.commercialUnitsSizeWise) {
                e['count'] = 0;
              }
            })
          }
          this.commercialSizeLoading = false;
        },
        error: (error: any) => {
          this.commercialSizeData = [];
          this.commercialSizeCount = 0;
          this.commercialSizeLoading = false;
        }

      }
    )
  }
  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.occupancyRentalMenuEnum.residentialUnitsTypeWise) {
        this.occupancyService.residentialTypePayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getResidentialTypeData();
      } else if (report === this.occupancyRentalMenuEnum.commercialUnitsSizeWise) {
        this.occupancyService.commercialSizePayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getcommercialSizeData();
      }
    }
  }
  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
  }
  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }
  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.occupancyService.downloadInvestmentReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=investment_residential`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }
  /**
   *
   * @param data
   * @returns
   * Common horizontalbar sorting
   */
  barSortData(data: any) {
    let overviewArray: any = Object.entries(data);
    return overviewArray.sort((a: any, b: any) => b[1].category - a[1].category);
  }
  /**
   *
   * @param e
   * @param value
   * @returns
   * On clicking the dropdown, the chart will be added or removed
   */
  selectChart(e: any, value: any) {
    let index = this.selectedChartValue.indexOf(value)
    if (!e.checked) {
      if (this.selectedChartValue.length === 1) {
        this.alertService.warn(this.translate.instant('atLeastOneMust'));
        e.source.checked = true;
      } else {
        this.selectedChartValue.splice(index, 1);
      }
    } else {
      if (this.selectedChartValue.length > 2) {
        this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
        e.source.checked = false;
        return;
      } else {
        this.selectedChartValue.push(value)
      }
    }
    this.retailRentalChartReport = Object.keys(this.overviewChartData)
      .filter(key => this.selectedChartValue.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = this.overviewChartData[key];
        return obj;
      }, {});
    this.overviewGraphLoading = false;
    this.showMoreText();
  }
  showMoreText() {
    let firstEleCount: any = document.querySelector('.setext')?.textContent?.split(',')[0].length;
    if (this.selectedChartValue.length == 2 && firstEleCount > 4) {
      this.moreText = `(1) ${this.translate.instant('reports.more')}`;
    } else if (this.selectedChartValue.length == 3 && firstEleCount > 4) {
      this.moreText = `(2) ${this.translate.instant('reports.more')}`;
    } else if (this.selectedChartValue.length == 2 && firstEleCount < 4) {
      this.moreText = '';
    } else {
      this.moreText = `(1) ${this.translate.instant('reports.more')}`;
    }
  }
  getReportKeys(): string[] {
    if (this.overviewChartData) {
      let sortedS = Object.keys(this.overviewChartData);
      return this.sharedService.sortGrades(sortedS)
    }
    return [];
  }
  /**
   *
   * @param menu
   * if click menu scroll particular div
   */
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }
  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }
  selectedYear(data: any) {
    if (data.reportType === 'residentialType') {
      this.occupancyService.residentialTypePayload.pageNumber = 0;
      this.occupancyService.residentialTypePayload.year = data.year;
      this.getResidentialTypeData();
    } else if (data.reportType === 'commercialSize') {
      this.occupancyService.commercialSizePayload.pageNumber = 0;
      this.occupancyService.commercialSizePayload.year = data.year;
      this.getcommercialSizeData();
    }
  }
  ngOnDestroy() {
    this.overviewData$.unsubscribe();
    this.overviewDataGrade$.unsubscribe();
    this.overviewDataGraph$.unsubscribe();
    this.residentialTypeData$.unsubscribe();
    this.commercialSizeData$.unsubscribe();
    this.transactionPropertyDetails$.unsubscribe();
    this.storeService.clear(this.officeRentOption);
  }
}
