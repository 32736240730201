import { AfterViewInit, Component, Input, OnChanges, Output, SimpleChanges, ViewChild, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef, ViewContainerRef, Renderer2, effect } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sort, MatSort } from '@angular/material/sort';

import { ReportTableColumn, ReportTableDropdown, tableDropdown, } from '../../../reports-lists/models/reports.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, distinctUntilChanged, of } from 'rxjs';
import { ReportsViewEnum } from '../../../../models/enums/reports.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ContactSalesComponent } from 'src/app/common/components/contact-sales/contact-sales.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-datatable-expanded',
  templateUrl: './report-datatable-expanded.component.html',
  styleUrls: ['./report-datatable-expanded.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReportDatatableExpandedComponent implements OnChanges, AfterViewInit, OnInit, OnDestroy {

  public tableDataSource!: MatTableDataSource<any>;
  @Input() tableColumns: any = [];
  @Input() tableExpandColumns: any[] = [];
  @Input() dataLength: number = 0;
  @Input() allowExcelDownload: boolean = false;
  @Input() showEstaterText: boolean = false;
  @Input() showLoader: boolean = true;
  @Input() expandColumn: boolean = true;
  @Input() currentPageIndex: number = 0;
  @Input() dropDownOption: ReportTableDropdown | null = null;
  @Input() showTooltip: boolean = false;
  @Input() downloadLoading: any;
  @Input() isWarehouseUnit: boolean = false;
  @Input() hidePagination: boolean = false;

  dropDownError = false;
  selectedOptions: any = [];
  selectedDropdown: any = [];
  selectedRadio: any = '';
  colType: string = '';

  CurrencyCode: string = '';
  expandedElement: any = [];
  goToPageNumber: number = 1;
  previousPageNbr: number = 1;
  totalPages: number | any;
  expandbgColor = '';
  currentToggle: boolean = true;
  currentReportView: number | any;
  LockReport: boolean = true;
  selectedIndex: number | null = null;

  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  @Input() set resetPaginator(data: boolean) {
    if (this.matPaginator && data) {
      this.resetPageIndex();
    }
  }

  @Output() pageEvent: EventEmitter<PageEvent> = new EventEmitter();
  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() excelDownload: EventEmitter<boolean> = new EventEmitter();
  @Output() dropDownValue: EventEmitter<boolean> = new EventEmitter();


  displayedColumns: string[] = [];
  dataSource: any[] = [];
  allowDownload: boolean = false;
  currentReportData: any = null;
  setColor = '';

  @ViewChild(MatPaginator) matPaginator: MatPaginator | any;
  @ViewChild(MatSort) matSort!: MatSort;

  startIndex: number = 1;
  maxIndexValue: number = 20;
  endIndex: number = 20;
  current_lang: any = 'en';
  queryParams: any = '';
  tenantLoading: boolean = false;
  languageSub$: Subscription = new Subscription();

  constructor(
    public reportService: ReportService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private mapService: ReportMapService, public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private elementRef: ViewContainerRef,
    private ren: Renderer2,
    private _decimalPipe: DecimalPipe,
    private matdailog: MatDialog,
    private route: ActivatedRoute
  ) {
    effect(() => this.selectedIndex = this.mapService.selectedIndex())
    this.languageSub$ = this.sharedService.language$.subscribe((lang) => {
      this.current_lang = lang;
      this.CurrencyCode = this.translate.instant(this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode));
    });
    /**
     * year dropdown click trigger subscribe
     */
    this.sharedService.yearTrigger.subscribe((res: any) => {
      if (res) {
        this.startIndex = 1;
        this.endIndex = Math.min(this.maxIndexValue, this.dataLength);
      }
    })
  }

  ngOnInit(): void {
    this.dropDownOption?.data.forEach((data: tableDropdown) => {
      this.selectedOptions.push(data.id)
    })
    this.route.queryParams.subscribe((param: any) => {
      this.queryParams = param;
    })
    this.currentReportData = this.reportService.getCurrentReport();
    this.setDownloadAccess();
    this.toggleReport()
    this.CurrencyCode = this.translate.instant(this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.LockReport = this.reportService.getLockReport();
    if ((changes['tableData']?.previousValue?.length <= 0 || !changes['tableData']?.previousValue) && !changes['showLoader']?.previousValue) {
      this.endIndex = Math.min(this.maxIndexValue, this.dataLength);
    }
    if (changes['dataLength'] && changes['dataLength']?.currentValue) {
      this.goToPageNumber = 1;
      this.startIndex = 1;
      this.endIndex = Math.min(this.maxIndexValue, changes['dataLength']?.currentValue);
    }
    if (changes['tableColumns']?.currentValue) {
      this.setTableColumn();
    }
  }
  setDownloadAccess() {
    let subscription = this.reportService.userSubscription.find((sub: any) => sub.report_id === this.currentReportData?.id);
    if (subscription) {
      this.allowDownload = subscription.allow_download && this.currentReportData?.is_download;
    }
  }

  ngAfterViewInit(): void {
    this.matSort.disableClear = true;
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;

  }

  setTableColumn() {
    this.displayedColumns = this.tableColumns.filter((tableColumns: ReportTableColumn) => !tableColumns.hideColumn)
      .map((tableColumn: ReportTableColumn) => tableColumn.header);
  }

  setTableDataSource(data: any): void {
    // lock table rows
    this.LockReport = this.reportService.getLockReport();
    if (this.LockReport) {
      data = data.slice(0, 3);

      for (let i = 0; i < 5; i++) {
        let temp: any = {};
        this.tableColumns.map((e: any) => {
          temp[e.field] = e.convertNumber ? 1111111 : 'No data'
          temp['NODATA'] = true;
        })
        data.push(temp);
      }
      this.dataSource = data;
    } else {
      this.dataSource = data;
    }
    // lock table rows


    this.tableDataSource = new MatTableDataSource<any>(data);
    //Add Element between arrows
    this.cdr.detectChanges();
    if (this.matPaginator) {
      this.totalPages = this.matPaginator.getNumberOfPages();
      this.addGoToPageInput();
    }

    if (this.expandColumn) {
      // Transform data
      let parentIndex = 1; // Initialize a counter for isParent objects
      const transformedData: any = data.flatMap((item: any) => {
        const parentObject = {
          ...item, isParent: true, expand: false, isDetail: item?.details?.length,
          bgColor: parentIndex % 2 === 1 ? '#F5F5F5' : '#FFFFFF', // Assign bgColor based on odd/even
        }; // Mark the parent object
        parentIndex++; // Increment the counter for isParent objects
        delete parentObject.details; // Remove details from parent object
        let detailsArray: any[] = []
        if (item?.details?.length > 0) {
          detailsArray = item.details.map((detail: any) => ({
            ...detail,
            isParent: false, // Mark as detail object
            isOpen: 1,
            bgColor: 'rgb(235, 241, 255)',
          }));
        }
        return [parentObject, ...detailsArray];
      });
      this.tableDataSource.data = transformedData;
    }

  }
  onParentClick(parentIndex: number): void {
    if (this.tableDataSource.data[parentIndex].isParent) {
      // Toggle the expand state of the clicked parent
      const isCurrentlyOpen = this.tableDataSource.data[parentIndex].expand;
      // Iterate through the data and collapse all children first
      for (let j = 0; j < this.tableDataSource.data.length; j++) {
        if (!this.tableDataSource.data[j].isParent) {
          this.tableDataSource.data[j].isOpen = 1; // Collapse all children
        }
      }
      // Expand or collapse the children of the clicked parent
      let i = parentIndex + 1;
      while (i < this.tableDataSource.data.length && !this.tableDataSource.data[i].isParent) {
        this.tableDataSource.data[i].isOpen = isCurrentlyOpen ? 2 : 1;
        i++;
      }
    }
  }
  isChildOpen(ele: any, pIndex: number) {
    const currentExpand = ele.expand;
    for (let j = 0; j < this.tableDataSource.data.length; j++) {
      if (this.tableDataSource.data[j].isParent) {
        this.tableDataSource.data[j].expand = false; // Collapse all children
      }
    }
    ele.expand = !currentExpand;
    //Click to update element collapse
    this.onParentClick(pIndex)
    // Trigger change detection to update the UI
    this.tableDataSource.data = [...this.tableDataSource.data];
  }
  /**
   * Appended the input and total page number between the left and right arrows
   */
  addGoToPageInput() {
    const actionContainer = this.elementRef.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');
    const nextButtonDefault = this.elementRef.element.nativeElement.querySelector('button.mat-mdc-paginator-navigation-next');
    let bubbleContainerRef: any = this.ren.createElement('div') as HTMLElement;
    this.ren.addClass(bubbleContainerRef, 'g-bubble-container');
    this.ren.insertBefore(actionContainer, bubbleContainerRef, nextButtonDefault);
    bubbleContainerRef.appendChild(this.elementRef.element.nativeElement.querySelector('.go-to-page'));
  }

  onPageChange(event: any) {
    this.pageEvent.emit(event)
    this.startIndex = event.pageIndex * event.pageSize + 1;
    this.endIndex = Math.min((event.pageIndex + 1) * event.pageSize, event.length);
    this.goToPageNumber = (event.pageIndex || 0) + 1;
  }
  /**
   * An API call is made when entering a number in the pagination input
   */
  onGoToPage() {
    if (this.goToPageNumber > this.totalPages || this.goToPageNumber <= 0) {
      this.goToPageNumber = this.previousPageNbr;
      return;
    }
    this.previousPageNbr = this.goToPageNumber;
    this.matPaginator.pageIndex = this.goToPageNumber - 1;
    const event: PageEvent = {
      length: this.matPaginator.length,
      pageIndex: this.matPaginator.pageIndex,
      pageSize: this.matPaginator.pageSize
    };
    this.goToPageNumber = (event.pageIndex || 0) + 1;
    this.onPageChange(event);
  }

  onSortTable(event: any) {
    if (this.tableDataSource.data?.length === 0 || !this.tableDataSource?.data) return;
    if (this.selectedOptions) {
      event = { ...event }
      event['selectedOptions'] = this.selectedOptions
      this.sort.emit(event)
    } else {
      this.sort.emit(event)
    }
    //click to sorting reset pagenumber & index
    this.goToPageNumber = 1;
    this.startIndex = 1;
    this.endIndex = Math.min(this.maxIndexValue, this.dataLength);
    this.resetPageIndex();
  }

  downloadExcel() {
    let subscription = this.reportService.userSubscription.find((sub: any) => sub.report_id === this.currentReportData?.id);
    if (subscription) {
      this.allowDownload = subscription.allow_download && this.currentReportData?.is_download;
    }
    if (!this.allowDownload) {
      this.alertService.warn(this.translate.instant("reports.disableDownloadAccessMsg"));
      return;
    }
    this.excelDownload.emit(true);
  }
  // lastParams:any;
  async panToMap(value: any, columnName: any, index?: any, type: string = '') {
    this.colType = type;
    if (type) this.selectedIndex = index;
    this.reportService.reportView$.next(ReportsViewEnum.mapGridView);
    //   if (JSON.stringify(value) === JSON.stringify(this.lastParams)) {
    //     return;
    // }
    // this.lastParams = value;
    let layer_data = this.reportService.getCurrentReport();


    if (value?.lat && type != 'layout') {
      if ((value.neigh_name || value.neighbourhood || value.neighborhood) && columnName !== 'transaction_date' && columnName !== 'property_name') {
        let layerdat = layer_data?.map_layer?.filter((r: any) => r.layer_name == "neighbourhoods")[0];
        if (layerdat) {
          layerdat.display = true;
        }
        this.mapService.overlay_mapLayer.next([layerdat]);
        this.mapService.mapCoordinates.next({ coordinates: [value.lat, value.lon], zoom: layerdat?.maxZoom, uid: value?.property_uid });
      }
      if (value.project_name && columnName !== 'transaction_date' && columnName !== 'property_name') {
        let layerdat = layer_data?.map_layer?.filter((r: any) => r.layer_name == "project_layer")[0];
        if (layerdat) layerdat.display = true;

        this.mapService.overlay_mapLayer.next([layerdat]);
        this.mapService.mapCoordinates.next({ coordinates: [value.lat, value.lon], zoom: layerdat?.maxZoom, uid: value?.property_uid });
      }
      //On clicking the transaction_date column, only the map will pan.
      if (columnName === 'transaction_date' || columnName === 'p_property_name' || columnName === 'property_name'
        || columnName == 'tenant_name' || columnName == 'unit_number'
      ) {
        let uid = value?.property_uid || value?.p_property_uid;
        this.tenantLoading = true;
        try {
          const propertyData: any = await this.mapService.getParcelInfo(uid);
          this.tenantLoading = false;
          if (propertyData.status == 200 && propertyData?.data?.length) {
            let properties = propertyData.data[0];
            this.mapService.mapCoordinates.next({ coordinates: [value.lat, value.lon], zoom: 20, uid: uid, layer_name: 'property', data: properties });
          } else {
            this.alertService.warn(this.translate.instant("reports.no_record_found"));
          }
        } catch (error) {
          this.tenantLoading = false;
          this.alertService.warn("Something went Wrong!, Try Again");
        }
      }
    }
    if (type == 'showOnMap' && value?.tenants_lat_lon) {
      if (value?.shop_name) {
        this.tenantLoading = true;
        this.reportService.retailInventoryTenantDetails(value.shop_name).subscribe((item: any) => {
          if (item.status == 200) {
            this.tenantLoading = false;
            this.mapService.mapCoordinates.next({ data: item.data, multiLatLon: true, tenant_name: value?.tenant_name });
          } else { this.alertService.warn(this.translate.instant("reports.no_record_found")); this.tenantLoading = false };
        })
      } else {
        this.alertService.warn(this.translate.instant("reports.no_record_found"));
      }
      // this.mapService.mapCoordinates.next({coordinates:value?.tenants_lat_lon, multiLatLon: true, tenant_name: value?.tenant_name});
    }
    if (type == 'layout') {
      this.reportService.reportView$.next(ReportsViewEnum.mapGridView);
      this.mapService.propertyinfotoggle.next(true);
      this.mapService.selected_propertydetails.next(value);
      this.reportService.showTenantMap$.next(value);
    }
  }

  resetPageIndex() {
    if (this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
  }

  selectRadioValue(value: any, name: any) {
    this.selectedRadio = name;
    this.dropDownValue.emit(value)
  }

  isCharNumber(value: any) {
    const toolTipValue = typeof value === 'string' ? value : this._decimalPipe.transform(value, '1.0-1');
    return `${toolTipValue} ${this.translate.instant(this.CurrencyCode)}/${this.translate.instant(GlobalContants.keySymbolContants.squareMeterUnit)}`;
  }

  setExpadndColor(index: number) {
    //  this.expandbgColor = index % 2 === 0 ? '#F5F5F5' : '#FFFFFF';
    this.expandbgColor = '#EBF1FF';
  }


  ngOnDestroy(): void {
    this.languageSub$?.unsubscribe();
  }

  toggleReport() {
    this.reportService.reportQueryToggle$.subscribe((toggle: boolean) => {
      this.currentToggle = toggle;

    });
    this.reportView()
  }
  reportView() {
    this.reportService.reportView$.subscribe(view => {
      this.currentReportView = view;
    })
  }

  valueDataType(type: number) {
    return this.sharedService.reportDataValueType(type)
  }

  /**
   * This is a fuction to open Contact Sales Form
   */
  openContactSales() {
    this.matdailog.open(ContactSalesComponent, {
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type: 'contact_us' }
    })
  }

  selectCheckboxValue(event: any, id: string, value: string) {
    if (event.checked) {
      this.selectedOptions.push(id);
    } else {
      this.selectedOptions = this.selectedOptions.filter((optionId: any) => optionId !== id);
    }
    this.dropDownValue.emit(this.selectedOptions);
  }
}
