import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { OfficeSpaceRentalMenuEnum } from "./report-office-space-renta.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class ReportOfficeSpaceRentalConstants{
    static overViewMenu = [
        {
            label: 'reports.overview',
            name: OfficeSpaceRentalMenuEnum.overview,
            count:null
        },
        {
            label: 'reports.rentUnitSizeWise',
            name: OfficeSpaceRentalMenuEnum.rentUnitSizeWise,
            count:null
        },
        {
            label: 'reports.projectsProperties',
            name: OfficeSpaceRentalMenuEnum.properties,
            count:null
        }
    ]
    //Properties Range Column
    static propertiesColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'count_of_floors',
            columnName: 'reports.no_of_floor',
            field: 'count_of_floors',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'property_grade',
            columnName: 'property_grade',
            field: 'property_grade',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'permissible_far',
            columnName: 'Permissible_p',
            field: 'permissible_far',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'actual_far',
            columnName: 'Actual_FAR_p',
            field: 'actual_far',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'gross_leasable_area_office',
            columnName: 'reports.gross_leasable_area',
            field: 'gross_leasable_area_office',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.officeSqmBracket,
        },
        {
            header: 'gross_leasable_area_retail',
            columnName: 'reports.gross_leasable_area',
            field: 'gross_leasable_area_retail',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.retailSqmBracket,
        },
        {
            header: 'lease_rate_avg_office',
            columnName: 'reports.lease_rate',
            field: 'lease_rate_avg_office',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.officeCurrencySqm,
        },
        {
            header: 'lease_rate_avg_retail',
            columnName: 'reports.lease_rate',
            field: 'lease_rate_avg_retail',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.retailCurrencySqm,
        },
        {
            header: 'property_type',
            columnName: 'reports.property_type',
            field: 'property_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'p_property_uid',
            columnName: 'reports.property_uid',
            field: 'p_property_uid',
            isSortable: true,
            border: false,
            isContainsHTML: false
        }
    ]
    //Properties Average Column
    static propertiesAverageColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'count_of_floors',
            columnName: 'reports.no_of_floor',
            field: 'count_of_floors',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'property_grade',
            columnName: 'property_grade',
            field: 'property_grade',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'permissible_far',
            columnName: 'Permissible',
            field: 'permissible_far',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'actual_far',
            columnName: 'Actual FAR',
            field: 'actual_far',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'gross_leasable_area_office',
            columnName: 'reports.gross_leasable_area',
            field: 'gross_leasable_area_office',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.officeSqmBracket,
        },
        {
            header: 'gross_leasable_area_retail',
            columnName: 'reports.gross_leasable_area',
            field: 'gross_leasable_area_retail',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.retailSqmBracket,
        },
        {
            header: 'lease_rate_avg_office',
            columnName: 'reports.lease_rate',
            field: 'lease_rate_avg_office',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.officeCurrencySqm,
        },
        {
            header: 'lease_rate_avg_retail',
            columnName: 'reports.lease_rate',
            field: 'lease_rate_avg_retail',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.retailCurrencySqm,
        },
        {
            header: 'property_type',
            columnName: 'reports.property_type',
            field: 'property_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'p_property_uid',
            columnName: 'reports.property_uid',
            field: 'p_property_uid',
            isSortable: true,
            border: false,
            isContainsHTML: false
        }
    ]
    /**
     * Range Data
     */
    static rentUnitSizeColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'grade_a+_range',
            columnName: 'reports.grade_a+',
            field: 'grade_a+_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            toolTipField:'avg_grade_a+',
        },
        {
            header: 'grade_a_range',
            columnName: 'reports.grade_a',
            field: 'grade_a_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            toolTipField:'avg_grade_a',
        },
        {
            header: 'grade_b_range',
            columnName: 'reports.grade_b',
            field: 'grade_b_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            toolTipField:'avg_grade_b',
        },
        {
            header: 'grade_c_range',
            columnName: 'reports.grade_c',
            field: 'grade_c_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'avg_grade_c',
        }
    ]
    static unitsizeExpandColumns: any[] = [
        {field : 'neighbourhood'},
        {field : 'grade_a+_range'},
        {field : 'grade_a_range'},
        {field : 'grade_b_range'},
        {field : 'grade_c_range'}
    ];
    /**
     * Average Data
     */
    static retailUnitsizeAverageColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'avg_grade_a+',
            columnName: 'reports.grade_a+',
            field: 'avg_grade_a+',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_a+_range',
        },
        {
            header: 'avg_grade_a',
            columnName: 'reports.grade_a',
            field: 'avg_grade_a',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_a_range'
        },
        {
            header: 'avg_grade_b',
            columnName: 'reports.grade_b',
            field: 'avg_grade_b',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_b_range'
        },
        {
            header: 'avg_grade_c',
            columnName: 'reports.grade_c',
            field: 'avg_grade_c',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_c_range'
        }
    ]
    static unitsizeExpandAverageColumns: any[] = [{field : 'neighbourhood', convertNumber: false},{field : 'avg_grade_a+', convertNumber: true},{field :'avg_grade_a', convertNumber: true},{field :'avg_grade_b', convertNumber: true},{field :'avg_grade_c',  convertNumber: true}];
    static unitSizeDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1,
        reportType : false
    }
    static propertiesDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1,
        reportType : false
    }
}
