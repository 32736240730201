

<app-reports-container
    [tableColumn]="totalColumnReportColumn"
    [dataValue]="totalColumnReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>




<app-reports-container
    [dataValue]="retailFnbCategoryWiseReport"
    [reportType]="chartView.pieChart"
    [chartName]="'retailFandBCategoryWise'"
    [loading]="retailFandBCategoryWiseLoading"
    [title]="'reports.retailFandBCategoryWise'"
    [yearDropdown]="true"
    [yearReportType]="reports.retailFandBCategoryWise"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<app-reports-container
    [dataValue]="retailFnbCuisineWiseSplitAreaReport"
    [reportType]="chartView.pieChart"
    [chartName]="'retailFandBCuisineWise'"
    [loading]="retailFandBCuisineWiseLoading"
    [title]="'reports.retailFandBCuisineWise'"
    [yearDropdown]="true"
    [yearReportType]="reports.retailFandBCuisineWise"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<app-reports-container
    [tableColumn]="averageLeasetableColumns"
    [dataValue]="averageLeasetableData"
    [isDownloadExcel]="true"
    [loading]="averageLeasetableDataLoading"
    [reportName]="'reports.retailFandBAverageLease'"
    [title]="'reports.retailFandBAverageLease'"
    [expandable]="false"
    [yearDropdown]="true"
    [yearReportType]="reports.retailFandBAverageLease"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<app-reports-container
    [tableColumn]="trendAreaOccupiedtableColumns"
    [dataValue]="trendAreaOccupiedtableData"
    [isDownloadExcel]="true"
    [loading]="trendAreaOccupiedtableDataLoading"
    [reportName]="'reports.retailFandBTrendArea'"
    [title]="'reports.retailFandBTrendArea'"
    [expandable]="false"
    [yearDropdown]="true"
    [yearReportType]="reports.retailFandBTrendArea"
    (yearSelected)="selectedYear($event)"
></app-reports-container>
