<div class="report-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <app-report-sub-menu 
    [menuList]="overViewMenu"
    (menuChangeValue)="menuChangeValue($event)"
></app-report-sub-menu>

    <div id="{{ propertyDetailMenuEnum.overview}}" class="report-tabsection" >
        <app-report-property-info [propertyInfo]="propertyInfo"></app-report-property-info>

        
    </div>
  


    <!--Built up Information-->
    <div class="report-tabsection"  id="{{ propertyDetailMenuEnum.builtInformation}}" [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.builtInformation}">
        <app-build-up-info [propertyInfo]="propertyInfo"></app-build-up-info>

    </div>
    <!--Built up Information-->

    
    <!--Construction-->
    <!-- <div id="{{ propertyDetailMenuEnum.costOfconstruction}}">
        <app-cost-of-construction [propertyInfo]="propertyInfo"></app-cost-of-construction>

    </div> -->

    <!--Construction-->

    <!--Amenities-->
    <div class="report-tabsection"  id="{{ propertyDetailMenuEnum.amentities}}" [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.amentities}">
        <app-property-amenities (headerCount)="setCount($event, propertyDetailMenuEnum.amentities)" [propertyInfo]="propertyInfo"></app-property-amenities>

    </div>
    
    <!--Amenities-->

    <!--Residential Units-->
    <div class="report-tabsection"   id="{{ propertyDetailMenuEnum.unit}}" [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.unit}">

      <app-residential-units (headerCount)="setCount($event, propertyDetailMenuEnum.unit)" [propertyInfo]="propertyInfo"></app-residential-units>

      <app-land-value-details [propertyInfo]="propertyInfo"></app-land-value-details>

    </div>    
    <!--Residential Units-->

    <!-- Units Trasaction-->
    <div  class="report-model report-tabsection"  id="{{ propertyDetailMenuEnum.unitTransaction}}" [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.unitTransaction}">

        <app-unit-transaction (headerCount)="setCount($event, propertyDetailMenuEnum.unitTransaction)" [propertyInfo]="propertyInfo"></app-unit-transaction>
    </div>
    <!--Units Trasaction-->

    <div class="report-model report-tabsection"  id="{{ propertyDetailMenuEnum.projectTransaction}}" [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.projectTransaction}">
        <app-property-transaction (headerCount)="setCount($event, propertyDetailMenuEnum.projectTransaction)" [propertyInfo]="propertyInfo"></app-property-transaction>
        
      
    </div>

    <div class="report-model report-tabsection"  id="{{ propertyDetailMenuEnum.rentalIncome}}" [ngClass]="{ 'scroll': currentScroll === propertyDetailMenuEnum.rentalIncome}">
        <app-residential-rentalincome-assessement [propertyInfo]="propertyInfo"></app-residential-rentalincome-assessement>
        
      
    </div>

 </div>    