<header class="header" [ngClass]="{ 'landing-header': landingheader }">
  <div class="estater-logo" *ngIf="landingheader !=  true">
    <img routerLink="/" src="assets/images/landing_page/logoArb.svg" *ngIf="currentLangID == 2; else defaultLogo"
      alt="Estater Markets">
    <ng-template #defaultLogo>
      <img routerLink="/" src="assets/images/landing_page/logo.svg" alt="Estater Markets">
    </ng-template>
  </div>
  <ng-container *ngIf="landingheader && isMobile">
    <img class="hammer-menu" (click)="drawer.toggle()" *ngIf="!opened"
    src="assets/images/landing_page/menu-mobile.svg" alt="menu">
      <mat-icon *ngIf="opened" (click)="drawer.toggle()">close</mat-icon>
  </ng-container>
  <div class="estater-logo" *ngIf="landingheader ==  true">
    <img src="assets/images/landing_page/logoArb.svg" *ngIf="currentLangID == 2; else defaultLogo" alt="Estater Markets">
    <ng-template #defaultLogo>
      <img src="assets/images/landing_page/logo.svg" alt="Estater Markets">
    </ng-template>
  </div>
  <div class="language">
    <span></span>
    <span>
      <ng-template [ngIf]="langList > 1 && landingheader != true">
        <span class="pointer" *ngIf="currentLangID == 2 && returnVal(currentLangID)" (click)="selectLang(1)">
          <img src="../../../../assets/images/en.svg" alt="en"></span>
        <span class="pointer" *ngIf="currentLangID == 1 && returnVal(currentLangID)" (click)="selectLang(2)">
          <img src="../../../../assets/images/ar.svg" alt="ar"></span>
      </ng-template>
    </span>
    <span>
      <ng-template [ngIf]="langList > 1 && landingheader == true && !isMobile">
        <span class="pointer" *ngIf="currentLangID == 2 && returnVal(currentLangID)" (click)="selectLang(1)">
          <img src="assets/images/landing_page/lang-en.svg" alt="en"></span>
        <span class="pointer" *ngIf="currentLangID == 1 && returnVal(currentLangID)" (click)="selectLang(2)">
          <img src="assets/images/landing_page/lang-arb.svg" alt="ar"></span>
      </ng-template>
    </span>
    <div class="header_btn" *ngIf="landingheader ==  true && !isMobile">
      <button type="button" [routerLink]="['/login']">{{'User.Login' | translate}}</button>
      <button type="button" class="btn-get-started" [routerLink]="['/sign-up']">
        {{'landing.get_started' | translate}}
        <img src="assets/images/landing_page/uparrow.svg" alt="Arrow" class="arrow-icon arrow-rotate" />
      </button>
    </div>
  </div>
</header>


<mat-drawer-container [hasBackdrop]="false">
  <mat-drawer #drawer class="mat-drawer-left header_btn" [(opened)]="opened">
    <div class="sidebar-btngrp">
      <ng-template [ngIf]="langList > 1">
        <span class="pointer lanbtn" *ngIf="currentLangID == 2" (click)="selectLang(1)">
          <img src="assets/images/landing_page/lang-en.svg" alt="en"></span>
        <span class="pointer lanbtn" *ngIf="currentLangID == 1" (click)="selectLang(2)">
          <img src="assets/images/landing_page/lang-arb.svg" alt="ar"></span>
      </ng-template>
      <button type="button" class="btn-login-landing" [routerLink]="['/login']">{{'User.Login' | translate}}</button>
      <button type="button" class="btn-get-started" [routerLink]="['/sign-up']">
        {{'landing.get_started' | translate}}
        <img src="assets/images/landing_page/uparrow.svg" alt="Arrow" class="arrow-icon arrow-rotate" />
      </button>
    </div>
    <footer class="footer-position ">
      <ul class="footer-list footer_social">
        <li><a href="https://www.facebook.com/people/Estatercom/100070864224953/" target="_blank"><img
              src="assets/images/landing_page/facebook-logo.svg" alt="Facebook"></a></li>

        <li><a href="https://www.linkedin.com/company/estater/about/" target="_blank"><img
              src="assets/images/landing_page/linkedin.svg" alt="linkedin"></a></li>

        <li><a href="https://www.instagram.com/the.real.estater?igsh=dGFwOWl2cjc0emxk" target="_blank"><img
              src="assets/images/landing_page/social-media.svg" alt="Instagram"></a></li>

        <li><a href="https://www.youtube.com/@TheRealEstater" target="_blank"><img
              src="assets/images/landing_page/play.svg" alt="youtube"></a></li>

        <li><a href="https://twitter.com/thereal_estater" target="_blank"> <img
              src="assets/images/landing_page/twitter.svg" alt="Twitter"></a></li>


      </ul>
      <div class="contact-info">
        <img src="assets/images/landing_page/mail.svg" alt="Email Icon">
        <span>{{'landing.contact_us' | translate}} <a href="mailto:sales@estater.com">{{'sales@estater.com'}}</a></span>
      </div>
    </footer>
  </mat-drawer>
</mat-drawer-container>