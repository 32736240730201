import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportInventoryService } from './report-inventory.service';
import { ReportsListsService } from '../../services/reports-lists.service';
import { InventoryReportConstant } from './report-inventory.constants';
import { ReportChartColumn, ReportTableColumn, ReportPreview } from '../../models/reports.model';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-inventory',
  templateUrl: './report-inventory.component.html',
  styleUrls: ['./report-inventory.component.scss']
})
export class ReportInventoryComponent implements OnInit, OnChanges {

  @Input() params: any;

  chartView = ReportsChartEnum;

  numberOfUnitsPropertyGradeWiseReport: ReportPreview = InventoryReportConstant.numberOfUnitsPropertyGradeWise;

  totalInventoryReport: any = [];
  totalInventoryLoading: boolean = false;
  totalInventoryReportColumn: ReportTableColumn[] = InventoryReportConstant.totalSummaryColumn;
  unitTypeReportTableColumn: ReportTableColumn[] = InventoryReportConstant.unitTypetableColumns;
  unitTypeData: any = [];
  unitTypeDataLoading: boolean = false;
  unitGradeReportTableColumn: ReportTableColumn[] = InventoryReportConstant.unitGradetableColumns;
  propertyTypetableLoading: boolean = false;
  propertyTypetableColumns: ReportTableColumn[] = InventoryReportConstant.propertyTypetableColumns;
  unitGradeData: any = [];
  unitGradeReportLoading: boolean = false;
  propertyTypeData: any = [];
  investmentPropertyReport: ReportChartColumn[] = [];
  investmentPropertyLoading: boolean = false;
  investmentUnitReport: ReportChartColumn[] = [];
  investmentUnitLoading: boolean = false;
  
  isFirstTime = true;

  constructor(
    private reportInventoryService: ReportInventoryService,
    private reportsListsService: ReportsListsService,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  getReports() {
    this.getTotalInventoryReport();
    this.getInvestmentPropertyReport();
    this.getInvestmentUnitReport();
    this.getPropertyTypeReport();
    this.getUnitGradeReport();
    this.getUnitTypeReport();
  }

  getTotalInventoryReport(year = null) {
    this.totalInventoryLoading = true;
    this.reportInventoryService.getTotalInventoryReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200 && res.data.length > 0) {
            this.totalInventoryReportColumn = [...this.reportsListsService.yearColumnAppend(InventoryReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
            this.totalInventoryReport = this.reportsListsService.summaryReportConversion(res.data, InventoryReportConstant.totalInventorColumn)

          } else {
            this.totalInventoryReport = [];
          }
          this.totalInventoryLoading = false;
        },
        error: (error: any) => {
          this.totalInventoryReport = [];
          this.totalInventoryLoading = false;
        }
      }
    )
  }

  getInvestmentPropertyReport() {
    this.investmentPropertyLoading = true;
    this.reportInventoryService.getInvestmentPropertyReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.investmentPropertyReport = res.data.map((data: any) => {
              const obj = {
                category: data.year.toString(),
                value: data.total_property
              }
              return obj;
            })
          } else {
            this.investmentPropertyReport = [];
          }
          this.investmentPropertyLoading = false;
        },
        error: (error: any) => {
          this.investmentPropertyReport = [];
          this.investmentPropertyLoading = false;
        }
      }
    )
  }

  getInvestmentUnitReport() {
    this.investmentUnitLoading = true;
    this.reportInventoryService.getInvestmentUnitReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.investmentUnitReport = res.data.map((data: any) => {
              const obj = {
                category: data.year.toString(),
                value: data.total_units
              }
              return obj;
            })
          } else {
            this.investmentUnitReport = [];
          }
          this.investmentUnitLoading = false;
        },
        error: (error: any) => {
          this.investmentUnitReport = [];
          this.investmentUnitLoading = false;
        }
      }
    )
  }

  getPropertyTypeReport() {
    this.propertyTypetableLoading = true;
    this.reportInventoryService.getPropertyReport().subscribe(
      {
        next: (res: any) => {
          this.propertyTypetableLoading = false;
          if (res && res.status == 200) {
            let column = ['neigh_name', 'category'];
            // this.numberOfUnitsPropertyGradeWiseReport.table.column = InventoryReportConstant.propertyTypetableColumns;
            if (res?.data?.year.length > 0) {
              this.propertyTypetableColumns = [...this.reportsListsService.yearColumnAppend(InventoryReportConstant.propertyTypetableColumns, res?.data.year)];

              // this.numberOfUnitsPropertyGradeWiseReport.table.column = [...this.reportsListsService.yearColumnAppend(InventoryReportConstant.propertyTypetableColumns, res?.data.year)];
            }
            this.propertyTypeData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);
            // this.numberOfUnitsPropertyGradeWiseReport.originalData = res?.data?.data;
            // this.numberOfUnitsPropertyGradeWiseReport.currentData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);

          } else {
            // this.numberOfUnitsPropertyGradeWiseReport.originalData = [];
            this.propertyTypeData = [];
          }
        },
        error: (error: any) => {
          this.numberOfUnitsPropertyGradeWiseReport.originalData = [];
          this.propertyTypetableLoading = false;
        }
      }
    )
  }

  getUnitGradeReport() {
    this.unitGradeReportLoading = true;
    this.reportInventoryService.getUnitGradeReport().subscribe(
      {
        next: (res: any) => {
          this.unitGradeReportLoading = false;
          if (res && res.status == 200) {
            let column = ['neigh_name', 'category'];
            if (res?.data?.year.length > 0) {
              this.unitGradeReportTableColumn = [...this.reportsListsService.yearColumnAppend(InventoryReportConstant.unitGradetableColumns, res?.data.year)];
            }
            this.unitGradeData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);
          } else {
            this.unitGradeData = [];
          }
        },
        error: (error: any) => {
          this.unitGradeData = [];
          this.unitGradeReportLoading = false;
        }
      }
    )
  }

  getUnitTypeReport() {
    this.unitTypeDataLoading = true;
    this.reportInventoryService.getUnitTypeReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['neigh_name', 'category'];
            if (res?.data?.year.length > 0) {
              this.unitTypeReportTableColumn = [...this.reportsListsService.yearColumnAppend(InventoryReportConstant.unitTypetableColumns, res?.data.year)];
            }
            this.unitTypeData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);
          } else {
            this.unitTypeData = [];
          }
          this.unitTypeDataLoading = false;
        },
        error: (error: any) => {
          this.unitTypeData = [];
          this.unitTypeDataLoading = false;
        }
      }
    )
  }

  selectedYear(data: any) {
    this.getTotalInventoryReport(data.year);
  }

}
