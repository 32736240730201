import { DataType } from "src/app/common/models/enums/common.enum";
import { ReportTableColumn } from "../../models/reports.model";
import { OfficeInventoryMenuEnum } from "./report-office-inventory.enum";

export class ReportOfficeInventoryConstants{
    static overViewMenu = [
        {
            label: 'reports.overview',
            name: OfficeInventoryMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.neighborhood',
            name: OfficeInventoryMenuEnum.neighborhood,
            count: null
        },
        {
            label: 'reports.projectsProperties',
            name: OfficeInventoryMenuEnum.properties,
            count: null
        }
    ]
    static neighborhoodColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'properties',
            columnName: 'reports.projectsProperties',
            field: 'properties',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.countWithBracket,
            isBreak: true
        },
        {
            header: 'office_space',
            columnName: 'reports.officeSpace',
            field: 'office_space',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
            isBreak: true
        },
        {
            header: 'retail_space',
            columnName: 'reports.retailSpace',
            field: 'retail_space',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
            isBreak: true
        },
        {
            header: 'other_commercial_space',
            columnName: 'reports.otherCommercialSpace',
            field: 'other_commercial_space',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'avgOfficeLeaseRate',
            columnName: 'reports.avgOfficeLeaseRate',
            field: 'average_office_lease_rate',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'avgRetailLeaseRate',
            columnName: 'reports.avgRetailLeaseRate',
            field: 'average_retail_lease_rate',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'averageOtherCommercialLeaseRate',
            columnName: 'reports.averageOtherCommercialLeaseRate',
            field: 'average_other_commercial_lease_rate',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'totalLandArea',
            columnName: 'reports.totalLandArea',
            field: 'total_land_area',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true,
            symbol: DataType.squareMeterWithBracket,
        }
    ]
    static neighborhoodExpandColumns: any[] = [
        { field : 'office_category', convertNumber: false },
        { field : 'properties', convertNumber: true },
        { field : 'office_space', convertNumber: true },
        { field : 'retail_space', convertNumber: true },
        { field : 'other_commercial_space', convertNumber: true },
        { field : 'average_office_lease_rate', convertNumber: true },
        { field : 'average_retail_lease_rate', convertNumber: true },
        { field : 'average_other_commercial_lease_rate', convertNumber: true },
        { field : 'total_land_area', convertNumber: true },
    ];
    static propertiesColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.property',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'type',
            columnName: 'reports.type',
            field: 'type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'property_grade',
            columnName: 'reports.propertyGrade',
            field: 'property_grade',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'office_space',
            columnName: 'reports.officeSpace',
            field: 'office_space',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter
        },
        {
            header: 'retail_space',
            columnName: 'reports.retailSpace',
            field: 'retail_space',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter
        },
        {
            header: 'other_commercial_space',
            columnName: 'reports.otherCommercialSpace',
            field: 'other_commercial_space',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter
        },
        {
            header: 'average_office_lease_rate',
            columnName: 'reports.avgOfficeLeaseRate',
            field: 'average_office_lease_rate',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'average_retail_lease_rate',
            columnName: 'reports.avgRetailLeaseRate',
            field: 'average_retail_lease_rate',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'average_other_commercial_space_lease_rate',
            columnName: 'reports.averageOtherCommercialSpaceLeaseRate',
            field: 'average_other_commercial_space_lease_rate',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter
        },
        {
            header: 'year_of_built',
            columnName: 'reports.builtYear',
            field: 'year_of_built',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ];
}