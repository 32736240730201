import { Injectable, PLATFORM_ID, Inject, signal, computed } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';

import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../constants/api-endpointsconstant';
import { commonContants } from '../constants/common.contants';
import { GlobalContants } from "../constants/global.contants";
import { StoreService } from './store.service';
import { DataType } from '../models/enums/common.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  cityList$ = new Subject<any>();
  language$ = new BehaviorSubject<string>(commonContants.defaultLanguage);
  isUserInfoLoaded: Subject<boolean> = new Subject();
  userInfo$ = new BehaviorSubject<any>(null);
  userToken$ = new BehaviorSubject<string>('');
  selectedCity$: Subject<number> = new Subject<number>();
  isBrowser: boolean;
  selectedCityName: string = '';
  selectedLanguage: string = this.getCurrentLang() || 'en';
  signUpSuccessMessage: string = '';
  savedReport: string[] = [];     // Used to store selected saved Report.
  savedFilter:string[] = [];      // used to store selected saved Filter.
  yearTrigger = new Subject<any>(); //year dropdown click trigger this
  languageList=signal([]);
  cityList$$=signal([]);
  location: any;
  emailMaxLength = 100;
  emailMinLength = 7;
  canOpenPopUp = true;
  constructor(

    private apiServive: ApiService,
    @Inject(PLATFORM_ID) private platformId: any,
    private storeService: StoreService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public translate: TranslateService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.location = window.location;
    }
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  getCitylist() {
    let url: string = `${environment.umsUrl}${apiEndPoints.city}`;
    return this.apiServive.get(url, true);
  }

  async getCity() {
    this.getCitylist().subscribe({
      next: (cityRes:any) => {
        if(cityRes.status == 200){
          let client_host = this.location.hostname;
          let mainCityList:any=[];
          let citis:any[] = cityRes.data;
          citis.sort((a, b) => a.place_name.localeCompare(b.place_name));
          citis.map((city: any) => {
              var temp = new Array();
              var str = city.domain ? city.domain : '';
              temp = str ? str.split(',') : [];
                  if(city?.child_data && city?.child_data?.length > 0){
                    city.child_data.map((e:any)=>{
                      str = e.domain ? e.domain : '';
                      temp = str ? str.split(',') : [];
                      if ((e.status == 1 || e.status == 2) && temp.indexOf(client_host) > -1) {
                        e['parent_name']=e?.country_name;
                        e['search_param']=e.place_name+' '+e?.country_name;
                        mainCityList.push(e);
                      }
                    })                            
                  } else {
                    if ((city.status == 1 || city.status == 2) && temp.indexOf(client_host) > -1) {
                      city['search_param']=city.place_name;
                      mainCityList.push(city);
                    }
                  }
          });

          this.cityList$.next({data:mainCityList});
          let cityList = mainCityList.map((item:any) => (item.slug).toLowerCase());
          this.cityList$$.set(cityList);
          this.storeService.add(GlobalContants.StoreKey.cityList, cityList);
        }
      }
    })
  }

  seletedCity(city: number) {
    this.selectedCity$.next(city);
  }

  getNeighDetails(geom: boolean, media: boolean) {
    const url: string = `${environment.miBaseUrl}${apiEndPoints.neighDetails}?get_geom=${geom}&get_media=${media}`;
    return this.apiServive.get(url);
  }

  get cityName(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.PlaceName)
    }
    return '';
  }

  get LangName(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.CurrentLang)
    }
    return '';
  }

  get CityLang(): string {
    return `${this.storeService.get(GlobalContants.StoreKey.Slug)}-${this.LangName || 'en'}`;
  }
  get CityID(): any {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.PlaceId)
    }
  }
  getCurrentLang(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.CurrentLang)
    }
    return '';
  }
  getLangList(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.langList)
    }
    return '';
  }
  getCityList(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.cityList)
    }
    return '';
  }
  getCurrentCitySlug(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.Slug)
    }
    return '';
  }

  getCurrentLangID(): number {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.CurrentLangID)
    }
    return 1;
  }


  getStoreValue(storykey: string): any {
    if (this.isBrowser) {
      return this.storeService.get(storykey);
    }
    return null;
  }

  setStoreValue(storykey: string, value:any): any{
    if(this.isBrowser){
      this.storeService.add(storykey, value);
    }
  }
  clearSession(storykey: string): any{
    if(this.isBrowser){
      this.storeService.clearSessionData(storykey);
    }
  }
  setLanguage(lang : number, url: string = ''): void {
    let langCode = lang === 1 ? commonContants.englishCode : commonContants.arabicCode;
    this.storeService.add(GlobalContants.StoreKey.CurrentLangID, lang);
    this.storeService.add(GlobalContants.StoreKey.CurrentLang, langCode);
    this.selectedLanguage = langCode;
    if (url && !url.includes(GlobalContants.Routes.RequestDemo)) {
      let urlV = url.split('/');
      let replace:string = urlV[1];
      let newurl = url.replace('/'+replace,'');
      const city = this.getStoreValue(GlobalContants.StoreKey.Slug);
      this.router.navigateByUrl(`${city}-${langCode}${newurl}`);
    }
    this.setDocument(langCode);
  }

  setDocument(currentLang: string): void {
    this.language$.next(currentLang);
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = currentLang === commonContants.arabicCode ? "rtl" : "ltr";
    htmlTag.lang = currentLang;
  }

  get UserId(): number {
    let userInfo: any = this.getStoreValue(GlobalContants.StoreKey.userInfo)
    return userInfo ? userInfo.user_id : '';
  }

  getUserInfo(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.userInfo)
    }
    return '';
  }
  getUserName(): any {
    let guestuser = {uname: 'Guest User', email: 'guestuser@estater.com'};
    if (this.isBrowser) {
      let userInfo: any = this.storeService.get(GlobalContants.StoreKey.userInfo)
      return userInfo ? userInfo : guestuser;
    }
    return guestuser;
  }
  getInitCharName(name: string) {
    if (name) {
      var names = name.trim().split(/\s+/);

      // Replaces the first name with an initial, followed by a period.
      names[0] = names[0].substring(0, 1) + "";
      names[1] = names[1].substring(0, 1) + "";

      // Glue the pieces back together.
      var name_abbr = names.join('');
      return name_abbr;
    }
    return '';
  }

  async getAddressByLatlon(x:any, y:any) {
    return this.apiServive.getAddressByLatlon(x, y);
  }
  getLanguageList() {
    let url: string = `${environment.umsUrl}${apiEndPoints.language}?status=1`;
    return this.apiServive.getLanguage(url, true);
  }

  getDurationInSeconds(startDate: Date, EndDate: Date) {
    const diff = (EndDate.getTime() - startDate.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  }

  replaceSpchar(value: string) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let re = /\ /gi;
      let name = value.replace(re, '_');
      return name;
    } else {
      return '';
    }
  }
  escapeMetaCharacters(inputString: string): string {
    var outputString = '';
    try {
      var metaCharacters = ['\\', "'"];
      for (var i = 0; i < metaCharacters.length; i++) {
        if (inputString.includes(metaCharacters[i])) {
          if (metaCharacters[i] == "'") {
            outputString = inputString.replace(metaCharacters[i], "''");
          } else {
            outputString = inputString.replace(
              metaCharacters[i],
              '\\' + metaCharacters[i]
            );
          }
          inputString = outputString;
        } else {
          outputString = inputString;
        }
      }
      return outputString;
    } catch (error) {
      console.log(
        `escape Meta Characters error  escapeMetaCharacters() | ${error}`
      );
      return '';
    }
  }

  compareObject(obj1: any, obj2: any): boolean {
    if (obj1 && obj2) {
      if (Object.keys(obj1).length == Object.keys(obj2).length) {
        var flag = true;
        for (let key in obj1) {
          if (obj1[key] == obj2[key]) {
            continue;
          } else {
            flag = false;
            break;
          }
        }
        return flag;
      }
      return false;
    }
    return false;
  }

  formatDateUTC(dt: any, getTime: boolean = false) {
    let value;
    if (dt == null) return '-';
    dt = new Date(dt);
    value = new Date(dt + ' UTC');
    if (getTime) {
      return value.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) + " " + value.getHours().toString().padStart(2) + ":"
        + value.getMinutes().toString().padStart(2) + ":" + value.getSeconds().toString().padStart(2);
    } else {
      return (
        value.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
      );
    }
  }

  setSelectedCity(city: any, url: any = null, onload:boolean=false) {
      this.setCity(city, url)
  }

  setCity(city:any, url:any=null ){
    let cityId: number = city.place_id;
    let lang = this.getCurrentLang() || 'en';
    this.storeService.add(GlobalContants.StoreKey.PlaceId, cityId);
    this.storeService.add(GlobalContants.StoreKey.Slug, city.slug);
    this.storeService.add(GlobalContants.StoreKey.PlaceName, city.place_name);
    this.storeService.add(GlobalContants.StoreKey.CurrencyCode, (city.currency_code.toUpperCase()));
    this.storeService.add(GlobalContants.StoreKey.latitude, city.latitude);
    this.storeService.add(GlobalContants.StoreKey.longitude, city.longitude);
    this.storeService.add(GlobalContants.StoreKey.placeCode, city.place_code);
    this.storeService.add(GlobalContants.StoreKey.CityFlag, city.image);
    this.seletedCity(cityId);
    this.selectedCityName = city.slug;
    this.getNeighDetails(false, false).subscribe({
      next: (res: any) => {
        if (res && res?.status === 200) {
          this.setStoreValue(GlobalContants.StoreKey.neighList, res?.data);
        } else {
          this.setStoreValue(GlobalContants.StoreKey.neighList, null);
        }
      }
    })

    // get resource
    const userInfo: any = this.storeService.get(GlobalContants.StoreKey.userInfo);
    if(userInfo) this.apiServive.getAllowResources(userInfo.user_id);
    if (!url) {
      this.router.navigateByUrl(`${city.slug}-${lang}/${GlobalContants.Routes.Reports}`);
    } else {
      this.router.navigateByUrl(`${url}`);
    }
  }

  downloadExcelPath(){
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }


  downloadExcelUrl(file_path: string) {
    var a: any = document.createElement('A');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  removeUnderscores(value: string) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/_/g, ' ');
      return this.camelizeAll(name);
    } else {
      return '';
    }
  }

  camelizeAll(str: string) {
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  }

  scrollMenu(menu: string) {
    document.getElementById(`${menu}`)?.scrollIntoView({ behavior: "smooth" })
  }


  /**
   * This is a function to get neigh name label from the localstorage
   * @param neigh_name
   * @returns
   */
  getNeighLabel(neigh_name:string) {
    let neigh_list =  this.getStoreValue(GlobalContants.StoreKey.neighList);
    if(neigh_list && neigh_name){
      if(this.getCurrentLangID() == 1){
        return neigh_name;
      }else{
       let neighName = neigh_list.find((e:any)=> e.neigh_name[1] == neigh_name)
       if(neighName && neighName.neigh_name[this.getCurrentLangID()]){
          return neighName.neigh_name[this.getCurrentLangID()]
       }else{
        return neigh_name;
       }
      }
    }
    return neigh_name;
  }

  reportDataValueType(type : number): string{
    switch(type){
      case DataType.percentage:
        return '%';
        break;
      case DataType.unit:
        return 'm\u00B2';
        break;
      case DataType.squareMeter:
        return this.translate.instant('reports.sqm');
        break;
      case DataType.squareMeterWithBracket:
        return `${this.translate.instant('reports.sqm')}`;
        break;
      case DataType.squareFeet:
        return this.translate.instant('reports.feet_unit');
        break;
      case DataType.squareFeetWithBracket:
        return `${this.translate.instant('reports.feet')}`;
        break;
      case DataType.currencyCode:
        return `(${this.translate.instant(this.getStoreValue(GlobalContants.StoreKey.CurrencyCode))})`;
        break;
      case DataType.currencyWithSquareMeter:
        return `(${this.translate.instant(this.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant('reports.sqm_unit')})`;
        break;
      case DataType.monthly:
        return `(${this.translate.instant('reports.monthly')})`;
        break;
      case DataType.currencyWithSquareMonthly:
        return `(${this.translate.instant(this.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant('reports.sqm_unit')} ${this.translate.instant('reports.monthly')})`;
        break;
      case DataType.countWithBracket:
        return `${this.translate.instant('reports.countWithBracket')}`;
        break;
      case DataType.yr:
        return `${this.translate.instant('reports.yrWithBracket')}`;
        break;
      case DataType.officeSqmBracket:
        return `(${this.translate.instant('reports.office')})(${this.translate.instant('sqm_unit')})`;
        break;
      case DataType.retailSqmBracket:
        return `(${this.translate.instant('retail')})(${this.translate.instant('sqm_unit')})`;
        break;
      case DataType.officeCurrencySqm:
        return `(${this.translate.instant('reports.office')})(${this.translate.instant(this.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant('reports.sqm_unit')})`;
      break;
      case DataType.retailCurrencySqm:
        return `(${this.translate.instant('retail')})(${this.translate.instant(this.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant('reports.sqm_unit')})`;
      break;
      case DataType.currencyWithSquareFeet:
        return `(${this.translate.instant(this.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant('reports.feet_unit')})`;
        break;
    }
    return '';
  }

  /**
   * This function is used to sort object based on keys.
   * @param obj : object to sort.
   * @returns : return sorted object based on keys.
   */
  sortObjectByKey(obj: any) {
    const sortedKeys = Object.keys(obj).sort();  // Sorts keys alphabetically
    const sortedObj: any = {};
    sortedKeys.forEach(key => {
      sortedObj[key] = obj[key];
    });
    return sortedObj;
  }

}
