import { Injectable } from '@angular/core';
import { 
        HTTP_INTERCEPTORS, 
        HttpErrorResponse, 
        HttpEvent, 
        HttpHandler, 
        HttpInterceptor, 
        HttpRequest, 
        HttpResponse
} from '@angular/common/http';

import { Observable, catchError, map, throwError } from 'rxjs';

import { LoaderInterceptor } from './loader.interceptor.service';
import { SharedService } from './shared.service';
import { GlobalContants } from '../constants/global.contants';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

        constructor(private userService: UserService,
            private sharedService: SharedService
        ){}

        intercept(
            request: HttpRequest<any>,
            next: HttpHandler
        ): Observable<HttpEvent<any>> {
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if ([403].includes(event?.body?.status) && this.sharedService.getStoreValue(GlobalContants.StoreKey.userToken)) {
                            this.userService.userLogout();
                        }
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    if([403].includes(error.status) && this.sharedService.getStoreValue(GlobalContants.StoreKey.userToken)){
                        this.userService.userLogout();
                    }
                    return throwError(() => error)
                })
            );
        }
}

export const HttpInterceptorsProvider = [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }
]
