import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, finalize, map, throwError } from "rxjs";

import { SpinnerService } from "../components/spinner/spinner.service";
import { LoaderType } from "../models/enums/common.enum";

export const InterceptorSkipLoaderHeader = 'X-Loader-Interceptor';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(private spinnerService: SpinnerService){}

    private counter: number = 0;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const loadingKey: any = req.headers.get(InterceptorSkipLoaderHeader);

        if(loadingKey === LoaderType.skip){
            return next.handle(req);
        } else {
            this.counter++;
            /* loadingKey */
            //2
              this.spinnerService.showLoader(loadingKey);
            

            return next.handle(req).pipe(
                map(data => {
                    return data;
                }),
                catchError((error: HttpErrorResponse) => throwError(error)),
                finalize(() => {
                    this.counter--;
                    if(this.counter <= 0){
                        this.spinnerService.hideLoader();
                    }
                }))
        }
        
    }
}
