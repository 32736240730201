import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/customValidator';
import { isPlatformBrowser } from '@angular/common';
import { LanguagesEnum } from '../../models/enums/common.enum';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  contactUsForm! :FormGroup;
  currentLang:string='';
  dialCode: any;
  btnDisable!: boolean;
  lengthCheck: any;
  phoneNumber:boolean=false;
  language$: Subscription = new Subscription();
  city_list: any=[];
  currentLangID: number = LanguagesEnum.english;
  get FullName() {
    return this.contactUsForm.get('full_name')!;
  }
  get Email() {
    return this.contactUsForm.get('email')!;
  }

  get Message(){
    return this.contactUsForm.get('message')!;
  }
  get City(){
    return this.contactUsForm.get('city')!;
  }
  constructor(private fb: FormBuilder,
    private sharedService: SharedService,
    private userService: UserService,
    private alertService: AlertService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ContactUsComponent>,
    @Inject(PLATFORM_ID) private platformId: any,
    ){
      if (isPlatformBrowser(this.platformId)) {
        this.setLang();
      }
  }

  setLang(): void{
    this.currentLangID = this.sharedService.getCurrentLangID();
    this.sharedService.language$.subscribe((lang : any) => {
      this.translate.use(lang);
    })
  }
  
  phoneInputChange(event:any) {
    this.phoneNumber = (event.target.value == '') ? false : true;
    if(!this.phoneNumber)
      this.contactUsForm.controls['phoneNumber'].setErrors(null);
    else if(event.target.value?.length != this.lengthCheck?.length)
      this.contactUsForm.controls['phoneNumber'].setErrors({'validatePhoneNumber': true });
  }
  getCountryCode(event:any) {
    this.dialCode = event.dialCode;
    this.lengthCheck = event.placeHolder.split('+'+this.dialCode)[1];
  }
  ngOnInit() {
    this.sharedService.getCity();
    this.sharedService.cityList$.subscribe((res)=>{
      this.city_list = res.data;
    })
    this.language$ = this.sharedService.language$.subscribe(res=>{
      this.currentLang = res;
    })
    let res: any = this.sharedService.getUserInfo();
    this.contactUsForm = this.fb.group(
      {
        full_name: ['', Validators.compose([Validators.required, Validators.maxLength(65), Validators.minLength(2),  Validators.pattern('^[a-zA-Z \u0621-\u064A\u0660-\u0669 ]*$')])],
        email: [{ value: '', disabled: (res && res.user_id ? true : false) }],
        phoneNumber: ['', CustomValidator.validatePhoneNumber],
        message: ['', Validators.compose([Validators.maxLength(140), Validators.minLength(10), Validators.required])],
        city:['', Validators.required]
      }
    );
    
    if (res) {
      this.patchForm(res);
    }

  }

  patchForm(obj:any){
    let language = this.sharedService.getCurrentLangID();
    let user_info = obj;
    let first_name = user_info.user_details[language]?.first_name ? user_info.user_details[language]?.first_name : user_info.user_details[1]?.first_name;
    let last_name = user_info.user_details[language]?.last_name ? user_info.user_details[language]?.last_name : user_info.user_details[1]?.last_name;
    let a = {
      full_name: first_name + ' '+ last_name,
      email: user_info.email,
      phoneNumber: (user_info.phone_number) ? user_info.phone_number.toString().replace('-',''):'',
    }
    this.contactUsForm.patchValue(a);
  }
  saveChanges(formData:any){
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched();
      return;
    }
    let value = formData.getRawValue();
    if(value['full_name'].trim() == "" || value['full_name'].trim() == null){
      this.contactUsForm.patchValue({first_name:''})
      this.alertService.warn(this.translate.instant('User.full_name_required'))
      return;
    }
    // let phone_number = value.phoneNumber ? `+${this.dialCode}${(value.phoneNumber.toString().replaceAll('+'+this.dialCode,''))}`:'';
    let body = {
      name: value.full_name,
      email: value.email,
      phone_number : value.phoneNumber,
      message: value.message,
    }
    this.btnDisable = true;
    this.userService.contactUsPost(body, value.city).subscribe(
      {
        next: (res: any) => {
          this.btnDisable = false;
          if (res && res.status == 201) {
            this.contactUsForm.reset();
            this.alertService.success(this.translate.instant('reports.Thanks_for_reaching'));
            this.dialogRef.close();
          } else {
            this.alertService.warn(res.message)
          }
        }, error: (err) => {
          this.btnDisable = false;
          this.alertService.error('Something went Wrong ! Try Again');
        }
      })
  }

  close(){
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.language$.unsubscribe();
  }

}
