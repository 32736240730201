import { Component, Input, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { Subscription } from 'rxjs';
import { OfficeReportPropertyDetailConstant } from './office-report-property-details.constant';
import { OfficePropertyDetailMenuEnum } from './office-report-property-detail.enum';
import { ReportsViewEnum } from 'src/app/reports/models/enums/reports.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { OfficeReportPropertyDetailService } from './office-report-property-detail.service';
import { ReportPropertyDetailService } from '../residential-property/report-property-detail.service';

@Component({
  selector: 'app-office-property-detail',
  templateUrl: './office-property-detail.component.html',
  styleUrls: ['./office-property-detail.component.scss'],
})
export class OfficePropertyDetailComponent {
  @Input() propertyInfo: any;
  currentLang: string = '';
  languageSub$: Subscription = new Subscription();
  overViewMenu = OfficeReportPropertyDetailConstant.overViewMenu;
  currentReportView: number = ReportsViewEnum.mapView;
  currentScroll: any = OfficePropertyDetailMenuEnum.overview;
  officePropertyDetailMenuEnum = OfficePropertyDetailMenuEnum;
  currentReportView$: Subscription = new Subscription();
  constructor(
    private sharedService: SharedService,
    public reportService: ReportService,
    private officeReportPropertyDetailService: OfficeReportPropertyDetailService,
    private reportPropertyDetailService : ReportPropertyDetailService
  ) {}

  ngOnInit(): void {
    this.setLang();
    this.getReportView();
  }
    ngOnChanges(changes: SimpleChanges): void {
      this.reportPropertyDetailService.propertyInfo = this.propertyInfo;
      this.officeReportPropertyDetailService.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
      this.officeReportPropertyDetailService.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
      this.officeReportPropertyDetailService.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
      this.scrollPoint(String(this.officePropertyDetailMenuEnum.overview));
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollPoint(String(this.officePropertyDetailMenuEnum.overview));
    }, 0);
  }
  setCount(count: any, heading: number) {
    this.overViewMenu.map((e: any) => {
      if (e.name == heading) {
        e['count'] = this.reportService.formatInternationNum(count) || 0;
      }
    });
  }
  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe((lang) => {
      this.currentLang = lang;
    });
  }
  getReportView() {
    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      this.currentReportView = view;
    });
  }
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }
  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }
  backToTransaction() {
    this.reportService.isDetailPageOpen = false;
    this.reportService.transactionPropertyDetails.next(false);
  }
  ngOnDestroy(): void {
    this.languageSub$?.unsubscribe();
    this.currentReportView$?.unsubscribe();
  }
}
