import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';
import { Alert, AlertType } from '../../models/alert';

@Component(
  {
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.scss']
  })
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  // @Input() fade = true;
  currentLang:string='en';
  alerts: Alert[] = [];
  alertSubscription!: Subscription;
  routeSubscription!: Subscription;

  constructor(private router: Router, private alertService: AlertService) {
  }

  ngOnInit() {
    // subscribe to new alert notifications
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {

        // clear alerts when an empty alert is received
        if (!alert.message) {
          // filter out alerts without 'keepAfterRouteChange' flag
          this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          this.alerts.forEach((x:any) => delete x.keepAfterRouteChange);
          return;
        }
        if (!this.alerts.length) {
          this.alerts=[alert];
        }
        else {
          for (let index = 0; index < this.alerts.length; index++) {
            const element = this.alerts[index];
            if (element.message === alert.message && element.type === alert.type) {
              return
            }
            else {
              this.alerts=[alert];
            }
          }
        }

        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 2000);
        }else if(alert.time){
          setTimeout(() => this.removeAlert(alert), alert.time);
        }

      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
  // removeAlert(alert: Alert) {
  //   // check if already removed to prevent error on auto close
  //   if (!this.alerts.includes(alert)) return;

  //   if (this.fade) {
  //     // fade out alert
  //     alert.fade = true;

  //     // remove alert after faded out
  //     setTimeout(() => {
  //       this.alerts = this.alerts.filter(x => x !== alert);
  //     }, 250);
  //   } else {
  //     // remove alert
  //     this.alerts = this.alerts.filter(x => x !== alert);
  //   }
  // }
  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert); 
  }
  cssClasses(alert: Alert) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertType.Success]: 'left-border-success',
      [AlertType.Error]: 'left-border-danger',
      [AlertType.Info]: 'left-border-info',
      [AlertType.Warning]: 'left-border-warning'
    }

    classes.push(alertTypeClass[alert.type]);

    // if (alert.fade) {
    //   classes.push('fade');
    // }

    return classes.join(' ');
  }

  getAlertTitle(alert: Alert){
    const alertTypeClass = {
      [AlertType.Success]: 'Success',
      [AlertType.Error]: 'Error',
      [AlertType.Info]: 'Info',
      [AlertType.Warning]: 'Warning'
    }
    return alertTypeClass[alert.type];
  }
}
