
<app-reports-container
    [tableColumn]="totalpopulationReportColumn"
    [dataValue]="totalpopulationReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>


<app-reports-container
    class="directionLtr"
    [dataValue]="populationYearWiseReport"
    [reportType]="chartView.barStackedChart"
    [chartName]="'population_year_wise'"
    [loading]="populationYearWiseReportLoading"
    [title]="'reports.population_year_wise'"
    [yearDropdown]="true"
    [yearReportType]="populationReports.population_year_wise"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<div class="report-list">
    <h4>{{"reports.population_trendline" | translate}}</h4>

    <ng-container *ngIf="populationTrendlineReport.length > 0">
        <app-report-chart-view
        [data]="populationTrendlineReport"
        [chartType]="chartView.multiBarLineGroupChart"
    ></app-report-chart-view>
    </ng-container>
    <div class="noRecord" *ngIf="populationTrendlineReport.length === 0">
        <span>{{"reports.no_record_found" | translate}}</span>
    </div>
</div>


<app-reports-container
    [tableColumn]="populationAgeWiseColumns"
    [dataValue]="populationAgeWiseData"
    [isDownloadExcel]="true"
    [loading]="populationAgeWiseDataLoading"
    [reportName]="'reports.totalPopulation'"
    [title]="'reports.totalPopulation'"
    [expandable]="true"
></app-reports-container>
