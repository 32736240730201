<div class="chart-container" >
  <div class="chartWidth" [ngClass]="{ 'halfChart': result.length === 2, 'fullhart' : result.length === 1}">
    <div dir="ltr" id="chartdiv1" class="chart-div" ></div>
    <div class="text">{{result[0]}}</div>

  </div>
  <div class="chartWidth" [ngClass]="{ 'halfChart': result.length === 2, 'noChart': result.length === 1}">
    <div dir="ltr" id="chartdiv2"   class="chart-div" ></div>
    <div class="text" *ngIf="result.length > 1">{{result[1]}}</div>

  </div>
  <div class="chartWidth" [ngClass]="{ 'noChart': result.length === 2 || result.length === 1} ">
    <div dir="ltr" id="chartdiv3"  class="chart-div" ></div>
    <div class="text" *ngIf="result.length === 3">{{result[2]}}</div>
  </div>
  </div>
  <div dir="ltr" id="legenddiv"></div>