import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-text',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.scss']
})
export class FormTextComponent implements OnInit {
  @Input() data: string='';
  @Input() name: any;
  @Input() required: boolean = false;

  textForm = new FormControl();
  filterValue: string = '';

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit(): void {
      this.textForm = this.controlContainer.control as FormControl;
  }

  ngOnChanges() {
      this.filterValue = this.data
  }
}
