
<div class="mi-container">
    <app-alert></app-alert>
    <app-side-bar [IsLoggedIn]="isLoggedIn" (collapseToggle)="collapseToggle($event)"></app-side-bar>
    <div class="main-container" [ngStyle]="{'width': !isLoggedIn ? '100%' : sidebarCollapse ? 'calc(100% - 54px)' : 'calc(100% - 224px)'}">
        <app-header [IsLoggedIn]="isLoggedIn"></app-header>
        <app-spinner *ngIf="loader === loaderType.normal"></app-spinner>
        <router-outlet></router-outlet>
    </div>
</div>
