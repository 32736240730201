<div class="map-search map-search-comman search_container" id="map-search">
    <div class="search-bar" *ngIf="!Searched_properties">
        <search-bar  #searchpropertyDetail  [MinCharSearch] = "3" [closeButton] = "true" (SearchClick)="getMapSearchedDetail($event)"
         (close)="closeMapSearch($event)" [Placeholder]="'map.Locate_a_property'" [tooltip_data]="cityCode == 'KWT' ? 'map.SearchPropertyNameKwt' : 'map.SearchPropertyName'"></search-bar>
    </div>
    <div class="search-bar" *ngIf="Searched_properties">
      <div class="search-bar-view">
        <mat-icon class="search-icon">search</mat-icon>
        <app-searched-address class="search-address" [Searched_properties]="Searched_properties" [current_lang]="current_lang"></app-searched-address>
        <mat-icon class="close-icon" (click)="clearChipdata(true); $event.stopPropagation()">close</mat-icon>
        <div class="search-address-hover">
          <app-searched-address [hover]="true" [Searched_properties]="Searched_properties" [current_lang]="current_lang"></app-searched-address>
        </div>
      </div>
        
    </div>
    <ng-container *ngIf="recentSearchedData?.length && showRecentSearch">
      <div class="recent-searched-container">
        <div class="recent-search-heading">
          <span>{{('map.recentSearch' | translate) | uppercase}}</span>
          <button (click)="deleteRecentSearchedParcelDetail('delete_all')" class="delete-all">{{'map.delete_all' | translate}}</button>
        </div>
        <div class="searched-group">
          <div *ngFor="let recentData of recentSearchedData let isLast = last" class="recent-searched-data-container">
            <div class="recent-searched-data searched-data-container"  [class.no-border]="isLast">
              <div class="searched-data" (click)="locateSearchedParcelOnMap(recentData)">
                <div class="parcel-id"><span [title]="recentData.property_name" class="property_name">{{recentData.property_name}}</span></div>
                <div class="neig-block-detail">
                  <span class="neigh-name">{{recentData.neigh_name}}</span>
                  <mat-icon *ngIf="recentData.neigh_name && recentData.block_name" [class.rotate-180]="current_lang == 'ar'">keyboard_arrow_right</mat-icon>
                  <span class="block-name">{{recentData.block_name}}</span>
                </div>
              </div>
              <div class="delete" (click) = "deleteRecentSearchedParcelDetail('delete', recentData.id)">
                <img src="assets/images/delete.png" alt="" width="12" height="14">
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="searchedData?.length  && !showRecentSearch">
      <div class="searched-group">
        <div class="searched-data-container" *ngFor="let data of searchedData" (click)="locateSearchedParcelOnMap(data)">
          <mat-icon class="map-filter_icns-common flip" svgIcon="upArrow"></mat-icon>
          <div class="searched-data re-searched-data">
              <div class="parcel-id"><span [title]="data.property_name" class="property_name">{{data.property_name}}</span></div>
              <div class="neig-block-detail">
                <span class="neigh-name">{{data.neigh_name}} </span>
                <mat-icon *ngIf="data.neigh_name && data.block_name" [class.rotate-180]="current_lang == 'ar'">keyboard_arrow_right</mat-icon>
                <span class="block-name">{{data.block_name}}</span>
              </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!searchedData?.length  && !showRecentSearch">
      <div class="no-record-found">{{'reports.no_record_found' | translate}}</div>
    </ng-container>
  </div>