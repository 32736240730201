import { UserService } from 'src/app/common/services/user.service';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SharedService } from '../../../common/services/shared.service';
import { ReportService } from '../../services/report.service';
import { actionIdConstants } from 'src/app/common/constants/common.contants';
import { SubscriptionPayload } from '../../models/users.model';
import { AlertService } from '../../../common/components/alert/alert.service';
import { ReportMapService } from '../../services/report-map.service';

@Component({
  selector: 'app-reports-category',
  templateUrl: './reports-category.component.html',
  styleUrls: ['./reports-category.component.scss'],
})
export class ReportsCategoryComponent implements OnChanges, OnInit, OnDestroy {
  @Input() reportStudiesData: any;

  startDate = new Date();
  routeSub$: Subscription = new Subscription();
  reportSub$: Subscription = new Subscription();
  reportPermission$: Subscription = new Subscription();
  clickedSavedReport$: Subscription = new Subscription();
  languageSub$: Subscription = new Subscription();
  queryParams: any;
  currentLang: string = 'en';

  selectedObjective: any = [];
  selectedObjectiveData: any = [];
  studiesList: any = [];
  selecteStudiesData: any = [];
  queriesList: any = [];
  selectedObjId!: number | null;
  selectedStudiesId!: number | null;
  selectedReportId!: number | null;
  showDescription=null;
  unloack_report_id: any;
  constructor(
    private reportService: ReportService,
    private router: Router,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private alertService: AlertService,
    private mapService: ReportMapService
  ) {
  }


  ngOnInit(): void {
    this.routeSub$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
      if(this.queryParams?.rid){
        // this.fetchSelectedReport();
      }
    });
    this.reportReset();
  }


  reportReset() {
    this.reportSub$ = this.reportService.selectedReport$.subscribe((report: any) => {
      if (!report.flag && this.queryParams?.rid && this.reportService.selectedRepored) {
        this.resetSelection();
      }
    });

    this.reportPermission$ = this.reportService.reportPermission$.subscribe((permissions: boolean) => {
        if(permissions){
          this.setSelectedReport();
        }
    })
    this.setLang();
  }

  setLang(): void{
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['reportStudiesData'] &&
      changes['reportStudiesData'].currentValue
    ) {
      this.resetSelection();
      this.setSelectedReport();
    }
  }

  selectStudies(objectives: any = null) {

    this.studiesList = objectives?.studies || [];

    this.selectedObjId = objectives?.obj_id;
    this.selectedObjectiveData = objectives;
    this.queriesList = [];
    this.selectedStudiesId = null;
    this.logActivity('objective',  this.selectedObjId, actionIdConstants.View_Objective);

    //If All queriesList Unlock so parent div also Unlock
    // this.studiesList.forEach((study: any) => {
    //   if(study.reports && study.reports.length > 0){
    //     const allActive = true; //study.reports.every((report: any) => (!report.is_open_for_demo));

    //     if(allActive){
    //       study.reports.map((report: any) => {
    //         report['active'] = false; //report?.is_open_for_demo;
    //         if(this.reportService.userSubscription?.length > 0){
    //           report['active'] = this.reportService.userSubscription.some((type: any) => type.group_id === study.study_id && report.report_id === type.report_id);
    //         }
    //         report['hidden'] = this.checkHidden(report)
    //       });
    //     }
    //     //study['active'] = true;
    //     const allActives = study.reports.every((report: any) => (report.hidden));
    //     if(allActives){
    //       study['active'] = false;
    //     }else{
    //       study['active'] = true;
    //     }
    //   }
    // })
  }

  //open for demo check
// checkHidden(report:any){
//   if(report.active){
//     return false;
//   }else if(report?.is_open_for_demo){
//     return false
//   }
//   return true;
// }

  selectQueries(studies: any = null) {
    this.selectedStudiesId = studies?.study_id;
    this.selecteStudiesData = studies;

    this.queriesList = [];
    if(studies?.reports){
      this.queriesList = this.subscriptionCheck(studies.reports);
    }

    this.logActivity('group',  this.selectedStudiesId, actionIdConstants.View_Group);
  }

  selectReport(report: any) {
    this.sharedService.clearSession('filter');
    this.mapService.mapFiltertoggle.next(false);
    this.mapService.mapLayertoggle.next(false);
    // if(!report.active){
    //   return;
    // }
    this.setReportData(report);
    let queryParams = {
      oid: this.selectedObjId,
      gid: this.selectedStudiesId,
      rid: this.selectedReportId,
    };

    //check lock report
    if (this.reportService.userSubscription?.length) {
      let subsreport = this.reportService.userSubscription.find((type: any) => report.report_id == type.report_id)
      this.reportService.setLockReport((subsreport ? false : true));
    }else{
      console.log('you dont have any subscription');
      return;
    }

    let defaultValue = this.reportService.setDefaultFilter(report);
    queryParams = { ...queryParams, ...defaultValue };
    this.reportService.selectedReport$.next({flag: true});
    this.router.navigate(
      [`${this.sharedService.CityLang}/reports`],
      { queryParams: queryParams }
    );
    this.reportService.reportQueryToggle$.next(false)
  }

  subscriptionCheck(studies: any){
     return studies.map((report: any) => {
      // this is code for using filter from subscription
          if(this.reportService.userSubscription?.length){
            let subsReport = this.reportService.userSubscription.find((subs:any) => subs.report_id == report.report_id);
            if(subsReport && subsReport?.filter && subsReport?.filter.length>0){
              report['filter'] = subsReport?.filter;
            }
          }
          // report['active'] = false; //report?.is_open_for_demo;
          // check the allowed report from the user subscription if report is
          // not available in the user subscription then table data will be lock
          // if(!report?.is_open_for_demo){
         // }else{
          // }  
          //  if(this.reportService.userSubscription?.length > 0){
          //   report['active'] = this.reportService.userSubscription.some((type: any) => type.group_id === this.selectedStudiesId && report.report_id === type.report_id);
          // }
          // report['hidden'] = this.checkHidden(report)
          return report;
      });
  }

  setReportData(report: any = null){
    this.reportService.selectedObjective = {...this.selectedObjectiveData};
    this.reportService.selectedStudies = {...this.selecteStudiesData};
    this.selectedReportId = report?.report_id || null;
    this.reportService.selectedRepored = report ? {...report} : null;
  }


  setSelectedReport() {
    if (this.queryParams?.rid) {
      this.fetchSelectedReport();
    } else {
      this.studiesList = [];
      this.queriesList = []
      this.selectedObjId = null;
      this.selectedStudiesId = null;
      this.selectedReportId = null;
      // this.setDefaultObjective();
    }
  }

  setDefaultObjective(){
    if(this.reportStudiesData.length > 0 && this.reportStudiesData.length === 1){
        this.selectStudies(this.reportStudiesData[0]);
    }
  }

  fetchSelectedReport(){
    let objectives = this.reportStudiesData?.find(
      (obj: any) => obj.obj_id == this.queryParams?.oid
    );
    this.selectStudies(objectives);

    if(objectives){
      let group = objectives.studies.find(
        (group: any) => group.study_id == this.queryParams?.gid
      );
       this.selectQueries(group);

       if(group){
          let report = group.reports.find(
            (reports: any) => reports.report_id == this.queryParams?.rid
          );

          this.setReportData(report);
          this.reportService.selectedReport$.next({flag: true});

       }
    }
  }

  resetSelection() {
    this.studiesList = [];
    this.queriesList = [];
    this.selectedStudiesId = null;
    this.selectedReportId = null;
    this.selectedObjective = [];
    this.selectedObjectiveData = [];
    this.selecteStudiesData = [];
    this.selectedObjId = null;
    this.reportService.selectedObjective = [];
    this.reportService.selectedStudies = [];
    this.reportService.selectedRepored = [];
  }

  logActivity(entity: string, entityId: any, action: any){
    const endDate = new Date();
    const activity = {
      duration: this.sharedService.getDurationInSeconds(
        this.startDate,
        endDate
      ),
      entityId: entityId,
      actionId: action,
      entity: entity,
    };
    this.userService.logUserActivity(activity);
  }

  submitSubscription(report: any) {
    this.unloack_report_id = report.report_id;
    const payload: SubscriptionPayload = this.reportService.subscriptionPayload(report);

    this.reportService.subscriptionRequest(payload).subscribe(
      {next:(res: any) => {
        this.unloack_report_id=null;
        this.alertService.success(res.message)
      },
      error: (error:any) => {
        this.unloack_report_id=null;
        this.alertService.error('Sorry ! Try again');
      }}
    )
  }

  ngOnDestroy(): void {
    this.routeSub$?.unsubscribe();
    this.reportSub$?.unsubscribe();
    this.clickedSavedReport$?.unsubscribe();
    this.reportPermission$?.unsubscribe();
    this.languageSub$?.unsubscribe();
  }
}
