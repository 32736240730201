import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, lastValueFrom, of, Subject, Subscription } from "rxjs";
import { ApiService } from "src/app/common/services/api.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { GlobalContants } from "src/app/common/constants/global.contants";
import { SharedService } from "src/app/common/services/shared.service";
import { ReportService } from "./report.service";
import { ReportsViewEnum } from '../models/enums/reports.enum';
import { apiEndPoints } from '../../common/constants/api-endpointsconstant';
import { TranslateService } from "@ngx-translate/core";
import { DecimalPipe } from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class ReportMapService {

  public zoom = new BehaviorSubject<number>(10);
  public mapZoom = this.zoom.asObservable();
  public tenantDataState$ = new BehaviorSubject<any>('');
  public tenantName= signal('')
  public selectedIndex= signal(-1)
  public mapCurrentMapState = signal('');
  public mapCurrentMapStateZoom = signal(0);
  public updateTenentBounds$ = new Subject<boolean>();
  public mapLayertoggle = new Subject<boolean>();
  public mapLayer_current = signal<any>('default');
  public reportFiltertoggle = new Subject<any>();
  public mapFiltertoggle = new Subject<boolean>();
  public propertyinfotoggle = new Subject<boolean>();
  public basemapLayer = new Subject<string>();
  public legendLayer = new Subject<any>();
  public legendLayerGroup = new Subject<any>();
  public legendLayerGroupList = new BehaviorSubject<any>('');
  public legendToggle = new Subject<boolean>();
  public drawPolyToggle = new Subject<boolean>();
  public mapFullscreenToggle = new Subject<boolean>();
  public reportFilter = new Subject<any>();
  public transaction_LayerActive = new Subject<boolean>();
  public mapCentroid: any = null;
  public mapCoordinates: any = new Subject<any>();
  public dashboardMapCoordinates: any = new Subject<any>();
  public overlay_mapLayer = new Subject<any>();
  public overlay_mapLayer_current = signal<any>('');
  public selected_propertydetails = new Subject<any>();
  public appliedFilter = new Subject<any>();
  public amenitiesItemList = new Subject<any>();
  activeRoute$: Subscription = new Subscription();
  placeCode: any;
  public mobile:boolean=false;
  propertyAttributes: any=[];
  public base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  private rand_array = ['s', 'e', '4', 'z', 'x', 'f', 'a', 'k', 'l', 'y', 'n', '6', '1'];
  keys: string = environment.encrypt_key;
  constructor(private apiServive: ApiService, private activatedRoute: ActivatedRoute, private sharedService: SharedService
    , private translate: TranslateService, public reportService: ReportService,
    private decimalPipe: DecimalPipe) { 
      this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
      if(this.placeCode){
        this.getAllowedAttributes();
      }
      this.sharedService.selectedCity$.subscribe(res=>{
        this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
        this.getAllowedAttributes();        
      })
    }

  /**
   * This is a function to use get allow attribute list of property
   */
  getAllowedAttributes() {
    this.apiServive.get(`${environment.miBaseUrl}${apiEndPoints.userSettings}?status=1&search_text=available_map_attributes`).subscribe(
      {
        next: (res: any) => {
          if(res.status == 200 && res.data?.length>0 && res.data[0]){
            let att = res.data[0];
            this.propertyAttributes = att.value ? (att.value && att.value.split(',')) : [];
          }
        },
        error: (err) => {

        }
      }
    )
  }
  public setZoom(zoom: number): void {
    this.zoom.next(zoom);
  }

  public setCentroid(value: any) {
    this.mapCentroid = value;
  }
  public getCentroid() {
    return this.mapCentroid;
  }
  public getLegendcolor() {
    let color_list = ["#800026", "#E31A1C", "#FEB24C", "#FEB24C", "#FED976"];
    return color_list;
  }

  public getLayerData() {
    let layer_data: any =
    {
      "sort_order": "1",
      "layer_name": "property_details",
      "store_name": "MI",
      "layer_type": "wms-pbf",
      "popup_exclude": ["lat", "lon", "wkt", "property_name", "property_uid"],
      "legend": [{ "legend": "max_unit_area", "label": "Land Area", "type": "number", "data": ["0", "200", "500", "1000"], "display": true },
      { "legend": "unit_category", "label": "Unit Type", "type": "like", "data": ["apartment", "office", "retail", "house"], "display": false },
      {
        "legend": "property_category", "label": "Category", "type": "like", "data": ["club", "retail", "government", "education", "health", "compound",
          "multi_story", "villa", "centers", "parking"], "display": false
      },
      { "legend": "property_grade", "label": "Grade", "type": "equals", "data": ["grade_a+", "grade_a", "grade_b", "grade_c", "grade_d"], "display": false }],
      "filter": [{ "name": "max_unit_area", "type": "number" }, { "name": "unit_category", "type": "like" },
      { "name": "unit_type", "type": "like" }, { "name": "property_category", "type": "like" },
      { "name": "neigh_name", "type": "equals" }, { "name": "property_name", "type": "equals" },
      { "name": "furnishing_status", "type": "equals" }, { "name": "property_grade", "type": "equals" },
      { "name": "setback", "type": "number" }, { "name": "frontage", "type": "number" },
      { "name": "street_name", "type": "equals" }, { "name": "furnishing_status", "type": "equals" },
      { "name": "corner", "type": "equals" }, { "name": "zoning", "type": "equals" },
      { "name": "transaction_value", "type": "number" }, { "name": "property_age", "type": "number" },
      { "name": "monthly_rent", "type": "number" }, { "name": "record_type", "type": "equals" },
      { "name": "lease_price", "type": "number" }, { "name": "year", "type": "equals" }],
      "map_layers": [{ "label": "Block", "layer_name": "blocks", "store_name": "gisdb", "color": "FFA500", "display": false, "type": "wms" },
      { "label": "Roads", "layer_name": "road_poly", "store_name": "gisdb", "color": "808080", "display": false, "type": "wms" },
      { "label": "Parcel", "layer_name": "gisdb:allparcels", "store_name": "gisdb", "color": "FF5733", "display": false, "type": "wms" },
      { "label": "Project", "layer_name": "project_layer", "store_name": "gisdb", "color": "EE7435", "display": false, "type": "pbf", "maxZoom": 25, "minZoom": 16 },
      { "label": "Metro", "layer_name": "metro_layer", "store_name": "gisdb", "color": "0000FF", "display": false, "type": "wms" },
      { "label": "Neighbourhood", "layer_name": "neighbourhoods", "store_name": "gisdb", "color": "5A5A5A", "display": false, "type": "pbf", "maxZoom": 15, "minZoom": 10 }]
    };
    return layer_data;
  }

  getReportCount(polygon?: any) {
    let queryParams={}
    this.activeRoute$ = this.activatedRoute.queryParams.subscribe((params) => {
      queryParams = params;
            // this.tenantName.set('')
      this.tenantDataState$.next('');
    })
    let neigh_name = null, property_category = null, unit_category = null, year: any = null;

    // let filter: any = {...this.activatedRoute.snapshot.queryParams};
    let filter: any = queryParams;
    
    let filterObj = this.reportService?.getCurrentReport()?.filter; /// To fetch reports Filter Object
    let report_id = null;
    if (filter?.year) {
      year = this.reportService.yearMaxValue(filter?.year);
    }else{
      year = new Date().getFullYear();
    }
    //If Report Filter has map_filter = 'none' then report filter should not be applicable for the map data count to make the map data parcels and count in sync
    // Array.isArray(filterObj) && filterObj?.forEach((r: any) => {
    //   if (Object.keys(filter).filter((val: any) => val == r.col_name).length > 0) {
    //     if (r.map_filter == 'none') filter = Object.keys(filter).filter(objKey => objKey !== r.col_name).reduce((newObj: any, key) => {
    //       newObj[key] = filter[key];
    //       return newObj;
    //     }, {});
    //   }
    // })
    Array.isArray(filterObj) && filterObj?.forEach((r: any) => {
      if (Object.keys(filter).filter((val: any) => val == r.col_name).length > 0) {
        if (r.map_filter == 'none') filter = Object.keys(filter).filter(objKey => objKey !== r.col_name).reduce((newObj: any, key) => {
          newObj[key] = filter[key];
          return newObj;
        }, {});
      }
    })
    if (filter.rid) {
      report_id = filter.rid;
    }
    if (filter.oid)
      filter = Object.keys(filter).filter(objKey => objKey !== "oid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.rid)
      filter = Object.keys(filter).filter(objKey => objKey !== "rid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.gid)
      filter = Object.keys(filter).filter(objKey => objKey !== "gid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.Centroid)
      filter = Object.keys(filter).filter(objKey => objKey !== "Centroid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.Zoom)
      filter = Object.keys(filter).filter(objKey => objKey !== "Zoom").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.neigh_name) {
      neigh_name = filter.neigh_name;
      filter = Object.keys(filter).filter(objKey => objKey !== "neigh_name").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }
    if (filter.property_category) {
      property_category = filter.property_category;
      filter = Object.keys(filter).filter(objKey => objKey !== "property_category").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }
    if (filter.unit_category) {
      unit_category = filter.unit_category;
      filter = Object.keys(filter).filter(objKey => objKey !== "unit_category").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }

    // if (polygon && polygon.length > 1) {
    //   filter["polygon"] = polygon;
    //   neigh_name = null;
    // }

    if (filter.zoom)
      filter = Object.keys(filter).filter(objKey => objKey !== "zoom").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.centroid)
      filter = Object.keys(filter).filter(objKey => objKey !== "centroid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.polygon){
      filter = Object.keys(filter).filter(objKey => objKey !== "polygon").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }
    if(filter?.amenities){
      this.amenitiesItemList.next(filter?.amenities);
      const items = filter.amenities.split(",");
      const resultArray = items.map((item:any) => {
        const parts = item.split("*");
        return {
          uid: parts[1],
          distance: parts[2]
        };
      });
      filter.amenities = resultArray;
    } else {
      this.amenitiesItemList.next(false);
    }
    let url = `${environment.miUrl}${apiEndPoints.reportMapCluster}`;
    if (filter) url += `?report_id=${report_id}&filter=${encodeURIComponent(JSON.stringify(this.reportService.filterData(filter)).replace(/[+]/g, '%2B'))}`;
    if (polygon) {
      url += `&polygon=${polygon}`;
      neigh_name = null;  //// If polygon is provided neighbourhood filter will be removed.
    }
    if (neigh_name && neigh_name.length > 1) url += `&neigh_name=${neigh_name}`;
    if (year) url += `&year=${year}`;
    if (property_category && property_category.length > 1) url += `&property_category=${property_category}`;
    if (unit_category && unit_category.length > 1) url += `&unit_category=${unit_category}`;

    return this.apiServive.get(url);
  }

  getGeoJsonLayerData(polygon?: any, legend?:string, zoom?:any) {
    let queryParams={}
    this.activeRoute$ = this.activatedRoute.queryParams.subscribe((params) => {
      queryParams = params;
    })
    let neigh_name = null, property_category = null, unit_category = null, year: any = null;

    // let filter: any = {...this.activatedRoute.snapshot.queryParams};
    let filter: any = queryParams;
    
    let filterObj = this.reportService?.getCurrentReport()?.filter; /// To fetch reports Filter Object
    let report_id = null;
    if (filter?.year) {
      year = this.reportService.yearMaxValue(filter?.year);
    }else{
      year = new Date().getFullYear();
    }
    //If Report Filter has map_filter = 'none' then report filter should not be applicable for the map data count to make the map data parcels and count in sync
    // Array.isArray(filterObj) && filterObj?.forEach((r: any) => {
    //   if (Object.keys(filter).filter((val: any) => val == r.col_name).length > 0) {
    //     if (r.map_filter == 'none') filter = Object.keys(filter).filter(objKey => objKey !== r.col_name).reduce((newObj: any, key) => {
    //       newObj[key] = filter[key];
    //       return newObj;
    //     }, {});
    //   }
    // })
    Array.isArray(filterObj) && filterObj?.forEach((r: any) => {
      if (Object.keys(filter).filter((val: any) => val == r.col_name).length > 0) {
        if (r.map_filter == 'none') filter = Object.keys(filter).filter(objKey => objKey !== r.col_name).reduce((newObj: any, key) => {
          newObj[key] = filter[key];
          return newObj;
        }, {});
      }
    })
    if (filter.rid) {
      report_id = filter.rid;
    }
    if (filter.oid)
      filter = Object.keys(filter).filter(objKey => objKey !== "oid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.rid)
      filter = Object.keys(filter).filter(objKey => objKey !== "rid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.gid)
      filter = Object.keys(filter).filter(objKey => objKey !== "gid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.Centroid)
      filter = Object.keys(filter).filter(objKey => objKey !== "Centroid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.Zoom)
      filter = Object.keys(filter).filter(objKey => objKey !== "Zoom").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.neigh_name) {
      neigh_name = filter.neigh_name;
      filter = Object.keys(filter).filter(objKey => objKey !== "neigh_name").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }
    if (filter.property_category) {
      property_category = filter.property_category;
      filter = Object.keys(filter).filter(objKey => objKey !== "property_category").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }
    if (filter.unit_category) {
      unit_category = filter.unit_category;
      filter = Object.keys(filter).filter(objKey => objKey !== "unit_category").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }

    // if (polygon && polygon.length > 1) {
    //   filter["polygon"] = polygon;
    //   neigh_name = null;
    // }

    if (filter.zoom)
      filter = Object.keys(filter).filter(objKey => objKey !== "zoom").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.centroid)
      filter = Object.keys(filter).filter(objKey => objKey !== "centroid").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    if (filter.polygon){
      filter = Object.keys(filter).filter(objKey => objKey !== "polygon").reduce((newObj: any, key) => {
        newObj[key] = filter[key];
        return newObj;
      }, {});
    }
    if(filter?.amenities){
      const items = filter.amenities.split(",");
      const resultArray = items.map((item:any) => {
        const parts = item.split("*");
        return {
          uid: parts[1],
          distance: parts[2]
        };
      });
      filter.amenities = resultArray;
    }
    // let url =  `https://bi.dev10.in/clickhouse/fetchDataFilter`;//
    let url = `${environment.miUrl}${apiEndPoints.geoJsonParcelLayer}`;
    if (filter) url += `?report_id=${report_id}&filter=${encodeURIComponent(JSON.stringify(this.reportService.filterData(filter)).replace(/[+]/g, '%2B'))}`;
    if (polygon) {
      url += `&polygon=${polygon}`;
      if(!zoom) neigh_name = null;  //// If polygon is provided neighbourhood filter will be removed.
    }
    if (neigh_name && neigh_name.length > 1) url += `&neigh_name=${neigh_name}`;
    if (year) url += `&year=${year}`;
    if(zoom) url+= `&zoom=${zoom}`;
    if (property_category && property_category.length > 1) url += `&property_category=${property_category}`;
    if (unit_category && unit_category.length > 1) url += `&unit_category=${unit_category}`;
    // return url;
    return this.apiServive.get(url);
  }

  getTransactionData() {
    let year = new Date().getFullYear();
    let url = `${environment.miUrl}${apiEndPoints.dashboardMapTransaction}?year=${year}`;
    return this.apiServive.get(url);
  }

  async getParcelInfo(uid: any, type:string='property') : Promise<any>{
    let queryParams = this.reportService.queryParams;
    let year = (queryParams?.year)? this.reportService.yearMaxValue(queryParams.year.toString()) : new Date().getFullYear();
    let url: string = `${environment.miUrl}${apiEndPoints.mapDetailsPopup}?uid=${uid}&type=${type}&year=${year}`;
    return lastValueFrom(this.apiServive.get(url));
  }
  createPoup(type:string, data:any, class_name = ''): string {
    const property = `<div class="popup-header map-amenities-card">
        <div class="directionLtr"><h6 class="sub-header">${data.property_name}</h6>
        </div></div>`;
    return property;
  }
  popup_content(type: string, propertiesdata: any, allowedAttributes: any, btn_label?: any, tenantData:any='', className?:string) {
    let dupicateKey: any = [];
    let properties = {...propertiesdata}
    
    let accessAttributes = allowedAttributes ? allowedAttributes.map((str: any) => str.split(','))[0] : '';
    let current_langID = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrentLangID);
    let that = this;
    let popup_exclude = ["lat", "lon"];
    // let propertyAttrShowList = ["proj_name","property_name","neigh_name","block_name","property_grade","build_year","year","property_category","max_unit_area","min_unit_area","unit_size","unit_type","unit_category","zoning","max_lease_price","min_lease_price","lease_price","total_floors","total_units","furnishing_status","corner","frontage","transaction_value","transaction_date","setback","land_area","record_type","builtup_area","residential_area"]
    let propertyAttrShowList = this.propertyAttributes;
    let tenantList = ["neigh_name","land_area", "gla", "occupancy", "blended_lease_rate", "total_shop", "is_layout"];
    if(type == 'tenant'){
        properties = {
          neigh_name: propertiesdata.neigh_name,
          land_area: propertiesdata.land_area,
          gla: propertiesdata.gla,
          total_shop: propertiesdata.total_shop,
          is_layout: propertiesdata.is_layout,
          occupancy: propertiesdata.occupancy,
          blended_lease_rate: propertiesdata.blended_lease_rate,
          lat: propertiesdata.lat,
          lon: propertiesdata.lon,
          property_uid: propertiesdata.property_uid,
          shop_name: propertiesdata.shop_name,
          shop_label: propertiesdata.shop_label,
          property_name: propertiesdata.property_name
      };
    }
    
    //for dashboard popup
    let type_data = [
      { name: this.translate.instant("Transaction_Details"), keys: ["transaction_value", "trans_sale_date", "zoning", "vacant"] },
      { name: this.translate.instant("Property_Details"), keys: (type == 'tenant')? tenantList :propertyAttrShowList }];
    //for reports popup property project and Neighbourhood
    if (type == 'property' || type == 'project') {
      type_data = [
        { name: this.translate.instant("Land_Details"), keys: ["setback_area", "frontage", "block_name", "corner", "no_of_blocks", "shape_area", "neigh_name"] },
        { name: btn_label === 'Neighbourhood' ? this.translate.instant("neighbourhood_Details") : btn_label === 'Project' ? this.translate.instant("project_Details") : this.translate.instant("Property_Details"), keys: propertyAttrShowList }]
    }
    let PropertyName = this.translate.instant('property_name');
    let ProjectName =  this.translate.instant('proj_name');
    let PACINo = this.translate.instant('PACI No');
    let NeighborhoodName = this.translate.instant('neigh_name');
    let PropertyUID = this.translate.instant('PropertyUID');
    let property_name = properties.ref_no ? properties.ref_no : properties.property_name ? properties.property_name.replace(/_/g, " ") : properties.property_uid ? properties.property_uid :
      (typeof properties.proj_name == 'string') ? JSON.parse(properties.proj_name)[current_langID] : (typeof properties.neigh_name == 'string') ? JSON.parse(properties.neigh_name)[current_langID] : '';
    let sub_header = properties.ref_no ? PACINo : properties.property_name ? PropertyName : properties.property_uid ? PropertyUID :
      properties.proj_name ? ProjectName : properties.neigh_name ? NeighborhoodName : '';
    let currencyCode = that.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode) ? that.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode) : "";
    let content = document.createElement("div");
    let img = (type == 'tenant')? '../../../../assets/images/store.svg': '../../../../assets/images/house.svg';
    content.innerHTML = `<div class="popup-header">
        <img src=${img} style="margin:5px 5px 0;padding:4px;width:32px;" />
        <div class="directionLtr"><h6 class="sub-header">${type == 'tenant'? property_name: sub_header}</h6>` +
        `<h6 class="sub-value">${type == 'tenant'? '<b>'+PACINo+':</b> - ': property_name}</h6>
        </div></div>`
      + `<hr style="background: #EAEAEA;width: 100%; position: relative; opacity: 0.2;">`;
    let containers = document.createElement("div");
    containers.style.paddingBottom = "6px";
    containers.style.paddingRight = "5px";
    containers.style.paddingLeft = "5px";
    for (let i = 0; i < type_data.length; i++) {
      let inner_content = document.createElement("div");
      inner_content.style.border = "1px #3774E8 solid";
      // inner_content.style.margin = "5px";
      inner_content.style.borderRadius = "5px";
      inner_content.style.paddingTop = "5px";
      inner_content.style.paddingBottom = "2px";
      inner_content.style.bottom = "10px";
      // inner_content.style.maxHeight = "101px";
      inner_content.style.maxHeight = (type_data.length > 1 && type != 'tenant')?"72px":"152px";
      inner_content.style.overflowY = "auto";
      let common_data = type_data[i].keys.filter((k:any) => Object.hasOwn(properties, k), type_data[i]);
      if (common_data.length >= 1) {
        if (accessAttributes.length > 1) {
          if (common_data.filter((value:any) => accessAttributes.includes(value)).length >= 1) {
            containers.innerHTML += `<div class="list-title"><span>${type_data[i].name}</span></div>`;
          }
        } else {
          containers.innerHTML += `<div class="list-title"><span>${type_data[i].name}</span></div>`;
        }
        // ${this.placeCode == environment.BHRCHECK? 'sqft':'sqm'} // BHR check
        
        for (const key in properties) {
          let value = properties[key];
          let value1 = properties[key];
          if(key == "transaction_value") properties['trans_sale']=properties[key];
           // 
           if(type == 'tenant'){
            if (key.includes("blended_lease_rate")) if (properties[key]) value = `${value} ${this.translate.instant(currencyCode)}/${this.translate.instant('sqm_unit')}`; else value = "-";
            if (key.includes("occupancy")) if (properties[key]) value = `${value ? parseInt(value).toLocaleString('en-US') : value}%`; else value = "-";
            if (key.includes("total_shop")) if (properties[key]) value = `${value ? parseInt(value).toLocaleString('en-US') : value}`; else value = "-";
            if (key.includes("is_layout")) if (properties[key]) value = `${value ? this.translate.instant('Available') : this.translate.instant('Not Available')}`; else value = this.translate.instant('Not Available');
          }
          // 
          if (value) { value = value.toString().replace(/_/g, " "); value = value.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase()); }
          if (key.includes("size") || key.includes("area") || key.includes("setback") || key.includes("gla")) if (properties[key]) value = `${value ? parseInt(value).toLocaleString('en-US') : value} ${this.translate.instant('sqm_unit')}`; else value = "-";
          if (key.includes("frontage")) if (properties[key]) value = `${value && value > 0 ? (this.decimalPipe.transform((value), '1.0-1') + this.translate.instant('meter')) : '-'}`; else value = "-";
          if (key.includes("date") || key.includes("year")) value = this.sharedService.formatDateUTC(value, false);
          if (key == "property_grade") { value = value?.replaceAll("P", "+"); value = value?.replaceAll("p", "+"); };
          if (key == 'vacant') {
            //  value = (value == 1) ? this.translate.instant('Vacant') : this.translate.instant('Developed')
            if (value == 2) {
              value = this.translate.instant('Part Developed')
            } else if (value == '0') {
              value = this.translate.instant('Developed');
            } else {
              value = this.translate.instant('Vacant')
            }
          }
          if ((key == "neigh_name" || key == "proj_name") && type == 'project') value = (typeof properties[key] == 'string') ? JSON.parse(properties[key])[current_langID] : properties[key];
          if ((key.includes("sale") || key.includes("value") || key.includes("price")) && !key.includes("date")) value = `${value ? parseInt(value).toLocaleString("en-US") : "-"} ${this.translate.instant(currencyCode)}`;
          if (!popup_exclude.includes(key) && !dupicateKey.includes(key) && type_data[i].keys.includes(key)) {
            if(type == 'tenant'){
              dupicateKey.push(key);
              let classname = (value1 && key == 'is_layout')?'available':'';
              inner_content.innerHTML += `<div class="value-item-list">
                  <span class="first-val">${this.translate.instant(key)} : </span>` +
                  `<span class="secend-val ${classname}" style="direction: ltr; text-transform: ${value && (value?.includes('sqm') || value?.includes('sqft')) ? 'lowercase': 'capitalize'};" title="${value ? value : "-"}"> ${value1 && key == 'is_layout'?`<span>${value}</span>`:value ? value : key === 'corner' ? 'No Corner' : "-"}
                  </span>
                </div>`;
            }
            if (type != 'tenant' && (accessAttributes.includes(key) || !accessAttributes)) { //allowedAttributes match code with array
              dupicateKey.push(key);
              inner_content.innerHTML += `<div class="value-item-list">
                  <span class="first-val">${this.translate.instant(key)} : </span>` +
                  `<span class="secend-val" style="direction: ltr; text-transform: ${value && (value?.includes('sqm') || value?.includes('sqft')) ? 'lowercase': 'capitalize'};" title="${value ? value : "-"}">${value ? value : key === 'corner' ? 'No Corner' : "-"}
                  </span>
                </div>`;
            }
          }
        }
        if (accessAttributes.length > 1) {
          if (common_data.filter((value:any) => accessAttributes.includes(value)).length >= 1) containers.append(inner_content);
        } else containers.append(inner_content);
      }
    }
    let LockReport = this.reportService.getLockReport();
    content.append(containers);
    let actionbtn = document.createElement("div");
    actionbtn.style.display = "flex";
    actionbtn.style.justifyContent = "end";
    if (type == "transaction" || type == "property" || type == 'project' || type == 'tenant') {
      let btn_content: any = document.createElement("button");
      btn_content.innerHTML = this.translate.instant('explore_property')//`Explore property`;
      if (btn_label?.length > 1) btn_content.innerHTML =  this.translate.instant(`explore_${btn_label}`);
      btn_content.classList.add('explore-btn');
      if(LockReport){
        let lockicon = document.createElement("img");
        lockicon.src = 'assets/images/Lock-disable.png'
        lockicon.classList.add('lock')
        btn_content.appendChild(lockicon);
        btn_content.disabled = true;
      }
      actionbtn.appendChild(btn_content);
      content.append(actionbtn);
      btn_content.onclick = () => {
        if(type == 'tenant'){
          this.reportService.reportView$.next(ReportsViewEnum.mapGridView);
          this.reportService.showTenantMap$.next(properties);
        } else {
          this.reportService.showTenantMap$.next(false);
        }
        if(type == 'tenant'){
          setTimeout(()=>{this.tenantDataState$.next(tenantData)}, 500) 
           this.reportService.showTenantMap$.next(properties);
           this.reportService.showTenantPrevPropertyMap$.set(properties);
           this.updateTenentBounds$.next(true)
         } else {
           this.reportService.showTenantMap$.next(false);
           this.tenantDataState$.next('');
           this.updateTenentBounds$.next(false)
         }
        // 
        let queryParam: any = [];
        queryParam = this.reportService.queryParams;
        let parems_p_cat = (queryParam['property_category'])? queryParam['property_category'].toLowerCase():null;
        let data_p_cat = (properties?.property_category)? properties.property_category.toLowerCase():null;
        if(data_p_cat == 'multi_story_residential' && parems_p_cat?.includes('multi_story_residential')){
          this.reportService.multi_story_residential$$.set(true)
        } else this.reportService.multi_story_residential$$.set(false)
        // 
        if (this.reportService.getCurrentReport()?.name === 'transactions' || this.reportService.getCurrentReport()?.name === 'house_and_villa'
          || this.reportService.getCurrentReport()?.name === 'inventory_house' || this.reportService.getCurrentReport()?.name === 'transactions_house_and_villa'
          || this.reportService.getCurrentReport()?.name === 'transaction_inv' || this.reportService.getCurrentReport()?.name === 'inventory' || this.reportService.getCurrentReport()?.name === 'occupancy_and_rental') {
          this.reportService.reportView$.next(ReportsViewEnum.mapGridView);
          this.propertyinfotoggle.next(false);
          setTimeout(() => { this.reportService.transactionPropertyDetails.next(properties) }, 20);
        } else {
          this.propertyinfotoggle.next(true);
          this.selected_propertydetails.next(properties);
        }
        // this.selected_property = properties;
      }
    }

    return content;
  }


  getHousevillaLandInfo(queryParams: any) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseVillaLandInformation}${changeParams}`;
    return this.apiServive.get(url);
  }

  getHousevillaAreaInfo(queryParams: any) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseVillaAreaInformation}${changeParams}`;
    return this.apiServive.get(url);
  }
  
    public mixKey(str: string) {
      this.keys += this.keys + str;
  }

    public encryptKey(text: string) {
      var rand_index = Math.floor(Math.random() * 10);
      var rand_value = this.rand_array[rand_index];

      this.mixKey(text);
      var str_final_char = rand_value;
      rand_index++; // one increment to avoid zero index
      for (var i = 0; i < text.length; i++) {
          var str = text.substr(i, 1);
          var str_value = str.charCodeAt(0) - rand_index;
          var str_chr = String.fromCharCode(str_value);
          str_final_char += str_chr;

          var keychar = this.keys.substr(i, 1);
          var keychr_value = keychar.charCodeAt(0) - rand_index;
          var key_chr = String.fromCharCode(keychr_value);
          str_final_char += key_chr;
      }
  
      return window.btoa(str_final_char);
  }
    public decryptKey(text: string) {
      if(this.base64regex.test(text)==false){
        text= this.encryptKey(text);
      }
      var original_key = this.keys;
      var decoed_text = window.atob(text);  //method decodes a base-64 encoded string.// The atob() method decodes a base-64 encoded string. Note. The atob() method decodes a string that has been encoded by the btoa()
      var str_final_char = '';
      var key_final_char = '';
      // one increment to avoid zero index
      let rand_index:any;
      for (let i = 0; i < decoed_text.length; i++) {
          var str = decoed_text.substr(i, 1); // extracts a part of a string on given index
          if (i == 0) {
              rand_index = this.rand_array.indexOf(str); // get index of string in range_array
              rand_index++;
              continue;
          }

          if (i % 2 != 0) {
              var str_value = str.charCodeAt(0) + rand_index; // string charcode + range_index
              var str_chr = String.fromCharCode(str_value); // static method returns a string created from the specified sequence of UTF-16 code units.
              str_final_char += str_chr;
          } else {
              var keychr_value = str.charCodeAt(0) + rand_index;
              var key_chr = String.fromCharCode(keychr_value);
              key_final_char += key_chr;
          }
      }
      var keyOriginalLen = original_key.length; //10
      var keyleng = key_final_char.length; //5
      if (keyOriginalLen < keyleng) {
          for (let k = 0; k < original_key.length; k++) {
              if (original_key.substr(k, 1) != key_final_char.substr(k, 1)) {
                  //   console.log('Key not matched');

                  return null;
              }
          }
      } else {
          for (let k = 0; k < key_final_char.length; k++) {
              if (original_key.substr(k, 1) != key_final_char.substr(k, 1)) {
                  // console.log('Key not matched');
                  return null;
              }
          }
      }
      return JSON.parse(str_final_char);
  }

  /**
   * popupOptions function to calcuate x/y position on map area
   * @param e event.target
   * @returns x,y
   */
  popupOptions(e: any, mapWidth:number, mapHeight:number) {
    let data = { class_name: '', x: 0, y: 0 }
    if (e.containerPoint.y < 255) {
      data.class_name = 'topCenter';
      data.y = 55, data.x = 0;
    }
    if (e.containerPoint.y < 255 && e.containerPoint.x < 125) {
      data.class_name = 'topLeft';
      data.y = 15, data.x = 150;
    }
    if (e.containerPoint.y >= 255 && e.containerPoint.x < 125) {
      data.class_name = 'bottomLeft';
      data.y = 40, data.x = 150;
    }
    if (e.containerPoint.y < 255 && e.containerPoint.x > (mapWidth - 125)) {
      data.class_name = 'topRight';
      data.y = 20, data.x = -150;
    }
    if (e.containerPoint.y >= 255 && e.containerPoint.x > (mapWidth - 125)) {
      data.class_name = 'bottomRight';
      data.y = 40, data.x = -150;
    }
    return data;
  }

  filterLegendData(data:any){
    return  data?.map((p:any) => {
       return {
         ...p,
         data: p.data.filter((item:any) => item.layer_status === true)
       };
     })
   }
  ngOnDestroy(): void {
    this.activeRoute$.unsubscribe();
  }
}
