import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportWarehouseRentalService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  warehouseUnitSizePayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  warehouseUnitProperties: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  averageRentRate = new Map();
  occupancyRate = new Map();

  getOverviewSummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.warehouseOverviewReport}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getWarehouseRentalRentrateReport() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.warehouseRentrateReport}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getOverviewSubCategory() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailWarehouseOverviewSubCategory}${changeParams}`;
    return this.apiServive.get(url, false);
  }



  getWarehouseUnitSize(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.warehouseUnitSizePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.warehouseRentunitsizeReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url, false);
  }

  getWarehouseProperties(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.warehouseUnitProperties.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.warehousePropertiesReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url, false);
  }

  downloadTransactionReport(type: string, user_id: number) {
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  downloadExcelUrl() {
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }

  formatOverViewSummary(data: any) {
    data['totalAverage_rate'] = this.reportService.calcPercentage(data.average_rate.prev_avg_rent, data.average_rate.avg_rent);
    data['totalOccupacy_rate'] = this.reportService.calcPercentage(data.occupacy_rate.prev_occupacy_rate, data.occupacy_rate.occupacy_rate);
    data['untillDate'] = data.average_rate.max_date ? moment.utc(data.average_rate.max_date).local().format('DD MMM YYYY') : null;
    return data;
  }


  downloadRetailRentalReport(type:string, user_id:any){
    const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  // changeOpacity(index: number, value: any, type: string){

  //   if(type === 'averageRentRate'){

  //     if(this.averageRentRate.has(value)){
  //       return this.averageRentRate.get(value);
  //     } else {
  //       this.averageRentRate.set(value, this.getOpacity(index));
  //       return this.getOpacity(index);
  //     }

  //   } else {
  //     if(this.occupancyRate.has(value)){
  //       return this.occupancyRate.get(value);
  //     } else {
  //       this.occupancyRate.set(value, this.getOpacity(index));
  //       return this.getOpacity(index);
  //     }
  //   }

  // }

  // getOpacity(index:number): string{
  //   // Adjust the factor and starting value as per your requirement
  //   return (1 - (index * 0.1)).toFixed(2);
  // }
}
