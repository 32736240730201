import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { TransactionsReportConstant } from './report-transactions.constants';
import { ReportTransactionService } from './report-transactions.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SharedService } from 'src/app/common/services/shared.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TransactionsMenuEnum } from './report-transactions.enum';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DataType } from 'src/app/common/models/enums/common.enum';
import { Label } from '@amcharts/amcharts5';
import { ReportTableDropdown } from '../../models/reports.model';

@Component({
  selector: 'app-report-transactions',
  templateUrl: './report-transactions.component.html',
  styleUrls: ['./report-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportTransactionsComponent implements OnInit, OnChanges {

  @Input() params: any;
  @Input() multi_story_residential: boolean = false;
  // value: string;
  //   id: any;
  transactionType : ReportTableDropdown = {
    label : "reports.transactionType",
    data : [{
      value :'Actual',
        id : 'Actual'
    }, {
      value : 'Estimate',
      id : 'Estimation'
    }, {
      value : 'Quote',
      id : 'Quote'
    }],
    default: "Actual",
    maxSelect: 3,
    reportType : true
  }
  transactionsMenuEnum = TransactionsMenuEnum;
  langName: any;
  transactionMenu = TransactionsReportConstant.transactionMenu;
  propertyTransactionColumns = TransactionsReportConstant.propertyTransactionColumns;
  unitTransactionColumns = TransactionsReportConstant.unitTransactionColumns;
  propertyTransactionExpandColumns = TransactionsReportConstant.propertyTransactionExpandColumns
  unitTransactionExpandColumns  = TransactionsReportConstant.unitTransactionExpandColumns
  currentScroll: any = '';
  UnitTypeEnum = UnitTypeEnum;
  propertyTransactiondata: any = [];
  propertyTransactionCount: number = 0;
  propertyTransactionActualCount : number = 0;
  propertyTransactionEstimatedCount : number = 0;
  unitTransactiondata: any = [];
  unitTransactionCount: number = 0;
  unitTransactionActualCount: number = 0;
  unitTransactionEstimatedCount: number = 0;
  transactionOverview: any = null;
  showPropertyDetails: boolean = false;
  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = null;
  transactionPropertyDetails$ = new Subscription();
  isFirstTime = true;
  unitTransactiondataLoading: boolean = false;
  propertyTransactiondataLoading: boolean = false;
  overViewLoading: boolean = false;
  chartView = ReportsChartEnum;
  downloadLoading: any = {
    a: false,
    b: false
  };
  BHRCHECK = environment.BHRCHECK
  propertyTypes:any[] = [];
  unitTypes:any[] = [];
  constructor(
    private reportTransactionService: ReportTransactionService,
    public reportService: ReportService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private translate: TranslateService
  ){
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit(){
    this.propertyTypes = this.transactionType.data.map(e=> {return e.id});
    this.unitTypes = this.transactionType.data.map(e=> {return e.id});
    this.getReports();
    this.isFirstTime = false;
    this.transactionPropertyDetails();
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);

    this.propertyTransactionColumns.map((e:any) => {
      if(this.placeCode == this.BHRCHECK){
        if (e.header == 'transaction_rate') e['symbol'] = DataType.currencyWithSquareFeet;
      }else{
        if (e.header == 'transaction_rate') e['symbol'] = DataType.currencyWithSquareMeter;
      }
    })

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.reportTransactionService.propertyTransactionPayload.pageNumber = 0;
      this.reportTransactionService.unitTransactionPayload.pageNumber = 0;
      this.propertyInfo = null;
      this.showPropertyDetails = false;
      this.getReports();
    }
  }

  transactionPropertyDetails(){
    this.transactionPropertyDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {
      if(data){
        this.showPropertyDetails = true;
        this.propertyInfo = data;
      } else {
        this.showPropertyDetails = false;
        this.propertyInfo = null;
      }

    });
  }


  getReports(){
    this.getTransactionSummary();
    this.getPropertyTransaction(null, this.propertyTypes.toString());
    this.getUnitTransaction(null, this.unitTypes.toString());
  }

  getTransactionSummary(year = null){
    this.overViewLoading = true;
    this.reportTransactionService.getTransactionSummary(year).subscribe(
      {next:(res: any) => {
        if(res.status === 200){
          this.transactionOverview = this.reportTransactionService.formatOverViewTransaction(res.data[0])
        } else {
          this.transactionOverview = null;
        }
        this.overViewLoading = false;
      },
      error: (error:any) => {
        this.transactionOverview = null;
        this.overViewLoading = false;
      }}
    )
  }

  getPropertyTransaction(year = null, type:string = ''){
    this.propertyTransactiondataLoading = true;
    this.reportTransactionService.getPropertyTransaction(this.reportTransactionService.propertyTransactionPayload, year, type).subscribe(
      {next:(res: any) => {

        if(res.status === 200){
          this.propertyTransactiondata = res.data.map((value: any) => {
            value['record_type'] = this.reportType(value);
            value['transaction_date']= value.transaction_date ? moment.utc(value.transaction_date).local().format('DD MMM YYYY') : null;
            if(value?.details) {
              for(let i = 0; i < value?.details.length; i++) {
                value.details[i]['transaction_date'] = value?.details[i]?.transaction_date ? moment.utc(value.details[i].transaction_date).local().format('DD MMM YYYY') : null
                value.details[i]['record_type'] = this.reportType(value.details[i])
              }
            }
            if(value.lat && value.lon){
              value.centroid = {
                coordinates: [value.lon, value.lat]
              }
            }
            // if(this.placeCode == environment.BHRCHECK){
            //   value['transaction_rate'] = (value.value / value.land_area);
            //   value['property_transaction_rate'] = ((value.value / value.land_area)/10.764);
            // }else{
            //   value['property_transaction_rate'] = ((value.value / value.land_area));
            // }
            // //extracted the transaction rate (Transaction Value / Unit  Area)

            // if(value['property_transaction_rate'] == Infinity || value['transaction_rate'] == -Infinity) value['property_transaction_rate'] = 0;
            return value;
          });
         if(this.reportTransactionService.propertyTransactionPayload.pageNumber == 0){
          this.propertyTransactionCount = res.count;
          this.propertyTransactionActualCount = res.actual_count;
          this.propertyTransactionEstimatedCount = res.estimate_quote_count;
          this.transactionMenu.map((e:any)=>{
            if(e.name ==TransactionsMenuEnum.property_transaction){
              e['count'] = this.reportService.formatInternationNum(this.propertyTransactionCount);
            }
          })
         }
        } else {
          this.propertyTransactiondata = [];
          this.propertyTransactionCount = 0;
          this.propertyTransactionActualCount = 0;
          this.propertyTransactionEstimatedCount = 0;
          if (this.reportTransactionService.propertyTransactionPayload.pageNumber == 0) {
            this.transactionMenu.map((e:any)=>{
              if(e.name ==TransactionsMenuEnum.property_transaction){
                e['count'] = 0;
              }
            })
          }
        }
        this.propertyTransactiondataLoading = false;
      },
      error: (error:any) => {
        this.propertyTransactiondata = [];
        this.propertyTransactionCount = 0;
        this.propertyTransactiondataLoading = false;
        if (this.reportTransactionService.propertyTransactionPayload.pageNumber == 0) {
          this.transactionMenu.map((e:any)=>{
            if(e.name ==TransactionsMenuEnum.property_transaction){
              e['count'] = 0;
            }
          })
        }
      }}
    )
  }

  getUnitTransaction(year = null, type:string= ""){
    this.unitTransactiondataLoading = true;
    this.reportTransactionService.getUnitTransaction(this.reportTransactionService.unitTransactionPayload, year, type).subscribe(
      {next:(res: any) => {
        if(res.status === 200){
          this.unitTransactiondata = res.data.map((value: any) => {
            value['record_type'] = this.reportType(value)
            value['transaction_date']= value.transaction_date ? moment.utc(value.transaction_date).local().format('DD MMM YYYY') : null;
            if(value?.details) {
              for(let i = 0; i < value?.details.length; i++) {
                value.details[i]['transaction_date'] = value?.details[i]?.transaction_date ? moment.utc(value.details[i].transaction_date).local().format('DD MMM YYYY') : null
                value.details[i]['record_type'] = this.reportType(value.details[i])
                //
                value.details[i]['property_name'] = null;
                value.details[i]['unit_uid'] = null;
              }
            }
            if(value.p_lat && value.p_lon){
              value.centroid = {
                coordinates: [value.p_lon, value.p_lat]
              }
            }
            //extracted the transaction rate (Transaction Value / Unit  Area)
            value['transaction_rate'] = value.transaction_rate;//value.value / value.u_unit_area;
            // if(value['transaction_rate'] == Infinity || value['transaction_rate'] == -Infinity) value['transaction_rate'] = 0;
            return value;
          });
          if(this.reportTransactionService.unitTransactionPayload.pageNumber == 0){
            this.unitTransactionCount = res.count;
            this.unitTransactionActualCount = res.actual_count;
            this.unitTransactionEstimatedCount = res.estimate_quote_count;
            this.transactionMenu.map((e:any)=>{
              if(e.name ==TransactionsMenuEnum.unit_transaction){
                e['count'] = this.reportService.formatInternationNum(this.unitTransactionCount);
              }
            })
           }
        } else {
          this.unitTransactiondata = [];
          this.unitTransactionCount = 0;
          this.unitTransactionActualCount = 0;
          this.unitTransactionEstimatedCount = 0;
          if (this.reportTransactionService.propertyTransactionPayload.pageNumber == 0) {
            this.transactionMenu.map((e:any)=>{
              if(e.name ==TransactionsMenuEnum.unit_transaction){
                e['count'] = 0;
              }
            })
          }
        }
        this.unitTransactiondataLoading = false;
      },
      error: (error:any) => {
        this.unitTransactiondata = [];
        this.unitTransactionCount = 0;
        this.unitTransactionActualCount = 0;
        this.unitTransactionEstimatedCount = 0;
        this.unitTransactiondataLoading = false;
        if (this.reportTransactionService.propertyTransactionPayload.pageNumber == 0) {
          this.transactionMenu.map((e:any)=>{
            if(e.name ==TransactionsMenuEnum.unit_transaction){
              e['count'] = 0;
            }
          })
        }
      }}
    )
  }

  reportType(value: any): string{
    if(value?.prop_type_estater == null || value?.prop_type_estater == 'null'){
      return '-'
    }
    if(value?.prop_type_govt && value?.prop_type_estater && value?.prop_type_govt !== value?.prop_type_estater){
      return `<span><del>${this.translate.instant(value?.prop_type_govt)}</del></span><span class='reportType'>*${this.translate.instant(value?.prop_type_estater)}</span>`;
    } else if(value?.prop_type_govt ) {
      return `<span>${this.translate.instant(value?.prop_type_govt)}</span>`;

    }else if(value?.prop_type_estater ) {
      return `<span>${this.translate.instant(value?.prop_type_estater)}</span>`;

    } else {
      return '-'
    }
  }

  onPageEvent(e: any, report: string){
    if(report === 'property_transaction'){
      if (e.previousPageIndex !== e.pageIndex) {
        this.reportTransactionService.propertyTransactionPayload.pageNumber = (e.pageSize * e.pageIndex);
      }
      this.getPropertyTransaction();
    } else if(report === 'unit_transaction'){
      if (e.previousPageIndex !== e.pageIndex) {
        this.reportTransactionService.unitTransactionPayload.pageNumber = (e.pageSize * e.pageIndex);
      }
      this.getUnitTransaction();
    }

  }

  menuChangeValue(menu: any){

    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  sortData(event: any, report: string){
    let sort = {
      column: event.active,
      order: event.direction
    }
    if(report === 'property_transaction'){
      this.propertyTypes = event?.selectedOptions
      this.reportTransactionService.propertyTransactionPayload.pageNumber = 0;
      this.reportTransactionService.propertyTransactionPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
      this.getPropertyTransaction(null, this.propertyTypes.toString());
    } else {
      this.unitTypes = event?.selectedOptions;
      this.reportTransactionService.unitTransactionPayload.pageNumber = 0;
      this.reportTransactionService.unitTransactionPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
      this.getUnitTransaction(null, this.unitTypes.toString());
    }
  }

  downloadTransactionReport(type: string, module_name:string, key: any){
    this.downloadLoading[key] = true;
    this.reportTransactionService.downloadTransactionReport(type, this.userInfo?.user_id).subscribe(
      {next:(res: any) => {
        this.downloadLoading[key] = false;
        if(res.status === 200){
          const downloadUrl = `${this.reportTransactionService.downloadExcelUrl()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=${module_name}`;
          this.sharedService.downloadExcelUrl(downloadUrl);
          this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));
        } else {
          this.alertService.warn(res.message)
          // this.unitTransactiondata = [];
          // this.unitTransactionCount = 0;
        }
      },
      error: (error:any) => {
        this.downloadLoading[key] = false;
      }}
    )
  }

  scrollPoint(menu: string){
    document.getElementById(`${menu}`)?.scrollIntoView({behavior: "smooth"})
  }

  downloadExcel(event: any, type: string, key: any){
    this.downloadTransactionReport(type, 'transaction', key);
  }

  selectedYear(data: any){
    if(data.reportType === 'property'){
      this.reportTransactionService.propertyTransactionPayload.pageNumber = 0;
      this.reportTransactionService.propertyTransactionPayload.year = data.year;
      this.getPropertyTransaction();
    } else {
      this.reportTransactionService.unitTransactionPayload.pageNumber = 0;
      this.reportTransactionService.unitTransactionPayload.year = data.year;
      this.getUnitTransaction();
    }
  }

  selectionChange(value:any, type:string) {
    if(type == 'property') {
      this.propertyTypes = value;
      this.getPropertyTransaction(null, this.propertyTypes.toString());
    }else {
      this.unitTypes = value
      this.getUnitTransaction(null, this.unitTypes.toString());
    }
  }

  ngOnDestroy(){
    this.transactionPropertyDetails$.unsubscribe()
  }
}
