import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DecimalPipe } from '@angular/common';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsListsService {

  constructor(
    private translateService: TranslateService,
    private _decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private reportService: ReportService
  ) { }

  convertReport(data: any, col: any, year: any){
    data.forEach((e:any)=>{
      if(e['neigh_name']){
        e['neigh_name'] = this.sharedService.getNeighLabel(e['neigh_name']);
      }
    })
    const report = [...this.getUniqueListBy(data, col[0])];
    const reportData = report.map((item: any) => {
      let reportItem = {...item};
      reportItem['expand'] = false;
      reportItem[col[1]] = reportItem?.average ? this.translateService.instant('reports.average') : this.translateService.instant('reports.total');
      if(reportItem?.average && col[2] == 'parking_ratio'){
        reportItem['parking_ratio']=reportItem?.average
      }
      if(year.length > 0){
        year.forEach((yearV: any) => {
          const getDataColum = data.filter(
            (ele: any) =>
            yearV === ele.year &&
              ele[col[0]] === reportItem[col[0]]
          );
          if(reportItem?.average){
            reportItem[`${yearV}`] = getDataColum.length > 0 ? reportItem?.average : 0;
          } else {
            reportItem[`${yearV}`] = getDataColum.reduce(function (a: any, b: any) {
              const total = b.value ? b.value : 0;
              return a + total;
            }, 0);
          }

        });

      }
      reportItem['subReport'] = this.getColumReport(this.convertYearReport(data, col, year), col, reportItem[col[0]]);
      return reportItem;
    })
    return reportData;
  }

  summaryReportYearConversion(data: any){
   const yearData = data.length > 5 ? data.slice(0, 5) : data; 
   return yearData.map((value: any) => value.year);
  }

  summaryReportConversion(data: any, column: any){
    let reportcomponent = this.reportService.getCurrentReport();
    let summary: any = [];
    let yearList = this.summaryReportYearConversion(data);
    let cityCurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    let placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    column.forEach((col: any) => {
      let obj: any = {};
      if(reportcomponent.query.component[0] == 'unit-occupancy-reports' && col.field == 'average_monthly_rent' && placeCode == environment.RYDCHECK){
        obj = { title: this.translateService.instant('reports.avg_yearly_rent') };
      }else{
        obj = { title: this.translateService.instant(col.header) };
      }      
      if(yearList.length > 0){
          yearList.forEach((year : any) => {
            let summaryObj = data.find((sum: any) => year === sum.year);
            let value = summaryObj[col.field] ? this._decimalPipe.transform(summaryObj[col.field], '1.0-1') : '';
            let symbol = col?.cityCode ? this.translateService.instant(cityCurrencyCode) : col?.symbol && col?.symbol.includes('sqm') ? this.translateService.instant(col?.symbol) : col?.symbol;
            if(reportcomponent.query.component[0] == 'unit-occupancy-reports' && col.field == 'occupancy_ratio' && placeCode == environment.RYDCHECK){
              obj[year] = '-'
            }else{
              obj[year] = symbol && value ? `${value} ${symbol}` : value;
            }
            
          })
      }
      summary.push(obj);
      
    })
    
    return summary;
  }

  getColumReport(data: any, col: any, name: any){
    return data.filter((subItem: any) => subItem[col[0]] === name);
  }

  getUniqueListBy(arr: any, key: string) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()];
  }

  convertYearReport(data: any, column: any, year: any) {
    let report: any = [];

    data.forEach((value: any) => {
      let obj = {
        ...value,
      };

      const existData = report.some(
        (ele: any) =>
          ele[column[0]] === obj[column[0]] &&
          ele[column[1]] === obj[column[1]]
      );


      if (!existData) {
        year.forEach((year: any) => {
          const getDataColum = data.find(
            (ele: any) =>
              ele[column[0]] === value[column[0]] &&
              ele[column[1]] === value[column[1]] &&
              ele.year === year
          );
          if (getDataColum) {
            obj[`${year}`] = getDataColum.value;
          }
        });
        report.push(obj);
      }
    });
    return report;
  }

  yearColumnAppend( column: any, yearcol: any[], cityCode: boolean = false, symbol: string = '', field: string = '', convertNumber: boolean = true){
    let col = [...column];
    yearcol.sort((a:any, b:any) =>  (Number(b) - Number(a))); 
    yearcol.forEach((year: any) => {
      let newCol: any = {
          header: field === 'gla' ? `${year} (${this.translateService.instant('gla')})` : year,
          field: field !== '' ? field : year,
          border: true,
          convertNumber: convertNumber,
          cityCode: cityCode
        }
        if(symbol !== ''){
          newCol['symbol'] = symbol;
        }
          col.push(newCol)
    });
    return col;
  }

  removeUnderscores(value: string) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/_/g, ' ');
      return this.camelizeAll(name);
    } else {
      return '';
    }
  }

  camelizeAll(str: string) {
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  }

}
