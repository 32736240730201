import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportMallInventoryService } from './report-mall-inventory.service';
import { MallInventoryReportConstant } from './report-mall-inventory.constants';
import { ReportChartColumn, ReportTableColumn } from '../../models/reports.model';
import { ReportsListsService } from '../../services/reports-lists.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';

@Component({
  selector: 'app-report-mall-inventory',
  templateUrl: './report-mall-inventory.component.html',
  styleUrls: ['./report-mall-inventory.component.scss']
})
export class ReportMallInventoryComponent implements OnInit, OnChanges {

  @Input() params: any;

  chartView = ReportsChartEnum;

  totalColumnReport: any = [];
  totalColumnReportColumn: ReportTableColumn[] = MallInventoryReportConstant.totalColumn;
  neighTrendlReport: any = [];
  neighTrendlReportColumn: ReportTableColumn[] = MallInventoryReportConstant.neighTrendlReporttableColumns;
  inventoryneighTrendlReport: any = [];
  inventoryneighTrendlReportColumn: ReportTableColumn[] = MallInventoryReportConstant.neighTrendlReporttableColumns;
  mallInventoryYearlyIncrementalReport: ReportChartColumn[] = [];
  mallInventoryYearlyUnitsReport: ReportChartColumn[] = [];
  mallInventoryYearlyOccupancyReport = [];
  totalLoading = true;
  mallInventoryYearlyUnitsLoading = false;
  mallInventoryYearlyOccupancyLoading = false;
  mallInventoryYearlyIncrementalLoading = false;
  inventoryneighTrendLoading = false;
  neighTrendlReportLoading = false;
  
  constructor(
    private reportMallInventoryService: ReportMallInventoryService,
    private reportsListsService: ReportsListsService
  ){}

  ngOnInit(){
    this.getReports();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.getReports();
    }
  }
  
  getReports(){
    this.getMallInventoryTotalReport();
    this.getMallInventoryYearlyUnitsReport();
    this.getMallInventoryYearlyOccupancyReport();
    this.getMallInventoryYearlyIncrementalReport();
    this.getMallInventoryNeighTrendlReport();
    this.getinventoryNeighTrendlReport();
  }

  getMallInventoryTotalReport(year = null){
    this.totalLoading = true;
    this.reportMallInventoryService.getMallInventoryTotalReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.totalColumnReportColumn = [...this.reportsListsService.yearColumnAppend(MallInventoryReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
          this.totalColumnReport = this.reportsListsService.summaryReportConversion( res.data, MallInventoryReportConstant.totalColumn)
        } else {
          this.totalColumnReport = [];
        }
        this.totalLoading = false;
      },
      error: (error:any) => {
        this.totalColumnReport = [];
        this.totalLoading = false;
      }}
    )
  }

  getMallInventoryYearlyUnitsReport(){
    this.mallInventoryYearlyUnitsLoading = true;
    this.reportMallInventoryService.getMallInventoryYearlyUnitsReport().subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.mallInventoryYearlyUnitsReport = res.data.map((data: any) => {
            const obj = {
              category: data.year.toString(),
              value: data.total_unit
            }
            return obj;
          })
          
        } else {
          this.mallInventoryYearlyUnitsReport = [];
        }
        this.mallInventoryYearlyUnitsLoading = false;

      },
      error: (error:any) => {
        this.mallInventoryYearlyUnitsReport = [];
        this.mallInventoryYearlyUnitsLoading = false;

      }}
    )
  }

  getMallInventoryYearlyOccupancyReport(){
    this.mallInventoryYearlyOccupancyLoading = true;
    this.reportMallInventoryService.getMallInventoryYearlyOccupancyReport().subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          
          this.mallInventoryYearlyOccupancyReport = res.data.map((val: any) => {
            const obj = {
              category: val.year.toString(),
              value1: val.total_unit,
              total: `${val.occupancy} %`,
            }
            return obj;
          })
        } else {
          this.mallInventoryYearlyOccupancyReport = [];
        }
        this.mallInventoryYearlyOccupancyLoading = false;
      },
      error: (error:any) => {
        this.mallInventoryYearlyOccupancyReport = [];
        this.mallInventoryYearlyOccupancyLoading = false;
      }}
    )
  }

  getMallInventoryYearlyIncrementalReport(){
    this.mallInventoryYearlyIncrementalLoading = true;
    this.reportMallInventoryService.getMallInventoryYearlyIncrementalReport().subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.mallInventoryYearlyIncrementalReport = res.data.map((data: any) => {
            const obj = {
              category: data.year.toString(),
              value: data.total_unit
            }
            return obj;
          })
        } else {
          this.mallInventoryYearlyIncrementalReport = [];
        }
        this.mallInventoryYearlyIncrementalLoading = false;
      },
      error: (error:any) => {
        this.mallInventoryYearlyIncrementalReport = [];
        this.mallInventoryYearlyIncrementalLoading = false;
      }}
    )
  }

  getinventoryNeighTrendlReport(){
    this.inventoryneighTrendLoading = true;
    this.reportMallInventoryService.getMallInventoryNeighTrendlReport('inventory').subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          if( res?.data?.year.length > 0){
            this.inventoryneighTrendlReportColumn = [...this.reportsListsService.yearColumnAppend(MallInventoryReportConstant.neighTrendlReporttableColumns, res?.data.year, false, GlobalContants.keySymbolContants.unit, 'value')];
          }
          this.inventoryneighTrendlReport = res.data.data;
        } else {
          this.inventoryneighTrendlReport = [];
        }
        this.inventoryneighTrendLoading = false;
      },
      error: (error:any) => {
        this.inventoryneighTrendlReport = [];
        this.inventoryneighTrendLoading = false;
      }}
    )
  }
  

  getMallInventoryNeighTrendlReport(){
    this.neighTrendlReportLoading = true;
    this.reportMallInventoryService.getMallInventoryNeighTrendlReport('occupancy').subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          if( res?.data?.year.length > 0){
            this.neighTrendlReportColumn = [...this.reportsListsService.yearColumnAppend(MallInventoryReportConstant.neighTrendlReporttableColumns, res?.data.year, false, GlobalContants.keySymbolContants.unit, 'value')];
          }
          this.neighTrendlReport = res.data.data;
        } else {
          this.neighTrendlReport = [];
        }
        this.neighTrendlReportLoading = false;
      },
      error: (error:any) => {
        this.neighTrendlReport = [];
        this.neighTrendlReportLoading = false;
      }}
    )
  }

  selectedYear(data: any){
    if(data.reportType === 'totalReport'){
      this.getMallInventoryTotalReport(data.year);
    } 
  }

}
