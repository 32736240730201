<div class="report-container">

    <ng-container *ngIf="!showPropertyDetails">
        <app-report-sub-menu [menuList]="overViewMenu"
        (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
        <ng-container *ngIf="houseVillaOverviewLoading">
            <app-skeleton-loader [reportType]="chartView.overView"
                ></app-skeleton-loader>
        </ng-container>
        <div class="report-tabsection">
            <div class="report-overview" *ngIf="!houseVillaOverviewLoading">
                <app-overview-card
                [card_title]="'reports.totalLandsForProperties'" 
                [total_current]="overViewSummary?.total_land_current"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.total_land_prev"
                [total_percentage]="overViewSummary?.total_percentage"></app-overview-card>
              
                <app-overview-card
                    [card_title]="'reports.averageLandArea'" 
                    [total_current]="overViewSummary?.average_land_current"
                    [untillDate]="overViewSummary?.untillDate"
                    [total_prev]="overViewSummary?.average_land_prev"
                    [total_percentage]="overViewSummary?.average_total_percentage"
                    [uniType]="UnitTypeEnum.sqm"></app-overview-card>
                
               
                <app-overview-card
                    [card_title]="'reports.landDevelopmentRate'" 
                    [total_current]="overViewSummary?.land_development_current"
                    [untillDate]="overViewSummary?.untillDate"
                    [total_prev]="overViewSummary?.land_development_prev"
                    [total_percentage]="overViewSummary?.landDevlopment_total_percentage"
                    [uniType]="UnitTypeEnum.percentage"></app-overview-card>
                
            </div>
        </div>    
    </ng-container>


    <ng-container *ngIf="showPropertyDetails">
        <ng-container *ngIf="multi_story_residential; else elseTemplate">
            <app-report-property-detail [propertyInfo]="propertyInfo"></app-report-property-detail>
        </ng-container>
        <ng-template #elseTemplate>
            <app-report-housevilla-property-details [propertyInfo]="propertyInfo"></app-report-housevilla-property-details>
        </ng-template>
    </ng-container>

</div>
