import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable } from "rxjs";
import { SharedService } from "../shared.service";
import { GlobalContants } from "../../constants/global.contants";
import { isPlatformBrowser } from "@angular/common";
import { StoreService } from "../store.service";

@Injectable()

export class AuthGuard {

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private sharedService: SharedService,
        private storeService:StoreService,
        private router: Router
    ){}

    canActivate(
        state: RouterStateSnapshot
    ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if(isPlatformBrowser(this.platformId)){
        if (this.sharedService.getStoreValue(GlobalContants.StoreKey.userToken)) {
            this.sharedService.isUserInfoLoaded.next(true);
            return true;
        } else {
            this.getPreviousUrl(state);
            this.sharedService.isUserInfoLoaded.next(false);
            this.storeService.clearAll();
            this.router.navigate([''])
            return false;
        }
    } else {
        return true;
    }
  }

  getPreviousUrl(state: any){
    let newUrl = '';
    let isReport = false;
    state?.url.forEach((element: any) => {
        newUrl += `/${element.path}`;
        if(element.path === 'reports'){
            isReport = true;
        }
    });
    if(state?.queryParams && Object.keys(state.queryParams).length > 0){
        let queryParams = '?';
        let length = Object.keys(state.queryParams).length;
        Object.keys(state.queryParams).forEach((key : any, index) => {
            queryParams += `${key}=${state.queryParams[key]}`;
            if(length - 1 !== index){
                queryParams += `&`;
            }
        })
        newUrl += queryParams;
    }
    if(isReport){
        this.storeService.add(GlobalContants.StoreKey.currentRoute, newUrl);
    }
  }
}