import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ReportTenantsMapService } from './reports-tenants-map-view.service';
import { ReportMapService } from '../../services/report-map.service';
import { Subscription } from 'rxjs';
import { ReportService } from '../../services/report.service';
import { ReportsViewEnum } from '../../models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';

declare const L: any;

@Component({
  selector: 'app-reports-tenants-map-view',
  templateUrl: './reports-tenants-map-view.component.html',
  styleUrls: ['./reports-tenants-map-view.component.scss']
})
export class ReportsTenantsMapViewComponent {

  @Input() classname!: string;
  @Input() set propertyInfo(value: any) {
    if (value) {
      this.selected_property = value;
    }

  }
  public mapCenter: any = [29.3560456, 47.9999959];
  mapLayer: any;
  map: any;
  public mapZoom: any = 16;
  floorDetail: any;
  currentFloorSelected: any;
  currentReportView!: number;
  currentReportView$: Subscription = new Subscription();
  public selected_property: any;
  viewType = ReportsViewEnum;
  selectedIndex = 0;
  currentLangID = 1;
  floorData: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private reportTenantsMapService: ReportTenantsMapService,
    private mapService: ReportMapService,
    public reportService: ReportService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sharedService.language$.subscribe(res=>{
        let language = this.sharedService.getCurrentLangID();
        this.currentLangID = language;
      })
      this.initMap();
    }

    this.getUnits()

    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      if (this.currentReportView != view) {
        if (this.map && view !== 0) this.map.closePopup();
        this.currentReportView = view;
      }
    });
  }

  getUnits() {
    this.reportTenantsMapService.getUnits(this.selected_property).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.floorDetail = res.data;
            let index = this.floorDetail.findIndex((item: any) => item?.unit_count) !== -1 
            ? this.floorDetail.findIndex((item: any) => item?.unit_count) 
            : this.floorDetail.findIndex((item: any) => item?.unit_layout?.layout_data);
            this.setGeoLayer(index)
          } else {
            this.floorDetail = null;
            this.currentFloorSelected = null;
          }
        },
        error: (error: any) => {
          this.floorDetail = null;
          this.currentFloorSelected = null;
        }
      }
    )
  }

  initMap() {

    this.map = L.map('tenantsMap', {
      zoomControl: false,
      maxZoom: 21,
      minZoom: this.mapZoom
    }).setView(this.mapCenter, this.mapZoom);
    this.map.attributionControl.setPrefix('');

  }

  setGeoLayer(index = 0) {
    this.currentFloorSelected = this.floorDetail[index];
    this.selectedIndex = index;
    var that = this;
    if (this.currentFloorSelected.unit_layout.layout_data) {
      if(this.floorData){
        this.floorData.clearLayers()
      }
      this.floorData = L.geoJSON(this.currentFloorSelected.unit_layout.layout_data, {
       
        style: function (feature: any) {
          let properties = feature.properties;
          if (properties.shop_name && properties.shop_name?.toLowerCase() == that.selected_property.shop_label?.toLowerCase()) {
           
            return that.reportTenantsMapService.tenantColor();
          } else if (properties.is_vacant) {
            return that.reportTenantsMapService.vacantColor();
          } else {
            return that.reportTenantsMapService.filledTenantColor();
          }
        }
      })
      .bindTooltip(function (layer:any) {
        return layer.feature.properties.shop_name || 'Vacant';
     },{
      direction: `${this.currentLangID == 1?'left':'right'}`,
      className: "tenantTT-tip",
     }

    ).addTo(this.map);
      this.map.fitBounds(this.floorData.getBounds());
      // this.map.setZoom(20);
    } else {

      this.floorData?.clearLayers()
    }

  }



}
