export enum ReportsViewEnum {
    mapGridView = 0,
    mapView,
    gridView
}

export enum ReportsChartEnum {
    barChart = 0,
    pieChart,
    barStackedChart,
    multiBarLineGroupChart,
    barLineGroupChart,
    normalBarLineChart,
    tableView,
    varianceIndicatorsChart,
    overView,
    smallBarChart,
    horizontalBarChart,
    smallvarianceIndicatorsChart
}
