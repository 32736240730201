<div class="mapLayer__panel" (click)="$event.stopPropagation()" #customLayerController [class.show_hide]="!mapLayertoggle">
    <div class="mapLayer-panel-header">
        <b>{{"reports.map_layer" | translate}}</b>
        <button class="refreshbtn" [disabled]="resetLayerDisable()" (click)="resetLayer()" [ngStyle]="{'float': current_lang == 'en' ? 'right':'left'} ">
            <img src="../../../../../assets/images/tabler-icon-rotate-clockwise.svg" alt="Refresh">
        </button>
    </div>
    <div class="maplayer-panel-body">
        <!-- <hr class="linedivider" [ngStyle]="{'left': current_lang == 'en' ? '5%':'','right': current_lang == 'ar' ? '5%':''}"> -->
        <div>
            <div class="map-layer d-flex align-items-center">
                <div class="d-flex align-items-center pointer" (click)="selectedBasemap('default')">
                <span class="type-radio" [ngClass]="{'selected_option': basemap_select == 'default'}">
                    <i *ngIf="basemap_select == 'default'" class="circle"></i>
                </span>
                <p>{{"reports.default" | translate}}</p>
            </div>
            </div>
            <div class="map-layer">
                <div class="d-flex align-items-center pointer" (click)="selectedBasemap('satellite')">
                <span class="type-radio"[ngClass]="{'selected_option': basemap_select == 'satellite'}">
                    <i *ngIf="basemap_select == 'satellite'" class="circle"></i>
                </span>
                <p>{{"reports.satellite" | translate}}</p>
            </div>
            </div>
        </div>
        <hr class="linedivider" [ngStyle]="{'left': current_lang == 'en' ? '5%':'','right': current_lang == 'ar' ? '5%':''}">
        <ng-container *ngIf="legendLayerGroup?.length > 0">
            <ng-container *ngFor="let legend of legendLayerGroup; let i = index">
                <div class="map-layer d-flex justify-content-between" *ngIf="legend.status">
                    <div class="d-flex align-items-center pointer" (click)="selectedLegend(legend)">
                    <span class="type-check-box"[ngClass]="{'selected_option': legend.display}">
                        <i *ngIf="legend.display" class="circle"></i>
                    </span>
                    <p>{{legend.label?.[current_lang_id]?legend.label[current_lang_id]:legend.label[1]}}</p>
                </div>
                    <!-- Active Cricle to show when mapZoom 15 above & check array length -->
                    <span class="active-symbol" *ngIf="legend?.data?.length > 0 && mapZoom > 15"></span>
                </div>
            </ng-container>
        </ng-container>
        <hr class="linedivider" [ngStyle]="{'left': current_lang == 'en' ? '5%':'','right': current_lang == 'ar' ? '5%':''}">
        <div>
            <ng-container *ngFor="let map_layer of mapLayers; let i = index">
                
                <div class="map-layer d-flex justify-content-between" *ngIf="map_layer?.layer_status">
                    <div class="d-flex align-items-center pointer" (click)="selectedLayers(map_layer)">
                        <span class="type-mult-check-box"[ngClass]="{'selected_option': map_layer.display}">
                            <svg *ngIf="map_layer.display" class="check-box"  width="6" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 1.125L3.0625 4.5625L1.5 3" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>                                
                        </span>
                        <p class="maplayer-box" [matTooltip]="map_layer?.showToolTip ? (map_layer.label | translate) : ''">{{map_layer.label | translate}}</p>
                    </div>
                    <!-- Active Cricle to show when mapZoom is between minZoom and maxZoom -->
                    <span class="active-symbol" *ngIf="mapZoom >= map_layer?.minZoom && mapZoom <= map_layer?.maxZoom"></span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
