import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'xlsx-js-style';



@Injectable({
  providedIn: 'root'
})
export class ExcelDownloadService {

  constructor() { }

  exportexcel(data: any, col: any, reportName: string): void {
    /* table id is passed over here */
    const ws: any = XLSX.utils.json_to_sheet(data);

    const xs= this.delete_row(ws, 0);

   

    const wb: XLSX.WorkBook = XLSXStyle.utils.book_new();

    const colWidths: any = [];
    col.forEach((element: any) => {
      colWidths.push({ wch: 20 })
    });

    

    ws['!cols'] = colWidths;
    //XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

    // Add a worksheet

    // Define the bold header style
    const boldHeaderStyle = { font: { bold: true } };

    // Define the border style
    const borderStyle = {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } }
    };

    // Determine the headers dynamically
    const headers = Object.keys(data[0]);

    // Apply bold style to all header cells dynamically
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 }); // Column index, first row
      if (ws[cellAddress]) {
        ws[cellAddress].s = { ...ws[cellAddress].s, ...boldHeaderStyle };
      }
    });

    // Determine the range of the worksheet
    const range = XLSX.utils.decode_range(ws['!ref']);

    // Apply border style to each cell in the range
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[cellAddress]) continue;
        if (!ws[cellAddress].s) ws[cellAddress].s = {};
        ws[cellAddress].s.border = borderStyle;
      }
    }

    XLSXStyle.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    let name = reportName.toLowerCase();
    XLSXStyle.writeFile(wb, `${name}.xlsx`);


  }

  delete_row(ws: any, row_index: number) {
    var variable = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < variable.e.r; ++R) {
      for (var C = variable.s.c; C <= variable.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
      }
    }
    variable.e.r--
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
  }

  ec(r: any, c: any) {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }

}
