<div class="container">
  <app-outer-header></app-outer-header>
  <div class="login-container">
    <div class="login-banner">
      <app-banner></app-banner>
    </div>
    <div class="login-right-section">
      <div class="login-right-section-inner d-flex">
        <div class="login-right-inner">
          <div class="title">
            <img
              src="../../../../assets/images/verification-with-exclamation.svg"
              alt="logo"
            />
            <p class="title-heading-notVerified">
              {{ "User.confirm_mail_activate_account" | translate }}
            </p>
            <p class="title-content-notVerified">
              {{'User.Weve_sent_an_email_to' | translate}}
              <span class="primary-font">{{ newUserEmail }}</span> {{'User.verify_your_account' | translate}}
            </p>
          </div>
          <div class="not-verified">
            <div class="step-heading text-center">
              <span>{{ "User.3_step_to_start" | translate }}</span>
            </div>
            <div class="step-content">
              <ul class="step-point">
                <li style="white-space: nowrap;">{{ "User.check_inbox" | translate }}</li>
                <li>{{ "User.click_activation_link" | translate }}</li>
                <li>{{ "User.generate_first_report" | translate }}</li>
              </ul>
            </div>
            <div class="resend-mail text-center">
              <span
                >{{ "User.not_received" | translate }}
                <button
                  [class.disable]="countdown != -1"
                  [disabled]="countdown != -1"
                  class="primary-font resend-button"
                  (click)="resendEmail()"
                >
                  {{ "User.resend" | translate }}
                </button>
                {{ "User.email_again" | translate }}
                <span *ngIf="countdown == -1" class="counter">(50s)</span>
                <span *ngIf="countdown != -1" class="counter">{{"(" + countdown + "s"+ ")"}}</span>
                </span
              >
            </div>
          </div>
        </div>
        <div class="demo-section d-flex">
          <p>{{ "User.Learn_more_about" | translate }}</p>
          <button (click)="requestDemo()" class="text-center">
            {{ "User.request_for_demo" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
