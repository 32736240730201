import { Component } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  currentLangID=1;
  constructor(
    private sharedService: SharedService
  ){}
  ngOnInit(): void {
    this.getChangedLang()
  }
  getChangedLang(){
    this.sharedService.language$.subscribe(res=>{
      let language = this.sharedService.getCurrentLangID();
      this.currentLangID = language;
    })
  }
}
