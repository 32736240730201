import { Component, Input, OnChanges } from '@angular/core';
import { ReportPropertyDetailConstant } from '../../report-property-detail.constant';
import { ReportPropertyDetailService } from '../../report-property-detail.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Component({
  selector: 'app-residential-rentalincome-assessement',
  templateUrl: './residential-rentalincome-assessement.component.html',
  styleUrls: ['./residential-rentalincome-assessement.component.scss']
})
export class ResidentialRentalincomeAssessementComponent implements OnChanges {

  @Input() propertyInfo: any;

  rentalIncomeCountLoading = false;
  rentalIncomeCountData = [];
  rentalIncomeCount = 0;

  rentalIncomeAreaLoading = false;
  rentalIncomeAreaData = [];
  rentalIncomeAreaCount = 0;

  rentalIncomeCountColumns  = ReportPropertyDetailConstant.rentalIncomeCount;
  rentalIncomeAreaColumns  = ReportPropertyDetailConstant.rentalIncomeArea;
  placeCode: any;


  constructor(private reportPropertyDetailService: ReportPropertyDetailService,
    private reportService: ReportService, private sharedService: SharedService
  ){
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.rentalIncomeCountColumns.map(e=>{
      if(e.field == 'monthly_rental' && this.placeCode == environment.RYDCHECK){
        e.columnName = 'reports.yearlyRental'
      }
    })
  }

  ngOnChanges(): void {
    this.getRentalIncomeCount();
    this.getRentalIncomeArea();
  }

  getRentalIncomeCount() {
    this.rentalIncomeCountLoading = true;
    this.reportPropertyDetailService.getRentalIncomeCountReport(this.reportPropertyDetailService.rentalIncomeCountPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           res.data.map((el:any)=>{
            Object.keys(el).map((e:any)=>{
              if(['monthly_rental'].includes(e)){
                el[e] = this.reportService.rangeToNumberFor(el[e]);
              }
            })
          })
          this.rentalIncomeCountData = res.data;
            if(this.reportPropertyDetailService.rentalIncomeCountPayload.pageNumber == 0){
            this.rentalIncomeCount = res.count;
           }
            
          } else {
            this.rentalIncomeCountData = [];
            this.rentalIncomeCount = 0;
          }
          this.rentalIncomeCountLoading = false;
        },
        error:()=>{
          this.rentalIncomeCountLoading = false;
          this.rentalIncomeCountData = [];
          this.rentalIncomeCount = 0;
         
        }
      }
    )
  }

  getRentalIncomeArea() {
    this.rentalIncomeAreaLoading = true;
    this.reportPropertyDetailService.getRentalIncomeAreaReport(this.reportPropertyDetailService.rentalIncomeAreaPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            res.data.map((el:any)=>{
              Object.keys(el).map((e:any)=>{
                if(['lease_rate'].includes(e)){
                  el[e] = this.reportService.rangeToNumberFor(el[e]);
                }
              })
            })
           this.rentalIncomeAreaData = res.data;
            if(this.reportPropertyDetailService.rentalIncomeAreaPayload.pageNumber == 0){
            this.rentalIncomeAreaCount = res.count;
           }
            
          } else {
            this.rentalIncomeAreaData = [];
            this.rentalIncomeAreaCount = 0;
          }
          this.rentalIncomeAreaLoading = false;
        },
        error:()=>{
          this.rentalIncomeAreaLoading = false;
          this.rentalIncomeAreaData = [];
          this.rentalIncomeAreaCount = 0;
         
        }
      }
    )
  }

  sortData(event: any, type: number) {
    let sort = {
      column: (event.active == 'ir_lease_rate') ? 'lease_rate': event.active,
      order: event.direction
    }
    if(type === 1){
      this.reportPropertyDetailService.rentalIncomeCountPayload.pageNumber = 0;
      this.reportPropertyDetailService.rentalIncomeCountPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRentalIncomeCount();
    } else {
      this.reportPropertyDetailService.rentalIncomeAreaPayload.pageNumber = 0;
      this.reportPropertyDetailService.rentalIncomeAreaPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRentalIncomeArea();
    }
   
    
  }

}
