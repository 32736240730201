
<div id="globalSearchBar" class="globalSearchBar">
    <mat-icon [class.ar-search-icon]="currentLang == 'ar'">search</mat-icon>
    <input type="text"
        [formControl]="searchControl"
        [placeholder]="placeholder | translate"
        class="input-search"
        (keydown)="disableInput($event)"

    >
</div>
