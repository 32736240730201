import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ReportPropertyDetailService } from '../../report-property-detail.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportService } from 'src/app/reports/services/report.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-report-property-info',
  templateUrl: './report-property-info.component.html',
  styleUrls: ['./report-property-info.component.scss']
})
export class ReportPropertyInfoComponent implements OnChanges {

  @Input() propertyInfo: any;
  unitType = GlobalContants.keySymbolContants.squareMeterUnit;
  propertyInfomation: any = [];
  overViewPropertySummary: any = null;
  CurrencyCode: any;

  constructor(private reportPropertyDetailService: ReportPropertyDetailService,
    private reportService: ReportService, private decimalPipe: DecimalPipe
  ){}

  ngOnChanges(): void {
    this.CurrencyCode = this.reportPropertyDetailService.CurrencyCode;
    // this.getPropertyOverviewSummary();
    this.getPropertyInfo();
  }

  // getPropertyOverviewSummary() {
  //   this.reportPropertyDetailService.getOverviewPropertySummary().subscribe(
  //     {
  //       next: (res: any) => {
  //         if (res.status === 200) {
  //          this.overViewPropertySummary = res.data;
  //          if(this.overViewPropertySummary?.land_area) this.overViewPropertySummary['land_area']=this.reportService.formatInternationNum(this.overViewPropertySummary?.land_area)
  //           if(this.overViewPropertySummary?.setback) this.overViewPropertySummary['setback']=this.reportService.formatInternationNum(this.overViewPropertySummary?.setback)
  //         } else {
  //           this.overViewPropertySummary = null;
  //         }
  //       }
  //     }
  //   )
  // }

  getPropertyInfo(){
    this.reportPropertyDetailService.getPropertyInfo().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200 && res.data) {
            let obj:any[] = res.data;
            obj.sort((a: any, b: any) => (Number(a.sort_order) - Number(b.sort_order)))
            this.propertyInfomation = obj;
          } else {
            this.propertyInfomation = [];
          }
        },
        error:(err)=> {
          this.propertyInfomation = [];
        },
      }
    )
  }


}
