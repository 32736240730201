import {
    AfterViewInit,
    Directive, ElementRef,EventEmitter,Host,Input,OnChanges,Optional,Output,Renderer2,Self,SimpleChanges,ViewContainerRef} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';

import { map, startWith } from 'rxjs';

@Directive({
    selector: '[stylePagination]',
    standalone: true,
})

export class PaginationDirective implements AfterViewInit, OnChanges {

    @Output() pageIndexChangeEmitter: EventEmitter<number> =new EventEmitter<number>();

    @Input() showFirstButton = true;
    @Input() showLastButton = true;
    @Input() renderButtonsNumber = 2;
    @Input() appCustomLength: number = 0;
    @Input() pageSize: number = 0;
    @Input() hideDefaultArrows = false;
    @Input() currentPageIndex:any = 0;
    private dotsEndRef!: HTMLElement;
    private dotsStartRef!: HTMLElement;
    private bubbleContainerRef!: HTMLElement;
    private buttonsRef: HTMLElement[] = [];

    constructor(
        @Host() @Self() @Optional() private readonly matPag: MatPaginator,
        private elementRef: ViewContainerRef,
        private ren: Renderer2
    ) { }
    
    ngAfterViewInit(): void {
        this.styleDefaultPagination();
        this.createBubbleDivRef();
        this.renderButtons();
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.['appCustomLength']?.firstChange) {
            this.removeButtons();// switch back to page 0 // this.switchPage(0);
            this.renderButtons();
        }

    }

    switchPageByCurrentIndex(){
        this.switchPage(this.currentPageIndex)
    }
    
    
    private renderButtons(): void {
        this.buildButtons();
        
        this.matPag.page
            .pipe(
                map((e) => [e.previousPageIndex ?? 0, e.pageIndex]),
                startWith([0, 0]))
                .subscribe(([prev, curr]) => {
                    this.changeActiveButtonStyles(prev, curr);
                });
    }
    
    private changeActiveButtonStyles(previousIndex: number, newIndex: number) {
        const previouslyActive = this.buttonsRef[previousIndex];
        const currentActive = this.buttonsRef[newIndex];
        this.buttonsRef.map((btn:any) => {this.ren.removeClass(btn, 'g-bubble__active');})
        
        if(currentActive){
            this.ren.addClass(currentActive, 'g-bubble__active');
        }
        this.buttonsRef.forEach((button) =>this.ren.setStyle(button, 'display', 'none'));
        
        const renderElements = this.renderButtonsNumber;
        const endDots = newIndex < this.buttonsRef.length - renderElements - 1;
        const startDots = newIndex - renderElements > 0;
        
        const firstButton = this.buttonsRef[0];
        const lastButton = this.buttonsRef[this.buttonsRef.length - 1];
        
        if (this.showLastButton) {
            this.ren?.setStyle(this.dotsEndRef, 'display', endDots ? 'block' : 'none');
            this.ren?.setStyle(lastButton, 'display', endDots ? 'flex' : 'none');
        }
        
        if (this.showFirstButton) {
            this.ren.setStyle(this.dotsStartRef,'display',startDots ? 'block' : 'none');
            this.ren.setStyle(firstButton, 'display', startDots ? 'flex' : 'none');
        }
        const startingIndex = startDots ? newIndex - renderElements : 0;
        const endingIndex = endDots? newIndex + renderElements: this.buttonsRef.length - 1;
        
        for (let i = startingIndex; i <= endingIndex; i++) {
            const button = this.buttonsRef[i];
            this.ren.setStyle(button, 'display', 'flex');
        }
    }
    
    private styleDefaultPagination() {
        const nativeElement = this.elementRef.element.nativeElement;
        const howManyDisplayedEl = nativeElement.querySelector('.mat-mdc-paginator-range-label');
        
        var previousButton = nativeElement.querySelector('button.mat-mdc-paginator-navigation-previous');
        var nextButtonDefault = nativeElement.querySelector('button.mat-mdc-paginator-navigation-next');
        previousButton.innerHTML = '<span class="previous-txt"></span><span class="mat-mdc-button-persistent-ripple mdc-icon-button__ripple"></span><svg viewBox="0 0 24 24" focusable="false" class="mat-mdc-paginator-icon"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg><span class="mat-mdc-focus-indicator"></span><span matripple="" class="mat-ripple mat-mdc-button-ripple" ng-reflect-disabled="true" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-mdc-button-touch-target"></span>';
        nextButtonDefault.innerHTML = '<span class="next-txt"></span><span class="mat-mdc-button-persistent-ripple mdc-icon-button__ripple"></span><svg viewBox="0 0 24 24" focusable="false" class="mat-mdc-paginator-icon"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg><span class="mat-mdc-focus-indicator"></span><span matripple="" class="mat-ripple mat-mdc-button-ripple" ng-reflect-disabled="true" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-mdc-button-touch-target"></span>';
        
        this.ren.setStyle(howManyDisplayedEl, 'display', 'none');
        
        if (this.hideDefaultArrows) {
            this.ren.setStyle(previousButton, 'display', 'none');
            this.ren.setStyle(nextButtonDefault, 'display', 'none');
        }
    }
    
    private createBubbleDivRef(): void {
        const actionContainer = this.elementRef.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');
        const nextButtonDefault = this.elementRef.element.nativeElement.querySelector('button.mat-mdc-paginator-navigation-next');

        this.bubbleContainerRef = this.ren.createElement('div') as HTMLElement;
        this.ren.addClass(this.bubbleContainerRef, 'g-bubble-container');
        this.ren.insertBefore(actionContainer,this.bubbleContainerRef,nextButtonDefault);
    }
    
    private buildButtons(): void {
        const neededButtons = Math.ceil(this.appCustomLength / this.matPag.pageSize);
        if (neededButtons === 0 || isNaN(neededButtons)) {
            return;
        }
        
        if (neededButtons === 1) {
            this.buttonsRef = [this.createButton(0)];
            this.dotsStartRef = this.createDotsElement();
            this.dotsEndRef = this.createDotsElement();
            return;
        }
        this.buttonsRef = [this.createButton(0)];

        this.dotsStartRef = this.createDotsElement();
        for (let index = 1; index < neededButtons - 1; index++) {
            this.buttonsRef = [...this.buttonsRef, this.createButton(index)];
        }
        
        this.dotsEndRef = this.createDotsElement();

        this.buttonsRef = [...this.buttonsRef,this.createButton(neededButtons - 1),];
    }

    private removeButtons(): void {
        this.buttonsRef.forEach((button) => {
            this.ren.removeChild(this.bubbleContainerRef, button);
        });
        
        this.bubbleContainerRef.innerText = "";
        this.buttonsRef.length = 0;
    }

    private createButton(i: number): HTMLElement {
        const bubbleButton = this.ren.createElement('div');
        const text = this.ren.createText(String(i + 1));

        this.ren.addClass(bubbleButton, 'g-bubble');
        this.ren.setStyle(bubbleButton, 'margin-right', '2px');
        this.ren.appendChild(bubbleButton, text);

        this.ren.listen(bubbleButton, 'click', () => {
            this.switchPage(i);
        });
        this.ren.appendChild(this.bubbleContainerRef, bubbleButton);

        this.ren.setStyle(bubbleButton, 'display', 'none');
        return bubbleButton;
    }

    private createDotsElement(): HTMLElement {
        const dotsEl = this.ren.createElement('span');
        const dotsText = this.ren.createText('...');

        this.ren.setStyle(dotsEl, 'font-size', '16px');
        this.ren.setStyle(dotsEl, 'margin-right', '2px');
        this.ren.setStyle(dotsEl, 'padding-top', '6px');
        this.ren.setStyle(dotsEl, 'color', '#919191');

        this.ren.appendChild(dotsEl, dotsText);
        this.ren.appendChild(this.bubbleContainerRef, dotsEl);
        this.ren.setStyle(dotsEl, 'display', 'none');
        return dotsEl;
    }

    private switchPage(i: number): void {
        
        const previousPageIndex = this.matPag.pageIndex;
        
        this.matPag.pageIndex = i;
        try {
            this.matPag['_emitPageEvent'](previousPageIndex);
        }catch (e) {console.log(e);}

        this.pageIndexChangeEmitter.emit(i);
    }
}
