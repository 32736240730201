<div class="overview">
    <div class="overview-data">
        <h2>{{card_title | translate}} <span *ngIf="infoTooltip" class="info-tooltip" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="infoTooltipContent"><img src="assets/images/contact_support.svg" alt=""></span></h2>
        <h1 class="directionLtr">
            <ng-container *ngIf="total_current; else otheroverViewSummary">
                {{current}}{{symble && symble == '%' ? symble : ''}} <sup class="sup-font">{{symble && symble !== '%' ? symble : ''}}</sup>
            </ng-container>
            <ng-template #otheroverViewSummary>-</ng-template>
        </h1>
        <div class="untill" *ngIf="untillDate">
            <span class="neight" *ngIf="neighborhood">{{'reports.in' | translate}} {{ neighborhood }}</span>
            <span>{{"reports.untill" | translate}}</span>
            <span class="directionLtr"> {{ untillDate ? untillDate : ''}}</span>
        </div>


    </div>
    <div class="overview-v">
        <div class="overview-prev-year">
            <!-- *ngIf="total_prev" -->
            <span *ngIf="isWas">{{ total_prev && total_prev != 0 ? ("reports.was" | translate) : ""}}
                <span
                    *ngIf="(total_prev && total_prev != 0)"><span style="direction: ltr; display: inline-block;">{{(total_prev | number:'1.0-1') + (symble ? symble : '')}}</span></span>
                <!-- <ng-template #wasvalueelse>-</ng-template> -->
            </span>

            <h5 class="normal-price directionLtr" *ngIf="total_percentage == 0">{{
                total_percentage }}%</h5>
            <h5 class="high-price directionLtr" *ngIf="total_percentage > 0">+{{
                total_percentage }}%</h5>
            <h5 class="low-price directionLtr" *ngIf="total_percentage < 0">{{
                total_percentage }}%</h5>
            <span *ngIf="(total_percentage && total_percentage !== '-')  || total_percentage === 0">{{"reports.fromLastYear" | translate}}</span>
        </div>
        <div class="overview-prev">
            <mat-icon *ngIf="total_percentage > 0" class="high-price">
                call_made</mat-icon>
            <mat-icon *ngIf="total_percentage < 0" class="low-price">
                call_received</mat-icon>
        </div>
    </div>
    <div class="horizontal-progress" *ngIf="graphData && !graphData?.loader">
        <ng-container *ngIf="graphData?.data?.length else noDataMessage">
            <ng-container *ngFor="let category of graphData?.data; let i=index;">
                <div class="progress d-flex align-items-center" *ngIf="category[1]['calculation'] != 0">
                    <h5>{{category[0]}}</h5>
                    <section>
                        <div class="bar"
                            [ngStyle]="{'width': (category[1]['calculation'] > 0 ? category[1]['calculation']+'%' : '2%'), 'opacity': getOpacity(i, getNumber(category[1]['category']) )}">
                        </div>
                        <small class="directionLtr">{{getNumber(category[1]['category']) |
                            number:'1.0-1'}} {{symble}}</small>
                    </section>

                </div>
            </ng-container>
        </ng-container>
        <ng-template #noDataMessage>
            <div class="data_unavail">
                <span>
                    {{"reports.data_not_available" | translate}} <mat-icon role="img"
                        class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                        aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                </span>
            </div>

        </ng-template>
    </div>
    <div *ngIf="graphData?.loader">
      <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
  </div>

</div>
