import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportHouseVillasService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  independentPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  governmentalPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  projectsPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  projectUnitPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  getOverviewSummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseVillasSummary}${changeParams}`;
    return this.apiServive.get(url);
  }
  getGraphSummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseVillaGraph}${changeParams}`;
    return this.apiServive.get(url);
  }



  getIndependentHouseVillas(payload: any, year = null) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.independentPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.independentHouseVillas}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if (offset == 0) {
      url += "&is_count=true"
    }
    return this.apiServive.get(url);
  }

  downloadTransactionReport(type: string, user_id: number) {
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  downloadExcelUrl() {
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }

  getGovernorHouseVillas(payload: any, year = null) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.governmentalPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0;

    let url: string = `${environment.miUrl}${apiEndPoints.governorHouseVillas}${changeParams}&limit=${payload.pageSize}&offset=${offset}&is_count=true`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    return this.apiServive.get(url);
  }

  getProjects(payload: any, year = null) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.projectsPayload.year ||   this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0;

    let url: string = `${environment.miUrl}${apiEndPoints.projectHouseVillas}${changeParams}&limit=${payload.pageSize}&offset=${offset}&is_count=true`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    return this.apiServive.get(url);
  }



  formatOverViewSummary(data: any) {

    data['total_percentage'] = this.reportService.calcPercentage(data.total_land_prev, data.total_land_current);
    data['average_total_percentage'] = this.reportService.calcPercentage(data.average_land_prev, data.average_land_current);
    data['landDevlopment_total_percentage'] = this.reportService.calcPercentage(data.land_development_prev, data.land_development_current);
    data['untillDate'] = data.max_date_current ? moment.utc(data.max_date_current).local().format('DD MMM YYYY') : null;
    return data;
  }

  getHousevillaLandInfo(queryParams: any) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseVillaLandInformation}${changeParams}`;
    return this.apiServive.get(url);
  }

  getHousevillaAreaInfo(queryParams: any) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseVillaAreaInformation}${changeParams}`;
    return this.apiServive.get(url);
  }
  getHouseVillaPropertyDetail(queryParams: any, uid: string) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    let url: string;
    if(queryParams.year == null){
      queryParams['year'] = new Date().getFullYear();
    }
    let changeParams = this.reportService.setfilterQueryParams(queryParams);
    url = `${environment.miUrl}${apiEndPoints.houseVillaPropertyDetail}${changeParams}&property_uid=${uid}`;
    return this.apiServive.get(url);
  }
  getHouseVillaProjectUnitDetail(pId: string, payload: any) { //Common
    let queryParamsData = { ...this.reportService.queryParams };
    if (queryParamsData?.year) {
      queryParamsData.year =  this.projectUnitPayload.year ||   this.reportService.yearMaxValue(queryParamsData.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParamsData);
    let offset = payload.pageNumber || 0;
    let url: string = `${environment.miUrl}${apiEndPoints.houseVillaProjectUnitDetails}${changeParams}&project_uid=${pId}&limit=${payload?.pageSize}&offset=${offset}&is_count=true`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    return this.apiServive.get(url);
  }
  getHouseVillaProjectOverview(pId: string) { //Common
    let queryParamsData = { ...this.reportService.queryParams };
    if (queryParamsData?.year) {
      queryParamsData.year =  this.projectUnitPayload.year ||   this.reportService.yearMaxValue(queryParamsData.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParamsData);
    let url: string = `${environment.miUrl}${apiEndPoints.houseVillaProjectAreaOverview}${changeParams}&project_uid=${pId}`;
    return this.apiServive.get(url);
  }
  getHouseVillaProjectLand(pId: string) { //Common
    let queryParamsData = { ...this.reportService.queryParams };
    if (queryParamsData?.year) {
      queryParamsData.year =  this.projectUnitPayload.year ||   this.reportService.yearMaxValue(queryParamsData.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParamsData);
    let url: string = `${environment.miUrl}${apiEndPoints.houseVillaProjectLandInfo}${changeParams}&project_uid=${pId}`;
    return this.apiServive.get(url);
  }
  downloadHouseVillaReport(type: string, user_id: any, puId?: any) {
    let url: string = `${environment.miUrl}${apiEndPoints.downloadHouseVillaReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    if(puId){
      url += `&project_uid=${puId}`
    }
    return this.apiServive.get(url);
  }
}
