import { Injectable } from '@angular/core';
import moment from 'moment';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';
import { ApiService } from 'src/app/common/services/api.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportRetailRentalService {

    constructor(
      private apiServive: ApiService,
      private reportService: ReportService
    ) { }
    retailUnitSizePayload: any = {
      pageSize: 20,
      pageNumber: 0,
      sort_order: null,
      year: null
    }
    retailMallWisePayload: any = {
      pageSize: 20,
      pageNumber: 0,
      sort_order: null,
      year: null
    }
    retailUnitPayload: any = {
      pageSize: 20,
      pageNumber: 0,
      sort_order: null,
      year: null
    }
    averageRentRate = new Map();
    occupancyRate = new Map();
    /**
     * This function in used to get overview summay
     * @returns overview summary
     */
    getOverviewSummary(summaryType:string) {
      let queryParams = { ...this.reportService.queryParams };
      if (queryParams?.year) {
        queryParams.year = null;//this.reportService.yearMaxValue(queryParams.year);
      }
      const changeParams = this.reportService.setfilterQueryParams(queryParams);
      const url: string = `${environment.miUrl}${summaryType == 'average' ? apiEndPoints.retailRentalOverviewV2 : apiEndPoints.retailRentalOverviewOccupancyV2 }${changeParams}`;
      return this.apiServive.get(url, false);
    }
    getRetailRentalRentrateReportV2() {
      let queryParams = { ...this.reportService.queryParams };
      if (queryParams?.year) {
        queryParams.year = this.reportService.yearMaxValue(queryParams.year);
      }
      const changeParams = this.reportService.setfilterQueryParams(queryParams);
      const url: string = `${environment.miUrl}${apiEndPoints.retailRentalRentrateReportV2}${changeParams}`;
      return this.apiServive.get(url, false);
    }
    // getOverviewSubCategory() {
    //   let queryParams = { ...this.reportService.queryParams };
    //   if (queryParams?.year) {
    //     queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    //   }
    //   const changeParams = this.reportService.setfilterQueryParams(queryParams);
    //   const url: string = `${environment.miUrl}${apiEndPoints.retailRentalOverviewSubCategory}${changeParams}`;
    //   return this.apiServive.get(url, false);
    // }

    getRetailMallWiseV2(payload: any) {
      let queryParams = { ...this.reportService.queryParams };
      if (queryParams?.year) {
        queryParams.year = this.retailMallWisePayload.year || this.reportService.yearMaxValue(queryParams.year);
      }
      const changeParams = this.reportService.setfilterQueryParams(queryParams);
      let offset = payload.pageNumber || 0
      let url: string = `${environment.miUrl}${apiEndPoints.retailRentalRentMallwiseReportV2}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
      if (payload.sort_order) {
        url += `&sort_order=${payload.sort_order}`;
      }
      if(offset == 0){
        url += "&is_count=true"
      }
      return this.apiServive.get(url, false);
    }

    getRetailUnitSizeV2(payload: any) {
      let queryParams = { ...this.reportService.queryParams };
      if (queryParams?.year) {
        queryParams.year = this.retailUnitSizePayload.year || this.reportService.yearMaxValue(queryParams.year);
      }
      const changeParams = this.reportService.setfilterQueryParams(queryParams);
      let offset = payload.pageNumber || 0
      let url: string = `${environment.miUrl}${apiEndPoints.retailRentalUnitSizeV2}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
      if (payload.sort_order) {
        url += `&sort_order=${payload.sort_order}`;
      }
      if(offset == 0){
        url += "&is_count=true"
      }
      return this.apiServive.get(url, false);
    }

    getRetailUnitV2(payload: any) {
      let queryParams = { ...this.reportService.queryParams };
      if (queryParams?.year) {
        queryParams.year = this.retailUnitPayload.year || this.reportService.yearMaxValue(queryParams.year);
      }
      const changeParams = this.reportService.setfilterQueryParams(queryParams);
      let offset = payload.pageNumber || 0
      let url: string = `${environment.miUrl}${apiEndPoints.retailRentalUnitV2}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
      if (payload.sort_order) {
        url += `&sort_order=${payload.sort_order}`;
      }
      if(offset == 0){
        url += "&is_count=true"
      }
      return this.apiServive.get(url, false);
    }

    downloadTransactionReport(type: string, user_id: number) {
      let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
      return this.apiServive.get(url);
    }

    downloadExcelUrl() {
      return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
    }

    downloadRetailRentalReport(type:string, user_id:any){
      const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
      return this.apiServive.get(url);
    }

}
