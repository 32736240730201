<div class="report-container retail-rental" [class.display-none]="showPropertyDetails">
  <app-report-sub-menu *ngIf="!showPropertyDetails" [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
  <ng-container *ngIf="overViewLoading">
    <app-skeleton-loader [reportType]="chartView.overView" [height]="'150px'"></app-skeleton-loader>
  </ng-container>
  <div class="report-tabsection" id="{{ retailRentalMenuEnum.overview }}" [ngClass]="{ scroll: currentScroll === retailRentalMenuEnum.overview }">
    <div class="report-overview" *ngIf="!overViewLoading">
      <!-- [card_title]="placeCode  == RYDCHECK ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'" -->

      <app-overview-card
        [card_title]="sharedService.priceCalculation$() === sharedService.priceYearly ? 'reports.AnnuallyAverageRent':'reports.averageRentRate'"
        [total_current]="overViewSummaryAvgRentRate?.avg_rent"
        [untillDate]="overViewSummaryAvgRentRate?.untillDate"
        [uniType]="UnitTypeEnum.currencywithsqm"
        [propertiesCount]="overViewSummaryAvgRentRate?.total_properties"
        [graphData]="{
          data: overviewAverageRate,
          type: 'averageRentRate',
          loader: graphLoader
        }"></app-overview-card>
      <app-overview-card
        [card_title]="'reports.occupancyRate'"
        [total_current]="overViewSummaryOccupancyRate?.occupancy_rate"
        [untillDate]="overViewSummaryOccupancyRate?.untillDate"
        [uniType]="UnitTypeEnum.percentage"
        [card_sub_title] = "'reports.based_on_select_properties'"
        [showTooltip] = "true"
        [toolTipContent] = "isPremium ? 'reports.covers_sample_properties_estater_field_team' : ''"
        [graphData]="{
          data: overviewOccupancy,
          type: 'occupancyRate',
          loader: graphLoader
        }"></app-overview-card>

      </div>
    <div class="report-model report-rentrate">
      <div class="report-model-header">
        <div class="info">
          <h3>
            {{ sharedService.priceCalculation$() === sharedService.priceYearly ? ("reports.annual_rental_price_in" | translate)
                : ("reports.rental_price_in" | translate)}}
            <span class="text-capitalize">{{placeName | lowercase | translate}}</span>
            <!-- {{'reports.past_years' | translate}} -->
            ({{ CurrencyCode | translate }}/{{"reports.sqm_unit" | translate}})
          </h3>
        </div>
        <button class="btn-select-grade"
          [matMenuTriggerFor]="menu"
          *ngIf="!retailRentalChartLoading && (retailRentalChartReport | json) != '{}' && (retailRentalChartReport | json) != '[]'">
          <div class="setext">
            <ng-container *ngFor="let name of selectedChartValue; let l = last">
              {{ name}}<span *ngIf="!l" style="padding-right: 2px">,</span>
            </ng-container>
          </div>
          <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'" class="grades-menu directionLtr">
          <div (click)="$event.stopPropagation()">
            <h5>{{ "reports.choose_maximum_options" | translate }}</h5>
            <ng-container *ngFor="let category of getReportKeys()">
              <mat-checkbox
                (change)="selectChart($event, category)"
                [checked]="selectedChartValue.includes(category)"
                >{{ category }}</mat-checkbox
              >
            </ng-container>
          </div>
        </mat-menu>
      </div>
      <ng-container *ngIf="retailRentalChartLoading">
        <div style="padding-bottom: 2rem">
          <app-skeleton-loader
            [reportType]="chartView.barChart"
          ></app-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!retailRentalChartLoading">
        <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}' && (retailRentalChartReport | json) != '[]'; else noDataMessage">
        <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue : originalOrder; let i = index">
            <app-report-charts class="grchart" [data]="chart.value" [chartType]="chartView.smallBarChart"
              [isBorder]="false" [name]="chart.key"[index]="i"
              [ngStyle]="{
                'min-width':
                  (retailRentalChartReport | keyvalue).length == 1
                    ? '100%'
                    : '300px'
              }"
            >
            </app-report-charts>
          </ng-container>
        </div>
        <ng-template #noDataMessage>
          <div class="data_unavail">
            <span>
              {{ "reports.data_not_available" | translate }}
              <mat-icon
                role="img"
                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                aria-hidden="true"
                data-mat-icon-type="font"
                >equalizer</mat-icon
              >
            </span>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <!-- Rental Unit Size -->
  <div class="report-tabsection" id="{{ retailRentalMenuEnum.retailUnitSize }}"
    [ngClass]="{scroll: currentScroll === retailRentalMenuEnum.retailUnitSize}">
    <app-report-year-dropdown [reportType]="'rentalUnit'" (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
    <div class="report-model">
      <div class="report-model-header">
        <div class="info">
          <h3>
            {{
              sharedService.priceCalculation$() === sharedService.priceYearly
                ? ("reports.annualRentUnitSizeWise" | translate)
                : ("reports.rentUnitSizeWise" | translate)
            }}
          </h3>
          <span class="totalTransaction">
            ({{retailUnitsizeCount? reportService.formatInternationNum(retailUnitsizeCount): retailUnitsizeCount}}
            {{ "reports.totalUnits" | translate }})
          </span>
        </div>
      </div>
      <!-- <ng-container *ngIf="retailUnitsizeLoading">
                  <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
              </ng-container> -->
      <app-report-datatable-expanded
        class="rrr-rentunitsize-table"
        [showLoader]="!retailUnitsizeLoading"
        [tableColumns]="retailUnitsizeColumns"
        [dataLength]="retailUnitsizeCount"
        [tableData]="retailUnitsizedata"
        (pageEvent)="onPageEvent($event, retailRentalMenuEnum.retailUnitSize)"
        (sort)="sortData($event, retailRentalMenuEnum.retailUnitSize)"
        [allowExcelDownload]="true"
        (excelDownload)="downloadExcel($event, 'retail_rental_unit_size_v2', 'a')"
        [downloadLoading]="downloadLoading['a']"
        [showEstaterText]="true"
        [expandColumn]="true"
        [tableExpandColumns]="retailUnitsizeExpandColumns"
        [dropDownOption]="unitSizeDropdown"
        (dropDownValue)="dropDownChange($event)"
        [showTooltip]="true"></app-report-datatable-expanded>
    </div>
  </div>

  <!-- Rent Mall Wise -->
  <div class="report-tabsection" id="{{ retailRentalMenuEnum.retailRentMallWise }}"
    [ngClass]="{scroll: currentScroll === retailRentalMenuEnum.retailRentMallWise}">
    <app-report-year-dropdown [reportType]="'mallUnit'" (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
    <div class="report-model">
      <div class="report-model-header">
        <div class="info">
          <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly? ("reports.annualRentMallWise" | translate): ("reports.rentMallWise" | translate)}}</h3>
          <span class="totalTransaction">
            ({{ retailMallWiseCount ? reportService.formatInternationNum(retailMallWiseCount) : retailMallWiseCount }}
            {{ "reports.totalMalls" | translate }})</span>
        </div>
      </div>
      <app-report-datatable-expanded
        class="mallWise-rentalv2-table"
        [showLoader]="!retailMallWiseLoading"
        [tableColumns]="retailRentMallWiseColumns"
        [ngClass]="{activeTable: tableName == retailRentalMenuEnum.retailRentMallWise}"
        (lastClickedTable)="lastClickedTable($event, retailRentalMenuEnum.retailRentMallWise)"
        [dataLength]="retailMallWiseCount"
        [tableData]="retailMallWisedata"
        (pageEvent)="onPageEvent($event, retailRentalMenuEnum.retailRentMallWise)"
        (sort)="sortData($event, retailRentalMenuEnum.retailRentMallWise)"
        [allowExcelDownload]="true"
        (excelDownload)="downloadExcel($event, 'retail_rental_mall_v2', 'b')"
        [downloadLoading]="downloadLoading['b']"
        [showEstaterText]="true"></app-report-datatable-expanded>
    </div>
  </div>

  <!-- Rental Unit -->
  <ng-container *ngIf="isPremium">
    <div
      class="report-tabsection"
      [ngClass]="{ scroll: currentScroll === retailRentalMenuEnum.retailUnit }"
      id="{{ retailRentalMenuEnum.retailUnit }}"
    >
      <app-report-year-dropdown
        [reportType]="'unit'"
        (selectedYear)="selectedYear($event)"
      ></app-report-year-dropdown>
      <div class="report-model">
        <div class="report-model-header">
          <div class="info">
            <h3>{{ "reports.unitInSelectedProperties" | translate }}</h3>
            <span class="totalTransaction">
              ({{retailUnitCount? reportService.formatInternationNum(retailUnitCount): retailUnitCount}}
              {{ "reports.totalUnits" | translate }})
            </span>
          </div>
        </div>
        <app-report-datatable
          [showLoader]="!retailUnitLoading"
          [tableColumns]="retailUnitColumns"
          [ngClass]="{
            activeTable: tableName == retailRentalMenuEnum.retailUnit
          }"
          (lastClickedTable)="
            lastClickedTable($event, retailRentalMenuEnum.retailUnit)
          "
          [dataLength]="retailUnitCount"
          [tableData]="retailUnitdata"
          (pageEvent)="onPageEvent($event, retailRentalMenuEnum.retailUnit)"
          (sort)="sortData($event, retailRentalMenuEnum.retailUnit)"
          [allowExcelDownload]="true"
          (excelDownload)="downloadExcel($event, 'retail_rental_unit_v2', 'c')"
          [downloadLoading]="downloadLoading['c']"
          [showEstaterText]="true"
        >
        </app-report-datatable>
      </div>
    </div>
  </ng-container>
</div>
