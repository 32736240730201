import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportTransactionService {
  offsetData: any;

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  propertyTransactionPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  unitTransactionPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  propertyHistoryPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  unitHistoryPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  getTransactionSummary(year = null){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.transactionSummary}${changeParams}`;
    return this.apiServive.get(url, false, LoaderType.report);
  }

  getTransactionPropertyDetail(property_uid: string) {
    let url: string;
    let parameters ={...this.reportService.queryParams}
    let year =  new Date().getFullYear();
    if(parameters?.year){
        year = this.reportService.yearMaxValue(parameters?.year);
    }
    parameters['year']=year;
    url = `${environment.miUrl}${apiEndPoints.propertyTransactionDetail}${this.reportService.setfilterQueryParams(parameters)}&property_uid=${property_uid}`;
    return this.apiServive.get(url, false, LoaderType.report);
  }

  getPropertyTransaction(payload: any, year = null, type:string = ''){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = this.propertyTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    if(type) {
      queryParams['price_type'] = type;
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.propertyTransaction}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if(payload.sort_order){
      url += `&sort_order=${payload.sort_order}`;
    }

    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url, false, LoaderType.report);
  }

  downloadTransactionReport(type: string, user_id: number){
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  downloadExcelUrl(){
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }

  getUnitTransaction(payload: any, year = null, type:string = ''){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = this.unitTransactionPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    if(type) {
      queryParams['price_type'] = type;
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0;

    let url: string = `${environment.miUrl}${apiEndPoints.unitTransaction}${changeParams}&limit=${payload.pageSize}&offset=${offset}`;
    if(payload.sort_order){
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.unitTransactionPayload.pageNumber = offset;
    return this.apiServive.get(url, false, LoaderType.report);
  }


  formatOverViewTransaction(data: any){

    data['total_percentage_property_transaction'] = this.reportService.calcPercentage(data.prev_year_total_property_transaction, data.total_property_transaction);
    data['total_percentage_avg_property_transaction'] = this.reportService.calcPercentage(data.prev_year_average_property_transaction_price, data.average_property_transaction_price);
    data['total_percentage_unit_transaction'] = this.reportService.calcPercentage(data.prev_year_total_unit_transaction, data.total_unit_transaction);
    data['total_percentage_avg_unit_transaction'] = this.reportService.calcPercentage(data.prev_year_average_unit_transaction_price, data.average_unit_transaction_price);
    data['untillDateProperty'] = data.last_property_transaction_date ? moment.utc(data.last_property_transaction_date).local().format('DD MMM YYYY') : null;
    data['untillDateUnit'] = data.last_unit_transaction_date ? moment.utc(data.last_unit_transaction_date).local().format('DD MMM YYYY') : null;
    // data['average_trans_price'] = this.reportService.formatInternationNum(data.average_trans_price)
    return data;

  }

  getPropertyTransactionHistory(payload:any, property_uid:string){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    this.propertyHistoryPayload.pageNumber = offset;
    let url: string = `${environment.miUrl}${apiEndPoints.propertyTransactionHistory}${changeParams}&limit=${payload?.pageSize}&offset=${offset}&property_uid=${property_uid}`;
    if(payload.sort_order){
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0) url += `&is_count=true`;
    return this.apiServive.get(url, false, LoaderType.report);
  }

  getUnitTransactionHistory(payload:any, property_uid: string){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0;
    this.unitTransactionPayload.pageNumber = offset;
    let url: string = `${environment.miUrl}${apiEndPoints.unitTransactionHistory}${changeParams}&limit=${payload.pageSize}&offset=${offset}&property_uid=${property_uid}`;
    if(payload.sort_order){
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0) url += `&is_count=true`;
    return this.apiServive.get(url, false, LoaderType.report);
  }

}
