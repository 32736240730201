import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { WarehouseRentalMenuEnum } from "./report-warehouse-rental-v2.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class WarehouseRentalReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: WarehouseRentalMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.rentUnitSizeWise',
            name: WarehouseRentalMenuEnum.retailUnitSize,
            count: null
        },
        {
            label: 'reports.projectsProperties',
            name: WarehouseRentalMenuEnum.properties,
            count: null
        }
    ]

    static retailUnitsizeExpandColumns: any[] = [
        {field : 'neigh_name'},
        {field : 'dry_warehouse_range'},
        {field : 'cold_storage_range'},
        {field : 'frozen_warehouse_range'},
        {field : 'open_yard_range'}
    ];
    static retailUnitsizeExpandAverageColumns: any[] = [{field : 'neigh_name', convertNumber: false},{field : 'dry_warehouse_avg', convertNumber: true},{field :'cold_storage_avg', convertNumber: true},{field :'frozen_warehouse_avg', convertNumber: true},{field :'open_yard_avg',  convertNumber: true}];



    static retailUnitsizeAverageColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'dry_warehouse_avg',
            columnName: 'reports.DRY_WAREHOUSE',
            field: 'dry_warehouse_avg',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'dry_warehouse_range',
        },
        {
            header: 'cold_storage_avg',
            columnName: 'reports.COLD_STORAGE',
            field: 'cold_storage_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'cold_storage_range',
        },
        {
            header: 'frozen_warehouse_avg',
            columnName: 'reports.FROZEN_WAREHOUSE',
            field: 'frozen_warehouse_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'frozen_warehouse_range',
        },
        {
            header: 'open_yard_avg',
            columnName: 'reports.Open_Yard',
            field: 'open_yard_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'open_yard_range',
        }

    ]

    static warehouseUnitsizeColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'dry_warehouse_range',
            columnName: 'reports.DRY_WAREHOUSE',
            field: 'dry_warehouse_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'dry_warehouse_avg',
        },

        {
            header: 'cold_storage_range',
            columnName: 'reports.COLD_STORAGE',
            field: 'cold_storage_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'cold_storage_avg',
        },
        {
            header: 'frozen_warehouse_range',
            columnName: 'reports.FROZEN_WAREHOUSE',
            field: 'frozen_warehouse_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'frozen_warehouse_avg',
        },
        {
            header: 'open_yard_range',
            columnName: 'reports.Open_Yard',
            field: 'open_yard_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'open_yard_avg'
        }

    ]

    static warehousePropertiesColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.propertyNameUnitType',
            field: 'property_name',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'neigh_name',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neigh_name',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            border: false,
            isSortable: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'unit_area',
            columnName: 'reports.gross_leasable_area',
            field: 'unit_area',
            border: false,
            isSortable: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'lease_rate_range',
            columnName: 'reports.lease_rate',
            field: 'lease_rate_range',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'property_uid',
            columnName: 'reports.property_uid',
            field: 'property_uid',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false
        }
    ]
    static warehousePropertiesAverageColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'neigh_name',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neigh_name',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            border: false,
            isSortable: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'unit_area',
            columnName: 'reports.gross_leasable_area',
            field: 'unit_area',
            border: false,
            isSortable: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'lease_rate_avg',
            columnName: 'reports.lease_rate',
            field: 'lease_rate_avg',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'property_uid',
            columnName: 'reports.property_uid',
            field: 'property_uid',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false
        }
    ]


    static unitSizeDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1,
        reportType : false
    }
    static propertiesDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1,
        reportType : false
    }

}
