import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class TenantProfilingReportConstant {

    static reports = {
        totalReport: 'totalReport',
        share_trend: 'share_trend',
        tenantAreaOccupied: 'tenantAreaOccupied',
        tentant_average_lease: 'tentant_average_lease'
    }

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]

    static totalColumn: ReportTableColumn[] = [
        {
            header: 'reports.no_of_retail_unit',
            field: 'total_units',
        },
        {
            header: 'reports.gross_leasable_area',
            field: 'gross_leasable_area',
            symbol: GlobalContants.keySymbolContants.unit
        },
        {
            header: 'reports.avg_unit_area',
            field: 'avg_lease_area',
            symbol: GlobalContants.keySymbolContants.unit
        }
    ]

    static averageLeasetableColumns: ReportTableColumn[] = [
        {
            header: 'reports.retail_property_type',
            field: 'property_type',
            border: false
        },
        {
            header: 'reports.category',
            field: 'tanent_category',
            border: false
        },
        {
            header: 'reports.sub_category',
            field: 'sub_category',
            border: false
        },
        {
            header: 'reports.lease_rate',
            field: 'avg_lease_rate',
            border: false,
            cityCode: true
        }
    ]

    static mallTenantTrentAreaableColumns: ReportTableColumn[] = [
        {
            header: 'reports.tenant_name',
            field: 'tanent_name',
            border: false
        },
        {
            header: 'reports.PropertyName',
            field: 'property_name',
            border: false
        },
        {
            header: 'reports.floor',
            field: 'floor',
            border: false
        },
        {
            header: 'reports.tenant_category',
            field: 'tanent_category',
            border: false
        },
        {
            header: 'reports.sub_category',
            field: 'sub_category',
            border: false
        }
    ]
}
