export class Alert {
    id: string | undefined;
    type!: AlertType;
    message: string | undefined;
    autoClose: boolean = false;
    keepAfterRouteChange: boolean=false;
    fade: boolean= false;
    time: number | undefined;
    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}