<div class="table-dropdown" *ngIf="dropDownOption">
  <button class="btn-select-dropdown" [matMenuTriggerFor]="menu">
    <span *ngIf="showInvalueText">{{ "reports.show_value_in" | translate }} :</span>
    <span>{{
      selectedRadio
      ? ("reports." + selectedRadio | translate)
      : ("reports." + dropDownOption.default | translate)
      }}</span>
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before" class="table-dropdown-menu">
    <div (click)="$event.stopPropagation()">
      <h5 *ngIf="dropDownOption.label">
        {{ dropDownOption.label | translate }}
      </h5>
      <mat-radio-group aria-label="Select an option">
        <ng-container *ngFor="let option of dropDownOption.data; let i = index">
          <mat-radio-button (change)="selectRadioValue(option.id, option.value)" value="{{ option.id }}"
            [checked]="option.id === dropDownOption.default">{{ "reports." + option.value | translate
            }}</mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </mat-menu>
</div>
