import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class FBReportConstant {

    static reports = {
        totalReport: 'totalReport',
        retailFandBCategoryWise: 'retailFandBCategoryWise',
        retailFandBCuisineWise: 'retailFandBCuisineWise',
        retailFandBAverageLease: 'retailFandBAverageLease',
        retailFandBTrendArea: 'retailFandBTrendArea'
    }

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]

    static totalColumn: ReportTableColumn[] = [
        {
            header: 'reports.no_of_retail_unit',
            field: 'no_of_retail_unit',
        },
        {
            header: 'reports.gross_leasable_area',
            field: 'gross_leasable_area',
            symbol: GlobalContants.keySymbolContants.unit
        },
        {
            header: 'reports.avg_unit_area',
            field: 'avg_unit_area',
            symbol: GlobalContants.keySymbolContants.unit
        }
    ]

    static averageLeasetableColumns: ReportTableColumn[] = [
        {
            header: 'reports.retail_property_type',
            field: 'property_name',
            border: false
        },
        {
            header: 'reports.unit_subtype',
            field: 'unit_subtype',
            border: false
        },
        {
            header: 'reports.lease_rate',
            field: 'lease_rate',
            border: true,
            cityCode: true
        }
    ]

    static trendAreaOccupiedTenantableColumns: ReportTableColumn[] = [
        {
            header: 'reports.tenant_name',
            field: 'tenant_name',
            border: false
        },
        {
            header: 'reports.PropertyName',
            field: 'property_name',
            border: false
        },
        {
            header: 'reports.cuisine',
            field: 'cuisine',
            border: false
        }
    ]
}
