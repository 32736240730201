import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from './common/services/shared.service';
import { GlobalContants } from './common/constants/global.contants';
import { SEOService } from './common/services/SEOService.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from './common/services/store.service';

@Component({
  selector: 'app-page-404',
  template: ` 
  <app-outer-header *ngIf="!isLogin"></app-outer-header>
  <div class="container-500" [ngStyle]="{'height': isLogin ? 'calc(100% - 54px)': 'calc(100% - 54px'}">
   <div class="d-flex justify-content-center">
   <div class="content">
   <img src ="assets/images/new404image.svg">
        <br>
        <h4 class="text mt-3"><strong>{{'404pageLine1' | translate}}</strong></h4>
        <p>{{'404pageLine2' | translate}}</p>
        <div class="text-center back-button" style="padding-top:5px;">
        <a (click)="backToHome()" class="btn backbutton">{{'404pageBtn' | translate}}</a>
   </div>
  </div>
  </div>
  </div>
    `,
  styles: [`
  .logo img {
    object-fit: contain;
    max-width: 12%;
  }
      .container-500{
        width: 100%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .content {
      padding-top:0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: #000000;
        max-width: 90%;
        height: calc(100vh - 60px);
      }
      .content img {
        width: 40%;
      }
      .container-500 .status-code {
        font-size: 16rem;
        bottom: 25%;
        position: relative;
      }
      .container-500 .text {
        font-size: 2rem;
      }
    .content p{
      font-weight: 500;
      line-height: 1.4;
      margin-top: 1rem;
      font-size: 1.3rem;
      width: 66%;
    }
    .backbutton{
      background: var(--primary-dark);
      color: var(--white);
      border: var(--primary-dark);
      font-size: .75rem;
      width: auto;
      display: block;
      border-radius: 5px;
      padding: 7px 18px;
      cursor: pointer;
    }
    .backbutton:hover{
      background: var(--primary-light);
    }
    /* Responsive scss */

    @media screen and (max-width: 1025px) {
      .container-500 .status-code {
        font-size: 12rem;
      }
      .container-500 .text {
        font-size: 2.31rem;
      }
      .content p{
        font-size: 1.2rem;
    }
    }

    @media screen and (max-width: 767px) {
      .container-500 {
        width: 100%;
        height: 100vh;
        background: url('../assets/images/Page-404-Mobile.svg');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
      }
      .logo img {
        object-fit: contain;
        max-width: 30%;
      }
      .container-500 .status-code {
        font-size: 8rem;
      }
      .container-500 .text {
        font-size: 1.5rem;
      }
    }`
  ]
})

export class Page404Component {
  isLogin: boolean=false;
  
  constructor(private router: Router, private shareService: SharedService, private _seoService:SEOService, private translateService: TranslateService, private storeService: StoreService) {
    this.isLogin = shareService.UserId ? true: false;
    this.translateService.use(this.shareService.getCurrentLang());
    let CurrentLangID: any = this.storeService.get(GlobalContants.StoreKey.CurrentLangID);
    this.shareService.setLanguage(CurrentLangID)
  }
  backToHome(){
    let city = this.shareService.getCurrentCitySlug();
    let lang = this.shareService.getCurrentLang();
    if(city && lang) this.router.navigateByUrl(`/${city}-${lang}/${GlobalContants.Routes.Reports}`)
    else this.router.navigateByUrl(`/`)
    let CurrentLangID: any = this.storeService.get(GlobalContants.StoreKey.CurrentLangID);
    this.shareService.setLanguage(CurrentLangID)
  }
  ngOnInit():void{
    this._seoService.updateTitle(`${GlobalContants.defaultTitleConstant.defaultTitle}`)
  }
}
