import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-sub-menu',
  templateUrl: './report-sub-menu.component.html',
  styleUrls: ['./report-sub-menu.component.scss']
})
export class ReportSubMenuComponent {

  @Input() menuList: any;
  @Output() menuChangeValue = new EventEmitter();
  currentScroll: string = '';
  activeTab: number = 0;

  scrollE: ElementRef | any = null;
  isScroller = true;

  //Scrollbar left to right
  @ViewChild('reportTab') reportTab: ElementRef | any;
  showLeftArrow = false;
  showRightArrow = false;
  private resizeObserver: ResizeObserver | any;
  currentLang: string = '';
  constructor(private sharedService: SharedService) { }
  languageSub$: Subscription = new Subscription();
  ngOnInit() {
    this.setLang();
  }
  ngAfterViewInit() {
    this.scrollE = document.querySelector('.report-list-data');
    if (this.scrollE) {
      this.scrollE.addEventListener('scroll', this.onScroll.bind(this));
    }
    //Check left & right arrow visible or not function
    this.setupResizeObserver();
  }
  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
      this.checkArrows();
    })
  }
  /**
   * Resize div call this function
  */
  private setupResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => this.checkArrows());
    this.resizeObserver.observe(this.reportTab.nativeElement);
  }
  /**
   * Check left & right arrow visible or not
  */
  checkArrows() {
    // Define scrollRight if not already defined
    if (!Object.getOwnPropertyDescriptor(Element.prototype, 'scrollRight')) {
      Object.defineProperty(Element.prototype, 'scrollRight', {
        get: function() {
          return this.scrollWidth - this.clientWidth - this.scrollLeft;
        }
      });
    }
    // Assuming you have access to the element
    if(this.reportTab){
      const element = this.reportTab.nativeElement;
      // Get the scroll values
      const scrollLeft = element.scrollLeft;
      const scrollRight = element.scrollRight; // This should now be defined
      const clientWidth = element.clientWidth;
      const scrollWidth = element.scrollWidth;
      // Determine which arrows to show based on the current language
      if (this.currentLang === 'en') {
        this.showLeftArrow = scrollLeft > 0;
        this.showRightArrow = scrollLeft + clientWidth < scrollWidth;
      } else {
        this.showLeftArrow =  Math.abs(scrollLeft) + clientWidth < scrollWidth;
        this.showRightArrow = scrollRight + clientWidth > scrollWidth;
      }
    }
  }
  /**
   * Scroll To LeftSide
  */
  scrollLeft() {
    const clientWidth = this.reportTab.nativeElement.clientWidth;
    this.reportTab.nativeElement.scrollBy({ left: -(clientWidth / 3), behavior: 'smooth' });
    setTimeout(() => this.checkArrows(), 300);
  }
  /**
   * Scroll To RightSide
  */
  scrollRight() {
    const clientWidth = this.reportTab.nativeElement.clientWidth;
    this.reportTab.nativeElement.scrollBy({ left: clientWidth / 3, behavior: 'smooth' });
    setTimeout(() => this.checkArrows(), 300);
  }

  onScroll(event: Event) {
    if (this.isScroller) {
      const scrollTop = (event.target as HTMLElement).scrollTop;
      const tabSections = document.querySelectorAll('.report-tabsection');

      for (let i = 0; i < tabSections.length; i++) {
        const section = tabSections[i] as HTMLElement;
        const rect = section.getBoundingClientRect();

        if (rect.top >= 0 && rect.top <= scrollTop + 100) {
          this.activeTab = i;
          break; // Exit loop once active tab is found
        }
      }
      setTimeout(() => {
        this.currentScroll = '';
      })
    }
  }

  menuChange(menu: any, event: any) {
    this.activeTab = menu.name;
    this.isScroller = false;
    setTimeout(() => {
      this.menuChangeValue.emit(menu);
      this.currentScroll = menu.name;
    }, 100)
    setTimeout(() => {
      this.isScroller = true;
      const target = event.target as HTMLElement;
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      setTimeout(() => this.checkArrows(), 300);
    }, 1000)
    //click to menu tab center
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.languageSub$.unsubscribe();
  }

}
