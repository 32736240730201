import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReportTableDropdown } from 'src/app/reports/components/reports-lists/models/reports.model';

@Component({
  selector: 'app-dropdown-radio-selection',
  templateUrl: './dropdown-radio-selection.component.html',
  styleUrls: ['./dropdown-radio-selection.component.scss']
})
export class DropdownRadioSelectionComponent {

  @Input() dropDownOption: ReportTableDropdown | null = null;
  @Input() showInvalueText: boolean = true;
  @Output() dropDownValue: EventEmitter<boolean> = new EventEmitter();


  selectedRadio: any = '';

  selectRadioValue(value: any, name: any){
    this.selectedRadio = name;
    this.dropDownValue.emit(value)
  }

}
