import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/services/api.service';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';

@Injectable({
  providedIn: 'root'
})
export class ReportProjectDetailsService {

  constructor(
    private apiService: ApiService,
    private reportService: ReportService
  ) { }

  projDetailLandParcel: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  projDetailHouses: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  projectDetailOverview(queryParams: any, uid: string) {
    if (queryParams?.year) queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    let url: string;
    if (queryParams.year == null) {
      queryParams['year'] = new Date().getFullYear();
    }
    url = `${environment.miUrl}${apiEndPoints.projectDetailsOverview}?year=${queryParams['year']}&project_uid=${uid}`;
    return this.apiService.get(url);
  }

  getLandParcel(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.projDetailLandParcel.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.projectDetailsLandParcel}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiService.get(url, false);
  }
  getPropertyHouses(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.projDetailHouses.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.projectDetailsPropertyHouses}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiService.get(url, false);
  }
  downloadProjectDetail(type: string, user_id: number){
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiService.get(url);
  }
  downloadExcelUrl(){
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }
}
