import { ReportService } from 'src/app/reports/services/report.service';
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "../services/shared.service";


@Component({
    selector: 'app-child-breadcum',
    template: `
        <div class="sticky-div">
            <button (click)="backToTransaction()" [ngClass]="{ 'backto-backbtn-ar' : currentLang === 'ar'}"
                class="backto-backbtn"><mat-icon>keyboard_arrow_left</mat-icon>
                <span>{{'reports.backToReport'|translate}}</span>
            </button>
        </div>
    `,
})
export class ChildBreadcumComponent {

    @Input() property_uid:string='';
    @Output() back = new EventEmitter();
    currentLang: string = '';
    languageSub$: Subscription = new Subscription();

    constructor(private sharedService: SharedService, private reportService : ReportService){}

    ngOnInit(): void {
        this.setLang();
    }

    backToTransaction(){
        this.reportService.isDetailPageOpen = false;
        this.back.emit()
    }
    setLang(): void {
        this.languageSub$ = this.sharedService.language$.subscribe(lang => {
          this.currentLang = lang;
        })
    }
    ngOnDestroy(): void {
        this.languageSub$?.unsubscribe()
    }
}
