<!-- <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu> -->
    <ng-container *ngFor="let main_container of PowerBIData.pb_container">
        <div class="items" [ngClass]="{ 'scroll': currentScroll == main_container.sort_order}"
        id="{{ main_container.sort_order }}"
        [class.col1]="main_container.items.length == 1"
        [class.col2]="main_container.items.length == 2" 
        [class.col3]="main_container.items.length == 3" 
        [class.col4]="main_container.items.length == 4">
            <ng-container *ngFor="let item of main_container.items">
                <div class="item-card">
                    <app-pbi-visual-container [params]="params" [embedData]="embedData" [main_data]="PowerBIData" [itemId]="item"></app-pbi-visual-container>
                </div>
            </ng-container>
        </div>
    </ng-container>