<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <ng-container *ngIf="allDataLoading['overview']">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
    </ng-container>
    
        <app-property-details-overview [Property_Uid]="propertyInfo?.property_uid"></app-property-details-overview>
    <!-- DataTable -->
    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.transactions_history' | translate}}</h3>
                <span class="totalTransaction">{{ propertyTransactionHistoryCount }} {{'reports.total_transaction' | translate}}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable [showLoader]="!allDataLoading['table1']" [tableColumns]="propertyTransactionHistoryColumns" [dataLength]="propertyTransactionHistoryCount"
            [tableData]="propertyTransactionHistoryData" [allowExcelDownload]="false" [showEstaterText]="true"
            (pageEvent)="onPageEvent($event, 'property-history')" (sort)="sortData($event, 'property-history')"
            (excelDownload)="downloadExcel($event, 'property-history')"></app-report-datatable>
    </div>

    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.units_transactions_history' | translate}}</h3>
                <span class="totalTransaction">{{ unitTransactionHistoryCount }} {{'reports.total_transaction' | translate}}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable [showLoader]="!allDataLoading['table2']" [tableColumns]="unitTransactionHistoryColumns" [dataLength]="unitTransactionHistoryCount"
            [tableData]="unitTransactionHistoryData" [allowExcelDownload]="false" [showEstaterText]="true"
            (pageEvent)="onPageEvent($event, 'unit-history')" (sort)="sortData($event, 'unit-history')"
            (excelDownload)="downloadExcel($event, 'unit-history')"></app-report-datatable>
    </div>
</div>
