<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <app-property-details-overview [Property_Uid]="propertyInfo?.property_uid"></app-property-details-overview>

    <div class="report-tabsection">
            <div class="report-overview" *ngIf="!overViewPropertyLoading && overViewPropertySummary">
                
                <app-overview-card
                    [card_title]="'reports.totalResidentialUnits'"
                    [untillDate]="overViewPropertySummary.untillDate"
                    [total_prev]="overViewPropertySummary.prev_residential_units"
                    [total_current]="overViewPropertySummary.residential_units"
                    [total_percentage]="overViewPropertySummary.totalresidential"
                ></app-overview-card>

                <app-overview-card
                    [card_title]="'reports.totalCommercialUnits'"
                    [untillDate]="overViewPropertySummary.untillDate"
                    [total_prev]="overViewPropertySummary.prev_commercial_units"
                    [total_current]="overViewPropertySummary.commercial_units"
                    [total_percentage]="overViewPropertySummary.totalcommercial"
                ></app-overview-card>

                <app-overview-card
                    [card_title]="'reports.totalParkingLots'"
                    [total_current]="overViewPropertySummary.parking_lots"
                ></app-overview-card>

            </div>
        </div>


    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.units' | translate}}</h3>
                <span class="totalTransaction">{{ unitCount }}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable 
            [showLoader]="!unitLoading" 
            [tableColumns]="unitPropertyColumns" 
            [dataLength]="unitCount"
            [tableData]="unitData" 
            [allowExcelDownload]="true"
            (pageEvent)="onPageEvent($event, inventoryMenuEnum.unit)" 
            (sort)="sortData($event, inventoryMenuEnum.unit)"
            (excelDownload)="downloadExcel($event, 'ir_inventory_unit')">
        </app-report-datatable>
    </div>

    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.unitTransactionsPrices' | translate}}</h3>
                <span class="totalTransaction">{{ unitTransactionCount }}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable 
            [showLoader]="!unitTransactionLoading" 
            [tableColumns]="unitTransactionPropertyColumns" 
            [dataLength]="unitTransactionCount"
            [tableData]="unitTransactionData" 
            [allowExcelDownload]="true"
            (pageEvent)="onPageEvent($event, inventoryMenuEnum.unitTransaction)" 
            (sort)="sortData($event, inventoryMenuEnum.unitTransaction)"
            (excelDownload)="downloadExcel($event, 'ir_inventory_unit_transaction')">
        </app-report-datatable>
    </div>

</div>
    

 <!-- <app-report-property-detail></app-report-property-detail> -->