import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportService } from 'src/app/reports/services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportHouseVillasService } from '../report-house-villas.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Component({
  selector: 'app-report-housevilla-property-details',
  templateUrl: './report-housevilla-property-details.component.html',
  styleUrls: ['./report-housevilla-property-details.component.scss']
})
export class ReportHousevillaPropertyDetailsComponent {

  @Input() propertyInfo: any;
  propertyDetail: any = null;
  allDataLoading = false;
  chartView = ReportsChartEnum;
  sqm_unit:string="";

  constructor(
    private reportHouseVillaService: ReportHouseVillasService,
    private reportService: ReportService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.route.queryParams.subscribe((param: any) => {
      this.getHouseVillaPropertyDetail(param);
    })
  }

  getHouseVillaPropertyDetail(param: any) {
    this.propertyDetail =null
    this.allDataLoading = true;
    let payload = { ...param }
    payload['neigh_name'] = this.propertyInfo?.neigh_name;
    this.reportHouseVillaService.getHouseVillaPropertyDetail(payload, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          this.allDataLoading = false;
          if (res && res.status == 200) {
            this.propertyDetail = res.data;
            if(this.propertyDetail?.furnishing_status){
              this.propertyDetail['furnishing_status'] = this.propertyDetail?.furnishing_status.toString();
            }
            this.propertyDetail['cornerValue'] = this.propertyDetail.corner && this.propertyDetail.corner >= 4 ? 4 : (this.propertyDetail.corner || 0);
            this.propertyDetail['frontAge'] = this.propertyDetail.direction_info ? this.directionInfo(this.propertyDetail.direction_info) : null;
          } else {
            this.propertyDetail = null;
          }
        },
        error: (error: any) => {
          this.propertyDetail = null;
          this.allDataLoading = false;
        }
      }
    )
  }
  backToTransaction() {
    this.reportService.transactionPropertyDetails.next(false);
  }

  directionInfo(value: any) {
    let sum = 0;
    for (const property in value) {
      sum += +value[property]?.toFixed(2);
    }
    return sum?.toFixed(2);
  }
}
