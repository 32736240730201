import { SideMenu } from "../models/common.model";
import { GlobalContants } from "./global.contants";

// export const language = ['en', 'ar'];

export const commonContants = {
    defaultLanguage: 'en',
    englishCode: 'en',
    arabicCode: 'ar'
}
export const actionIdConstants = {
  Add_Objectives: 1,
  Add_Groups: 2,
  Add_Reports: 3,
  Update_Objectives: 4,
  Update_Groups: 5,
  Update_Reports: 6,
  Profile_Update: 7,
  Sync_Process: 8,
  Csv_Upload: 9,
  View_Objective: 10,
  View_Group: 11,
  View_Report: 12,
  Csv_Template: 14,
  User_Login: 13,
  Download_Report_Pdf: 15,
  Share_Report: 16,
  User_Logout: 17,
  Save_Report: 18,
  Add_Subscription_Master: 19,
  Update_Subscription_Master: 20,
  Add_User_Subscription: 21,
  Update_User_Subscription: 22,
  Add_Search_Master: 23,
  Update_Search_Master: 24,
  Delete_Search_Master: 25,
  Add_Parameter_Master: 26,
  Update_Parameter_Master: 27,
  Delete_Parameter_Master: 28,
  Update_Report_Filter: 29,
  Setting_Update: 30,
  Package_Plan_Request: 31,
  Add_Project: 32,
  Update_Project: 33,
  Share_Project: 34,
  Un_share_project: 35,
  Apply_Filter:36
};
export const menuList: SideMenu[] = [
//     {
//     index: 1,
//     icon: 'markets',
//     image: '',
//     title: 'dashboard',
//     isVisible: true,
//     isDisable: false,
//     routeUrl: `/${GlobalContants.Routes.Dashboard}`
// },
{
    index: 1,
    icon: 'description',
    title: 'myQueries',
    image: '',
    isVisible: true,
    isDisable: false,
    routeUrl: `/${GlobalContants.Routes.Reports}`
},{
    index: 2,
    icon: 'bookmark',
    title: 'mySavedQueries',
    image: '',
    isVisible: true,
    isDisable: false,
    routeUrl: `/${GlobalContants.Routes.BookMark}`
}
,{
  index: 3,
  icon: 'micMenu',
  title: 'aiReports',
  image: '',
  isVisible: false,
  isDisable: false,
  routeUrl: `/${GlobalContants.Routes.SampleReport}`
}]


export const countryCode = {
  saudi: 'SA',
}