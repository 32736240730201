import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidator {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            // if control is empty return no error
            return {};
          }

          // test the value of the control against the regexp supplied
          const valid = regex.test(control.value);

          // if true, return no error (no error), else return error passed in the second parameter
          return valid ? {} : error;
        };
      }

      static passwordMatchValidator(control: any) {
        const password: string = control.get('password').value; // get password from our password form control
        const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
        if (confirmPassword === '' && password !== '') {
          control.get('confirmPassword').setErrors({ required: true });
        }else if (password !== confirmPassword) {
          control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
        } else {
          control.get('confirmPassword').setErrors(null);
        }

      }


  static validatePhoneNumber(control: AbstractControl): ValidationErrors | null {
      const value = control.value;
        // Check if the value contains at least one character or digit
    if (value == undefined || (value && !/[0-9]/.test(value))) {
      return { 'validatePhoneNumber': true };
    }
    return null;
  }

  static notOnlyWhitespace(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value?.trim() === '') {
      return { 'notOnlyWhitespace': true };
    }else if(control.value?.trim().length < 2) {
      return {'minLength' : true}
    }
    return null;
  }

  // static specialCharacterValidator() {
  //   return (control: any) => {
  //     const value = control.value;
  //     const hasSpecialCharacter = /[!@#$%^&*(),?":{}|<>=+_]/.test(value);
  //     return hasSpecialCharacter ? { hasSpecialCharacter: true } : null;
  //   };
  // }

  }
