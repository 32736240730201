import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { debounceTime, distinctUntilChanged, lastValueFrom, map, Subject, switchMap, tap } from 'rxjs';
import { StoreService } from 'src/app/common/services/store.service';

import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-amenties-filter-form',
  templateUrl: './amenties-filter-form.component.html',
  styleUrls: ['./amenties-filter-form.component.scss']
})
export class AmentiesFilterFormComponent implements OnInit {

  @Input() data: string = '';
  @Input() name: any;
  @Input() required: boolean = false;
  @Input() filterData: any;
  @Input() amentiesSelected: string = '';

  formValue!: FormGroup;
  amentiesList: any[][] = [];
  filterValue: string[] = [];
  amentiesData: any[] = [];
  amentiesSelArr: any[] = [];
  filterIndex: number = -1;
  isEmpty: boolean = false;
  private searchSubject = new Subject<any>();
  @ViewChildren('searchInput') searchInputs!: QueryList<ElementRef>;

  radiusList: any[] = [];

  constructor(
    private controlContainer: ControlContainer,
    private fb: FormBuilder,
    private reportService: ReportService,
    private storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.formValue = this.controlContainer.control as FormGroup;
    this.radiusList = this.reportService.getDistance();
    this.getFilterData();
    // let amentiesSelArr = [];
    this.amentiesSelArr = this.amentiesSelected ? this.amentiesSelected.split(','):[];
    if (this.amentiesSelArr && this.amentiesSelArr?.length > 0) {
      this.selectedAmenitiesPatch(this.amentiesSelArr)
    } else {
      this.addAmenties();
    }
  }
  async selectedAmenitiesPatch (amentiesSelArr:any) {
  for (let i = 0; i < amentiesSelArr.length; i++) {
    const amentiesItem = amentiesSelArr[i].split('*');
    const radiusValue = Number(amentiesItem[2]);
    const radiusObj = this.radiusList.find((x: any) => x.value === radiusValue);
    if (!radiusObj) {
      continue;
    }
    await this.addAmenties('', radiusObj, i, 'patch', amentiesItem[1]);
  }
}
  get typeForms() {
    return this.formValue.get('amenties') as FormArray
  }

  get overlapForms() {
    if(this.typeForms.controls.length>1 && this.checkOverlapEnable){
      return this.formValue.get('is_overlap')?.value
    } 
    return false;
  }

  async addAmenties(
    amenities = '',
    distance = this.radiusList[0],
    i: number = 0,
    type: string = '',
    amentiesItem: string = ''
  ) {
    try {
      this.isEmpty = false;
      let index: number = 0;
  
      if (type === 'patch') {
        index = i;
      } else {
        const list = this.fb.group({
          name: [amenities],
          distance: [distance]
        });
        this.typeForms.push(list);
        index = this.typeForms.length - 1;
      }
      if (!this.amentiesList[index]?.length) {
        const apiCall$ = this.reportService.getAmenties(amenities, amentiesItem);
  
        try {
          const res: any = await lastValueFrom(apiCall$);
          if (res.status === 200) {
            this.amentiesList[index] = res.data;
            this.amentiesData = res.data;
            // Handle patch case
            if (type === 'patch') {
              const selectedAmenity = this.amentiesList[index].find(
                (x: any) => x.property_uid == amentiesItem
              );
  
              const list = this.fb.group({
                name: [selectedAmenity],
                distance: [distance]
              });
              this.typeForms.push(list);
            }
          } 
        } catch (err: any) {
          this.amentiesList.slice(index, 1);
          if (type === 'patch') this.amentiesData = [];
        }
      }
    } catch (err: any) {
      console.warn('Outer Error:', err.message);
    }
  }


  getFilterData() {
    this.searchSubject
  .pipe(
    debounceTime(800),
    distinctUntilChanged(),
    switchMap((data: any) => 
      this.reportService.getAmenties(data.value, data.selected).pipe(
        map((response:any) => ({ response, data })) 
      )
    )
  )
  .subscribe(({ response, data }) => {
    this.amentiesList[this.filterIndex] = response.data;
    if (this.amentiesSelArr[this.filterIndex]?.length && data.value == '') {
      const selectedAmenity = this.amentiesList[this.filterIndex].find(
        (x: any) => x.property_uid == data.selected
      );
      this.typeForms.controls[this.filterIndex].patchValue({
        name: selectedAmenity
      })
    }
    this.filterIndex - 1;
  });
  }

  
  closeSelection(index: number) {
    if (this.amentiesList[index].length === 0) {
      this.amentiesList[index] = this.amentiesData;
      this.filterValue[index] = '';
    }
  }

  deleteAmenties(i: number) {
    if(i > 0){
      this.typeForms.removeAt(i)
      this.filterValue.splice(i, 1);
      this.amentiesList.splice(i, 1);
    } else {
      this.typeForms.controls[i].patchValue({
        name:''
      })
    }
  }

  filterOptions(index: number) {
    const value = this.filterValue[index].toLowerCase();
    this.filterIndex = index;
    let data={
      value : (value?.length < 3)?'':value,
      selected:this.amentiesSelArr? this.amentiesSelArr[index]?.split('*')[1] : ''
    }
    this.searchSubject.next(data);
  }

  focusSearchInput(i: number) {
    setTimeout(() => {
        this.searchInputs.toArray()[i]?.nativeElement?.focus();
    }, 100); 
}
  checkSelectedValue(item: any, index: number) {
    const selectedAmenties = this.typeForms.length > 1 && this.typeForms.value.map((val: any) => val.name.property_uid) || [];
    selectedAmenties.splice(index, 1)
    return selectedAmenties.includes(item.property_uid)
  }

  get checkOverlapEnable(){
    return this.typeForms.controls.filter((data: any) => data.value.name !== '')
  }

  onOverlaphange(event: Event){
    const inputElement = event.target as HTMLInputElement;
    this.formValue.get('is_overlap')?.patchValue(inputElement.checked)
  }

}
