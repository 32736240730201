<div class="skeleton-loader">
    <ng-container *ngIf="reportType === tableView">
        <div class="table-section">
            <div *ngFor="let a of loaderList" class="row loader-body"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="reportType === pieChartView">
        <div class="circle-section">
            <div class="circle loader-body"></div>

        </div>
    </ng-container>

    <ng-container *ngIf="reportType === barChartView">
        <div class="bar-section">
            <div class="bar loader-body"></div>
            <div class="bar loader-body"></div>
            <div class="bar loader-body"></div>
            <div class="bar loader-body"></div>

        </div>
    </ng-container>
    <div class="overview-loader" [class.overview-loader-grid]="gridColumn > 0"
        [ngStyle]="{'grid-template-columns': gridColumn > 0 ? 'repeat('+gridColumn+', auto)' : ''}"
        *ngIf="reportType === overView">

        <div *ngFor="let a of loaderList" class="overview loader-body" [ngStyle]="{'height': height}"></div>
    </div>

    <div class="hortizontalBar-loader" *ngIf="reportType === horizontalBarChart">

        <div class="hbarChart loader-body" ></div>
        <div class="hbarChart loader-body" ></div>
        <div class="hbarChart loader-body" ></div>
    </div>


</div>