import { DecimalPipe } from '@angular/common';
import { Component, effect, Input, SimpleChanges } from '@angular/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportService } from '../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportsChartEnum } from '../../models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-property-details-overview',
  templateUrl: './property-details-overview.component.html',
  styleUrls: ['./property-details-overview.component.scss']
})
export class PropertyDetailsOverviewComponent {

  @Input() Property_Uid:any;
  unitType = GlobalContants.keySymbolContants.squareMeterUnit;
  CurrencyCode: any;
  PropertyDetails:any={};
  param:any;
  multi_story_residential:boolean=false;
  loading: boolean= false;
  chartView = ReportsChartEnum;
  constructor(private sharedService: SharedService,
    private reportService: ReportService,
    private route : ActivatedRoute,
    private decimalPipe : DecimalPipe
  ){
    effect(()=> this.multi_story_residential = this.reportService.multi_story_residential$$())

    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPropertyDetails(this.Property_Uid);
  }


  ngOnInit(): void {
    this.sharedService.language$.subscribe(res=>{
      this.getPropertyDetails(this.Property_Uid);
    })
  }


  getPropertyDetails(uid:string){
    this.loading = true;
    this.route.queryParams.subscribe((param: any) => {
      this.param = {...param}
    })
    this.reportService.PropertyDetail(this.param, uid).subscribe({
      next:(res:any)=>{
        this.loading = false;
        if(res?.data){
          this.PropertyDetails = {...res.data};
          this.PropertyDetails['year_of_build'] = this.PropertyDetails.year_of_build ? new Date(this.PropertyDetails.year_of_build).getFullYear(): null;
          this.PropertyDetails['frontage'] = this.PropertyDetails.direction_info ? this.directionInfo(this.PropertyDetails.direction_info) : null;
          this.PropertyDetails['land_area'] =  this.PropertyDetails['land_area'] ? this.reportService.formatInternationNum(this.PropertyDetails['land_area']) : null;
        }else{
          this.PropertyDetails = null;
        }
      },error: (err)=>{
        this.PropertyDetails = null;
      }
    })
  }

  directionInfo(value: any) {
    let sum = 0;
    for (const property in value) {
      sum += +value[property]?.toFixed(5);
    }
    return this.decimalPipe.transform(sum, '1.0-1') ;
  }
}
