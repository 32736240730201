<div class="tenants-info" >
    <h1>{{selected_property.shop_label}}</h1>
    <div class="floors" *ngIf="floorDetail">
        <h2>{{'reports.floor' | translate }} : </h2>
        <ul>
            <li *ngFor="let floor of floorDetail; index as i" (click)="setGeoLayer(i)" [ngClass]="{'active': selectedIndex == i, 'disabled_button' : selectedIndex == -1}">{{ floor.floor_name }} <span *ngIf="floor.unit_count && !selected_property?.is_property">{{floor.unit_count}}</span></li>
        </ul>

    </div>
    <div class="showperror" *ngIf="floorDetail && !currentFloorSelected?.unit_layout?.tenant_unit && !selected_property?.is_property">
        <span>{{'There is no' | translate}} "{{selected_property.shop_label}}" {{'on this floor' | translate }}</span>
    </div>
    <div class="floor-layers" *ngIf="currentFloorSelected">
        <ul>
            <li class="filled">{{'reports.occupiedUnits' | translate}} ({{ currentFloorSelected.unit_layout?.filled_unit || 0  }})</li>
            <li class="tenant" *ngIf="currentFloorSelected.unit_layout.tenant_unit">{{selected_property.shop_label}} ({{ currentFloorSelected.unit_layout?.tenant_unit || 0 }})</li>
            <li class="vacannt">{{'Vacant' | translate }} ({{ currentFloorSelected.unit_layout.vacant_unit || 0 }})</li>
        </ul>
    </div>
</div>
<div id="tenantsMap" #mapIdentifier [ngClass]="{'no-tenant': !currentFloorSelected?.unit_layout?.tenant_unit && !selected_property?.is_property}">
    
</div>
<app-spinner *ngIf="!floorDetail" [loderstate]="true"></app-spinner>

<!-- <ng-container  *ngIf="currentReportView === viewType.gridView || currentReportView === viewType.mapGridView">
<div class="mapLayer-panel">
    <app-map-property-details [propertyInfo]="selected_property"></app-map-property-details>
</div>   
</ng-container>  -->