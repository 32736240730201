import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportPropertyProfilingService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService,
    private translate: TranslateService
  ) { }

  getTotalPropertyProfilingReport(year = null){
    
    const url: string = `${environment.miUrl}${apiEndPoints.propertyProfilingTotalReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getTypePropertyProfilingReport(type: string, year = null){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = year ? year : this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.typePropertyProfilingReport}${changeParams}&type=${type}`;
    return this.apiServive.get(url);
  }

  getTypeGradePropertyProfilingReport(type: string, year = null){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = year ? year : this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.typeGradePropertyProfilingReport}${changeParams}&type=${type}`;
    return this.apiServive.get(url);
  }

  getGradeFurnishedPropertyProfilingReport(type: string, year = null){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = year ? year : this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.gradeFurnishedPropertyProfilingReport}${changeParams}&type=${type}`;
    return this.apiServive.get(url);
  }

  getAmenitiesPropertyProfilingReport(year = null){
    let queryParams = {...this.reportService.queryParams};
    if(queryParams?.year){
      queryParams.year = year ? year : this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.amenitiesPropertyProfilingReport}${changeParams}`;
    return this.apiServive.get(url);
  }

  convertAmenitiesReport(data: any, col: any){
    const report = [...this.getUniqueListBy(data, col[0])];
    const reportData = report.map((item: any) => {
      let reportItem = {...item};
      const getDataColum = data.filter((ele: any) => ele[col[0]] === reportItem[col[0]] );
      reportItem['expand'] = false;
      reportItem[col[1]] = this.translate.instant('reports.total');
      reportItem.amenities_property = getDataColum.reduce(function (a: any, b: any) {
                const total = b.amenities_property ? b.amenities_property : 0;
                return a + total;
              }, 0);
      reportItem.total_properties = getDataColum.reduce(function (a: any, b: any) {
        const total = b.total_properties ? b.total_properties : 0;
        return a + total;
      }, 0)
      reportItem.value = Number(getDataColum.reduce(function (a: any, b: any) {
        const total = b.value ? b.value : 0;
        return a + total;
      }, 0).toFixed(1));

     reportItem['subReport'] = getDataColum;
      return reportItem;
    })
    return reportData;
  }

  convertfurnishedProfiling(data: any, col: any) {
   const report = [...this.getUniqueListBy(data, col[0])];
   const reportData = report.map((item: any) => {
     let reportItem = {...item};

    const furnished = data.filter((ele: any) => ele[col[0]] === reportItem[col[0]] && ele.category === 'furnished' )?.reduce(function (a: any, b: any) {
      const total = b['total_unit'] ? b['total_unit'] : 0;
      return a + total;
    }, 0);

    const unfurnished = data.filter((ele: any) => ele[col[0]] === reportItem[col[0]] && ele.category === 'unfurnished' )?.reduce(function (a: any, b: any) {
      const total = b['total_unit'] ? b['total_unit'] : 0;
      return a + total;
    }, 0);

    const semifurnished = data.filter((ele: any) => ele[col[0]] === reportItem[col[0]] && ele.category === 'semi_furnished' )?.reduce(function (a: any, b: any) {
      const total = b['total_unit'] ? b['total_unit'] : 0;
      return a + total;
    }, 0);

     const neighName = data.filter((ele: any) => ele[col[0]] === reportItem[col[0]]);
     reportItem['expand'] = false;
     reportItem[col[1]] = this.translate.instant('reports.total');
     reportItem['furnished'] = furnished;
     reportItem['unfurnished'] = unfurnished;
     reportItem['semifurnished'] = semifurnished;
     reportItem['grandTotal'] = Number(furnished) + Number(unfurnished) + Number(semifurnished);
     reportItem['subReport'] = this.furnishedProfiling(neighName);

     return reportItem;

   })
   return reportData;
  }

  furnishedProfiling(data: any) {
    let report: any = [];
    let newData = [...data];
    newData.forEach((val: any) => {
      const checkExists = report.some((v: any) =>  val.property_grade === v.property_grade);
      if (!checkExists) {
        const furnished = newData.find((fur: any) => fur.property_grade === val.property_grade &&  fur.category === 'furnished' );

        const semi_furnished = newData.find((fur: any) => fur.property_grade === val.property_grade && fur.category === 'semi_furnished');
        const unfurnished = newData.find( (fur: any) => fur.property_grade === val.property_grade && fur.category === 'unfurnished' );
        const furnishedVal = furnished ? Number(furnished.total_unit) : 0;
        const semi_furnishedVal = semi_furnished
          ? Number(semi_furnished.total_unit)
          : 0;
        const unfurnishedVal = unfurnished ? Number(unfurnished.total_unit) : 0;

        const obj = {
          neigh_name: val.neigh_name,
          property_grade: val.property_grade,
          furnished: furnishedVal ?? 0,
          semifurnished: semi_furnishedVal ?? 0,
          unfurnished: unfurnishedVal ?? 0,
          grandTotal:
            Number(furnishedVal) +
            Number(semi_furnishedVal) +
            Number(unfurnishedVal),
        };
        report.push(obj);
      }
    });

    return report;
  }

  getUniqueListBy(arr: any, key: string) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()];
  }
}
