import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { SharedService } from 'src/app/common/services/shared.service';

import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-road-access-filter-form',
  templateUrl: './road-access-filter-form.component.html',
  styleUrls: ['./road-access-filter-form.component.scss']
})
export class RoadAccessFilterFormComponent implements OnInit {

   @Input() data: string = '';
    @Input() name: any;
    @Input() required: boolean = false;
    @Input() filterData: any;
    @Input() amentiesSelected: string = '';
  
    formValue!: FormGroup;
    dataList: any[][] = [];
    filterValue: string[] = [];
    streetNameData: any[] = [];
    filterIndex: number = -1;
    isEmpty: boolean = false;
    private searchSubject = new Subject<string>();
    currentLangID=1;
    radiusList: any[] = []
  
    constructor(
      private controlContainer: ControlContainer,
      private fb: FormBuilder,
      private reportService: ReportService,
      private sharedService: SharedService
    ) { }
  
    ngOnInit(): void {
      this.getChangedLang()
      this.formValue = this.controlContainer.control as FormGroup;
      this.radiusList = this.reportService.getRoadAccessDistance();
      this.getStreetName()
      this.getFilterData();
      this.reportService.street_name$.subscribe((val) => {
        this.typeForms.clear();
        this.dataList = [];
        this.filterValue = [];
        this.preSelected()
      })
    }

    getChangedLang(){
      this.sharedService.language$.subscribe(res=>{
        let language = this.sharedService.getCurrentLangID();
        this.currentLangID = language;
      })
    }
  
    get typeForms() {
      return this.formValue.get('street_name') as FormArray
    }
  
    
    getStreetName(searchText: string = '') {
      this.reportService.getStreetName(searchText).subscribe({
        next: (res: any) => {
          if (res.status === 200) {
          this.streetNameData = res.data.map((data: any) => {
            data.street_name_label = data.street_name[1];//.replaceAll(' ','_');
            data.street_name = data.street_name[this.currentLangID];
            return data
          });
            if (this.amentiesSelected) {
              this.preSelected();
            } else {
              this.addStreetName()
            }
  
          } else {
            this.streetNameData = [];
          }
        }, error: (err) => {
          this.streetNameData = [];
        }
      })
    }
  
    preSelected() {
      const amentiesSelected = this.amentiesSelected.split(',');
      amentiesSelected.length && amentiesSelected.forEach((item: any, index: number) => {
        const val = item.split('*')
        let amenties = this.streetNameData.find((item: any) => item.street_name_label == val[0])
        const distance = this.radiusList.find((item: any) => item.value == Number(val[1]))
        if (!amenties) {
          amenties = {
            street_name_label: val[0],
            street_name: val[0].toString().replaceAll('_',' '),
          }
  
          this.streetNameData.push(amenties);
        }
        this.addStreetName(amenties, distance)
  
      })
    }
  
    addStreetName(amenities = '', distance = this.radiusList[0]) {
      this.isEmpty = false;
      const list = this.fb.group({
        name: [amenities],
        distance: [distance]
      })
  
      this.typeForms.push(list);
      this.dataList.push([...this.streetNameData]); // Initialize filtered options
      this.filterValue.push(''); // Initialize filter value
    }
  
    getFilterData() {
      this.searchSubject
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          switchMap((value) => this.reportService.getStreetName(value))
        )
        .subscribe((res: any) => {
          this.dataList[this.filterIndex] = res.data.map((data: any) => {
            data.street_name_label = data.street_name[1];//.replaceAll(' ','_');
            data.street_name = data.street_name[this.currentLangID];
            return data
          });
          this.filterIndex - 1;
        });
    }
  
    closeSelection(index: number) {
      if (this.dataList[index].length === 0) {
        this.dataList[index] = this.streetNameData;
        this.filterValue[index] = '';
      }
  
    }
  
    deleteRow(i: number) {
      this.typeForms.removeAt(i)
      this.filterValue.splice(i, 1);
      this.dataList.splice(i, 1);
      if (this.typeForms.length === 0) {
        this.filterValue = [];
        this.dataList = [];
        this.addStreetName()
      }
    }
  
    filterOptions(index: number) {
      const value = this.filterValue[index].toLowerCase();
      this.filterIndex = index;
      if(value?.length < 3 ) {this.searchSubject.next(''); return};
      this.searchSubject.next(value);
    }
  
    checkSelectedValue(item: any, index: number) {
      const selectedAmenties = this.typeForms.length > 1 && this.typeForms.value.map((val: any) => val.name.street_name_label) || [];
      selectedAmenties.splice(index, 1)
      return selectedAmenties.includes(item.street_name_label)
    }
  
}
