import { Component } from '@angular/core';

import { SpinnerService } from './spinner.service';
import { SharedService } from '../../services/shared.service';
import { LoaderType } from '../../models/enums/common.enum';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  isLoading:boolean = false;
  isReportsLoading:boolean = false;
  language = 1;

  constructor(
    private spinnerService: SpinnerService,
    private sharedService: SharedService
  ){
    this.callLoader();
  }

  callLoader(){
    this.spinnerService.isLoader$.subscribe((res: any) => {
      if(res && res !== LoaderType.skip){
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    })
  }

}
