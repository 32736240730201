import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-searched-address',
  template: `<div class="breadcrumb" [class.hover]="hover" dir="ltr">
              <ng-container *ngIf="Searched_properties?.neigh_name">
                <span>{{Searched_properties?.neigh_name || ''}}</span>
                <mat-icon>keyboard_arrow_right</mat-icon>
                <span>{{Searched_properties?.block_name || ''}}</span>
                <mat-icon>keyboard_arrow_right</mat-icon>
              </ng-container>
              <span class="property_name">{{Searched_properties?.property_name || ''}}</span>
            </div>`,
  styleUrls: ['./searched-address.component.scss']
})
export class SearchedAddressComponent {

  @Input() Searched_properties:any;
  @Input() current_lang:string='en';
  @Input() hover:boolean=false;
}

