import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportTransactionService } from '../report-transactions.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { TransactionsReportConstant } from '../report-transactions.constants';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SharedService } from 'src/app/common/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-transactions-property-details',
  templateUrl: './report-transactions-property-details.component.html',
  styleUrls: ['./report-transactions-property-details.component.scss']
})
export class ReportTransactionsPropertyDetailsComponent implements OnInit, OnChanges {

  @Input() propertyInfo: any;

  propertyDetail: any = null;
  propertyTransactionHistoryCount: number = 0;
  unitTransactionHistoryCount: number = 0;
  propertyTransactionHistoryData: any[] = [];
  unitTransactionHistoryData: any[]=[];
  propertyTransactionHistoryColumns =  TransactionsReportConstant.propertyTransactionHistoryColumns;
  unitTransactionHistoryColumns =  TransactionsReportConstant.unitTransactionHistoryColumns;
  paramData: any;
  chartView= ReportsChartEnum;
  sqm_unit:string='';
  allDataLoading = {overview:false,table1:false,table2:false,};
  placeCode: any;
  // BHRCHECK = environment.BHRCHECK;
  constructor( 
    private reportTransactionService: ReportTransactionService,
    private reportService: ReportService,
    private route:ActivatedRoute,
    private translate: TranslateService,
    private sharedService: SharedService
  ){
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
  }

  ngOnInit(): void {
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    
    // this.unitTransactionHistoryColumns.map((e:any) => {
    //   if(this.placeCode == this.BHRCHECK){
    //     if(e.header == 'unit_area') e['symbol']= 'reports.'+GlobalContants.keySymbolContants.squarefeet;
    //   }
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTransactionPropertyDetail();
    this.route.queryParams.subscribe((param: any) => {
      this.paramData = param;
      this.reportTransactionService.propertyHistoryPayload.pageNumber = 0;
      this.reportTransactionService.unitHistoryPayload.pageNumber = 0;
      this.getPropertyTransactionHistory();
      this.getUnitTransactionHistory();
    })
  }

  getTransactionPropertyDetail(){
    this.allDataLoading['overview'] = true;
    this.reportTransactionService.getTransactionPropertyDetail(this.propertyInfo?.property_uid).subscribe(
      {next:(res: any) => {
         this.allDataLoading['overview'] = false;
        if (res && res.status == 200) {
          this.propertyDetail = res.data;
          this.propertyDetail['cornerValue'] = this.propertyDetail.corner && this.propertyDetail.corner >= 4 ? 4 : (this.propertyDetail.corner || 0);
          this.propertyDetail['frontAge'] = this.propertyDetail.direction_info ? this.directionInfo(this.propertyDetail.direction_info) : null;
        } else {
          this.propertyDetail = null;
        }
      },
      error: (error:any) => {
         this.allDataLoading['overview'] = false;
        this.propertyDetail = null;
      }}
    )
  }

  backToTransaction(){
    this.reportService.transactionPropertyDetails.next(false);
  }

  directionInfo(value: any){
    let sum = 0;
    for (const property in value) {
      sum += +value[property]?.toFixed(2);
    }
    return sum?.toFixed(2);
  }


  onPageEvent(e: any, report: string) {
    if(report == 'property-history'){
      if (e.previousPageIndex !== e.pageIndex) {
        this.reportTransactionService.propertyHistoryPayload.pageNumber = (e.pageSize * e.pageIndex);
      }
      this.getPropertyTransactionHistory();
    }else if(report == 'unit-history'){
      if (e.previousPageIndex !== e.pageIndex) {
        this.reportTransactionService.unitHistoryPayload.pageNumber = (e.pageSize * e.pageIndex);
      }
      this.getUnitTransactionHistory();
    }

  }
  sortData(event: any, report: string) {
    if(report == 'property-history'){
      let sort = {
        column: event.active,
        order: event.direction
      }
      this.reportTransactionService.propertyHistoryPayload.pageNumber = 0;
      this.reportTransactionService.propertyHistoryPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
      this.getPropertyTransactionHistory();
    }else if(report == 'unit-history'){
      let sort = {
        column: event.active,
        order: event.direction
      }
      this.reportTransactionService.unitHistoryPayload.pageNumber = 0;
      this.reportTransactionService.unitHistoryPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
      this.getUnitTransactionHistory();
    }
  }

  /**
   * This is a function for getting property transaction History data
   */
  getPropertyTransactionHistory(){
    this.allDataLoading['table1']=true;
    this.reportTransactionService.getPropertyTransactionHistory(this.reportTransactionService.propertyHistoryPayload, this.propertyInfo?.property_uid)
      .subscribe({
        next: (res: any) => {
          this.allDataLoading['table1']=false;
          if(this.reportTransactionService.propertyHistoryPayload.pageNumber == 0){
            this.propertyTransactionHistoryCount = res?.count;
          }

          if (res.data) {
            this.propertyTransactionHistoryData = res.data.map((value: any) => {
              value['value'] = value?.trans_sale || 0;
              value['record_type'] = this.reportType(value);
              value['date_of_transaction']= value.trans_sale_date ? moment.utc(value.trans_sale_date).local().format('DD MMM YYYY') : null;
             return value;
            })
          }
          else {
            this.propertyTransactionHistoryData = [];
            this.propertyTransactionHistoryCount = 0;
          }
        },
        error: () => {
          this.allDataLoading['table1']=false;
          this.propertyTransactionHistoryData = [];
          this.propertyTransactionHistoryCount = 0;
        }
      })
  }


  /**
   * This is a function for getting unit transaction History data
   */
  getUnitTransactionHistory(){
    this.allDataLoading['table2']=true;
    this.reportTransactionService.getUnitTransactionHistory(this.reportTransactionService.unitHistoryPayload, this.propertyInfo?.property_uid)
      .subscribe({
        next: (res: any) => {
          this.allDataLoading['table2']=false;
          if(this.reportTransactionService.unitHistoryPayload.pageNumber == 0){
            this.unitTransactionHistoryCount = res?.count;
          }
          if (res.data) {
            this.unitTransactionHistoryData = res.data.map((value: any) => {
              value['value'] = value?.trans_sale || 0;
              value['type'] = this.translate.instant('reports.Transaction');
              value['date_of_transaction']= value.trans_sale_date ? moment.utc(value.trans_sale_date).local().format('DD MMM YYYY') : null;
              return value;
            })
          } else {
            this.unitTransactionHistoryData = [];
            this.unitTransactionHistoryCount=0;
          }

        },
        error: () => {
          this.allDataLoading['table2']=false;
          this.unitTransactionHistoryData = [];
          this.unitTransactionHistoryCount = 0;
        }
      })
  }

  /**
   * This is a fuction for the use Change the type for table (land and building -- estater/gover)
   * @param value
   * @returns
   */
  reportType(value: any): string{
    if(value?.prop_type_estater == null || value?.prop_type_estater == 'null'){
      return '-'
    }
    if(value?.prop_type_govt && value?.prop_type_estater && value?.prop_type_govt !== value?.prop_type_estater){
      return `<span><del>${value?.prop_type_govt}</del></span><span class='reportType'>*${value?.prop_type_estater}</span>`;
    } else if(value?.prop_type_govt ) {
      return `<span>${value?.prop_type_govt}</span>`;

    }else if(value?.prop_type_estater ) {
      return `<span>${value?.prop_type_estater}</span>`;

    } else {
      return '-'
    }
  }

  downloadExcel(event: any, type: string) {

  }
}
