import { Component, Input, SimpleChanges } from '@angular/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportHouseVillasService } from '../../reports-lists/components/report-house-villas/report-house-villas.service';
import { ReportTransactionService } from '../../reports-lists/components/report-transactions/report-transactions.service';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Component({
  selector: 'app-map-property-details',
  templateUrl: './map-property-details.component.html',
  styleUrls: ['./map-property-details.component.scss']
})
export class MapPropertyDetailsComponent {

  @Input() propertyInfo: any;
  propertyDetail: any = null;
  reportName: any = null;
  allDataLoading = false;
  chartView = ReportsChartEnum;
  sqm_unit:string="";
  constructor(
    private reportTransactionService: ReportTransactionService,
    private reportHouseVillaService: ReportHouseVillasService,
    private mapService: ReportMapService,
    private reportService: ReportService,
    private route: ActivatedRoute
  ) { 
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
  }

  ngOnInit() {
    this.reportName = this.reportService.getCurrentReport()?.label;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!['house_and_villa','transactions'].includes(this.reportService.getCurrentReport()?.name)){
      this.getTransactionPropertyDetail();
     }else{
      
    }
  }

  getTransactionPropertyDetail() {
    this.propertyDetail = null;
    this.allDataLoading = true;
    this.reportTransactionService.getTransactionPropertyDetail(this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          this.allDataLoading = false;
          if (res && res.status == 200) {
            this.propertyDetail = res.data;
            this.propertyDetail['cornerValue'] = this.propertyDetail.corner && this.propertyDetail.corner >= 4 ? 4 : (this.propertyDetail.corner || 0);
            this.propertyDetail['frontAge'] = this.propertyDetail.direction_info ? this.directionInfo(this.propertyDetail.direction_info) : null;
          } else {
            this.propertyDetail = null;
          }
        },
        error: (error: any) => {
          this.allDataLoading = false;
          this.propertyDetail = null;
        }
      }
    )
  }

  getHouseVillaPropertyDetail(param: any) {
    let payload = { ...param }
    payload['neigh_name'] = this.propertyInfo?.neigh_name;

    this.reportHouseVillaService.getHouseVillaPropertyDetail(payload, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.propertyDetail = res.data;
            if (this.propertyDetail?.furnishing_status) {
              this.propertyDetail['furnishing_status'] = this.propertyDetail?.furnishing_status.toString();
            }
            this.propertyDetail['cornerValue'] = this.propertyDetail.corner && this.propertyDetail.corner >= 4 ? 4 : (this.propertyDetail.corner || 0);
            this.propertyDetail['frontAge'] = this.propertyDetail.direction_info ? this.directionInfo(this.propertyDetail.direction_info) : null;
          } else {
            this.propertyDetail = null;
          }
        },
        error: (error: any) => {
          this.propertyDetail = null;
        }
      }
    )
  }
  backToTransaction() {
    this.reportService.transactionPropertyDetails.next(false);
    this.mapService.propertyinfotoggle.next(false);
    this.reportService.showTenantMap$.next(false);
  }

  directionInfo(value: any) {
    let sum = 0;
    for (const property in value) {
      sum += +value[property]?.toFixed(2);
    }
    return sum?.toFixed(2);
  }

}
