<div class="report-tab-container">
    <div class="arrow left" *ngIf="showLeftArrow" (click)="scrollLeft()"><mat-icon>arrow_back_ios</mat-icon></div>
<div class="report-tab" (window:resize)="checkArrows()" #reportTab>
    <ul>

        <li *ngFor="let menu of menuList" (click)="menuChange(menu, $event)" [ngClass]=" {'active' : (currentScroll === menu.name) || (activeTab === menu.name) }">
            {{ menu.label | translate }}<span style="color: var( --color-field-primary)" *ngIf="(menu?.count >= 0 || menu?.count >= '0') && menu?.count != null && menu?.count !== ''">({{menu?.count}})</span>
        </li>

    </ul>
</div>
<div class="arrow right" *ngIf="showRightArrow" (click)="scrollRight()"><mat-icon>arrow_forward_ios</mat-icon></div>
</div>
