import { Component, Input } from '@angular/core';
import { ReportPropertyDetailService } from '../../report-property-detail.service';

@Component({
  selector: 'app-cost-of-construction',
  templateUrl: './cost-of-construction.component.html',
  styleUrls: ['./cost-of-construction.component.scss']
})
export class CostOfConstructionComponent {

  @Input() propertyInfo: any;

  costOfConstruction: any = null;

  constructor(private reportPropertyDetailService: ReportPropertyDetailService){}

  ngOnChanges(): void {
    this.getCostOfConstruction();
  }

  getCostOfConstruction() {
    this.reportPropertyDetailService.getCostOfConstruction().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.costOfConstruction = res.data[0];
          } else {
            this.costOfConstruction = null;
          }
        }
      }
    )
  }
}
