import { DataType } from 'src/app/common/models/enums/common.enum';
import { ReportTableColumn } from '../../../../models/reports.model';
import { OfficePropertyDetailMenuEnum } from './office-report-property-detail.enum';
export class OfficeReportPropertyDetailConstant {
  static overViewMenu = [
    {
      label: 'reports.overview',
      name: OfficePropertyDetailMenuEnum.overview,
    },
    {
      label: 'reports.unitRental',
      name: OfficePropertyDetailMenuEnum.unitRental,
      count: null,
    },
    {
      label: 'reports.propertyTransactions',
      name: OfficePropertyDetailMenuEnum.propertyTransaction,
      count: null,
    },
  ];

  static unitRentalColumn: ReportTableColumn[] = [
    {
      header: 'unit_type',
      columnName: 'reports.unitType',
      field: 'unit_type',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'unit_size',
      columnName: 'reports.unitSize',
      field: 'unit_size',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      symbol: DataType.squareMeter,
      convertNumber: true,
    },
    {
      header: 'rental_lumpsum',
      columnName: 'reports.rental',
      field: 'rental_lumpsum',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      symbol: DataType.currencyCode,
      convertNumber: true,
    },
    {
      header: 'lease_rate',
      columnName: 'reports.lease_rate',
      field: 'lease_rate',
      border: true,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
      symbol: DataType.currencyWithSquareMonthly,
    },
  ];
  static unitRentalColumnSaudi: ReportTableColumn[] = [
    {
      header: 'unit_type',
      columnName: 'reports.unitType',
      field: 'unit_type',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'unit_size',
      columnName: 'reports.unitSize',
      field: 'unit_size',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      symbol: DataType.squareMeter,
      convertNumber: true,
    },
    {
      header: 'rental_lumpsum',
      columnName: 'reports.rental',
      field: 'rental_lumpsum',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      symbol: DataType.squareMeter,
      convertNumber: true,
    },
    {
      header: 'lease_rate',
      columnName: 'reports.lease_rate',
      field: 'lease_rate',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
      symbol: DataType.currencyWithSquareYearly,
    },
    {
      header: 'transactionDate',
      columnName: 'reports.transactionDate',
      field: 'transactionDate',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
  ];

  static propertyTransactionColumn: ReportTableColumn[] = [
    {
        header: 'transaction_date',
        columnName: 'reports.transactionDate',
        field: 'transaction_date',
        border: false,
        isSortable: true,
        isContainsHTML: false,
        convertNumber: false
    },
    {
        header: 'value',
        columnName: 'reports.value',
        field: 'value',
        border: true,
        isSortable: true,
        isContainsHTML: false,
        convertNumber: true,
        symbol: DataType.currencyCode,
    },
    {
        header: 'transaction_area',
        columnName: 'reports.transaction_area',
        field: 'transaction_area',
        isSortable: true,
        border: false,
        isContainsHTML: false,
        symbol: DataType.squareMeter,
        convertNumber: true,
    },
    {
        header: 'transaction_rate',
        columnName: 'reports.property_transaction_rate',
        field: 'transaction_rate',
        border: false,
        isSortable: true,
        isContainsHTML: false,
        convertNumber: true,
        symbol: DataType.currencyWithSquareMeter,
    },
    {
      header: 'transaction_type',
      columnName: 'reports.transactionType',
      field: 'transaction_type',
      border: false,
      isSortable: true,
      isContainsHTML: false,
    },
    {
        header: 'record_type',
        columnName: 'reports.record_type',
        field: 'record_type',
        border: false,
        isSortable: true,
        isContainsHTML: true,
    },
]
}
