import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { Subscription } from 'rxjs';

import { SharedService } from '../../../common/services/shared.service';
import { ReportService } from '../../services/report.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/common/services/google-analytics.service';

@Component({
  selector: 'app-report-query',
  templateUrl: './report-query.component.html',
  styleUrls: ['./report-query.component.css']
})
export class ReportQueryComponent implements OnInit, OnDestroy {

  collapseReports$: boolean = false;
  currentLang: string | null = null;
  toggleSub$: Subscription = new Subscription();
  languageSub$: Subscription = new Subscription();
  reportSub$: Subscription = new Subscription();
  reportStudiesData: any = [];
  resetSearch: boolean = false;
  citySub$ = new Subscription();
  searchReportName: string = "";
  @ViewChild('menuTrigger') trigger!: MatMenuTrigger;


  constructor(
    private sharedService: SharedService,
    public reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private router: Router
  ){
    this.setLang();
  }

  ngOnInit(): void {
    this.toggleReport();
    if(this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId)){
      this.getUserSubscription();   
    }
    this.getCurrentCity();
  }
  genrateReportButtonClick(){
    this._googleAnalyticsService.trackEvent('button_click', {event_category:'Generate Report', event_name: 'Button Click', event_label: 'Generate A new Report', event_value:'', screen_name:''})
  }
  getObjectStudyReport(){
    this.reportService.getObjectStudyReport().subscribe(
      {next:(res: any) => {
        if(res.status === 200 && res.data){
          if(this.activatedRoute.snapshot.queryParams['rid'])
            this.reportService.reportQueryToggle$.next(false);
          else
            this.reportService.reportQueryToggle$.next(true);
          this.reportStudiesData = res.data;
        } else {
          this.reportService.reportQueryToggle$.next(false);
          this.reportStudiesData = [];
        }
      },
      error: (error:any) => {
        this.reportService.reportQueryToggle$.next(false);
        this.reportStudiesData = [];
      }}
    )
  }

  getCurrentCity(){
    this.citySub$ = this.sharedService.selectedCity$.subscribe(city => {

      if(this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId)){
        this.getUserSubscription();
      }
    });
    this.reportSub$ = this.reportService.selectedReport$.subscribe(report => {
      this.trigger?.closeMenu();
    })
  }

  getUserSubscription(){
   this.reportService.getUserSubscription().subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.reportService.userSubscription = res.data[0]?.access_details;
          this.reportService.userSublength = res.data.length;
          this.reportService.reportPermission$.next(true);
          this.getObjectStudyReport();
        } else {
          this.reportService.userSublength = 0;
          this.reportService.userSubscription = [];
          this.getObjectStudyReport();   
        }
      },
      error: (error:any) => {
        this.reportService.userSublength = 0;
        this.reportService.userSubscription = [];
        this.getObjectStudyReport();   
      }}
    )
  }


  toggleReport(){
    this.toggleSub$ = this.reportService.reportQueryToggle$.subscribe((toggle: boolean) => {
      this.collapseReports$ = toggle;
    });
  }

  setLang(): void{
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
    })
  }

  collapseReportSide(toggle: boolean){
    this.reportService.reportQueryToggle$.next(toggle);
  }

  onSearchChange(value: string){
    this.searchReportName = value;
  }

  ngOnDestroy(): void {
    this.languageSub$?.unsubscribe();
    this.toggleSub$?.unsubscribe();
    this.citySub$?.unsubscribe();
    this.reportSub$?.unsubscribe();
  }
}
