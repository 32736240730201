import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportTenantProfilingService } from './report-tenant-profiling.service';
import { TenantProfilingReportConstant } from './report-tenant-profiling.constants';
import { ReportChartColumn, ReportTableColumn } from '../../models/reports.model';
import { ReportsListsService } from '../../services/reports-lists.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';


@Component({
  selector: 'app-report-tenant-profiling',
  templateUrl: './report-tenant-profiling.component.html',
  styleUrls: ['./report-tenant-profiling.component.scss']
})
export class ReportTenantProfilingComponent  implements OnInit, OnChanges {

  @Input() params: any;
  chartView = ReportsChartEnum;

  reports = TenantProfilingReportConstant.reports;

  totalColumnReport: any = [];
  totalColumnReportColumn: ReportTableColumn[] = TenantProfilingReportConstant.totalColumn;
  averageLeasetableData: any = [];
  averageLeasetableColumns: ReportTableColumn[] = TenantProfilingReportConstant.averageLeasetableColumns;
  mallTenantTrentAreaableData: any = [];
  mallTenantTrentAreaableColumns: ReportTableColumn[] = TenantProfilingReportConstant.mallTenantTrentAreaableColumns;
  mallTenantShareCategoryReport: ReportChartColumn[] = [];

  totalLoading = false;
  mallTenantTrentAreaableDataLoading = false;
  averageLeasetableDataLoading = false;
  mallTenantShareCategoryReportLoading = false;
  
  constructor(
    private reportTenantProfilingService: ReportTenantProfilingService,
    private reportsListsService: ReportsListsService
  ){}

  ngOnInit(){
    this.getReports();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.getReports();
    }
  }
  
  getReports(){
    this.getTotalTenantReport();
    this.getMallTenantShareCategoryReport();
    this.getMallTenantTrentAreaReport();
    this.getMallTenantLeaseRatePropertyTypeReport();
  }

  getTotalTenantReport(year = null){
    this.totalLoading = true;
    this.reportTenantProfilingService.getTotalTenantReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.totalColumnReportColumn = [...this.reportsListsService.yearColumnAppend(TenantProfilingReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
          this.totalColumnReport = this.reportsListsService.summaryReportConversion( res.data, TenantProfilingReportConstant.totalColumn)
        } else {
          this.totalColumnReport = [];
        }
        this.totalLoading = false;
      },
      error: (error:any) => {
        this.totalColumnReport = [];
        this.totalLoading = false;
      }}
    )
  }

  getMallTenantShareCategoryReport(year = null){
    this.mallTenantShareCategoryReportLoading = true;
    this.reportTenantProfilingService.getMallTenantShareCategoryReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.mallTenantShareCategoryReport = res.data.map((data: any) => {
            const obj = {
              category: data.category,
              value: data.unit_percentage
            }
            return obj;
          })
        } else {
          this.mallTenantShareCategoryReport = [];
        }
        this.mallTenantShareCategoryReportLoading = false;
      },
      error: (error:any) => {
        this.mallTenantShareCategoryReport = [];
        this.mallTenantShareCategoryReportLoading = false;
      }}
    )
  }

  getMallTenantTrentAreaReport(year = null){
    this.mallTenantTrentAreaableDataLoading = true;
    this.reportTenantProfilingService.getMallTenantTrentAreaReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          if( res?.data?.year.length > 0){
            this.mallTenantTrentAreaableColumns = [...this.reportsListsService.yearColumnAppend(TenantProfilingReportConstant.mallTenantTrentAreaableColumns, res?.data.year, false, GlobalContants.keySymbolContants.unit, 'total_area')];
          }
          this.mallTenantTrentAreaableData = res.data.data;
        } else {
          this.mallTenantTrentAreaableData = [];
        }
        this.mallTenantTrentAreaableDataLoading = false;
      },
      error: (error:any) => {
        this.mallTenantTrentAreaableData = [];
        this.mallTenantTrentAreaableDataLoading = false;
      }}
    )
  }
  

  getMallTenantLeaseRatePropertyTypeReport(year = null){
    this.averageLeasetableDataLoading = true;
    this.reportTenantProfilingService.getMallTenantLeaseRatePropertyTypeReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.averageLeasetableData = res.data;
        } else {
          this.averageLeasetableData = [];
        }
        this.averageLeasetableDataLoading = false;
      },
      error: (error:any) => {
        this.averageLeasetableData = [];
        this.averageLeasetableDataLoading = false;
      }}
    )
  }

  selectedYear(data: any){
    switch (data.reportType) {
      case this.reports.totalReport:
        this.getTotalTenantReport(data.year);
        break;
      case this.reports.share_trend:
        this.getMallTenantShareCategoryReport(data.year);
        break;
      case this.reports.tenantAreaOccupied:
        this.getMallTenantTrentAreaReport(data.year);
        break;
      case this.reports.tentant_average_lease:
        this.getMallTenantLeaseRatePropertyTypeReport(data.year);
        break;
    }
  }

} 
