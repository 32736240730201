<div class="land-value-detail">
    <div class="info">
       <h5>{{'reports.landValue' | translate}} <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionStates' | translate"></i></h5>
       <span class="landvalue">
        <img *ngIf="landValueInfo?.land_value" src="../../../../../../../../../assets/images/plus-minus.svg" alt="">
        <h1>{{ landValueInfo?.land_value  ? (landValueInfo?.land_value | number: '1.0-1') : '-' }}<sup *ngIf="landValueInfo?.land_value">{{ CurrencyCode }}</sup></h1>
       </span>
        <span *ngIf="landValueInfo?.land_value_date">As on {{landValueInfo?.land_value_date}}</span>
    </div>
    <div class="info">
        <h5>{{'reports.landOrPropertyTransactions' | translate}} <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionShowsTheTotal' | translate"></i></h5>
        <h1>{{ landValueInfo?.property_transactions  ? (landValueInfo?.property_transactions | number: '1.0-1') : '-' }}</h1>
        <span  *ngIf="landValueInfo?.min_property_transaction_date && landValueInfo?.max_property_transaction_date">{{'from' | translate}} {{landValueInfo?.min_property_transaction_date}} {{'reports.to' | translate}} {{landValueInfo?.max_property_transaction_date}}</span>
    </div>
    <div class="info">
        <h5>{{'reports.unitsTransactions' | translate}} <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionIndicates' | translate"></i></h5>
        <h1>{{ landValueInfo?.unit_transactions  ? (landValueInfo?.unit_transactions | number: '1.0-1') : '-' }}</h1>
        <span  *ngIf="landValueInfo?.min_unit_transaction_date && landValueInfo?.max_unit_transaction_date">{{'from' | translate}} {{landValueInfo?.min_unit_transaction_date}} {{'reports.to' | translate}} {{landValueInfo?.max_unit_transaction_date}}</span>
    </div>
</div>
