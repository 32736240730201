import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";

export class MallProfilingReportConstant {

    static totalSummaryColumn: ReportTableColumn[] = [
        {
            header: 'reports.title',
            field: 'title',
        }
    ]
    
    static totalColumn: ReportTableColumn[] = [
        {
            header: 'reports.land_area',
            field: 'land_area',
            symbol: GlobalContants.keySymbolContants.unit
        },
        {
            header: 'reports.builtup_area',
            field: 'total_buildup_area',
        },
        {
            header: 'reports.gross_leasable_area',
            field: 'gross_leasable_area',
            symbol: GlobalContants.keySymbolContants.unit
        },
        {
            header: 'reports.no_of_floor',
            field: 'no_of_floor',
        },
        {
            header: 'reports.total_retail_unit',
            field: 'total_retail_unit',
        },
        {
            header: 'reports.total_anchor_tenant',
            field: 'total_anchor_tenant',
        },
        {
            header: 'reports.total_car_parking',
            field: 'total_car_parking',
        },
        {
            header: 'reports.occupancy',
            field: 'occupancy',
            symbol: GlobalContants.keySymbolContants.percentage
        }
    ]

    static floorWisetableColumns: ReportTableColumn[] = [
        {
            header: 'reports.floors',
            field: 'floors',
            border: false
        },
        {
            header: 'reports.gross_leasable_area',
            field: 'gross_leasable_area',
            border: false,
            symbol: GlobalContants.keySymbolContants.unit,
            convertNumber: true
        },
        {
            header: 'reports.no_of_tenants',
            field: 'no_of_tenants',
            border: false
        },
        {
            header: 'reports.total_anchor_tenant',
            field: 'total_anchor_tenant',
            border: false
        }
    ]

}
