<div class="container">
  <div class="inputBx">
    <div class="form-group">
      <label for="inputField">{{"reports.filter_title" | translate}}</label>
      <input id="inputField" class="inputField" #input placeholder="Ex. Polygon1" [formControl]="inputText"
        (blur)="updateErrorMessage()" required maxlength="21">

      <div class="other-text d-flex">
        <div class="error-msg error-msg-inner" *ngIf="inputText.invalid">
          <span>{{errorMessage}}</span>
        </div>
        <div class="character-count">
          <span>{{input.value.length}}</span><span>/ 20</span>
        </div>
      </div>
    </div>
    <div class="form-group-btns" fxLayout="row" fxLayoutAlign="center center">
      <button class="btn btn-cancel" *ngIf="type!='info' && type!='warn'"
        (click)="confirmDialogBox(false)">{{"User.Cancel" | translate}}</button> &nbsp;&nbsp;
      <button class="btn btn-save" *ngIf="type!='info' && type!='warn'"
        (click)="confirmDialogBox(true)">{{"map.save_catchment" | translate}}</button>
    </div>
  </div>