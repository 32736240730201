<div class="overview">
    <div class="overview-data">
        <h2>{{card_title | translate}} <span *ngIf="infoTooltip" class="info-tooltip" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="infoTooltipContent"><img src="assets/images/contact_support.svg" alt=""></span></h2>
        <div class="untill" *ngIf="untillDate">
            <span class="neight" *ngIf="neighborhood">{{'reports.in' | translate}} {{ neighborhood }}</span>
            <span>{{"reports.asOf" | translate}}</span>
            <span class="directionLtr"> {{ untillDate ? untillDate : ''}}</span>
        </div>
        <h1 class="directionLtr">
            <ng-container *ngIf="total_current; else otheroverViewSummary">
                {{current}}{{symble && symble == '%' ? symble : ''}} <sup class="sup-font">{{symble && symble !== '%' ? symble : ''}}</sup>
            </ng-container>
            <ng-template #otheroverViewSummary>-</ng-template>
        </h1>
       
        <ng-container *ngIf="propertiesCount">
             <h3>{{'reports.basedOn' | translate}} {{propertiesCount}} {{'reports.projectsProperties' | translate}}</h3>
        </ng-container>
        <ng-container *ngIf="card_sub_title">
             <h3>{{card_sub_title | translate}}
              <ng-container *ngIf="showTooltip">
                <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]=" toolTipContent | translate"></i>
              </ng-container></h3>
        </ng-container>
        <ng-container *ngIf="BasedOnRentalsText">
             <h3>{{"reports.basedOnRentalsFor" | translate}} {{rentalsName}}</h3>
        </ng-container>


    </div>
    <div class="horizontal-progress" *ngIf="graphData && !graphData?.loader">
        <ng-container *ngIf="graphData?.data?.length else noDataMessage">
            <ng-container *ngFor="let category of graphData?.data; let i=index;">
                <div class="progress d-flex align-items-center" *ngIf="category[1]['calculation'] != 0">
                    <h5 [title]="category[0]">{{category[0]}}</h5>
                    <section>
                        <div class="bar"
                            [ngStyle]="{'width': (category[1]['calculation'] > 0 ? category[1]['calculation']+'%' : '2%'), 'opacity': getOpacity(i, getNumber(category[1]['category']) )}">
                        </div>
                        <small class="directionLtr">{{getNumber(category[1]['category']) |
                            number:'1.0-1'}} {{symble}}</small>
                    </section>

                </div>
            </ng-container>
        </ng-container>
        <ng-template #noDataMessage>
            <div class="data_unavail">
                <span>
                    {{"reports.data_not_available" | translate}} <mat-icon role="img"
                        class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                        aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                </span>
            </div>

        </ng-template>
    </div>
    <div *ngIf="graphData?.loader">
      <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1" [height]="'150px'"></app-skeleton-loader>
  </div>

</div>
