<div class="report-model">


  <div class="report-model-header">
      <div class="info">
          <h3>{{'reports.unitRental' | translate}}</h3>
          <span class="totalTransaction">({{unitRentalCount ?
            reportService.formatInternationNum(unitRentalCount) : unitRentalCount}} {{"reports.totalUnits" |
            translate}})</span>
      </div>

  </div>
  <app-report-datatable
      [showLoader]="!unitRentalLoading"
      [tableColumns]="unitRentalPropertyColumns"
      [dataLength]="unitRentalCount"
      [tableData]="unitRentalData"
      [allowExcelDownload]="true"
      (pageEvent)="onPageEvent($event)"
      (sort)="sortData($event)"
      (excelDownload)="downloadExcel($event, 'property_details_unit_rental')"
      [downloadLoading]="downloadLoading" [showEstaterText]="true">
  </app-report-datatable>
</div>
