<div [ngClass]="{'custom_zindex' : tenantPropertyInfo}" class="map_section {{classname}}" [class.map-without-report] = "draw_btn_toggle" [class.isReport] = "isReport && (is_save_allow || (studyName.toLocaleLowerCase() === 'apartment' && (reportName.toLocaleLowerCase() === 'occupancy_and_lease' || reportName.toLocaleLowerCase() === 'inventory_in_all_the_neighborhoods' || reportName.toLocaleLowerCase() === 'units' || reportName.toLocaleLowerCase() === 'property_profiling')))">
<div id="map" #mapIdentifier [ngClass]="{'arb-map': current_lang == 'ar'}" [ngStyle]="{'margin-top' : reportId ? '60px' : '0px', 'height' : reportId ? 'calc(100vh - 143px)' : 'calc(100vh - 83px)'}">
    <div class="map-spinner" *ngIf="map_spinner">
        <mat-spinner class="background" mode="determinate" value="100">
        </mat-spinner>
        <mat-spinner class="mat-spin"></mat-spinner>
    </div>

    <div class="draw-toolbar"
        [ngStyle]="{'margin': draw_btn_toggle ? '10px':''} "
        *ngIf="draw_btn_toggle">
        <ng-container *ngIf="polygon_create">
            <app-draw-catchment
            [drawnPolygons]="drawnPolygons"
            [filterList]="filterList"
            [polygon_chip]="polygon_chip"
            [polygon_create]="polygon_create"
            (polygonRemove)="polygonWKTRemove($event)"
            (catchmentExpandCol)="catchmentExpand($event)"
            (resetCatchmentConfirm)="resetCatchmentConfirm()"
            (loadCatchmentModel)="loadCatchmentModel()"
            (drawNewCatchmentLoad)="drawNewCatchmentLoad()"
        ></app-draw-catchment>

        </ng-container>


        <button
            [disabled]="reportService.getLockReport()"
            *ngIf="!polygon_create  && reportName?.toLocaleLowerCase() !== 'population'"
            id="draw_polybtn"
            class="draw-btn"
            [matMenuTriggerFor]="catchment"
            (click)="savedCatchment.length === 0 ? drawPolygon() : null"
            >
            <img *ngIf="reportService.getLockReport()" class="lock" src="assets/images/Lock-disable.png" alt="">
            <i class="setCatchment-icon"></i>
            <span *ngIf="!polygon_create">{{"map.set_catchment" | translate}}</span>

        </button>

        <div class="load" *ngIf="loadCatchmentShow">
            <app-load-catchment (closeLoadCatchment)="closeLoadCatchment($event)"></app-load-catchment>
        </div>


        <mat-menu #catchment="matMenu" class="catchment-panel" [xPosition]="current_lang == 'ar' ? 'before' : 'after'">
            <ul class="catchment-menu">
                <li (click)="drawPolygon();">{{'reports.drawNewCatchment' | translate}}</li>
                <li (click)="loadCatchment()">{{'reports.viewSavedCatchment' | translate}}</li>
            </ul>

        </mat-menu>
    </div>
    <ng-container *ngIf="draw_btn_toggle">
      <div class="universalSearchContainer">
        <div class="search-setting">
          <div class="universal-search-btn-chip">
            <div style="position: relative;">
              <mat-chip-row *ngIf="isShowChip" class="searchedProperty searchedPropertyOnDrawPolygon">
                {{Searched_properties?.property_name || ''}}
                <mat-icon matChipRemove (click)="closeChip(true)" class="remove-property">close</mat-icon>
              </mat-chip-row>
              <div class="search-address-hover">
                <app-searched-address [hover]="true" [Searched_properties]="Searched_properties" [current_lang]="current_lang"></app-searched-address>
              </div>
            </div>
            <div class="map-filter map-search-comman">
              <button (click)="openMapSearch(); $event.stopPropagation()" class="map-filter_icns map-filter_icns-common" [class.active-map-search] = "isShowChip || isOpenMapSearch" matTooltip="{{'reports.search'| translate}}"><mat-icon
                  svgIcon="searchIcon"></mat-icon></button>
              <button (click)="layerToggle(); $event.stopPropagation()" class="map-filter_icns map-filter_icns-common" [class.activeLayer]="mapLayertoggle"  matTooltip="{{'reports.map-settings'|translate}}"><mat-icon
                    svgIcon="layerIcon"></mat-icon></button>
            </div>
            <app-map-layers #customLayerOverlay [mapLayertoggle]="mapLayertoggle" [mapZoom]="map.getZoom()"></app-map-layers>
          </div>
          <!--  -->
          <ng-container *ngIf="isOpenMapSearch">
           <app-universal-search [Searched_properties]="Searched_properties" 
           (SearchedParcelOnMap)="locateSearchedParcelOnMap($event)"
           (closeChip)="closeChip(false, $event); $event.stopPropagation()"></app-universal-search>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="tenentContaine">
      <div class="tenant-names d-flex align-items-center" *ngIf="TenantName">
        {{'reports.show_on_map' | translate}}:
        <div class="tenant-btn d-flex align-items-center">
        <button>
          <span class="name_t" dir="ltr"  [matTooltip]="TenantName" matTooltipPosition="above" (click)="recenterMap()">{{TenantName}}</span>
        </button>
        <span class="icon" (click)="removeCustomMaker(true)">
          <mat-icon class="remove-filter">close</mat-icon>
        </span>
      </div>
      </div>
    </div>

    <div class="legendContainer map_legend" *ngIf="legendToggle && legend_data_by_group?.length"
        [ngStyle]="{'direction': current_lang == 'ar' ? 'ltr':''} ">
        <span class="legendContainer_header"><span>{{'Map Legend' | translate}}
                ({{layerselected}})</span> <span class="legend_close" (click)="collapseLegend()"><img
                    class="legend_close" src="../../../../assets/images/cross.svg" /></span></span>
        <div *ngFor="let main_legend of legend_data_by_group;let idx = index;">
            <span class="mapLegend" style="font-weight: 500;">{{ main_legend.label[current_lang_id]?main_legend.label[current_lang_id]:main_legend?.label[1] }}</span>
            <div *ngFor="let legend of main_legend.data;let idx = index; let isLast=last;" class="mapLegend rows">
                <div style="display: flex;">
                        <span class="legendcolor" [class.circle]="main_legend.legend_type == 'grade'" [ngStyle]="{'background-color': legend.color}"></span>
                        {{ legend?.label?.[current_lang_id]?legend.label[current_lang_id]:legend?.label?.[1] }}
                </div>
            </div>
        </div>
    </div>
</div>
<app-reports-tenants-map-view *ngIf="tenantPropertyInfo" [propertyInfo]="tenantPropertyInfo"></app-reports-tenants-map-view>
</div>

<ng-container *ngIf="showAreaToggle && (currentReportView === viewType.gridView || currentReportView === viewType.mapGridView)">
    <div class="mapLayer-panel"
    [ngClass]="{'mapLayer-fullpanel': showAreaToggle && currentReportView === viewType.gridView }">
      <app-report-neigh-area-details [propertyInfo]="neightInfo"></app-report-neigh-area-details>
    </div>
</ng-container>

<ng-container *ngIf="showProjectToggle && (currentReportView === viewType.gridView || currentReportView === viewType.mapGridView)">
    <div class="mapLayer-panel" [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }">
        <app-report-project-details [projectInfo]="selected_property"></app-report-project-details>
    </div>
</ng-container>

<ng-container *ngIf="propertyinfoToggle && (currentReportView === viewType.gridView || currentReportView === viewType.mapGridView)">
    <div class="mapLayer-panel property-detail-scroll"
        [ngClass]="{'mapLayer-fullpanel': currentReportView === viewType.gridView }">
        <app-power-bi-property-details [property]="selected_property"></app-power-bi-property-details>
    </div>
</ng-container>
