import { DataType } from 'src/app/common/models/enums/common.enum';
import {
  ReportTableColumn,
  ReportTableDropdown,
} from '../../models/reports.model';
import { RetailRentalMenuEnum } from './report-retail-rental-v2.enum';

export class RetailRentalReportConstant {
  static push(rentalReportFilterArray: any) {
    throw new Error('Method not implemented.');
  }

  static overViewMenuL1 = [
    {
      label: 'reports.overview',
      name: RetailRentalMenuEnum.overview,
      count: null,
    },
    {
      label: 'reports.rentUnitSizeWise',
      name: RetailRentalMenuEnum.retailUnitSize,
      count: null,
    },
    {
      label: 'reports.rentMallWise',
      name: RetailRentalMenuEnum.retailRentMallWise,
      count: null,
    }
  ];
  static overViewMenuL3 = [
    {
      label: 'reports.overview',
      name: RetailRentalMenuEnum.overview,
      count: null,
    },
    {
      label: 'reports.rentUnitSizeWise',
      name: RetailRentalMenuEnum.retailUnitSize,
      count: null,
    },
    {
      label: 'reports.rentMallWise',
      name: RetailRentalMenuEnum.retailRentMallWise,
      count: null,
    },
    {
      label: 'reports.unitInSelectedProperties',
      name: RetailRentalMenuEnum.retailUnit,
      count: null,
    },
  ];

  static retailUnitsizeExpandColumns: any[] = [
    { field: 'category', convertNumber: false },
    { field: 'lower_ground_range', convertNumber: false },
    { field: 'ground_range', convertNumber: false },
    { field : 'first_floor_range', convertNumber: false },
    { field : 'second_floor_range', convertNumber: false },
    { field : 'third_floor_range', convertNumber: false },
    { field : 'fourth_floor_range', convertNumber: false },
    { field : 'fifth_floor_range', convertNumber: false },
    { field : 'sixth_floor_range', convertNumber: false },
    { field : 'higher_floor_range', convertNumber: false },
  ];
  static retailUnitsizeExpandAverageColumns: any[] = [
    { field: 'category', convertNumber: false },
    { field: 'lower_ground_avg', convertNumber: true },
    { field: 'ground_avg', convertNumber: true },
    { field : 'first_floor_avg', convertNumber: true },
    { field : 'second_floor_avg', convertNumber: true },
    { field : 'third_floor_avg', convertNumber: true },
     { field : 'fourth_floor_avg', convertNumber: true },
    { field : 'fifth_floor_avg', convertNumber: true },
    { field : 'sixth_floor_avg', convertNumber: true },
    { field : 'higher_floor_avg', convertNumber: true },
  ];

  static retailUnitsizeAverageColumns: ReportTableColumn[] = [
    {
      header: 'unit_size',
      columnName: 'reports.unit_size',
      field: 'unit_size',
      isSortable: false,
      border: false,
      symbol: DataType.squareMeter,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'lower_ground_avg',
      columnName: 'reports.lower_ground',
      field: 'lower_ground_avg',
      symbol: DataType.currencyWithSquareMeter,
      isSortable: false,
      border: false,
      isContainsHTML: false,
      convertNumber: true,
      toolTipField: 'lower_ground_range',
    },

    {
      header: 'ground_avg',
      columnName: 'reports.ground',
      field: 'ground_avg',
      symbol: DataType.currencyWithSquareMeter,
      isSortable: false,
      border: false,
      isContainsHTML: false,
      convertNumber: true,
      toolTipField: 'ground_range',
    },
    {
      header: `first_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `1Floor`,   // firstFloor
      field: `first_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'first_floor_range',

    },
    {
      header: `second_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `2Floor`,   // firstFloor
      field: `second_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'second_floor_range',

    },
    {
      header: `third_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `3Floor`,   // firstFloor
      field: `third_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'third_floor_range',

    },
    {
      header: `fourth_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `4Floor`,   // firstFloor
      field: `fourth_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'fourth_floor_range',

    },
    {
      header: `fifth_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `5Floor`,   // firstFloor
      field: `fifth_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'fifth_floor_range',

    },
    {
      header: `sixth_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `6Floor`,   // firstFloor
      field: `sixth_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'sixth_floor_range',

    },
    {
      header: `higher_floor_avg_floor_count_column`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `higherFloor`,   // firstFloor
      field: `higher_floor_avg`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'higher_floor_range',

    }
  ];

  static retailUnitsizeColumns: ReportTableColumn[] = [
    {
      header: 'unit_size',
      columnName: 'unit_size',
      field: 'unit_size',
      isSortable: false,
      border: false,
      symbol: DataType.squareMeter,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'lower_ground_range',
      columnName: 'reports.lower_ground',
      field: 'lower_ground_range',
      symbol: DataType.currencyWithSquareMeter,
      isSortable: false,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      toolTipField: 'lower_ground_avg',
    },

    {
      header: 'ground_range',
      columnName: 'reports.ground',
      field: 'ground_range',
      symbol: DataType.currencyWithSquareMeter,
      isSortable: false,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      toolTipField: 'ground_avg',
    },
    {
      header: `first_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `1Floor`,   // firstFloor
      field: `first_floor_range`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'first_floor_avg',

    },
    {
      header: `second_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `2Floor`,   // firstFloor
      field: `second_floor_range`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'second_floor_avg',

    },
    {
      header: `third_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `3Floor`,   // firstFloor
      field: `third_floor_range`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'third_floor_avg',

    },
    {
      header: `fourth_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `4Floor`,   // firstFloor
      field: `fourth_floor_range`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'fourth_floor_avg',

    },
    {
      header: `fifth_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `5Floor`,   // firstFloor
      field: `fifth_floor_range`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'fifth_floor_avg',

    },
    {
      header: `sixth_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `6Floor`,   // firstFloor
      field: `sixth_floor_range`,              // first_floor_range, first_floor_avg
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'sixth_floor_avg',

    },
    {
      header: `higher_floor_range`,      // first_floor_range_floor_count_column, first_floor_avg_floor_count_column
      columnName: `higherFloor`,
      field: `higher_floor_range`,
      symbol: DataType.currencyWithSquareMeter,
      border: false,
      isSortable: false,
      isContainsHTML: false,
      convertNumber:false,
      toolTipField: 'higher_floor_avg',

    }
  ];

  static retailUnitColumns: ReportTableColumn[] = [
    {
      header: 'tenant_name_unit_number',
      columnName: 'reports.tentant_name_with_unit_number',
      field: 'tenant_name_unit_number',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      showOnMap: true,
      isTenent: true,
    },
    {
      header: 'property_name',
      columnName: 'reports.PropertyName',
      field: 'property_name',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      showOnMap: true,
      tenentShowOnMap: true,
    },
    {
      header: 'floor',
      columnName: 'reports.floor',
      field: 'floor',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'gross_area',
      columnName: 'reports.gross_area',
      field: 'gross_area',
      isSortable: true,
      border: false,
      symbol: DataType.squareMeter,
      isContainsHTML: false,
      convertNumber: true,
    },
    {
      header: 'lease_rate',
      columnName: 'reports.lease_rate',
      field: 'lease_rate',
      border: false,
      symbol: DataType.currencyWithSquareMeter,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: true,
    },
    {
      header: 'rental_price',
      columnName: 'reports.monthly_rentals',
      field: 'rental_price',
      border: false,
      isSortable: true,
      symbol: DataType.currencyCode,
      isContainsHTML: false,
      convertNumber: true,
    },
    {
      header: 'is_anchor',
      columnName: 'reports.anchor',
      field: 'is_anchor',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'neighborhood',
      columnName: 'reports.NEIGHBORHOODS',
      field: 'neighborhood',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'tenant_category',
      columnName: 'reports.tenant_category',
      field: 'tenant_category',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'property_uid',
      columnName: 'reports.property_uid',
      field: 'property_uid',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'unit_uid',
      columnName: 'reports.unit_uid',
      field: 'unit_uid',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'categories',
      columnName: 'reports.categories',
      field: 'categories',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
  ];
  static retailUnitColumnsYearly: ReportTableColumn[] = [
    {
      header: 'tenant_name_unit_number',
      columnName: 'reports.tentant_name_with_unit_number',
      field: 'tenant_name_unit_number',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      showOnMap: true,
      isTenent: true,
    },
    {
      header: 'property_name',
      columnName: 'reports.PropertyName',
      field: 'property_name',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      showOnMap: true,
      tenentShowOnMap: true,
    },
    {
      header: 'floor',
      columnName: 'reports.floor',
      field: 'floor',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'gross_area',
      columnName: 'reports.gross_area',
      field: 'gross_area',
      isSortable: true,
      border: false,
      symbol: DataType.squareMeter,
      isContainsHTML: false,
      convertNumber: true,
    },
    {
      header: 'lease_rate',
      columnName: 'reports.annual_lease_rate',
      field: 'lease_rate',
      border: false,
      symbol: DataType.currencyWithSquareMeter,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: true,
    },
    {
      header: 'rental_price',
      columnName: 'reports.yearly_rentals',
      field: 'rental_price',
      border: false,
      isSortable: true,
      symbol: DataType.currencyCode,
      isContainsHTML: false,
      convertNumber: true,
    },
    {
      header: 'is_anchor',
      columnName: 'reports.anchor',
      field: 'is_anchor',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'neighborhood',
      columnName: 'reports.NEIGHBORHOODS',
      field: 'neighborhood',
      isSortable: true,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'tenant_category',
      columnName: 'reports.tenant_category',
      field: 'tenant_category',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'property_uid',
      columnName: 'reports.property_uid',
      field: 'property_uid',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'unit_uid',
      columnName: 'reports.unit_uid',
      field: 'unit_uid',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'categories',
      columnName: 'reports.categories',
      field: 'categories',
      border: false,
      isSortable: true,
      isContainsHTML: false,
      convertNumber: false,
    },
  ];
  static retailRentMallWiseColumns: ReportTableColumn[] = [
    {
      header: 'property_name',
      columnName: 'reports.propertyNameFloor',
      field: 'property_name',
       isSortable: false,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
      showOnMap: true,
      tenentShowOnMap: true,
    },
    {
      header: 'avg_lease_rate',
      columnName: 'reports.averageLeaseRate',
      field: 'avg_lease_rate',
      border: false,
      symbol: DataType.currencyWithSquareMeter,
       isSortable: false,
      isContainsHTML: false,
      convertNumber: true,
      isBreak: true
    },
    {
      header: 'min_lease_rate',
      columnName: 'reports.min_lease_rate',
      field: 'min_lease_rate',
      border: false,
       isSortable: false,
      symbol: DataType.currencyWithSquareMeter,
      isContainsHTML: false,
      convertNumber: true,
      isBreak: true
    },
    {
      header: 'max_lease_rate',
      columnName: 'reports.max_lease_rate',
      field: 'max_lease_rate',
      border: false,
       isSortable: false,
      symbol: DataType.currencyWithSquareMeter,
      isContainsHTML: false,
      convertNumber: true,
      isBreak: true
    },
    {
      header: 'neighborhood',
      columnName: 'reports.NEIGHBORHOODS',
      field: 'neighborhood',
       isSortable: false,
      border: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'property_uid',
      columnName: 'reports.property_uid',
      field: 'property_uid',
      border: false,
       isSortable: false,
      isContainsHTML: false,
      convertNumber: false,
    },
    {
      header: 'categories',
      columnName: 'reports.categories',
      field: 'categories',
      border: false,
       isSortable: false,
      isContainsHTML: false,
      convertNumber: false,
    },
  ];
  static unitSizeDropdown: ReportTableDropdown = {
    label: 'reports.show_price_in',
    data: [
      {
        value: 'Range',
        id: 'Range',
      },
      {
        value: 'Average',
        id: 'Average',
      },
    ],
    default: 'Range',
    maxSelect: 1,
    reportType: false,
  };
}
