<div class="report-model">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.propertyTransactions' | translate}}</h3>
            <span class="totalTransaction">({{ propertyTransactionCount }})</span>
        </div>


    </div>
    <app-report-datatable
        [showLoader]="!propertyTransactionLoading"
        [tableColumns]="transactionPropertyColumns"
        [dataLength]="propertyTransactionCount"
        [tableData]="propertyTransactionData"
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event)"
        (sort)="sortData($event)"
        (excelDownload)="downloadExcel($event, 'ir_property_details_property_transactions')">
    </app-report-datatable>
</div>
