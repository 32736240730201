import { Injectable } from '@angular/core';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';
import { ApiService } from 'src/app/common/services/api.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportOfficeInventoryService {
  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  neighborhoodPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  propertiesPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  /**
   *
   * @returns
   * OverView Data
  */
  getOverview(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeInventoryOverviewReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  /**
   *
   * @returns
   * Bar Chart Data
   */
  getOverviewGraph(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }

    const changeParams = this.reportService.setfilterQueryParams(queryParams);

    let url: string = `${environment.miUrl}${apiEndPoints.officeInventoryGrossReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  getNeighborhood(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.neighborhoodPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.officeInventoryNeighborhoodReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.neighborhoodPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  /**
   * 
   * @param payload 
   * @returns 
   * Properties 
   */
  getProperties(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.propertiesPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.officeInventoryPropertiesReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.propertiesPayload.pageNumber = offset;
    return this.apiServive.get(url, false);
  }
  downloadOfficeReport(type:string, user_id:any, property_uid:string='', proj_uid:string=''){
    let url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    if(property_uid) url += `&property_uid=${property_uid}`;
    if(proj_uid) url += `&project_uid=${proj_uid}`;
    return this.apiServive.get(url);
  }
}
