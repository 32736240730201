<app-reports-container
    [tableColumn]="totalUnitReportColumn"
    [dataValue]="totalUnitReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [dataValue]="investmentUnitReport"
    [reportType]="chartView.barChart"
    [chartName]="'investmentUnitReport'"
    [loading]="investmentUnitReportLoading"
    [title]="'reports.investment_unitreport'"
></app-reports-container>

<!-- <div class="report-list">
  <h4>{{ "reports.unit_grade" | translate }}</h4>
  <app-report-year-dropdown [reportType]="reports.unit_grade"
    (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
  <ng-container *ngIf="unitOccupancyLeaseReport.length > 0">
    <app-report-chart-view [data]="unitOccupancyLeaseReport" [chartType]="chartView.pieChart"></app-report-chart-view>
  </ng-container>
  <div class="noRecord" *ngIf="unitOccupancyLeaseReport.length === 0">
    <span>{{ "reports.no_record_found" | translate }}</span>
  </div>
</div> -->

<app-reports-container
    [dataValue]="unitOccupancyLeaseReport"
    [reportType]="chartView.pieChart"
    [chartName]="'unitOccupancyLeaseReport'"
    [loading]="unitOccupancyLeaseReportLoading"
    [title]="'reports.unit_grade'"
    [yearDropdown]="true"
    [yearReportType]="reports.unit_grade"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<app-reports-container
    [dataValue]="unitOccupancyTypeReport"
    [reportType]="chartView.pieChart"
    [chartName]="'unitOccupancyTypeReport'"
    [loading]="unitOccupancyTypeReportLoading"
    [title]="'reports.unit_type'"
    [yearDropdown]="true"
    [yearReportType]="reports.unit_type"
    (yearSelected)="selectedYear($event)"
></app-reports-container>


<app-reports-container
    [dataValue]="unitFurnishedTypeReport"
    [reportType]="chartView.pieChart"
    [chartName]="'unitFurnishedTypeReport'"
    [loading]="unitFurnishedTypeReportLoading"
    [title]="'reports.unit_furnishedStatus'"
    [yearDropdown]="true"
    [yearReportType]="reports.furnished"
    (yearSelected)="selectedYear($event)"
></app-reports-container>

<div class="report-list">
  <h4>{{'reports.occp_lease_report'| translate}}</h4>

  <div class="filter-year" *ngIf="occupancyAverageData.length > 0">

    <ng-container>
      <span [matMenuTriggerFor]="yearList" class="year-text-many">
        <span>{{ occupancyAverageVal }} </span>
        <mat-icon class="filter-arrow-icon">keyboard_arrow_down</mat-icon>
      </span>

      <mat-menu #yearList="matMenu">
        <ul class="filter-list">
          <li (click)="occupancyAverageFilterChange(val)" *ngFor="let val of occupancyAverageFilter">{{ val }}</li>
        </ul>
      </mat-menu>
    </ng-container>
  </div>

  <ng-container *ngIf="occupancyAverageData.length > 0">

    <app-report-chart-view [legend]="['reports.averageLeaseRate' | translate,'reports.occupancy' | translate]"
      [data]="occupancyAverageData" [chartType]="chartView.barLineGroupChart"></app-report-chart-view>
  </ng-container>
  <div class="noRecord" *ngIf="occupancyAverageData.length === 0">
    <span>{{"reports.no_record_found" | translate}}</span>
  </div>

</div>

<app-reports-container
    [tableColumn]="unitTypeReportTableColumn"
    [dataValue]="unitTypeData"
    [isDownloadExcel]="true"
    [loading]="unitTypeDataLoading"
    [reportName]="'reports.numberOfunits'"
    [title]="'reports.numberOfunits'"
    [expandable]="true"
></app-reports-container>
