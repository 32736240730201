import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ReportPropertyDetailConstant } from './report-property-detail.constant';
import { SharedService } from 'src/app/common/services/shared.service';
import { PropertyDetailMenuEnum } from './report-property-detail.enum';
import { ReportPropertyDetailService } from './report-property-detail.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-report-property-detail',
  templateUrl: './report-property-detail.component.html',
  styleUrls: ['./report-property-detail.component.scss']
})
export class ReportPropertyDetailComponent implements OnChanges, OnDestroy {

  @Input() propertyInfo: any;
  propertyDetail: any = null;

  overViewMenu = ReportPropertyDetailConstant.overViewMenu;
  currentScroll: any = '';

  propertyDetailMenuEnum = PropertyDetailMenuEnum;
  reportName:string='';
  constructor(
    private sharedService: SharedService,
    private reportPropertyDetailService: ReportPropertyDetailService,
    public reportService: ReportService,

  ){}

  ngOnChanges(changes: SimpleChanges): void {
    this.scrollPoint(String(this.propertyDetailMenuEnum.overview));
    
   this.reportPropertyDetailService.propertyInfo = this.propertyInfo;
   this.reportPropertyDetailService.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
   this.reportPropertyDetailService.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
   this.reportPropertyDetailService.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);

  }
  setCount(count:any, heading:number){
    this.overViewMenu.map((e:any)=>{
      if(e.name == heading){
        e['count'] = this.reportService.formatInternationNum(count);
      }
    })
  }
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  backToTransaction() {
    this.reportService.transactionPropertyDetails.next(false);
  }

  ngOnDestroy(): void {
    this.reportPropertyDetailService.propertyInfo = null;
  }

  
}
