import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SpinnerService } from './spinner.service';
import { SharedService } from '../../services/shared.service';
import { LoaderType } from '../../models/enums/common.enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy{
  @Input() loderstate: boolean=false;
  @Input() classname!: string;
  isLoading:boolean = false;
  isReportsLoading:boolean = false;
  loderSub$= new Subject<void>();
  language = 1;

  constructor(
    private spinnerService: SpinnerService,
    private sharedService: SharedService
  ){ }
  ngOnInit() {
    this.isLoading = this.loderstate;
    if(!this.loderstate)this.callLoader();
  }
  callLoader(){
    this.spinnerService.isLoader$.pipe(
      takeUntil(this.loderSub$)
    ).subscribe((res: any) => {
      if(res && res !== LoaderType.skip){
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    })
  }
  ngOnDestroy():void{
    this.loderSub$.next();
    this.loderSub$.complete();
  }
}
