import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { DataType } from "src/app/common/models/enums/common.enum";

export class LandInventoryConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: 0,
            count: null
        }
    ]

    
}
