<div class="report-container retail-rental">

    <ng-container>

        <app-report-sub-menu [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
        <ng-container *ngIf="overViewLoading">
            <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
        </ng-container>
        <div class="report-tabsection" id="{{ warehouseRentalMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === warehouseRentalMenuEnum.overview}">
            <div class="report-overview" *ngIf="!overViewLoading">
                <app-overview-card
                [card_title]="placeCode  == RYDCHECK ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'" 
                [total_current]="overViewSummary?.average_rate.avg_rent"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.average_rate.prev_avg_rent"
                [total_percentage]="overViewSummary?.totalAverage_rate"
                [uniType]="UnitTypeEnum.currencywithsqm"
                [graphData]="{data: overviewAverageRate, type: 'averageRentRate', loader: graphLoader}"></app-overview-card>

                <app-overview-card
                [card_title]="'reports.occupancyRate'" 
                [total_current]="overViewSummary?.occupacy_rate.occupacy_rate"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.occupacy_rate.prev_occupacy_rate"
                [total_percentage]="overViewSummary?.totalOccupacy_rate"
                [uniType]="UnitTypeEnum.percentage"
                [graphData]="{data: overviewOccupancy, type: 'occupancyRate',  loader: graphLoader}"></app-overview-card>

                <!-- <div class="overview">
                    <div class="overview-data">
                        <h2 *ngIf="placeCode  != RYDCHECK">{{"reports.averageRentRate" | translate}}</h2>
                        <h2 *ngIf="placeCode  == RYDCHECK">{{"reports.AnnuallyAverageRent" | translate}}</h2>
                        <h1>
                            <ng-container *ngIf="overViewSummary?.average_rate.avg_rent">
                                {{overViewSummary?.average_rate.avg_rent | number:'1.0-1'}} <sup>{{(CurrencyCode | translate) + '/'+(sqm_unit | translate)}}</sup>
                            </ng-container>
                            <ng-container *ngIf="!overViewSummary?.average_rate.avg_rent">
                                -
                            </ng-container>
                        </h1>
                        <div class="untill">
                            <span class="directionLtr">{{"reports.untill" | translate}}
                             <ng-container *ngIf="overViewSummary?.average_rate.avg_rent">{{overViewSummary?.untillDate}}</ng-container>
                             <ng-container *ngIf="!overViewSummary?.average_rate.avg_rent">-</ng-container>
                            </span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}}  {{overViewSummary?.average_rate.prev_avg_rent ? (overViewSummary?.average_rate.prev_avg_rent | number:'1.0-0') +' ' + (CurrencyCode | translate) + '/'+(sqm_unit | translate) : '-'}}
                            </span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.totalAverage_rate && overViewSummary?.totalAverage_rate == 0">{{
                            overViewSummary?.totalAverage_rate | number:'1.0-0'}} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.totalAverage_rate && overViewSummary?.totalAverage_rate > 0">+ {{
                                overViewSummary?.totalAverage_rate | number:'1.0-0'}} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.totalAverage_rate < 0">{{
                                overViewSummary?.totalAverage_rate | number:'1.0-0'}} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.totalAverage_rate > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.totalAverage_rate < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                  
                <div class="horizontal-progress">
                    <ng-container *ngFor="let category of overviewAverageRate; let i=index;">
                        <div class="progress d-flex align-items-center" *ngIf="category[1]['calculation'] != 0">
                            <h5>{{category[0]}}</h5>
                            <section>
                                <div class="bar" [ngStyle]="{'width': (category[1]['calculation'] > 0 ? category[1]['calculation']+'%' : '2%'), 'opacity': getOpacity(i, getNumber(category[1]['category']) , 'averageRentRate')}">
                                </div>
                                <small>{{getNumber(category[1]['category']) | number:'1.0-1'}} {{(CurrencyCode | translate) + '/'+(sqm_unit | translate)}}</small>
                            </section>

                        </div>
                    </ng-container>

                </div>
                </div>
                <div class="overview">
                    <div class="overview-data">
                        <h2>{{'reports.occupancyRate' | translate}}</h2>
                        <h1>
                            <ng-container *ngIf="overViewSummary?.occupacy_rate.occupacy_rate">
                                {{overViewSummary?.occupacy_rate.occupacy_rate | number:'1.0-1'}}  <sup>%</sup>
                            </ng-container>
                            <ng-container *ngIf="!overViewSummary?.occupacy_rate.occupacy_rate">
                                -
                            </ng-container>
                        </h1>

                        <div class="untill">

                            <span class="directionLtr">{{"reports.untill" | translate}}
                                <ng-container *ngIf="overViewSummary?.occupacy_rate.occupacy_rate">
                                    {{overViewSummary?.untillDate}}
                                </ng-container>
                                <ng-container *ngIf="!overViewSummary?.occupacy_rate.occupacy_rate">
                                    -
                                </ng-container>
                            </span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}} {{overViewSummary?.occupacy_rate.prev_occupacy_rate ? (overViewSummary.occupacy_rate.prev_occupacy_rate | number:'1.0-0') +' %' :'-'}}
                            </span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.totalOccupacy_rate && overViewSummary?.totalOccupacy_rate == 0"> {{
                            overViewSummary?.totalOccupacy_rate | number:'1.0-0'}} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.totalOccupacy_rate && overViewSummary?.totalOccupacy_rate > 0">+ {{
                                overViewSummary?.totalOccupacy_rate | number:'1.0-0'}} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.totalOccupacy_rate < 0">{{
                                overViewSummary?.totalOccupacy_rate | number:'1.0-0'}} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.totalOccupacy_rate > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.totalOccupacy_rate < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>

                     <div class="horizontal-progress">
                        <ng-container *ngFor="let category of overviewOccupancy; let i=index;">
                            <div class="progress  d-flex align-items-center" *ngIf="category[1]['calculation'] != 0">
                                <h5>{{category[0]}}</h5>
                                <section>
                                    <div class="bar" [ngStyle]="{'width': (category[1]['calculation'] > 0 ? category[1]['calculation']+'%' : '2%'),  'opacity': getOpacity(i, category[1]['category'], 'occupancyRate')}">
                                    </div>
                                    <small>{{category[1]['category'] | number:'1.0-1' }} %</small>
                                </section>

                            </div>
                        </ng-container>


                    </div>
                </div> -->

            </div>
            <div class="report-model report-rentrate" *ngIf="retailRentalRentrateReport">
                <div class="report-model-header">
                    <div class="info">
                        <h3>{{'reports.rental_price_in' | translate}} <span class="text-capitalize">{{(placeName | lowercase) | translate}}</span> <!-- {{'reports.past_years' | translate}} --> ({{CurrencyCode | translate}}/{{'reports.sqm_unit' | translate}})
                        </h3>
                    </div>
                    <button class="btn-select-grade" [matMenuTriggerFor]="menu" *ngIf="!retailRentalChartLoading && (retailRentalChartReport | json) != '{}'">
                        <div class="setext">
                          <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l" style="padding-right: 2px;">,</span></ng-container>
                        </div>
                        <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'" class="grades-menu directionLtr">
                        <div (click)="$event.stopPropagation()">
                          <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                          <ng-container *ngFor="let category of getReportKeys()">
                            <mat-checkbox (change)="selectChart($event, category)" [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                          </ng-container>
                        </div>
                      </mat-menu>



                </div>
                <ng-container *ngIf="retailRentalChartLoading">
                    <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!retailRentalChartLoading">
                    <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                        <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                            <app-report-charts
                            class="grchart"
                            [data]="chart.value"
                            [chartType]="chartView.smallBarChart"
                            [isBorder]="false"
                            [name]="chart.key"
                            [index]="i" [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '300px'}"
                            >
                        </app-report-charts>
                        </ng-container>


                    </div>
                    <ng-template #noDataMessage>
                        <div class="data_unavail">
                            <span>
                                {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                            </span>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>







        <!-- Rental Unit Size -->
        <div class="report-tabsection"
            [ngClass]="{ 'scroll': currentScroll === warehouseRentalMenuEnum.retailUnitSize}"
            id="{{ warehouseRentalMenuEnum.retailUnitSize }}">

            <app-report-year-dropdown [reportType]="'rentalUnit'"
                (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{'reports.rentUnitSizeWise' | translate}}</h3>
                        <!-- <span class="totalTransaction"> ({{retailUnitsizeCount ? reportService.formatInternationNum(retailUnitsizeCount) : retailUnitsizeCount}} {{"reports.totalUnits" | translate}})</span> -->
                    </div>

                </div>
                <!-- <ng-container *ngIf="warehouseUnitsizeLoading">
                    <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
                </ng-container> -->
                <app-report-datatable
                class="rwr-rentunitsize-table"
                 [showLoader]="!warehouseUnitsizeLoading"
                 [tableColumns]="warehouseUnitsizeColumns"
                 [dataLength]="retailUnitsizeCount"
                 [tableData]="warehouseUnitsizedata"
                 (pageEvent)="onPageEvent($event, warehouseRentalMenuEnum.retailUnitSize)"
                 (sort)="sortData($event, warehouseRentalMenuEnum.retailUnitSize)"
                 [allowExcelDownload]="false"
                 (excelDownload)="downloadExcel($event, 'retail_rental_unit_size', 'a')" [downloadLoading]="downloadLoading['a']"
                 [showEstaterText]="true"
                 [expandColumn]="true"
                 [tableExpandColumns]="retailUnitsizeExpandColumns"
                 [dropDownOption]="unitSizeDropdown"
                 (dropDownValue)="dropDownChange($event, warehouseRentalMenuEnum.retailUnitSize)"
                [showTooltip]="true"
                [isWarehouseUnit]="true"
     
             >
             </app-report-datatable>



            </div>
        </div>

         <!-- Properties table -->
         <div class="report-tabsection"
         [ngClass]="{ 'scroll': currentScroll === warehouseRentalMenuEnum.properties}"
         id="{{ warehouseRentalMenuEnum.properties }}">

         <app-report-year-dropdown [reportType]="'unit'"
             (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


         <div class="report-model">

             <div class="report-model-header">
                 <div class="info">
                     <h3>{{'reports.projectsProperties' | translate}}</h3>
                     <span class="totalTransaction">({{retailUnitCount ? reportService.formatInternationNum(retailUnitCount) : retailUnitCount}} {{"reports.total_transaction" | translate}})</span>
                 </div>

             </div>
             <!-- <ng-container *ngIf="retailUnitLoading">
                 <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
             </ng-container> -->
             <app-report-datatable
                 [showLoader]="!retailUnitLoading"
                 [tableColumns]="warehousePropertiesColumns"
                 [dataLength]="retailUnitCount"
                 [tableData]="retailUnitdata"
                 (pageEvent)="onPageEvent($event, warehouseRentalMenuEnum.properties)"
                 (sort)="sortData($event, warehouseRentalMenuEnum.properties)"
                 [allowExcelDownload]="true"
                 (excelDownload)="downloadExcel($event, 'warehouse_rental_property', 'b')" [downloadLoading]="downloadLoading['b']"
                 [showEstaterText]="true" [dropDownOption]="propertiesDropdown" (dropDownValue)="dropDownChange($event, warehouseRentalMenuEnum.properties)"

             >
             </app-report-datatable>


         </div>
     </div>


    </ng-container>

</div>
