import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';

import { ReportService } from 'src/app/reports/services/report.service';
import { Subscription } from 'rxjs';
import { ReportChartColumn } from '../../models/reports.model';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { LandInventoryService } from './land-inventory.service';
import { LandInventoryConstant } from './land-inventory.constants';

@Component({
  selector: 'app-land-inventory',
  templateUrl: './land-inventory.component.html',
  styleUrls: ['./land-inventory.component.scss']
})
export class LandInventoryComponent {

  
  @Input() params: any;
  @Input() multi_story_residential: boolean = false;
  UnitTypeEnum=UnitTypeEnum;
  overViewMenu = LandInventoryConstant.overViewMenu;
  chartView = ReportsChartEnum;

  currentScroll: any = '';

  houseVillaChart: ReportChartColumn[] = [];

  overViewSummary: any = null;
  graphData: any[] = [];


  showPropertyDetails: boolean = false;
  houseVillaChartLoading = false;
  houseVillaOverviewLoading = false;


  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  propertyDetails$ = new Subscription();
  isFirstTime = true;

  constructor(
    private sharedService: SharedService,
    private reportHouseVillasService: LandInventoryService,
    private reportService: ReportService,
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
    this.houseVillaPropertyDetails();
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.propertyInfo = null
      this.showPropertyDetails = false;
      this.getReports();
    }
  }

  getReports() {
    this.getOverviewSummary();
  }

  getOverviewSummary() {
    this.houseVillaOverviewLoading = true;
    this.reportHouseVillasService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.reportHouseVillasService.formatOverViewSummary(res.data)
            // if (this.overViewSummary?.untillDate) this.overViewSummary.untillDate = new Date(this.overViewSummary?.untillDate).getFullYear();
          } else {
            this.overViewSummary = null;
          }
          this.houseVillaOverviewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.houseVillaOverviewLoading = false;
        }
      }
    )
  }
  
  
  houseVillaPropertyDetails() {
    this.propertyDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {
      if (data && data.property_uid) {
        this.showPropertyDetails = true;
      } else {
        this.showPropertyDetails = false;
      }
      if (data) {
        this.propertyInfo = data;
      } else {
        this.propertyInfo = {};
      }
    });
  }

  ngOnDestroy() {
    this.propertyDetails$.unsubscribe()
  }

}
