import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportService } from 'src/app/reports/services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { UnitTypeEnum } from '../../reports-lists/models/unit-type-enum';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/common/services/store.service';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-neigh-area-details',
  templateUrl: './report-neigh-area-details.component.html',
  styleUrls: ['./report-neigh-area-details.component.scss']
})
export class ReportNeighDetailsComponent {
  @Input() propertyInfo: any;
  landInformation: any;
  areaInformation: any;
  chartView = ReportsChartEnum;
  landInformationPieData:any = []
  report_name='';
  queryParams='';
  sqm_unit:string='';
  placeCode:string='';
  allDataLoading = false;
  downloadLoading:boolean = false;
  allowDownload:boolean = false;
  UnitTypeEnum= UnitTypeEnum;
  constructor(
    private reportMapService: ReportMapService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private storeService: StoreService,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
   }

  ngOnInit() {
    this.setDownloadAccess();
      this.report_name = this.reportService.getCurrentReport()?.label;
      this.placeCode = this.storeService.get(GlobalContants.StoreKey.placeCode);
      this.sharedService.language$.subscribe((lang) => {
        if(this.queryParams && this.propertyInfo?.neigh_name){
          this.getLandInformation(this.queryParams);
          this.getAreaInformation(this.queryParams);
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.route.queryParams.subscribe((param: any) => {
      this.queryParams = param;
      this.getLandInformation(param);
      this.getAreaInformation(param);
    })
  }

  getLandInformation(queryParams: any) {
    let param = {...queryParams}
    param['neigh_name'] =  this.propertyInfo?.neigh_name ? JSON.parse(this.propertyInfo?.neigh_name)[1] : null
     if(param['neigh_name']){
      this.allDataLoading = true;
      this.reportMapService.getHousevillaLandInfo(param).subscribe(
        {
          next: (res: any) => {

            this.allDataLoading = false;
            if (res && res.status == 200) {
            this.landInformation = res.data.land_info[0];
            if(this.landInformation?.max_date) this.landInformation.max_date = this.reportService.getDataFormat(this.landInformation?.max_date);
            if(this.landInformation?.occupied && this.landInformation?.vacant){
              let temp = [];
              temp.push({
                category: this.translate.instant('Developed'),
                value: this.landInformation?.occupied
              })
              temp.push({
                category: this.translate.instant('Vacant'),
                value: this.landInformation?.vacant
              })
              this.landInformationPieData = temp;
              }else{
                this.landInformationPieData
              }
            } else {
              this.landInformation = null;
              this.landInformationPieData=[];
            }
          },
          error: (error: any) => {
            this.landInformation = null;
            this.landInformationPieData=[];
            this.allDataLoading = false;
          }
        }
      )
    }else{
      this.landInformation = null;
      this.landInformationPieData=[];
    }
  }
  getAreaInformation(queryParams: any) {
    let param = {...queryParams}
    param['neigh_name'] = this.propertyInfo?.neigh_name ? JSON.parse(this.propertyInfo?.neigh_name)[1] : null
    if(param['neigh_name']){
      this.reportMapService.getHousevillaAreaInfo(param).subscribe(
        {
          next: (res: any) => {
            if (res && res.status == 200) {
              this.areaInformation = res.data[0];
              if(this.areaInformation) this.areaInformation['total_land_area'] = this.reportService.formatInternationNum(this.areaInformation.total_land_area)
            } else {
              this.areaInformation = null;
            }
          },
          error: (error: any) => {
            this.areaInformation = null;
          }
        }
      )
    }else{
      this.areaInformation = null;
    }

  }

  backToTransaction() {
    this.reportService.isDetailPageOpen = false;
    this.reportService.transactionPropertyDetails.next(false);
    this.reportService.projectReportView = false;
  }

  directionInfo(value: any) {
    let sum = 0;
    for (const property in value) {
      sum += +value[property]?.toFixed(2);
    }
    return sum?.toFixed(2);
  }

  downloadVacantDevelopedNeighReport(type: string, neigh_name:string) {
    if (!this.allowDownload) {
      this.alertService.warn(this.translate.instant("reports.disableDownloadAccessMsg"));
      return;
    }
    this.downloadLoading = true;
    this.reportService.getReportDownloadHouseVilla(this.queryParams, type, neigh_name).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.downloadLoading = false;
            this.reportService.downloadExcelUrl(res.data, this.placeCode);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));
          } else {
            this.downloadLoading = false;
            this.alertService.warn(res.message)
          }
        }
      }
    )
  }

  setDownloadAccess() {
    let currentReportData = this.reportService.getCurrentReport();
    let subscription = this.reportService.userSubscription.find((sub: any) => sub.report_id === currentReportData?.id);
    if (subscription) {
      this.allowDownload = subscription.allow_download && currentReportData?.is_download;
    }
  }
}
