import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RetailPropertyDetailConstant } from '../../retail-property-detail/retail-property-detail.constant';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportRetailDetailService } from '../../retail-property-detail/retail-property-detail.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { retailsPropertyDetailMenuEnum } from '../../retail-property-detail/retail-property-detail.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Component({
  selector: 'app-unit-rental',
  templateUrl: './unit-rental.component.html',
  styleUrls: ['./unit-rental.component.scss']
})
export class UnitRentalComponent {

    @Input() propertyInfo: any;
    @Output() headerCount = new EventEmitter();

    retailsPropertyDetailMenuEnum = retailsPropertyDetailMenuEnum;
    retailUnitColumns = RetailPropertyDetailConstant.retailUnitColumns;
    retailUnitdata: any;
    retailUnitCount: any;
    retailUnitLoading: boolean=false;
    downloadLoading: boolean=false;

    userInfo:any;
  constructor(private sharedService: SharedService,
    private reportRetailDetailService: ReportRetailDetailService,
    public reportService: ReportService,
    private alertService: AlertService,
    private translate: TranslateService){
      this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
      //Update Column Monthly/Annual
      if (this.sharedService.priceCalculation$() === this.sharedService.priceYearly) {
          this.retailUnitColumns = RetailPropertyDetailConstant.retailUnitColumnsYearly;
      }
  }


  ngOnChanges(): void {
    this.getRetailUnit();
  }

  getRetailUnit() {
    this.retailUnitLoading = true;
    this.reportRetailDetailService.getRetailUnit(this.reportRetailDetailService.retailUnitPayload, 'retail', this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.retailUnitdata = res.data;
            if (this.reportRetailDetailService.retailUnitPayload.pageNumber == 0) {
              this.retailUnitCount = res.count;
              this.headerCount.emit(this.retailUnitCount)
            }

          } else {
            this.retailUnitdata = [];
            this.retailUnitCount = 0;
            this.headerCount.emit(0)
          }
          this.retailUnitLoading = false;
        },
        error: (error: any) => {
          this.retailUnitdata = [];
          this.retailUnitCount = 0;
          this.retailUnitLoading = false;
          this.headerCount.emit(0)
        }
      }
    )
  }


  downloadExcel(event: any, type: string) {
    this.downloadRentalReport(type);
  }
  downloadRentalReport(type: string) {
    this.downloadLoading   = true;
    this.reportService.downloadReport(type, this.userInfo?.user_id, 'retail',  this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.reportRetailDetailService.placeCode.toLowerCase()}&module_name=retail_rental`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading  = false;
        }
      }
    )
  }

  onPageEvent(e: any) {
    if (e.previousPageIndex !== e.pageIndex) {
        this.reportRetailDetailService.retailUnitPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getRetailUnit();

      }

  }

  sortData(event: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
      this.reportRetailDetailService.retailUnitPayload.pageNumber = 0;
      this.reportRetailDetailService.retailUnitPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRetailUnit();
  }
}
