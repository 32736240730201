<div class="report-container inventory" [class.display-none]="showPropertyDetails">
        <app-report-sub-menu *ngIf="!showPropertyDetails"
            [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"
            [activeTab]="activeTab"
            [activeTabName]="activeTabName"
        ></app-report-sub-menu>
        <ng-container *ngIf="overViewLoading">
            <app-skeleton-loader [reportType]="chartView.overView" [height]="'150px'"></app-skeleton-loader>
        </ng-container>

        <div class="report-tabsection" id="{{ inventoryMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === inventoryMenuEnum.overview}">
            <div class="report-overview" *ngIf="!overViewLoading && overViewSummary">
                <app-overview-card
                    [card_title]="'reports.totalProperties'"
                    [untillDate]="overViewSummary.untillDate"
                    [total_prev]="overViewSummary.prev_total_properties"
                    [total_current]="overViewSummary.total_properties"
                    [total_percentage]="overViewSummary.totalpropertiesVal"
                ></app-overview-card>

                <app-overview-card
                    [card_title]="'reports.totalResidentialUnits'"
                    [untillDate]="overViewSummary.untillDate"
                    [total_prev]="overViewSummary.prev_residential_units"
                    [total_current]="overViewSummary.residential_units"
                    [total_percentage]="overViewSummary.totalresidential"
                ></app-overview-card>

                <app-overview-card
                    [card_title]="'reports.totalCommercialUnits'"
                    [untillDate]="overViewSummary.untillDate"
                    [total_prev]="overViewSummary.prev_commercial_units"
                    [total_current]="overViewSummary.commercial_units"
                    [total_percentage]="overViewSummary.totalcommercial"
                ></app-overview-card>
            </div>
        </div>
        
        <div class="report-model" *ngIf="inventoryChart.length > 0">
            <div class="report-model-header">
                <div class="info">
                    <h3> {{'reports.yearlyCoverageInvestment' | translate}} {{inventoryChart.length}} {{'reports.years' | translate}}  </h3>
                </div>

            </div>
            <ng-container *ngIf="inventoryChartLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!inventoryChartLoading">
                <app-report-charts [data]="inventoryChart" [chartType]="chartView.varianceIndicatorsChart"
                    [name]="'inventoryChart'"></app-report-charts>

            </ng-container>




        </div>
        
        <div class="report-tabsection"
            [ngClass]="{ 'scroll': currentScroll === inventoryMenuEnum.propertiesByNeighborhood}"
            id="{{ inventoryMenuEnum.propertiesByNeighborhood }}">

            <app-report-year-dropdown [reportType]="'propertiesByNeighborhood'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>

            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{'reports.Properties_by_neighborhood_expands_into_blocks' | translate}}</h3>
                        <span class="totalTransaction">({{ propertiesByNeighborhoodCount ? reportService.formatInternationNum(propertiesByNeighborhoodCount) : propertiesByNeighborhoodCount}})</span>

                    </div>

                </div>
               
                <app-report-datatable [ngClass]="{'activeTable': tableName == inventoryMenuEnum.propertiesByNeighborhood}"
                (lastClickedTable)="lastClickedTable($event, inventoryMenuEnum.propertiesByNeighborhood)"
                class="rii-rentunitsize-table"
                 [showLoader]="!propertiesByNeighborhoodLoading"
                 [tableColumns]="propertiesByNeighborhoodColumns"
                 [dataLength]="propertiesByNeighborhoodCount"
                 [tableData]="propertiesByNeighborhoodData"
                 (pageEvent)="onPageEvent($event, inventoryMenuEnum.propertiesByNeighborhood)"
                 (sort)="sortData($event, inventoryMenuEnum.propertiesByNeighborhood)"
                 [allowExcelDownload]="true"
                 (excelDownload)="downloadExcel($event, 'ir_inventory_neighwise_property', 'a')"
                 [expandColumn]="true"
                 [tableExpandColumns]="propertiesByNeighborhoodExpandColumns" [downloadLoading]="downloadLoading['a']"
                
             >
             </app-report-datatable>



            </div>
        </div>


         <!-- Average Rent Unit Wise -->
         <div class="report-tabsection"
         [ngClass]="{ 'scroll': currentScroll === inventoryMenuEnum.projectsAndProperties}"
         id="{{ inventoryMenuEnum.projectsAndProperties }}">

         <app-report-year-dropdown [reportType]="'rental'"
             (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


         <div class="report-model">

             <div class="report-model-header">
                 <div class="info">
                     <h3>{{'reports.projectsProperties' | translate}}</h3>
                     <span class="totalTransaction">({{ projectsAndPropertiesReportCount ? reportService.formatInternationNum(projectsAndPropertiesReportCount) : projectsAndPropertiesReportCount}})</span>
                 </div>

             </div>
           
             <app-report-datatable [ngClass]="{'activeTable': tableName == inventoryMenuEnum.projectsAndProperties}"
                (lastClickedTable)="lastClickedTable($event, inventoryMenuEnum.projectsAndProperties)"
                 [showLoader]="!projectsAndPropertiesReportLoading"
                 [tableColumns]="projectsAndPropertiesColumns"
                 [dataLength]="projectsAndPropertiesReportCount"
                 [tableData]="projectsAndPropertiesReportdata"
                 (pageEvent)="onPageEvent($event, inventoryMenuEnum.projectsAndProperties)"
                 (sort)="sortData($event, inventoryMenuEnum.projectsAndProperties)"
                 [allowExcelDownload]="true"
                 (excelDownload)="downloadExcel($event, 'ir_inventory_property', 'b')" [downloadLoading]="downloadLoading['b']"
                 
                 
             >
             </app-report-datatable>


         </div>
        </div>

        <div class="report-tabsection"
        [ngClass]="{ 'scroll': currentScroll === inventoryMenuEnum.project}"
        id="{{ inventoryMenuEnum.project }}">

        <app-report-year-dropdown [reportType]="'project'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.project' | translate}}</h3>
                    <span class="totalTransaction">({{ projectsReportCount ? reportService.formatInternationNum(projectsReportCount) : projectsReportCount}})</span>
                </div>

            </div>
          
            <app-report-datatable [ngClass]="{'activeTable': tableName == inventoryMenuEnum.project}"
            (lastClickedTable)="lastClickedTable($event, inventoryMenuEnum.project)"
                [showLoader]="!projectsReportLoading"
                [tableColumns]="projectColumns"
                [dataLength]="projectsReportCount"
                [tableData]="projectsReportdata"
                (pageEvent)="onPageEvent($event, inventoryMenuEnum.project)"
                (sort)="sortData($event, inventoryMenuEnum.project)"
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'ir_inventory_project', 'c')" [downloadLoading]="downloadLoading['c']"
                
                
            >
            </app-report-datatable>


        </div>
       </div>
</div>

<!-- <ng-container *ngIf="showPropertyDetails">
    <ng-container *ngIf="multi_story_residential; else elseTemplate">
        <app-report-property-detail [propertyInfo]="propertyInfo"></app-report-property-detail>
    </ng-container>
    <ng-template #elseTemplate>
        <app-report-investment-inventory-details  [propertyInfo]="propertyInfo"></app-report-investment-inventory-details>
    </ng-template>
</ng-container> -->

