import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';

import { I18nModule } from '../i18n/i18n.module';
import { SharedModule } from '../common/shared.module';
import { ReportsRoutingModule } from './reports-routing.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';

import { ReportsComponent } from './components/reports/reports.component';
import { ReportsMapViewComponent } from './components/reports-map-view/reports-map-view.component';
import { ReportDataComponent } from './components/report-data/report-data.component';
import { ReportGridComponent } from './components/report-grid/report-grid.component';
import { ReportLayoutComponent } from './components/report-layout/report-layout.component';
import { ReportBreadcumComponent } from './components/report-breadcum/report-breadcum.component';
import { ReportQueryComponent } from './components/report-query/report-query.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { ReportMapLegendComponent } from './components/report-map-legend/report-map-legend.component';
import { ReportsCategoryComponent } from './components/reports-category/reports-category.component';
import { ReportUnitComponent } from './components/reports-lists/components/report-unit/report-unit.component';
import { ReportInventoryComponent } from './components/reports-lists/components/report-inventory/report-inventory.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportsPreviewModule } from './components/reports-preview/reports-preview.module';
import { RecentSavedReportComponent } from './components/recent-saved-report/recent-saved-report.component';
import { ReportOccupancyLeaseComponent } from './components/reports-lists/components/report-occupancy-lease/report-occupancy-lease.component';
import { ReportPopulationComponent } from './components/reports-lists/components/report-population/report-population.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SaveReportDailogComponent } from './components/save-report-dailog/save-report-dailog.component';
import { ReportUnderConstructionComponent } from './components/reports-lists/components/report-under-construction/report-under-construction.component';
import { ReportPropertyProfilingComponent } from './components/reports-lists/components/report-property-profiling/report-property-profiling.component';
import { ReportMallProfilingComponent } from './components/reports-lists/components/report-mall-profiling/report-mall-profiling.component';
import { ReportMallInventoryComponent } from './components/reports-lists/components/report-mall-inventory/report-mall-inventory.component';
import { ReportFbComponent } from './components/reports-lists/components/report-fb/report-fb.component';
import { ReportTenantProfilingComponent } from './components/reports-lists/components/report-tenant-profiling/report-tenant-profiling.component';
import { MinMaxSliderComponent } from './components/filter-components/min-max-slider/min-max-slider.component';
import { MultiSelectComponent } from './components/filter-components/multi-select/multi-select.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { FormTextComponent } from './components/filter-components/form-text/form-text.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReportTransactionsComponent } from './components/reports-lists/components/report-transactions/report-transactions.component';
import { ReportTransactionsPropertyDetailsComponent } from './components/reports-lists/components/report-transactions/report-transactions-property-details/report-transactions-property-details.component';
import { DateRangeComponent } from './components/filter-components/date-range/date-range.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReportHouseVillasComponent } from './components/reports-lists/components/report-house-villas/report-house-villas.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { ReportHousevillaPropertyDetailsComponent } from './components/reports-lists/components/report-house-villas/report-housevilla-property-details/report-housevilla-property-details.component';
import { ReportsContainerComponent } from './components/reports-lists/components/reports-container/reports-container.component';
import { MapPropertyDetailsComponent } from './components/reports-map-view/map-property-details/map-property-details.component';
import { ReportHouseRentalComponent } from './components/reports-lists/components/report-house-rental/report-house-rental.component';
import { ReportHouseLandVacancyComponent } from './components/reports-lists/components/report-house-land-vacancy/report-house-land-vacancy.component';
import { ReportHouseLandVacancyDetailsComponent } from './components/reports-lists/components/report-house-land-vacancy/report-house-land-vacancy-details/report-house-land-vacancy-details.component';
import { ReportFilterGroupComponent } from './components/report-filter-group/report-filter-group.component';
import { ReportOfficeSpaceRentalComponent } from './components/reports-lists/components/report-office-space-rental/report-office-space-rental.component';
import { ReportRetailRentalComponent } from './components/reports-lists/components/report-retail-rental/report-retail-rental.component';
import { ReportNeighDetailsComponent } from './components/reports-map-view/report-neigh-area-details/report-neigh-area-details.component';
import { ReportWarehouseRentalComponent } from './components/reports-lists/components/report-warehouse-rental/report-warehouse-rental.component';
import { OverviewCardComponent } from './components/reports-lists/components/common/overview-card/overview-card.component';
import { ReportHousevillaProjectDetailsComponent } from './components/reports-map-view/report-housevilla-project-details/report-housevilla-project-details.component';
import { ReportInvestmentInventoryComponent } from './components/reports-lists/components/report-investment-inventory/report-investment-inventory.component';
import { ReportInventoryProjectDetailsComponent } from './components/reports-map-view/report-inventory-project-details/report-inventory-project-details.component';
import { ReportOccupancyRentalComponent } from './components/reports-lists/components/report-occupancy-rental/report-occupancy-rental.component';
import { OccupancyRentalPropertyDetailComponent } from './components/reports-lists/components/report-occupancy-rental/occupancy-rental-property-detail/occupancy-rental-property-detail.component';
import { ReportCommonInfoModule } from './components/reports-lists/components/common/report-common-info.module';
import { ReportRetailInventoryComponent } from './components/reports-lists/components/report-retail-inventory/report-retail-inventory.component';
import { ReportInvestmentInventoryDetailsComponent } from './components/reports-lists/components/report-investment-inventory/report-investment-inventory-details/report-investment-inventory-details.component';
import { ReportOfficeInventoryComponent } from './components/reports-lists/components/report-office-inventory/report-office-inventory.component';
import { ReportWarehouseInventoryComponent } from './components/reports-lists/components/report-warehouse-inventory/report-warehouse-inventory.component';
import { ReportsTenantsMapViewComponent } from './components/reports-tenants-map-view/reports-tenants-map-view.component';
import { LoadCatchmentComponent } from './components/reports-map-view/load-catchment/load-catchment.component';
import { DrawCatchmentComponent } from './components/reports-map-view/draw-catchment/draw-catchment.component';
import { PocContainerComponent } from './components/poc-container/poc-container.component';
import { PieChartComponent } from './components/poc-container/components/pie-chart/pie-chart.component';
import { LineChartComponent } from './components/poc-container/components/line-chart/line-chart.component';
import { TableComponent } from './components/poc-container/components/table/table.component';
import { MainChartComponent } from './components/poc-container/components/main-chart/main-chart.component';
import { CatchmentModelComponent } from './components/reports-map-view/draw-catchment/catchment-model/catchment-model.component';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ReportPowerBiFullComponent } from './components/reports-lists/components/report-power-bi-full/report-power-bi-full.component';
@NgModule({
  declarations: [
    ReportsComponent,
    ReportsMapViewComponent,
    ReportDataComponent,
    ReportGridComponent,
    ReportLayoutComponent,
    ReportBreadcumComponent,
    ReportQueryComponent,
    ReportFilterComponent,
    ReportMapLegendComponent,
    ReportsCategoryComponent,
    ReportUnitComponent,
    ReportInventoryComponent,
    RecentSavedReportComponent,
    ReportOccupancyLeaseComponent,
    ReportPopulationComponent,
    SaveReportDailogComponent,
    ReportUnderConstructionComponent,
    ReportPropertyProfilingComponent,
    ReportMallProfilingComponent,
    ReportMallInventoryComponent,
    ReportFbComponent,
    ReportTenantProfilingComponent,
    MinMaxSliderComponent,
    MultiSelectComponent,
    FormTextComponent,
    ReportTransactionsComponent,
    ReportTransactionsPropertyDetailsComponent,
    DateRangeComponent,
    ReportHouseVillasComponent,
    ReportHousevillaPropertyDetailsComponent,
    ReportHousevillaProjectDetailsComponent,
    ReportsContainerComponent,
    MapPropertyDetailsComponent,
    ReportHouseRentalComponent,
    ReportHouseLandVacancyComponent,
    ReportHouseLandVacancyDetailsComponent,
    ReportFilterGroupComponent,
    ReportOfficeSpaceRentalComponent,
    ReportRetailRentalComponent,
    ReportNeighDetailsComponent,
    ReportWarehouseRentalComponent,
    OverviewCardComponent,
    ReportInvestmentInventoryComponent,
    ReportInventoryProjectDetailsComponent,
    ReportInvestmentInventoryDetailsComponent,
    ReportOccupancyRentalComponent,
    OccupancyRentalPropertyDetailComponent,
    ReportRetailInventoryComponent,
    ReportOfficeInventoryComponent,
    ReportWarehouseInventoryComponent,
    ReportsTenantsMapViewComponent,
    LoadCatchmentComponent,
    DrawCatchmentComponent,
    PocContainerComponent,
    PieChartComponent,
    LineChartComponent,
    TableComponent,
    MainChartComponent,
    CatchmentModelComponent,
    ReportPowerBiFullComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedModule,
    ReportsRoutingModule,
    MatIconModule,
    HttpClientModule,
    MatExpansionModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    ReportsPreviewModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSelectModule,
    MatSliderModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReportCommonInfoModule,
    MatProgressBarModule
  ],
  exports: [ReportsComponent,
    RecentSavedReportComponent,
    MinMaxSliderComponent,
    MultiSelectComponent,
    FormTextComponent,
    ReportMapLegendComponent,
    ReportsMapViewComponent,
    ReportNeighDetailsComponent,
    OverviewCardComponent,
    ReportHousevillaProjectDetailsComponent
  ],
  providers:[{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})

export class ReportsModule { }
