import { StoreService } from 'src/app/common/services/store.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, SimpleChange, ViewChild } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { ReportMapService } from '../../services/report-map.service';
import { ReportService } from '../../services/report.service';
import { ReportsViewEnum } from '../../models/enums/reports.enum';
import { MatRegisterIconService } from 'src/app/common/services/mat-register-icon.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SaveFilterComponent } from 'src/app/common/components/save-filter/save-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SaveAsFilterComponent } from 'src/app/common/components/save-as-filter/save-as-filter.component';
@Component({
  selector: 'app-report-breadcum',
  templateUrl: './report-breadcum.component.html',
  styleUrls: ['./report-breadcum.component.css'],
})
export class ReportBreadcumComponent implements OnInit, OnDestroy {

  @Input() filterList: any;
  selectReportSub$: Subscription = new Subscription();
  reportViewSub$: Subscription = new Subscription();
  isReport: boolean = false;
  currentReportView: number = ReportsViewEnum.mapView;
  viewType = ReportsViewEnum;
  selectedObjective: any = [];
  selectedStudies: any = [];
  selectedRepored: any = [];
  polygon_create: boolean = false;
  filterData: any = [];
  activeRoute: Subscription = new Subscription();
  queryParams: any;
  queryParamsFirst: any;
  user_info!: any;
  filterDropdownData: any = [];
  Params: any = {};
  public drawPolySub$: Subscription = new Subscription();
  languageSub$: Subscription = new Subscription();
  currentLang: string = '';
  countOfFilter: number = 0;
  langNbr: any;
  savedFilters: any = [];
  // appliedFilter = '';
  showSaveFilterOption: boolean = false;
  filterSearchTerm: string = '';
  reports: any;
  unSubsZoom$: Subscription = new Subscription();
  reportFilterForm: FormGroup | any;
  breadcumWidth: any;
  isFirstLoad = true;
  isResetBtn = false;
  isSavedFilter = false;
  // selectedSaveFilter: any ={};
  // filter_name='';
  constructor(
    private mapService: ReportMapService,
    public reportService: ReportService,
    @Inject(PLATFORM_ID) private platformId: any,
    private matregis: MatRegisterIconService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    public storeService: StoreService,
    private dailog: MatDialog,
    private fb: FormBuilder,
    private alertService: AlertService,
  ) {

  }
  ngOnInit() {
    this.user_info = this.sharedService.getUserInfo();
    this.setLang();
    // this.reportService.getSavedFilter();
    this.reportSet();
    this.reportView();
    this.getAppliedFilter();
    this.reports = this.reportService.savedFilters;
    let savefilter: any = this.storeService.getSessionData('filter');
    if (savefilter) {
      this.queryParamsFirst =  {
        "oid": this.queryParams['oid'],
      "gid": this.queryParams['gid'],
      "rid": this.queryParams['rid'],
      ...savefilter.data.filter}
    }
    this.sharedService.selectedCity$.subscribe(city => {
      if(this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId)){
        this.reportService.getSavedFilter();
      }
    });
  }
  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
      if (lang) { this.langNbr = this.sharedService.getCurrentLangID() }
    })
  }
  getSaveFilter() {
    let savefilter: any = this.storeService.getSessionData('filter');
    if (savefilter) {
      // this.selectedSaveFilter = savefilter?.data?.filter;
      return savefilter?.name || '';
    }
    return '';
  }
  getAppliedFilter() {
    this.activeRoute = this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      this.setQueryParameters(this.queryParams);
      let currentParams = {...params};
      let savefilter: any = this.storeService.getSessionData('filter');
      if(this.storeService.getSessionData("queryParamsFirst")) {
        this.queryParamsFirst = this.storeService.getSessionData("queryParamsFirst");
      }
      if(savefilter){
        if(!this.sharedService.compareObject(this.queryParamsFirst, currentParams)) {
          this.isResetBtn = true;
        }else {
          this.isResetBtn = false;
        }
        if(this.queryParamsFirst && ((this.queryParamsFirst['rid'] !== params['rid']) || (this.queryParamsFirst['srid'] !== params['srid']))) {
          this.isResetBtn = false
        }
      }else{
        this.isResetBtn = false;
      }

    })
  }

  ngOnChanges(changes: any) {
    this.getAppliedFilter();
    let dropdowns = changes?.filterList.currentValue;
    if (dropdowns.length > 0) {
      this.filterDropdownData = dropdowns;
    }


  }

  getLabel(key: string, value: string) {
    let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
    if (filter && filter.value_list?.length) {
      let f_value_obj = filter.value_list.find((e: any) => e.val == value);
      if (f_value_obj) {
        return f_value_obj.label[this.langNbr] ? f_value_obj.label[this.langNbr] : f_value_obj.label['1']
      }
    }
    return value//this.sharedService.removeUnderscores(value);
  }
  /**
   *
   * @param params contains the object of all the query parameters of current route.
   * @returns
   */
  setQueryParameters(params: any) {

    const queryParamsArray: any = [];
    this.countOfFilter = 0;
    for (let key in params) {
      if (['property_category', 'unit_category', 'zoom', 'centroid', 'Zoom', 'Centroid', 'rid', 'srid', 'gid', 'oid'].includes(key)) {
        this.Params[key] = params[key];
        continue;
      }
      this.countOfFilter++;
      let paramsValue = params[key].split(',');
      if (key !== 'polygon') {
        paramsValue.forEach((value: any) => {
          queryParamsArray.push({ filter: key, value: value, label: this.getLabel(key, value) });
        });
      }
    }
    if(params.hasOwnProperty('polygon') && params.hasOwnProperty('neigh_name')){
      this.countOfFilter--;
    }
    this.filterData = queryParamsArray;
    // this.drawPolySub$ = this.mapService.drawPolyToggle.subscribe((toggle) => { // When polygon removed then to remove from breadcrum also
    //   if (!toggle) {
    //     if (this.Params['polygon']) delete this.Params['polygon']
    //   }
    // })
    // this.filterData = queryParamsArray;

    // this.countOfFilter = 0;
    // for (let i = 0; i < this.filterData.length; i++) {
    //   if (this.Params['polygon'] && this.filterData[i].filter === 'neigh_name') {
    //     continue;
    //   }
    //   this.countOfFilter++;
    // }
    // return queryParamsArray;
  }

  // removeFilter(index: number) {
  //   this.sharedService.clearSession('filter');
  //   this.filterData.splice(index, 1); // Remove filter from filterData array
  //   const queryParams: Params = {};
  //   this.filterData.forEach((filter: any) => {
  //     if (!queryParams[filter.filter]) {
  //       queryParams[filter.filter] = filter.value;
  //     } else {
  //       queryParams[filter.filter] += ',' + filter.value;
  //     }
  //   });

  //   let mergerparam = { ...queryParams, ...this.Params }
  //   //Getting Updated zoom from map
  //   this.unSubsZoom$ = this.mapService.zoom.subscribe(res => {
  //     mergerparam['zoom'] = res;
  //   })
  //   //Getting Updated centroid from map
  //   if (this.mapService.getCentroid()) mergerparam['centroid'] = this.mapService.getCentroid();

  //   this.router.navigate([`${this.sharedService.cityName}-${this.sharedService.LangName}/${GlobalContants.Routes.Reports}`], {
  //     queryParams: mergerparam,
  //   });
  //   this.mapService.reportFiltertoggle.next(true);
  //   if (mergerparam['property_category']) {
  //     delete mergerparam['property_category'];
  //   }
  //   if (mergerparam['unit_category']) {
  //     delete mergerparam['unit_category'];
  //   }
  //   if (mergerparam['quarter']) {
  //     let quarter = JSON.parse(mergerparam['quarter'])
  //     mergerparam['quarter'] = quarter;
  //   }
  //   this.setQueryParameters(mergerparam);
  //   Object.keys(mergerparam).forEach(value => {
  //     if (Array.isArray(mergerparam[value])) {
  //       mergerparam[value] = mergerparam[value].join(',');
  //     }
  //   })
  //   delete mergerparam['srid'];
  //   let body = {
  //     report_id: this.queryParams['rid'],
  //     user_id: this.user_info?.user_id,
  //     filter_data: mergerparam,
  //     added_by: this.user_info.user_id
  //   }
  //   this.mapService.reportFilter.next(mergerparam);
  //   this.reportService.setUserPreference(body).subscribe((res: any) => { });
  // }


  reportSet() {
    this.selectReportSub$ = this.reportService.selectedReport$.subscribe(isReport => {
      if (isReport.flag) {
        this.selectedObjective = this.reportService.selectedObjective;
        this.selectedStudies = this.reportService.selectedStudies;
        this.selectedRepored = this.reportService.selectedRepored;
        /**get breadcums-container dynamic width */
        setTimeout(() => {
          let a: any = document.querySelector('.breadcums-container');
          const windowWidth = a.getBoundingClientRect().width;
          if (windowWidth > 0) {
            this.breadcumWidth = `calc(100% - ${windowWidth}px)`
          }
        }, 1)
      }

      this.isReport = isReport.flag;
    });
  }

  reportView() {
    this.reportViewSub$ = this.reportService.reportView$.subscribe(view => {
      this.currentReportView = view;
    })
  }

  layerToggle() {
    this.mapService.mapFiltertoggle.next(false);
    this.mapService.mapLayertoggle.next(true);
  }
  filterToggle() {
    this.mapService.mapLayertoggle.next(false);
    this.mapService.mapFiltertoggle.next(true);
  }
  /**
   * This function is used to apply the saved filter in report
   * @param savedFilter store the data related to saved filter which we want to apply.
   */
  applySavedFilter(savedFilter: any) {
    this.storeService.addSessionData("filter", savedFilter)

    let savedFilterData = savedFilter.data.filter;
    let queryParams: any = {}
    this.reportService.reportFilter.forEach((ele: any) => {
      if (savedFilterData.hasOwnProperty(ele.parameter_name))
        queryParams[ele.parameter_name] = savedFilterData[ele.parameter_name]
    });
    if (savedFilterData['centroid'])
      queryParams['centroid'] = savedFilterData['centroid'];
    if (savedFilterData['zoom'])
      queryParams['zoom'] = savedFilterData['zoom'];
    if (this.queryParams['srid'])
      queryParams['srid'] = savedFilterData['srid'];
    if (savedFilter.data['catchment']) {
      queryParams['polygon'] = JSON.stringify(savedFilter.data['catchment'])
    }
    queryParams = {
      "oid": this.queryParams['oid'],
      "gid": this.queryParams['gid'],
      "rid": this.queryParams['rid'],
      ...queryParams
    }
    this.queryParamsFirst = queryParams
    this.storeService.addSessionData("queryParamsFirst", this.queryParamsFirst)
    // this.selectedSaveFilter = queryParams;
    this.router.navigate(
      [`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`],
      { queryParams: queryParams }
    );
    this.isSavedFilter = true;
  }
  /**
   * This function is used to save or update filter
   * @param event : element
   * @param dialogType differenciate which operation to perform. (i.e: save or update )
   * @returns
   */
  savedFilterName(event: any, dialogType: string = "") {
    if(this.reportService.getLockReport()){
      return;
    }
    if(event){
      event?.stopPropagation();
    }
   
    if (this.countOfFilter == 0) return;
    let qParams = { ...this.queryParams };
    this.unSubsZoom$ = this.mapService.zoom.subscribe(res => {
      qParams['zoom'] = res;
    })
    if (this.mapService.getCentroid()) qParams['centroid'] = this.mapService.getCentroid();
    let _data = {
      filterData: qParams,
      filterDropdownData: this.filterDropdownData
    };
    if (dialogType) {
      this.dailog.open(SaveAsFilterComponent, {
       
        data: _data
      }).afterClosed().subscribe(res => {
        if (res === 'save') {
          this.dailog.open(SaveFilterComponent, {
           
            data: _data
          }).afterClosed().subscribe(res => { })
        } else if(res === 'update') {
            this.queryParamsFirst = this.queryParams;
            this.isResetBtn = false;
        }
      })
    } else {
      this.dailog.open(SaveFilterComponent, {
       
        data: _data
      }).afterClosed().subscribe(res => { })
    }

  }
  searchReport(event: any) {
    this.filterSearchTerm = event;
    this.reportService.getSavedFilter(this.filterSearchTerm);
  }

  resetFilter(event: any) {
    event.stopPropagation();
    this.router.navigate([`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`], {
      queryParams: this.queryParamsFirst
    });
    // this.isResetBtn = false;
    // setTimeout(() => this.isResetBtn = false);
  }

  /**
   * This is a function to check deff applied filter and with normal filter
   */
  // isChangedFilter(){

  //   let queryParams:any={}
  //   this.activeRoute = this.activatedRoute.queryParams.subscribe(params => {
  //     queryParams = {...params};
  //   })
  //   if(this.selectedSaveFilter && queryParams){

  //     if(this.selectedSaveFilter['oid']) delete this.selectedSaveFilter['oid'];
  //     if(this.selectedSaveFilter['gid']) delete this.selectedSaveFilter['gid'];
  //     if(this.selectedSaveFilter['rid']) delete this.selectedSaveFilter['rid'];
  //     if(this.selectedSaveFilter['centroid']) delete this.selectedSaveFilter['centroid'];
  //     if(this.selectedSaveFilter['zoom']) delete this.selectedSaveFilter['zoom'];

  //     if(queryParams['oid']) delete queryParams['oid'];
  //     if(queryParams['gid']) delete queryParams['gid'];
  //     if(queryParams['rid']) delete queryParams['rid'];
  //     if(queryParams['centroid']) delete queryParams['centroid'];
  //     if(queryParams['zoom']) delete queryParams['zoom'];

  //     if(this.sharedService.compareObject(this.selectedSaveFilter,queryParams)){
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  ngOnDestroy(): void {
    this.selectReportSub$?.unsubscribe();
    this.reportViewSub$?.unsubscribe();
    this.activeRoute?.unsubscribe();
    this.drawPolySub$?.unsubscribe();
    this.languageSub$.unsubscribe();
    this.unSubsZoom$.unsubscribe();
  }
}
