import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportHouseLandVacancyService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  landParcelPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null
  }

  getOverviewSummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.houseLandVacancyOverview}${changeParams}`;
    return this.apiServive.get(url, false, LoaderType.report);
  }



  getLandParcelHouseVillas(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.houseLandVacancyList}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    this.landParcelPayload.pageNumber = offset;
    return this.apiServive.get(url, false, LoaderType.report);
  }

  downloadTransactionReport(type: string, user_id: number) {
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  downloadExcelUrl() {
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }



  formatOverViewSummary(data: any) {

    data['land_percentage'] = this.reportService.calcPercentage(data.prev_total_land, data.total_land);
    data['developed_house_percentage'] = this.reportService.calcPercentage(data.prev_developed_house, data.developed_house);
    data['landRate_percentage'] = this.reportService.calcPercentage(data.prev_land_dev_rate, data.land_dev_rate);
    data['untillDate'] = data.max_date ? moment.utc(data.max_date).local().format('DD MMM YYYY') : null;
    return data;
  }

  downloadHouseVillaReport(type:string, user_id:any){
    const url: string = `${environment.miUrl}${apiEndPoints.downloadHouseVillaReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }
}
