import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ReportPropertyDetailConstant } from '../../report-property-detail.constant';
import { ReportPropertyDetailService } from '../../report-property-detail.service';
import moment from 'moment';

@Component({
  selector: 'app-unit-transaction',
  templateUrl: './unit-transaction.component.html',
  styleUrls: ['./unit-transaction.component.scss']
})
export class UnitTransactionComponent implements OnChanges {

  @Input() propertyInfo: any;
  @Output() headerCount = new EventEmitter();
  unitTransactionLoading = false;
  unitTransactionData = [];
  unitTransactionCount = 0;
  unitTransactionPropertyColumns  = ReportPropertyDetailConstant.unitTransactionPropertyColumns;

  constructor(private reportPropertyDetailService: ReportPropertyDetailService){}

  ngOnChanges(): void {
    this.getUnitTransaction();
  }

  getUnitTransaction() {
    this.unitTransactionLoading = true;
    this.reportPropertyDetailService.getUnitTransctionReport(this.reportPropertyDetailService.unitTransactionPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.unitTransactionData = res.data.map((value: any) => {
            value['transaction_date']= value.transaction_date ? moment.utc(value.transaction_date).local().format('DD MMM YYYY') : null;
            return value;
          }) 
            if(this.reportPropertyDetailService.unitTransactionPayload.pageNumber == 0){
            this.unitTransactionCount = res.count;
            this.headerCount.emit(this.unitTransactionCount)
           }
            
          } else {
            this.unitTransactionData = [];
            this.unitTransactionCount = 0;
            this.headerCount.emit(null)
          }
          this.unitTransactionLoading = false;
        },
        error:()=>{
          this.unitTransactionLoading = false;
          this.unitTransactionData = [];
          this.unitTransactionCount = 0;
          this.headerCount.emit(null)
         
        }
      }
    )
  }

  

  onPageEvent(e: any) {
    this.reportPropertyDetailService.unitTransactionPayload.pageNumber = (e.pageSize * e.pageIndex);
    this.getUnitTransaction();
  }

  sortData(event: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    this.reportPropertyDetailService.unitTransactionPayload.pageNumber = 0;
    this.reportPropertyDetailService.unitTransactionPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
    this.getUnitTransaction();
  }

  downloadExcel(event: any, type: string) {
    this.reportPropertyDetailService.downloadRentalReport(type);
  }

 

}
