<app-reports-container
    [tableColumn]="totalColumnReportColumn"
    [dataValue]="totalColumnReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [dataValue]="mallInventoryYearlyUnitsReport"
    [reportType]="chartView.barChart"
    [chartName]="'mallInventoryYearlyUnitsReport'"
    [loading]="mallInventoryYearlyUnitsLoading"
    [title]="'reports.yearlyTrendRetailInventory'"
></app-reports-container>

<app-reports-container
    [dataValue]="mallInventoryYearlyOccupancyReport"
    [reportType]="chartView.normalBarLineChart"
    [chartName]="'mallInventoryYearlyOccupancyReport'"
    [loading]="mallInventoryYearlyOccupancyLoading"
    [title]="'reports.yearlyOccupiedTrend'"
></app-reports-container>

<div class="report-list" *ngIf="mallInventoryYearlyOccupancyReport.length > 0">
    <h4>{{"reports.yearlyOccupiedTrend" | translate}}</h4>
    <app-report-chart-view
        [data]="mallInventoryYearlyOccupancyReport"
        [chartType]="chartView.normalBarLineChart"
    ></app-report-chart-view>
</div>

<app-reports-container
    [dataValue]="mallInventoryYearlyIncrementalReport"
    [reportType]="chartView.barChart"
    [chartName]="'mallInventoryYearlyIncrementalReport'"
    [loading]="mallInventoryYearlyIncrementalLoading"
    [title]="'reports.mallInventory_incremental'"
></app-reports-container>


<app-reports-container
    [tableColumn]="inventoryneighTrendlReportColumn"
    [dataValue]="inventoryneighTrendlReport"
    [loading]="inventoryneighTrendLoading"
    [reportName]="'reports.mallInventory_neighWiseInventory'"
    [title]="'reports.mallInventory_neighWiseInventory'"
    [isDownloadExcel]="true"
    [expandable]="false"
></app-reports-container>


<app-reports-container
    [tableColumn]="neighTrendlReportColumn"
    [dataValue]="neighTrendlReport"
    [loading]="neighTrendlReportLoading"
    [reportName]="'reports.mallInventory_neighWiseOccupancy'"
    [title]="'reports.mallInventory_neighWiseOccupancy'"
    [isDownloadExcel]="true"
    [expandable]="false"
></app-reports-container>

