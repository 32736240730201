import { Component, Input, SimpleChanges } from '@angular/core';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {

  @Input() reportType: number = ReportsChartEnum.tableView;
  @Input() loaderSize: number = 2;
  @Input() gridColumn: number = 0;
  @Input() height: string = '';

  loaderList:number[]=[];
  tableView = ReportsChartEnum.tableView;
  pieChartView = ReportsChartEnum.pieChart;
  overView = ReportsChartEnum.overView;
  horizontalBarChart = ReportsChartEnum.horizontalBarChart;
  barChartView = ReportsChartEnum.barChart;

  constructor(){}

  ngOnChanges(changes: SimpleChanges): void {
    for (let index = 0; index < this.loaderSize; index++) {
      this.loaderList.push(index)      
    }
  }
}
