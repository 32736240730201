import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss']
})
export class TableGridComponent {
  @Input() tableData:any;
  @Input() tableHeading: any;
  @Output() view = new EventEmitter();
  @Output() delete = new EventEmitter();
  @ViewChild('selectAllCheckBox') selectAllCheckBox !: ElementRef;
  reports : string[] = []
  lang!:number;

  constructor(public sharedService: SharedService){
    this.lang = this.sharedService.getCurrentLangID();
  }

  getColspan(): number {
    return this.tableHeading.length > 0 ? this.tableHeading.length : 1;
  }

  deleteReport(data:any){
    this.delete.emit(data);
  }
  viewReport(element:any){
    this.view.emit(element);
  }

  getJSONName(val:any) {
    if (val && val.hasOwnProperty(this.lang)) {
      return val[this.lang];
    } else if (val && val.hasOwnProperty(2)) {
      return val[2];
    } else if (val && val.hasOwnProperty(1)) {
      return val[1];
    }
    return '';
  }
  /**
   * make select all option selected when all report get selected one by one.
   * @returns boolean value
   */
  isCheckboxChecked() {
    return this.sharedService.savedReport?.length > 0 && this.sharedService.savedReport?.length == this.tableData?.length
  }
  /**
   * This function used to collect selected report one by one or all report in case of select all.
   * @param report : unique report id.
   */
  selectReport(report:string = '') {
    if(!report) {
      if(this.selectAllCheckBox?.nativeElement.checked){
        this.tableData.forEach((ele:any) => {
          if (!this.sharedService.savedReport.includes(ele.id)) {
            this.sharedService.savedReport.push(ele.id);
          }
        });
      }else  {
        this.sharedService.savedReport = []
      }
    } else {
      const index = this.sharedService.savedReport.indexOf(report);
      if (index === -1){
        this.sharedService.savedReport.push(report);
      }
      else {
        this.sharedService.savedReport.splice(index, 1);
      }
    }
  }
}
