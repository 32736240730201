import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ReportPropertyDetailService } from '../../report-property-detail.service';

@Component({
  selector: 'app-property-amenities',
  templateUrl: './property-amenities.component.html',
  styleUrls: ['./property-amenities.component.scss']
})
export class PropertyAmenitiesComponent {

  @Input() propertyInfo:any;
  items:any = [];
  @Output() headerCount = new EventEmitter();
  count:any=null;
  // items:any = [
  //   {icon:"assets/images/amenities/gymnasium.png",name:'Gym'}, 
  //   {icon:"assets/images/amenities/deceased.png",name:'Community Garden'}, 
  //   {icon:"assets/images/amenities/desktop.png",name:'Media room'}, 
  //   {icon:"assets/images/amenities/dishwasher_gen.png",name:'On-site laundry facilities'}, 
  //   {icon:"assets/images/amenities/pool.png",name:'Outdoor swimming pool'}, 
  //   {icon:"assets/images/amenities/outdoor_grill.png",name:'Barbecue or outdoor kitchen'}, 
  //   {icon:"assets/images/amenities/ar_stickers.png",name:'Playground'}, 
  //   {icon:"assets/images/amenities/monorail.png",name:'Public transportation access'}, 
  //   {icon:"assets/images/amenities/pool.png",name:'Indoor swimming pool'}, 
  //   {icon:"assets/images/amenities/diversity.png",name:'Community events'}, 
  //   {icon:"assets/images/amenities/sports_cricket.png",name:'Sport courts'}, 
  //   {icon:"assets/images/amenities/warehouse.png",name:'Storage space'},
  //   {icon:"assets/images/amenities/spa.png",name:'Spa'}, 
  //   {icon:"assets/images/amenities/blender.png",name:'Community kitchen'}, 
  //   {icon:"assets/images/amenities/weekend.png",name:'Rooftop lounge'}, 
  //   {icon:"assets/images/amenities/parking.png",name:'Covered parking'}
  // ];
 
  constructor(private reportPropertyDetailService: ReportPropertyDetailService){

  }
  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAmenities();
  }

   /**
   * call the api for getting amenities list
   */
   getAmenities(){
    this.reportPropertyDetailService.getPropertyAmneities(this.propertyInfo.property_uid).subscribe({
      next:(res:any)=>{
        if(res.status == 200 && res.data?.amenities){
          this.items = res.data?.amenities ? res.data?.amenities.split(','): [];
          this.count = this.items.length > 0 ? this.items.length : null;
          this.headerCount.emit(this.count)
        }else{
          this.items= [];
          this.count=null;
          this.headerCount.emit(this.count)
        }
        
      },error:(err)=>{
        this.items= [];
        this.count=null;
        this.headerCount.emit(this.count)
      }
    })
  }

  // getColumns(): any[] {
  //   const columns: any[] = [];
  //   const numberOfColumns = 4;
  //   for (let i = 0; i < this.items.length; i += numberOfColumns) {
  //     columns.push(this.items.slice(i, i + numberOfColumns));
  //   }
  //   return columns;
  // }

}
