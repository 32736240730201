import { Injectable } from '@angular/core';
type GtagFunction = (event: string, lebel?: string, params?: object) => void;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
    public gtag: GtagFunction;

    constructor() {
        // Ensure gtag is defined before using
        this.gtag = (window as any).gtag || function() { console.log('gtag is not defined'); };
        // this.gtag('consent', 'default', {
        //     'analytics_storage': 'granted',  // Default consent state for analytics
        //     'wait_for_update': 500  // Wait for up to 500ms for consent state update
        //   });
      }
    
      public trackEvent(eventName: string, eventParams?: object): void {
        this.gtag('event', eventName, eventParams);
      }
    
}
