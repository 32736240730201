import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { ReportService } from '../../../../../reports/services/report.service';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-year-dropdown',
  templateUrl: './report-year-dropdown.component.html',
  styleUrls: ['./report-year-dropdown.component.scss']
})
export class ReportYearDropdownComponent implements OnInit, OnDestroy {


  @Input() reportType: string = '';
  @Output() selectedYear = new EventEmitter<any>();
  queryParams: any;

  routeSub$: Subscription = new Subscription();


  yearData: any = [];
  currentValue: any = null;
  lang: number = 1;

  constructor(
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lan => {
      if (lan) { this.lang = this.sharedService.getCurrentLangID() }
    })
  }

  ngOnInit(): void {
    this.routeSub$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = { ...params };
      this.currentValue = null;
      this.yearValue();
    });
  }

  getPosion() {
    return this.lang == 2 ? 'before' : 'after'
  }

  yearValue() {
    if (this.queryParams?.year) {
      let year = this.queryParams?.year.split(',');
      this.yearData = year
      if (!this.currentValue) {
        this.currentValue = this.reportService.yearMaxValue(this.queryParams.year);
      }
    } else {
      this.yearData = [];
    }
  }

  reportBasedOnyear(year: number): void {
    if (year != this.currentValue) {
    this.currentValue = year;
    const data = {
      year,
      reportType: this.reportType,
    };
    this.selectedYear.emit(data);
    this.sharedService.yearTrigger.next(true);
    }
  }

  ngOnDestroy() {
    this.routeSub$?.unsubscribe();
  }

}
