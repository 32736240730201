<div class="report-model">


    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.unitTransactionsPrices' | translate}}</h3>
            <span class="totalTransaction">({{ unitTransactionCount }})</span>
        </div>

    </div>
    <app-report-datatable 
        [showLoader]="!unitTransactionLoading" 
        [tableColumns]="unitTransactionPropertyColumns" 
        [dataLength]="unitTransactionCount"
        [tableData]="unitTransactionData" 
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event)" 
        (sort)="sortData($event)"
        (excelDownload)="downloadExcel($event, 'ir_inventory_unit_transaction')">
    </app-report-datatable>
</div>
