
    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.unitRental' | translate}}</h3>
                <span class="totalTransaction">({{retailUnitCount ?
                    reportService.formatInternationNum(retailUnitCount) : retailUnitCount}} {{"reports.totalUnits" |
                    translate}})</span>
            </div>

        </div>
        <!-- <ng-container *ngIf="retailUnitLoading">
            <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
        </ng-container> -->
        <app-report-datatable [showLoader]="!retailUnitLoading" [tableColumns]="retailUnitColumns"
            [dataLength]="retailUnitCount" [tableData]="retailUnitdata"
            (pageEvent)="onPageEvent($event)"
            (sort)="sortData($event)" [allowExcelDownload]="true"
            (excelDownload)="downloadExcel($event, 'property_details_unit_rental')"
            [downloadLoading]="downloadLoading" [showEstaterText]="true">
        </app-report-datatable>


    </div>
