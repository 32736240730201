<div>
    <div class="top-section">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
         xmlns:svgjs="http://svgjs.com/svgjs" width="50" height="50" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g fill="#000"><circle cx="12" cy="17" r="1" transform="rotate(180 12 17)" fill="#f89230" data-original="#000000" class=""></circle><path d="M11.25 14a.75.75 0 0 0 1.5 0zm1.5-7a.75.75 0 0 0-1.5 0zm8.5 5A9.25 9.25 0 0 1 12 21.25v1.5c5.937 0 10.75-4.813 10.75-10.75zM12 21.25A9.25 9.25 0 0 1 2.75 12h-1.5c0 5.937 4.813 10.75 10.75 10.75zM2.75 12A9.25 9.25 0 0 1 12 2.75v-1.5C6.063 1.25 1.25 6.063 1.25 12zM12 2.75A9.25 9.25 0 0 1 21.25 12h1.5c0-5.937-4.813-10.75-10.75-10.75zM12.75 14V7h-1.5v7z" fill="#f89230" data-original="#000000" class=""></path></g></g></svg>
        <h3 class="m-0">{{'confirm.confirm' | translate }}</h3>
    </div>
    <div class="center_div">
        <p class="{{class}}" *ngIf="message">{{message}}</p>
    </div>
    <div class="action-btn" fxLayout="row" fxLayoutAlign="center center">
        <button class="gry-button" *ngIf="type!='info' && type!='warn'" (click)="confirmDialogBox(true)">{{'confirm.yes' | translate}}</button> &nbsp;&nbsp;
        <button class="blue-button" *ngIf="type!='info' && type!='warn'" (click)="confirmDialogBox(false)">{{'confirm.no' | translate}}</button>
        <button class="blue-button" *ngIf="type && (type=='info' || type == 'warn')" (click)="confirmDialogBox(false)">{{'confirm.close' | translate}}</button>
    </div>
</div>