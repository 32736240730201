import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { WarehouseInventoryMenuEnum } from "./report-warehouse-inventory.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class WarehouseInventoryConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: WarehouseInventoryMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.neighborhood',
            name: WarehouseInventoryMenuEnum.neighborhood,
            count: null
        },
        {
            label: 'reports.projectsProperties',
            name: WarehouseInventoryMenuEnum.properties,
            count: null
        }
    ]

    
    static neighbourhoodExpandColumns: any[] = [
        { field : 'warehouse_category', convertNumber: false }, 
        { field : 'land_area_covered', convertNumber: true }, 
        { field : 'builtup_area', convertNumber: true }, 
        { field : 'avg_builtup_area', convertNumber: true }, 
        { field : 'count_of_properties', convertNumber: true }, 
    ]


    
    static neighborhoodColumns: ReportTableColumn[] = [
        {
            header: 'neighborhood',
            columnName: 'reports.neighborhood',
            field: 'neighborhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'land_area_covered',
            columnName: 'reports.land_area_covered',
            field: 'land_area_covered',
            symbol: DataType.squareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true
        },
       
        {
            header: 'builtup_area',
            columnName: 'reports.builtup_area',
            field: 'builtup_area',
            symbol: DataType.squareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true
        },
        {
            header: 'avg_builtup_area',
            columnName: 'reports.avg_buildup_area',
            field: 'avg_builtup_area',
            symbol: DataType.squareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true
        },
        {
            header: 'count_of_properties',
            columnName: 'reports.countOfProperties',
            field: 'count_of_properties',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            isBreak: true
        }
       
    ]

    static propertiesColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.property',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true
        },
        {
            header: 'land_area_covered',
            columnName: 'reports.land_area_covered',
            field: 'land_area_covered',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
            
        },
        {
            header: 'type',
            columnName: 'reports.type',
            field: 'type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            
        },
       
        {
            header: 'builtup_area',
            columnName: 'reports.builtup_area',
            field: 'builtup_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
            
        },
        {
            header: 'neighborhood',
            columnName: 'reports.neighborhood',
            field: 'neighborhood',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'year_of_built',
            columnName: 'reports.builtYear',
            field: 'year_of_built',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]


    
}
