<div class="container">
  <div class="heading text-center">
    <p>{{"reports.saveChangesOrSaveAs" | translate}}</p>
  </div>
  <div class="content">
    <div class="content-inner text-center">
      <p>{{"reports.youMadeSomeChanges" | translate}} <strong> {{appliedFilterName + " "}}</strong>{{"reports.wouldYouLikeTo" | translate}}</p>
    </div>
    <div class="content-inner btns d-flex">
      <button type="button" class="btn btn-cancel" (click)="onCancelClick('')">{{"reports.dontSave" | translate}}</button>
      <button type="submit" class="btn btn-save" (click)="onSaveClick()">{{"User.Save_changes" | translate}}</button>
    </div>
    <div class="content-inner text-center">
      <span>Or </span>
      <button class="dialog-option" (click)="onCancelClick('save')"> {{"reports.SaveAsNewSearchCriteria" | translate}}</button>
    </div>
  </div>
</div>
