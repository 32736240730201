import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from '../../services/report.service';
import { filter, Subscription } from 'rxjs';
import { ReportMapService } from '../../services/report-map.service';
import { Router } from '@angular/router';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-filter-group',
  templateUrl: './report-filter-group.component.html',
  styleUrls: ['./report-filter-group.component.scss']
})
export class ReportFilterGroupComponent {
  @Input() filterDropdownData: any; //
  @Input() filterData: any;  //applied filter.
  @Input() filterList: any; // filter corresponding to open report
  @Input() queryParams: any;
  @Input() user_info: any;

  transformedFilter: any[] = [];
  selectedReport: any;
  SilderValue: any = {};
  langNbr: any;
  CatchmentNbr = 0;

  reportFilterForm!: FormGroup;
  unSubsZoom: Subscription = new Subscription();
  languageSub$: Subscription = new Subscription();
  currentLang: string = '';

  //New
  isPolygon: boolean = false;
  LockReport: boolean = true;
  applyDisable:boolean = false;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private sharedService: SharedService,
    public reportService: ReportService,
    private mapService: ReportMapService,
    private router: Router,
    private translateService: TranslateService
  ) {

  }

  ngOnInit() {
   
  }
  ngOnChanges(changes: any) {
    this.LockReport = this.reportService.getLockReport();
    this.CatchmentNbr = this.queryParams?.polygon ? JSON.parse(this.queryParams?.polygon)?.features.length : 0;
    this.setLang();
    if (changes?.filterData) {
      this.transformJson(this.filterData)
    }
    this.selectedReport = this.reportService.getCurrentReport();
    const selectedData = this.reportService.setFiltervalue(this.queryParams);
    this.getPatchValue(selectedData);
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   this.queryParams = params;
    //   this.isPolygon = (this.queryParams?.polygon) ? true : false;
    // })

    this.isPolygon = (this.queryParams?.polygon) ? true : false;
  }

  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
      if (lang) { this.langNbr = this.sharedService.getCurrentLangID() }
    })
  }

  /**
   * This function is used to group the value of same filter.
   * @param data : applied filter
   */
  transformJson(data: any) {
    // filterList
    this.transformedFilter = []
    //Add Lock
    this.reportService.filterSubscription();
    // Grouping by 'filter' value
    const groupedData = data.reduce((acc: any, curr: any) => {
      if (!acc[curr.filter]) {
        acc[curr.filter] = [];
      }
      acc[curr.filter].push(curr.label);
      return acc;
    }, {});
    // Converting grouped data into desired format
    for (const key in groupedData) {
      
      if (this.filterDropdownData?.length > 0) {
        let obj = this.filterDropdownData.find((x: any) => x.parameter_name === key);
        if (obj) {
          this.transformedFilter.push({
            name: obj.title,
            filter: key,
            data: groupedData[key],
            isChecked: true,
            type: obj.type,
            title: obj.title,
            required: obj.required,
            parameter_name: obj.parameter_name
          });
        }
      }
    }
    
    //Create a Form
    let form: any = {};
    this.transformedFilter.map((ele: any) => {
      if (ele.required) {
        form[ele.parameter_name] = new FormControl('', Validators.required);
      } else {
        form[ele.parameter_name] = new FormControl('');
      }
      if (ele.type == 'range') {
        form[ele.parameter_name] =
          this.fb.group({
            min: [0],
            max: [0],
          });
      }
    })
    this.reportFilterForm = this.fb.group(form);
  }
  //Apply Filter button Action
  applyFilter() {
    const controls = this.reportFilterForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        let ftype: any = this.filterList.find((e: any) => e.parameter_name == name);
        if(ftype.type == "range"){
          this.alertService.warn(`${ftype.label[this.sharedService.getCurrentLangID()] +" "+ this.translateService.instant('reports.invalid')}`);
        }else{
          this.alertService.warn(`${ftype.label[this.sharedService.getCurrentLangID()] +" "+ this.translateService.instant('reports.is_required')}`);
        }
      }
    }
    if (this.reportFilterForm.invalid) return;
    const filterUrl = this.reportService.getFilterInURL(
      this.reportFilterForm.value
    );

    let mergerparam = { ...this.queryParams, ...filterUrl }
    for (let key in mergerparam) {
      if (mergerparam[key] == '' || mergerparam[key] == null || mergerparam[key] == undefined || mergerparam[key] == '0-0') {
        delete mergerparam[key];
      }
    }

    this.unSubsZoom = this.mapService.zoom.subscribe(res => {
      mergerparam['zoom'] = res;
    })
    if (this.mapService.getCentroid()) mergerparam['centroid'] = this.mapService.getCentroid();
    delete mergerparam['Zoom'];
    delete mergerparam['Centroid'];
    this.reportService.filterFlag = true;  // hold the user activity on router change
    this.router.navigate([`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`], {
      queryParams: mergerparam,
    });
    // this.savefilterValue(mergerparam);
    this.mapService.reportFiltertoggle.next(true); //// for when filter is being applied
  }
  // savefilterValue(value: any) {
  //   let filterValue = { ...value };

  //   if (filterValue['property_category']) {
  //     delete filterValue['property_category'];
  //   }
  //   if (filterValue['unit_category']) {
  //     delete filterValue['unit_category'];
  //   }
  //   if (filterValue['quarter']) {
  //     let quarter = JSON.parse(filterValue['quarter'])
  //     filterValue['quarter'] = quarter;
  //   }
  //   Object.keys(filterValue).forEach(value => {
  //     if (Array.isArray(filterValue[value])) {
  //       filterValue[value] = filterValue[value].join(',');
  //     }
  //   })
  //   delete filterValue['srid'];
  //   let body = {
  //     report_id: this.selectedReport.id,
  //     user_id: this.user_info.user_id,
  //     filter_data: filterValue,
  //     added_by: this.user_info.user_id
  //   }
  //   this.mapService.reportFilter.next(filterValue);
  //   this.reportService.setUserPreference(body).subscribe((res: any) => { });
  // }
  getPatchValue(selectedData: any) {
    delete selectedData['gid'];
    delete selectedData['rid'];
    delete selectedData['oid'];
    this.SilderValue = {}
    if (selectedData) {
      let ftype: any;
      for (let key in selectedData) {
        ftype = this.selectedReport.filter.find((e: any) => e.parameter_name == key);
        if (ftype && ftype.type == 'multi_select') {
          if (selectedData[key]?.includes(',')) {
            this.reportFilterForm.patchValue({ [key]: selectedData[key].split(',') });
          } else {
            this.reportFilterForm.patchValue({ [key]: selectedData[key] ? [selectedData[key]] : '' });
          }
        }
        if (ftype && ftype.type == 'single_select') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : '' });
        }
        if (ftype && ftype.type == 'range') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : { min: "0", max: "0" } });
          this.SilderValue[key] = selectedData[key];
        }
        if (ftype && ftype.type == 'text') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : '' });
        }
        if (ftype && ftype.type == 'date_range') {
          this.reportFilterForm.patchValue({ [key]: selectedData[key] ? selectedData[key] : '' });
        }
      }
    }
  }
  //click to checkbox value assign in form group
  onCheckboxChange(event: any, value: any, control: any) {
    const eleControl = this.reportFilterForm.get(control) as FormControl;
    const controlValue = eleControl.value as string[];
    if (event.checked) {
      controlValue.push(value);
    } else {
      const index = controlValue.indexOf(value);
      if (index !== -1) {
        controlValue.splice(index, 1);
      }
    }
    // Update the value of the form control
    eleControl.setValue(controlValue);
  }
  //Click to remove all filter
  removeAllFilter(obj: any) {
    if (obj.required) {
      let ftype: any = this.filterList.find((e: any) => e.parameter_name == obj.parameter_name);
      this.alertService.warn(`${ftype.label[this.sharedService.getCurrentLangID()]} is Required`);
      return;
    }
    if (obj.type !== 'range') {
      this.reportFilterForm.controls[obj.parameter_name].setValue('');
    }
    if (obj.type == 'range') {
      this.reportFilterForm.controls[obj.parameter_name].setValue(
        {
          min: [0],
          max: [0],
        }
      );
    }
    this.applyFilter();
  }
  //Chip Click Action
  getFilterChip(obj: any) {
    obj.isChecked = false;
    setTimeout(() => {
      obj.isChecked = true;
    });
    //Add Lock
    this.reportService.filterSubscription();
    let filterObj = this.filterList.find((x: any) => x.parameter_name === obj.parameter_name);
    if(filterObj){
      if(filterObj?.value_list?.length){
        this.applyDisable = filterObj.value_list.some((x: any) => x.isSubscription);
      }else{
        this.applyDisable = filterObj?.isSubscription;
      }
    }
    if(obj.type == 'range' || obj.type == 'date_range'){
      this.applyDisable = true;
    }
  }
  //Range slider set values
  getMaxValue(rangeValue: string) {
    if (rangeValue) {
      const val = rangeValue.split('|')
      if (val[1]) {
        return Number(val[1]);
      } else {
        const a = val[0].split('-');
        if (a[1]) {
          return Number(a[1]);
        }
      }
    }
    return 50000;
  }
  // If "neigh_name" exists, then change the language condition.
  getNeighLabelFromLocal(label: string) {
    return this.sharedService.getNeighLabel(label);
  }
  getLabel(key: string, value: string) {
    let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
    if (filter && filter.value_list?.length) {
      let f_value_obj = filter.value_list.find((e: any) => e.val == value);
      if (f_value_obj) {
        return f_value_obj.label[this.langNbr] ? f_value_obj.label[this.langNbr] : f_value_obj.label['1']
      }
    }
    return value;
  }
  cancelFilter() {
    const selectedData = this.reportService.setFiltervalue(this.queryParams);
    this.getPatchValue(selectedData);
  }
  ngOnDestroy(): void {
    this.unSubsZoom.unsubscribe();
    this.languageSub$.unsubscribe();
  }
}

