<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <ng-container *ngIf="detailLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
    </ng-container>
    <!-- *ngIf="propertyDetail" -->
    <div class="property-details" *ngIf="!detailLoading && propertyDetail">
        <div class="property-info">
            <h4>{{ propertyDetail?.property_name}}</h4>
            <p><strong>{{'reports.address' | translate}}:</strong> {{ propertyDetail?.address }}</p>
            <p><strong>{{'reports.developer' | translate}}:</strong> {{ propertyDetail?.developer_name }}</p>

            <p><strong>{{'reports.year_of_build' | translate}}:</strong> {{ propertyDetail?.year_of_build }}</p>


            <div class="property-zone" *ngIf="propertyDetail?.zoning">
                <h4>{{'reports.property_type_zoning' | translate}}</h4>
                <div class="zoning-info">
                    <span>{{ "reports.zoning" | translate }} : <strong>{{ propertyDetail?.zoning }}</strong></span>
                    <span *ngIf="propertyDetail?.property_type"><strong>{{ propertyDetail?.property_type
                            }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>{{'reports.property_information' | translate}}</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>{{'reports.land_area' | translate}}</h6>
                        <span class="d-flex align-items-center justify-content-center" [matTooltip]="propertyDetail?.land_area">{{ propertyDetail?.land_area ?
                            propertyDetail?.land_area : ('na' | translate)
                            }}
                            <small class="smvalue" *ngIf="propertyDetail?.land_area">{{'sqm_unit' | translate}}</small>
                        </span>
                    </div>
                    <div class="property-area frontage">
                        <h6>{{ "reports.setback" | translate }}</h6>
                        <span class="d-flex align-items-center justify-content-center" [matTooltip]="propertyDetail?.setback">
                            {{ propertyDetail?.setback ? (propertyDetail?.setback) : ('na' | translate) }}
                            <small class="smvalue" *ngIf="propertyDetail?.setback">{{'sqm_unit' | translate}}</small>
                        </span>
                    </div>
                    <div class="property-area setback">
                        <h6>{{ "reports.corners" | translate }}</h6>
                        <span [ngClass]="{'nocorner-txt': !propertyDetail?.corners}">{{ propertyDetail?.corners ?
                            propertyDetail?.corners : ('reports.no_corner' | translate) }}</span>
                    </div>
                    <div class="property-area corner">
                        <h6>{{'reports.buildUp' | translate}}</h6>
                        <span class="d-flex align-items-center justify-content-center">{{ propertyDetail?.builtup ? propertyDetail?.builtup : ('na' | translate) }}
                            <small class="smvalue" *ngIf="propertyDetail?.builtup">{{'sqm_unit' | translate}}</small>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="prop-img">
            <img *ngIf="propertyDetail?.corners == '0'" src="../../../../../../../../../assets/images/corner_0.svg" alt="Image">
            <img *ngIf="propertyDetail?.corners == '1'" src="../../../../../../../../../assets/images/corner_1.svg" alt="Image">
            <img *ngIf="propertyDetail?.corners == '2'" src="../../../../../../../../../assets/images/corner_2.svg" alt="Image">
            <img *ngIf="propertyDetail?.corners != '0' && propertyDetail?.corners != '1' && propertyDetail?.corners != '2'" src="../../../../../../../../../assets/images/corner_4.svg" alt="Image">
        </div>
    </div>
    <div class="report-tabsection">
        <ng-container *ngIf="overViewPropertyLoading">
            <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="3"></app-skeleton-loader>
        </ng-container>
        <div class="report-overview" *ngIf="!overViewPropertyLoading && overViewPropertySummary">
            
            <app-overview-card
                [card_title]="'reports.totalResidentialUnits'"
                [untillDate]="overViewPropertySummary.untillDate"
                [total_prev]="overViewPropertySummary.prev_residential_units"
                [total_current]="overViewPropertySummary.residential_units"
                [total_percentage]="overViewPropertySummary.totalResidential"
            ></app-overview-card>

            <app-overview-card
                [card_title]="'reports.totalCommercialUnits'"
                [untillDate]="overViewPropertySummary.untillDate"
                [total_prev]="overViewPropertySummary.prev_commercial_units"
                [total_current]="overViewPropertySummary.commercial_units"
                [total_percentage]="overViewPropertySummary.totalCommercial"
            ></app-overview-card>

            <app-overview-card
                [card_title]="'reports.Total_Parking_Lots'"
                [untillDate]=""
                [total_prev]=""
                [total_current]="overViewPropertySummary.parking_lots"
                [total_percentage]="" [isWas]="false"
            ></app-overview-card>

        </div>
    </div>


<div class="report-model">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.units' | translate}}</h3>
            <span class="totalTransaction">{{ unitCount }}</span>
        </div>

        <!-- <search-bar></search-bar> -->
    </div>
    <app-report-datatable 
        [showLoader]="!unitLoading" 
        [tableColumns]="unitPropertyColumns" 
        [dataLength]="unitCount"
        [tableData]="unitData" 
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event, inventoryMenuEnum.unit)" 
        (sort)="sortData($event, inventoryMenuEnum.unit)"
        (excelDownload)="downloadExcel($event, 'ir_occupancy_property_unit_report', 'a')" [downloadLoading]="downloadLoading['a']">
    </app-report-datatable>
</div>

<div class="report-model">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.unitTransactionsPrices' | translate}}</h3>
            <span class="totalTransaction">{{ unitTransactionCount }}</span>
        </div>

        <!-- <search-bar></search-bar> -->
    </div>
    <app-report-datatable 
        [showLoader]="!unitTransactionLoading" 
        [tableColumns]="unitTransactionPropertyColumns" 
        [dataLength]="unitTransactionCount"
        [tableData]="unitTransactionData" 
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event, inventoryMenuEnum.unitTransaction)" 
        (sort)="sortData($event, inventoryMenuEnum.unitTransaction)"
        (excelDownload)="downloadExcel($event, 'ir_occupancy_property_unit_transaction_report', 'b')" [downloadLoading]="downloadLoading['b']">
    </app-report-datatable>
</div>
</div>