import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
/**
 * This component is used as dialog-box if we want to show list of content.
 */
@Component({
  selector: 'app-list-dialog-box',
  templateUrl: './list-dialog-box.component.html',
  styleUrls: ['./list-dialog-box.component.scss']
})
export class ListDialogBoxComponent {
  selectedFolder:string = "";
  message: any;
  class='';
  type:string='';
  isSubmit = false;
  constructor(
    public dialogRef: MatDialogRef<ListDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    public alertService: AlertService,
    public translate: TranslateService) {
    this.message = this.info.message;
    this.class = this.info.class
    this.type= this.info.type?this.info.type:'';

   }

  selectFolder(id:string) {
    this.isSubmit = false;
    this.selectedFolder = id;
  }

  confirmDialogBox() {
    this.isSubmit = true;
    if(this.selectedFolder) {
      this.dialogRef.close(this.selectedFolder);
    }

  }

}
