import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportInvestmentInventoryService } from '../report-investment-inventory.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { InventoryMenuEnum } from '../report-investment-inventory.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { InventoryReportConstant } from '../report-investment-inventory.constants';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-report-investment-inventory-details',
  templateUrl: './report-investment-inventory-details.component.html',
  styleUrls: ['./report-investment-inventory-details.component.scss']
})
export class ReportInvestmentInventoryDetailsComponent implements OnInit {

  @Input() propertyInfo: any;
  propertyDetail: any = null;
  allDataLoading = false;
  sqm_unit:string="";


  unitLoading = false;
  unitData = [];
  unitCount = 0;

  unitTransactionLoading = false;
  unitTransactionData = [];
  unitTransactionCount = 0;

  downloadLoading = false;

  inventoryMenuEnum = InventoryMenuEnum;

  unitPropertyColumns = InventoryReportConstant.unitPropertyColumns;
  unitTransactionPropertyColumns  = InventoryReportConstant.unitTransactionPropertyColumns;

  overViewPropertyLoading: boolean = false;
  overViewPropertySummary: any = null;


  placeName='';
  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  
  constructor(
    private sharedService: SharedService,
    private reportInvestmentInventoryService: ReportInvestmentInventoryService,
    private alertService: AlertService,
    public translate: TranslateService,
    public reportService: ReportService,
    private router: ActivatedRoute
  ){}

  ngOnInit(): void {
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName =  this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.router.queryParams.subscribe((param: any) => {
      this.getPropertyAreaSummary(param);
    })
    this.getReport()
  }

  backToTransaction() {
    this.reportService.transactionPropertyDetails.next(false);
  }

  getReport(){
    // this.getPropertyOverviewSummary();
    this.getUnit();
    this.getUnitTrasanction();
  }

  getPropertyAreaSummary(param:any) {
    this.propertyDetail =null
    this.allDataLoading = true;
    let payload = { ...param }
    payload['neigh_name'] = this.propertyInfo?.neigh_name;
    this.reportInvestmentInventoryService.getPropertyAreaSummary(payload, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.propertyDetail = res.data[0];
           this.propertyDetail['land_area'] =  this.propertyDetail['land_area'] ? this.reportService.formatInternationNum(this.propertyDetail['land_area']) : null
           this.propertyDetail['setback'] =  this.propertyDetail['setback'] ? this.reportService.formatInternationNum(this.propertyDetail['setback']) : null
           this.propertyDetail['builtup'] = this.propertyDetail['builtup'] ? this.reportService.formatInternationNum(this.propertyDetail['builtup']): null
          } else {
            this.propertyDetail = null;
          }
        },
        error: (error: any) => {
          this.propertyDetail = null;
        }
      }
    )
  }

  // getPropertyOverviewSummary() {
  //   this.overViewPropertyLoading = true;
  //   this.reportInvestmentInventoryService.getOverviewPropertySummary(this.propertyInfo?.property_uid).subscribe(
  //     {
  //       next: (res: any) => {
  //         if (res.status === 200) {
  //          this.overViewPropertySummary = this.reportInvestmentInventoryService.formatOverViewPropertySummary(res.data[0]);
  //         } else {
  //           this.overViewPropertySummary = null;
  //         }
  //         this.overViewPropertyLoading = false;
  //       },
  //       error: (error: any) => {
  //         this.overViewPropertySummary = null;
  //         this.overViewPropertyLoading = false;
  //       }
  //     }
  //   )
  // }


  getUnit() {
    this.unitLoading = true;
    this.reportInvestmentInventoryService.getUnitReport(this.reportInvestmentInventoryService.unitPayload, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.unitData = res.data;
            if(this.reportInvestmentInventoryService.unitPayload.pageNumber == 0){
            this.unitCount = res.count;
           }
            
          } else {
            this.unitData = [];
            this.unitCount = 0;
          }
          this.unitLoading = false;
        },
        error:()=>{
          this.unitLoading = false;
          this.unitData = [];
          this.unitCount = 0;
         
        }
        
      }
    )
  }

  getUnitTrasanction(){
    this.unitTransactionLoading = true;
    this.reportInvestmentInventoryService.getUnitTransctionReport(this.reportInvestmentInventoryService.unitTransactionPayload, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.unitTransactionData = res.data.map((value: any) => {
             value['transaction_date']= value.transaction_date ? moment.utc(value.transaction_date).local().format('DD MMM YYYY') : null;
            return value;
            }) 
           if(this.reportInvestmentInventoryService.unitTransactionPayload.pageNumber == 0){
            this.unitTransactionCount = res.count;
           }
            
          } else {
            this.unitTransactionData = [];
            this.unitTransactionCount = 0;
          }
          this.unitTransactionLoading = false;
        },
        error:()=>{
          this.unitTransactionLoading = false;
          this.unitTransactionData = [];
          this.unitTransactionCount = 0;
         
        }
        
      }
    )
  }

  downloadExcel(event: any, type: string) {
    this.downloadRentalReport(type);
  }

  downloadRentalReport(type: string) {
    this.downloadLoading = true;
    this.reportInvestmentInventoryService.downloadInvestmentReport(type, this.userInfo?.user_id, this.propertyInfo?.property_uid).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=investment_residential`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading = false;
        }
      }
    )
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.inventoryMenuEnum.unit) {
        this.reportInvestmentInventoryService.unitPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getUnit();
      } else {
        this.reportInvestmentInventoryService.unitTransactionPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getUnitTrasanction();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.inventoryMenuEnum.unit) {
      this.reportInvestmentInventoryService.unitPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.unitPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getUnit();
    }  else {
      this.reportInvestmentInventoryService.unitTransactionPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.unitTransactionPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getUnitTrasanction();
    }
  }

}
