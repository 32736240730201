import { ReportService } from 'src/app/reports/services/report.service';
import { Component, effect, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import {filter} from 'rxjs/operators';

import { commonContants, menuList } from '../../constants/common.contants';
import { SharedService } from '../../services/shared.service';
import { LanguagesEnum } from '../../models/enums/common.enum';
import { SideMenu } from '../../models/common.model';
import { GlobalContants } from '../../constants/global.contants';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { ContactSalesComponent } from '../contact-sales/contact-sales.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  @Input("IsLoggedIn") isLoggedIn: boolean = false;

  collapseMenu$: boolean = true;
  langset: string = commonContants.defaultLanguage;
  currentLangID: number = LanguagesEnum.english;
  menuList = menuList;
  navigateID: number = 2;
  cityLang!:string;
  currentUrl: string = '';
  langList!:number;
  languageList:any;
  @Output() collapseToggle = new EventEmitter<any>();

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private dialog: MatDialog,
    private apiService: ApiService,
    private reportService: ReportService
  ){
    effect(()=> {
      this.languageList = this.sharedService.languageList()
      this.langList = this.sharedService.languageList()?.length
  });

  }
  returnVal(lanId:number){
    if(lanId == LanguagesEnum.english && this.languageList.indexOf('en') > -1){
      return true;
    }
    if(lanId == LanguagesEnum.arabic && this.languageList.indexOf('ar') > -1){
      return true;
    }
    return false;
  }
  ngOnInit(): void {
    this.setLang();
    let userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    if(userInfo) this.apiService.getAllowResources(userInfo.user_id);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        this.navigateID = this.getNavigationUrl(this.currentUrl);
      });
      this.collapseToggle.emit(this.collapseMenu$)
  }
  ngAfterViewInit(){
    setTimeout(()=>{
      // this.setLang();
    }, 500)
  }

  check(menu:any){
    if((menu.routeUrl == '/AI-report')){
      if(this.apiService.checkResourceAccess('ai_report').GET){
        return true;
      }else{
        return false;
      }
    }
    return true;
  }
  setLang(){
    let currentLang = this.sharedService.getCurrentLangID();
    this.currentLangID = currentLang === 1 ? LanguagesEnum.arabic : LanguagesEnum.english;
    this.cityLang = `${this.sharedService.CityLang}`
  }

  collapseMenu(){
    this.collapseMenu$ = !this.collapseMenu$;
    this.collapseToggle.emit(this.collapseMenu$);
    
  }

  changeLang(lang: number){
    console.log(lang);
    
    this.currentLangID = lang === 1 ? LanguagesEnum.arabic : LanguagesEnum.english;
    this.sharedService.setLanguage(lang, this.currentUrl)
  }

  navigateMenu(menu: SideMenu){
    if(menu.index == 2 && menu.index === this.navigateID) {
      this.reportService.reportQueryToggle$.next(true);
    }
    this.collapseMenu$ = true;
    this.collapseToggle.emit(this.collapseMenu$)
    this.navigateID = menu.index;
    const lang = this.sharedService.getCurrentLang() || 'en';
    const city = this.sharedService.getStoreValue(GlobalContants.StoreKey.Slug);
    const getReportUrl = this.sharedService.getStoreValue(GlobalContants.StoreKey.latestReportRoute);
    if(menu.index === 2 && getReportUrl){
      this.router.navigateByUrl(`${getReportUrl}`);
    } else {
      this.router.navigateByUrl(`${city}-${lang}${menu.routeUrl}`);
    }
  }

  getNavigationUrl(url: string){
    let formatUrl = url;
    if(url.includes('?')){
      const index = url.indexOf('?');
      formatUrl = url.substring(0,index)?.trim();
    }
    let match = null;
    let urlData = formatUrl.split('/');
    if(urlData.length > 1){
      match = this.menuList.find((name) => {
        return name.routeUrl === `/${urlData[2]}`
      })
    }
    return match ? match?.index : 0;
  }


  getHelp(){
    this.dialog.open(ContactSalesComponent,{
      width: '600px',
      maxHeight: '95vh',
      panelClass: 'contact-form',
      data: { Type : 'contact_us'}
    })
  }
}
