import { Component, Input } from '@angular/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from '../../services/report.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/common/services/user.service';
import { ReportMapService } from '../../services/report-map.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogBoxComponent } from 'src/app/common/components/confirm-dialog-box/confirm-dialog-box.component';
import { AlertService } from 'src/app/common/components/alert/alert.service';

@Component({
  selector: 'app-recent-saved-report',
  templateUrl: './recent-saved-report.component.html',
  styleUrls: ['./recent-saved-report.component.scss']
})
export class RecentSavedReportComponent {
  userInfo!: any;
  savedReport:any=[];
  citySub$: Subscription = new Subscription();
  savedReport$: Subscription = new Subscription();
  recentReport:string = "";
  @Input() searchReportName = "";
  isReportLoaded:boolean = false;
  openedReport:number = -1;
  lang : number = 1;
  constructor(private reportService: ReportService,
    private sharedService : SharedService,
    private userService: UserService,
    private mapService: ReportMapService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private dailog: MatDialog,
    private alertService : AlertService
  ){ }
  ngOnChanges(change: any) {
    this.sharedService.language$.subscribe(lang => {
      if (lang) { this.lang = this.sharedService.getCurrentLangID() }
    })
    if(change?.searchReportName?.currentValue || !change?.searchReportName?.firstChange) this.getSavedReport(this.searchReportName);
    this.activatedRoute.queryParams.subscribe((res) => {
      if(res['srid'])
        this.recentReport = res['srid'];
      else  this.recentReport = ""
    })
  }
  ngOnInit(){
    if(this.sharedService.cityName) this.getSavedReport();
    this.citySub$ = this.sharedService.selectedCity$.subscribe((res: any) => {
      this.getSavedReport();
    })
    this.savedReport$ = this.userService.savedReport$.subscribe((res:boolean)=>{
      if(res){
        this.getSavedReport();
      }
    })
  }
  getSavedReport(searchTerm:string = ""){
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    if(this.userInfo?.user_id){
      this.isReportLoaded = true;
      this.reportService.getRecentSavedReport(this.userInfo.user_id, searchTerm).subscribe({next: (res:any)=>{
        if(res.status == 200 && res.data.length){
          this.savedReport = res.data;
          this.isReportLoaded = false;
        }else{
          this.savedReport = [];
          this.isReportLoaded = false;
        }
      }, error:()=>{
        this.savedReport = [];
      }})
    }
  }

  viewReport(report:any){
    this.recentReport = report.id;
    this.mapService.mapFiltertoggle.next(false);
    this.mapService.mapLayertoggle.next(false);
    if(report.data){
      let queryParams:any={};
      queryParams['srid']=report.id;
      for(let key in report.data){
        if(key == 'polygon' && report.data[key]){
          queryParams[key] = JSON.stringify(report.data[key])
        }else{
          queryParams[key] = String(report.data[key])
        }
      }

      // this.reportService.selectedReport$.next({flag: true});
      this.router.navigate([`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`],
      {queryParams: queryParams})
    }
    this.reportService.reportQueryToggle$.next(false)
  }
  getJSONName(val:any) {
    if (val && val.hasOwnProperty(this.sharedService.getCurrentLangID())) {
      return val[this.sharedService.getCurrentLangID()];
    } else if (val && val.hasOwnProperty(2)) {
      return val[2];
    } else if (val && val.hasOwnProperty(1)) {
      return val[1];
    }
    return '';
  }
  deleteReport(report: any, index:number) {
    let _data = {
      parent_data: null,
      message: this.translate.instant('reports.Do_you_want_delete_report'),
    };
    this.dailog
      .open(ConfirmDialogBoxComponent, {
        width: '400px',
        height: 'auto',
        data: _data,
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.userService.deleteReport(report).subscribe({
            next: (res: any) => {
              this.openedReport = index;
              if (res && res.status == 201) {
                this.getSavedReport();
                this.alertService.success(res.message);
              } else {
                this.alertService.warn(res.message);
              }
            },
            error: (err: any) => {
              this.openedReport = index;
              this.alertService.error(err.error.message);
            },
          });
        }
      });
  }
  ngOnDestroy(): void{
    this.citySub$?.unsubscribe();
    this.savedReport$.unsubscribe();
  }
}
