import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-for-demo-confirm-dialog',
  templateUrl: './request-for-demo-confirm-dialog.component.html',
  styleUrls: ['./request-for-demo-confirm-dialog.component.scss']
})
export class RequestForDemoConfirmDialogComponent {

  constructor(
    public dialogRef : MatDialogRef<RequestForDemoConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public info : any,
  ) { }
  confirmDialogBox(check:boolean) {
    this.dialogRef.close(check);
  }
}
