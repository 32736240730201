<div class="filter-year" *ngIf="yearData.length > 1">
  <!-- <span class="year-text" >444</span -->
  
  <ng-container>
    <span [matMenuTriggerFor]="yearList" class="year-text-many">
      <span >{{ currentValue }} </span>
      <mat-icon class="filter-arrow-icon">keyboard_arrow_down</mat-icon>
    </span>

    <mat-menu #yearList="matMenu" [xPosition]="getPosion()">
      <ul class="filter-list">
        <li  
        (click)="reportBasedOnyear(year)"
        *ngFor="let year of yearData">{{ year }}</li>
      </ul>
    </mat-menu>
  </ng-container>
</div>
