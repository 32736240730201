<div class="navBar">
    <div class="navBar-btn">
        <button (click)="backToTransaction()" [ngClass]="{ 'backToReportAr' : currentLang === 'ar'}"
            class="backToReport"><mat-icon>keyboard_arrow_left</mat-icon>
            <span>{{'reports.backToReport'|translate}}</span> </button>
    </div>

    <div class="property-detailMenu" [ngClass]="{ 'property-detailMenuAr' : currentReportView === 2}">
        <app-report-sub-menu [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    </div>
</div>
<div class="report-tabsection" id="{{ menuEnum.overview}}" [ngClass]="{ 'scroll': currentScroll === menuEnum.overview}">
    <div class="project-detail">
        <!-- Overview -->
        <app-project-detail-overview [projectUid]="projectInfo.proj_uid"></app-project-detail-overview>
    </div>
</div>

<!-- Land Parcel Table -->
<div class="report-tabsection" id="{{menuEnum.landParcelTable}}"
    [ngClass]="{ 'scroll': currentScroll === menuEnum.landParcelTable}">
    <!-- <app-report-year-dropdown [reportType]="'unit'" (selectedYear)="selectedYear($event)"></app-report-year-dropdown> -->
    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{"reports.landParcelTable" | translate}}</h3>
                <span class="totalTransaction"> ({{landParcelCount ?
                    reportService.formatInternationNum(landParcelCount) : landParcelCount}}
                    {{"reports.totalCount" | translate}})</span>
            </div>
        </div>
        <app-report-datatable class="unitTransaction-table" [showLoader]="!landParcelLoading"
            [tableColumns]="landParcelColumns" [dataLength]="landParcelCount" [tableData]="landParceldata"
            [allowExcelDownload]="true" [showEstaterText]="true" (pageEvent)="onPageEvent($event, 'landParcel')"
            (sort)="sortData($event, 'landParcel')" (excelDownload)="downloadExcel($event, 'unit', 'a')"
            [downloadLoading]="downloadLoading['a']">
        </app-report-datatable>

    </div>
</div>

<!-- Property Details - houses -->
<div class="report-tabsection" id="{{menuEnum.propertyDetailsHouses}}"
    [ngClass]="{ 'scroll': currentScroll === menuEnum.propertyDetailsHouses}">
    <!-- <app-report-year-dropdown [reportType]="'unit'" (selectedYear)="selectedYear($event)"></app-report-year-dropdown> -->
    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{"reports.propertyDetailsHouses" | translate}}</h3>
                <span class="totalTransaction"> ({{propertyHousesCount ?
                    reportService.formatInternationNum(propertyHousesCount) : propertyHousesCount}}
                    {{"reports.totalCount" | translate}})</span>
            </div>
        </div>
        <app-report-datatable class="unitTransaction-table" [showLoader]="!propertyHousesLoading"
            [tableColumns]="propertyHousesColumns" [dataLength]="propertyHousesCount" [tableData]="propertyHousesdata"
            [allowExcelDownload]="true" [showEstaterText]="true" (pageEvent)="onPageEvent($event, 'propertyHouses')"
            (sort)="sortData($event, 'propertyHouses')" (excelDownload)="downloadExcel($event, 'unit', 'b')"
            [downloadLoading]="downloadLoading['b']">
        </app-report-datatable>

    </div>
</div>