import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportMallProfilingService } from './report-mall-profiling.service';
import { MallProfilingReportConstant } from './report-mall-profiling.constants';
import { ReportTableColumn } from '../../models/reports.model';
import { ReportsListsService } from '../../services/reports-lists.service';


@Component({
  selector: 'app-report-mall-profiling',
  templateUrl: './report-mall-profiling.component.html',
  styleUrls: ['./report-mall-profiling.component.scss']
})
export class ReportMallProfilingComponent implements OnInit, OnChanges {

  @Input() params: any;

  totalColumnReport: any = [];
  totalColumnReportColumn: ReportTableColumn[] = MallProfilingReportConstant.totalColumn;
  floorWiseableColumn: ReportTableColumn[] = MallProfilingReportConstant.floorWisetableColumns;
  floorWiseData: any = [];
  totalLoading = false;
  floorWiseDataLoading = false;
  
  constructor(
    private reportMallProfilingService: ReportMallProfilingService,
    private reportsListsService: ReportsListsService

  ){}

  ngOnInit(){
    this.getReports();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.getReports();
    }
  }
  
  getReports(){
    this.getMallProfilingTotalReport();
    this.getMallProfilingFloorWiseReport();
  }

  getMallProfilingTotalReport(year = null){
    this.totalLoading = true;
    this.reportMallProfilingService.getMallProfilingTotalReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.totalColumnReportColumn = [...this.reportsListsService.yearColumnAppend(MallProfilingReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
          this.totalColumnReport = this.reportsListsService.summaryReportConversion( res.data, MallProfilingReportConstant.totalColumn)
        } else {
          this.totalColumnReport = [];
        }
        this.totalLoading = false;
      },
      error: (error:any) => {
        this.totalColumnReport = [];
        this.totalLoading = false;
      }}
    )
  }

  getMallProfilingFloorWiseReport(year = null){
    this.floorWiseDataLoading = true;
    this.reportMallProfilingService.getMallProfilingFloorWiseReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.floorWiseData = res?.data;
        } else {
          this.floorWiseData = [];
        }
        this.floorWiseDataLoading = false;
      },
      error: (error:any) => {
        this.floorWiseData = [];
        this.floorWiseDataLoading = false;
      }}
    )
  }

  selectedYear(data: any){
    if(data.reportType === 'totalReport'){
      this.getMallProfilingTotalReport(data.year);
    } else {
      this.getMallProfilingFloorWiseReport(data.year);
    }
  }

}
