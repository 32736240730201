

<div class="report-list"> 
    
    <h4 *ngIf="title !== ''">{{ title | translate}}</h4>

    <ng-container *ngIf="yearDropdown">
        <app-report-year-dropdown
            [reportType]="yearReportType"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>

    </ng-container>
    
    <ng-container *ngIf="loading">
        <app-skeleton-loader 
            [reportType]="reportType"
        ></app-skeleton-loader>
    </ng-container>

   

    <ng-container *ngIf="!loading">

      
        

        <ng-container *ngIf="reportType === tableView;else chartView">
            <app-report-table-view
                [tableColumn]="tableColumn"
                [dataValue]="dataValue"
                [isDownloadExcel]="isDownloadExcel"
                [reportName]="reportName"
                [expandable]="expandable"
            >
            </app-report-table-view>
        </ng-container>

        
        <ng-template #chartView>
            <ng-container *ngIf="dataValue.length > 0;else noRecord">
        
                <app-report-charts
                    [ngClass]="{'directionLtr' : reportType == pieChart }"
                    [data]="dataValue"
                    [chartType]="reportType"
                    [name]="chartName"
                ></app-report-charts>
            </ng-container>
            <ng-template #noRecord>
                <div class="noRecord" *ngIf="dataValue.length === 0">
                    <span>{{"reports.no_record_found" | translate}}</span>
                </div>
            </ng-template>
        </ng-template>

    </ng-container>

</div>
