import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subject, Subscription, takeUntil } from 'rxjs';

import { SharedService } from '../../services/shared.service';
import { ReportService } from '../../../reports/services/report.service';
import { ReportsViewEnum } from '../../../reports/models/enums/reports.enum';
import { TranslateService } from '@ngx-translate/core';
import { GlobalContants } from '../../constants/global.contants';
import { StoreService } from '../../services/store.service';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { actionIdConstants } from '../../constants/common.contants';
import { UserService } from '../../services/user.service';
import { FormControl } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-city-model',
  templateUrl: './city-model.component.html',
  styleUrls: ['./city-model.component.scss']
})
export class CityModelComponent implements OnInit, OnDestroy {

  cityList: any | null = null;
  cityListStored: any | null = null;
  featureCityListStored: any | null = null;
  groupedItems: any[][] = [];
  citySub$ = new Subscription();
  selectedCitySlug:string;
  cityFlag:string = '';
  currentCitySlug:string;
  selectedCityName!:string;
  citySelectForm = new FormControl();
  public searchFilter = new FormControl<string>('');
  protected _onDestroy = new Subject<void>();
  public lang:any;
  public windowWidth:any;
  public noMatchecItem:boolean=false;
  public showLoader:boolean=true;
  slideConfig1: OwlOptions = {};

  constructor(
    private  userservice :UserService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<CityModelComponent>,
    private reportService: ReportService,
    public translate: TranslateService,
    private storeService: StoreService,
    private mapService: ReportMapService
  ) {
    this.selectedCitySlug = this.sharedService.getCurrentCitySlug();
    this.currentCitySlug = this.sharedService.getCurrentCitySlug();
    this.sharedService.language$.subscribe(lan => {
      if(lan){this.lang = this.sharedService.getCurrentLangID()}
    })
  }

  ngOnInit(): void {
    this.getCity();
    this.getCitylist();
  }
  ngAfterViewInit() {
    this.slideConfig1 = {
      loop:false,
      mergeFit:true,
      autoHeight:true,
      autoWidth:true,
      margin:16,
      nav:true,
      dots:false,
      navText:['', ''],
      slideBy:1,
      responsive:{
          0:{
              items:2
          },
          400:{
            items:3
          },
          500:{
              items:4
          }
      }
    }
  }

  getCity() {
    this.sharedService.getCity()
  }
  /**
   * create a multidiamension array of city list
   */
  groupItems() {
    this.windowWidth = window.innerWidth;
    for (let i = 0; i < this.featureCityListStored.length; i += 2) {
      if(this.featureCityListStored[i + 1]){
      this.groupedItems.push([this.featureCityListStored[i], this.featureCityListStored[i + 1]]);
      }
      else this.groupedItems.push([this.featureCityListStored[i]]);
    }
  }
  /**
   * selecteVal function to patch value in select lebel
   * @param item selected item
   */
  selecteVal(item:string){
    this.cityFlag = '';
    if(item){
      let cities = this.cityListStored.find((items:any)=> items.slug == item);
      let selectedlebel = `${cities?.parent_name?this.translate.instant(cities.parent_name) +', ':''}${this.translate.instant(cities.place_name)}` 
      this.selectedCityName =  selectedlebel;
      this.cityFlag = cities.image;
    }
    else this.selectedCityName = ''
  }
  /**
   * getCitylist get city list
   */
  getCitylist() {
    this.citySub$ = this.sharedService.cityList$.subscribe((res: any | null) => {
      if (res && res.data) {
        this.cityList = res.data;
        this.cityListStored = res.data;
        this.featureCityListStored = res.data.filter((item:any)=> item.is_feature_city)?.splice(0, 10);
        this.groupItems();
        this.showLoader = false;
        this.citySelectForm.patchValue(this.currentCitySlug);
        this.selecteVal(this.currentCitySlug)
      }
    })
    
    this.searchFilter.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.findSearchVal();
    });
  }
  /**
   * findSearchVal function use to filter city list
   * @returns 
   */
  protected findSearchVal() {
    if (!this.cityList) {
      return;
    }
    // 
    let search:string = this.searchFilter.value?.toLowerCase() || '';  // Make search case-insensitive
    let selectedItem: any;

    // Check if a city slug is selected
    if (this.selectedCitySlug) {
      let selectedSlug = this.selectedCitySlug.toLowerCase();
      selectedItem = this.cityListStored.find((d: any) => d.slug?.toLowerCase().includes(selectedSlug));

      if (selectedItem) {
        selectedItem['hideItem'] = false;  // Make sure the selected item is visible
      }
      this.cityList = this.cityListStored.filter((city: any) => city.search_param?.toLowerCase().includes(search));
      let isCityInList = this.cityList.some((item: any) => item.slug == selectedSlug);

      if (!isCityInList && selectedItem) {
        selectedItem['hideItem'] = true;
        this.cityList.unshift(selectedItem);
        this.noMatchecItem = this.cityList.length === 1;
      } else {
        this.noMatchecItem = false;
      }
    } else {
      this.cityList = this.cityListStored.filter((d: any) => d.search_param?.toLowerCase().includes(search));
      this.noMatchecItem = false;
    }
    
  }
/**
 * onCitychange function to select city in sync select and list
 * @param event 
 * @param selectType 
 */
  onCitychange(event:any, selectType:boolean= false) {
    if(event.status == 2){
      return;
    }
    if(selectType) this.selectedCitySlug = event.slug;
    else this.selectedCitySlug = event.value;
    this.citySelectForm.patchValue(this.selectedCitySlug)
    this.selecteVal(this.selectedCitySlug)
  }
/**
 * citySelect function to select city
 * @param item 
 */
  citySelect(item: any='') {
    let city = this.cityListStored.find((d:any)=>d.slug?.toLowerCase().includes(this.selectedCitySlug));
    this.storeService.clear(GlobalContants.StoreKey.latestReportRoute)
    this.sharedService.setSelectedCity(city);

    // on city changes close the map, report filter and legend UI
    this.mapService.mapLayertoggle.next(false);
    this.mapService.mapFiltertoggle.next(false);
    this.mapService.legendToggle.next(false);

    this.mapService.dashboardMapCoordinates.next([city.latitude, city.longitude]);
    this.reportService.reportView$.next(ReportsViewEnum.mapView);
    this.dialogRef.close({ selectedCity: city })
    let userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    
      // Handle case where userInfo is null or undefined
      const activity = {
        userdata: {
          place_id : city.place_id,
          place_name : city.place_name
        },
        actionId: actionIdConstants.User_Login,
        entity: 'User Login',
        }
      this.userservice.logUserActivity(activity);

    }
    
  ngOnDestroy(): void {
    this.citySub$?.unsubscribe();
  }
  /**
   * close city dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
