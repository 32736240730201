import { Injectable } from '@angular/core';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';
import { ApiService } from 'src/app/common/services/api.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';
import { units } from './report-office-space-rental.interface';
import { map, mergeMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportOfficeSpaceRentalService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  unitsPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  rentUnitSizePayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  averageRentRate = new Map();
  occupancyRate = new Map();
  /**
   *
   * @param payload
   * @returns
   * Unit List Get Data
   */
  getProperties(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.unitsPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalpropertyListReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url);
  }

   /**
   *
   * @param payload
   * @returns
   * Unit List Get Data
   */
   getPropertiesV2(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.unitsPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalPropertiesReportv2}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url);
  }
  /**
   *
   * @param payload
   * @returns
   * Unit List Get Data
   */
  getRentUnitSize(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.rentUnitSizePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalRentUnitSizewiseReport}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url);
  }

   /**
   *
   * @param payload
   * @returns
   * Unit List Get Data
   */
   getRentUnitSizeV2(payload: any){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year =  this.rentUnitSizePayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalRentUnitsizeReportv2}${changeParams}&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url);
  }
  /**
   *
   * @returns
   * OverView Data
   */
  getOverview(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  
  /**
   *
   * @returns
   * OverView Data
   */
  getOverviewV2(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewReportv2}${changeParams}`;
    return this.apiServive.get(url)
    
  
  }

  getOverviewNeighV2(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewNeighwiseReportv2}${changeParams}`;
    return this.apiServive.get(url)
    
  
  }
  
  // /**
  //  *
  //  * @returns
  //  * OverView Data
  //  */
  // getOverviewGradeV2(type: string){
  //   let queryParams = { ...this.reportService.queryParams };
  //   if (queryParams?.year) {
  //     queryParams.year = this.reportService.yearMaxValue(queryParams.year);
  //   }
  //   const changeParams = this.reportService.setfilterQueryParams(queryParams);
  //   let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewReportv2}${changeParams}&type=${type}`;
  //   return this.apiServive.get(url);
  // }

  // /**
  //  *
  //  * @returns
  //  * OverView Data
  //  */
  // getOverviewNeighGradeV2(type: string){
  //   let queryParams = { ...this.reportService.queryParams };
  //   if (queryParams?.year) {
  //     queryParams.year = this.reportService.yearMaxValue(queryParams.year);
  //   }
  //   const changeParams = this.reportService.setfilterQueryParams(queryParams);
  //   let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewNeighwiseReportv2}${changeParams}&type=${type}`;
  //   return this.apiServive.get(url);
  // }

  /**
   *
   * @returns
   * OverView Data
   */
  getOverviewGrade(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalOverviewSubCategoryDetailsReport}${changeParams}`;
    return this.apiServive.get(url);
  }
  /**
   *
   * @returns
   * OverView Data
   */
  getOverviewGraph(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    delete queryParams['property_grade']
    const changeParams = this.reportService.setfilterQueryParams(queryParams);

    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalRentRateOverviewGraphdataReport}${changeParams}`;
    return this.apiServive.get(url);
  }

   /**
   *
   * @returns
   * OverView Data
   */
   getOverviewGraphV2(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);

    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalRentRateReportv2}${changeParams}`;
    return this.apiServive.get(url);
  }

   /**
   *
   * @returns
   * OverView Data
   */
   getTransactonVolumeV2(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);

    let url: string = `${environment.miUrl}${apiEndPoints.officeRentalTransactionVolumeReportv2}${changeParams}`;
    return this.apiServive.get(url);
  }
  /**
   *
   * @param type
   * @param user_id
   * @returns
   * Download Report in Excel
   */
  downloadOfficeRentalReport(type:string, user_id:any, is_subscription:boolean=true){
    let url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    if(is_subscription == false){
      url += `&subscription=basic`;
    }
    return this.apiServive.get(url);
  }

  // changeOpacity(index: number, value: any, type: string){

  //   if(type === 'averageRentRate'){

  //     if(this.averageRentRate.has(value)){
  //       return this.averageRentRate.get(value);
  //     } else {
  //       this.averageRentRate.set(value, this.getOpacity(index));
  //       return this.getOpacity(index);
  //     }

  //   } else {
  //     if(this.occupancyRate.has(value)){
  //       return this.occupancyRate.get(value);
  //     } else {
  //       this.occupancyRate.set(value, this.getOpacity(index));
  //       return this.getOpacity(index);
  //     }
  //   }

  // }

  // getOpacity(index:number): string{
  //   // Adjust the factor and starting value as per your requirement
  //   return (1 - (index * 0.1)).toFixed(2);
  // }
}
