<div class="report-container house-land-vacancy">

    <ng-container>

        <app-report-sub-menu [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
            <ng-container *ngIf="overViewLoading">
                <app-skeleton-loader [reportType]="chartView.overView" [height]="'150px'"
                    ></app-skeleton-loader>
            </ng-container>
        <div class="report-tabsection"  id="{{ houseLandVacancyMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === houseLandVacancyMenuEnum.overview}">
            <div class="report-overview" *ngIf="!overViewLoading">
                <div class="overview">
                    <div class="overview-data">
                        <h2>Total number of Lands</h2>
                        <h1> {{ overViewSummary?.total_land | number:'1.0-1' }}</h1>
                        <div class="untill">

                            <span class="directionLtr">Until {{ overViewSummary?.untillDate }}  </span>
                        </div>
                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}} {{ overViewSummary?.prev_total_land | number:'1.0-1' }}
                            </span>
                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.land_percentage == 0">{{
                            overViewSummary?.land_percentage }} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.land_percentage > 0">+ {{
                                overViewSummary?.land_percentage }} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.land_percentage < 0">{{
                                overViewSummary?.land_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.land_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.land_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="overview">
                    <div class="overview-data">
                        <h2>Developed Houses</h2>
                        <h1>{{ overViewSummary?.developed_house | number:'1.0-1' }}</h1>
                        <div class="untill">

                            <span class="directionLtr">Until {{ overViewSummary?.untillDate }}  </span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}} {{ overViewSummary?.prev_developed_house | number:'1.0-1' }}
                            </span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.developed_house_percentage == 0"> {{
                            overViewSummary?.developed_house_percentage }} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.developed_house_percentage > 0">+ {{
                                overViewSummary?.developed_house_percentage }} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.developed_house_percentage < 0">{{
                                overViewSummary?.developed_house_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.developed_house_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.developed_house_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="overview">
                    <div class="overview-data">
                        <h2>Development Ratio</h2>
                        <h1>{{ overViewSummary?.land_dev_rate | number:'1.0-1' }} %</h1>
                        <div class="untill">

                            <span class="directionLtr">Until {{ overViewSummary?.untillDate }}  </span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}}  {{ overViewSummary?.prev_land_dev_rate | number:'1.0-1' }}
                            </span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.landRate_percentage == 0"> {{
                            overViewSummary?.landRate_percentage }} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.landRate_percentage > 0">+ {{
                                overViewSummary?.landRate_percentage }} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.landRate_percentage < 0">{{
                                overViewSummary?.landRate_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.landRate_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.landRate_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>The count of new houses constructed every year</h3>
                </div>

            </div>
            <!-- <ng-container *ngIf="houserentalChartLoading">
                <app-skeleton-loader [reportType]="chartView.varianceIndicatorsChart"
                ></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!houserentalChartLoading">
                <app-report-charts
                [data]="houserentalChart"
                [chartType]="chartView.varianceIndicatorsChart"
                [name]="'houserentalChart'"
            ></app-report-charts>

            </ng-container> -->




        </div>


         <!-- Average Rent Unit Wise -->
        <div  class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === houseLandVacancyMenuEnum.landParcel}"
            id="{{ houseLandVacancyMenuEnum.landParcel }}">

            <app-report-year-dropdown
                [reportType]="'landVacancy'"
                (selectedYear)="selectedYear($event)"
            ></app-report-year-dropdown>


            <div class="report-model" >

                <div class="report-model-header">
                    <div class="info">
                        <h3>Zoning wise count of land parcels</h3>
                        <span class="totalTransaction">({{ landParcelCount }} Total Land Parcels)</span>
                    </div>

                </div>
                <!-- <ng-container *ngIf="landParcelLoading">
                    <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
                </ng-container> -->
                    <app-report-datatable [showLoader]="!landParcelLoading"
                    [tableColumns]="landParcelColumns"
                    [dataLength]="landParcelCount"
                    [tableData]="landParceldata"
                    (pageEvent)="onPageEvent($event, houseLandVacancyMenuEnum.landParcel)"
                    (sort)="sortData($event, houseLandVacancyMenuEnum.landParcel)"
                    [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'landParcel')"
                    [showEstaterText]="true"
                >
                </app-report-datatable>


            </div>
        </div>


    </ng-container>

</div>
