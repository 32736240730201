export enum PropertyDetailMenuEnum {
    overview = 11,
    builtInformation,
    //costOfconstruction,
    amentities,
    unit,
    unitTransaction,
    propertyTransaction,
    rentalIncome
}
