import { Component, Input } from '@angular/core';
import { ReportPropertyDetailService } from '../../report-property-detail.service';
import moment from 'moment';

@Component({
  selector: 'app-land-value-details',
  templateUrl: './land-value-details.component.html',
  styleUrls: ['./land-value-details.component.scss']
})
export class LandValueDetailsComponent {

  @Input() propertyInfo: any;

  landValueInfo: any = null;
  CurrencyCode = null;

  constructor(private reportPropertyDetailService: ReportPropertyDetailService){}

  ngOnChanges(): void {
    this.CurrencyCode = this.reportPropertyDetailService.CurrencyCode;
    this.getLandValueInfo();
  }

  getLandValueInfo() {
    this.reportPropertyDetailService.getPropertyDetailsLandValue().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.landValueInfo = res.data[0];
          if(this.landValueInfo.land_value_date) this.landValueInfo["land_value_date"]= moment.utc(this.landValueInfo.land_value_date).local().format('DD MMM YYYY')
          if(this.landValueInfo.max_unit_transaction_date) this.landValueInfo["max_unit_transaction_date"]= moment.utc(this.landValueInfo.max_unit_transaction_date).local().format('DD MMM YYYY')
          if(this.landValueInfo.min_unit_transaction_date) this.landValueInfo["min_unit_transaction_date"]= moment.utc(this.landValueInfo.min_unit_transaction_date).local().format('DD MMM YYYY')
          if(this.landValueInfo.max_property_transaction_date) this.landValueInfo["max_property_transaction_date"]= moment.utc(this.landValueInfo.max_property_transaction_date).local().format('DD MMM YYYY')
          if(this.landValueInfo.min_property_transaction_date) this.landValueInfo["min_property_transaction_date"]= moment.utc(this.landValueInfo.min_property_transaction_date).local().format('DD MMM YYYY')
          } else {
            this.landValueInfo = null;
          }
        }
      }
    )
  }
}
