<div class="col-draw-tools" [ngClass]="{'colar-draw-tools' : current_lang == 'ar'  }" *ngIf="polygon_create && catchmentExpandList">
    <h1>{{getSaveFilter() ? getSaveFilter()  : ("map.draw_catchment" | translate)}}</h1>
    <i (click)="catchmentExpand()" class="catchmentCols-icon"></i>
</div>

<div class="draw-tools" *ngIf="polygon_create && !catchmentExpandList">
    <div class="draw-title">
        <h1>{{getSaveFilter() ? getSaveFilter() : ("map.draw_catchment" | translate)}}</h1>
        <div class="set-load">
            <button class="load" (click)="loadSaved()"><i class="load-icon"></i><span>{{'map.load' | translate}}</span></button>
            <i (click)="catchmentExpand()" class="catchmentExpand-icon"></i>
        </div>
    </div>

    <div class="selected-catchment">
        <ng-container *ngFor="let filter of drawnPolygons;let i = index">
            <mat-chip-row class="filter-matChip mr-3"
                [ngClass]="{'darw-chipone': i === 0, 'darw-chiptwo': i === 1, 'darw-chipthree': i === 2, 'darw-chipfour': i === 3}">
                C{{i+1}}
                <button matChipRemove (click)="polygonWKTRemove(filter)" [disabled]="polygon_chip">
                    <mat-icon class="remove-filter">close</mat-icon>
                </button>
            </mat-chip-row>
        </ng-container>
        <div class="save-and-reset" [ngClass]="{'save-and-reset-ar' : current_lang == 'ar'  }">
            <i *ngIf="!getSaveFilter() && drawnPolygons.length > 0 && !catchmentExistingSelected" (click)="resetCatchmentOpen()" class="resetcatchment-icon"></i>
            <i *ngIf="getSaveFilter() && catchmentExistingSelected" (click)="revertCatchmentOpen()" class="revertcatchment-icon"></i>
            <button *ngIf="!catchmentExistingSelected" (click)="savedFilterName($event)"
                [disabled]="drawnPolygons.length === 0" [ngClass]="{'save-disable': drawnPolygons.length === 0 }"
                class="saveCatchement-btn"><i class="saveCatchment-icon"></i>{{'map.save' | translate}} </button>
            <button *ngIf="catchmentExistingSelected" [matMenuTriggerFor]="existingCatchment"
                [disabled]="drawnPolygons.length === 0" [ngClass]="{'save-disable': drawnPolygons.length === 0 }"
                class="saveCatchement-btn"><i class="saveCatchment-icon"></i>{{'map.save' | translate}} </button>
            <mat-menu #existingCatchment="matMenu" xPosition="after" class="existingCatchment">
                <div class="existingCatchment-list">
                    <span (click)="savedFilterName($event, true)">{{'map.overwrite_existing' | translate}}</span>
                    <span (click)="savedFilterName($event)">{{'map.createNewCatchment' | translate}}</span>
                </div>

            </mat-menu>
        </div>

    </div>
</div>
<div class="loader-prog" *ngIf="loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>