import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { HouseLandVacancyMenuEnum } from "./report-house-land-vacancy.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class HouseLandVacancyReportConstant {

    static overViewMenu = [
        {
            label: 'Overview',
            name: HouseLandVacancyMenuEnum.overview
        },
        {
            label: 'Land Parcel',
            name: HouseLandVacancyMenuEnum.landParcel
        }
    ]

    static landParcelColumns: ReportTableColumn[] = [
       
        {
            header: 'neigh_name',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neigh_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'total_no_of_land',
            columnName: 'reports.villaType',
            field: 'total_no_of_land',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'developed_house',
            columnName: 'reports.projectAndCompound',
            field: 'developed_house',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_vacant_villas',
            columnName: 'reports.unitType',
            field: 'total_vacant_villas',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'land_development_ratio',
            columnName: 'reports.avg_buildup_area',
            field: 'land_development_ratio',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        }
    ]
}
