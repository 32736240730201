import { number } from '@amcharts/amcharts4/core';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { AlertService } from 'src/app/common/components/alert/alert.service';

import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-amenties-filter-form',
  templateUrl: './amenties-filter-form.component.html',
  styleUrls: ['./amenties-filter-form.component.scss']
})
export class AmentiesFilterFormComponent implements OnInit {

  @Input() data: string = '';
  @Input() name: any;
  @Input() required: boolean = false;
  @Input() filterData: any;
  @Input() amentiesSelected: string = '';

  formValue!: FormGroup;
  amentiesList: any[][] = [];
  filterValue: string[] = [];
  amentiesData: any[] = [];
  filterIndex: number = -1;
  isEmpty: boolean = false;
  private searchSubject = new Subject<string>();

  radiusList: any[] = []

  constructor(
    private controlContainer: ControlContainer,
    private fb: FormBuilder,
    private reportService: ReportService,
    private alert: AlertService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.formValue = this.controlContainer.control as FormGroup;
    this.radiusList = this.reportService.getDistance();
    this.getAmenties()
    this.getFilterData();
    this.reportService.amentiesData$.subscribe((val) => {
      this.typeForms.clear();
      this.amentiesList = [];
      this.filterValue = [];
      this.preSelected()
    })
  }

  get typeForms() {
    return this.formValue.get('amenties') as FormArray
  }

  getAmenties(searchText: string = '') {
    this.reportService.getAmenties(searchText).subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.amentiesData = res.data;
          if (this.amentiesSelected) {
            this.preSelected();
          } else {
            this.addAmenties()
          }

        } else {
          this.amentiesData = [];
        }
      }, error: (err) => {
        this.amentiesData = [];
      }
    })
  }

  preSelected() {
    const amentiesSelected = this.amentiesSelected.split(',');
    amentiesSelected.length && amentiesSelected.forEach((item: any, index: number) => {
      const val = item.split('*')
      let amenties = this.amentiesData.find((item: any) => item.property_uid == val[1])
      const distance = this.radiusList.find((item: any) => item.value == Number(val[2]))
      if (!amenties) {
        amenties = {
          block_name: "",
          lat: Number(val[3]),
          lon: Number(val[4]),
          neigh_name: "",
          property_cat_subcat: null,
          property_name: val[0],
          property_sub_cat: null,
          property_uid: val[1]
        }

        this.amentiesData.push(amenties);
      }
      this.addAmenties(amenties, distance)

    })
  }

  addAmenties(amenities = '', distance = this.radiusList[0]) {
    this.isEmpty = false;
    const list = this.fb.group({
      name: [amenities],
      distance: [distance]
    })

    this.typeForms.push(list);
    this.amentiesList.push([...this.amentiesData]); // Initialize filtered options
    this.filterValue.push(''); // Initialize filter value
  }

  getFilterData() {
    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => this.reportService.getAmenties(value))
      )
      .subscribe((res: any) => {
        this.amentiesList[this.filterIndex] = res.data
        this.filterIndex - 1;
      });
  }

  closeSelection(index: number) {
    if (this.amentiesList[index].length === 0) {
      this.amentiesList[index] = this.amentiesData;
      this.filterValue[index] = '';
    }

  }

  deleteAmenties(i: number) {
    this.typeForms.removeAt(i)
    this.filterValue.splice(i, 1);
    this.amentiesList.splice(i, 1);
    if (this.typeForms.length === 0) {
      this.filterValue = [];
      this.amentiesList = [];
      this.addAmenties()
    }
  }

  filterOptions(index: number) {
    const value = this.filterValue[index].toLowerCase();
    this.filterIndex = index;
    this.searchSubject.next(value);
  }

  checkSelectedValue(item: any, index: number) {
    const selectedAmenties = this.typeForms.length > 1 && this.typeForms.value.map((val: any) => val.name.property_uid) || [];
    selectedAmenties.splice(index, 1)
    return selectedAmenties.includes(item.property_uid)
  }

}
