<div class="excel-download" [ngClass]="{ 'excel-ar-download': current_lang === 'ar' }">
  <!--- Dropdown option -->
  <div class="table-dropdown" *ngIf="dropDownOption && (tableDataSource.data?.length !== 0 && tableDataSource?.data)">
    <button class="btn-select-dropdown" [matMenuTriggerFor]="menu" [disabled]="LockReport">
      <ng-container *ngIf="!dropDownOption.reportType">
        {{ "reports.show_value_in" | translate }} :
        <span>{{
          selectedRadio
          ? ("reports." + selectedRadio | translate)
          : ("reports." + dropDownOption.default | translate)
          }}</span>
      </ng-container>
      <ng-container *ngIf="dropDownOption.reportType">
       <span> {{ "reports.transactionType" | translate }}</span>
      </ng-container>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [xPosition]="current_lang === 'ar' ? 'after' : 'before'" class="table-dropdown-menu">
      <div (click)="$event.stopPropagation()">
        <h5 *ngIf="dropDownOption.label">
          {{ dropDownOption.label | translate }}
        </h5>
        <mat-radio-group *ngIf="!dropDownOption.reportType" aria-label="Select an option">
          <ng-container *ngFor="let option of dropDownOption.data; let i = index">
            <mat-radio-button (change)="selectRadioValue(option.id, option.value)" value="{{ option.id }}"
              [checked]="option.id === dropDownOption.default">{{ "reports." + option.value | translate
              }}</mat-radio-button>
          </ng-container>
        </mat-radio-group>

        <ng-container *ngIf="dropDownOption.reportType">
          <ng-container *ngFor="let option of dropDownOption.data; let i = index">
            <mat-checkbox
              (change)="selectCheckboxValue($event, option.id, option.value)"
              [checked]="selectedOptions.includes(option.id)"
              [disabled]="option.id == 'Actual'">
              {{ "reports." + option.value | translate }}
            </mat-checkbox>
          </ng-container>
        </ng-container>
      </div>
    </mat-menu>
  </div>
  <!--- Down load icon -->
  <div class="download-excel-outer" *ngIf="
      tableDataSource.data?.length !== 0 &&
      tableDataSource?.data &&
      allowExcelDownload
    ">
    <button class="download_excel" [disabled]="LockReport" *ngIf="!downloadLoading" (click)="downloadExcel()" matTooltip="{{ 'reports.download_excel' | translate }}"
      matTooltipPosition="above"><img *ngIf="!LockReport" src="../../../../../../assets/images/excel-icon.svg" />
      <img *ngIf="LockReport" src="../../../../../../assets/images/excel-icon-disable.svg" /></button>
    <span *ngIf="downloadLoading"><mat-spinner class="download-spinner"></mat-spinner></span>
  </div>
  <!--- Dropdown option -->
</div>


<!--- grid table -->
<div class="grid-wrapper">

  <div class="grid-default" id="tablediv">
    <div class="combined_both">
      <table mat-table matSort multiTemplateDataRows [dataSource]="tableDataSource"
        (matSortChange)="onSortTable($event)" class="mat-elevation-z8">
        <ng-container *ngFor="let tableColumn of tableColumns; index as i">
          <ng-container *ngIf="!tableColumn.hideColumn">
            <ng-container [matColumnDef]="tableColumn.header">

              <!---  Column -->
              <ng-container *ngIf="tableColumn.isSortable; else notSortable">
                <th mat-header-cell *matHeaderCellDef [class.custom-bg-th]="tableColumn.border">
                  <span [mat-sort-header]="tableColumn.header">


                    <ng-container *ngIf="dropDownOption; else noDropDownOption">
                      <span class="header_label">
                        {{ tableColumn?.columnName | translate }}

                        <span class="text-transform-normal text-right mlr-10px" >{{
                          tableColumn?.symbol
                          ? valueDataType(tableColumn?.symbol)
                          : ""
                          }}</span>
                      </span>
                    </ng-container>
                    <ng-template #noDropDownOption>
                      <span class="header_label">
                      {{ tableColumn?.columnName | translate }}

                      <span class="text-transform-normal mlr-10px">{{
                        tableColumn?.symbol
                        ? valueDataType(tableColumn?.symbol)
                        : ""
                        }}</span>
                        </span>

                    </ng-template>
                  </span>
                </th>
              </ng-container>

              <ng-template #notSortable>
                <th mat-header-cell *matHeaderCellDef [class.custom-bg-th]="tableColumn.border">
                  <ng-container *ngIf="dropDownOption; else noDropDownOption">
                    <span class="header_label">
                      {{ tableColumn?.columnName | translate }}

                      <span class="text-transform-normal mlr-10px">{{
                        tableColumn?.symbol
                        ?  valueDataType(tableColumn?.symbol)
                        : ""
                        }}</span>
                    </span>
                  </ng-container>
                  <ng-template #noDropDownOption>
                    <span>{{ tableColumn?.columnName | translate }}</span>



                    <span class="text-transform-normal mlr-10px" [class.break-text]="tableColumn?.isBreak">{{
                      tableColumn?.symbol
                      ?  valueDataType(tableColumn?.symbol)
                      : ""
                      }}</span>
                  </ng-template>
                </th>
              </ng-template>

              <!---  Column -->

              <!---  column data -->
              <ng-container>
                <td mat-cell *matCellDef="let element; let j = dataIndex" [ngClass]="{'blur-content': element['NODATA'], 'custom-conditional-bg-td' : tableColumn?.border}" [class.custom-bg-td]="tableColumn.border"
                  style="position: relative">
                  <ng-container *ngIf="!tableColumn.isContainsHTML">
                    <div class="column-data">
                      <div class="expand-icon" *ngIf="expandColumn && i === 0" [class.d-none]="!element.details">
                        <mat-icon (click)="
                            expandedElement =
                              expandedElement === element ? null : element
                          ">{{
                          expandedElement === element
                          ? "arrow_drop_down"
                          : current_lang === "ar"
                          ? "arrow_left"
                          : "arrow_right"
                          }}</mat-icon>
                      </div>
                      <div class="tooltip" [ngClass]="{'showonmap-column' : (element?.tenants_lat_lon || element?.lat) && tableColumn?.showOnMap,'active' : selectedIndex === j, 'underline_cell': (element?.lat || element?.tenants_lat_lon) && (tableColumn?.tenentShowOnMap || tableColumn?.isTenent),'outlier-td' : true && tableColumn?.columnName === 'reports.property_transaction_rate'}">
                        <span [ngClass]="{'direction': tableColumn?.columnName === 'reports.transactionDate' || tableColumn?.columnName === 'reports.date_of_transaction', 'active_child_layout' : selectedIndex === j && tableColumn?.isTenent && colType == 'layout', 'active_child_tenant' : selectedIndex === j && tableColumn?.tenentShowOnMap && colType == 'tenant', 'outlier-text' : element['outlier'] && tableColumn?.columnName === 'reports.property_transaction_rate', 'tenantname_unit': tableColumn?.isTenent}"
                        class="column_entry"
                        [attr.title]="(tableColumn?.showOnMap && (element?.tenants_lat_lon || element?.lat)) ? element[tableColumn.field] : null"
                        matTooltip="{{
                            isCharNumber(element[tableColumn.toolTipField])
                          }}" [matTooltipDisabled]="
                            showTooltip && element[tableColumn.toolTipField]
                              ? false
                              : true
                          ">
                          {{
                          element[tableColumn.field]
                          ? tableColumn?.convertNumber
                          ? (element[tableColumn.field]
                          | number : "1.0-1") +
                          (tableColumn?.header === "occupancy"
                          ? "%"
                          : "")
                          : element[tableColumn.field] +
                          (tableColumn?.header === "occupancy"
                          ? "%"
                          : "")
                          : (tableColumn?.header === "occupancy") ? '0%' : "-"
                          }}
                          {{
                          element[tableColumn.field] && tableColumn?.valSymbol
                          ? tableColumn?.valSymbol
                          : ""
                          }}</span>

                          <span class="tenent_map_icon icon__ show_on_map" matTooltip="{{'reports.show_floor_plan' | translate}}" [ngClass]="{'clicked_item_layout': !tenantLoading && colType == 'layout' && selectedIndex === j}" *ngIf="element?.lat && tableColumn?.showOnMap && tableColumn?.isTenent && element?.is_layout" (click)="panToMap(element, tableColumn.field, j, 'layout')"><mat-icon svgIcon="layoutIcon" class="icon"></mat-icon></span>
                          <span class="tenent_map_icon loader__" [ngClass]="{'clicked_item': tenantLoading && colType == 'layout' && selectedIndex === j}" *ngIf="(element?.lat || element?.tenants_lat_lon) && tableColumn?.showOnMap && tableColumn?.isTenent"><mat-spinner class="download-spinner"></mat-spinner></span>
                          <!-- <span class="tenent_map_icon icon__ show_on_map" matTooltip="{{'reports.show_on_map' | translate}}" [ngClass]="{'clicked_item': !tenantLoading && colType == 'tenant' && selectedIndex === j}" *ngIf="(element?.lat || element?.tenants_lat_lon) && tableColumn?.showOnMap && tableColumn?.tenentShowOnMap && element?.shop_name" (click)="panToMap(element, tableColumn.field, j, 'tenant')"><mat-icon svgIcon="location" class="icon"></mat-icon></span>
                          <span class="tenent_map_icon loader__" [ngClass]="{'clicked_item': tenantLoading && colType == 'tenant' && selectedIndex === j}" *ngIf="(element?.lat || element?.tenants_lat_lon) && tableColumn?.showOnMap && tableColumn?.tenentShowOnMap"><mat-spinner class="download-spinner"></mat-spinner></span> -->
                          <!-- <span
                            class="show_on_map" matTooltip="{{'reports.show_on_map' | translate}}" (click)="panToMap(element, tableColumn.field)"
                            *ngIf="element?.lat && tableColumn?.showOnMap && element[tableColumn.field] && !tableColumn?.isTenent && !tableColumn?.tenentShowOnMap">
                            <mat-icon svgIcon="location" class="icon"></mat-icon>
                          </span> -->
                          <span class="tenent_map_icon icon__ show_on_map" matTooltip="{{'reports.show_on_map' | translate}}" [ngClass]="{'clicked_item': !tenantLoading && colType == 'showOnMap' && selectedIndex === j}" *ngIf="(element?.lat || element?.tenants_lat_lon) && tableColumn?.showOnMap && element[tableColumn.field] && !tableColumn?.isTenent" (click)="panToMap(element, tableColumn.field, j, 'showOnMap')"><mat-icon svgIcon="location" class="icon"></mat-icon></span>
                          <span class="tenent_map_icon loader__" [ngClass]="{'clicked_item': tenantLoading && colType == 'showOnMap' && selectedIndex === j}" *ngIf="(element?.lat || element?.tenants_lat_lon) && tableColumn?.showOnMap && element[tableColumn.field] && !tableColumn?.isTenent && selectedIndex === j && tenantLoading"><mat-spinner class="download-spinner"></mat-spinner></span>
                          <span class="outlier" *ngIf="tableColumn?.columnName === 'reports.property_transaction_rate' && element['outlier']"> {{'reports.outlier' | translate}} </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tableColumn.isContainsHTML">
                    <span class="htmlView" *ngIf="element[tableColumn.field]"
                      [innerHtml]="element[tableColumn.field] | safeHtml"></span>
                    <span class="htmlView" *ngIf="!element[tableColumn.field]" [innerHtml]="'-'"></span>
                  </ng-container>
                </td>
              </ng-container>
              <!---  column data -->


            </ng-container>
          </ng-container>
        </ng-container>

        <!--- Expand td Column -->
        <ng-container matColumnDef="expandedDetail" *ngIf="expandColumn">
          <td class="expand-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="expand-element-detail" [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              ">
              <table [style.background-color]="expandbgColor">
                <!-- <ng-container *ngFor="let tableColumn of tableColumns; index as i">


                    </ng-container> -->
                <ng-container *ngIf="element.details">
                  <tr *ngFor="let child of element.details" [ngClass]="{ 'outlier-row':child['outlier']}">
                    <td *ngFor="let expandCol of tableExpandColumns" [ngClass]="{
                        'expand-td-item':
                          !currentToggle && currentReportView == 2,
                          'custom-conditional-bg-td' : expandCol?.contentBg
                      }">
                      <!-- <span *ngIf="expandCol.convertNumber">{{ child[expandCol.field] ? (child[expandCol.field] | number : "1.0-1") : '-'}}</span>
                    <span *ngIf="!expandCol.convertNumber">{{ child[expandCol.field] ? child[expandCol.field] : '-' }}</span> -->
                    <ng-container *ngIf="!expandCol?.isContainsHTML">
                      <div class="column-data" [ngClass]="{'expand-column-data': child['outlier']}">
                        <div [ngClass]="{'direction' : expandCol.field === 'transaction_date', 'outlier-text': expandCol.field === 'transaction_rate' && child['outlier']}" *ngIf="!expandCol?.blankColumn" class="expand-data">
                          {{
                          child[expandCol.field]
                          ? expandCol?.convertNumber
                          ? (child[expandCol.field] | number : "1.0-1") +
                          (expandCol?.field === "occupancy" ? "%" : "")
                          : child[expandCol.field] +
                          (expandCol?.field === "occupancy" ? "%" : "")
                          : "-"
                          }}
                        </div>
                        <span class="outlier" *ngIf="expandCol.field === 'transaction_rate' && child['outlier']"> {{'reports.outlier' | translate}} </span>
                      </div>

                    </ng-container>
                    <ng-container *ngIf="expandCol?.isContainsHTML">
                      <ng-container *ngIf="expandCol?.isContainsHTML">
                        <span class="htmlView" *ngIf="element[expandCol?.field]"
                          [innerHtml]="element[expandCol?.field] | safeHtml"></span>
                        <span class="htmlView" *ngIf="!element[expandCol?.field]" [innerHtml]="'-'"></span>
                      </ng-container>

                    </ng-container>

                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </td>
        </ng-container>
        <!--- Expand td Column -->

        <!--- normal td Column -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let j = dataIndex" class="example-element-row"
          (click)="setExpadndColor(j)"
          [style.background-color]="row['outlier'] ? '#FFE1E1' : (j % 2 === 0 && expandedElement !== row) ? '#F5F5F5' : '#FFFFFF'"
          [ngClass]="(expandedElement === row && !row['outlier'] ? 'example-expanded-row':'')"
          [ngClass]="{ hideTable: !showLoader }"></tr>
        <!--- normal td Column -->

        <!--- Expand tr Column -->
        <ng-container *ngIf="expandColumn">
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </ng-container>
        <!--- Expand tr Column -->
      </table>

      <!--- No Record Found -->
      <div class="data_unavail" *ngIf="tableDataSource.data?.length === 0 || !tableDataSource?.data">
        <span>
            {{"reports.no_record_found" | translate}} <!-- <mat-icon role="img"
                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon> -->
        </span>
    </div>

      <!--- No Record Found -->
      <!-- Table content blur start -->
      <div class="lock-table-row" *ngIf="LockReport">
        <img src="../../../../../../assets/images/Lock.png" alt="">
      </div>
      <!-- Table content blur end -->
    </div>
    <div id="loaderDiv" *ngIf="!showLoader && tableDataSource.data">
      <app-skeleton-loader [loaderSize]="tableDataSource.data.length / 2"></app-skeleton-loader>
    </div>
  </div>



  <!--- Pagination -->
  <div class="pagination-wrapper pagination-showing-count"
    *ngIf="tableDataSource.data?.length !== 0 && tableDataSource?.data && !hidePagination">
    <p *ngIf="!LockReport" [class.showingrecord-margin]="dataLength > 2">
      {{ "reports.Showing" | translate }} {{ startIndex }}
      {{ "reports.to" | translate }} {{ endIndex }}
      {{ "reports.outOf" | translate }}
      {{ reportService.formatInternationNum(dataLength) }}
      {{ "reports.records" | translate}}
    </p>
    <p *ngIf="LockReport" class="pb-2">
      <span class="contact-sale" (click)="openContactSales()">{{'contactSales' | translate }}</span>
      {{'to_unlock' | translate }} <ng-container *ngIf="dataLength > 3"> {{reportService.formatInternationNum(dataLength - 3) }} {{'rows_more'| translate}}</ng-container> <ng-container *ngIf="dataLength <= 3">{{'more_rows'| translate}}</ng-container>.
    </p>

    <mat-paginator *ngIf="dataLength > 20" [disabled]="!showLoader || LockReport" class="default-pagination" [length]="dataLength" [pageSize]="20"
      [pageSizeOptions]="[5, 10, 15, 25, 100]" [showFirstLastButtons]="true" (page)="onPageChange($event)"
      [hidePageSize]="true">
    </mat-paginator>

    <!-- Pagination input -->
    <div class="go-to-page d-flex align-items-center" *ngIf="dataLength > 20">
      <input class="go-to-page-input" type="number" [(ngModel)]="goToPageNumber" (change)="onGoToPage()"
        [disabled]="totalPages === 1 || !showLoader || LockReport" />
      <span class="oftext">of</span>
      <span>{{ totalPages }}</span>
    </div>
  </div>
  <!--- Pagination -->

  <!--- data cleaned Text -->
  <div class="dataCleaned">
    <span>
      <ng-container *ngIf="showEstaterText">{{
        "reports.dataCleaned" | translate
        }}</ng-container>
    </span>
    <!-- <span>
      <ng-container *ngIf="dropDownOption?.reportType">{{"reports.likely_bulk_transaction" | translate}}</ng-container>
    </span> -->

  </div>

  <!--- data cleaned Text -->
</div>


<!--- grid table -->
