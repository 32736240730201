import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesEnum } from 'src/app/common/models/enums/common.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SEOService } from 'src/app/common/services/SEOService.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  isBrowser: any;
  langList!: number;
  currentLangID: number = LanguagesEnum.english;
  isVideoModalOpen = false;
  youtubeUrl: SafeResourceUrl | null = null;
  isMobile: boolean = false;
  windowWidth: number | any;
  tabletScreenWidth = 1080;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sharedService: SharedService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private _seoService: SEOService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit(): void {
    this.dialog.closeAll();
    this.checkIfMobile();
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.setLang();
      this.sharedService.language$.subscribe(() => {
        this.langList = this.sharedService.languageList()?.length;
      }
    );
    }
   this.windowWidth = window.innerWidth;
  }
  checkIfMobile(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  setLang(): void {
    this.currentLangID = this.sharedService.getCurrentLangID();
    this.sharedService.language$.subscribe(lang => {
      this.currentLangID = this.sharedService.getCurrentLangID();
      this._seoService.updateTitle((this.currentLangID === 1) ? 'Estater Markets' :'استيتر أسواق');
    });
  }

  openVideoModal(videoId: string): void {
    this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}`);
    this.isVideoModalOpen = true;
  }

  closeVideoModal(): void {
    this.isVideoModalOpen = false;
    this.youtubeUrl = null; // Clear the URL when the modal is closed
  }

  slideConfig: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 3000,
    dots: false,
    nav: false,
    center: true,
    margin: 20,
    responsive: {
      0: {
        items: 1.2, // Show 1 item below 768px
        dots: false,
        nav: false,
        center: false,
      },
      768: {
        items: 2.1, // Show 2 items from 768px to 992px
        dots: false,
        nav: false,
        center: false,
      },
      992: {
        items: 2.5, // Show 3 items above 992px
        dots: false,
        nav: false,
        center: false,
      }
    }
  };
  
  slideConfig1: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 3000,
    dots: true,
    nav: false,
    margin: 35,
    items: 1
  };




  processItems = [
    {
      title: 'landing.data_cleaning',
      isOpen: true,
      details: [
        'landing.data_cleaning_para1',
        'landing.data_cleaning_para2',
        'landing.data_cleaning_para3'
      ]
    },
    {
      title: 'landing.data_correction',
      isOpen: false,
      details: ['landing.data_correction_para1',
        'landing.data_correction_para2',
        'landing.data_correction_para3']
    },
    {
      title: 'landing.data_integration',
      isOpen: false,
      details: ['landing.data_integration_para1',
        'landing.data_integration_para2',
        'landing.data_integration_para3',
        'landing.data_integration_para4'
      ]
    },
    {
      title: 'landing.quality_assurance',
      isOpen: false,
      details: ['landing.quality_assurance_para1',
        'landing.quality_assurance_para2',
        'landing.quality_assurance_para3']
    }
  ];

  toggleAccordion(index: number): void {
    this.processItems.forEach((item, i) => {
      if (i === index) {
        item.isOpen = !item.isOpen;
      } else {
        item.isOpen = false;
      }
    });
  }

  testimonials = [
    {
      name: 'John Doe',
      company: 'Estater Ventures',
      text: 'Estater Markets is a great  tool for generati reports. Our team just loves the software Estater Markets is a great  tool for generati reports. Our team just loves the software',
    },
    {
      name: 'John Doe',
      company: 'Estater Ventures',
      text: 'Estater Markets is a great  tool for generati reports. Our team just loves the software Estater Markets is a great  tool for generati reports. Our team just loves the software',
    },
    {
      name: 'John Doe',
      company: 'Estater Ventures',
      text: 'Estater Markets is a great  tool for generati reports. Our team just loves the software Estater Markets is a great  tool for generati reports. Our team just loves the software',
    },
    {
      name: 'John Doe',
      company: 'Estater Ventures',
      text: 'Estater Markets is a great  tool for generati reports. Our team just loves the software Estater Markets is a great  tool for generati reports. Our team just loves the software',
    },
    {
      name: 'John Doe',
      company: 'Estater Ventures',
      text: 'Estater Markets is a great  tool for generati reports. Our team just loves the software Estater Markets is a great  tool for generati reports. Our team just loves the software',
    },
  ];

  dataSources = [
    {
      icon: 'assets/images/landing_page/distance.svg',
      alt: 'Municipalities',
      title: 'landing.land_data',
      subtitle: 'landing.land_data_subtitle',
      description: 'landing.land_data_para'
    },
    {
      icon: 'assets/images/landing_page/contract.svg',
      alt: 'Transactions Data',
      title: 'landing.transactions_data',
      subtitle: 'landing.transactions_data_subtitle',
      description: 'landing.transactions_data_para'
    },
    {
      icon: 'assets/images/landing_page/travel_explore.svg',
      alt: 'Land Vacancy & Inventory Data',
      title: 'landing.land_vacancy',
      subtitle: 'landing.land_vacancy_subtitle',
      description: 'landing.land_vacancy_para'
    },
    {
      icon: 'assets/images/landing_page/group.svg',
      alt: 'Population & Demographics',
      title: 'landing.population_demographics',
      subtitle: 'landing.population_demographics_subtitle',
      description: 'landing.population_demographics_para'
    },
    {
      icon: 'assets/images/landing_page/storefront.svg',
      alt: 'Commercial Tenant Franchise',
      title: 'landing.commercial_tenant',
      subtitle: 'landing.commercial_tenant_subtitle',
      description: 'landing.commercial_tenant_para'
    },
    {
      icon: 'assets/images/landing_page/location_home.svg',
      alt: 'Rental Data',
      title: 'landing.rental_data',
      subtitle: 'landing.rental_data_subtitle',
      description: 'landing.rental_data_para'
    },
    {
      icon: 'assets/images/landing_page/real_estate_agent.svg',
      alt: 'Property Data',
      title: 'landing.property_data',
      subtitle: 'landing.property_data_subtitle',
      description: 'landing.property_data_para'
    },
    {
      icon: 'assets/images/landing_page/trial.svg',
      alt: 'Start your 15 day trial',
      title: 'landing.start_trial',
      description: ''
    }
  ];

  gisSteps = [
    {
      number: "01",
      title: "landing.collect_data",
      details: [
        "landing.collect_data_para1",
        "landing.collect_data_para2"
      ]
    },
    {
      number: "02",
      title: "landing.digitize_ensure_quality",
      details: [
        "landing.digitize_ensure_quality_para1",
        "landing.digitize_ensure_quality_para2"
      ]
    },
    {
      number: "03",
      title: "landing.perform_layered",
      details: [
        "landing.perform_layered_para1",
        "landing.perform_layered_para2"
      ]
    },
    {
      number: "04",
      title: "landing.create_interactive",
      details: [
        "landing.create_interactive_para1",
        "landing.create_interactive_para2"
      ]
    },
    {
      number: "05",
      title: "landing.publish_make",
      details: [
        "landing.publish_make_para1",
        "landing.publish_make_para2"
      ]
    }
  ];

  sectors = [
    {
      imageSrc: "assets/images/landing_page/retail.svg",
      altText: "Retail and F&B",
      name: "landing.retail_and_fb",
      description: "landing.retail_and_fb_para"
    },
    {
      imageSrc: "assets/images/landing_page/industrial.svg",
      altText: "Industrial",
      name: "landing.industrial",
      description: "landing.industrial_para"
    },
    {
      imageSrc: "assets/images/landing_page/investment.svg",
      altText: "Investment Apartment",
      name: "landing.apartment_units",
      description: "landing.apartment_units_para"
    },
    {
      imageSrc: "assets/images/landing_page/officespace.svg",
      altText: "Office Space",
      name: "landing.office_space",
      description: "landing.office_space_para"
    },
    {
      imageSrc: "assets/images/landing_page/houseandvilla.svg",
      altText: "House and Villa",
      name: "landing.house_and_villa",
      description: "landing.house_and_villa_para"
    },
    {
      imageSrc: "assets/images/landing_page/hospitility.svg",
      altText: "Hospitality",
      name: "landing.hospitality",
      description: "landing.hospitality_para"
    }
  ];

  reports = [
    {
      imageSrc: "assets/images/landing_page/inventory-icon.svg",
      altText: "checklist icon",
      title: "landing.inventory_report",
      description: "landing.inventory_report_para"
    },
    {
      imageSrc: "assets/images/landing_page/Occupancy&Lease-Report.svg",
      altText: "occupancy icon",
      title: "landing.occupancy_lease_report",
      description: "landing.occupancy_lease_report_para"
    },
    {
      imageSrc: "assets/images/landing_page/Units-icon.svg",
      altText: "units icon",
      title: "landing.units_report",
      description: "landing.units_report_para"
    },
    {
      imageSrc: "assets/images/landing_page/TransactionsReport.svg",
      altText: "transaction icon",
      title: "landing.transactions_report",
      description: "landing.transactions_report_para"
    },
    {
      imageSrc: "assets/images/landing_page/Property-Profiling-Report.svg",
      altText: "checklist icon",
      title: "landing.property_profiling_report",
      description: "landing.property_profiling_report_para"
    },
    {
      imageSrc: "assets/images/landing_page/Vector.svg",
      altText: "get started icon",
      title: "landing.get_started",
      description: "landing.get_started_para",
      isGetStarted: true,
       link: "/sign-up"
    }
  ];

  unlockWorldDetails = [
    {
      title: "landing.download_save",
      description: "landing.download_save_para"
    },
    {
      title: "landing.customizable_report",
      description: "landing.customizable_report_para"
    },
    {
      title: "landing.comprehensive_market",
      description: "landing.comprehensive_market_para"
    },
    {
      title: "landing.refine_reports",
      description: "landing.refine_reports_para"
    }
  ];
}
