<div class="report-bottom">
    <!-- <div class="legendContainer" *ngIf="!transaction_layer_active && legend_data && viewType.gridView != currentReportView" -->
        <div class="legendContainer" *ngIf="!transaction_layer_active && legend_data_by_group && viewType.gridView != currentReportView"
        [ngStyle]="{'left': current_lang == 'en' ? '10px':'','right': current_lang == 'ar' ? '10px':''}">
        <span class="mapLegend">{{'reports.legend' | translate}} (<span *ngIf="!transaction_layer_active">{{layerselected}}</span>) : </span>
        <!-- <div *ngFor="let legend of legend_data.data; let idx = index; let isLast=last;" class="mapLegend">
            <div *ngIf="legend_data.type != 'number' && !transaction_layer_active" class="child">
                <img src="{{returnVal(legend_data.data[idx],'src',legend_data)}}" alt="legend" class="legendImg"><span
                    class="legendText">{{returnVal(legend_data.data[idx],'text')}}</span>
            </div>
            <div *ngIf="legend_data.type == 'number' && !isLast && !transaction_layer_active">
                <img src="{{returnVal(legend_data.data[idx]+'-'+legend_data.data[idx+1],'src',legend_data)}}"
                    alt="legend" class="legendImg"><span class="legendText">
                    {{returnVal(legend_data.data[idx],'text')}}
                    {{'sqm_unit' | translate}}. -
                    {{returnVal(legend_data.data[idx+1],'text')}} {{'sqm_unit' | translate}}.</span>
            </div>
            <div *ngIf="legend_data.type == 'number' && isLast && !transaction_layer_active">
                <img src="{{returnVal('>'+legend_data.data[idx],'src',legend_data)}}" alt="legend" class="legendImg">
                <span class="legendText">{{returnVal(legend_data.data[idx],'text')}}
                    {{'sqm_unit' | translate}}. +</span>
            </div>
        </div> -->
        <div class="map_legend_container">
            <div *ngFor="let legend of legend_data_by_group?.data; let idx = index; let isLast=last;" class="mapLegend">
                <div class="child">
                    <span class="legendcolor" [ngStyle]="{'background-color': legend.color}"></span>
                    <span class="legendText" title="{{legend?.label?.[1]}}">{{ legend?.label?.[1] }}</span>
                </div>
            </div>
        </div>
        <span class="legendBtn" *ngIf="legend_data_by_group?.data?.length" (click)="legendExpand()">{{"reports.show-all" |
            translate}}</span>
    </div>
    <div class="legendContainer" *ngIf="transaction_layer_active"
        [ngStyle]="{'left': current_lang == 'en' ? '4px':'90%'} ">
        <span class="mapLegend">{{'reports.legend' | translate}} (<span *ngIf="transaction_layer_active">-</span>) : </span>
        <div *ngIf="transaction_layer_active">
            &emsp; <span class="legendText">-</span> &emsp; <span class="legendText">-</span>
            &emsp; <span class="legendText">-</span> &emsp; <span class="legendText">-</span>
        </div>
    </div>
    <div class="report-view-type" *ngIf="isReport">
        <ul>
            <!-- <li class="fullscreenView" (click)="mapFullscreen()"></li>   Have commented for now until User Story has been cleared -->
            <li class="mapView" [ngClass]="{ 'active': viewType.mapView === currentReportView }"
                (click)="changeView(viewType.mapView)" matTooltip = "{{'map_view' | translate}}"></li>
            <li class="mapGridView" [ngClass]="{ 'active': viewType.mapGridView === currentReportView }"
                (click)="changeView(viewType.mapGridView)" matTooltip = "{{'map_&_report_view' | translate}}"></li>
            <li class="gridView" [ngClass]="{ 'active': viewType.gridView === currentReportView }"
                (click)="changeView(viewType.gridView)" matTooltip = "{{'report_view' | translate}}"></li>
        </ul>
    </div>

    <ng-container>
        <div class="slider" [ngClass]="{ 'disabl_slider': viewType.gridView === currentReportView }">
            <span class="zoomBtn" (click)="mapZoomChanger('zoom-out')">-</span>
            <input #mapZoomSlide type="range" min="4" max="18" value="11" (change)="mapZoomChanger($event)"
                [disabled]="viewType.gridView === currentReportView" />
            <span class="zoomBtn" (click)="mapZoomChanger('zoom-in')">+</span>
        </div>
        <span class="mapLayer">{{(location_Name | lowercase) | translate}}</span>
    </ng-container>

</div>
