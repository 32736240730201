import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { DataType } from "src/app/common/models/enums/common.enum";
import { OccupancyRentalBasicMenuEnum } from "./report-occupancy-rental-basic.enum";

export class ReportOccupancyRentalBasicConstants {
    static overViewMenu = [
        {
            label: 'reports.overview',
            name: OccupancyRentalBasicMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.residential_units_type_wise',
            name: OccupancyRentalBasicMenuEnum.residentialUnitsTypeWise,
            count: null
        },
        {
            label: 'reports.commercial_units_size_wise',
            name: OccupancyRentalBasicMenuEnum.commercialUnitsSizeWise,
            count: null
        }
    ]
    static residentialTypeColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },

        {
            header: 'average_size',
            columnName: 'reports.AverageSize',
            field: 'average_size',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'rentals_as_lumpsum',
            columnName: 'reports.rentals_as_lumpsum',
            field: 'rentals_as_lumpsum',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        },
        {
            header: 'rental_price',
            // columnName: 'reports.monthly_rentals',
            columnName: 'reports.monthly_rental_rate',
            field: 'rental_price',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter,
        }
    ]
    static residentialTypeExpandColumns: any[] = [
        { field: 'unit_type', convertNumber: false },
        { field: 'occupancy', convertNumber: true },
        { field: 'average_size', convertNumber: true },
        { field: 'rentals_as_lumpsum', convertNumber: true },
        { field: 'rental_price', convertNumber: true }
    ]
    static commercialSizeColumns: ReportTableColumn[] = [
        {
            header: 'neighbourhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighbourhood',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
        },
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
        {
            header: 'commercialRetail',
            columnName: 'retail',
            field: 'retail',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'commercial',
            columnName: 'reports.commercial',
            field: 'commercial',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'clinics',
            columnName: 'reports.clinics',
            field: 'clinics',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        },
        {
            header: 'professional',
            columnName: 'reports.professional',
            field: 'professional',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter
        }
    ]
    static commercialSizeExpandColumns: any[] = [
        { field: 'unit_size', convertNumber: false },
        { field: 'occupancy', convertNumber: true },
        { field: 'retail', convertNumber: true },
        { field: 'commercial', convertNumber: true },
        { field: 'clinics', convertNumber: true },
        { field: 'professional', convertNumber: true }
    ]
}