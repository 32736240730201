import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import * as pbi from 'powerbi-client';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-pbi-visual-container',
  templateUrl: './pbi-visual-container.component.html',
  styleUrls: ['./pbi-visual-container.component.scss']
})
export class PbiVisualContainerComponent {

  @Input() itemId: any;
  @Input() main_data: any;
  @Input() embedData: any;
  @Input() params: any;

  visuals: any[] = [];
  visualsGrFilter: any[] = [];
  powerBiReport: any;

  @ViewChild('visualOne', { static: false }) visualOne!: ElementRef;
  
  constructor(private reportService: ReportService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getVisualFilter();
    }
    if (changes['embedData'] && changes['embedData'].currentValue && !changes['embedData'].firstChange) {
      const powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.embedVisuals(powerbiService, this.embedData.embedUrl, this.embedData.token);
    }

  }


 async getVisualFilter() {
    //Basics filter
    let basicFilter: any = await this.createFilterJson();
    
    // Filter out any objects where values are empty or undefined
    basicFilter = basicFilter.filter((filter: any) => filter.values && filter.values.length > 0);

    this.visualsGrFilter.forEach(visual => {
      visual.setFilters(basicFilter);
    });
  }

  embedVisuals(powerbiService: any, embedUrl: any, accessToken: any) {
    // Create configuration for each visual
    const visualConfig = {
      type: 'visual',
      id: this.main_data.pb_report_id,
      embedUrl: embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      pageName:  this.main_data.pb_page_id, // "ff4e7fd69b75a04602f1", // Name of the page
      visualName:  this.itemId.visualId, // "714c773b4a41417284c3",
      Settings: {
        background: models.BackgroundType.Transparent,
        interactionsEnabled: true,
        filterPaneEnabled: true
      }
    };

    // Embed the visual
    this.powerBiReport = powerbiService.embed(this.visualOne.nativeElement, visualConfig);
    this.visualsGrFilter.push(this.powerBiReport);

    // Set up an event listener for the 'loaded' event
    this.powerBiReport.on('loaded', async () => {
      this.getVisualFilter();
    });

  }


  convertCommaFilter(name: any) {
    if (name) return name.split(',');
  }


   createFilterJson(){
    return new Promise((resolve, reject) => {     
      let paramsFilters = {...this.params};
      delete paramsFilters.rid;
      delete paramsFilters.oid;
      delete paramsFilters.gid;
      delete paramsFilters.zoom;
      delete paramsFilters.centroid;
      delete paramsFilters.polygon;
      delete paramsFilters.srid;
  
      let basicFilter:any = [];
       if(this.reportService.reportFilter?.length > 0){
        for(let key in paramsFilters){
          let data = paramsFilters[key];
          if(key == 'year'){
            data = (paramsFilters['year']?.split(',') || [new Date().getFullYear()]).map((y: any) => Number(y));
          }else{
            data = paramsFilters[key].includes(',') ? paramsFilters[key]?.split(',') : [paramsFilters[key]]
          }
    
          let filter = this.reportService.reportFilter.find((f:any)=> f.parameter_name == key);
          if(filter){
            basicFilter.push({
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: filter.table,
                    column: filter.col_name
                  },
                  operator: "In",
                  values: data
                })
          }
         }
        resolve(basicFilter)
       }else{
        resolve(basicFilter)
       }
      
    })
    
  }
}
