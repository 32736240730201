import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { ReportsViewEnum } from '../../models/enums/reports.enum';
import { ReportService } from '../../services/report.service';
import { ReportMapService } from '../../services/report-map.service';
import { GlobalContants } from '../../../common/constants/global.contants';
import { SharedService } from '../../../common/services/shared.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-map-legend',
  templateUrl: './report-map-legend.component.html',
  styleUrls: ['./report-map-legend.component.css']
})
export class ReportMapLegendComponent implements OnInit, OnDestroy {

  viewType = ReportsViewEnum;
  currentReportView: number = ReportsViewEnum.mapView;
  @ViewChild('mapZoomSlide') mapZoomSlide!: ElementRef;
  selectReportSub$: Subscription = new Subscription();
  reportViewSub$: Subscription = new Subscription();
  isReport: boolean = false;
  location_Name: any = 'Neighborhood Name';
  layerselected: number = 1;
  legend_data: any;
  current_lang: any = 'en';
  public transaction_layer_active: boolean = true;
  public layerData: any = [];
  public layer_year = new Date().getFullYear();

  constructor(private reportService: ReportService, private mapService: ReportMapService, private sharedService: SharedService,
    private translate: TranslateService
  ) {
    if (this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName))
      this.location_Name = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName).split('-')[0];
    this.sharedService.selectedCity$.subscribe((city: any) => {
      this.location_Name = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName).split('-')[0];
    })
    this.sharedService.language$.subscribe((lang) => {
      this.current_lang = lang;
    });
    this.reportService.selectedReport$.subscribe((data) => {
      this.transaction_layer_active = !data.flag;
      if (data.flag) {
        let layer_data = this.reportService.getCurrentReport();
        if (layer_data) {
          this.layerData = layer_data.layer;
          if (this.layerData) this.mapLegendmenu();
        }
      }
    })
  }

  ngOnInit(): void {
    this.mapService.mapZoom.subscribe((zoom: any) => { if (this.mapZoomSlide) this.mapZoomSlide.nativeElement.value = zoom });
    this.reportSet();
    this.reportView();
    // this.mapService.transaction_LayerActive.subscribe((toggle: boolean) => {
    //   this.transaction_layer_active = toggle;
    // })
  }

  reportSet() {
    this.selectReportSub$ = this.reportService.selectedReport$.subscribe((isReport: any) => {
      this.isReport = isReport.flag;
      this.currentReportView = this.isReport ? ReportsViewEnum.mapGridView : ReportsViewEnum.mapView;
      this.reportService.reportView$.next(this.currentReportView);
    });
  }

  reportView() {
    this.reportViewSub$ = this.reportService.reportView$.subscribe(view => {
      this.currentReportView = view;
    })
  }

  changeView(view: number): void {
    if(this.currentReportView !== view){
      this.currentReportView = view;
      this.reportService.reportView$.next(view);
    }
  }

  mapZoomChanger(event: any) {
    let zoomval: any;
    this.mapService.mapZoom.subscribe((zoom: any) => { zoomval = zoom; });

    if (event.target?.value) zoomval = parseInt(event.target.value);
    else if (event == 'zoom-in') zoomval = parseInt(zoomval) + 1;
    else if (event == 'zoom-out') zoomval = parseInt(zoomval) - 1;

    this.mapService.setZoom(zoomval);
    this.mapZoomSlide.nativeElement.value = zoomval;
  }

  mapLegendmenu() {
    this.legend_data = this.layerData.legend.filter((r: any) => r.display == true)[0];
    this.mapService.legendLayer.subscribe((data: any) => {
      this.layerselected = data.length;
      this.legend_data = data[0];
    })
  }

  ngOnDestroy(): void {
    this.selectReportSub$?.unsubscribe();
  }

  legendExpand() {
    this.mapService.legendToggle.next(true);
  }

  returnVal(value: string, type: string, legend_obj?: any) {
    if (type == 'text') {
      return this.translate.instant(value.replace(/_/g, " ").toLowerCase());
    } else if (type == 'src') {
      let queryParam = this.reportService.queryParams;
      if (queryParam?.year) {
        this.layer_year = queryParam?.year.split(',').map(Number)
          .sort(function (a: any, b: any) { return a - b }).pop();
      } else if (!queryParam?.year) {
        this.layer_year = new Date().getFullYear();
      }
      let style_name;
      let place_id = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId);
      let store_name = this.layerData?.store_name;
      let layer_name = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${this.layerData?.layer_name}_${this.layer_year}`;
      if (legend_obj.type != 'like') style_name = `style_Layer_${legend_obj.type}`;
      else style_name = `style_Layer_${legend_obj.legend}`;

      let url = `${environment.geoestater}wms-layer?place_id=${place_id}&token=${environment.user_token}&request=GetLegendGraphic&layer=${layer_name}` +
        `&style=${style_name}&width=20&height=20&store=${store_name}&rule=${value}`;
      return url;
    } else {
      return value;
    }
  }

  mapFullscreen() {
    this.mapService.mapFullscreenToggle.next(true);
  }

}
