import { Injectable } from '@angular/core';

import { ApiService } from '../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../common/constants/api-endpointsconstant';
import { ReportService } from '../../services/report.service';


@Injectable({
  providedIn: 'root'
})
export class ReportTenantsMapService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService,

  ) { }


  getUnits(property: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    } else {
      queryParams['year'] = new Date().getFullYear();
    }
    const property_uid = property.property_uid;//'KWTLA00012'; property UID for testing from (AL Baik tenant)
    const shop_name = property.shop_name?.toLowerCase();
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.retailInventoryTenantFloorDetail}${changeParams}&property_uid=${property_uid}`;
    if(shop_name) url+= `&shop_name=${shop_name}`
    return this.apiServive.get(url, false, '1');
  }

  tenantColor(){
    return  {
      fillColor: "#4773E0",
      color: "#ffffff",
      weight: 2,
      opacity: 1,
      fillOpacity: 1

    }
  }

  vacantColor(){
    return {
      fillColor: "#ffffff",
      color: "#707070",
      weight: 4,
      opacity: 1,
      fillOpacity: 0.8
    }
  }

  filledTenantColor(){
    return  {
      fillColor: "#BEBEBE",
      color: "#ffffff",
      weight: 2,
      opacity: 1,
      fillOpacity: 1

    }
  }


}
