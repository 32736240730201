import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: 'app-child-breadcum',
    template: `<div class="back-transaction">
    <span (click)="backToTransaction()">
        {{'reports.mainReport' | translate }}
    </span>
    <ng-container *ngIf="property_uid">
        <mat-icon>keyboard_arrow_right</mat-icon><span class="pro-name">{{ property_uid }}</span>
    </ng-container>
</div>`,
})
export class ChildBreadcumComponent {

    @Input() property_uid:string='';
    @Output() back = new EventEmitter();

    backToTransaction(){
        this.back.emit()
    }
}