import { Component, HostListener, OnInit } from '@angular/core';
import { StoreService } from 'src/app/common/services/store.service';

@Component({
  selector: 'app-poc-container',
  templateUrl: './poc-container.component.html',
  styleUrls: ['./poc-container.component.scss']
})
export class PocContainerComponent implements OnInit {

  containers: any = [];

  constructor(private storeService: StoreService){

  }

  ngOnInit(): void {
    this.containers = this.storeService.get('widget') || [];
  }

  mainadd() {
    const obj = {
      label:'',
      subcontainers:[],
      type:'',
      overviewCount: 0,
    }
    this.containers.push(obj);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(): void {
    this.storeService.add('widget', this.containers);
  }

  // subadd(index: any) {
  //   this.containers[index].subcontainers.push(1)
  // }

  subOverview(index: any, subIndex: any){

  }


  dataChanged(ind: any){
    let container = this.containers[ind];
    if(container.overviewCount > 0){
      this.containers[ind].subcontainers = [];
      for(let i = 0; i < container.overviewCount; i++){
        let obj = {
          name: 'count'
        }
        this.containers[ind].subcontainers.push(obj)
      }

    }
    
  }

  selectionOverview(event: any, ind: any , j: any){
    console.log(event.value)
    this.containers[ind].subcontainers[j].name = event.value
  }
}
