<div class="container">
  <div class="upper-container">
    <div class="upper-container-content">
      <img src="../../../../assets/images/Estater-banner-en-logo.svg" alt="logo" *ngIf="currentLangID == 1; else arlogo">
      <ng-template #arlogo>
        <img src="../../../../assets/images/Estater-banner-ar-logo.svg" alt="logo">
      </ng-template>
      <span>{{'best_solution_in_the_market' | translate}}</span>
    </div>
  </div>
  <div class="lower-container">
    <img src="../../../../assets/images/ReportBanner.svg" alt="">
  </div>
</div>
