import {
  Component,
  AfterViewInit,
  Inject,
  NgZone,
  PLATFORM_ID,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-line-series-chart',
  templateUrl: './line-series-chart.component.html',
  styleUrls: ['./line-series-chart.component.scss']
})
export class LineSeriesChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  
  private root: am5.Root | undefined;
  @Input() data: any = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root) {
      this.root.dispose();
    }
   this.createChart()
  }

  createChart(){
    this.browserOnly(() => {
      let root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        })
      );
      
      
      
      // The data
      var data = this.data;
      

      var xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true
      });
    
      xRenderer.grid.template.set('forceHidden', true);

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );


     
      xAxis.data.setAll(data);
      
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          min: 0,
          numberFormat: "#'%'",
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );


      var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "none"
      }));
      cursor.lineY.set("visible", false);
      
     
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: "category",
            tooltip: am5.Tooltip.new(root, {
              labelText: "[bold]{categoryX}: {valueY}%"
            })
          })
        );

       
      
      
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 3,
              fill:am5.color(0x3774e8),
            })
          });
        });

        series.bullets.push(function(root) {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              text: "{valueY}%",
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              populateText: true,
              dy: -10,
              fill: am5.color(0x3774e8),
              fontSize: 12
            })
          });
        });

       
      
        
        series.set("setStateOnChildren", true);
        series.states.create("hover", {});
      
        series.mainContainer.set("setStateOnChildren", true);
        series.mainContainer.states.create("hover", {});
      
        series.strokes.template.states.create("hover", {
          strokeWidth: 4
        });
      
        series.data.setAll(data);
        series.appear(1000);
      }
      
      createSeries("Category", "value");
      
      chart.appear(1000, 100);

      this.root = root;
    });
  }

  ngAfterViewInit() {
    // Chart code goes in here
    
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
