<table class="my-saved-report">
  <thead>
    <tr>
      <ng-container *ngFor="let heading of tableHeading">
        <th>
          <input [disabled]="tableData?.length == 0" class="checkboxInput" type="checkbox" *ngIf="heading.checkBox" (click) = 'selectReport()' #selectAllCheckBox [checked] = "isCheckboxChecked()">
          <span [ngClass]="{'checkbox-title': heading.checkBox}">{{ "reports."+heading.label | translate }}</span>
          <i *ngIf="heading.Icon" class="{{heading.Icon}}" aria-hidden="true"></i>
        </th>
      </ng-container>
    </tr>
    <tr class="divider-row">
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="tableData?.length == 0">
      <tr class="no-record-row">
        <td [attr.colspan]="getColspan()" class="no-record-found" style="text-align: center !important;">
          {{'reports.no_record_found' | translate}}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngFor="let data of tableData">
      <tr>
        <td>
          <span class="checkbox">
              <input class="checkboxInput" type="checkbox" [checked]="sharedService.savedReport.indexOf(data.id) != -1" (click)="selectReport(data.id)"/>
              <span class="pointer" (click)="viewReport(data)">{{getJSONName(data.report_name)}}</span>
          </span>
        </td>
        <td><span class="child">{{data.added_date | date:'d MMM y'}}</span></td>
        <!-- <td><span class="child">{{"User Name"}}</span></td> -->
        <td><button class="download-count" disabled><span class="child">-
          <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
        </span></button></td>
        <!-- <td><span class="child">{{"Shared (4)"}}</span></td> -->
        <td>
          <ul>
            <!-- <li>
              <button disabled>
                <mat-icon svgIcon="fileIcon"></mat-icon>
              </button>
            </li>
            <li>
              <button disabled>
                <mat-icon svgIcon="downloadIcon"></mat-icon>
              </button>
            </li>
            <li>
              <button disabled>
                <mat-icon svgIcon="shareIcon"></mat-icon>
              </button>
            </li>
            <li>
              <button disabled>
                <mat-icon svgIcon="removeFolderIcon"></mat-icon>
              </button>
            </li> -->
            <li>
              <button (click)="deleteReport(data)" class="delete" matTooltip="{{'reports.delete' | translate}}">
                <mat-icon svgIcon="deleteIcon"></mat-icon>
              </button>
            </li>
          </ul>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
