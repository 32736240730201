import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as pbi from 'powerbi-client';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { Subscription } from 'rxjs';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-power-bi-property-details',
  templateUrl: './power-bi-property-details.component.html',
  styleUrls: ['./power-bi-property-details.component.scss']
})
export class PowerBiPropertyDetailsComponent {

  @Input() propertyUid:string='';

  public pbPageId:string='ff4e7fd69b75a04602f1';
  public pbVisualId:string='499f536c58ee5170e23b';
  public pbReportId:string='e841374b-bf9b-4f0d-8e04-2ceb4121ba51';
  public pbGroupId:string='4d482ce2-d9ee-4536-9d79-56315260d58a'

  visualLoader: boolean = false;
  embedData: any;

  
  visuals: any[] = [];
  visualsGrFilter: any[] = [];
  powerBiReport: any;
  routeSub$:Subscription;
  @ViewChild('visualId', { static: false }) visualId!: ElementRef;
  params:any;
  constructor(
    private reportService : ReportService,
    private activatedRoute: ActivatedRoute
  ){
    this.routeSub$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.loadVisualReport();
  }

  
  loadVisualReport(){
    this.visualLoader = true;
    this.reportService.powerBiFecthReport(this.pbGroupId, this.pbReportId)
      .subscribe({
        next: (res: any) =>{
          this.embedData = res.data;
          const powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
          this.embedVisuals(powerbiService, this.embedData.embedUrl, this.embedData.token);
          this.getVisualFilter();
          this.visualLoader = false;
        },
        error: (err)=>{
          this.visualLoader = false;
        }
      })
  }

  
  ngOnChanges(changes: SimpleChanges): void {
    
  }


  getVisualFilter() {
    //Get QueryParam Data
    let paramsFilters = {...this.params};
    let filterYear: any;
    filterYear = (paramsFilters['year']?.split(',') || [new Date().getFullYear()]).map((y: any) => Number(y));
    let basicFilter: any;
    
    //Basics
    basicFilter = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "year",
          column: "year"
        },
        operator: "In",
        values: filterYear
      }
    ]
    // Filter out any objects where values are empty or undefined
    basicFilter = basicFilter.filter((filter: any) => filter.values && filter.values.length > 0);

    this.visualsGrFilter.forEach(visual => {
      visual.setFilters(basicFilter);
    });

  }

  embedVisuals(powerbiService: any, embedUrl: any, accessToken: any) {
    // Create configuration for each visual
    const visualConfig = {
      type: 'visual',
      id: this.pbReportId,
      embedUrl: embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      pageName: this.pbPageId, // "ff4e7fd69b75a04602f1", // Name of the page
      visualName: this.pbVisualId, // "714c773b4a41417284c3",
      Settings: {
        background: models.BackgroundType.Transparent,
        interactionsEnabled: true,
        filterPaneEnabled: true
      }
    };

    // Embed the visual
    this.powerBiReport = powerbiService.embed(this.visualId.nativeElement, visualConfig);
    this.visualsGrFilter.push(this.powerBiReport);

    // Set up an event listener for the 'loaded' event
    this.powerBiReport.on('loaded', async () => {
      this.getVisualFilter();
    });

  }


  convertCommaFilter(name: any) {
    if (name) return name.split(',');
  }

}
