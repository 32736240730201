import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportsComponent } from './components/reports/reports.component';
import { PocContainerComponent } from './components/poc-container/poc-container.component';


const reportsRoutes: Routes = [
    { path: '', component: ReportsComponent },
    { path: 'poc-container', component: PocContainerComponent }
];

@NgModule({
  imports: [RouterModule.forChild(reportsRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
                                                                                                                                                                                                                                                                                                                                                                      