<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <ng-container *ngIf="detailLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1" [height]="'150px'"></app-skeleton-loader>
    </ng-container>
    <!-- *ngIf="propertyDetail" -->
    <app-property-details-overview [Property_Uid]="propertyInfo?.property_uid"></app-property-details-overview>
    <div class="report-tabsection">
        <ng-container *ngIf="overViewPropertyLoading">
            <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="3" [height]="'150px'"></app-skeleton-loader>
        </ng-container>
        <div class="report-overview" *ngIf="!overViewPropertyLoading && overViewPropertySummary">

            <app-overview-card
                [card_title]="'reports.totalResidentialUnits'"
                [untillDate]="overViewPropertySummary.untillDate"
                [total_prev]="overViewPropertySummary.prev_residential_units"
                [total_current]="overViewPropertySummary.residential_units"
                [total_percentage]="overViewPropertySummary.totalResidential"
            ></app-overview-card>

            <app-overview-card
                [card_title]="'reports.totalCommercialUnits'"
                [untillDate]="overViewPropertySummary.untillDate"
                [total_prev]="overViewPropertySummary.prev_commercial_units"
                [total_current]="overViewPropertySummary.commercial_units"
                [total_percentage]="overViewPropertySummary.totalCommercial"
            ></app-overview-card>

            <app-overview-card
                [card_title]="'reports.Total_Parking_Lots'"
                [untillDate]=""
                [total_prev]=""
                [total_current]="overViewPropertySummary.parking_lots"
                [total_percentage]="" [isWas]="false"
            ></app-overview-card>

        </div>
    </div>


<div class="report-model">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.units' | translate}}</h3>
            <span class="totalTransaction">{{ unitCount }}</span>
        </div>

        <!-- <search-bar></search-bar> -->
    </div>
    <app-report-datatable
        [showLoader]="!unitLoading"
        [tableColumns]="unitPropertyColumns"
        [dataLength]="unitCount"
        [tableData]="unitData"
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event, inventoryMenuEnum.unit)"
        (sort)="sortData($event, inventoryMenuEnum.unit)"
        (excelDownload)="downloadExcel($event, 'ir_occupancy_property_unit_report', 'a')" [downloadLoading]="downloadLoading['a']">
    </app-report-datatable>
</div>

<div class="report-model">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.unitTransactionsPrices' | translate}}</h3>
            <span class="totalTransaction">{{ unitTransactionCount }}</span>
        </div>

        <!-- <search-bar></search-bar> -->
    </div>
    <app-report-datatable
        [showLoader]="!unitTransactionLoading"
        [tableColumns]="unitTransactionPropertyColumns"
        [dataLength]="unitTransactionCount"
        [tableData]="unitTransactionData"
        [allowExcelDownload]="true"
        (pageEvent)="onPageEvent($event, inventoryMenuEnum.unitTransaction)"
        (sort)="sortData($event, inventoryMenuEnum.unitTransaction)"
        (excelDownload)="downloadExcel($event, 'ir_occupancy_property_unit_transaction_report', 'b')" [downloadLoading]="downloadLoading['b']">
    </app-report-datatable>
</div>
</div>
