import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportFbService } from './report-fb.service';
import { FBReportConstant } from './report-fb.constants';
import { ReportChartColumn, ReportTableColumn } from '../../models/reports.model';
import { ReportsListsService } from '../../services/reports-lists.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';

@Component({
  selector: 'app-report-fb',
  templateUrl: './report-fb.component.html',
  styleUrls: ['./report-fb.component.scss']
})
export class ReportFbComponent  implements OnInit, OnChanges {

  @Input() params: any;

  chartView = ReportsChartEnum;

  reports = FBReportConstant.reports;


  totalColumnReport: any = [];
  totalColumnReportColumn: ReportTableColumn[] = FBReportConstant.totalColumn;
  averageLeasetableData: any = [];
  averageLeasetableColumns: ReportTableColumn[] = FBReportConstant.averageLeasetableColumns;
  trendAreaOccupiedtableData: any = [];
  trendAreaOccupiedtableColumns: ReportTableColumn[] = FBReportConstant.trendAreaOccupiedTenantableColumns;
  retailFnbCategoryWiseReport: ReportChartColumn[] = [];
  retailFnbCuisineWiseSplitAreaReport: ReportChartColumn[] = [];
  totalLoading = false;
  averageLeasetableDataLoading = false;
  trendAreaOccupiedtableDataLoading= false;
  retailFandBCategoryWiseLoading = false;
  retailFandBCuisineWiseLoading = false;
  
  constructor(
    private reportFbService: ReportFbService,
    private reportsListsService: ReportsListsService
  ){}

  ngOnInit(){
    this.getReports();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.getReports();
    }
  }
  
  getReports(){
    this.getFbTotalReport();
    this.getRetailFnbCategoryWiseReport();
    this.getRetailFnbCuisineWiseSplitAreaReport();
    this.getAverageLeasetReport();
    this.getTrendAreaOccupiedReport();
  }

  getFbTotalReport(year = null){
    this.totalLoading = true;
    this.reportFbService.getTotalfbReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.totalColumnReportColumn = [...this.reportsListsService.yearColumnAppend(FBReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
          this.totalColumnReport = this.reportsListsService.summaryReportConversion( res.data, FBReportConstant.totalColumn)
        } else {
          this.totalColumnReport = [];
        }
        this.totalLoading = false;
      },
      error: (error:any) => {
        this.totalColumnReport = [];
        this.totalLoading = false;
      }}
    )
  }

  getRetailFnbCategoryWiseReport(year = null){
    this.retailFandBCategoryWiseLoading = true;
    this.reportFbService.getRetailFnbCategoryWiseReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.retailFnbCategoryWiseReport = res.data.map((data: any) => {
            const obj = {
              category: data.sub_category,
              value: data.unit_percentage
            }
            return obj;
          })
        } else {
          this.retailFnbCategoryWiseReport = [];
        }
        this.retailFandBCategoryWiseLoading = false;
      },
      error: (error:any) => {
        this.retailFnbCategoryWiseReport = [];
        this.retailFandBCategoryWiseLoading = false;
      }}
    )
  }

  getRetailFnbCuisineWiseSplitAreaReport(year = null){
    this.retailFandBCuisineWiseLoading = true;
    this.reportFbService.getRetailFnbCuisineWiseSplitAreaReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          this.retailFnbCuisineWiseSplitAreaReport = res.data.map((data: any) => {
            const obj = {
              category: data.cuisine,
              value: data.unit_percentage
            }
            return obj;
          })
        } else {
          this.retailFnbCuisineWiseSplitAreaReport = [];
        }
        this.retailFandBCuisineWiseLoading = false;
      },
      error: (error:any) => {
        this.retailFnbCuisineWiseSplitAreaReport = [];
        this.retailFandBCuisineWiseLoading = false;
      }}
    )
  }

  getAverageLeasetReport(year = null){
    this.averageLeasetableDataLoading = true;
    this.reportFbService.getRetailfnbAverageLeaseCategoryReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
         
          this.averageLeasetableData = res.data;
        } else {
          this.averageLeasetableData = [];
        }
        this.averageLeasetableDataLoading = false;
      },
      error: (error:any) => {
        this.averageLeasetableData = [];
        this.averageLeasetableDataLoading = false;
      }}
    )
  }
  

  getTrendAreaOccupiedReport(year = null){
    this.trendAreaOccupiedtableDataLoading = true;
    this.reportFbService.getRetailfnbTrendAreaTentantReport(year).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          if( res?.data?.year.length > 0){
            this.trendAreaOccupiedtableColumns = [...this.reportsListsService.yearColumnAppend(FBReportConstant.trendAreaOccupiedTenantableColumns, res?.data.year, false, GlobalContants.keySymbolContants.unit, 'gla')];
          }
          this.trendAreaOccupiedtableData = res.data.data;
        } else {
          this.trendAreaOccupiedtableData = [];
        }
        this.trendAreaOccupiedtableDataLoading = false;
      },
      error: (error:any) => {
        this.trendAreaOccupiedtableData = [];
        this.trendAreaOccupiedtableDataLoading = false;
      }}
    )
  }

  selectedYear(data: any){
    switch (data.reportType) {
      case this.reports.totalReport:
        this.getFbTotalReport(data.year);
        break;
      case this.reports.retailFandBCategoryWise:
        this.getRetailFnbCategoryWiseReport(data.year);
        break;
      case this.reports.retailFandBCuisineWise:
        this.getRetailFnbCuisineWiseSplitAreaReport(data.year);
        break;
      case this.reports.retailFandBAverageLease:
        this.getAverageLeasetReport(data.year);
        break;
      case this.reports.retailFandBTrendArea:
        this.getTrendAreaOccupiedReport(data.year);
        break;
    }
  }

}
