

<app-reports-container
    [tableColumn]="totalColumnReportColumn"
    [dataValue]="totalColumnReport"
    [isDownloadExcel]="false"
    [loading]="totalLoading"
></app-reports-container>

<app-reports-container
    [tableColumn]="floorWiseableColumn"
    [dataValue]="floorWiseData"
    [isDownloadExcel]="true"
    [loading]="floorWiseDataLoading"
    [reportName]="'reports.mallProfiling_floor_wise'"
    [title]="'reports.mallProfiling_floor_wise'"
    [expandable]="false"
    [yearDropdown]="true"
    [yearReportType]="'mallProfiling_floor_wise'"
    (yearSelected)="selectedYear($event)"
></app-reports-container>
