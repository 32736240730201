import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import { LoaderType } from 'src/app/common/models/enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportInventoryService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  getTotalInventoryReport(year = null){
   
    const url: string = `${environment.miUrl}${apiEndPoints.totalInventoryReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getInvestmentPropertyReport(){
    const url: string = `${environment.miUrl}${apiEndPoints.investmentPropertyReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getInvestmentUnitReport(){
    const url: string = `${environment.miUrl}${apiEndPoints.investmentUnitReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getPropertyReport(){
    let url: string = `${environment.miUrl}${apiEndPoints.propertyReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getUnitGradeReport(){
    let url: string = `${environment.miUrl}${apiEndPoints.unitGradeReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }

  getUnitTypeReport(){
    let url: string = `${environment.miUrl}${apiEndPoints.unitTypeReport}${this.reportService.filterQueryParams}`;
    return this.apiServive.get(url);
  }
}
