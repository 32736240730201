<div class="breadcum-filters breadcum-count-filter">
    <div class="d-flex">

        <!-- If draw catchment show name & count -->
        <mat-chip-row class="filter-matChip filter-matChip-catchment" *ngIf="CatchmentNbr > 0">
            {{'map.catchment' | translate}} <span class="countnbr">{{CatchmentNbr}}</span>
        </mat-chip-row>

        <ng-container *ngIf="transformedFilter && transformedFilter['length'] > 0">
            <ng-container *ngFor="let filterParent of transformedFilter let i = index">
                <!-- click chip -->
                <mat-chip-row [disabled]="(isPolygon && filterParent?.filter == 'neigh_name') ? true : false"  class="filter-matChip" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                    (click)="getFilterChip(filterParent)" (menuClosed)="cancelFilter()">
                    {{filterParent?.name | langtranslate}} <span
                        class="countnbr">({{filterParent?.data?.length}})</span>
                </mat-chip-row>

                <!-- Open Menu -->
                <mat-menu #menu="matMenu" class="filter-type-card">
                    <ng-container *ngIf="filterList.length">
                        <form [formGroup]="reportFilterForm" class="filter-form typecard-in"
                            (click)="$event.stopPropagation()">
                            <ng-container *ngFor="let filter of filterList">
                                <ng-container *ngIf="filter?.value_list || (filter?.type == 'range' || filter?.type == 'date_range')">
                                    <ng-container *ngIf="filterParent.parameter_name === filter.parameter_name">
                                        <div class="position-relative" *ngIf="filter.type == 'single_select' || filter.type == 'multi_select'">
                                            <div id="{{filter.parameter_name}}">
                                                <!-- {{filter.atOneSubscription}} -->
                                                <a *ngIf="filter?.isSubscription" (click)="removeAllFilter(filter)" class="removetext">{{'reports.remove'|translate}}</a>
                                                <app-multi-select
                                                    [required]="filter.required" [name]="filter.title"
                                                    [filterData]="filter" [query_params]="queryParams"
                                                    formGroupName="{{filter.parameter_name}}" [selectAllSubscription]="filter?.isSubscription" [atLeastOneSubscription]="filter?.atLeastOneSubscription"></app-multi-select>
                                            </div>
                                        </div>
                                        <div class="position-relative" *ngIf="filter.type == 'range'">
                                            <a *ngIf="filter?.isSubscription" (click)="removeAllFilter(filter)" class="removetext">{{'reports.remove'|translate}}</a>
                                            <app-min-max-slider [isSubscription]="filter?.isSubscription" [required]="filter.required" [name]="filter.title"
                                                [sliderValue]="SilderValue[filter.parameter_name]" [floorMin]="0"
                                                [floorMax]="getMaxValue(filter.default)"
                                                formGroupName="{{filter.parameter_name}}"></app-min-max-slider>
                                        </div>
                                        <div class="position-relative" *ngIf="filter.type == 'text'">
                                            <a *ngIf="filter?.isSubscription" (click)="removeAllFilter(filter)" class="removetext">{{'reports.remove'|translate}}</a>
                                            <app-form-text [required]="filter.required" [name]="filter.title"
                                                [data]="filter.textval"
                                                formGroupName="{{filter.parameter_name}}"></app-form-text>
                                        </div>
                                        <div class="position-relative amenties-form" *ngIf="filter.type == 'form' && filter.parameter_name === 'amenities'">
                                            <a (click)="removeAllFilter(filter)" class="removetext">{{'reports.remove'|translate}}</a>
                                            <app-amenties-filter-form [name]="filter.title" formGroupName="{{filter.parameter_name}}" [amentiesSelected]="amentiesSelected" [filterData]="filter"></app-amenties-filter-form>

                                        </div>
                                        <div class="position-relative amenties-form" *ngIf="filter.type == 'form' && filter.parameter_name === 'street_name'">
                                            <a (click)="removeAllFilter(filter)" class="removetext">{{'reports.remove'|translate}}</a>
                                            <app-road-access-filter-form [name]="filter.title" formGroupName="{{filter.parameter_name}}" [amentiesSelected]="streetNameSelected" [filterData]="filter"></app-road-access-filter-form>

                                        </div>

                                        
                                        <div *ngIf="filter.type == 'date_range'" class="position-relative">
                                            <a *ngIf="filter?.isSubscription" (click)="removeAllFilter(filter)" class="removetext">{{'reports.remove'|translate}}</a>
                                            <app-date-range [isSubscription]="filter?.isSubscription" [required]="filter.required" [name]="filter.title"
                                                formGroupName="{{filter.parameter_name}}"></app-date-range>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <div class="action-filter">
                                <button (click)="applyFilter()" class="apply-filter"
                                    type="button" [disabled]="LockReport || !applyDisable">{{'reports.apply'|translate}}</button>
                                <button class="reset-filter" (click)="menuTrigger.closeMenu(); cancelFilter()">{{'User.Cancel' |
                                    translate}}</button>
                            </div>
                        </form>
                    </ng-container>
                </mat-menu>
            </ng-container>
        </ng-container>
    </div>
</div>
