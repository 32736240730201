<div class="container">
  <div *ngIf="btnDisable" class="loader-div">
    <img src="../../../../assets/images/loading_signup.gif" alt="">
  </div>
    <div class="login-container">
      <div class="login-right-section">
       
        <div class="login-right-section-inner d-flex">
          <div *ngIf="isRequestSend">
            <div class="contact-success">
              <img (click)="close()" class="close pointer" src="assets/images/cancel.png" alt="">
              <img src="assets/images/task_alt.png" alt="">
              <h3>{{'your_request_has_been_sent' | translate}}</h3>
              <p>{{'our_representative_from_the_sales'| translate}}</p>
            </div>
          </div>
          <div class="login-right-inner" *ngIf="!isRequestSend">
            <div class="title">
                <span>{{'contactSales' | translate}}</span>
                <span class="pointer" (click)="close()"><img src="assets/images/cancel.png" alt=""></span>
            </div>
            <div class="form">
                      <form  [formGroup]="RequestDemoForm" autocomplete="off" (ngSubmit)="saveChanges(RequestDemoForm.getRawValue())" >
                        <div class="form-group mt-3">
                              <div class="input-field">
                                  <div class="">
                                      <label for="">{{"User.full_name" | translate}} <span class="star"> *</span></label>
                                      <input [class.error] = "FullName.invalid && (FullName.dirty || FullName.touched)" type="text" class="form-control" formControlName="full_name" autocomplete="nickname" placeholder="{{'Enter_Name' | translate}}">
                                      <div class="error-msg">
                                        <mat-error class="" *ngIf="FullName.invalid && (FullName.dirty || FullName.touched)">
                                            <span *ngIf="!FullName.errors?.['required'] && FullName.errors?.['pattern'] && FullName?.errors != null">
                                                {{'User.valid_name' | translate}}</span>
                                            <span class="text-center" *ngIf="FullName.errors?.['required'] && !FullName.errors?.['pattern'] && FullName?.errors != null">
                                                {{'User.full_name_required' | translate}}</span>
                                            <span class="text-center" *ngIf="FullName.errors?.['maxlength'] && !FullName.errors?.['pattern'] && FullName?.errors != null">
                                                {{'User.full_name_maxlen_request_demo' | translate}}</span>
                                            <!-- <span class="text-center" *ngIf="FullName.errors?.['minlength'] && !FullName.errors?.['pattern'] && FullName?.errors != null">
                                             </span> -->
                                                <span class="text-center" *ngIf="(FullName.errors?.['minLength']  || (FullName.value.trim() !== '' && FullName.errors?.['minlength'] && FullName?.errors != null)) && !FullName.errors?.['pattern']">
                                                  {{'User.full_name_minlen' | translate}}</span>
                                                <span *ngIf="FullName.errors?.['notOnlyWhitespace'] && FullName?.errors != null && FullName.value.length != 0"
                                                >{{"User.first_name_not_space" | translate}}</span>
                                        </mat-error>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="form-group mt-3">
                              <label for="">{{"User.Email" | translate}} <span class="star"> *</span></label>
                              <input [class.error] = "Email.invalid && (Email.dirty || Email.touched)" type="email" class="form-control" formControlName="email" autocomplete="address-level1" placeholder="{{'Enter_address' | translate}}">
                              <div class="error-msg">
                                <mat-error class="" *ngIf="Email.invalid && (Email.dirty || Email.touched)">
                                    <!-- <span *ngIf="!Email.errors?.['required'] && !Email.errors?.['pattern'] && Email?.errors != null">{{'User.valid_email' | translate}}</span> -->
                                    <!-- <span *ngIf="!Email.errors?.['required'] && Email.errors?.['pattern'] && Email?.errors != null">{{'User.enter_valid_email_address' | translate}}</span> -->
                                     <!-- Valid Email -->
                                    <span *ngIf="(!Email.errors?.['required'] && Email.errors?.['pattern'] && Email?.errors != null && !Email.errors?.['minlength'])">{{'User.valid_email_address' | translate}}</span>
                                     <!-- Min Length -->
                                    <span class="text-center" *ngIf="Email.errors?.['minlength']">
                                      {{ "User.valid_email_sign_up" | translate }}
                                    </span>
                                    <span class="text-center" *ngIf="Email.errors?.['required'] && !Email.errors?.['pattern'] && Email?.errors != null">
                                        {{'User.Email_required' | translate}}</span>
                                    <span class="text-center" *ngIf="Email.errors?.['maxlength'] && !Email.errors?.['pattern'] && Email?.errors != null">
                                        {{'User.valid_email_maxlen' | translate}}</span>
                                </mat-error>
                              </div>
                          </div>
                          <div class="form-group mt-3">
                            <div class="input-field">
                                <div class="">
                                    <label for="">{{"User.company_name" | translate}} <span class="star"> *</span></label>
                                    <input [class.error] = "CompanyName.invalid && (CompanyName.dirty || CompanyName.touched)" type="text" class="form-control" formControlName="company_name" autocomplete="address-level1" placeholder="{{'Enter_company_name' | translate}}">
                                    <div class="error-msg">
                                      <mat-error class="" *ngIf="CompanyName.invalid && (CompanyName.dirty || CompanyName.touched)">
                                          <span class="text-center" *ngIf="CompanyName.errors?.['required'] && CompanyName?.errors != null">
                                              {{'User.company_name_required' | translate}}</span>
                                          <span class="text-center" *ngIf="(CompanyName.errors?.['maxlength'] && CompanyName?.errors != null)">
                                                {{'User.company_name_maxlen' | translate}}</span>
                                          <span class="text-center" *ngIf="CompanyName.errors?.['minLength'] || (CompanyName.value.trim() !== '' && CompanyName.errors?.['minlength'] && CompanyName?.errors != null)">
                                                {{'User.company_name_minlen' | translate}}</span>
                                          <span *ngIf="CompanyName.errors?.['notOnlyWhitespace'] && CompanyName?.errors != null && CompanyName.value.length != 0"
                                                    >{{"User.company_name_not_space" | translate}}</span
                                                >
                                      </mat-error>
  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                          <div class="input-field">
                              <div class="">
                                  <label for="">{{"User.Cities_of_Interest" | translate}} <span class="star"> *</span></label>
                                  <mat-select multiple [class.error] = "City.invalid && (City.dirty || City.touched)" class="form-control" formControlName="city" (openedChange)="search_item =''">
                                    <div class="search-div">
                                      <input type="text" class="form-control" [(ngModel)]="search_item" (keydown)="$event.stopPropagation()" placeholder="{{'reports.search' | translate}}" [ngModelOptions]="{standalone: true}">
                                    </div>
                                      <ng-container *ngIf="( city_list | searchFilter: 'place_name': search_item) as result">
                                        <ng-container *ngIf="search_item==''"><mat-checkbox [checked]="selected_citis.length == city_list.length" class="city-checkbox" (change)="selectAll($event.checked)"> {{'reports.un_select_all' | translate}} </mat-checkbox></ng-container>
                                        <mat-option #matOption  (click)="selectOne(matOption.selected, city.place_id)" *ngFor="let city of result" [value]="city.place_id">
                                          {{ (city?.parent_name?(city.parent_name | translate):'')}} {{ (city.place_name) | translate}}
                                        </mat-option>
                                        <p style="padding: 5px 20px;" *ngIf="result.length === 0">{{'reports.no_record_found' | translate}}</p>  
                                      </ng-container>
                                      
                                  </mat-select>
                                  <!-- <input type="text" class="form-control" formControlName="company_name" autocomplete="address-level1"> -->
                                  <div class="error-msg">
                                    <mat-error class="" *ngIf="City.invalid && (City.dirty || City.touched)">
                                        <span class="text-center" *ngIf="City.errors?.['required'] && City?.errors != null">
                                            {{'User.city_required' | translate}}</span>
                                    </mat-error>
                                  </div>
                              </div>
                          </div>
                      </div>
                          <div class="form-group mt-3">
                              <div class="input-field">
                                  <div class="">
                                      <label for="">{{"User.Mobile_number" | translate}} <span class="star"> *</span></label>
                                      <div class="mobile-country-code">
                                          <ngx-mat-intl-tel-input [class.error] = "PhoneNumber.errors?.['validatePhoneNumber'] || PhoneNumber.invalid && (PhoneNumber.dirty || PhoneNumber.touched)"  class="form-control-mobile" [class.ar-country]="currentLang == 'ar'"
                                          [enablePlaceholder]="true"
                                          [enableSearch]="true"
                                          #phone
                                          describedBy="phoneInput"
                                          formControlName="phoneNumber"
                                          (countryChanged)="getCountryCode($event)"
                                          (input)="phoneInputChange($event)"></ngx-mat-intl-tel-input>
                                      </div>
                                      <ng-container>
                                          <div><mat-hint>{{'User.eg' | translate}} {{phone.selectedCountry?.placeHolder?.replace('+'+dialCode,"")}}</mat-hint></div>
                                          <div *ngIf="PhoneNumber.invalid && (PhoneNumber.dirty || PhoneNumber.touched)">
                                              <mat-error *ngIf="PhoneNumber.errors?.['required']"
                                              >{{"User.Phone_number_required" | translate}}</mat-error>
                                              <div class="error-msg">
                                                <mat-error *ngIf="PhoneNumber.errors?.['validatePhoneNumber']"
                                                    >{{"User.Invalid_number" | translate}}</mat-error
                                                >
                                              </div>
                                          </div>
  
                                        </ng-container>
                                  </div>
                              </div>
                          </div>
                          <div class="form-group mt-3">
                            <div class="input-field">
                                <div class="">
                                    <label for="">{{"User.message" | translate}} </label>
                                    <textarea [class.error] = "Message.invalid && (Message.dirty || Message.touched)" type="text" class="form-control" formControlName="message" autocomplete="address-level1"
                                    placeholder="{{'Enter_message'| translate}}"></textarea>
                                    <div class="error-msg">
                                      <mat-error class="" *ngIf="Message.invalid && (Message.dirty || Message.touched)">
                                          <!-- <span class="text-center" *ngIf="Message.errors?.['required'] && Message?.errors != null">
                                              {{'User.message_required' | translate}}</span> -->
                                          <span class="text-center" *ngIf="(Message.errors?.['maxlength'] && Message?.errors != null)">
                                                {{'User.valid_message_maxlen' | translate}}</span>
                                          <span class="text-center" *ngIf="Message.errors?.['minLength'] || (Message.value.trim() !== '' && Message.errors?.['minlength'] && Message?.errors != null)">
                                                {{'User.valid_message_minlen' | translate}}</span>
                                          <!-- <span *ngIf="Message.errors?.['notOnlyWhitespace'] && Message?.errors != null && Message.value.length != 0"
                                                    >{{"User.message_name_not_space" | translate}}</span -->
                                                >
                                      </mat-error>
  
                                    </div>
                                </div>
                            </div>
                        </div>
                          <div class="form-group buttons">
                              <button class="btn-default m-1" type="button" (click)="close()">{{"User.Cancel" | translate}}</button>
                              <button class="btn-primary m-1" type="submit">{{"User.submit" | translate}}</button>
                          </div>
                      </form>
            </div>
          </div>          
        
        </div>
      </div>
    </div>
  </div>
  
  