import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import {
  Observable,
  Subscription,
  debounceTime,
  defer,
  distinctUntilChanged,
  map,
  merge,
  of
} from 'rxjs';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnChanges, OnInit {

  public searchControl!: FormControl;
  public checkMinCharTyped: Observable<boolean> = of(false);
  private isSearch: boolean = false;
  currentLang: string = 'en';
  languageSub$: Subscription = new Subscription();
  @Input('Reset') reset: boolean = false;
  @Input('MinCharSearch') minCharSearch: number = 2;
  @Input('Placeholder') placeholder: string = 'reports.search';
  @Input() disableSearch: boolean = false;
  @Input() value = '';
  @Output('SearchClick') searchClick: EventEmitter<string> = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
    private sharedService: SharedService){}


  ngOnChanges(changes: SimpleChanges): void {
      if(changes['reset']?.currentValue){
        this.clearSearch();
      }
  }

  ngOnInit(): void {
    this.searchControl = this.formBuilder.control('');
    this.searchControl.setValue(this.value);
    this.searchChanges();
    this.setLang();
  }
  setLang(): void{
    this.languageSub$ = this.sharedService.language$.subscribe((lang) => {
      this.currentLang = lang;
    })
  }
  searchChanges(){
    this.checkMinCharTyped = this.searchControl.valueChanges.pipe(
      map(searchKey => searchKey.length >= this.minCharSearch)
    );

    const searchData$ = merge(
      defer(() => of(this.searchControl.value)),
      this.searchControl.valueChanges
    ).pipe(debounceTime(400), distinctUntilChanged());

    this.checkMinCharTyped.subscribe(search => {
      this.isSearch = search;
    });

    searchData$.subscribe((strV) => {
      if(this.isSearch){
        this.searchControl?.markAllAsTouched();
        this.searchValue(strV);
      } else if(!this.isSearch && strV === '' && this.searchControl.touched){
        this.searchValue(strV);
      }
    })
  }

  clearSearch(){
    this.searchControl.setValue('');
  }

  searchValue(searchData: string){
    searchData = searchData.toLowerCase();
    this.searchClick.emit(searchData);
  }

  disableInput(event: any){
    if(this.disableSearch){
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.languageSub$?.unsubscribe();
  }
}
