<div class="report-container office-space-rental">
    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <!-- Overview -->
    <ng-container *ngIf="overviewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ officeSpaceRentalMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === officeSpaceRentalMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overviewLoading">
            <app-overview-card
                [card_title]="sharedService.priceCalculation$() === sharedService.priceYearly ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'"
                [total_current]="averageData?.avg_rent"
                [untillDate]="averageData?.untillDate"
                [total_prev]="averageData?.prev_avg_rent"
                [total_percentage]="averageData?.totalaverage_rate"
                 [propertiesCount]="averageData?.total_properties"
                [uniType]="UnitTypeEnum.currencywithsqm"
                [graphData]="{data: averageGradeData, type: 'averageRentRate', loader: overviewGradeLoading}"></app-overview-card>

                <app-overview-card
                [card_title]="sharedService.priceCalculation$() === sharedService.priceYearly ? 'reports.averageRentRateNeighWiseAnnual': 'reports.averageRentRateNeighWise'"
                [total_current]="occupancyData?.neigh_rate"
                [untillDate]="occupancyData?.untillDate"
                [total_prev]="occupancyData?.prev_occupacy_rate"
                [total_percentage]="occupancyData?.totaloccupancy_rate"
                 [propertiesCount]="occupancyData?.total_properties"
                [uniType]="UnitTypeEnum.currencywithsqm"
                [graphData]="{data: occupancyGradeData, type: 'occupancyRate', loader: overviewGradeLoading}"></app-overview-card>
         </div>

       

        <!-- Rent Rate Graph -->
        <div class="report-model report-rentrate" *ngIf="countryCode !== countryListCode.saudi">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ? ("reports.annual_rental_price_in" | translate) : ("reports.rental_price_in" | translate)}} <span class="text-capitalize">{{(placeName | lowercase) | translate}}</span> <!-- {{'reports.past_years' | translate}} --> ({{CurrencyCode | translate}}/{{'reports.sqm_unit' | translate}})
                    </h3>
                </div>
              
                <button class="btn-select-grade" [matMenuTriggerFor]="menu" *ngIf="overviewChartData">
                    <div class="setext">
                      <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l" style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'" class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                      <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                      <ng-container *ngFor="let category of getReportKeys()">
                        <mat-checkbox (change)="selectChart($event, category)" [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                      </ng-container>
                    </div>
                  </mat-menu>
            </div>
            <ng-container *ngIf="overviewGraphLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!overviewGraphLoading">
                <div class="multiBarChart d-flex" *ngIf="overviewChartData; else noDataMessage">
                    <ng-container *ngFor="let chart of retailRentalChartReportArray; let i = index">
                      <ng-container *ngFor="let key of getKeys(chart)">
                        <app-report-charts
                        class="grchart"
                        [data]="chart[key]"
                        [chartType]="chartView.smallBarChart"
                        [isBorder]="false"
                        [name]="key" [index]="i" [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '300px'}"
                        >
                    </app-report-charts>
                  </ng-container>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container>
        </div>

        <div class="report-model report-rentrate" *ngIf="countryCode === countryListCode.saudi">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.transactionVolumeByGrade' | translate}}  ({{'reports.sqm_unit' | translate}})
                    </h3>
                </div>
              
                <button class="btn-select-grade" [matMenuTriggerFor]="menu" *ngIf="transactionVolumeChartData">
                    <div class="setext">
                      <ng-container *ngFor="let name of selectedTranVolume; let l = last">{{name}}<span *ngIf="!l" style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedTranVolume.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'" class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                      <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                      <ng-container *ngFor="let category of getTransactionKeys()">
                        <mat-checkbox (change)="selectTracVolChart($event, category)" [checked]="selectedTranVolume.includes(category)">{{category}}</mat-checkbox>
                      </ng-container>
                    </div>
                  </mat-menu>
            </div>
            <ng-container *ngIf="transactionVolumeLoading">
                <app-skeleton-loader [reportType]="chartView.pieChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!transactionVolumeLoading">
                <div *ngIf="transactionVolumeChartData; else noDataVolumeMessage"> 
                    <app-pie-chart-report [data]="tranactionVolumeChartReport"></app-pie-chart-report>
                </div>
                 
            </ng-container>
            <ng-template #noDataVolumeMessage>
                <div class="data_unavail">
                    <span>
                        {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                    </span>
                </div>
            </ng-template>
        </div>
    </div>

    <!-- Rent Unit Size Wise -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === officeSpaceRentalMenuEnum.rentUnitSizeWise}"
        id="{{ officeSpaceRentalMenuEnum.rentUnitSizeWise }}">
        <!-- Multiple Year Dropdown -->
        <app-report-year-dropdown [reportType]="'rentUnitSize'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{sharedService.priceCalculation$() === sharedService.priceYearly ? ("reports.annualRentUnitSizeWise" | translate) : ("reports.rentUnitSizeWise" | translate)}}</h3>
                    <!-- <span class="totalTransaction">({{rentUnitSizeCount ? reportService.formatInternationNum(rentUnitSizeCount) : rentUnitSizeCount}} {{"reports.totalUnits" | translate}})</span> -->
                    <span class="totalTransaction">({{rentUnitSizeCount ? reportService.formatInternationNum(rentUnitSizeCount) : rentUnitSizeCount}})</span>
                </div>
            </div>
            <!-- <ng-container *ngIf="rentUnitSizeLoading">
                <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
            </ng-container> -->
            <app-report-datatable class="rosr-rentunitsize-table"
                [showLoader]="!rentUnitSizeLoading"
                [tableColumns]="rentUnitSizeColumns"
                [dataLength]="rentUnitSizeCount"
                [tableData]="rentUnitSizeData"
                (pageEvent)="onPageEvent($event, officeSpaceRentalMenuEnum.rentUnitSizeWise)"
                (sort)="sortData($event, officeSpaceRentalMenuEnum.rentUnitSizeWise)"
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'office_rental_rent_unitsize_v2', 'a')"
                [showEstaterText]="true"
                [expandColumn]="true"
                [tableExpandColumns]="unitsizeExpandColumns"
                [dropDownOption]="unitSizeDropdown"
                (dropDownValue)="dropDownChange($event, officeSpaceRentalMenuEnum.rentUnitSizeWise)"
                [downloadLoading]="downloadLoading['a']"
                [showTooltip]="true"
            >
            </app-report-datatable>
        </div>
    </div>

    <!-- Properties -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === officeSpaceRentalMenuEnum.properties}"
        id="{{ officeSpaceRentalMenuEnum.properties }}">
        <!-- Multiple Year Dropdown -->
        <app-report-year-dropdown [reportType]="'units'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>
        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.projectsProperties" | translate}}</h3>
                    <span class="totalTransaction">({{unitsCount ? reportService.formatInternationNum(unitsCount) : unitsCount }} {{"reports.totalProperties" | translate}})</span>
                </div>
            </div>
            <!-- <ng-container *ngIf="unitsLoading">
                <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
            </ng-container> -->
            <app-report-datatable [showLoader]="!unitsLoading" [tableColumns]="propertiesColumns" [dataLength]="unitsCount"
                [tableData]="unitsData" (pageEvent)="onPageEvent($event, officeSpaceRentalMenuEnum.properties)"
                (sort)="sortData($event, officeSpaceRentalMenuEnum.properties)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'office_rental_property_v2', 'b')"
                 [downloadLoading]="downloadLoading['b']" [showEstaterText]="true">
            </app-report-datatable>
        </div>
    </div>

</div>
