import { Component, effect, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.css']
})
export class ReportGridComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reportData: any;
  routeSub$: Subscription = new Subscription();
  queryParams: any;
  filter_data!: { report: any; reportId: any; filter: any; };
  
  multi_story_residential:any;

  // showReportLabel = true;

  // hiddenLabel = ['transactions','house_and_villa','rental','office_rental']
  studyName: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService : ReportService
  ){
    effect(()=> this.multi_story_residential = this.reportService.multi_story_residential$$())
  }

  ngOnInit(){
    this.routeSub$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.studyName = this.reportService?.selectedStudies?.study_name;
    // if(this.hiddenLabel.includes(this.reportData?.name)){
    //   this.showReportLabel = false;
    // }
  }

  ngOnDestroy(): void {
    this.routeSub$.unsubscribe();
  }

}
